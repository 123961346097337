import { instance } from "../../instance";

// 下載自動轉賬系統用戶手冊
export const downloadBook = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/payroll/salary-and-mpf-autopay/autopay-user-manual/download`,
    data: {
      categoryId: 24,
    },
    responseType: "arraybuffer",
  }).then(
    (result) => {
      let url = window.URL.createObjectURL(new Blob([result.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      const fileName = decodeURI(
        result.headers["content-disposition"]
          .split(";")[1]
          .split("filename*=UTF-8''")[1]
      );
      if (!fileName) {
        alert("請求出錯");
        return;
      }
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

export const getMaintworkerautopaymainflow = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getMaintworkerautopaymainflow`,
    // data: 'kfwgvzpo',
    // headers: {
    //     'Content-Type': 'text/plain'
    // }
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 設置地盤輸入帳戶
export const getShowSetSiteUser = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getShowSetSiteUser`,
    // data: 'kfwgvzpo',
    // headers: {
    //     'Content-Type': 'text/plain'
    // }
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
// 獲取大糧期
export const getBigPeriod = async () => {
  // getPeriodMake
  return instance
    .get(`${process.env.REACT_APP_WEB_URL}/vps/common/getPeriodMake`)
    .then(
      (result) => {
        return result.data.data;
      },
      (error) => {
        // alert("請求出錯")
        return [];
      }
    );
};

// 獲取短糧期
export const getShortPeriod = async (dateFrom) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getShortBatchPeriod`,
    data: {
      dateFrom: dateFrom,
    },
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 製作自動轉賬檔案表格
export const getWorkerDetail = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getWorkerDetail`,
    data: {
      subBatchId: data.shortPay === "F" ? null : data.shortPay,
      dateFrom: data.bigPay,
      pdDateFrom: data.pdDateFrom,
      pdDateTo: data.pdDateTo,
    },
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

export const getWorkerDetailSub = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getWorkerDetail3`,
    data: {
      // userId: userId,
      dateFrom: data.bigPay,
      pdDateFrom: data.pdDateFrom,
      pdDateTo: data.pdDateTo,
    },
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
export const getWorkerDetailFinall = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getWorkerDetail2`,
    data: {
      // userId: userId,
      dateFrom: data.bigPay,
      pdDateFrom: data.pdDateFrom,
      pdDateTo: data.pdDateTo,
    },
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 製作自動轉賬檔案
export const getBankacsetRs = async (
  dateFrom,
  vendorId,
  shortBatchPeriod,
  pdDateTo,
  pdDateFrom
) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getBankacsetRs`,
    data: {
      dateFrom: dateFrom,
      vendorId: vendorId,
      shortBatchPeriod: shortBatchPeriod,
      pdDateTo: pdDateTo,
      pdDateFrom: pdDateFrom,
    },
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
// button7 地盤名稱下拉框
export const getSiteName = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getFillSite`,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
// button7 分判商下拉框
export const getFillSubContractor = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getFillSubContractor`,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
// button7 支薪及供款期下拉框
export const getPeriodList = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getPeriod`,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
// button7 狀態下拉框
export const getStatusList = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getfillStatus`,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
// button7 短糧期下拉框
export const getgetShortPeriod = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getShortPeriod`,
    data: "15974",
    headers: {
      "Content-Type": "text/plain",
    },
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// button8 档案递交方式
export const getSaveWay = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getShortPeriod`,
    data: "15974",
    headers: {
      "Content-Type": "text/plain",
    },
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// button9 支薪及供款期下拉框
export const getCompleteSearchPeriod = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getCompleteSearchPeriod`,
    // data: 'jmghrupo',
    // headers: {
    //     'Content-Type': 'text/plain'
    // }
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

export const getCompleteSelectvendorsite = async (dateFrom) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getCompleteSelectvendorsite`,
    data: {
      dateFrom: dateFrom,
    },
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
// button9 分判商下拉框
export const getCompleteSubcontractor = async (dateFrom) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getCompleteSubcontractor`,
    data: {
      dateFrom: dateFrom,
    },
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// button9 拿數據
export const getWorkerDetailRs = async (
  vendorId,
  dateFrom,
  dateTo,
  siteId,
  subContractorId
) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getWorkerDetailRs`,
    data: {
      vendorId: vendorId,
      dateFrom: dateFrom,
      dateTo: dateTo,
      siteId: siteId,
      selectSubConId: subContractorId,
      pdDateTo: dateTo,
      pdDateFrom: dateFrom,
    },
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
// button9 （分支沒有供款的糧期） 拿數據
export const getWorkerDetailRsGap = async (dateFrom, vendorId) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/searchPayrollGapReport`,
    data: {
      dateFrom: dateFrom,
      vendorId: vendorId,
    },
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
// button7 下拉框
export const getWorkerAutopayMainSearch = async (userId) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getWorkerAutopayMainSearch`,
    // data: "KFWGVZ",
    // headers: {
    //     'Content-Type': 'text/plain'
    // }
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// button7部分糧期下拉框
export const getPartPariodList = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getWorkerAutopayPartialBatchMainSearch`,
    // data: "KFWGVZ",
    // headers: {
    //     'Content-Type': 'text/plain'
    // }
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 删除一条数据
export const deleteData = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/deletebodyAutopayMainData`,
    data: data,
  }).then(
    (result) => {
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// button7 提交
export const sendAllData = async (sendData) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getWorkerAutopayMainData`,
    data: sendData,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// button7 部分粮期提交
export const sendPartAllData = async (sendData) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getWorkerAutopayMainPartialBatchData`,
    data: sendData,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// button7 拿最大的seqNo
export const getSeqNo = async (dataFrom, vendorId) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getMaxSeqNo`,
    data: {
      dateFrom: dataFrom,
      vendorId: vendorId,
    },
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
// button7 保存表格的數據
export const saveTableData = async (
  dateFrom,
  dateTo,
  subContractorId,
  siteId,
  headerId,
  data
) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/updatebodyAutopayMainData`,
    data: {
      dateFrom: dateFrom,
      dateTo: dateTo,
      subContractorId: subContractorId,
      siteId: siteId,
      headerId: headerId,
      workerDetailsql: data,
    },
  }).then(
    (result) => {
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
// 制作自动转账
export const salaryAndMpfAutopay = async (sendData) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/payroll/salary-and-mpf-autopay/autopay-profile/export`,
    data: sendData,
  }).then(
    (result) => {
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 修改工人薪金/強積金資料表头数据
export const updateHeaderAutopayMainData = async (headData) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/updateHeaderAutopayMainData`,
    data: headData,
  }).then(
    (result) => {
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 修改工人薪金/強積金資料核对
export const checkworkerDetailsql = async (checkData) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/checkworkerDetailsql`,
    data: checkData,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 打印工人強積金自動轉賬授權書
export const mpfPrint = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/payroll/salary-and-mpf-autopay/letter-mpf/print`,
    data: data,
  }).then(
    (result) => {
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
// 打印工人入息自動轉賬檔案 最后打印PDF
export const mpfPrintPDF = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/payroll/salary-and-mpf-autopay/letter-mpf/pdf`,
    data: data,
    responseType: "arraybuffer",
  }).then(
    (result) => {
      let url = window.URL.createObjectURL(new Blob([result.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      const fileName = decodeURI(
        result.headers["content-disposition"]
          .split(";")[1]
          .split("filename*=UTF-8''")[1]
      );
      link.setAttribute("download", fileName + ".pdf");
      document.body.appendChild(link);
      link.click();
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 下載工人強積金自動轉賬授權書
export const mpfDownload = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/payroll/salary-and-mpf-autopay/letter-mpf/download`,
    data: data,
    responseType: "arraybuffer",
  }).then(
    (result) => {
      let url = window.URL.createObjectURL(new Blob([result.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      const fileName = data.filename;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 下載工人入息自動轉賬檔案
export const letterPayrollDownload = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/payroll/salary-and-mpf-autopay/letter-payroll/download`,
    data: data,
    responseType: "arraybuffer",
  }).then(
    (result) => {
      // if(result.)
      let url = window.URL.createObjectURL(new Blob([result.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      const fileName = data.filename;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 打印工人入息自動轉賬檔案
export const letterPayrollPrint = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/payroll/salary-and-mpf-autopay/letter-payroll/print`,
    data: data,
  }).then(
    (result) => {
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 打印工人入息自動轉賬檔案 最后打印PDF
export const letterPayrollPrintPDF = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/payroll/salary-and-mpf-autopay/letter-payroll/pdf`,
    data: data,
    responseType: "arraybuffer",
  }).then(
    (result) => {
      let url = window.URL.createObjectURL(new Blob([result.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      const fileName = decodeURI(
        result.headers["content-disposition"]
          .split(";")[1]
          .split("filename*=UTF-8''")[1]
      );
      link.setAttribute("download", fileName + ".pdf");
      document.body.appendChild(link);
      link.click();
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 製作自動轉賬檔案打印pdf  SubGeneratePdf
export const SubPrintPDF = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/MaintWorkerAutopayGenerateSubGeneratePdf`,
    data: data,
    responseType: "arraybuffer",
  }).then(
    (result) => {
      let url = window.URL.createObjectURL(new Blob([result.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      const fileName = decodeURI(
        result.headers["content-disposition"]
          .split(";")[1]
          .split("filename=")[1]
      );
      link.setAttribute("download", fileName + ".pdf");
      document.body.appendChild(link);
      link.click();
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
// 製作自動轉賬檔案打印pdf  FinallGeneratePdf
export const finallPrintPDF = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/MaintWorkerAutopayGenerateFinalGeneratePdf`,
    data: data,
    responseType: "arraybuffer",
  }).then(
    (result) => {
      let url = window.URL.createObjectURL(new Blob([result.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      const fileName = decodeURI(
        result.headers["content-disposition"]
          .split(";")[1]
          .split("filename=")[1]
      );
      link.setAttribute("download", fileName + ".pdf");
      document.body.appendChild(link);
      link.click();
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
// 製作自動轉賬檔案打印pdf  GenerateGeneratePdf
export const GeneratePrintPDF = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/MaintWorkerAutopayGenerateGeneratePdf`,
    data: data,
    responseType: "arraybuffer",
  }).then(
    (result) => {
      let url = window.URL.createObjectURL(new Blob([result.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      const fileName = decodeURI(
        result.headers["content-disposition"]
          .split(";")[1]
          .split("filename=")[1]
      );
      link.setAttribute("download", fileName + ".pdf");
      document.body.appendChild(link);
      link.click();
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
// 製作自動轉賬檔案打印pdf  PartialGeneratePdf
export const PartialPrintPDF = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/MaintWorkerAutoPayGeneratePartialBatchGeneratePdf`,
    data: data,
    responseType: "arraybuffer",
  }).then(
    (result) => {
      let url = window.URL.createObjectURL(new Blob([result.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      const fileName = decodeURI(
        result.headers["content-disposition"]
          .split(";")[1]
          .split("filename=")[1]
      );
      link.setAttribute("download", fileName + ".pdf");
      document.body.appendChild(link);
      link.click();
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

//   承判商自動轉賬狀況打印pdf
export const searchAutoFilePrintPDF = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/ReportVendorAutoPayStatusDetailPdf`,
    data: data,
    responseType: "arraybuffer",
  }).then(
    (result) => {
      let url = window.URL.createObjectURL(new Blob([result.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      const fileName = decodeURI(
        result.headers["content-disposition"]
          .split(";")[1]
          .split("filename=")[1]
      );
      link.setAttribute("download", fileName + ".pdf");
      document.body.appendChild(link);
      link.click();
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

//
export const getPeriod = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/periodsearch-salaryandmpf`,
    data: data,
    // headers: {
    //     'Content-Type': 'text/plain'
    // }
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

//
export const getShortBatchList = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/shortbatchperiodsearch-salaryandmpf`,
    data: data,
    // headers: {
    //     'Content-Type': 'text/plain'
    // }
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

export const getVendorList = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getvendorDesc`,

    // headers: {
    //     'Content-Type': 'text/plain'
    // }
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

export const getSiteList = async (data) => {
  return instance({
    method: "POST",
    data: data,
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/sitesearch-salaryandmpf`,

    // headers: {
    //     'Content-Type': 'text/plain'
    // }
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

export const getSubcontractorList = async (data) => {
  return instance({
    method: "POST",
    data: data,
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/subcontractorsearch-salaryandmpf`,

    // headers: {
    //     'Content-Type': 'text/plain'
    // }
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

export const salaryAndMPFSearchReport = async (data) => {
  return instance({
    method: "POST",
    data: data,
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/salaryAndMPFSearchReport`,

    // headers: {
    //     'Content-Type': 'text/plain'
    // }
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
