import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Autocomplete,
  Container,
  Grid,
  FormControl,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  MenuItem,
  TableFooter,
  FormLabel,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import moment from 'moment'
import $http, { $download } from 'src/utils/api/services'
import { set } from 'lodash'
import * as api from '../../../utils/api/vendorAutopaySystem/RptVendorAutoPaySummaryMainApi'

export default function RptVendorAutoPaySummaryMain() {
  const [isSearchPage, setIsSearchPage] = useState(true)
  // 國際化
  const { t } = useTranslation()
  const [backOpen, setBackOpen] = useState(false)
  const [siteList, setSiteList] = useState([])
  const [jobNatureIdList, setJobNatureIdList] = useState([])
  const [sendData, setSendData] = useState({})
  const [compareReport, setCompareReport] = useState({})
  const [showDiff, setShowDiff] = useState(1)
  const [tableData, setTableData] = useState([])
  const [vendorIdList, setVendorIdList] = useState([])
  const [dateTo, setDateTo] = useState([])
  const [dateFrom, setDateFrom] = useState([])
  useEffect(() => {
    setBackOpen(true)

    const getData = async () => {
      let getJobNatureIdList = await api.getfilljobNatureId()
      let getVendorIdList = await api.getVendorIdList()
      let getSiteList = await api.getSiteList()
      let getDateFrom = await api.getDateFrom()
      let getDateTo = await api.getDateTo()

      setSendData({
        ...sendData,
        jobNatureId: '',
        siteId: '',
        compareReport: '1',
        showDiff: '1',
      })
      if (getDateFrom) {
        setDateFrom(getDateFrom)
      }
      if (getDateTo) {
        setDateTo(getDateTo)
      }
      if (getVendorIdList) {
        getVendorIdList.splice(0, 0, { id: '', text: 'ALL' })
        setVendorIdList(getVendorIdList)
      }

      if (getSiteList) {
        getSiteList.splice(0, 0, { id: '', text: 'ALL' })
        setSiteList(getSiteList)
      }
      if (getJobNatureIdList) {
        getJobNatureIdList.splice(0, 0, { id: '', text: 'ALL' })
        setJobNatureIdList(getJobNatureIdList)
      }
      setBackOpen(false)
    }
    getData()
  }, [])

  const handleBack = () => {
    setShowDiff('1')
    setSendData({ ...sendData, showDiff: '1' })
    setIsSearchPage(true)
  }

  const handleDateFrom = (e) => {
    setSendData({
      ...sendData,
      dateFrom: e.id,
      dateFromDesc: e.text,
      dateTo: '',
    })
  }

  const handleDateTo = (e) => {
    if (!completeDate(new Date(sendData.dateFrom), new Date(e.id), 12)) {
      alert(t('The_difference_between_the_date_12_check'))
      return
    }
    if (isBefore(new Date(e.id), new Date(sendData.dateFrom))) {
      alert(t('Date_to_cannot_be_less_than_date_by'))
      return
    }
    setSendData({ ...sendData, dateTo: e.id, dateToDesc: e.text })
  }

  const handleSiteId = (e, data) => {
    if (data !== null) {
      setSendData({
        ...sendData,
        siteId: data.id ? data.id : null,
        site: data,
      })
    } else {
      setSendData({
        ...sendData,
        siteId: null,
        site: null,
      })
    }
  }

  const handleVendorId = (_event, data) => {
    if (data !== null) {
      setSendData({
        ...sendData,
        vendorId: data.id,
        vendorDesc: data.text,
        vendor: data,
      })
    } else {
      setSendData({
        ...sendData,
        vendorId: null,
        vendorDesc: null,
        vendor: null,
      })
    }
  }
  const isBefore = (date1, date2) => {
    return date1 < date2
  }

  const completeDate = (time1, time2, m) => {
    const year1 = time1.getFullYear()
    const year2 = time2.getFullYear()
    const month1 = time1.getMonth() + 1
    const month2 = time2.getMonth() + 1
    const day1 = time1.getDate()
    const day2 = time2.getDate()
    if (year2 === year1) {
      // 判断两个日期 是否是同年
      if (month2 - month1 > m) {
        // 相差是否 在m个月中
        return false
      } else if (month2 - month1 === m) {
        // 如果正好为 m月 判断天数
        if (day2 > day1) {
          return false
        }
      }
    } else {
      // 不同年
      if (year2 - year1 > 1) {
        return false
      } else if (year2 - year1 === 1) {
        if (month2 > m || month1 + m - month2 < 12) {
          return false
        } else if (month1 + m - month2 === 12) {
          // 正好为m月 判断天数
          if (day2 > day1) {
            return false
          }
        }
      }
    }
    return true
  }

  const handleShowDiff = (e) => {
    setSendData({ ...sendData, showDiff: e.target.value })
    setShowDiff(e.target.value)
  }

  const handleCompareReport = (e) => {
    setSendData({ ...sendData, compareReport: e.target.value })
    setCompareReport(e.target.value)
  }

  const handleJobNatureId = (e, data) => {
    if (data !== null) {
      setSendData({
        ...sendData,
        jobNatureId: data.id ? data.id : null,
        jobNatureIdDesc: data.text,
        jobNature: data,
      })
    } else {
      setSendData({
        ...sendData,
        jobNatureId: null,
        jobNatureIdDesc: null,
        jobNature: null,
      })
    }
  }

  const handleSubmit = (e) => {
    if (!sendData.dateFrom || !sendData.dateTo) {
      alert('請選擇日期')
      return
    }

    const getData = async () => {
      console.log('sendata', sendData)

      setBackOpen(true)

      let send = await api.getAutoPaySummarymain(sendData)

      if (send) {
        setIsSearchPage(false)
        setTableData(send)
      }
      setBackOpen(false)
    }
    getData()
  }

  const resetForm = (e) => {
    setSendData({})
  }

  const exportExcel = (data) => {
    $download(`/payrollReportController/getAutoPaySummarymainExcel`, 'application/vnd.ms-excel', data)
  }

  const exportPDF = (data) => {
    $download(`/payrollReportController/getAutoPaySummarymainPdf`, 'application/pdf', data)
  }

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {isSearchPage && (
        <form>
          <Container maxWidth="md">
            <Grid spacing={2}>
              {/* 承判商/工人自動轉賬狀況分析 */}
              <Grid item xs={12}>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                  <b>{t('contractor_worker_automatic_transfer_status_analysis')}</b>
                </Typography>
              </Grid>
              <div>
                <Grid
                  item
                  xs={6}
                  sx={{
                    margin: 'auto',
                    width: '400px',
                    marginBottom: '20px',
                    marginTop: '10px',
                  }}
                >
                  <Autocomplete
                    value={sendData.vendor || null}
                    onChange={handleVendorId}
                    fullWidth
                    size="small"
                    id="combo-box-demo"
                    getOptionLabel={(option) => {
                      console.log('option', option)
                      return option.text
                    }}
                    options={vendorIdList}
                    renderInput={(params) => <TextField fullWidth size="small" {...params} label={t('icon_vas_wsms_report_table_header_vendor')} />}
                  />
                </Grid>
              </div>

              <Grid
                item
                xs={6}
                sx={{
                  margin: 'auto',
                  width: '400px',
                  marginBottom: '20px',
                  marginTop: '10px',
                }}
              >
                <Autocomplete
                  value={sendData.site || null}
                  onChange={handleSiteId}
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  getOptionLabel={(option) => {
                    return option.text
                  }}
                  options={siteList}
                  renderInput={(params) => <TextField fullWidth size="small" defaultValue="1" {...params} label={t('icon1_autopay_setSiteUserMapping_tableLabel1')} />}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  margin: 'auto',
                  width: '400px',
                  marginBottom: '20px',
                  marginTop: '10px',
                }}
              >
                <Autocomplete
                  value={sendData.jobNature || null}
                  onChange={handleJobNatureId}
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  getOptionLabel={(option) => {
                    return option.text
                  }}
                  options={jobNatureIdList}
                  renderInput={(params) => <TextField fullWidth size="small" {...params} label={t('Outfit')} />}
                />
              </Grid>

              <Grid
                sx={{
                  margin: 'auto',
                  width: '400px',
                  marginBottom: '20px',
                  marginTop: '10px',
                }}
              >
                <TextField sx={{ width: '400px' }} error={!sendData.dateFrom} id="dateFrom" value={sendData.dateFrom || ''} fullWidth size="small" label={t('date_from')} select>
                  {dateFrom.map((item, index) => (
                    <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleDateFrom(item)}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid
                sx={{
                  margin: 'auto',
                  width: '400px',
                  marginBottom: '20px',
                  marginTop: '10px',
                }}
              >
                <TextField sx={{ width: '400px' }} error={!sendData.dateTo} id="dateTo" value={sendData.dateTo || ''} fullWidth size="small" label={t('date_to')} select>
                  {dateTo.map((item, index) => (
                    <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleDateTo(item)}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid
                sx={{
                  margin: 'auto',
                  width: '400px',
                  marginBottom: '20px',
                  marginTop: '10px',
                }}
              >
                <FormLabel id="contentTitle">{t('log_difference')}</FormLabel>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <FormControl size="small" sx={{ width: '400px' }}>
                  <RadioGroup defaultValue={showDiff} row onChange={handleShowDiff}>
                    <FormControlLabel label={t('show')} value="0" control={<Radio />} />
                    <FormControlLabel label={t('do_not_show')} value="1" control={<Radio />} />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {sendData.showDiff === '0' && (
                <Grid
                  item
                  xs={6}
                  sx={{
                    margin: 'auto',
                    width: '400px',
                    marginBottom: '20px',
                    marginTop: '10px',
                  }}
                >
                  <TextField defaultValue="1" value={sendData.compareReport} fullWidth size="small" label={t('')} select onChange={handleCompareReport}>
                    <MenuItem value="1">{t('salary_analysis_of_workers_classified_by_line')}</MenuItem>
                    <MenuItem value="2">{t('salary_Analysis_of_Workers_Classified_by_Site_A')}</MenuItem>
                    <MenuItem value="3">{t('salary_Analysis_of_Workers_Classified_by_Site_B')}</MenuItem>
                    <MenuItem value="4">{t('salary_Analysis_of_Workers_Classified_by_Contractors')}</MenuItem>
                    <MenuItem value="5">{t('contractor_automatic_transfer_of_payouts_and_labor_analysis_chart_monthly')}</MenuItem>
                    <MenuItem value="6">{t('contractor_automatic_transfer_to_pay_workers_salary_performance_monthly')}</MenuItem>
                    <MenuItem value="7">{t('contractor_automatic_transfer_to_pay_workers_salary_performance_quarterly')}</MenuItem>
                  </TextField>
                </Grid>
              )}
              <Grid item xs={6} />
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <FormControl>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                      backgroundColor: 'primaryBlue',
                      color: 'commom.white',
                    }}
                  >
                    {t('button_submit')}
                  </Button>
                </FormControl>
                <FormControl sx={{ marginLeft: '20px' }}>
                  <Button
                    variant="contained"
                    onClick={resetForm}
                    sx={{
                      backgroundColor: 'primaryGreen',
                      color: 'commom.white',
                      marginRight: '10px',
                    }}
                    label="Reset"
                  >
                    {t('button_reset')}
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Container>
        </form>
      )}

      {!isSearchPage && (
        <Container maxWidth={false}>
          <div
            style={{
              display: 'block',
              textAlign: 'left',
              marginTop: '0px',
            }}
          >
            <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
              <ArrowBackIcon />
              {t('button_back')}
            </Button>
          </div>

          <div style={{ display: 'block', textAlign: 'right' }}>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportExcel(sendData)}>
              <DownloadIcon />
              {t('button_export_excel')}
            </Button>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportPDF(sendData)}>
              <DownloadIcon />
              {t('button_export_pdf')}
            </Button>
          </div>
          <div>
            <Typography variant="h4" style={{ textAlign: 'center', mt: '-30px' }}>
              <b>{t('contractor_worker_automatic_transfer_status_analysis')}</b>
            </Typography>
            <TableContainer>
              <Table sx={{ textAlign: 'left' }} size="small">
                <TableBody>
                  <TableRow>
                    {/* 製作日期： */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      {t('date_of_production')}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{moment(new Date().getTime()).format('yyyy/MM/DD')}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 地盤名稱： */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        display: 'block',
                        borderBottom: 'none',
                      }}
                    >
                      {t('Site_Name')}:
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.site?.text === 'ALL' ? sendData.site.text : t('all_sites')}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 承判商： */}
                    {/* 行頭： */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        width: 100,
                        borderBottom: 'none',
                      }}
                    >
                      {t('icon1_accrecsearch_sub_condition')}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.vendorDesc ? sendData.vendorDesc : t('all_contractors')}</TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'left',
                        fontWeight: 'bold',
                        width: 100,
                        borderBottom: 'none',
                      }}
                    >
                      {t('Outfit')}:
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.jobNatureIdDesc ? sendData.jobNatureIdDesc : t('all_outfits')}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 日期（由）： */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      {t('date_from')}:
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.dateFrom}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 日期（至）： */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      {t('date_to')}:
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.dateTo}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer style={{ width: '80%' }}>
              {/* form1 */}
              <Table size="small" sx={{ mt: '20px' }}>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('the_number_of_workers_entering_and_exiting_Sun_Hung_Kai_site')}
                  </TableCell>
                  {/* {tableData.map((row, index) => (
                    <TableCell sx={{ textAlign: 'right' }}>{row.dateFrom}</TableCell>
                  ))} */}
                  <TableCell sx={{ textAlign: 'right' }}>{sendData.dateToDesc}</TableCell>
                </TableRow>
                {sendData.vendorId && (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('dSC_worker_A')}
                      </TableCell>
                      {tableData.map((row, index) => (
                        <TableCell sx={{ textAlign: 'right' }}>{row.mainData.dsccount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                      ))}
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('nSC_worker_B')}
                      </TableCell>
                      {tableData.map((row, index) => (
                        <TableCell sx={{ textAlign: 'right' }}>{row.mainData.nsccount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('number_of_workers_who_have_permission_to_enter_and_exit_the_site_A_B')}
                      </TableCell>
                      {tableData.map((row, index) => (
                        <TableCell sx={{ textAlign: 'right' }}>{(row.mainData.dsccount + row.mainData.nsccount).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                      ))}
                    </TableRow>
                  </>
                )}

                {!sendData.vendorId && (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('dSC_worker_A')}
                      </TableCell>
                      {tableData.map((row, index) => (
                        <TableCell sx={{ textAlign: 'right' }}>{row.mainData.dsccount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                      ))}
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('nSC_worker_B')}
                      </TableCell>
                      {tableData.map((row, index) => (
                        <TableCell sx={{ textAlign: 'right' }}>{row.mainData.nsccount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('number_of_workers_who_have_permission_to_enter_and_exit_the_site_A_B')}
                      </TableCell>
                      {tableData.map((row, index) => (
                        <TableCell sx={{ textAlign: 'right' }}>{(row.mainData.dsccount + row.mainData.nsccount).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                      ))}
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('the_number_of_workers_who_have_permission_but_do_not_have_access_to_the_Sun_Hung_Kai_site_C')}
                      </TableCell>
                      {tableData.map((row, index) => (
                        <TableCell sx={{ textAlign: 'right' }}>{row.mainData.narshkpcount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                      ))}
                    </TableRow>

                    {(sendData.siteId === '' || sendData.siteId === null || !sendData.siteId) && (sendData.jobNatureId === '' || sendData.jobNatureId === null || !sendData.jobNatureId) && (
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: '15px',
                            wordBreak: 'keep-all',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {t('number_of_workers_without_permission_to_enter_and_exit_the_site_D')}
                        </TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.narcount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                        ))}
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('number_of_Workers_with_Authorized_Access_to_Site_A_B_C')}
                      </TableCell>
                      {tableData.map((row, index) => (
                        <TableCell sx={{ textAlign: 'right' }}>
                          {(row.mainData.narshkpcount + row.mainData.dsccount + row.mainData.nsccount).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        </TableCell>
                      ))}
                    </TableRow>
                    {(sendData.siteId === '' || sendData.siteId === null) && (sendData.jobNatureId === '' || sendData.jobNatureId === null) && (
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: '15px',
                            wordBreak: 'keep-all',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {t('the_total_number_of_workers_in_the_new_Honky_site_A_B_C_D')}
                        </TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>
                            {(row.mainData.narshkpcount + row.mainData.dsccount + row.mainData.nsccount + row.mainData.narcount).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                          </TableCell>
                        ))}
                      </TableRow>
                    )}
                  </>
                )}
              </Table>

              {/* form2 */}
              <Table size="small" sx={{ mt: '20px' }}>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('workers_using_the_AutoPay_system')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('Number_of_non_participating_autopay_E')}
                  </TableCell>
                  {tableData.map((row, index) => (
                    <TableCell sx={{ textAlign: 'right' }}>
                      {row.mainData.notautopayworkers} (
                      {(row.mainData.notautopayworkers / (row.mainData.autopayworkers + row.mainData.notautopayworkers + row.mainData.exemptionworkers)) * 100
                        ? ((row.mainData.notautopayworkers / (row.mainData.autopayworkers + row.mainData.notautopayworkers + row.mainData.exemptionworkers)) * 100)
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                        : 0}
                      %)
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('number_of_people_participating_in_automatic_transfer_F')}
                  </TableCell>
                  {tableData.map((row, index) => (
                    <TableCell sx={{ textAlign: 'right' }}>
                      {row.mainData.autopayworkers} (
                      {(row.mainData.autopayworkers / (row.mainData.autopayworkers + row.mainData.notautopayworkers + row.mainData.exemptionworkers)) * 100
                        ? ((row.mainData.autopayworkers / (row.mainData.autopayworkers + row.mainData.notautopayworkers + row.mainData.exemptionworkers)) * 100)
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                        : 0}
                      %)
                    </TableCell>
                  ))}
                </TableRow>
                {!sendData.vendorId && (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('number_of_people_including_exemption_from_automatic_transfer_G')}
                      </TableCell>
                      {tableData.map((row, index) => (
                        <TableCell sx={{ textAlign: 'right' }}>
                          {row.mainData.exemptionworkers && row.mainData.exemptionworkers !== 0}(
                          {((row.mainData.exemptionworkers * 100) / (row.mainData.autopayworkers + row.mainData.notautopayworkers + row.mainData.exemptionworkers))
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                          %)
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('number_of_people_including_exemption_from_automatic_transfer_E_F_G')}
                      </TableCell>
                      {tableData.map((row, index) => (
                        <TableCell sx={{ textAlign: 'right' }}>{row.mainData.autopayworkers + row.mainData.notautopayworkers + row.mainData.exemptionworkers}</TableCell>
                      ))}
                    </TableRow>
                  </>
                )}
                {sendData.vendorId && (
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('total_number_of_autopay_E_F')}
                    </TableCell>
                    {tableData.map((row, index) => (
                      <TableCell sx={{ textAlign: 'right' }}>{row.mainData.autopayworkers + row.mainData.notautopayworkers}</TableCell>
                    ))}
                  </TableRow>
                )}
              </Table>

              {/* form3 */}
              <Table size="small" sx={{ mt: '20px' }}>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('actual_working_days_of_workers')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('non_Participating_Autopay_Days_H')}
                  </TableCell>
                  {tableData.map((row, index) => (
                    <TableCell sx={{ textAlign: 'right' }}>
                      {row.mainData.notautopayworkday} (
                      {(row.mainData.notautopayworkday / (row.mainData.notautopayworkday + row.mainData.autopayworkday)) * 100
                        ? ((row.mainData.notautopayworkday / (row.mainData.notautopayworkday + row.mainData.autopayworkday)) * 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                        : 0}
                      %)
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('participate_in_autopay_days_I')}
                  </TableCell>
                  {tableData.map((row, index) => (
                    <TableCell sx={{ textAlign: 'right' }}>
                      {row.mainData.autopayworkday} (
                      {(row.mainData.autopayworkday / (row.mainData.notautopayworkday + row.mainData.autopayworkday)) * 100
                        ? ((row.mainData.autopayworkday / (row.mainData.notautopayworkday + row.mainData.autopayworkday)) * 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                        : 0}
                      %)
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('total_Sun_Hung_Kai_Site_Days_H_I')}
                  </TableCell>
                  {tableData.map((row, index) => (
                    <TableCell sx={{ textAlign: 'right' }}>{row.mainData.autopayworkday + row.mainData.notautopayworkday}</TableCell>
                  ))}
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('sun_Hung_Kai_total_number_of_disk_days_total_system_days')}
                  </TableCell>
                  {tableData.map((row, index) => (
                    <TableCell sx={{ textAlign: 'right' }}>
                      {row.mainData.totalvendorinput} / {row.mainData.totalsystem}(
                      {(row.mainData.totalvendorinput / row.mainData.totalsystem) * 100
                        ? ((row.mainData.totalvendorinput / row.mainData.totalsystem) * 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                        : 0}
                      %)
                    </TableCell>
                  ))}
                </TableRow>
              </Table>

              {/* form4 */}
              <Table size="small" sx={{ mt: '20px' }}>
                {sendData.vendorId && (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('contractor_not_using_autopay_for_worker_count')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('number_of_workers_on_site_that_does_not_use_autopay_A_B_F')}
                      </TableCell>
                      {tableData.map((row, index) => (
                        <TableCell sx={{ textAlign: 'right' }}>{row.mainData.dsccount + row.mainData.nsccount - row.mainData.autopayworkers}</TableCell>
                      ))}
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('number_of_workers_on_sites_that_do_not_use_autopay_excluding_exempt_contractors_A_B_F_G')}
                      </TableCell>
                      {tableData.map((row, index) => (
                        <TableCell sx={{ textAlign: 'right' }}>{row.mainData.dsccount + row.mainData.nsccount - row.mainData.autopayworkers - row.mainData.exemptionworkers}</TableCell>
                      ))}
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('number_of_workers_A_B_E_F_G')}
                      </TableCell>
                      {tableData.map((row, index) => (
                        <TableCell sx={{ textAlign: 'right' }}>
                          {row.mainData.dsccount + row.mainData.nsccount - row.mainData.autopayworkers - row.mainData.exemptionworkers - row.mainData.notautopayworkers}
                        </TableCell>
                      ))}
                    </TableRow>
                  </>
                )}

                {!sendData.vendorId && (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('contractor_not_using_autopay_for_worker_count')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('number_of_workers_on_sites_that_do_not_use_autopay_A_F')}
                      </TableCell>
                      {tableData.map((row, index) => (
                        <TableCell sx={{ textAlign: 'right' }}>
                          {row.mainData.dsccount + row.mainData.nsccount - row.mainData.autopayworkers} (
                          {(((row.mainData.dsccount + row.mainData.nsccount - row.mainData.autopayworkers) / (row.mainData.nsccount + row.mainData.dsccount)) * 100)
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                          %)
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('A_E_F')}
                      </TableCell>
                      {tableData.map((row, index) => (
                        <TableCell sx={{ textAlign: 'right' }}>
                          {row.mainData.dsccount + row.mainData.nsccount - (row.mainData.autopayworkers + row.mainData.notautopayworkers + row.mainData.exemptionworkers)} (
                          {(
                            ((row.mainData.dsccount + row.mainData.nsccount - (row.mainData.autopayworkers + row.mainData.notautopayworkers + row.mainData.exemptionworkers)) /
                              (row.mainData.dsccount + row.mainData.nsccount)) *
                            100
                          )
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                          %)
                        </TableCell>
                      ))}
                    </TableRow>
                  </>
                )}
              </Table>

              {/* form5 */}
              <Table size="small" sx={{ mt: '20px' }}>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('income')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('gross_Worker_Earnings_J')}
                  </TableCell>

                  {tableData.map((row, index) => (
                    <TableCell sx={{ textAlign: 'right' }}>
                      {row.mainData.totalsalary.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') ? row.mainData.totalsalary.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('average_Worker_Earnings_J_I')}
                  </TableCell>
                  {tableData.map((row, index) => (
                    <TableCell sx={{ textAlign: 'right' }}>
                      {(row.mainData.totalsalary / row.mainData.autopayworkday ? row.mainData.totalsalary / row.mainData.autopayworkday : 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                    </TableCell>
                  ))}
                </TableRow>
              </Table>
              <Table size="small" sx={{ mt: '20px' }}>
                {/* form6  新鴻基承判商數目 */}
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('number_of_Sun_Hung_Kai_contractors')}
                  </TableCell>
                </TableRow>
                {(sendData.siteId === '' || sendData.siteId === null) && (sendData.jobNatureId === '' || sendData.jobNatureId === null) && (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('contractors_participating_in_autopay_cumulative_from_11_2003')}
                      </TableCell>
                      {tableData.map((row, index) => (
                        <TableCell sx={{ textAlign: 'right' }}>{row.mainData.totalvendoracc.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('contractors_who_are_exempt_from_autopay_cumulative_from_11_2003')}
                      </TableCell>
                      {tableData.map((row, index) => (
                        <TableCell sx={{ textAlign: 'right' }}>{row.mainData.totalexemvendoracc.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('contractors_participating_in_autopay_monthly_K')}
                      </TableCell>
                      {tableData.map((row, index) => (
                        <TableCell sx={{ textAlign: 'right' }}>{row.mainData.totalvendoreachmonth.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                      ))}
                    </TableRow>
                    {!sendData.vendorId && (
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: '15px',
                            wordBreak: 'keep-all',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {t('contractor_with_autopay_waiver_monthly_L')}
                        </TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.totalexemvendor.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                        ))}
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('non_Participating_Autopay_Contractors_M')}
                      </TableCell>
                      {tableData.map((row, index) => (
                        <TableCell sx={{ textAlign: 'right' }}>
                          {(row.mainData.totalvendor - row.mainData.totalvendoreachmonth - row.mainData.totalexemvendor).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        </TableCell>
                      ))}
                    </TableRow>
                  </>
                )}
                {sendData.vendorId && (
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('total_Sun_Hung_Kai_Contractors_K_M')}
                    </TableCell>
                    {tableData.map((row, index) => (
                      <TableCell sx={{ textAlign: 'right' }}>{row.mainData.totalvendor.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                    ))}
                  </TableRow>
                )}
                {!sendData.vendorId && (
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('total_Sun_Hung_Kai_Contractors_K_L_M')}
                    </TableCell>
                    {tableData.map((row, index) => (
                      <TableCell sx={{ textAlign: 'right' }}>{row.mainData.totalvendor.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                    ))}
                  </TableRow>
                )}
              </Table>

              {sendData.showDiff === '0' && (
                <Table size="small" sx={{ mt: '20px' }}>
                  {/* report1 */}
                  {sendData?.compareReport === '1' && (
                    <>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '15px',
                            wordBreak: 'keep-all',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {t('numerical_differences_between_this_report_and_other_reports_regarding_logarithmic_time')}
                        </TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{sendData.dateToDesc}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_Participants_Pay_Period')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withDifferent1}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_not_Participants_Pay_Period')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withoutDifferent1}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_Participants_Clothes')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withDifferent2}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_not_Participants_Clothes')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withoutDifferent2}</TableCell>
                        ))}
                      </TableRow>
                    </>
                  )}
                  {/* report2 */}
                  {sendData?.compareReport === '2' && (
                    <>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '15px',
                            wordBreak: 'keep-all',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {t('numerical_differences_between_this_report_and_other_reports_regarding_logarithmic_time')}
                        </TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{sendData.dateToDesc}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_Participants_Pay_Period_A')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withDifferent1}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_not_Participants_Pay_Period_A')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withoutDifferent1}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_Participants_Site_A')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withDifferent2}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_not_Participants_Site_A')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withoutDifferent2}</TableCell>
                        ))}
                      </TableRow>
                    </>
                  )}
                  {/* report3 */}
                  {sendData?.compareReport === '3' && (
                    <>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '15px',
                            wordBreak: 'keep-all',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {t('numerical_differences_between_this_report_and_other_reports_regarding_logarithmic_time')}
                        </TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{sendData.dateToDesc}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_Participants_Pay_Period_B')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withDifferent1}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_not_Participants_Pay_Period_B')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withoutDifferent1}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_Participants_Site_B')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withDifferent2}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_not_Participants_Site_B')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withoutDifferent2}</TableCell>
                        ))}
                      </TableRow>
                    </>
                  )}
                  {/* report4 */}
                  {sendData?.compareReport === '4' && (
                    <>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '15px',
                            wordBreak: 'keep-all',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {t('numerical_differences_between_this_report_and_other_reports_regarding_logarithmic_time')}
                        </TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{sendData.dateToDesc}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_Participants_Contractor')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withDifferent1}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_not_Participants_Contractor')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withoutDifferent1}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_Participants_Contractor_Contractor')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withDifferent2}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_not_Participants_Contractor_Contractor')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withoutDifferent2}</TableCell>
                        ))}
                      </TableRow>
                    </>
                  )}

                  {/* report5 */}
                  {sendData?.compareReport === '5' && (
                    <>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '15px',
                            wordBreak: 'keep-all',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {t('numerical_differences_between_this_report_and_other_reports_regarding_logarithmic_time')}
                        </TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{sendData.dateToDesc}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_Participants_Automatic_Transfer')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withDifferent1}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_not_Participants_Automatic_Transfer')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withoutDifferent1}</TableCell>
                        ))}
                      </TableRow>
                    </>
                  )}

                  {/* report6 */}
                  {sendData?.compareReport === '6' && (
                    <>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '15px',
                            wordBreak: 'keep-all',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {t('numerical_differences_between_this_report_and_other_reports_regarding_logarithmic_time')}
                        </TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{sendData.dateToDesc}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_Participants_Salary_Performance')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withDifferent1}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_not_Participants_Salary_Performance')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withoutDifferent1}</TableCell>
                        ))}
                      </TableRow>
                    </>
                  )}

                  {/* report7 */}
                  {sendData?.compareReport === '7' && (
                    <>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '15px',
                            wordBreak: 'keep-all',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {t('numerical_differences_between_this_report_and_other_reports_regarding_logarithmic_time')}
                        </TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{sendData.dateToDesc}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_Participants_Salary_Performance_Current_Month_in_Quarterly')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withDifferent1}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('number_of_not_Participants_Salary_Performance_Current_Month_in_Quarterly')}</TableCell>
                        {tableData.map((row, index) => (
                          <TableCell sx={{ textAlign: 'right' }}>{row.mainData.withoutDifferent1}</TableCell>
                        ))}
                      </TableRow>
                    </>
                  )}
                </Table>
              )}
            </TableContainer>
          </div>
        </Container>
      )}
    </>
  )
}
