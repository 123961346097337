import React, { useRef, useState, useEffect } from 'react'
import { Container, Typography, Paper, Table, TableBody, TableHead, TableCell, TableContainer, Link, TableRow, Backdrop, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import TablePagination from '@mui/material/TablePagination'
import DownloadIcon from '@mui/icons-material/Download'
import moment from 'moment'
import $http, { $download } from 'src/utils/api/services'
import { sub } from 'date-fns'
import { getComparator, stableSort } from '../TableSort'

export default function QtoweafDetail() {
  const componentRef = useRef()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [mainData, setMainData] = React.useState([])
  const [titleData1, setTitleData1] = React.useState({})
  const [titleData2, setTitleData2] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('siteDesc')
  useEffect(() => {}, [])

  const handleBack = () => {
    navigate(-1)
  }

  const printComponentRef = useRef()

  useEffect(() => {
    getInOutRecordList()
  }, [])

  const getInOutRecordList = async () => {
    let list = await apiAtt.getAcssArTerminationSetupList(searchParams.get('applyDate'))

    if (list !== null) {
      setMainData(list.mainData)
      setTitleData1(list.titleData1)
      setTitleData2(list.titleData2)
    }
    setLoading(false)
  }

  const getApprovedDesc = (rejected) => {
    return rejected === '1' ? <label>{t('search_keep_title')}</label> : <label>{t('button_delete')}</label>
  }

  const getDeleteCount = () => {
    return mainData.filter((value) => {
      return value.approved === '1'
    }).length
  }

  const getKeepCount = () => {
    return mainData.filter((value) => {
      return value.rejected === '1'
    }).length
  }

  const getLastDestinMonthDate = (nmonthsWithoutAr) => {
    const myMomentObject = moment(searchParams.get('applyDate'), 'YYYY/MM/DD')

    let t = moment(myMomentObject).subtract(nmonthsWithoutAr, 'months').format('YYYY/MM/DD')
    return t
  }

  const getLastDestinDayDate = (nmonthsWithoutAr) => {
    const myMomentObject = moment(searchParams.get('applyDate'), 'YYYY/MM/DD')

    let t = moment(myMomentObject).subtract(1, 'days').format('YYYY/MM/DD')
    return t
  }

  const exportExcel = (data) => {
    $download(`/attendance/report/getAcssArTerminationSetupListExcel`, 'application/vnd.ms-excel', data)
  }

  const exportPDF = (data) => {
    $download(`/attendance/report/getAcssArTerminationSetupListPdf`, 'application/pdf', data)
  }

  // 获取日期列表最大的日期
  const getMaxDate = () => {
    let dateList = []
    mainData.map((value) => {
      dateList.push(value.effectiveDate)
      return []
    })
    let moments = dateList.map((d) => moment(d))

    return moment.max(moments).format('YYYY/MM/DD')
  }

  function clickCell(index) {
    switch (index) {
      case 1:
        setOrderBy('siteDesc')
        break
      case 2:
        setOrderBy('subContractorDesc')
        break
      case 3:
        setOrderBy('jobNatureDesc')
        break
      case 4:
        setOrderBy('smartCardId')
        break
      case 5:
        setOrderBy('chineseName')
        break
      case 6:
        setOrderBy('englishName')
        break
      case 7:
        setOrderBy('effectiveDate')
        break
      case 8:
        setOrderBy('rejected')
        break
      default:
        setOrderBy('siteDesc')
        break
    }

    if (order === 'asc') {
      setOrder('desc')
    } else {
      setOrder('asc')
    }
  }

  return (
    <Container maxWidth="false">
      <div style={{ display: 'block', textAlign: 'left' }}>
        <Button sx={{ float: 'left' }} onClick={handleBack}>
          <ArrowBackIcon />
          {t('button_back')}
        </Button>
      </div>

      <div style={{ display: 'block', textAlign: 'right' }}>
        <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportExcel({ applicationDate: searchParams.get('applyDate') })}>
          <DownloadIcon />
          {t('button_export_excel')}
        </Button>
        <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportPDF({ applicationDate: searchParams.get('applyDate') })}>
          <DownloadIcon />
          {t('button_export_pdf')}
        </Button>
      </div>

      <div id="div" style={{}} ref={printComponentRef}>
        <Container maxWidth="false">
          <div>
            <div style={{ display: 'block', marginBottom: '60px' }}>
              <Typography variant="h4" sx={{ textAlign: 'center' }}>
                <b>{t('search_check_teminateApplayCase_title')}</b>
              </Typography>
            </div>

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>

            {
              <div>
                <TableContainer component={Paper} sx={{ marginBottom: '20px', backgroundColor: 'transparent' }}>
                  <Table sx={{ textAlign: 'left' }} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('search_apply_date_dot') + searchParams.get('applyDate')}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('search_vendor_desc') + titleData1.vendorDesc}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('search_suggest_teminate_siteDate') + titleData1.proposedEffectiveEndDate}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('search_apply_submit_user') + titleData1.submittedBy}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>
                          {t('search_apply_submit_date') + titleData1.submissionDate + t('search_apply_list_remark') + titleData2.monthlyCutOffDay + t('search_day_aotuo_submit')}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>
                          {t('search_teminate_title') +
                            titleData2.nmonthsWithoutAr +
                            t('search_in_month') +
                            getLastDestinMonthDate(titleData2.nmonthsWithoutAr) +
                            t('search_to_tips') +
                            getLastDestinDayDate(titleData2.nmonthsWithoutAr) +
                            t('search_noPermit_to_site')}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>
                          {t('search_totals_title') + mainData.length + t('search_record_desc_title') + getDeleteCount() + t('search_delete_desc') + getKeepCount() + t('search_itemKeep_desc')}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }
          </div>

          {/* table */}
          <TableContainer sx={{ marginTop: '20px', maxHeight: '80vh', maxWidth: '100vw' }}>
            <Table sx={{ p: '2px' }} size="small" stickyHeader>
              <TableHead>
                <TableRow hover>
                  {/* (siteName, carid, engName, chName, joy, approveDate, inDate, inVateDate, applyDate */}
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link style={{ cursor: 'pointer' }} underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(1)}>
                      {t('search_site_title')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link style={{ cursor: 'pointer' }} underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(2)}>
                      {t('Subcontractors')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link style={{ cursor: 'pointer' }} underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(3)}>
                      {t('search_work_type')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link style={{ cursor: 'pointer' }} underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(4)}>
                      {t('search_smartcardId')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link style={{ cursor: 'pointer' }} underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(5)}>
                      {t('search_chinese_not_dot')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link style={{ cursor: 'pointer' }} underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(6)}>
                      {t('search_english_not_dot')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link style={{ cursor: 'pointer' }} underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(7)}>
                      {t('search_effectiveDate')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link style={{ cursor: 'pointer' }} underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(8)}>
                      {t('search_keepOrDelete')}
                    </Link>
                  </TableCell>
                </TableRow>
              </TableHead>

              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>

              {stableSort(mainData, getComparator(order, orderBy)).map((mainDataRow, index) => (
                <>
                  {/* 單個人數據，需要循環遍歷 */}
                  <TableRow
                    hover
                    key={mainDataRow + Math.random}
                    sx={{
                      backgroundColor: index % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                    }}
                  >
                    {/* 地盤 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainDataRow.siteDesc} </TableCell>
                    {/* 分判商 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainDataRow.subContractorDesc} </TableCell>
                    {/* 工作類別 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainDataRow.jobNatureDesc} </TableCell>
                    {/* 智能卡編號 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainDataRow.smartCardId} </TableCell>
                    {/* 中文姓名 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainDataRow.chineseName} </TableCell>
                    {/* 英文姓名 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainDataRow.englishName} </TableCell>
                    {/* 生效日期 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainDataRow.effectiveDate} </TableCell>
                    {/* 刪除/保留 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{getApprovedDesc(mainDataRow.rejected)} </TableCell>
                  </TableRow>
                </>
              ))}
            </Table>
          </TableContainer>
        </Container>
      </div>
    </Container>
  )
}
