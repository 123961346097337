import React, { useEffect } from 'react'
import axios from "axios";
import $http from 'src/utils/api/services'

export default function RedirectLogin() {

  useEffect(async () => {

    const link = await axios.post(`${process.env.REACT_APP_WEB_URL}/oauth/anonymous-login`, {}, {});
    // const link = await $http('/oauth/anonymous-login', {});
    window.location.href = link
  }, [])

  return (
    <>
    </>
  );
}
