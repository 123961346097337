import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  FormControl,
  MenuItem,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InputDropDown from "src/components/use-form/InputDropDown";
import { useForm } from "react-hook-form";
import { useNavigate, createSearchParams } from "react-router-dom";
import * as apiAtt from "src/utils/api/workerAttendanceRecord/AutoPayApiAttend";
import CustomDialog from "src/components/common/CustomDialog";

export default function ReportMonthsSearch() {
  const navigate = useNavigate();
  const componentRef = useRef();
  const { t } = useTranslation();
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [siteId, setSiteId] = useState("");
  const [siteDesc, setSiteDesc] = useState(null);
  const handleBack = () => {
    navigate(-1);
  };

  const [dialog, setDialog] = useState({
    title: "",
    content: "",
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false });
        }}
      >
        {t("Confirm")}
      </Button>
    ),
    open: false,
  });

  const showDetailResult = () => {
    if (!siteId) {
      setDialog({
        ...dialog,
        content: t("Please_select_a_site"),
        open: true,
      });
      return;
    }
    if (!dateFrom) {
      setDialog({
        ...dialog,
        content: t("search_chooseDatetips")+"("+t("search_from_tips")+")",
        open: true,
      });
      return;
    }
    if (!dateTo) {
      setDialog({
        ...dialog,
        content: t("search_chooseDatetips")+"("+t("search_to_tips")+")",
        open: true,
      });
      return;
    }

    if(dateFrom>dateTo){
      setDialog({
        ...dialog,
        content: t("Date_to_cannot_be_less_than_date_by"),
        open: true,
      });
      return;
    }

    navigate({
      pathname:
        "/workerAttendanceRecord/adocspMain/reportMonthsSearch/reportMonthsSearchResult",
      // isViewData 为1的时候表示是查看数据 为0的时候表示编辑数据
      // 這裡只能觀看，所以設置為1
      search: `?${createSearchParams({
        siteId: siteId,
        siteDesc: siteDesc,
        dateFrom: dateFrom,
        dateTo: dateTo,
      })}`,
    });
  };
  


  const { control, handleSubmit, getValues, reset } = useForm({
    defaultValues: {
      dateFrom: "",
      dateTo: "",
    },
  });

  const resetForm = () => {
    setDateFrom("");
    setDateTo("");
    setSiteId("");
    setSiteDesc("");
  };

  const [siteList, setSiteList] = useState([]);

  // 选了地盘后，需要请求分判商
  const handleSiteId = (data) => {
    setSiteId(data.id);
    setSiteDesc(data.text);
  };

  useEffect(() => {
    const getFillSite = async () => {
      let getFillSiteSelect = await apiAtt.getFillSite();
      if (getFillSiteSelect) {
        const result = getFillSiteSelect;
        setSiteList(result);
      }
    };

    getFillSite();
  }, []);

  return (
    <>
      <div style={{ display: "block", textAlign: "left" }}>
        <Button sx={{ float: "left" }} onClick={handleBack}>
          <ArrowBackIcon />
          {t("button_back")}
        </Button>
      </div>
      <div id="div" ref={componentRef}>
        <Container>
          <div>
            <div style={{ display: "block" }}>
              <Typography
                variant="h4"
                style={{ color: "black", textAlign: "center" }}
              >
                <b>{t("search_vendor_malfcafsss_person")}</b>
              </Typography>
              <Container
                sx={{ width: "500px", borderColor: "#d3d3d3", borderRadius: 4 }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{ margin: "auto", width: "400px", marginTop: "10px" }}
                >
                  <TextField
                    id="siteDesc"
                    value={siteId || ""}
                    fullWidth
                    size="small"
                    label={t("Site_Name")}
                    error={!siteId}
                    select
                  >
                    {siteList.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.id}
                        name={item.text}
                        onClick={() => handleSiteId(item)}
                      >
                        {item.id} - {item.text}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={6} sx={{ margin: "auto", width: "400px" }}>
                  <TextField
                    type="month"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    sx={{ textAlign: "left", maxWidth: "400px", mt: "20px" }}
                    label={t("search_date_from_noDot")}
                    error={!dateFrom}
                    inputProps={{
                      max: "2099-12",
                    }}
                    value={dateFrom}
                    onChange={(event) => {
                      setDateFrom(event.target.value);
                    }}
                    format="yyyy/MM"
                  />
                </Grid>

                <Grid item xs={6} sx={{ margin: "auto", width: "400px" }}>
                  <TextField
                    type="month"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    sx={{ textAlign: "left", maxWidth: "400px", mt: "20px" }}
                    label={t("search_date_to_noDot")}
                    error={!dateTo}
                    inputProps={{
                      max: "2099-12",
                    }}
                    value={dateTo}
                    onChange={(event) => {
                      setDateTo(event.target.value);
                    }}
                    format="yyyy/MM"
                  />
                </Grid>
              </Container>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            {/* 提交按钮 */}
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <FormControl size="small">
                <Button
                  sx={{
                    backgroundColor: "primaryBlue",
                    color: "commom.white",
                  }}
                  variant="contained"
                  onClick={handleSubmit(showDetailResult)}
                >
                  {t("button_submit")}
                </Button>
              </FormControl>

              {/* 重置按钮 */}
              <FormControl size="small">
                <Button
                  variant="contained"
                  onClick={resetForm}
                  label="Reset"
                  sx={{
                    backgroundColor: "primaryGreen",
                    color: "commom.white",
                    marginLeft: "20px",
                  }}
                >
                  {t("button_reset")}
                </Button>
              </FormControl>
            </Grid>
          </div>
        </Container>
      </div>

      <CustomDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        action={dialog.action}
      />
    </>
  );
}
