import moment from 'moment'
import { instance } from '../instance'

// getTrade 行頭
export const getJobNatureList = async () => {
  return instance({
    method: 'GET',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getfilljobNatureId`,
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}
// get Date month  日期由
export const getDateMonthList = async () => {
  return instance({
    method: 'GET',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getfillDate`,
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}

// 工人薪金及強積金及安全人員狀況分析 承判商
export const getVendorList = async () => {
  return instance({
    method: 'GET',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getVendorId2`,
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}

// 工人薪金及強積金及安全人員狀況分析 日期
export const getDateYearMonth = async () => {
  return instance({
    method: 'GET',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getDateFrom`,
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}

// 工人薪金及強積金及安全人員狀況分析 地盤
export const getSiteList = async () => {
  return instance({
    method: 'GET',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getSite2`,
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}

// 工人薪金分析(以地盤分類)-A 地盤
export const getFillSite = async () => {
  return instance({
    method: 'GET',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getFillSite`,
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}

// 工人薪金分析(以地盤分類)-B 地盤
export const getTeamBSite = async () => {
  return instance({
    method: 'GET',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getSiteAndTeam`,
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}

// 获取token refresh_token 过期时间戳
export const getRefreshTokenTime = async (data) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/oauth/getRefreshTokenTime`,
    data: data,
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}

/**
 *
 * @param {檢索條件對象} data
 * @param {URL相對路徑} relativePath
 * @param {文件類型} fileType
 * @param {文件名} fileName
 * @returns
 */
export const exportFile = async (data, relativePath, fileType, fileName) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}${relativePath}`,
    data: data,
    responseType: 'arraybuffer',
  }).then(
    (result) => {
      let url = window.URL.createObjectURL(new Blob([result.data]))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      const documentName = !fileName ? moment(new Date().getTime()).format('yyyyMMDDHHmmssSSS') : fileName + moment(new Date().getTime()).format('yyyyMMDDHHmmssSSS')
      link.setAttribute('download', `${documentName}.${fileType}`)
      document.body.appendChild(link)
      link.click()
      return result.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}
