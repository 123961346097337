import { instance } from "../../instance";

// 获取行头
export const getfilljobNatureId = async () => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/vps/common/getfilljobNatureId`,
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

// 承判商名称
export const getVendorIdList = async () => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/vps/common/getfillVendorId`,
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};
// 年份
export const getPeriodYear = async () => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/vps/common/getPeriodYear`,
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};





// get Report  详细记录
export const getRPTworkersautopaystatusvendorlist = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/getRPTworkersautopaystatusvendorlist`,
        data: data
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};


// get Report 一覽表
export const getRPTworkersautopaystatusvendorlistwholemonth = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/getRPTworkersautopaystatusvendorlistwholemonth`,
        data: data
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};












