import React, { useRef, useEffect } from 'react';
import {
  Button,
  Container,
  Paper,
  Typography,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow, Backdrop, CircularProgress
} from '@mui/material';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import $http, { $download } from 'src/utils/api/services'
import DownloadIcon from "@mui/icons-material/Download";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function ReportMonthsSearchResult() {
  const componentRef = useRef()
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);

  const handleBack = () => {
    navigate(-1)
  }

  const [monthList, setMonthList] = React.useState([]);
  const [mainData, setMainData] = React.useState([]);
  const [subData, setSubData] = React.useState([]);
  const [vendorDesc, setvendorDesc] = React.useState("");

  useEffect(() => {
    const getMonthlyApprovedDay = async () => {

      let dict = await apiAtt.getMonthlyApprovedDay(
        searchParams.get("siteId"),
        searchParams.get("dateFrom"),
        searchParams.get("dateTo"))
      if (dict !== null) {
        // setContentList(Object.values(dict))
        console.log('mainData==', dict.mainDta)
        setMainData(dict.mainDta)
        setSubData(dict.subData)
        setvendorDesc(dict.mainDta.length === 0 ? "" : dict.mainDta[0].monthlyApprovedDayVoList[0].vendorDesc)
      }
      getMonth()
    }
    getMonthlyApprovedDay()
  }, [])

  const getMonth = () => {
    let startTime = dayjs(searchParams.get("dateFrom")).format('YYYY-MM');
    let endTime = dayjs(searchParams.get("dateTo")).format('YYYY-MM');

    let arrLen = Math.trunc((Date.parse(endTime) - Date.parse(startTime)) / 86400e3)
    let arr = Array.from({ length: arrLen + 1 }, (v, i) => {
      return new Date(Date.parse(startTime) + i * 86400e3).toISOString().substring(0, 7).replace('-', '')
    })
    setMonthList([...new Set(arr)])
    setLoading(false)
  }

  const getMandayDesc = (month, personData) => {
    let monthString = ''
    let handleMonth = ''
    monthString = month
    // 比较handleMonth与personData里面的month
    handleMonth = monthString.replace('-', '')
    let backMandy = '0.00'
    personData.map((value) => {

      if (value.yearmonth === handleMonth) {
        backMandy = value.monthlyDay
      }
      return []
    })

    return backMandy
  }

  const showDetailResult = (personData,yearmonth, smartCardId, vendorDesc) => {
    navigate({
      pathname:
        "/workerAttendanceRecord/adocsp/reportMonths/ReportMonthsDetailView",
      // isViewData 为1的时候表示是查看数据 为0的时候表示编辑数据
      // 這裡只能觀看，所以設置為1
      search: `?${createSearchParams({
        safetyPositionDesc:personData.safetyPositionDesc,
        engName:getEnglistName(personData.monthlyApprovedDayVoList),
        chName:getChName(personData.monthlyApprovedDayVoList),
        siteId: searchParams.get("siteId"),
        siteDesc: searchParams.get("siteDesc"),
        dateTo: searchParams.get("dateTo"),
        dateFrom: searchParams.get("dateFrom"),
        vendorDesc: vendorDesc[0].vendorDesc,
        vendorId: vendorDesc[0].vendorId,
        yearmonth: yearmonth,
        smartCardId: smartCardId,
      })}`,
    });
  }



  const getEnglistName = (personData) => {
    let engName = ''

    personData.map((value) => {
      engName = value.englishName
      return []
    })

    return engName
  }

  const getChName = (personData) => {
    let chName = '-'

    personData.map((value) => {
      chName = value.chineseName

      return []
    })

    return chName
  }

  // const exportExcel = (data) => {
  //   $download(`/attendance/report/getMonthlyApprovedDayExcel`, 'application/vnd.ms-excel', data)
  // };
  // const exportPDF = (data) => {
  //   $download(`/attendance/report/getMonthlyApprovedDayPdf`, 'application/pdf', data)
  // };


  const getTotalCount = (personData) => {
    let total = 0

    personData.map((value) => {
      total += parseFloat(value.monthlyDay)

      return []
    })

    return total.toFixed(2)
  }

  return (
    <>
      <div style={{ display: 'block', textAlign: 'left', }}>
        <Button sx={{ float: 'left' }} onClick={handleBack}><ArrowBackIcon />{t('button_back')}</Button>
      </div>
      {/* {       <div style={{ display: 'block', textAlign: 'right' }}>
        <Button sx={{ float: "right", mr: "20px" }} onClick={() => exportExcel(
          {
            "siteId": searchParams.get("siteId"),
            "siteDesc": searchParams.get("siteDesc"),  
            "dateFrom": searchParams.get("dateFrom"),
            "dateTo": searchParams.get("dateTo")
          }
        )}>
          <DownloadIcon />
          {t("button_export_excel")}
        </Button>
        <Button sx={{ float: "right", mr: "20px" }} onClick={() => exportPDF(
          {
            "siteId": searchParams.get("siteId"),
            "siteDesc": searchParams.get("siteDesc"),  
            "dateFrom": searchParams.get("dateFrom"),
            "dateTo": searchParams.get("dateTo")
      
          }
        )}>
          <DownloadIcon />
          {t("button_export_pdf")}
        </Button>
      </div> } */}

      <div id='div' ref={componentRef} style={{ textAlign: 'cteter', }}>
        <Container maxWidth="false" >

          <div>
            <div style={{ display: 'block', marginBottom: '60px' }}>
              <Typography variant='h4' sx={{ textAlign: 'center' }}>
                <b>{t("search_vendor_malfcafsss_person")}</b></Typography>
            </div>

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            {mainData.length > 0 && (<div  >
              <TableContainer sx={{ marginTop: '30px', }}>
                <Table sx={{ minWidth: 1000, p: '2px', justifyContent: 'center' }} size="small" scroll={{ x: true }} >
                  <TableBody>
                    <TableRow    >
                      <TableCell sx={{ fontSize: '15px', px: '5px', width: 80, textAlign: 'left' }}>{t("search_site_title") + '：'}	</TableCell>
                      <TableCell sx={{ fontSize: '15px', px: '5px', textAlign: 'left' }}>{searchParams.get("siteDesc") === null ? 'All' : searchParams.get("siteDesc")}</TableCell>
                    </TableRow>
                    <TableRow   sx={{ marginBottom:'20px'}} >
                      <TableCell sx={{ fontSize: '15px', px: '5px', width: 80, textAlign: 'left' }}>{t("Contractor") + '：'}</TableCell>
                      <TableCell sx={{ fontSize: '15px', px: '5px', textAlign: 'left' }}>{vendorDesc === null ? 'All' : vendorDesc}</TableCell>
                    </TableRow>
                    <TableRow >
                      <TableCell sx={{ border:'0' }}> </TableCell>
                    </TableRow>

                    <TableRow sx={{ minHeight: 50 }}>
                      <TableCell sx={{ fontSize: '15px', px: '5px', whiteSpace: "nowrap", textAlign: 'left' }}>{t("search_employment_position")}</TableCell>
                      {mainData.map((personData, index) => (

                        <TableCell key={index + Math.random} sx={{ fontSize: '15px', px: '5px', textAlign: 'center' }}>{personData.safetyPositionDesc}  </TableCell>

                      ))}
                    </TableRow>



                    <TableRow  >
                      <TableCell sx={{ whiteSpace: "nowrap", fontSize: '15px', px: '5px', textAlign: 'left' }}>{t("search_english_name_dot")}</TableCell>
                      {mainData.map((personData, index) => (

                        <TableCell key={index + Math.random} sx={{ whiteSpace: "nowrap", fontSize: '15px', textAlign: 'center' }}>{getEnglistName(personData.monthlyApprovedDayVoList)}  </TableCell>

                      ))}
                    </TableRow>

                    <TableRow sx={{}} >
                      <TableCell sx={{ whiteSpace: "nowrap", fontSize: '15px', px: '5px', textAlign: 'left' }}>{t("search_chinese_name_dot")}</TableCell>
                      {mainData.map((personData, index) => (

                        <TableCell key={index + Math.random} sx={{ whiteSpace: "nowrap", fontSize: '15px', textAlign: 'center' }}>{getChName(personData.monthlyApprovedDayVoList)}  </TableCell>

                      ))}
                    </TableRow>

                    <TableRow sx={{}} >
                      <TableCell sx={{ whiteSpace: "nowrap", fontSize: '15px', px: '5px', textAlign: 'left' }}>{t("search_smartCardId_dot")}</TableCell>
                      {mainData.map((personData, index) => (
                        <TableCell key={index + Math.random} sx={{ whiteSpace: "nowrap", fontSize: '15px', textAlign: 'center' }}>{personData.smartCardId}  </TableCell>
                      ))}
                    </TableRow>

                    <TableRow   >
                      <TableCell sx={{ whiteSpace: "nowrap", fontSize: '18px', textAlign: 'left' }}  ><b>{t("search_inOut_date")}</b></TableCell>
                      {mainData.map((personData, index) => (
                        <TableCell key={index + Math.random} sx={{ whiteSpace: "nowrap", fontSize: '18px', textAlign: 'center' }}><b>{t("search_approve_day")}</b></TableCell>
                      ))}
                    </TableRow>

                    {monthList.map((month, index) => (
                      <TableRow hover key={index + Math.random}>
                        <TableCell sx={{
                          fontSize: '15px', px: '5px',
                          textAlign: 'center',
                        }}>{month} </TableCell>
                        {mainData.map((personData, index) => (
                          <TableCell
                            key={index + Math.random}
                            sx={{
                              fontSize: '15px',
                              px: '5px',
                              textAlign: 'center',

                            }}
                          >{getMandayDesc(month, personData.monthlyApprovedDayVoList) === "0.00" ? getMandayDesc(month, personData.monthlyApprovedDayVoList) : <Link style={{cursor:"pointer"}} onClick={() => showDetailResult(personData,month, personData.smartCardId, personData.monthlyApprovedDayVoList)}>{getMandayDesc(month, personData.monthlyApprovedDayVoList)}</Link>} </TableCell>
                        ))}
                      </TableRow>
                    ))}
                    <TableRow   >
                      <TableCell sx={{ textAlign: 'center' }}><b>{t("total")}</b></TableCell>
                      {mainData.map((personData, index) => (
                        <TableCell key={index + Math.random} sx={{ textAlign: 'center' }}><b>{getTotalCount(personData.monthlyApprovedDayVoList)}</b></TableCell>

                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>)}

            {mainData.length === 0 && (<div>
              <TableContainer
                component={Paper}
                sx={{ marginBottom: "30px", backgroundColor: "transparent" }}
              >
                <Table sx={{ textAlign: "left" }} size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 80,
                          border: 0,
                        }}
                      >
                        {t("search_site_title_dot")}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          border: 0,
                          textAlign: "left",
                        }}
                      >
                        {searchParams.get("siteDesc")}
                      </TableCell>
                    </TableRow>
                    <TableRow    >
                      <TableCell sx={{ fontSize: '15px', px: '5px', width: 160, border: 0 }}>
                        Total: </TableCell>
                      <TableCell sx={{ fontSize: '15px', px: '5px', border: 0 }}>
                        {mainData.length} Record(s)</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>)}




            {/* 底部控件 */}
            <div  >
              <TableContainer component={Paper} sx={{ marginTop: '30px', backgroundColor: 'transparent' }}>
                <Table sx={{ textAlign: 'left' }} size="small"  >

                  <TableBody>
                    {subData.map((sub, index) => (
                      <TableRow key={index + Math.random + 100} >
                        <TableCell sx={{ fontSize: '15px', px: '5px', width: 300, }}>{sub} </TableCell>
                      </TableRow>
                    ))}

                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Container>
      </div>

    </>
  )
}

