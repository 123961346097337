import { useState } from 'react'
import { Box, Card, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CDialog, confirm } from 'src/components/common'

export default function CardItem({ elementItem, color = 'primary', sx, ...other }) {
  const { t, i18n } = useTranslation()
  const handleCard = (elementItem) => {
    // return;
    if (elementItem.isMaintain) {
      confirm({
        title: t('tip'),
        content: t('maintaing', { startTime: elementItem.maintainStartTime, endTime: elementItem.maintainEndTime }),
        okText: t('Close'),
      })
      return
    }
    // 判断打开link的方式
    if (elementItem.target) {
      window.open(elementItem.target, '_blank')
    } else {
      if (elementItem.path?.includes('http://') || elementItem.path?.includes('https://')) {
        window.location.href = elementItem.path
      } else {
        window.location.href = '/newsmps' + elementItem.path
      }
    }
  }

  return (
    <Link style={{ textDecoration: 'none', display: 'inline-block' }} underline="hover" component="button" onClick={() => handleCard(elementItem)}>
      <Card
        sx={{
          mx: '10px',
          width: '165px',
          height: '148.5px',
          py: 3,
          my: 1,
          boxShadow: 0,
          textAlign: 'center',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: '#fff',
          // color: (theme) => theme.palette[elementItem.color].darker,
          background: 'linear-gradient(180deg, #059aff, #007dd1);',
          // bgcolor: (theme) => theme.palette[elementItem.color].lighter,
          '&:hover': { transform: 'scale3d(1.1, 1.1, 1)', bgcolor: (theme) => (theme.palette[elementItem.color]?.light ? theme.palette[elementItem.color].light : '#AAF27F') },
          ...sx,
        }}
        {...other}
      >
        {/* <CardActionArea
                 onClick={handleCard}
             > */}
        <Box
          component={elementItem.icon}
          sx={{
            width: '50px',
            height: '50px',
          }}
        />
        <Typography
          sx={{
            color: '#fff',
            // opacity: 0.72,
            mt: '15px',
            fontSize: '15px',
          }}
        >
          {i18n.language === 'zh-CN' ? elementItem.menuDescCn : elementItem.menuDescEn}
        </Typography>
        {/* <Typography
          sx={{
            color: '#fff',
            // opacity: 0.72,
            fontSize: '15px',
          }}
        >
          {elementItem.menuDescEn}
        </Typography> */}
        {/* </CardActionArea > */}
      </Card>
    </Link>
  )
}
