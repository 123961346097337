

function CTableExpandable(props) {
    const { label, value, render } = props

    return (
        <div style={{ marginBottom: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <div style={{ marginBottom: '8px', fontSize: '15px', overflow: 'hidden', fontWeight: 600, color: '#2196F3', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} title={label + ''}>
                {label}：
            </div>
            {render || <div title={value + ''}>{value}</div>}
        </div>
    )
}

export default CTableExpandable
