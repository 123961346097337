import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  FormControl,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InputDropDown from "src/components/use-form/InputDropDown";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import * as apiAtt from "src/utils/api/workerAttendanceRecord/AutoPayApiAttend";
import CustomDialog from "src/components/common/CustomDialog";
import SearchChoosePerson from "../SearchChoosePerson";

export default function ReportDetailSearch() {
  const navigate = useNavigate();
  const componentRef = useRef();
  const { t } = useTranslation();
  const [openChoosePerson, setOpenChoosePerson] = React.useState(false);
  const [siteList, setSiteList] = useState([]);
  const [superHolderId, setSuperHolder] = useState("");
  const [siteId, setSiteId] = useState("");
  const [siteDesc, setSiteDesc] = useState("");
  const [shortPeriodDateFrom, setShortPeriodDateFrom] = useState("");
  const [shortPeriodDateTo, setShortPeriodDateTo] = useState("");
  const [checkAllRecord, setCheckAllRecord] = useState(true);
  const handleChoosePersonClose = (e) => {
    setOpenChoosePerson(false);
  };
  const [dialog, setDialog] = useState({
    title: "",
    content: "",
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false });
        }}
      >
        {t("Confirm")}
      </Button>
    ),
    open: false,
  });

  const showChoosePeron = (e) => {
    setOpenChoosePerson(true);
  };

  const handleSiteId = (data) => {
    setSiteId(data.id);
    setSiteDesc(data.text);
  };

  const showOpenDetail = (e) => {
    // if (!siteId) {
    //   alert(t("search_chooseSite_tips"));
    //   return;
    // }
    console.log("e", siteId);

    if (!siteId) {
      setDialog({
        ...dialog,
        content: t("Please_select_a_site"),
        open: true,
      });
      return;
    }
  if(!checkAllRecord){
  if (!shortPeriodDateFrom) {
    setDialog({
      ...dialog,
      content: t("search_chooseApproveDateFrom_tips"),
      open: true,
    });
    return;
  }
  if (!shortPeriodDateTo) {
    setDialog({
      ...dialog,
      content: t("search_chooseApproveDateTo_tips"),
      open: true,
    });
    return;
  }
  if(shortPeriodDateFrom>shortPeriodDateTo){
    setDialog({
      ...dialog,
      content: t("Date_to_cannot_be_less_than_date_by"),
      open: true,
    });
    return;
  }
}

    navigate({
      pathname:
        "/workerAttendanceRecord/adocspMain/reportDetailSearch/ReportDetailSearchResult",
      // isViewData 为1的时候表示是查看数据 为0的时候表示编辑数据
      // 這裡只能觀看，所以設置為1
      search: `?${createSearchParams({
        holderId: superHolderId,
        siteId: siteId,
        siteDesc: siteDesc,
        dateFrom: checkAllRecord?null:shortPeriodDateFrom,
        dateTo: checkAllRecord?null:shortPeriodDateTo,
        cd:checkAllRecord?"A":"D",
      })}`,
    });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const { control, handleSubmit, setValue, getValues, reset } = useForm({
    defaultValues: {
      dateFrom: "",
      dateTo: "",
    },
  });

  const resetForm = (data) => {
    setSiteId("");
    setSiteDesc("");
    setSuperHolder("");
    setShortPeriodDateFrom("");
    setShortPeriodDateTo("");
  };

  useEffect(() => {
    const getFillSite = async () => {
      let getFillSiteSelect = await apiAtt.getFillSite();
      if (getFillSiteSelect) {
        const result = getFillSiteSelect;

        setSiteList(result);
      }
    };

    getFillSite();
  }, [superHolderId]);

  return (
    <>
      <div style={{ display: "block", textAlign: "left" }}>
        <Button sx={{ float: "left" }} onClick={handleBack}>
          <ArrowBackIcon />
          {t("button_back")}
        </Button>
      </div>
      <div id="div" ref={componentRef} style={{}}>
        <Container>
          <div>
            <div style={{ display: "block" }}>
              <Typography
                variant="h4"
                style={{ color: "black", textAlign: "center" }}
              >
                <b>{t("search_vendor_ss_detail_record")}</b>
              </Typography>
              <Container
                sx={{ width: "500px", borderColor: "#d3d3d3", borderRadius: 4 }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{ margin: "auto", width: "400px", marginTop: "10px" }}
                >
                  <TextField
                    id="siteDesc"
                    value={siteId || ""}
                    error={!siteId}
                    fullWidth
                    size="small"
                    label={t("Site_Name")}
                    select
                  >
                    {siteList.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.id}
                        name={item.text}
                        onClick={() => handleSiteId(item)}
                      >
                      {item.id} - {item.text}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* 智能卡編號 */}
                <Grid
                  item
                  xs={12}
                  sx={{ margin: "auto", width: "400px", marginTop: "20px" }}
                >
                  <TextField
                    label={t("search_smartcardId")}
                    type="number"
                    placeholder="First 9 digits"
                    fullWidth
                    value={superHolderId}
                    onChange={(event) => {
                      setSuperHolder(event.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button onClick={showChoosePeron} variant="outlined">
                            Choose
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <TableContainer sx={{ width: "100%", margin: "auto" }}>
                  <Table sx={{ textAlign: "left" }} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "17px",
                            px: "25px",
                            border: 0,
                            width: "25%",
                            height: "50px",
                            padding: 0,
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {t("search_all_records")}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "15px",
                            px: "5px",
                            border: 0,
                            padding: 0,
                          }}
                        >
                          <Checkbox checked={checkAllRecord} onChange={(event) => {
                            setCheckAllRecord(event.target.checked);
                          }} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {!checkAllRecord && (
                  <>
                    <Grid item xs={6} sx={{ margin: "auto", width: "400px" }}>
                      <TextField
                        type="month"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        sx={{
                          textAlign: "left",
                          maxWidth: "400px",
                          mt: "20px",
                        }}
                        label={t("search_approve_date_from")}
                        error={!shortPeriodDateFrom}
                        inputProps={{
                          max: "2099-12",
                        }}
                        value={shortPeriodDateFrom}
                        onChange={(event) => {
                          setShortPeriodDateFrom(event.target.value);
                        }}
                        format="yyyy/MM"
                      />
                    </Grid>

                    <Grid item xs={6} sx={{ margin: "auto", width: "400px" }}>
                      <TextField
                        type="month"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        sx={{
                          textAlign: "left",
                          maxWidth: "400px",
                          mt: "20px",
                        }}
                        label={t("search_approve_date_to")}
                        error={!shortPeriodDateTo}
                        inputProps={{
                          max: "2099-12",
                        }}
                        value={shortPeriodDateTo}
                        onChange={(event) => {
                          setShortPeriodDateTo(event.target.value);
                        }}
                        format="yyyy/MM"
                      />
                    </Grid>
                  </>
                )}
              </Container>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            {/* 提交按钮 */}
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <FormControl size="small">
                <Button
                  sx={{
                    backgroundColor: "primaryBlue",
                    color: "commom.white",
                  }}
                  variant="contained"
                  onClick={handleSubmit(showOpenDetail)}
                >
                  {t("button_submit")}
                </Button>
              </FormControl>

              {/* 重置按钮 */}
              <FormControl size="small">
                <Button
                  variant="contained"
                  onClick={resetForm}
                  label="Reset"
                  sx={{
                    backgroundColor: "primaryGreen",
                    color: "commom.white",
                    marginLeft: "20px",
                  }}
                >
                  {t("button_reset")}
                </Button>
              </FormControl>
            </Grid>
          </div>
        </Container>
      </div>

      <Dialog
        fullScreen
        onClose={handleChoosePersonClose}
        open={openChoosePerson}
      >
        <SearchChoosePerson
          setOpenChoosePerson={setOpenChoosePerson}
          setSuperHolder={setSuperHolder}
        >
          3
        </SearchChoosePerson>
      </Dialog>
      <CustomDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        action={dialog.action}
      />
    </>
  );
}
