import React, { useRef, useEffect } from 'react'
import { Container, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Backdrop, CircularProgress } from '@mui/material'
import html2canvas from 'html2canvas'
import { useTranslation } from 'react-i18next'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import DownloadIcon from '@mui/icons-material/Download'
import $http, { $download } from 'src/utils/api/services'
import moment from 'moment'

// 工人出入記錄一覽表---
export default function LoeaerIncomeDetail() {
  const componentRef = useRef()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [mainData, setMainData] = React.useState([])
  const [dataSource, setDataSource] = React.useState([])
  // 記錄的days天 格式為20
  const [recordDays, setRecordDays] = React.useState([])
  // 記錄的dates日期 格式為2021/11/01
  const [recordDates, setRecordDates] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const contentRef = useRef(null)
  useEffect(() => {}, [])

  const handleBack = () => {
    navigate(-1)
  }

  const printComponentRef = useRef()

  useEffect(() => {
    getDays()
    getDates()
    getInOutRecordListForWorker()
  }, [])

  const getDateFrom = () => {
    let dateF = ''
    dateF = searchParams.get('accessDateFrom')
    while (dateF.indexOf('-') >= 0) {
      dateF = dateF.replace('-', '/')
    }
    return dateF
  }

  const getDateTo = () => {
    let dateF = ''
    dateF = searchParams.get('accessDateTo')
    while (dateF.indexOf('-') >= 0) {
      dateF = dateF.replace('-', '/')
    }
    return dateF
  }

  const getDays = () => {
    let startDateString = getDateFrom()
    let endDateString = getDateTo()
    let startDate = new Date(startDateString)
    let endDate = new Date(endDateString)

    let list = []
    while (startDate <= endDate) {
      list.push(moment(startDate).format('DD'))
      startDate = moment(startDate).add(1, 'days')
    }

    setRecordDays([...new Set(list)])
  }

  const getDates = () => {
    let startDateString = getDateFrom()
    let endDateString = getDateTo()
    let startDate = new Date(startDateString)
    let endDate = new Date(endDateString)

    let list = []
    while (startDate <= endDate) {
      list.push(moment(startDate).format('yyyy/MM/DD'))
      startDate = moment(startDate).add(1, 'days')
    }

    setRecordDates([...new Set(list)])
  }

  const getInOutRecordListForWorker = async () => {
    let list = await apiAtt.getInOutRecordListForWorker(getDateFrom(), getDateTo(), searchParams.get('siteId'), searchParams.get('smartCardId'), searchParams.get('subContractorId'))

    if (list !== null) {
      setMainData(list.mainData)
      setDataSource(list.titleData)
    }
    setLoading(false)
  }

  // 获取每个人的salary
  const getPersonSalary = (subData, destinDate) => {
    let record
    subData.map((value) => {
      if (value.shortdate === destinDate) {
        if (value.dailyRate) {
          record = value.dailyRate.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
      }
      return []
    })
    return record
  }

  // 获取每个雇主的mpf
  const getEmploerMPF = (subData, destinDate) => {
    let record
    subData.map((value) => {
      if (value.shortdate === destinDate) {
        if (value.employerMpf) {
          record = value.employerMpf.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
      }
      return []
    })
    return record
  }

  // 获取每个雇员的mpf
  const getEmploeeMPF = (subData, destinDate) => {
    let record
    subData.map((value) => {
      if (value.shortdate === destinDate) {
        if (value.employeeMpf) {
          record = value.employeeMpf.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
      }
      return []
    })
    return record
  }

  // 獲取每個人的總數
  const getVailablePersonRecordCount = (subData) => {
    // 只要個人的數據isValue為Y就算一條有效數據
    let recordCount = 0
    subData.map((value) => {
      if (value.isValue === 'Y') {
        recordCount += 1
      }
      return []
    })
    return recordCount
  }

  // 獲取每個人的總入息
  const getPersonTotalIncome = (subData) => {
    let record = 0
    subData.map((value) => {
      if (value.dailyRate) {
        record += value.dailyRate
      }
      return []
    })
    return record.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  // 獲取每個人下的雇主的MPF
  const getPersonTotalEpolerMPF = (subData) => {
    let record = 0
    subData.map((value) => {
      if (value.employerMpf) {
        record += value.employerMpf
      }
      return []
    })
    return record.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  // 獲取每個分判下面雇主的mpf
  const getVailableVendorEmployerMPFCount = (countList) => {
    let recordCount = 0
    countList.map((countListValue) => {
      countListValue.subData.map((value) => {
        if (value.employerMpf) {
          recordCount += value.employerMpf
        }
        return []
      })
      return []
    })
    return recordCount
  }

  // 獲取每個人下的雇员的MPF
  const getPersonTotalEpoleeMPF = (subData) => {
    let record = 0
    subData.map((value) => {
      if (value.employeeMpf) {
        record += value.employeeMpf
      }
      return []
    })
    return record.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  // 獲取每個分判下面雇员的mpf
  const getVailableVendorEmployeeMPFCount = (countList) => {
    let recordCount = 0
    countList.map((countListValue) => {
      countListValue.subData.map((value) => {
        if (value.employeeMpf) {
          recordCount += value.employeeMpf
        }
        return []
      })
      return []
    })
    return recordCount
  }

  // 獲取每個分判下面總入息總數
  const getVailableVendorIncomeCount = (countList) => {
    let recordCount = 0
    countList.map((value) => {
      value.subData.map((value) => {
        if (value.dailyRate) {
          recordCount += value.dailyRate
        }
        return []
      })
      return []
    })
    return recordCount
  }

  const exportExcel = (data) => {
    $download(`/attendance/report/getInOutRecordListForWorkerExcel`, 'application/vnd.ms-excel', data)
  }

  const exportPDF = (data) => {
    $download(`/attendance/report/getInOutRecordListForWorkerPdf`, 'application/pdf', data)
  }

  // 獲取全部分判下面總入息總數
  const getVailableAllVendorIncomeCount = (mainData) => {
    let recordCount = 0
    mainData.map((mainDataValue) => {
      mainDataValue.coutList.map((countListValue) => {
        countListValue.subData.map((value) => {
          if (value.dailyRate) {
            recordCount += value.dailyRate
          }
          return []
        })
        return []
      })
      return []
    })

    return recordCount
  }
  // 獲取全部分判下面總入息總數
  const getVailableAllVendorEmployerCount = (mainData) => {
    let recordCount = 0
    mainData.map((mainDataValue) => {
      mainDataValue.coutList.map((countListValue) => {
        countListValue.subData.map((value) => {
          if (value.employerMpf) {
            recordCount += value.employerMpf
          }
          return []
        })
        return []
      })
      return []
    })

    return recordCount
  }

  // 獲取全部分判下面總入息總數
  const getVailableAllVendorEmployeeCount = (mainData) => {
    let recordCount = 0
    mainData.map((mainDataValue) => {
      mainDataValue.coutList.map((countListValue) => {
        countListValue.subData.map((value) => {
          if (value.employeeMpf) {
            recordCount += value.employeeMpf
          }
          return []
        })
        return []
      })
      return []
    })

    return recordCount
  }

  // 打印
  const handlePrint = async () => {
    try {
      const content = contentRef.current
      // const width = content.clientWidth;
      // const height = content.clientHeight;
      //   contentRef.scrollLeft = 0;

      const scale = 0.4

      if (content) {
        const canvas = await html2canvas(content, { scale: scale })
        const imgData = canvas.toDataURL('image/png')

        // 创建一个新窗口用于打印
        const printWindow = window.open('', '', 'width=600,height=600')
        printWindow.document.open()
        printWindow.document.write('<img src="' + imgData + '" />')
        printWindow.document.close()

        // 等待图像加载完成后再执行打印
        printWindow.onload = function () {
          printWindow.print()
          printWindow.close()
        }
      }
    } catch (error) {
      console.error('打印出错：', error)
    }
  }

  return (
    <Container maxWidth={false}>
      <div style={{ display: 'block', textAlign: 'left' }}>
        <Button sx={{ float: 'left' }} onClick={handleBack}>
          <ArrowBackIcon />
          {t('button_back')}
        </Button>
      </div>

      <div style={{ display: 'block', textAlign: 'right' }}>
        <Button
          sx={{ float: 'right', mr: '20px' }}
          onClick={() =>
            exportPDF({
              siteId: searchParams.get('siteId'),
              siteDesc: searchParams.get('siteDesc'),
              smartCardId: searchParams.get('smartCardId'),
              subContractorId: searchParams.get('subContractorId'),
              subContractorDesc: searchParams.get('subContractorDesc'),
              dateFrom: searchParams.get('accessDateFrom'),
              dateTo: searchParams.get('accessDateTo'),
              vendorId: dataSource.vendorId,
              vendorDesc: dataSource.vendorDesc,
            })
          }
        >
          <DownloadIcon />
          {t('button_export_pdf')}
        </Button>
        <Button
          sx={{ float: 'right', mr: '20px' }}
          onClick={() =>
            exportExcel({
              siteId: searchParams.get('siteId'),
              siteDesc: searchParams.get('siteDesc'),
              smartCardId: searchParams.get('smartCardId'),
              subContractorId: searchParams.get('subContractorId'),
              subContractorDesc: searchParams.get('subContractorDesc'),
              dateFrom: searchParams.get('accessDateFrom'),
              dateTo: searchParams.get('accessDateTo'),
              vendorId: dataSource.vendorId,
              vendorDesc: dataSource.vendorDesc,
            })
          }
        >
          <DownloadIcon />
          {t('button_export_excel')}
        </Button>
        <Button
          sx={{ float: 'right', mr: '20px' }}
          // onClick={() => handlePrint()}
          onClick={() =>
            exportPDF({
              siteId: searchParams.get('siteId'),
              siteDesc: searchParams.get('siteDesc'),
              smartCardId: searchParams.get('smartCardId'),
              subContractorId: searchParams.get('subContractorId'),
              subContractorDesc: searchParams.get('subContractorDesc'),
              dateFrom: searchParams.get('accessDateFrom'),
              dateTo: searchParams.get('accessDateTo'),
              vendorId: dataSource.vendorId,
              vendorDesc: dataSource.vendorDesc,
            })
          }
        >
          <DownloadIcon />
          {t('列印')}
        </Button>
      </div>

      <div id="print" style={{}} ref={contentRef}>
        <Container maxWidth={false}>
          <div>
            <div style={{ display: 'block', marginBottom: '60px' }}>
              <Typography variant="h4" sx={{ textAlign: 'center' }}>
                <b>{t('search_detailofEmployee_income')}</b>
              </Typography>
            </div>

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>

            {
              <div>
                <TableContainer component={Paper} sx={{ marginBottom: '20px', backgroundColor: 'transparent' }}>
                  <Table sx={{ textAlign: 'left' }} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('search_company_name')}</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{''}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', width: 200, border: 0 }}>{t('Vendor') + '：'}</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{dataSource.vendorId + '-' + dataSource.vendorDesc}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('search_form_date_dot')}</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{searchParams.get('accessDateFrom').replace('-', '/').replace('-', '/')}</TableCell>
                        <TableCell sx={{ px: '5px', width: 160, border: 0 }}>{t('search_to_date_dot')}</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{searchParams.get('accessDateTo').replace('-', '/').replace('-', '/')}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('Site_Name') + '：'}</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{!searchParams.get('siteDesc') ? 'All' : searchParams.get('siteDesc')}</TableCell>
                      </TableRow>

                      {searchParams.get('subContractorDesc') && (
                        <TableRow>
                          <TableCell sx={{ px: '5px', border: 0 }}>{t('search_name_of_vendor')}</TableCell>
                          <TableCell sx={{ px: '5px', border: 0 }}>{searchParams.get('subContractorDesc')}</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }
          </div>

          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <TableContainer sx={{ marginTop: '20px' }}>
            <Table sx={{ p: '2px' }} size="small">
              {/* 主要内容 */}
              <div>
                {mainData.map((mainDataRow, index) => (
                  <div key={index + 9}>
                    <TableRow hover>
                      {/* (siteName, carid, engName, chName, joy, approveDate, inDate, inVateDate, applyDate */}
                      <TableCell
                        sx={{
                          whiteSpace: 'nowrap',
                          fontSize: '18px',
                          maxWidth: 5,
                        }}
                      >
                        <b>序號</b>
                      </TableCell>
                      <TableCell
                        sx={{
                          whiteSpace: 'nowrap',
                          fontSize: '18px',
                          maxWidth: 5,
                        }}
                      >
                        <b>{t('search_type_title')}</b>
                      </TableCell>

                      {recordDays.map((dayDesc, index) => (
                        <TableCell
                          key={index + Math.random}
                          sx={{
                            fontSize: '18px',
                            textAlign: 'left',
                          }}
                        >
                          <b>{dayDesc}</b>
                        </TableCell>
                      ))}

                      <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '18px' }}>
                        <b>{t('search_total_income')}</b>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '18px' }}>
                        <b>{t('search_total_employer_mpf')}</b>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '18px' }}>
                        <b>{t('search_total_staff_mpf')}</b>
                      </TableCell>
                    </TableRow>

                    {/* 分判商的名字 */}
                    <TableRow hover key={index + Math.random + 111}>
                      <TableCell colSpan={recordDays.length + 3} sx={{ fontSize: '15px', textAlign: 'left' }}>
                        <b>{t('search_vendor_title_dot') + mainDataRow.subContractorDesc}</b>{' '}
                      </TableCell>
                    </TableRow>

                    {mainDataRow.coutList.map((coutListRow, coutListRowIndex) => (
                      <>
                        {/* 用户的名字和smartcardId */}
                        <TableRow
                          hover
                          key={index + Math.random + 222}
                          sx={{
                            backgroundColor: coutListRowIndex % 2 !== 0 ? '#DCDCDC' : '#ffffff',
                          }}
                        >
                          <TableCell colSpan={recordDays.length + 3} sx={{ fontSize: '15px', textAlign: 'left' }}>
                            {t('search_smartCardId_dot') + coutListRow.smartCardId + ' ' + coutListRow.chineseName + ' ' + coutListRow.englishName}{' '}
                          </TableCell>
                        </TableRow>

                        {/* 第一行 */}
                        <TableRow
                          hover
                          key={coutListRowIndex + Math.random + 444}
                          sx={{
                            backgroundColor: coutListRowIndex % 2 !== 0 ? '#DCDCDC' : '#ffffff',
                          }}
                        >
                          {/* 地盤名字 */}
                          <TableCell
                            sx={{
                              fontSize: '15px',
                              textAlign: 'left',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {searchParams.get('siteDesc')}{' '}
                          </TableCell>
                          {/* 類型 */}
                          <TableCell
                            sx={{
                              fontSize: '15px',
                              textAlign: 'left',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            Salary{' '}
                          </TableCell>
                          {/* 智能卡編號 */}
                          {/* 每天的Salary数据 */}
                          {recordDates.map((recordDatesRow, recordDatesRowIndex) => (
                            <TableCell key={recordDatesRowIndex + Math.random} sx={{ fontSize: '15px', textAlign: 'left' }}>
                              {getPersonSalary(coutListRow.subData, recordDatesRow)}
                            </TableCell>
                          ))}
                          {/* 入息總數 */}
                          <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{getPersonTotalIncome(coutListRow.subData)} </TableCell>

                          {/* 	顧主MPF總數 */}
                          <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{} </TableCell>

                          {/* 顧員MPF總數 */}
                          <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{} </TableCell>
                        </TableRow>

                        {/* 第二行 */}
                        <TableRow
                          hover
                          key={coutListRowIndex + Math.random + 77}
                          sx={{
                            backgroundColor: coutListRowIndex % 2 !== 0 ? '#DCDCDC' : '#ffffff',
                          }}
                        >
                          {/* 地盤名字 */}
                          <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{} </TableCell>
                          {/* 類型 */}
                          <TableCell
                            sx={{
                              fontSize: '15px',
                              textAlign: 'left',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            Employer MPF{' '}
                          </TableCell>
                          {/* 智能卡編號 */}
                          {/* 每天的数据 */}
                          {recordDates.map((recordDatesRow, recordDatesRowIndex) => (
                            <TableCell key={recordDatesRowIndex + Math.random + 77} sx={{ fontSize: '15px', textAlign: 'left' }}>
                              {getEmploerMPF(coutListRow.subData, recordDatesRow)}
                            </TableCell>
                          ))}
                          {/* 入息總數 */}
                          <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{} </TableCell>

                          {/* 	顧主MPF總數 */}
                          <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{getPersonTotalEpolerMPF(coutListRow.subData)} </TableCell>

                          {/* 顧員MPF總數 */}
                          <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{} </TableCell>
                        </TableRow>

                        {/* 第三行 */}
                        <TableRow
                          hover
                          key={coutListRowIndex + Math.random + 66}
                          sx={{
                            backgroundColor: coutListRowIndex % 2 !== 0 ? '#DCDCDC' : '#ffffff',
                          }}
                        >
                          {/* 地盤名字 */}
                          <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{} </TableCell>
                          {/* 類型 */}
                          <TableCell
                            sx={{
                              fontSize: '15px',
                              textAlign: 'left',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            Employee MPF{' '}
                          </TableCell>
                          {/* 智能卡編號 */}
                          {/* 每天的数据 */}
                          {recordDates.map((recordDatesRow, recordDatesRowIndex) => (
                            <TableCell key={recordDatesRowIndex + Math.random + 66} sx={{ fontSize: '15px', textAlign: 'left' }}>
                              {getEmploeeMPF(coutListRow.subData, recordDatesRow)}
                            </TableCell>
                          ))}
                          {/* 入息總數 */}
                          <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{} </TableCell>

                          {/* 	顧主MPF總數 */}
                          <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{} </TableCell>

                          {/* 顧員MPF總數 */}
                          <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{getPersonTotalEpoleeMPF(coutListRow.subData)} </TableCell>
                        </TableRow>

                        <TableRow hover key={index + Math.random + 44}>
                          <TableCell colSpan={recordDays.length + 3} sx={{ fontSize: '15px', textAlign: 'left' }}>
                            {}{' '}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}

                    {/* 总数 */}
                    <TableRow hover key={index + Math.random + 2}>
                      <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{}</TableCell>
                      {/* colSpan表示合併單元格 */}
                      <TableCell colSpan={recordDays.length + 1} sx={{ fontSize: '15px', textAlign: 'left' }}>
                        {t('search_totals_title')}
                      </TableCell>
                      {/* 每个分判下的总入息 */}
                      <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                        {getVailableVendorIncomeCount(mainDataRow.coutList)
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}{' '}
                      </TableCell>
                      {/* 每个分判下雇主总MPF */}
                      <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                        {getVailableVendorEmployerMPFCount(mainDataRow.coutList)
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}{' '}
                      </TableCell>
                      {/* 每个分判下雇员总MPF */}
                      <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                        {getVailableVendorEmployeeMPFCount(mainDataRow.coutList)
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}{' '}
                      </TableCell>
                    </TableRow>

                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{}</TableCell>

                    {/* 最后的总数 */}
                    <TableRow hover>
                      <TableCell colSpan={recordDays.length + 2} sx={{ fontSize: '15px', textAlign: 'left' }}>
                        {t('search_totals_title')}
                      </TableCell>

                      {/* 全部分判下的总入息 */}
                      <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                        {getVailableAllVendorIncomeCount(mainData)
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}{' '}
                      </TableCell>
                      {/* 全部分判下雇主总MPF */}
                      <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                        {getVailableAllVendorEmployerCount(mainData)
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}{' '}
                      </TableCell>
                      {/* 全部分判下雇员总MPF */}
                      <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                        {getVailableAllVendorEmployeeCount(mainData)
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}{' '}
                      </TableCell>
                    </TableRow>
                  </div>
                ))}
              </div>
            </Table>
          </TableContainer>

          {
            <div>
              <Typography sx={{ textAlign: 'left', marginTop: '60px' }}>
                數字 (小時:分鐘) - 工人當天最先入閘時間及最後出閘時間的差額
                <br />
                "M" - 工人出入閘記錄不正確
                <br />
                " " - 當天沒有出入閘記錄
                <br />
              </Typography>
            </div>
          }
        </Container>
      </div>
    </Container>
  )
}
