import React, { useState, useRef, useEffect } from 'react'
import { Button, Box, Container, Checkbox, FormGroup, FormControlLabel, Grid, Backdrop, MenuItem, CircularProgress, TextField, Typography, FormLabel } from '@mui/material'

import { useTranslation } from 'react-i18next'
import CustomDialog from 'src/components/common/CustomDialog'
import CustomSnackbar from 'src/components/common/CustomSnackbar'
import { confirm } from 'src/components/common'

import * as api from 'src/utils/api/workerAttendanceRecord/AutoPayApi'
import { left } from '@popperjs/core'
import SaveButton from './SaveButton'

import Checking from './Checking'

export default function Button7(props) {
  const { type, setType, data } = props
  const { t } = useTranslation()
  const [sendData, setSendData] = React.useState({
    checkAll: true,
    checkEn: true,
    check1: true,
    check2: true,
    check3: true,
    check4: true,
    check5: true,
    check6: true,
    check7: true,
    check8: true,
    pageNumber: 1,
    pageSize: 50,
  })
  const [open, setOpen] = React.useState(false)
  const [step, setStep] = useState(0)
  const [contractorList, setContractorList] = React.useState([])
  const [statusList, setStatusList] = React.useState([])
  const [shortPeriodList, setShortPList] = React.useState([])
  const [siteList, setSiteList] = React.useState([])
  const [allData, setAllData] = React.useState({})
  const [saveData, setSaveData] = React.useState({})
  const [checkTable, setCheckTable] = React.useState([])

  const [dialog, setDialog] = useState({
    content: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        確認
      </Button>
    ),
    open: false,
  })

  const [snackbar, setSnackbar] = useState({
    message: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setSnackbar({ ...snackbar, open: false })
        }}
      >
        確認
      </Button>
    ),
    open: false,
  })

  useEffect(() => {
    const getData = async () => {
      setOpen(true)
      // 獲取部分糧期
      let getAllData = {}
      if (data.headerSet && data.payrollType === 'P') {
        getAllData = await api.getPartPariodList()
      } else {
        // 獲取短糧期
        getAllData = await api.getWorkerAutopayMainSearch()
      }
      // let getAllData = await api.getWorkerAutopayMainSearch()

      if (getAllData) {
        console.log('loading')
        setSendData({
          ...sendData,
          payrollType: data.payrollType || '',
          loanFlag: getAllData.rsbutton && getAllData.rsbutton[0] ? getAllData.rsbutton[0].loanFlag : '',
        })

        setAllData(getAllData)
        setStatusList([...getAllData.status] || [])
        // getAllData.subcontractorset?.splice(0, 0, {
        //   subContractorDesc: '所有分判商',
        //   subContractorId: '',
        // })
        setContractorList(getAllData.subcontractorset)
        setSiteList(getAllData.objRS)
        setShortPList(getAllData.subBatchPeriodSet)
      } else {
        setDialog({
          ...dialog,
          content: '未知錯誤',
          open: true,
        })
      }
      setOpen(false)
      setSendData({
        ...sendData,
        payrollType: data.payrollType || '',
        // checkAll: true,
        // checkEn: true,
      })
    }
    getData()
    console.log('sendData', sendData)
  }, [step])

  const handleCancel = () => {
    setType(0)
  }
  const handleCheck = () => {
    if (data.payrollType === 'P') {
      if (!sendData.siteId || !sendData.subBatchId) {
        alert('請輸入地盤以及部分糧期')
        return
      }
    } else {
      if (!sendData.siteId || !sendData.subBatchPeriod) {
        alert('請輸入地盤以及短糧期')
        return
      }
    }

    // if (!sendData.subcon) {
    //   alert('請輸入分判商')
    //   return
    // }

    sendData.dateFrom = allData.headerSet?.dateFrom
    sendData.dateTo = allData.headerSet?.dateTo
    setOpen(true)
    const checkData = async () => {
      sendData.headerId = allData.headerSet.headerId
      if (allData.headerSet.payrollType === 'F') {
        sendData.dateFrom = allData.periodset?.dateFrom
        sendData.dateTo = allData.periodset?.dateTo
      }
      if (sendData.checkAll) {
        sendData.check1 = null
        sendData.check2 = null
        sendData.check3 = null
        sendData.check4 = null
      }
      if (sendData.checkEn) {
        sendData.check5 = null
        sendData.check6 = null
        sendData.check7 = null
        sendData.check8 = null
      }
      let checkApi = await api.checkworkerDetailsql(sendData)
      if (checkApi) {
        setOpen(false)
        setCheckTable(checkApi || [])
      }
      setOpen(false)
    }
    checkData()
    setStep(2)
    if (sendData.checkAll) {
      sendData.check1 = true
      sendData.check2 = true
      sendData.check3 = true
      sendData.check4 = true
    }
    if (sendData.checkEn) {
      sendData.check5 = true
      sendData.check6 = true
      sendData.check7 = true
      sendData.check8 = true
    }
  }

  const handleSubBatchPeriod = (item) => {
    let data = { ...sendData }
    // 短粮期
    if (allData.headerSet?.payrollType === 'S') {
      data.pdDateFrom = item.dateFrom
      data.pdDateTo = item.dateTo
    }
    // 部分粮期
    if (allData.headerSet?.payrollType === 'P') {
      data.pdDateFrom = allData.headerSet?.dateFrom
      data.pdDateTo = allData.headerSet?.dateTo
    }
    if (allData.headerSet && allData.headerSet?.payrollType === 'P' && shortPeriodList?.length > 0) {
      data.subBatchId = item.subBatchId
      data.subDesc = item.text
    } else {
      data.subBatchPeriod = item.id
      data.subDesc = item.id
    }
    setSendData({ ...data })
  }

  const handleSiteName = (item) => {
    setSendData({ ...sendData, siteId: item.siteId, siteDesc: item.siteDesc })
  }
  const handleVendor = (item) => {
    setSendData({
      ...sendData,
      subcon: item.subContractorId,
      conDesc: item.subContractorDesc,
    })
  }
  const handleTxt = (e) => {
    setSendData({ ...sendData, txtSearch: e.target.value })
  }
  const handleCheckAll = (e) => {
    setSendData({
      ...sendData,
      checkAll: e.target.checked || null,
      check1: e.target.checked || null,
      check2: e.target.checked || null,
      check3: e.target.checked || null,
      check4: e.target.checked || null,
    })
  }
  const handleCheck1 = (e) => {
    if (e.target.checked && sendData.check2 && sendData.check3 && sendData.check4) {
      setSendData({
        ...sendData,
        check1: e.target.checked || null,
        checkAll: true,
      })
    } else {
      setSendData({
        ...sendData,
        check1: e.target.checked || null,
        checkAll: null,
      })
    }
  }

  const handleCheck2 = (e) => {
    if (e.target.checked && sendData.check1 && sendData.check3 && sendData.check4) {
      setSendData({
        ...sendData,
        check2: e.target.checked || null,
        checkAll: true,
      })
    } else {
      setSendData({
        ...sendData,
        check2: e.target.checked || null,
        checkAll: null,
      })
    }
  }
  const handleCheck3 = (e) => {
    if (e.target.checked && sendData.check1 && sendData.check2 && sendData.check4) {
      setSendData({
        ...sendData,
        check3: e.target.checked || null,
        checkAll: true,
      })
    } else {
      setSendData({
        ...sendData,
        check3: e.target.checked || null,
        checkAll: null,
      })
    }
  }
  const handleCheck4 = (e) => {
    if (e.target.checked && sendData.check1 && sendData.check2 && sendData.check3) {
      setSendData({
        ...sendData,
        check4: e.target.checked || null,
        checkAll: true,
      })
    } else {
      setSendData({
        ...sendData,
        check4: e.target.checked || null,
        checkAll: null,
      })
    }
  }
  const handleCheckEn = (e) => {
    setSendData({
      ...sendData,
      checkEn: e.target.checked || null,
      check5: e.target.checked || null,
      check6: e.target.checked || null,
      check7: e.target.checked || null,
      check8: e.target.checked || null,
    })
  }
  const handleCheck5 = (e) => {
    if (e.target.checked && sendData.check6 && sendData.check7 && sendData.check8) {
      setSendData({
        ...sendData,
        check5: e.target.checked || null,
        checkEn: true,
      })
    } else {
      setSendData({
        ...sendData,
        check5: e.target.checked || null,
        checkEn: null,
      })
    }
  }
  const handleCheck6 = (e) => {
    if (e.target.checked && sendData.check5 && sendData.check7 && sendData.check8) {
      setSendData({
        ...sendData,
        check6: e.target.checked || null,
        checkEn: true,
      })
    } else {
      setSendData({
        ...sendData,
        check6: e.target.checked || null,
        checkEn: null,
      })
    }
  }
  const handleCheck7 = (e) => {
    if (e.target.checked && sendData.check6 && sendData.check5 && sendData.check8) {
      setSendData({
        ...sendData,
        check7: e.target.checked || null,
        checkEn: true,
      })
    } else {
      setSendData({
        ...sendData,
        check7: e.target.checked || null,
        checkEn: null,
      })
    }
  }
  const handleCheck8 = (e) => {
    if (e.target.checked && sendData.check6 && sendData.check7 && sendData.check5) {
      setSendData({
        ...sendData,
        check8: e.target.checked || null,
        checkEn: true,
      })
    } else {
      setSendData({
        ...sendData,
        check8: e.target.checked || null,
        checkEn: null,
      })
    }
  }

  const handleStatus = (e) => {
    setSendData({ ...sendData, status: e.target.value })
  }

  const handleProduce = (e) => {
    setSendData({ ...sendData, produce: e.target.value })
  }

  const handleSave = async () => {
    if (data.payrollType === 'P') {
      if (!sendData.siteId || !sendData.subBatchId) {
        alert('請輸入地盤以及部分糧期')
        return
      }
    } else {
      if (!sendData.siteId || !sendData.subBatchPeriod) {
        alert('請輸入地盤以及短糧期')
        return
      }
    }

    // if (!sendData.subcon) {
    //   alert('請輸入分判商')
    //   return
    // }

    const save = async () => {
      setOpen(true)
      // sendData.dateFrom = allData.dateFrom
      // sendData.dateTo = allData.dateTo
      let saveApi = {}
      if (sendData.checkAll) {
        sendData.check1 = null
        sendData.check2 = null
        sendData.check3 = null
        sendData.check4 = null
      }
      if (sendData.checkEn) {
        sendData.check5 = null
        sendData.check6 = null
        sendData.check7 = null
        sendData.check8 = null
      }
      sendData.dateFrom = allData.headerSet?.dateFrom
      sendData.dateTo = allData.headerSet?.dateTo
      // if (allData.headerSet.payrollType === "F") {
      //     sendData.dateFrom = allData.headerSet?.dateFrom
      //     sendData.dateTo = allData.headerSet?.dateTo
      // }
      // sendData.pdDateFrom = sendData.dateFrom
      // sendData.pdDateTo = sendData.dateTo
      if (data.payrollType === 'P') {
        // 部分粮期
        // saveApi = await api.sendPartAllData(sendData)
      } else {
        // saveApi = await api.sendAllData(sendData)
      }
      if (saveApi) {
        // setSaveData(saveApi)
        setStep(1)
      } else {
        setStep(1)
        // alert('未知錯誤')
      }
      if (sendData.checkAll) {
        sendData.check1 = true
        sendData.check2 = true
        sendData.check3 = true
        sendData.check4 = true
      }
      if (sendData.checkEn) {
        sendData.check5 = true
        sendData.check6 = true
        sendData.check7 = true
        sendData.check8 = true
      }
    }
    save()
  }
  const handleBack = () => {
    let list = step
    list[8] = 0
    setStep([...list])
  }

  return (
    <>
      <CustomDialog open={dialog.open} title={dialog.title} content={dialog.content} action={dialog.action} />
      <CustomSnackbar open={snackbar.open} message={snackbar.message} action={snackbar.action} />
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {step === 0 || !step ? (
        <Container maxWidth="sm">
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h4" sx={{ mb: '20px' }}>
              <b>{t('Enter/amend-worker_salary/MPF_information')}</b>
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} md={12}>
                <TextField
                  sx={{ maxWidth: '400px', textAlign: 'left' }}
                  fullWidth
                  size="small"
                  disabled
                  label={t('Payroll_and_Contribution_Period')}
                  value={allData.headerSet?.dateFrom + '~' + allData.headerSet?.dateTo}
                />
              </Grid>
              {/* 短糧期 */}
              <Grid item xs={6} md={12}>
                {allData.headerSet?.payrollType === 'P' ? (
                  <>
                    {/* 部分粮期 */}
                    {shortPeriodList?.length > 0 ? (
                      <TextField
                        error={!sendData.subBatchId}
                        sx={{ maxWidth: '400px', textAlign: 'left' }}
                        value={sendData.subBatchId || ''}
                        size="small"
                        fullWidth
                        label={t('Partial_pay_period')}
                        select
                      >
                        {shortPeriodList.map((item, index) => (
                          <MenuItem onClick={() => handleSubBatchPeriod(item)} key={index} value={item.subBatchId}>
                            {item.subBatchId}-{item.dateFrom}-{item.dateTo}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        error={!sendData.subBatchId}
                        sx={{ maxWidth: '400px', textAlign: 'left' }}
                        value={sendData.subBatchId || ''}
                        size="small"
                        fullWidth
                        label={t('Partial_pay_period')}
                        select
                      >
                        <MenuItem
                          key="F"
                          value="F"
                          onClick={() =>
                            handleSubBatchPeriod({
                              subBatchId: 'F',
                              text: ' 部分糧期',
                            })
                          }
                        >
                          {' '}
                          部分糧期
                        </MenuItem>
                      </TextField>
                    )}
                  </>
                ) : (
                  <>
                    {/* 短粮期 */}
                    {shortPeriodList?.length > 0 ? (
                      <TextField
                        error={!sendData.subBatchPeriod}
                        sx={{ maxWidth: '400px', textAlign: 'left' }}
                        value={sendData.subBatchPeriod || ''}
                        label={t('Short_Pay_Period')}
                        size="small"
                        fullWidth
                        select
                      >
                        {shortPeriodList.map((item, index) => (
                          <MenuItem onClick={() => handleSubBatchPeriod(item)} key={index} value={item.id}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        error={!sendData.subBatchPeriod}
                        sx={{ maxWidth: '400px', textAlign: 'left' }}
                        label={t('Short_Pay_Period')}
                        value={sendData.subBatchPeriod || ''}
                        size="small"
                        fullWidth
                        select
                      >
                        <MenuItem
                          key="F"
                          value="F"
                          onClick={() =>
                            handleSubBatchPeriod({
                              id: 'F',
                              text: ' 最後的短糧期',
                              dateFrom: allData.periodset?.dateFrom,
                              dateTo: allData.periodset?.dateTo,
                            })
                          }
                        >
                          {' '}
                          最後的短糧期
                        </MenuItem>
                      </TextField>
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={6} md={12}>
                {/* 地盤名稱 */}
                <TextField error={!sendData.siteId} sx={{ maxWidth: '400px', textAlign: 'left' }} label={t('Site_Name')} value={sendData.siteId || ''} size="small" fullWidth select>
                  {siteList &&
                    siteList.map((item, index) => (
                      <MenuItem key={index} value={item.siteId} onClick={() => handleSiteName(item)}>
                        {item.siteDesc}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              {/* error={!sendData.subcon} */}
              <Grid item xs={6} md={12}>
                <TextField sx={{ maxWidth: '400px', textAlign: 'left' }} label={t('Subcontractors')} value={sendData.subcon || ''} size="small" fullWidth select>
                  <MenuItem
                    onClick={() => {
                      setSendData({
                        ...sendData,
                        subcon: null,
                        conDesc: null,
                      })
                    }}
                    value={null}
                  >
                    {'所有分判商'}
                  </MenuItem>
                  {contractorList &&
                    contractorList.map((item, index) => (
                      <MenuItem onClick={() => handleVendor(item)} key={index} value={item.subContractorId}>
                        {item.subContractorDesc}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={6} md={12}>
                <TextField sx={{ maxWidth: '400px', textAlign: 'left' }} label={t('Chinese_English_Name_or_Cardholder_ID')} value={sendData.txtSearch} onChange={handleTxt} size="small" fullWidth />
              </Grid>
              <Grid item xs={6} md={12}>
                <TextField sx={{ maxWidth: '400px', textAlign: 'left' }} label={t('Input_Status')} value={sendData.status || ''} onChange={handleStatus} size="small" fullWidth select>
                  <MenuItem value={null}>None</MenuItem>
                  {statusList?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6} md={12}>
                <TextField sx={{ maxWidth: '400px', textAlign: 'left' }} label={t('whether_to_provide_food')} value={sendData.produce || ''} onChange={handleProduce} size="small" fullWidth select>
                  <MenuItem value={null}>None</MenuItem>
                  <MenuItem value={'0'}>{t('yes')}</MenuItem>
                  <MenuItem value={'1'}>{t('no')}</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} md={12} sx={{ textAlign: 'left' }}>
                <Box sx={{ margin: '0 auto', maxWidth: '400px' }}>
                  <FormLabel component="legend">{t('icon1_autopay_button7_label6')}</FormLabel>
                  <FormControlLabel
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }}
                    size="small"
                    control={<Checkbox checked={sendData.checkAll || false} onChange={handleCheckAll} />}
                    label={t('button_select_all')}
                  />
                  <br />
                  <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }} control={<Checkbox checked={sendData.check1 || false} onChange={handleCheck1} />} label="500-" />
                  <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }} size="small" control={<Checkbox checked={sendData.check2 || false} onChange={handleCheck2} />} label="500~999.99" />
                  <FormControlLabel
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }}
                    size="small"
                    control={<Checkbox checked={sendData.check3 || false} onChange={handleCheck3} />}
                    label="1000~1499.99"
                  />
                  <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }} size="small" control={<Checkbox checked={sendData.check4 || false} onChange={handleCheck4} />} label="1500+" />
                </Box>
              </Grid>
              <Grid item xs={6} md={12} sx={{ textAlign: 'left' }}>
                <Box sx={{ margin: '0 auto', maxWidth: '400px' }}>
                  <FormLabel component="legend">{t('icon1_autopay_button7_label7')}</FormLabel>
                  <FormControlLabel
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 15 }, textAlign: 'left' }}
                    control={<Checkbox checked={sendData.checkEn || false} onChange={handleCheckEn} />}
                    label={t('button_select_all')}
                  />
                  <br />
                  <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }} control={<Checkbox checked={sendData.check5 || false} onChange={handleCheck5} />} label="A ~ H" />
                  <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }} control={<Checkbox checked={sendData.check6 || false} onChange={handleCheck6} />} label="I ~ L" />
                  <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }} control={<Checkbox checked={sendData.check7 || false} onChange={handleCheck7} />} label="M ~ T" />
                  <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }} control={<Checkbox checked={sendData.check8 || false} onChange={handleCheck8} />} label="U ~ Z" />
                </Box>
              </Grid>
            </Grid>
            <Button onClick={handleSave} sx={{ mt: '20px', width: '100px' }} variant="contained">
              {t('button_save')}
            </Button>
            <Button onClick={handleCancel} sx={{ mt: '20px', ml: '10px', width: '100px' }} variant="contained">
              {t('button_cancel')}
            </Button>
            <Button onClick={handleCheck} sx={{ mt: '20px', ml: '10px', width: '100px' }} variant="contained">
              {t('button_Checking')}
            </Button>
          </div>
        </Container>
      ) : step === 1 ? (
        <SaveButton
          handleSaveReload={handleSave}
          setSnackbar={setSnackbar}
          snackbar={snackbar}
          setDialog={setDialog}
          dialog={dialog}
          setType={setType}
          setOpen={setOpen}
          sendData={sendData}
          lastPageData={data}
          setStep={setStep}
        />
      ) : (
        <Checking sendData={sendData} checkTable={checkTable} setStep={setStep} />
      )}
    </>
  )
}
