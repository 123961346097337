import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  MenuItem,
  TextField,
  Typography,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Backdrop,
  CircularProgress,
  FormControl,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import * as apiAtt from "src/utils/api/workerAttendanceRecord/AutoPayApiAttend";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function SearchChoosePerson(props) {
  const { setOpenChoosePerson, setSuperHolder } = props;
  const componentRef = useRef();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {}, []);

  function clickHeaderSortButton(e, row) {
    console.log(row.cardHolderId);
    setSuperHolder(row.smartCardId);
    setOpenChoosePerson(false);
  }

  const handleBack = () => {
    setOpenChoosePerson(false);
  };

  const [jobList, setJobList] = useState([]);
  const [smartCardIdList, setSmartCardIdList] = useState([]);
  const [holderId, setHolderId] = useState("");
  const [englisthName, setEnglisthName] = useState("");
  const [chineseName, setChineseName] = useState("");
  const [positionValue, setPositionValue] = useState("");
  const [positionDisplayValue, setPositionDisplayValue] = useState("");
  const [hasRunOneTime, setHasRunOneTime] = useState(false);

  useEffect(() => {
    if (!hasRunOneTime) {
      getfillSafetyPosition();
    }
    getfillSmartCard2();
  }, [holderId, englisthName, chineseName, positionValue]);

  const getfillSmartCard2 = async () => {
    let list = await apiAtt.getfillSmartCard2(
      chineseName,
      englisthName,
      holderId,
      positionValue
    );

    setLoading(false);
    if (list == null) {
      setSmartCardIdList([]);
    } else {
      setSmartCardIdList(list);
    }
  };

  const getfillSafetyPosition = async () => {
    let getfillSafetyPositionSelect = await apiAtt.getfillSafetyPosition();
    if (getfillSafetyPositionSelect) {
      const result = getfillSafetyPositionSelect;
      console.log("result", result);
      let tempList = [];

      result.map((value) => {
        let temp = {};
        temp["val"] = value.id;
        temp["text"] = value.text;
        tempList.push(temp);
        return [];
      });
      console.log("getfillSafetyPosition", tempList);
      setJobList(tempList);
      setLoading(false);
      setHasRunOneTime(true);
    }
  };

  const clickSearch = (event) => {
    getfillSmartCard2();
  };

  const clickReset = (event) => {
    setEnglisthName("");
    setChineseName("");
    setPositionDisplayValue("");
    setHolderId("");
  };

  const handleChangeHolderId = (event) => {
    setLoading(true);
    setHolderId(event.target.value);
  };

  const handleChangeEnglishName = (event) => {
    setLoading(true);
    setEnglisthName(event.target.value);
  };

  const handleChangeChineshName = (event) => {
    setLoading(true);
    setChineseName(event.target.value);
  };

  const handleChangePosition = (event) => {
    setLoading(true);
    setPositionDisplayValue(event.text);
    jobList.map((value) => {
      console.log(value);
      if (event.text === value.text) {
        setPositionValue(value.val);
        console.log(value.val);
      }
      return [];
    });
  };

  return (
    <>
      <div style={{ display: "block", textAlign: "left", marginTop: "0px" }}>
        <Button sx={{ float: "left" }} onClick={handleBack}>
          <ArrowBackIcon />
          {t("button_back")}
        </Button>
      </div>
      <div id="div" ref={componentRef} style={{ marginTop: "60px" }}>
        <Container>
          <div>
            <div style={{ display: "block" }}>
              <Container
                sx={{ width: "500px", borderColor: "#d3d3d3", borderRadius: 4 }}
              >
                {/* 智能卡編號 */}
                <Grid
                  item
                  xs={6}
                  sx={{ margin: "auto", width: "400px", marginTop: "20px" }}
                >
                  <TextField
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    placeholder="First 9 digits"
                    sx={{ textAlign: "left", maxWidth: "400px", mt: "20px" }}
                    label={t("search_smartCardId_notDot")}
                    // 設置了value可以進行重置
                    value={holderId}
                    onChange={(event) => {
                      handleChangeHolderId(event);
                    }}
                  />
                </Grid>

                {/* 英文名字 */}
                <Grid item xs={6} sx={{ margin: "auto", width: "400px" }}>
                  <TextField
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    sx={{ textAlign: "left", maxWidth: "400px", mt: "20px" }}
                    label={t("search_englishName_notDot")}
                    // 設置了value可以進行重置
                    value={englisthName}
                    onChange={(event) => {
                      handleChangeEnglishName(event);
                    }}
                  />
                </Grid>

                {/* 中文名字 */}
                <Grid item xs={6} sx={{ margin: "auto", width: "400px" }}>
                  <TextField
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    sx={{ textAlign: "left", maxWidth: "400px", mt: "20px" }}
                    label={t("search_chinese_name_notDot")}
                    // 設置了value可以進行重置
                    value={chineseName}
                    onChange={(event) => {
                      handleChangeChineshName(event);
                    }}
                  />
                </Grid>

                {/* 选择受雇职位 */}
                <Grid
                  item
                  xs={6}
                  sx={{ margin: "auto", width: "400px", marginTop: "10px" }}
                >
                  <TextField
                    id="positionDisplayValue"
                    value={positionDisplayValue || ""}
                    fullWidth
                    size="small"
                    label={t("search_employmentPosition")}
                    select
                  >
                    {jobList.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.text}
                        name={item.text}
                        onClick={() => handleChangePosition(item)}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Container>
            </div>
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            {/* 提交按钮 */}
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <FormControl size="small">
                <Button
                  sx={{
                    backgroundColor: "primaryBlue",
                    color: "commom.white",
                  }}
                  variant="contained"
                  onClick={clickSearch}
                >
                  {t("button_submit")}
                </Button>
              </FormControl>

              {/* 重置按钮 */}
              <FormControl size="small">
                <Button
                  variant="contained"
                  onClick={clickReset}
                  label="Reset"
                  sx={{
                    backgroundColor: "primaryGreen",
                    color: "commom.white",
                    marginLeft: "20px",
                  }}
                >
                  {t("button_reset")}
                </Button>
              </FormControl>
            </Grid>
          </div>

          {smartCardIdList.length > 0 && (
            <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
              <Table
                padding="none"
                sx={{ minWidth: 650, p: "2px" }}
                size="small"
              >
                <TableHead>
                  <TableRow hover sx={{ border: "1px" }}>
                    <TableCell align="center">
                      {t("search_smartCardId_notDot")}
                    </TableCell>
                    <TableCell align="center">
                      {t("search_englishName_notDot")}
                    </TableCell>
                    <TableCell align="center">
                      {t("search_chinese_name_notDot")}
                    </TableCell>
                    <TableCell align="center">
                      {t("search_employmentPosition")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {smartCardIdList.map((row, index) => (
                    <TableRow hover>
                      <TableCell
                        align="center"
                        sx={{ fontSize: "15px", px: "5px" }}
                      >
                        <Button
                          sx={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onClick={(e) => clickHeaderSortButton(e, row)}
                        >
                          {row.smartCardId}
                        </Button>{" "}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontSize: "15px", px: "5px" }}
                      >
                        {row.englishName}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontSize: "15px", px: "5px" }}
                      >
                        {row.chineseName}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ fontSize: "15px", px: "5px" }}
                      >
                        {row.jobNatureId}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Container>
      </div>
    </>
  );
}
