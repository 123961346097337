import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import { Backdrop, CircularProgress, Container, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { $download } from 'src/utils/api/services'
import * as api from '../../../utils/api/vendorAutopaySystem/WorkerSalaryMpfSafetyApi'

// 一覽表
export default function WSMSDetail3() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [mainData, setMainData] = React.useState([])
  const [dataSource, setDataSource] = React.useState({})
  const [loading, setLoading] = React.useState(true)

  useEffect(() => {}, [])

  const handleBack = () => {
    console.log(123111)
    navigate(-1)
    setTimeout(() => {
      console.log(searchParams.get('siteDesc'))
      navigate({
        pathname: '/vendorAutopaySystem/WorkerSalaryMpfSafety',
        search: `?${createSearchParams(
          {
            // dateFromId: dateFromId,
            // dateFromText: dateFromText,
            // dateToId: dateToId,
            // dateToText: dateToText,
            // siteId: siteId,
            // vendorDesc: vendorDesc,
            // vendorId: vendorId,

            dateFrom: searchParams.get('dateFromId'),
            dateTo: searchParams.get('dateToId'),
            siteId: searchParams.get('siteId'),
            siteDesc: searchParams.get('siteDesc'),
            vendorDesc: searchParams.get('vendorDesc'),
            vendorId: searchParams.get('vendorId'),
            reportType: searchParams.get('reportType'),
          },
          { state: { fromSpecificPage: true } },
        )}`,
      })
    }, 300)
  }

  const printComponentRef = useRef()

  useEffect(() => {
    getMaintDebit()
  }, [])

  const getMaintDebit = async () => {
    let list = {}

    list = await api.getSummarySQLlistWS({
      dateFrom: searchParams.get('dateFromId'),
      dateTo: searchParams.get('dateToId'),
      siteId: searchParams.get('siteId'),
      vendorDesc: searchParams.get('vendorDesc'),
      siteDesc: searchParams.get('siteDesc'),
      vendorId: searchParams.get('vendorId'),
      reportType: searchParams.get('reportType'),
    })

    if (list !== null) {
      console.log(list)
      setMainData(list.mainData)
      setDataSource(list)
    }
    setLoading(false)
  }

  const getInMPFSQLListDesc = (mainDataRow) => {
    let desc = ''
    mainDataRow.inMPFSQLList.map((inMPFSQLListValue) => {
      if (inMPFSQLListValue.period !== null) {
        console.log('inMPFSQLListValue', inMPFSQLListValue.period)
        desc += inMPFSQLListValue.period
        desc += '\n'
      }
      return []
    })

    // console.log(mainDataRow.inMPFSQLList)
    return desc
  }

  const getInPayRollListDesc = (mainDataRow) => {
    let desc = ''
    mainDataRow.inPayRollList.map((inPayRollListValue) => {
      if (inPayRollListValue.period !== null) {
        desc += inPayRollListValue.period
        desc += '\n'
      }
      return []
    })

    console.log('desc', desc)
    return desc
  }

  const exportExcel = (data) => {
    $download(`/payrollReportController/getSummarySQLlistWSExcel`, 'application/vnd.ms-excel', data)
  }

  const exportPdf = (data) => {
    console.log('searchdate', searchParams)
    $download(`/payrollReportController/getSummarySQLlistWSPdf`, 'application/pdf', data)
  }

  // t("search_vendor_autoPay_statues")
  return (
    <Container maxWidth="mx">
      <div style={{ display: 'block', textAlign: 'left' }}>
        <Button sx={{ float: 'left' }} onClick={handleBack}>
          <ArrowBackIcon />
          {t('button_back')}
        </Button>
      </div>

      <div style={{ display: 'block', textAlign: 'right' }}>
        <Button
          sx={{ float: 'right', mr: '20px' }}
          onClick={() =>
            exportPdf({
              siteId: searchParams.get('siteId'),
              siteDesc: searchParams.get('siteDesc'),
              vendorId: searchParams.get('vendorId'),
              vendorDesc: searchParams.get('vendorDesc'),
              dateFrom: searchParams.get('dateFromId'),
              dateTo: searchParams.get('dateToId'),
            })
          }
        >
          <DownloadIcon />
          {t('button_export_pdf')}
        </Button>
      </div>

      <div style={{ display: 'block', textAlign: 'right' }}>
        <Button
          sx={{ float: 'right', mr: '20px' }}
          onClick={() =>
            exportExcel({
              siteId: searchParams.get('siteId'),
              siteDesc: searchParams.get('siteDesc'),
              vendorId: searchParams.get('vendorId'),
              vendorDesc: searchParams.get('vendorDesc'),
              dateFrom: searchParams.get('dateFromId'),
              dateTo: searchParams.get('dateToId'),
            })
          }
        >
          <DownloadIcon />
          {t('button_export_excel')}
        </Button>
      </div>

      <div id="div" style={{}} ref={printComponentRef}>
        <Container maxWidth="mx">
          <div>
            <div style={{ display: 'block', marginBottom: '60px' }}>
              <Typography variant="h4" sx={{ textAlign: 'center' }}>
                <b>{t('icon_vas_wsms_title')}</b>
              </Typography>
            </div>

            <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>

            {
              <div>
                <TableContainer sx={{ marginBottom: '20px', backgroundColor: 'transparent' }}>
                  <Table sx={{ textAlign: 'left' }} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('icon_vas_wsms_report_print_date') + '：' + moment(new Date()).format('yyyy/MM/DD')}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>
                          {t('Contractor')} : {''}
                          {searchParams.get('vendorDesc') !== null && searchParams.get('vendorDesc') !== '' && searchParams.get('vendorDesc') !== 'null'
                            ? searchParams.get('vendorDesc')
                            : t('icon_vas_wsmszo_vendor')}
                        </TableCell>
                      </TableRow>

                      {searchParams.get('siteId').length > 0 && (
                        <TableRow>
                          <TableCell sx={{ px: '5px', border: 0 }}>
                            {t('search_site_title2')} : {''}
                            {searchParams.get('siteId') !== null && searchParams.get('siteId') !== '' && searchParams.get('siteId') !== 'null' ? searchParams.get('siteId') : t('All_site1')}
                          </TableCell>
                        </TableRow>
                      )}

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('icon_vas_wsms_date_from') + '：' + searchParams.get('dateFromText')}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('icon_vas_wsms_date_to') + '：' + searchParams.get('dateToText')}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('data_checklist')}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }
          </div>

          {/* table */}
          <TableContainer sx={{ marginTop: '20px', maxHeight: '80vh', maxWidth: '100vw' }}>
            <Table sx={{ p: '2px' }} size="small" stickyHeader>
              <TableHead>
                <TableRow hover>
                  {/* (siteName, carid, engName, chName, joy, approveDate, inDate, inVateDate, applyDate */}
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(1)}>
                      {t('icon_vas_wsms_site')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(2)}>
                      {t('icon_vas_wsms_vendor')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(3)}>
                      {t('icon_vas_wsms_report_table_header_period')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(4)}>
                      {t('icon_vas_wsms_report_table_header_total_salary')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ minWidth: '180px' }}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(5)}>
                      {t('icon_vas_wsms_report_table_header_boss_mpf')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ minWidth: '180px' }}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(5)}>
                      {t('icon_vas_wsms_report_table_header_boss_mpf_percentage')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ minWidth: '180px' }}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(6)}>
                      {t('Actual_number_of_working_days')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ minWidth: '180px' }}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(7)}>
                      {t('icon_vas_wsms_report_table_header_average_salary')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ minWidth: '180px' }}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(8)}>
                      {t('confirm_the_salary_paid_by_the_employer')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ minWidth: '180px' }}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(8)}>
                      {t('confirm_the_amount_of_MPF_that_the_employer_has_paid')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ minWidth: '260px', maxWidth: '260px' }}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(8)}>
                      {t('icon_vas_wsms_report_table_header_no_salary_period')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ minWidth: '160px' }}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(8)}>
                      {t('icon_vas_wsms_report_table_header_no_salary_period_amount')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ minWidth: '260px', maxWidth: '260px' }}>
                    <Link underline="none" onClick={() => clickCell(8)}>
                      {t('icon_vas_wsms_report_table_header_no_mpf_period')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ minWidth: '180px' }}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(8)}>
                      {t('icon_vas_wsms_report_table_header_no_mpf_period_amount')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ minWidth: '130px' }}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(8)}>
                      {t('icon_vas_wsms_report_table_header_director_approval_days')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ minWidth: '145px' }}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(8)}>
                      {t('icon_vas_wsms_report_table_header_supervisor_approval_days')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ minWidth: '180px' }}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(8)}>
                      {t('icon_vas_wsms_report_table_header_director_amount')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ minWidth: '180px' }}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(8)}>
                      {t('icon_vas_wsms_report_table_header_supervisor_amount')}
                    </Link>
                  </TableCell>
                </TableRow>
              </TableHead>

              <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>

              {mainData.map((mainDataRow, index) => (
                <>
                  {/* 單個人數據，需要循環遍歷 */}
                  <TableRow
                    hover
                    key={index + Math.random}
                    sx={{
                      backgroundColor: index % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                    }}
                  >
                    {/* 地盤 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{mainDataRow.siteDesc}</TableCell>
                    {/* 承判商 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{mainDataRow.vendorDesc}</TableCell>
                    {/* 時段 */}
                    <TableCell
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: '15px',
                        textAlign: 'left',
                      }}
                    >
                      {mainDataRow.dateFrom + '~' + mainDataRow.dateTo}
                    </TableCell>
                    {/* 	總薪金 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{mainDataRow.relevantIncome?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}</TableCell>
                    {/* 總僱主MPF */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{mainDataRow.employerMPF?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}</TableCell>
                    {/* 僱主MPF百分比 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{mainDataRow.percentage.toFixed(2)}</TableCell>
                    {/* 實際上班日數 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{mainDataRow.workDayAct?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0'}</TableCell>
                    {/* 工人平均日薪 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{mainDataRow.aveSalary?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}</TableCell>

                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{mainDataRow.subIncome?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{mainDataRow.subMpf?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                    {/* 沒有薪金轉賬收條的糧期 */}
                    <TableCell
                      sx={{
                        fontSize: '15px',
                        textAlign: 'left',
                      }}
                    >
                      {getInPayRollListDesc(mainDataRow)}
                    </TableCell>
                    {/* 沒有薪金轉賬收條糧期的金額 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{mainDataRow.notNetIncomeTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}</TableCell>
                    {/* 沒有強積金轉賬收條的糧期 */}
                    <TableCell
                      sx={{
                        // whiteSpace: "nowrap",
                        fontSize: '15px',
                        textAlign: 'left',
                      }}
                    >
                      {getInMPFSQLListDesc(mainDataRow)}
                    </TableCell>
                    {/* 沒有強積金轉賬收條糧期的 MPF金額 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{mainDataRow.notEmployerMpfTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}</TableCell>
                    {/* 安全主任已批核總工數 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{mainDataRow.soapproved}</TableCell>
                    {/* 安全督導員已批核總工數 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{mainDataRow.ssapproved}</TableCell>
                    {/* 安全主任總金額 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{mainDataRow.socharge?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}</TableCell>
                    {/* 安全督導員總金額 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{mainDataRow.sscharge?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}</TableCell>
                  </TableRow>
                </>
              ))}

              <TableRow key={Math.random + 100}>
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'left',
                  }}
                >
                  {}
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'left',
                  }}
                >
                  {}
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'left',
                  }}
                >
                  {t('icon_vas_wsms_label_total_num') + ':'}
                </TableCell>
                {/* 總薪金  */}
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {dataSource.relevantIncomeTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}
                </TableCell>
                {/* 總僱主MPF  */}
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {dataSource.employerMPFTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}
                </TableCell>
                {/* 僱主MPF百分比  */}
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {dataSource.percentageSum?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}
                </TableCell>
                {/* 實際上班日數  */}
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {dataSource.workDayActTotal?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0'}
                </TableCell>
                {/* 工人平均日薪 */}
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {dataSource.averagesal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}
                </TableCell>

                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {dataSource.subincome?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {dataSource.summpf?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'left',
                  }}
                >
                  {}
                </TableCell>
                {/* 沒有薪金轉賬收條糧期的金額  */}
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {dataSource.NotNetIncomeAllTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'left',
                  }}
                >
                  {}
                </TableCell>
                {/* 沒有強積金轉賬收條糧期的 MPF金額  */}
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {dataSource.NotEmployerMpfAllTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}
                </TableCell>
                {/* 安全主任已批核總工數  */}
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {dataSource.sOApproved1Total}
                </TableCell>
                {/* 安全督導員已批核總工數  */}
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {dataSource.sSApproved1Total}
                </TableCell>
                {/* 安全主任總金額  */}
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {dataSource.sOCharge1Total?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}
                </TableCell>
                {/* 安全督導員總金額  */}
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {dataSource.sSCharge1Total?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </Container>
      </div>
    </Container>
  )
}
