import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
// @mui
import AnnouncementIcon from '@mui/icons-material/Announcement'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import StorageIcon from '@mui/icons-material/Storage'
import { Backdrop, Box, CircularProgress, Drawer } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

// api
import $http from 'src/utils/api/services'

// mock
// hooks
import useResponsive from '../../../hooks/useResponsive'
// components
import NavSection from '../../../components/nav-section'
import Scrollbar from '../../../components/scrollbar'
//
// import { routers } from './config';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}))

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
}

function Nav({ openNav, onCloseNav, type }) {
  const { pathname } = useLocation()
  const { t, i18n } = useTranslation()

  const isDesktop = useResponsive('up', 'lg')
  const [routeList, setRouteList] = useState([])

  const [backOpen, setBackOpen] = useState(false)

  useEffect(() => {
    const getData = async () => {
      setBackOpen(true)
      let menus = await $http('/getMenuList')
      if (typeof menus !== 'undefined') {
        let routeList = menus.data
        for (const item of routeList) {
          setIcon(item)
          if (type !== 'index' && item.key === type) {
            routeList = [...item.children]
          }
        }
        console.log('routeList', routeList)
        console.log('type', type)
        setRouteList([...routeList])
        setBackOpen(false)
      } else {
        setBackOpen(false)
      }
    }
    getData()
    if (openNav) {
      onCloseNav()
    }
  }, [pathname])

  function setIcon(item) {
    if (item.menuCode === 'ICON3') {
      item.icon = SettingsSuggestIcon
    }
    if (item.menuCode === 'ICON1') {
      item.icon = ReceiptLongIcon
    }
    if (item.menuCode === 'ICON2') {
      item.icon = RequestQuoteIcon
    }
    if (item.menuCode === 'ICON4') {
      item.icon = AnnouncementIcon
    }
    if (item.menuCode === 'ICON5') {
      item.icon = StorageIcon
    }
  }

  const renderContent = (
    // <Grid>
    //  <Button>123</Button>
    // </Grid>

    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <NavSection data={routeList} type={type} />
      {/* <NavSection data={navConfig[type]} type={type} /> */}
      <Box sx={{ flexGrow: 1, backgroundColor: '#FFFFFF' }} />
    </Scrollbar>
  )

  return (
    <Box component="nav" sx={{ flexShrink: { lg: 0 }, width: { lg: NAV_WIDTH } }}>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Drawer open variant="permanent" PaperProps={{ sx: { mt: '65px', pb: '65px', width: NAV_WIDTH, bgcolor: 'background.default', borderRightStyle: 'dashed' } }}>
        {renderContent}
      </Drawer>
    </Box>
  )
}

export default Nav
