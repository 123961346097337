import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CPagination } from 'src/components/common'
import CustomDialog from 'src/components/common/CustomDialog'
import * as api from '../../../utils/api/systemAdministrator/SetupUserApi'

const ReportCenterTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    fontSize: '15px',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'center',
  },
}))

const ReportLeftTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    backgroundColor: '#c6c6c6',
    padding: '3px',
    fontSize: '14px',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'Left',
    padding: '3px',
    fontSize: '13px',
  },
}))

const ReportRightTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    fontSize: '14px',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'right',
    padding: '3px',
    fontSize: '13px',
  },
}))

// setup user

export default function SetUpUser() {
  const [isSearchPage, setIsSearchPage] = useState(1)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [subDialogOpen, setSubDialogOpen] = useState(false)

  const { t } = useTranslation()
  const [backLoading, setBackLoading] = useState(false)
  const [sendData, setSendData] = useState({})
  const [tableData, setTableData] = useState([])
  const [newTitle, setNewTitle] = useState('')
  const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 10 })

  const handleShowSizeChange = (pageNumber, pageSize) => {
    setPagination({
      pageNumber,
      pageSize: pageSize > 0 ? pageSize : undefined,
    })
  }

  const [dialog, setDialog] = useState({
    content: '',
    action: (
      <Button variant="contained" onClick={() => setDialog({ ...dialog, open: false })}>
        {t('Confirm')}
      </Button>
    ),
    open: false,
  })
  const [childList, setChildList] = useState([])
  const [roleList, setRoleList] = useState([])
  // const [totalList, setTotalList] = useState([])
  const [totalList, setTotalList] = useState(0)
  const [notSelectRoleList, setNotSelectRoleList] = useState([])
  const [userData, setUserData] = useState({})
  const [mappingDeleteData, setMappingDeleteData] = useState({})
  const [reload, setReload] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    setBackLoading(true)
    const getData = async () => {
      let userWithRole = await api.getUserListWithRoles({
        ...sendData,
        pageNumber: pagination.pageNumber,
        pageSize: pagination.pageSize,
      })
      let userWithRoleAll = await api.getUserListAll(sendData)

      // setTotalList([...userWithRoleAll])
      setTotalList(userWithRoleAll)

      setTableData([...userWithRole])

      setBackLoading(false)
    }
    getData()
  }, [reload, pagination])

  const handleSearch = (data) => {
    setSendData({
      ...sendData,
      loginId: data.loginId,
      userName: data.userName,
      emailAddress: data.emailAddress,
    })
    console.log('nock', 123)
    setReload(!reload)
  }

  const dialogAction = (showCancelButton, handleConfirmDialog, handleCancelDialog) => {
    if (showCancelButton) {
      return (
        <>
          <Button variant="contained" onClick={handleConfirmDialog}>
            {t('Confirm')}
          </Button>
          <Button variant="contained" onClick={handleCancelDialog}>
            {t('button_cancel')}
          </Button>
        </>
      )
    } else {
      return (
        <Button variant="contained" onClick={handleConfirmDialog}>
          {t('Confirm')}
        </Button>
      )
    }
  }

  const deleteData = (row) => {
    setDialog({
      ...dialog,
      open: true,
      content: t('confirm_deletion'),
      action: dialogAction(
        true,
        () => {
          const deleteMenu = async () => {
            setDialogOpen(true)
            let send
            send = await api.sendDelete({
              ...mappingDeleteData,
              roleId: row.roleId,
            })

            if (send.code === 200) {
              setDialogOpen(false)
              setDialog({
                ...dialog,
                content: t('deletion_was_successful'),
                open: true,
                action: dialogAction(
                  false,
                  () => {
                    setDialog({
                      ...dialog,
                      open: false,
                    })
                    handleOpenDialog({ ...mappingDeleteData })
                    // setDialog({ open: false });
                  },
                  null,
                ),
              })
            } else {
              setDialogOpen(false)
              setDialog({
                ...dialog,
                open: true,
                content: send.message,
                action: dialogAction(
                  false,
                  () => {
                    setDialog({
                      ...dialog,
                      open: false,
                    })
                  },
                  null,
                ),
              })
            }
          }
          deleteMenu()

          //
          // let send;
          // send = await api.sendDelete({
          //   ...mappingDeleteData,
          //   roleId: row.roleId,
          // });
          // if (send.code === 200) {
          //   handleOpenDialog({ ...mappingDeleteData });
          //   setDialog({ open: false });
          // } else {
          //   setDialog({ open: false });
          // }
        },
        () => {
          setDialog({
            ...dialog,
            open: false,
          })
        },
      ),
    })

    console.log('11111111111')
  }

  async function handleOpenDialog(row, title) {
    // setNewTitle(title);
    let userByRole = await api.userSelectByUser(row)
    setRoleList(userByRole)
    setDialogOpen(true)
    setMappingDeleteData(row)
  }

  async function handleOpenDialogCovertPower(row) {
    setBackLoading(true)
    let userByRoleCovert = await api.userSelectByUserCovert(row)
    setNotSelectRoleList(userByRoleCovert)
    setBackLoading(false)
    setSubDialogOpen(true)
  }

  const handleChlid = (item, index) => {
    console.log(item, index)
    // 子操作
    let key = 'userId'
    item[key] = mappingDeleteData.userId
    let childIndex = childList.indexOf(item)
    if (childIndex === -1) {
      childList.push(item)
    } else {
      childList.splice(childIndex, 1)
    }
    console.log(childList)
    setChildList([...childList])
  }

  const addData = (row) => {
    setDialog({
      ...dialog,
      open: true,
      content: '確認添加?',
      action: dialogAction(
        true,
        () => {
          const addMenu = async () => {
            let send
            send = await api.addRoleList(row)
            if (send.code === 200) {
              setDialog({
                ...dialog,
                content: t('添加成功'),
                open: true,
                action: dialogAction(
                  false,
                  () => {
                    setDialog({
                      ...dialog,
                      open: false,
                    })
                    setChildList([])
                    handleOpenDialog({ ...mappingDeleteData })
                    setRoleList([...roleList])
                    setSubDialogOpen(false)
                    handleOpenDialog({ ...mappingDeleteData })
                    // setDialog({ open: false });
                  },
                  null,
                ),
              })
              //

              //   action: dialogAction(
              //     false,
              //     () => {
              //       setDialog({
              //         ...dialog,
              //         open: false,
              //           setDialog({ open: false });
              // setChildList([]);
              // handleOpenDialog({ ...mappingDeleteData });
              // setRoleList([...roleList]);
              // setSubDialogOpen(false);
              //       handleOpenDialog({ ...mappingDeleteData });
              //       });

              // setDialog({ open: false });
            } else {
              setDialogOpen(false)
              setDialog({
                ...dialog,
                open: true,
                content: send.message,
                action: dialogAction(
                  false,
                  () => {
                    setDialog({
                      ...dialog,
                      open: false,
                    })
                    setChildList([])
                  },
                  null,
                ),
              })
            }
          }

          addMenu()
        },

        () => {
          setDialog({ ...dialog, open: false })
        },
      ),
    })
  }

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomDialog sx={{ whiteSpace: 'pre-line' }} open={dialog.open} title={dialog.title} content={dialog.content} action={dialog.action} />
      {isSearchPage === 1 && (
        <Container maxWidth="false">
          <Typography variant="h4" sx={{ textAlign: 'center', mb: '10px' }}>
            <b>{t('User_Setup')}</b>
          </Typography>
          <Grid container spacing={2} sx={{ mx: '5px', my: '5px' }}>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                size="small"
                label={t('login_Id')}
                value={sendData.loginId || ''}
                onChange={(e) => {
                  setSendData({ ...sendData, loginId: e.target.value })
                }}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                size="small"
                label={t(' Username')}
                value={sendData.userName || ''}
                onChange={(e) => {
                  setSendData({ ...sendData, userName: e.target.value })
                }}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                size="small"
                label={t('Email')}
                value={sendData.email || ''}
                onChange={(e) => {
                  setSendData({ ...sendData, email: e.target.value })
                }}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Button size="small" variant="contained" onClick={() => handleSearch(sendData)} sx={{ mx: '5px', my: '5px' }}>
                {t('Search')}
              </Button>
              <Button size="small" variant="contained" onClick={() => setSendData({})}>
                {t('Reset')}
              </Button>
            </Grid>
          </Grid>

          <TableContainer>
            <Table size="small" sx={{ mt: '20px' }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                    }}
                  >
                    {t('login_Id')}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                    }}
                  >
                    {t('Username')}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                    }}
                  >
                    {t('Email')}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                    }}
                  >
                    {t('ROLE_DESC_CN')}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                    }}
                  >
                    {t('ENABLE_FLAG')}
                  </TableCell>
                </TableRow>
              </TableHead>
              {!!tableData && tableData.length > 0 && (
                <TableBody>
                  {tableData.map((row, ind) => (
                    <TableRow hover key={ind}>
                      <TableCell sx={{ textAlign: 'left' }}>{row.loginId}</TableCell>
                      <TableCell sx={{ textAlign: 'left' }}>{row.userName}</TableCell>
                      <TableCell sx={{ textAlign: 'left' }}>{row.emailAddress}</TableCell>
                      <TableCell sx={{ textAlign: 'left' }}>
                        <Button size="small" onClick={() => handleOpenDialog(row, t('details'))}>
                          {t('details')}
                        </Button>
                      </TableCell>
                      <TableCell sx={{ textAlign: 'left' }}>{row.enableFlag}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}

              {/* 分頁 */}
              <CPagination total={totalList} rowsPerPage={pagination.pageSize} onChange={handleShowSizeChange} />

              {(!tableData || tableData.length === 0) && (
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }} colSpan={10}>
                      {'No data found !'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Container>
      )}

      <Dialog open={dialogOpen} maxWidth="md">
        <DialogTitle>{t('list_of_roles')}</DialogTitle>
        <Divider />
        <DialogContent>
          <TableContainer>
            <Table size="large">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                    }}
                  >
                    {t('ROLE_ID')}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                    }}
                  >
                    {t('ROLE_CODE')}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                    }}
                  >
                    {t('ROLE_DESC_EN')}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                    }}
                  >
                    {t('ROLE_DESC_CN')}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                    }}
                  >
                    {t('ENABLE_FLAG')}
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                    }}
                  >
                    {t('operate')}
                  </TableCell>
                </TableRow>
              </TableHead>
              {!!roleList && roleList.length > 0 && (
                <TableBody>
                  {roleList.map((row, ind) => (
                    <TableRow hover key={ind}>
                      <TableCell sx={{ textAlign: 'left' }}>{row.roleId}</TableCell>
                      <TableCell sx={{ textAlign: 'left' }}>{row.roleCode}</TableCell>
                      <TableCell sx={{ textAlign: 'left' }}>{row.roleDescEn}</TableCell>
                      <TableCell sx={{ textAlign: 'left' }}>{row.roleDescCn}</TableCell>
                      <TableCell sx={{ textAlign: 'left' }}>{row.enableFlag}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Stack spacing={2} direction="row" justifyContent="center">
                          <Button size="small" variant="outlined" color="error" onClick={() => deleteData(row)}>
                            {t('delete')}
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
              {(!roleList || roleList.length === 0) && (
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }} colSpan={10}>
                      {'No data found !'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleOpenDialogCovertPower(mappingDeleteData)}>{t('add_role')}</Button>
          <Button
            onClick={() => {
              setDialogOpen(false)
              setRoleList(null)
            }}
          >
            {t('quit')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={subDialogOpen}>
        <DialogTitle>{t('list_of_permissions')}</DialogTitle>
        <DialogContent dividers>
          <TableContainer>
            <Table size="large">
              {!!notSelectRoleList && notSelectRoleList.length > 0 && (
                <TableBody>
                  {notSelectRoleList.map((row, ind) => (
                    <FormGroup hover key={row.roleId}>
                      <FormControlLabel control={<Checkbox />} label={row.roleDescEn + ' / ' + row.roleDescCn} value={row.roleId} onChange={() => handleChlid(row, row.roleId)} />
                    </FormGroup>
                  ))}
                </TableBody>
              )}
              {(!notSelectRoleList || notSelectRoleList.length === 0) && (
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }} colSpan={10}>
                      {'No data found !'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => addData(childList)}>{t('submit')}</Button>
          <Button
            onClick={() => {
              setSubDialogOpen(false)
              setChildList([])
            }}
          >
            {t('quit')}
          </Button>
        </DialogActions>
      </Dialog>

      {isSearchPage === 2 && <Container maxWidth={false}>123</Container>}
    </>
  )
}
