import React from 'react'
import { Checkbox, TableCell, tableCellClasses, TableHead, TableRow, TableSortLabel } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(even)': {
        backgroundColor: '#fbfbfb'
    }
}))
const StyledTableCell = styled(TableCell)(() => ({
    padding: '15px 16px',
    borderBottom: 'none',
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#f8f9fa',
        color: '#333',
        fontSize: 15
    }
}))



function CTableHead(props) {
    const { columns, expandable, hide, numSelected = 0, order, orderBy, rowCount = 0, rowSelection, onRequestSort, onSelectAllClick } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <>
            {hide ? null : (
                <TableHead>
                    <StyledTableRow>
                        {rowSelection ? (
                            rowSelection.type === 'checkbox' ? (
                                <StyledTableCell padding="checkbox">
                                    <Checkbox
                                        checked={rowCount > 0 && numSelected === rowCount}
                                        color="primary"
                                        indeterminate={numSelected > 0 && numSelected < rowCount}
                                        inputProps={{ 'aria-label': 'select all desserts' }}
                                        onChange={onSelectAllClick}
                                    />
                                </StyledTableCell>
                            ) : null
                        ) : null}

                        {/* 展開佔位符 */}
                        {expandable ? <StyledTableCell sx={{ paddingLeft: '16px', paddingRight: '16px', width: '82px' }} /> : null}

                        {/* 表頭内容 */}
                        {columns.map((column, index) => (
                            <StyledTableCell key={column.dataIndex || index} align={column.align} sortDirection={orderBy === column.dataIndex ? order : false}
                                sx={{ 
                                    width: column.width ? column.width : 'auto', 
                                    textAlign: column.align, 
                                    ...column.headProps 
                                }}
                            >
                                {/* 判斷是否有排序 undefined：默認展示 */}
                                {typeof column.isOrder === 'undefined' || column.isOrder ? (
                                    <TableSortLabel active={orderBy === column.dataIndex} direction={orderBy === column.dataIndex ? order : 'asc'} onClick={createSortHandler(column.dataIndex)}>
                                        {column.label}
                                    </TableSortLabel>
                                ) : (
                                    <>{column.label}</>
                                )}
                            </StyledTableCell>
                        ))}
                    </StyledTableRow>
                </TableHead>
            )}
        </>
    )
}

export default CTableHead
