import { instance } from "../../instance";

export const setSiteUserMapping = async (searchCondition) => {
  // searchCondition.userId = "oyhpxo";
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/SetSiteUserMapping`,
    data: searchCondition,
  }).then(
    (result) => result.data,
    (error) => error
  );
};

export const updateSiteUserMapping = async (siteUserMapping) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/UpdateSetSiteUserMapping`,
    data: siteUserMapping,
  }).then(
    (result) => result.data,
    (error) => error
  );
}

export const deleteSiteUserMapping = async (siteUserMapping) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/DeleteSetSiteUserMapping`,
    data: siteUserMapping,
  }).then(
    (result) => result.data,
    (error) => error
  );
}