import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {
    Button,
    Box,
    Card,
    Checkbox,
    Container,
    DialogContent,
    FormLabel,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    TableCell,
    TextField,
    Tooltip,
    Typography,
    RadioGroup,
    Radio,
    InputLabel,
    InputAdornment,
    OutlinedInput,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import { CustomDialog, CDatePicker, CAutocomplete, CTable, CPagination, CDialog, CTransferList } from "src/components/common"

import CommonUtils from "src/utils/CommonUtils"

import $http from 'src/utils/api/services'
import { t } from 'i18next';



// 添加數據接口
const handleAddOrUpdateData = async (body) => {
    try {
        // 调用接口
        const data = async () => {
            let data = await $http('/autopay/function/vendor-auth-info/insertOrUpdate', body);
            return data.message;
        }
        return data();
    } catch (error) {
        return null;
    }
};
// 查詢詳情接口
const handleDetailData = async (body) => {
    try {
        // 调用接口
        const data = async () => {
            let data = await $http('/autopay/function/vendor-auth-info/select', body);
            return data.data;
        }
        return data();
    } catch (error) {
        return null;
    }
};

function VendorAuthorForm(prop) {
    const { onClose, username, dialogTitle } = prop;
    // 是否为admin角色
    const [ifAdmin, setIfAdmin] = React.useState(false);
    // 搜索按钮loading
    const [openLoading, setOpenLoading] = React.useState(false);
    // 搜索按钮loading
    const [vendorIdDisable, setVendorIdDisable] = React.useState(true);

    // 表单内容
    const [formData, setFormData] = React.useState({
        userId: '',
        loginId: '',
        vendorId: '',
        vendorDesc: '',
        vendorDescC: '',
        vendorAuthors: [],
    });
    // 表单内容改变
    const handleFormDataChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    // 彈出框
    const [dialog, setDialog] = useState({
        content: "",
        action: (
            <Button
                variant="contained"
                onClick={() => {
                    setDialog({ ...dialog, open: false });
                }}
            >
                {t('Confirm')}
            </Button>
        ),
        open: false,
    });
    useEffect(() => {
        handleDetailData({ username }).then((value) => {
            let authors = [...value.vendorAuthors]
            if (authors.length < 2) {
                const emptyAuthor = {
                    authorizerName: "",
                    authorizerPhone: "",
                }
                for (let index = 0; index < 3 - authors.length; index++) {
                    authors = [...authors, emptyAuthor]
                }
            }
            setFormData({
                userId: value.userId,
                vendorId: value.vendorId,
                vendorDesc: value.vendorDesc,
                vendorDescC: value.vendorDescC,
                vendorAuthors: authors,
            });
        })
    }, [username]);

    // 添加一行新數據
    const handleAddClick = () => {
        if (CommonUtils.isEmptyOrBlank(formData.vendorId)) {
            // 提示是否成功
            setDialog({
                ...dialog,
                content: t("icon2_vendor_author_vendor_null"),
                action: (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setDialog({ ...dialog, open: false });
                        }}
                    >
                        {t('Close')}
                    </Button>
                ),
                open: true,
            });
            return;
        } else if (CommonUtils.isEmptyOrBlank(formData.vendorAuthors) || formData.vendorAuthors?.length < 2) {
            // 提示是否成功
            setDialog({
                ...dialog,
                content: t("icon2_vendor_author_min"),
                action: (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setDialog({ ...dialog, open: false });
                        }}
                    >
                        {t('Close')}
                    </Button>
                ),
                open: true,
            });
            return;
        } else if (formData.vendorAuthors) {
            if (!CommonUtils.isEmptyOrBlank(formData.vendorAuthors)) {
                let errorFlag = false
                formData.vendorAuthors.forEach((author) => {
                    if (CommonUtils.isEmptyOrBlank(author.authorizerName) || CommonUtils.isEmptyOrBlank(author.authorizerPhone)) {
                        errorFlag = true
                    }
                })
                if (errorFlag) {
                    // 提示是否成功
                    setDialog({
                        ...dialog,
                        content: t("icon2_vendor_author_null"),
                        action: (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setDialog({ ...dialog, open: false });
                                }}
                            >
                                {t('Close')}
                            </Button>
                        ),
                        open: true,
                    });
                    return;
                }
            }
        }
        // 打开按钮loading, 防止重复点击
        setOpenLoading(true);
        handleAddOrUpdateData(formData).then((value) => {
            // 关闭按钮loading
            setOpenLoading(false);
            // 提示是否成功
            setDialog({
                ...dialog,
                // content: value ? t('AddSuccess') : t('AddFail'),
                content: value,
                action: (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setDialog({ ...dialog, open: false });
                            onClose();
                        }}
                    >
                        {t('Confirm')}
                    </Button>
                ),
                open: true,
            });
        });

    }
    // 编辑一行新數據
    const handleEditClick = () => {

        if (CommonUtils.isEmptyOrBlank(formData.vendorId)) {
            // 提示是否成功
            setDialog({
                ...dialog,
                content: t("icon2_vendor_author_vendor_null"),
                action: (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setDialog({ ...dialog, open: false });
                        }}
                    >
                        {t('Close')}
                    </Button>
                ),
                open: true,
            });
            return;
        } else if (CommonUtils.isEmptyOrBlank(formData.vendorAuthors) || formData.vendorAuthors?.length < 2) {
            // 提示是否成功
            setDialog({
                ...dialog,
                content: t("icon2_vendor_author_min"),
                action: (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setDialog({ ...dialog, open: false });
                        }}
                    >
                        {t('Close')}
                    </Button>
                ),
                open: true,
            });
            return;
        } else if (formData.vendorAuthors) {
            if (!CommonUtils.isEmptyOrBlank(formData.vendorAuthors)) {
                let errorFlag = false
                formData.vendorAuthors.forEach((author) => {
                    if (CommonUtils.isEmptyOrBlank(author.authorizerName) || CommonUtils.isEmptyOrBlank(author.authorizerPhone)) {
                        errorFlag = true
                    }
                })
                if (errorFlag) {
                    // 提示是否成功
                    setDialog({
                        ...dialog,
                        content: t("icon2_vendor_author_null"),
                        action: (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setDialog({ ...dialog, open: false });
                                }}
                            >
                                {t('Close')}
                            </Button>
                        ),
                        open: true,
                    });
                    return;
                }
            }
        }
        // 打开按钮loading, 防止重复点击
        setOpenLoading(true);
        handleAddOrUpdateData(formData).then((value) => {
            // 关闭按钮loading
            setOpenLoading(false);
            // 提示是否成功
            setDialog({
                ...dialog,
                content: value,
                action: (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setDialog({ ...dialog, open: false });
                            onClose();
                        }}
                    >
                        {t('Confirm')}
                    </Button>
                ),
                open: true,
            });
        }).catch((error) => {
            console.log("error:");
            console.log(error);
        });

    }
    // 重置添加表单
    const handleResetAddClick = () => {
        setFormData({
            ...formData,
            vendorAuthors: [],
        });
    };
    // 重置修改表单
    const handleResetEditClick = () => {
        setFormData({
            ...formData,
            vendorAuthors: [],
        });
    };

    // 打開新建授權人文本框
    const handleAddAuthorClick = () => {
        const newVendorAuthor = {
            authorizerName: "",
            authorizerPhone: "",
        }
        if (formData.vendorAuthors) {
            setFormData({
                ...formData,
                vendorAuthors: [...formData.vendorAuthors, newVendorAuthor]
            })
        } else {
            setFormData({
                ...formData,
                vendorAuthors: [newVendorAuthor]
            })
        }
    };
    const handleNewDataChange = (event, changeIndex) => {
        const oldList = formData.vendorAuthors.slice();

        const newList = oldList.map((vendorAuthor, index) => {
            if (index === changeIndex) {
                const newAuthor = {
                    ...vendorAuthor,
                    [event.target.name.slice(0, -1)]: event.target.name.slice(0, -1) === "authorizerName" ? event.target.value.slice(0, 50) : event.target.name.slice(0, -1) === "authorizerPhone" ? event.target.value.slice(0, 20) : event.target.value,
                }
                return newAuthor
            }
            return vendorAuthor
        })
        setFormData({
            ...formData,
            vendorAuthors: newList,
        });
    };
    return (
        <>
            {/* 提示框 */}
            <CustomDialog
                sx={{ whiteSpace: "pre-line" }}
                open={dialog.open}
                title={dialog.title}
                content={dialog.content}
                action={dialog.action}
            />
            <DialogContent dividers sx={{ pt: 3, pb: 3 }}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs={12} />
                    <Grid item xs={12} >
                        敬啟者 :
                    </Grid>
                    <Grid item xs={12} >
                        &emsp;&emsp;鑑於近日有分判商負責人因病需入院治療而未能簽署工人出糧文件，導致影響工人無法領取薪金問題，為避免出現類似情況，我 司經開會商討後，現請各分判商需到以下連結提供至少兩名授權人的姓名及聯絡資料，以供我司在緊急情況時聯絡。這些授權人必須能代表貴 司簽署工人出糧文件及支票，或涉及其他工程開支的文件及支票。謝謝合作！
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12} />
                    <Grid item xs={12}>
                        <CAutocomplete
                            disabled={vendorIdDisable}
                            required
                            url='/vps/common/getVendorForAuthor'
                            name='vendorId'
                            label={t("company_name")}
                            value={!!formData.vendorId ? { id: formData.vendorId, text: formData.vendorDesc } : null}
                            onChange={(event, newData) => {
                                if (!!newData) {
                                    setFormData({
                                        ...formData,
                                        vendorId: !!newData ? newData.id : null,
                                        vendorDesc: !!newData ? newData.text : null,
                                    })
                                } else {
                                    setFormData({
                                        ...formData,
                                        vendorId: null,
                                        vendorDesc: null,
                                    })
                                }
                            }}
                        />
                        <Tooltip title={t("icon2_add_author")}>
                            <IconButton onClick={(event) => handleAddAuthorClick(event)}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    {
                        formData.vendorAuthors?.map((vendorAuthor, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Grid item xs={12}>
                                        <TextField
                                            multiline fullWidth
                                            id="outlined-adornment-authorizerName"
                                            name={'authorizerName' + index} value={vendorAuthor.authorizerName || ""} size="small" label={t("vendor_author") + "(" + ++index + ")"} onChange={(event) => handleNewDataChange(event, --index)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">{t("Mr/Ms")}</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            multiline fullWidth
                                            name={'authorizerPhone' + index} value={vendorAuthor.authorizerPhone || ""} size="small" label={t("phone")} onChange={(event) => handleNewDataChange(event, --index)}
                                        />
                                    </Grid>
                                </React.Fragment>
                            )
                        })
                    }
                    <Grid item xs={12} sx={{ textAlign: "center", marginTop: 2 }}>
                        <FormControl>
                            <LoadingButton
                                loading={openLoading}
                                variant="contained"
                                sx={{
                                    backgroundColor: "#2d8d27",
                                    color: "commom.white",
                                }}
                                onClick={dialogTitle === t('Add') ? handleAddClick : handleEditClick}
                            >
                                {dialogTitle}
                            </LoadingButton>
                        </FormControl>
                        <FormControl sx={{ marginLeft: "10px" }}>
                            <Button
                                variant="contained"
                                onClick={dialogTitle === t('Add') ? handleResetAddClick : handleResetEditClick}
                                sx={{
                                    backgroundColor: "primaryGreen",
                                    color: "commom.white",
                                    marginRight: "10px",
                                }}
                                label={t('Reset')}
                            >
                                {t('Reset')}
                            </Button>
                        </FormControl>
                        <FormControl >
                            <Button
                                variant="contained"
                                onClick={onClose}
                                sx={{
                                    backgroundColor: "#9b9b9b",
                                    color: "commom.white",
                                    marginRight: "10px",
                                }}
                                label={t('Close')}
                            >
                                {t('Close')}
                            </Button>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>

        </>
    )
}
export default VendorAuthorForm;