import { instance } from "../../instance";

// 控制新增糧期表狀況-顯示
export const selectStatus = async () => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/managementController/maintreceiptconversionactionView`,
    }).then(
        (result) => result.data,
        (error) => error
    );
};
// 控制新增糧期表狀況-修改
export const updateStatus = async () => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/managementController/maintreceiptconversionaction`,
    }).then(
        (result) => result.data,
        (error) => error
    );
};