import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Link,
  TableRow,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as apiAtt from "src/utils/api/workerAttendanceRecord/AutoPayApiAttend";
import { getComparator, stableSort } from "../../TableSort";

export default function ApplyList() {
  const componentRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("siteId");
  const [attendDayList, setAttendDayList] = React.useState([]);

  useEffect(() => {
    const getPFSApplicationApply1 = async () => {
      let list = await apiAtt.getPFSApplicationApply1();

      setAttendDayList(list);
      setLoading(false);
    };
    getPFSApplicationApply1();
  }, []);

  function clickCell(index) {
    switch (index) {
      case 1:
        setOrderBy("siteId");
        break;
      case 2:
        setOrderBy("smartCardId");
        break;
      case 3:
        setOrderBy("englishName");
        break;
      case 4:
        setOrderBy("chineseName");
        break;
      case 5:
        setOrderBy("safetyPositionDesc");
        break;
      case 6:
        setOrderBy("spApprovedDate");
        break;
      case 7:
        setOrderBy("effectiveDate1");
        break;
      case 8:
        setOrderBy("effectiveDateTo");
        break;
      default:
        setOrderBy("siteId");
        break;
    }

    if (order === "asc") {
      setOrder("desc");
    } else {
      setOrder("asc");
    }
  }

  const goDetail = (row) => {
    navigate({
      pathname: "/workerAttendanceRecord/adocspMain/applyList/applyListDetail",
      search: `?${createSearchParams({
        smartCardId: row.smartCardId,
        siteId: row.siteId,
        effectiveDate1: row.effectiveDate1,
        effectiveDateTo: row.effectiveDateTo,
        spApprovedDate: row.spApprovedDate,
        dateTo: row.dateTo || "",
      })}`,
    });
  };

  const handleBack = () => {
    navigate("/workerAttendanceRecord/adocspMain");
  };

  return (
    <>
      <div style={{ display: "block", textAlign: "left" }}>
        <Button sx={{ float: "left"}} onClick={handleBack}>
          <ArrowBackIcon />
          {t("button_back")}
        </Button>
      </div>
      <div style={{ display: "block"}}>
        <Typography variant="h4" sx={{ display: "block", textAlign: "center" }}>
          <b>{t("search_vendor_ss_outDays")}</b>
        </Typography>
      </div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div id="div" ref={componentRef} style={{}}>
        <Container maxWidth="false">
          <div>
            <div style={{ display: "block", textAlign: "left" }}>
              {/* table */}
              <TableContainer>
                <Table sx={{ p: "2px" }} size="small">
                  {attendDayList.length > 0 && (
                    <TableHead>
                      <TableRow hover>
                        <TableCell>
                          <Link
                            style={{ cursor: 'pointer' }}
                            underline="none"
                            sx={{ whiteSpace: "nowrap" }}
                            onClick={() => clickCell(1)}
                          >
                            {t("search_site_title")}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            style={{ cursor: 'pointer' }}
                            underline="none"
                            sx={{ whiteSpace: "nowrap" }}
                            onClick={() => clickCell(2)}
                          >
                            {t("search_smartCardId_notDot")}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            style={{ cursor: 'pointer' }}
                            underline="none"
                            sx={{ whiteSpace: "nowrap" }}
                            onClick={() => clickCell(3)}
                          >
                            {t("search_englishName_notDot")}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            style={{ cursor: 'pointer' }}
                            underline="none"
                            sx={{ whiteSpace: "nowrap" }}
                            onClick={() => clickCell(4)}
                          >
                            {t("search_chinese_name_notDot")}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            style={{ cursor: 'pointer' }}
                            underline="none"
                            sx={{ whiteSpace: "nowrap" }}
                            onClick={() => clickCell(5)}
                          >
                            {t("search_employmentPosition")}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            style={{ cursor: 'pointer' }}
                            underline="none"
                            sx={{ whiteSpace: "nowrap" }}
                            onClick={() => clickCell(6)}
                          >
                            {t("search_approveDate_title")}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            style={{ cursor: 'pointer' }}
                            underline="none"
                            sx={{ whiteSpace: "nowrap" }}
                            onClick={() => clickCell(7)}
                          >
                            {t("search_enttyValidDateFrom")}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            style={{ cursor: 'pointer' }}
                            underline="none"
                            sx={{ whiteSpace: "nowrap" }}
                            onClick={() => clickCell(8)}
                          >
                            {t("search_enttyValidDateTo")}
                          </Link>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {t("search_apply_record")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  )}

                  <TableBody>
                    {/* {排序} attendDayList.sort(currentSortType[currentSort].fn)  */}
                    {stableSort(
                      attendDayList,
                      getComparator(order, orderBy)
                    ).map((row, index) => (
                      <TableRow hover key={index + Math.random}>
                        <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                          {row.siteId + " - " + row.siteDesc}
                        </TableCell>
                        <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                          {row.smartCardId}
                        </TableCell>
                        <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                          {row.englishName}
                        </TableCell>
                        <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                          {row.chineseName}
                        </TableCell>
                        <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                          {row.safetyPositionDesc}
                        </TableCell>
                        <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                          {row.spApprovedDate}
                        </TableCell>
                        <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                          {row.effectiveDate1}
                        </TableCell>
                        <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                          {row.dateTo}
                        </TableCell>
                        <TableCell>
                          <Link
                            style={{ cursor: 'pointer' }}
                            underline="none"
                            sx={{ whiteSpace: "nowrap" }}
                            onClick={() => goDetail(row)}
                          >
                            {t("details")}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
