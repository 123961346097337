import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Divider,
  Container,
  Grid,
  MenuItem,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  tableCellClasses,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import $http, { $download } from 'src/utils/api/services'
import moment from 'moment'
import * as api from '../../../utils/api/vendorAutopaySystem/AutopayPayrollReportByPeriodApi'

const ReportTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: '15px',
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'right',
    padding: '3px',
    fontSize: '13px',
  },
}))

// 承判商自動轉賬支薪報告

export default function AutopayPayrollReportByPeriod() {
  const [isSearchPage, setIsSearchPage] = useState(true)
  const { t } = useTranslation()
  const [backOpen, setBackOpen] = useState(false)
  const [siteList, setSiteList] = useState([])
  const [subList, setSubList] = useState([])
  const [dateList, setDateList] = useState([])
  const [sendData, setSendData] = useState({})
  const [tableData, setTableData] = useState([])
  const [vendorIds, setVendorIds] = useState({})
  const [lastDay, setLastDay] = useState([])

  useEffect(() => {
    setBackOpen(false)
    const getData = async () => {
      let getSubContractor = await api.getSubContractorList()
      let getDateList = await api.getPeriodYear()

      if (getDateList.length === 0) {
        getDateList.splice(0, 0, { id: '', text: 'nothing' })
        setDateList(getDateList)
      } else {
        setDateList(getDateList)
      }

      if (getSubContractor) {
        setSubList(getSubContractor)
      }

      setSendData({
        ...sendData,
        dataType: 'Screen',
      })
      setBackOpen(false)
    }
    getData()
  }, [])

  const handleBack = () => {
    setIsSearchPage(true)
  }

  const handleSubcontractors = (data) => {
    setSendData({ ...sendData, vendorId: data.id, vendorDesc: data.text })
  }

  const handleDataFrom = (data) => {
    setSendData({ ...sendData, year: data.id })
  }

  const handleType = (e) => {
    setSendData({ ...sendData, dataType: e.target.value })
  }

  const handleSubmit = (e) => {
    if (!sendData.year) {
      alert('請選擇自動轉賬日期')
      return
    }
    setBackOpen(true)
    const send = async () => {
      if (sendData.dataType === 'Screen') {
        let getData = await api.getData(sendData)
        if (getData) {
          setTableData(getData)
          setIsSearchPage(false)
        }
        setBackOpen(false)
      }
      if (sendData.dataType === 'EXCEL') {
        await exportExcel(sendData)
          .then((result) => setBackOpen(false))
          .catch((error) => console.log(error))
      } else if (sendData.dataType === 'PDF') {
        await exportPdf(sendData)
          .then((result) => etBackOpen(false))
          .catch((error) => console.log(error))
      }

      if (typeof sendData.vendorId !== 'undefined') {
        if (sendData.vendorId !== '') {
          let vendorId = await api.getVendorId(sendData)
          setVendorIds(vendorId)
        } else {
          setVendorIds('')
        }
      } else {
        setVendorIds('')
      }

      //   console.log("qiming", vendorIds);

      let date = new Date(sendData.year)
      date.setMonth(date.getMonth() + 1)
      date.setDate(0)
      let lastDay = date.toLocaleDateString()
      setLastDay(lastDay)
      console.log('lastDay', lastDay)

      setBackOpen(false)
    }
    send()
  }

  const resetForm = (e) => {
    setSendData({ dataType: 'Screen' })
  }

  const exportPdf = (data) => {
    return $download(`/payrollReportController/vendorAutoPayStatusByPayrollListPDF`, 'application/pdf', data)
  }

  const exportExcel = (data) => {
    return $download(`/payrollReportController/vendorAutoPayStatusByPayrollListExcel`, 'application/vnd.ms-excel', data)
  }

  // 承判商自動轉賬支薪報告
  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {isSearchPage && (
        <form>
          <Container maxWidth="md" sx={{ textAlign: 'center' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                  <b>{t('autopayPayrollReportByPeriod')}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField sx={{ textAlign: 'left', maxWidth: '400px' }} value={sendData.vendorId || ''} fullWidth size="small" label={t('Contractor')} select>
                  <MenuItem value="" onClick={() => handleSubcontractors({ id: '', text: '所有承判商' })}>
                    {' '}
                    所有承判商
                  </MenuItem>
                  {subList.map((item, index) => (
                    <MenuItem key={index} value={item.id} onClick={() => handleSubcontractors(item)}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField error={!sendData.year} sx={{ textAlign: 'left', maxWidth: '400px' }} value={sendData.year || ''} fullWidth size="small" label={t('Autopay_Date')} select>
                  {dateList.map((item, index) => (
                    <MenuItem key={index} value={item.id} onClick={() => handleDataFrom(item)}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ textAlign: 'left', maxWidth: '400px' }}
                  value={sendData.dataType || ''}
                  fullWidth
                  size="small"
                  label={t('icon1_useraccesslog_export_type_label')}
                  type="date"
                  onChange={handleType}
                  select
                >
                  <MenuItem value="Screen">Screen</MenuItem>
                  <MenuItem value="PDF">PDF</MenuItem>
                  <MenuItem value="EXCEL">EXCEL</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    backgroundColor: 'primaryBlue',
                    color: 'commom.white',
                  }}
                >
                  {t('button_submit')}
                </Button>
                <Button
                  variant="contained"
                  onClick={resetForm}
                  sx={{
                    marginLeft: '20px',
                    backgroundColor: 'primaryGreen',
                    color: 'commom.white',
                    marginRight: '10px',
                  }}
                >
                  {t('button_reset')}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </form>
      )}

      {!isSearchPage && (
        <Container maxWidth={false}>
          <div
            style={{
              display: 'block',
              textAlign: 'left',
              marginTop: '0px',
            }}
          >
            <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
              <ArrowBackIcon />
              {t('button_back')}
            </Button>
          </div>

          <div style={{ display: 'block', textAlign: 'right' }}>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportExcel(sendData)}>
              <DownloadIcon />
              {t('button_export_excel')}
            </Button>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportPdf(sendData)}>
              <DownloadIcon />
              {t('button_export_pdf')}
            </Button>
          </div>
          <div>
            <Typography variant="h4" style={{ textAlign: 'center', mt: '-30px' }}>
              <b>{t('autopayPayrollReportByPeriod')}</b>
            </Typography>
            <TableContainer>
              <Table sx={{ textAlign: 'left' }} size="small">
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('Production_Date')}:</TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{moment(new Date().getTime()).format('yyyy/MM/DD')}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('Contractor')}:</TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>
                      {sendData.vendorId === '' || typeof sendData.vendorId === 'undefined' ? t('icon_vas_wsmszo_vendor') : sendData?.vendorDesc}
                    </TableCell>
                  </TableRow>

                  {sendData.vendorId !== '' && typeof sendData.vendorId !== 'undefined' && (
                    <>
                      <TableRow>
                        <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('Contractor_zhi')}:</TableCell>
                        <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>
                          {sendData.year} ~ {lastDay}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('Contractor_lian')}:</TableCell>
                        <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{vendorIds.contactPerson}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('Contractor_tel')}:</TableCell>
                        <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{vendorIds.phoneNumber}</TableCell>
                      </TableRow>
                    </>
                  )}

                  <TableRow>
                    {/* Total */}
                    <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('search_total_dot')}</TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{tableData?.length}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer sx={{ maxHeight: '80vh', maxWidth: '100vw' }}>
              <Table size="small" sx={{ mt: '20px' }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <ReportTableCell>{t('Site_Name')}</ReportTableCell>
                    <ReportTableCell>{t('Payroll_period') + t('from')}</ReportTableCell>
                    <ReportTableCell>{t('Payroll_period') + t('to')}</ReportTableCell>
                    {sendData.vendorId === '' && <ReportTableCell>{t('Contractor')}</ReportTableCell>}
                    <ReportTableCell>{t('search_work_type')}</ReportTableCell>
                    <ReportTableCell>{t('ChineseName')}</ReportTableCell>
                    <ReportTableCell>{t('EnglishName')}</ReportTableCell>
                    <ReportTableCell>{t('icon1_useraccesslog_smart_card_label')}</ReportTableCell>
                    <ReportTableCell>{t('Direct_Employer')}</ReportTableCell>
                    <ReportTableCell>{t('Emp_Type')}</ReportTableCell>
                    <ReportTableCell>{t('icon1_autopay_button8_print_label7')}</ReportTableCell>
                    <ReportTableCell>{t('Number_Of_Workers_Working_Days')}</ReportTableCell>
                    <ReportTableCell>{t('Actual_number_of_working_days')}</ReportTableCell>
                    <ReportTableCell>{t('Worker_income')}</ReportTableCell>
                    <ReportTableCell>{t('Actual_average_income')}</ReportTableCell>
                    <ReportTableCell>{t('icon1_autopay_button7_label6')}</ReportTableCell>
                    <ReportTableCell>{t('Employee_Account_Number')}</ReportTableCell>
                    <ReportTableCell>{t('plan_Member_Number')}</ReportTableCell>
                    <ReportTableCell>{t('Remark')}</ReportTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((item, index) => (
                    <TableRow
                      hover
                      key={index}
                      sx={{
                        backgroundColor: index % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                      }}
                    >
                      <TableCell sx={{ textAlign: '' }}>{item.siteDesc}</TableCell>
                      <TableCell sx={{ textAlign: '' }}>{item.dateFrom}</TableCell>
                      <TableCell sx={{ textAlign: '' }}>{item.dateTo}</TableCell>
                      {sendData.vendorId === '' && <TableCell sx={{ textAlign: '' }}>{item.vendorDesc}</TableCell>}
                      <TableCell sx={{ textAlign: '' }}>{item.jobNatureDesc}</TableCell>
                      <TableCell sx={{ textAlign: '' }}>{item.chineseName}</TableCell>
                      <TableCell sx={{ textAlign: '' }}>{item.englishName}</TableCell>
                      <TableCell sx={{ textAlign: '' }}>{item.smartCardId}</TableCell>
                      <TableCell sx={{ textAlign: '' }}>{item.employerName}</TableCell>
                      <TableCell sx={{ textAlign: '' }}>{item.empTypeDesc}</TableCell>
                      <TableCell sx={{ textAlign: '' }}>{item.mobile}</TableCell>
                      <TableCell sx={{ textAlign: '' }}>{item.workDay}</TableCell>
                      <TableCell sx={{ textAlign: '' }}>{item.workDayAct}</TableCell>
                      <TableCell sx={{ textAlign: '' }}>{item.relevantIncomefomate}</TableCell>
                      <TableCell sx={{ textAlign: '' }}>{item.avgRelevantIncomefomate}</TableCell>
                      <TableCell sx={{ textAlign: '' }}>{item.basicSalaryfomate}</TableCell>
                      <TableCell sx={{ textAlign: '' }}>{item.bankAccount}</TableCell>
                      <TableCell sx={{ textAlign: '' }}>{item.mpfScheme}</TableCell>
                      <TableCell sx={{ textAlign: '' }}>{item.payrollRemark}</TableCell>
                    </TableRow>
                  ))}
                  {(!tableData || tableData.length === 0) && (
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ textAlign: 'center' }} colSpan={19}>
                          {'No data found !'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Container>
      )}
    </>
  )
}
