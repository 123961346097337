import React, { useState, useRef, useEffect } from 'react'
import { Container, Grid, Typography, MenuItem, TextField, FormControl, Backdrop, CircularProgress } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { useNavigate, createSearchParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useForm } from 'react-hook-form'
import CustomDialog from 'src/components/common/CustomDialog'
import moment from 'moment'
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import { parseInt } from 'lodash'

// 承判商工人支薪記錄表---搜索界面（acmemw有数据
export default function CwprfSearch() {
  const navigate = useNavigate()
  const componentRef = useRef()
  const { t } = useTranslation()
  const [siteId, setSiteId] = useState('')
  const [siteDesc, setSiteDesc] = useState('')
  const [loading, setLoading] = React.useState(false)
  const [siteList, setSiteList] = useState([])
  const [subContractList, setSubContractList] = useState([])
  const [shortPeriodDateFrom, setShortPeriodDateFrom] = useState('')
  const [shortPeriodDateTo, setShortPeriodDateTo] = useState('')
  const [smartCardId, setSmartCardId] = useState('')
  const [englishName, setEnglishName] = useState('')
  const [chineseName, setChineseName] = useState('')
  const [subContractorId, setSubContractorId] = useState('')
  const [subContractorDesc, setSubContractorDesc] = useState('')

  const [verify, setVerify] = useState({
    shortPeriodDateFrom: false,
    shortPeriodDateTo: false,
  })

  useEffect(() => {}, [])

  const handleBack = () => {
    navigate(-1)
  }

  const { control, handleSubmit, setValue, getValues, reset } = useForm({
    defaultValues: {
      subContract: '',
      dateFrom: '',
      dateTo: '',
      outputType: '',
    },
  })

  const resetForm = (data) => {
    setShortPeriodDateFrom('')
    setShortPeriodDateTo('')
    setSiteId('')
    setSiteDesc('')
    setChineseName('')
    setEnglishName('')
    setSmartCardId('')
    setSubContractorId('')
    setSubContractorDesc('')
  }

  const [dialog, setDialog] = useState({
    title: '',
    content: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        {t('Confirm')}
      </Button>
    ),
    open: false,
  })

  const showDetailResult = (data) => {
    console.log('subContractorId', subContractorId)
    if (!siteId) {
      setDialog({
        ...dialog,
        content: '請選擇地盤',
        open: true,
      })
      return
    }

    if (!shortPeriodDateFrom) {
      setDialog({
        ...dialog,
        content: t('search_chooseDatetips') + '(' + t('search_from_tips') + ')',
        open: true,
      })
      return
    }
    if (!shortPeriodDateTo) {
      setDialog({
        ...dialog,
        content: t('search_chooseDatetips') + '(' + t('search_to_tips') + ')',
        open: true,
      })
      return
    }

    let fromDate = moment(shortPeriodDateFrom, 'YYYY/MM/DD')
    let toDate = moment(shortPeriodDateTo, 'YYYY/MM/DD')

    if (fromDate > toDate) {
      setDialog({
        ...dialog,
        content: '日期（由） 不能大於 日期（至）!',
        open: true,
      })
      return
    }

    // '2000/08/07'
    // '2100/08/07',
    // 'TSWTL-33',
    navigate({
      pathname: '/workerAttendanceRecord/cwprfSearch/cwprfDetail',
      search: `?${createSearchParams(
        {
          accessDateFrom: shortPeriodDateFrom,
          accessDateTo: shortPeriodDateTo,
          siteId: siteId,
          siteDesc: siteDesc,
          chineseName: chineseName,
          englishName: englishName,
          smartCardId: smartCardId,
          subContractorId: subContractorId,
          subContractorDesc: subContractorDesc,
        },
        { state: { fromSpecificPage: true } },
      )}`,
    })
  }

  // 选了地盘后，需要请求分判商
  const handleSiteId = (data) => {
    setLoading(true)
    const getSub = async () => {
      let getSubConList = await apiAtt.getSubContractorId(data.id)
      if (getSubConList) {
        setSubContractList(getSubConList)
      }
      setLoading(false)
      setSiteId(data.id)
      setSiteDesc(data.text)
    }
    getSub()
  }

  const handleSubContractorId = (data) => {
    setSubContractorId(data.id)
    setSubContractorDesc(data.text)
    console.log(subContractorId)
  }

  useEffect(() => {
    const getFillSite = async () => {
      let getFillSiteSelect = await apiAtt.getFillSite()
      if (getFillSiteSelect) {
        const result = getFillSiteSelect
        setSiteList(result)
      }
    }
    setShortPeriodDateFrom(moment(getPreMonth(moment(new Date()).format('yyyy-MM-DD'))).format('yyyy-MM-DD'))
    setShortPeriodDateTo(moment(new Date()).format('yyyy-MM-DD'))

    getFillSite()
  }, [])

  const getPreMonth = (date) => {
    console.log('date', date)
    let arr = date.split('-')
    let year = arr[0] // 获取当前日期的年份
    let month = arr[1] // 获取当前日期的月份
    let day = arr[2] // 获取当前日期的日
    let days = new Date(year, month, 0)
    days = days.getDate() // 获取当前日期中月的天数
    let year2 = year
    let month2 = parseInt(month) - 1
    if (month2 === 0) {
      year2 = parseInt(year2) - 1
      month2 = 12
    }
    let day2 = day
    let days2 = new Date(year2, month2, 0)
    days2 = days2.getDate()
    if (day2 > days2) {
      day2 = days2
    }
    if (month2 < 10) {
      month2 = '0' + month2
    }
    let t2 = year2 + '-' + month2 + '-' + day2
    return t2
  }

  return (
    <>
      {
        <>
          <div style={{ display: 'block', textAlign: 'left' }}>
            <Button sx={{ float: 'left' }} onClick={handleBack}>
              <ArrowBackIcon />
              {t('button_back')}
            </Button>
          </div>
          <div id="div" ref={componentRef}>
            <Container>
              <div>
                <div style={{ display: 'block' }}>
                  <Typography variant="h4" style={{ color: 'black', textAlign: 'center' }}>
                    <b>{t('search_vendor_pay_record')}</b>
                  </Typography>

                  <Backdrop
                    sx={{
                      color: '#fff',
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>

                  <Container
                    sx={{
                      width: '500px',
                      borderColor: '#d3d3d3',
                      borderRadius: 4,
                    }}
                  >
                    <Grid item xs={6} sx={{ margin: 'auto', width: '400px', marginTop: '10px' }}>
                      <TextField error={!siteId} id="siteDesc" value={siteId || ''} fullWidth size="small" label={t('Site_Name')} select>
                        <MenuItem value="" onClick={() => handleSiteId({ id: null, text: 'All' })}>
                          None
                        </MenuItem>
                        {siteList.map((item, index) => (
                          <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleSiteId(item)}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={6} sx={{ margin: 'auto', width: '400px', marginTop: '10px' }}>
                      <TextField id="subContractId" value={subContractorId || ''} fullWidth size="small" label={t('Subcontractors_(direct employers)')} select>
                        <MenuItem value="" onClick={() => handleSubContractorId({ id: null, text: 'All' })}>
                          None
                        </MenuItem>
                        {subContractList.map((item, index) => (
                          <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleSubContractorId(item)}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={6} sx={{ margin: 'auto', width: '400px' }}>
                      <TextField
                        type="date"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        sx={{
                          textAlign: 'left',
                          maxWidth: '400px',
                          mt: '20px',
                        }}
                        label={t('search_form_date_dot')}
                        error={!shortPeriodDateFrom}
                        value={shortPeriodDateFrom}
                        inputProps={{
                          max: '2099-12-31',
                        }}
                        onChange={(event) => {
                          setShortPeriodDateFrom(event.target.value)
                        }}
                        format="yyyy/MM/dd"
                      />
                    </Grid>

                    <Grid item xs={6} sx={{ margin: 'auto', width: '400px' }}>
                      <TextField
                        type="date"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        sx={{
                          textAlign: 'left',
                          maxWidth: '400px',
                          mt: '20px',
                        }}
                        label={t('search_to_date_dot')}
                        error={!shortPeriodDateTo}
                        value={shortPeriodDateTo}
                        inputProps={{
                          max: '2099-12-31',
                        }}
                        onChange={(event) => {
                          setShortPeriodDateTo(event.target.value)
                        }}
                        format="yyyy/MM/dd"
                      />
                    </Grid>

                    {/* 智能卡編號 */}
                    <Grid item xs={6} sx={{ margin: 'auto', width: '400px' }}>
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        sx={{
                          textAlign: 'left',
                          maxWidth: '400px',
                          mt: '20px',
                        }}
                        label={t('search_smartcardId')}
                        // 設置了value可以進行重置
                        value={smartCardId}
                        onChange={(event) => {
                          setSmartCardId(event.target.value)
                        }}
                      />
                    </Grid>

                    {/* 英文姓名 */}
                    <Grid item xs={6} sx={{ margin: 'auto', width: '400px' }}>
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        sx={{
                          textAlign: 'left',
                          maxWidth: '400px',
                          mt: '20px',
                        }}
                        label={t('search_english_not_dot')}
                        // 設置了value可以進行重置
                        value={englishName}
                        onChange={(event) => {
                          setEnglishName(event.target.value)
                        }}
                      />
                    </Grid>

                    {/* 中文姓名 */}
                    <Grid
                      item
                      xs={6}
                      sx={{
                        margin: 'auto',
                        width: '400px',
                        marginBottom: '20px',
                      }}
                    >
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        sx={{
                          textAlign: 'left',
                          maxWidth: '400px',
                          mt: '20px',
                        }}
                        label={t('search_chinese_not_dot')}
                        // 設置了value可以進行重置
                        value={chineseName}
                        onChange={(event) => {
                          setChineseName(event.target.value)
                        }}
                      />
                    </Grid>
                  </Container>
                </div>
              </div>

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '18px',
                }}
              >
                {/* 提交按钮 */}
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <FormControl size="small">
                    <Button
                      sx={{
                        backgroundColor: 'primaryBlue',
                        color: 'commom.white',
                      }}
                      variant="contained"
                      onClick={handleSubmit(showDetailResult)}
                    >
                      {t('button_submit')}
                    </Button>
                  </FormControl>

                  {/* 重置按钮 */}
                  <FormControl size="small">
                    <Button
                      variant="contained"
                      onClick={resetForm}
                      label="Reset"
                      sx={{
                        backgroundColor: 'primaryGreen',
                        color: 'commom.white',
                        marginLeft: '20px',
                      }}
                    >
                      {t('button_reset')}
                    </Button>
                  </FormControl>
                </Grid>
              </div>
            </Container>
          </div>
        </>
      }

      <CustomDialog open={dialog.open} title={dialog.title} content={dialog.content} action={dialog.action} />
    </>
  )
}
