import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {
    Button,
    Box,
    Card,
    Collapse,
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    FormControlLabel,
    Grid,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TablePagination,
    IconButton,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
    RadioGroup,
    Radio,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles'
import LoadingButton from '@mui/lab/LoadingButton';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import SettingsIcon from '@mui/icons-material/Settings'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import moment from 'moment';
// import * as api from "../../../utils/api/systemAdministrator/CategorySetupApi";
import { CustomDialog, CDatePicker, CAutocomplete, CTable, CPagination, CDialog } from "src/components/common"
import CommonUtils from "src/utils/CommonUtils"

import $http, { $download } from 'src/utils/api/services'
import { t } from 'i18next';

// 添加數據接口
const handleAddData = async (body) => {
    try {
        // 调用接口
        const data = async () => {
            let data = await $http('/managementController/category/insert', body);
            return data.message;
        }
        return data();
    } catch (error) {
        return null;
    }
};
// 修改數據接口
const handleUpdateData = async (body) => {
    try {
        // 调用接口
        const data = async () => {
            let data = await $http('/managementController/category/update', body);
            return data.message;
        }
        return data();
    } catch (error) {
        return null;
    }
};
// 刪除數據接口
const handleDeleteData = async (body) => {
    try {
        // 调用接口
        const data = async () => {
            let data = await $http('/managementController/category/delete', body);
            return data.message;
        }
        return data();
    } catch (error) {
        return null;
    }
};
// 查詢报表接口
const handleRefreshData = async (body) => {
    try {
        // 调用接口
        const data = async () => {
            let data = await $http('/managementController/category/select-all', body);
            return data.data;
        }
        return data();
    } catch (error) {
        return null;
    }
};

function CategorySetup() {
    // 國際化
    const { t } = useTranslation();
    // 表格数据
    const [rows, setRows] = React.useState([]);
    // 表格数据總數
    const [total, setTotal] = React.useState(0);
    // 表格内容开关
    const [detailsOpen, setDetailsOpen] = React.useState(false);

    // 頁數
    const [page, setPage] = React.useState(1);
    // 每頁數量
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    // 表格每頁數量設置
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 25, 100]);

    // 搜索按钮loading
    const [openLoading, setOpenLoading] = React.useState(false);
    // table loading
    const [tableLoading, setTableLoading] = React.useState(false);
    // 搜索框开关
    const [searchOpen, setSearchOpen] = React.useState(true);
    // 搜索表单
    const [searchForm, setSearchForm] = React.useState({
        categoryName: '',
        enable: '',
        pageSize: rowsPerPage,
        pageNumber: page,
        orderBy: '',
        ascOrder: '',
    });
    // 行数据详情
    const [tableRowDetail, setTableRowDetail] = React.useState({
        categoryId: '',
        categoryName: '',
        parentCategoryId: null,
        categoryLevel: null,
        seqNum: '',
        enable: '',
    });

    // 彈出框
    const [dialog, setDialog] = useState({
        content: "",
        action: (
            <Button
                variant="contained"
                onClick={() => {
                    setDialog({ ...dialog, open: false });
                }}
            >
                {t('Confirm')}
            </Button>
        ),
        open: false,
    });

    // 是否为添加表单
    const [isAddFlag, setIsAddFlag] = React.useState(false);
    // 表單开关
    const [formOpen, setFormOpen] = React.useState(false);
    // 弹出框标题
    const [dialogTitle, setDialogTitle] = React.useState('');

    // 頁面初始化
    useEffect(() => {
        handleRefreshData(searchForm).then((value) => {
            // 设置报表数据
            setRows(value ? value.records : []);
            setTotal(value ? value.total : 0);
        })
    }, [page, rowsPerPage])

    // 表單值改變
    const handleFormChange = (event) => {
        setSearchForm({
            ...searchForm,
            [event.target.name]: event.target.value,
        });
    };
    const handleTableRowDetailChange = (event) => {
        setTableRowDetail({
            ...tableRowDetail,
            [event.target.name]: event.target.value,
        });
    };

    // 刷新列表
    const refreshRowsPage = async (body) => {
        // table loading open
        setTableLoading(true);
        handleRefreshData(body).then((value) => {
            // 报表内容显示
            setDetailsOpen(true);
            // 搜索内容关闭
            setSearchOpen(false);
            // 设置报表数据
            setRows(value ? value.records : []);
            setTotal(value ? value.total : 0);
            // 設置分頁數據
            // setPage(value ? value.current - 1 : 0);
            // setRowsPerPage(value ? value.size : 1);
            // 判斷每頁數量是否存在,不存在就加入進去
            setRowsPerPageOptions(pushKey(rowsPerPageOptions, value ? value.size : 5));
            // table loading close
            setTableLoading(false);
        });
    }
    // 刷新列表
    const refreshRows = async () => {
        // table loading open
        setTableLoading(true);
        handleRefreshData(searchForm).then((value) => {
            // 报表内容显示
            setDetailsOpen(true);
            // 搜索内容关闭
            setSearchOpen(false);
            // 设置报表数据
            setRows(value ? value.records : []);
            setTotal(value ? value.total : 0);
            // 設置分頁數據
            // setPage(value ? value.current - 1 : 0);
            // setRowsPerPage(value ? value.size : 1);
            // 判斷每頁數量是否存在,不存在就加入進去
            setRowsPerPageOptions(pushKey(rowsPerPageOptions, value ? value.size : 5));
            // table loading close
            setTableLoading(false);
        });
    }
    // 表单提交
    const handleSearchClick = async () => {
        // 打开按钮loading, 防止重复点击
        setOpenLoading(true);
        setSearchForm({
            ...searchForm,
            pageNumber: page,
            pageSize: rowsPerPage,
        })
        handleRefreshData(searchForm).then((value) => {
            // 关闭按钮loading
            setOpenLoading(false);
            // 改变key值, 使列表刷新
            // setRefreshRows((oldRefreshRows) => oldRefreshRows + 1);
            // 设置报表数据
            setRows(value ? value.records : []);
            setTotal(value ? value.total : 0);
        });
    };
    // 判斷數組是否含有某個元素, 沒有就加進去
    const pushKey = (arr, key) => {
        // arr是否為數組
        if (!Array.isArray(arr)) return;
        // 是否含有該key
        const _key = arr.some((item) => item === key);
        if (_key) {
            return arr;
        } else {
            return [...arr, key];
        }
    }

    // 重置查询表单
    const handleResetClick = () => {
        setSearchForm({
            articleDateFrom: '',
            articleDateTo: '',
        });
    };

    // 重置添加表单
    const handleResetAddClick = () => {
        setTableRowDetail({
            categoryId: '',
            categoryName: '',
            parentCategoryId: null,
            categoryLevel: null,
            seqNum: '',
            enable: 1,
        });
    };
    // 重置修改表单
    const handleResetEditClick = () => {
        setTableRowDetail({
            ...tableRowDetail,
            categoryId: '',
            categoryName: '',
            parentCategoryId: null,
            categoryLevel: null,
            seqNum: '',
            enable: '',
        });
    };

    // 禁用开关组件
    const radioRef = React.useRef(null);

    // 添加一行新數據
    const handleAddClick = () => {
        if (CommonUtils.isEmptyOrBlank(tableRowDetail.categoryName) ||
            CommonUtils.isEmptyOrBlank(tableRowDetail.parentCategoryId) ||
            CommonUtils.isEmptyOrBlank(tableRowDetail.enable)
        ) {
            return;
        }
        // 打开按钮loading, 防止重复点击
        setOpenLoading(true);

        // 设置body
        const body = {
            "categoryName": tableRowDetail.categoryName,
            "parentCategoryId": tableRowDetail.parentCategoryId.id,
            "enable": tableRowDetail.enable,
        }
        handleAddData(body).then((value) => {
            // 关闭按钮loading
            setOpenLoading(false);
            // 提示是否成功
            setDialog({
                ...dialog,
                // content: value ? t('AddSuccess') : t('AddFail'),
                content: value,
                action: (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setDialog({ ...dialog, open: false });
                            handleCloseFormClick();
                        }}
                    >
                        {t('Confirm')}
                    </Button>
                ),
                open: true,
            });
            // 更新table
            refreshRows()
        });

    }
    // 编辑一行新數據
    const handleEditClick = () => {
        // 獲取RadioGroup組件的DOM節點
        const radioNode = radioRef.current;
        // 選擇被選中的Radio元素
        const selected = radioNode.querySelector("input:checked");
        // 獲取它的value屬性
        const enable = selected.getAttribute("value");
        if (CommonUtils.isEmptyOrBlank(tableRowDetail.categoryName) ||
            CommonUtils.isEmptyOrBlank(tableRowDetail.categoryId) ||
            CommonUtils.isEmptyOrBlank(tableRowDetail.parentCategoryId) ||
            CommonUtils.isEmptyOrBlank(enable)
        ) {
            return;
        }
        // 打开按钮loading, 防止重复点击
        setOpenLoading(true);
        // 设置body
        const body = {
            "categoryId": tableRowDetail.categoryId,
            "categoryName": tableRowDetail.categoryName,
            "parentCategoryId": tableRowDetail.parentCategoryId.id,
            "enable": enable,
        }
        handleUpdateData(body).then((value) => {
            // 关闭按钮loading
            setOpenLoading(false);
            // 提示是否成功
            setDialog({
                ...dialog,
                content: value,
                action: (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setDialog({ ...dialog, open: false });
                            handleCloseFormClick();
                        }}
                    >
                        {t('Confirm')}
                    </Button>
                ),
                open: true,
            });
            // 更新table
            refreshRows()
        });

    }
    // 确定执行删除事件
    const handleDeleteClick = (row) => {
        if (CommonUtils.isEmptyOrBlank(row.categoryId)) {
            return;
        }
        // 打开按钮loading, 防止重复点击
        setOpenLoading(true);
        // 设置body
        const body = {
            categoryId: row.categoryId,
        }
        handleDeleteData(body).then((value) => {
            // 关闭按钮loading
            setOpenLoading(false);
            // 提示是否成功
            setDialog({
                ...dialog,
                content: value,
                action: (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setDialog({ ...dialog, open: false });
                        }}
                    >
                        {t('Confirm')}
                    </Button>
                ),
                open: true,
            });
            // 更新table
            refreshRows()
        });
    };

    // 刪除按鈕點擊事件
    const handleOpenDeleteClick = (row) => {
        // 删除时要打开提示框提示是否删除
        setDialog({
            ...dialog,
            content: t('sure_to_delete'),
            action: (
                <>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <FormControl>
                            <LoadingButton
                                loading={openLoading}
                                variant="contained"
                                sx={{
                                    backgroundColor: "#ff0000",
                                    color: "commom.white",
                                }}
                                onClick={() => handleDeleteClick(row)}
                            >
                                {t('Delete')}
                            </LoadingButton>
                        </FormControl>
                        <FormControl sx={{ marginLeft: "10px" }}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setDialog({ ...dialog, open: false });
                                }}
                                sx={{
                                    backgroundColor: "#9b9b9b",
                                    color: "commom.white",
                                    marginRight: "10px",
                                }}
                                label={t('Close')}
                            >
                                {t('Close')}
                            </Button>
                        </FormControl>
                    </Grid>
                </>
            ),
            open: true,
        });
    }
    // 关闭表单弹出框
    const handleCloseFormClick = () => {
        setFormOpen(false);
    }
    // 打開添加窗口
    const handleOpenAddClick = () => {
        // 添加时需要显示输入框
        setIsAddFlag(true)
        // 重置表单内容
        handleResetAddClick();
        // 设置弹出框标题为-添加
        setDialogTitle(t('Add'));
        // 打开添加表单
        setFormOpen(true);
    };

    const handleShowSizeChange = (page, rowsPerPage) => {
        setPage(page);
        setRowsPerPage(rowsPerPage > 0 ? rowsPerPage : undefined);
        setSearchForm({
            ...searchForm,
            pageNumber: page,
            pageSize: rowsPerPage,
        });
    }
    // 編輯按鈕點擊事件
    const handleOpenEditClick = (row) => {
        // setOpen(true)
        // 编辑时需要隐藏输入框
        setIsAddFlag(false)
        // 回显表单内容
        setTableRowDetail({
            ...tableRowDetail,
            categoryId: row.categoryId,
            categoryName: row.categoryName,
            parentCategoryId: { id: row.parentCategoryId, text: row.parentCategoryName },
            enable: row.enable,
        });
        // 设置弹出框标题为-编辑
        setDialogTitle(t('Edit'));
        // 打开修改表单
        setFormOpen(true);
    }
    // 表頭
    const columns = [
        { dataIndex: 'categoryName', label: t('Category'), align: 'left', width: '60%' },
        { dataIndex: 'enable', label: t('State'), align: 'center', width: '20%', render: (record) => record.enable === 1 ? t('Enabled') : t('Disabled') },
        {
            dataIndex: 'action', label: t('Action'), align: 'center', width: '20%', isOrder: false, render: (record) => {
                return (
                    <>
                        <Tooltip title={t('Edit')}>
                            <IconButton onClick={() => handleOpenEditClick(record)}>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('Delete')}>
                            <IconButton onClick={() => handleOpenDeleteClick(record)}>
                                <DeleteOutlineRoundedIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                )
            }
        },
    ];

    return (
        <>
            <Container maxWidth={false} sx={{ mt: 5, mb: 8 }}>
                <Typography variant="h4" sx={{ mb: 3 }}>
                    {t('icon3_category_setup')}
                </Typography>

                <Card variant="outlined" sx={{ border: '1px solid rgba(5, 5, 5, .06)', borderRadius: '8px' }}>
                    <Box sx={{ padding: '32px 24px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    name='categoryName' value={searchForm.categoryName || ""} fullWidth size="small" label={t("Category")} onChange={(event) => handleFormChange(event)} />
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                <FormControl>
                                    <LoadingButton
                                        loading={openLoading} variant="contained" onClick={handleSearchClick} sx={{ backgroundColor: "primaryBlue", color: "commom.white", }}
                                    >
                                        {t('Search')}
                                    </LoadingButton>
                                </FormControl>
                                <FormControl sx={{ marginLeft: "20px" }}>
                                    <Button
                                        variant="contained" onClick={handleResetClick} sx={{ backgroundColor: "primaryGreen", color: "commom.white", }} label={t('Reset')}
                                    >
                                        {t("Reset")}
                                    </Button>
                                </FormControl>
                                <FormControl sx={{ marginLeft: "20px" }}>
                                    <Button
                                        variant="contained" onClick={handleOpenAddClick} sx={{ backgroundColor: "#2d8d27", color: "commom.white", }} label={t('New')}
                                    >
                                        {t("New")}
                                    </Button>
                                </FormControl>
                            </Grid>

                        </Grid>
                    </Box>
                    <CTable disablePagination rowKey="categoryId" loading={tableLoading} columns={columns} data={rows} paperSx={{ border: 0 }} />
                    <CPagination total={total} rowsPerPage={rowsPerPage} onChange={handleShowSizeChange} />
                </Card>
            </Container>
            {/* 提示框 */}
            <CustomDialog
                sx={{ whiteSpace: "pre-line" }}
                open={dialog.open}
                title={dialog.title}
                content={dialog.content}
                action={dialog.action}
            />

            {/* <EditForm open={formOpen} /> */}

            <CDialog
                fullWidth
                disableBackdropClick
                maxWidth={'sm'}
                title={dialogTitle}
                open={formOpen}
                onClose={() => setFormOpen(false)}
            >
                <DialogContent dividers sx={{ pt: 3, pb: 3 }}>
                    {/* <Card variant="outlined" sx={{ mb: 3, borderRadius: '8px', border: '1px solid rgba(5, 5, 5, .06)' }}> */}
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item xs={12} />
                        <Grid item xs={12}>
                            <TextField
                                error={tableRowDetail.categoryName === ''}
                                required name='categoryName' value={tableRowDetail.categoryName || ""} fullWidth size="small" label={t("Title")} onChange={(event) => handleTableRowDetailChange(event)} />
                        </Grid>
                        <Grid item xs={12}>
                            <CAutocomplete
                                // error={tableRowDetail.categoryId === ''} 
                                required
                                url='/vps/common/getAllCategoryId'
                                name='categoryId'
                                label={t("Parent_Category")}
                                value={!!tableRowDetail.parentCategoryId ? { id: tableRowDetail.parentCategoryId.id, text: tableRowDetail.parentCategoryId.text } : null}
                                onChange={(event, newData) => {
                                    if (!!newData) {
                                        setTableRowDetail({
                                            ...tableRowDetail,
                                            parentCategoryId: { id: newData.id, text: newData.text },
                                        })
                                    } else {
                                        setTableRowDetail({
                                            ...tableRowDetail,
                                            parentCategoryId: null,
                                        })
                                    }
                                }}
                            />
                        </Grid>

                        {!isAddFlag && (
                            <>
                                <Grid item xs={12}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            name="enable"
                                            defaultValue="1"
                                            ref={radioRef}
                                            value={tableRowDetail.enable}
                                            onChange={(event) => handleTableRowDetailChange(event)}
                                        >
                                            <FormControlLabel
                                                value="1"
                                                control={<Radio />}
                                                label={t("Enabled")}
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="0"
                                                control={<Radio />}
                                                label={t("Disabled")}
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: "center", marginTop: 2 }}>
                        <FormControl>
                            <LoadingButton
                                loading={openLoading}
                                variant="contained"
                                sx={{
                                    backgroundColor: "#2d8d27",
                                    color: "commom.white",
                                }}
                                onClick={dialogTitle === t('Add') ? handleAddClick : handleEditClick}
                            >
                                {dialogTitle}
                            </LoadingButton>
                        </FormControl>
                        <FormControl sx={{ marginLeft: "10px" }}>
                            <Button
                                variant="contained"
                                onClick={dialogTitle === t('Add') ? handleResetAddClick : handleResetEditClick}
                                sx={{
                                    backgroundColor: "primaryGreen",
                                    color: "commom.white",
                                    marginRight: "10px",
                                }}
                                label={t('Reset')}
                            >
                                {t('Reset')}
                            </Button>
                        </FormControl>
                        <FormControl >
                            <Button
                                variant="contained"
                                onClick={handleCloseFormClick}
                                sx={{
                                    backgroundColor: "#9b9b9b",
                                    color: "commom.white",
                                    marginRight: "10px",
                                }}
                                label={t('Close')}
                            >
                                {t('Close')}
                            </Button>
                        </FormControl>
                    </Grid>
                    {/* </Card> */}
                </DialogContent>
            </CDialog>
        </>
    )
}

export default CategorySetup;