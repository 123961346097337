// @mui
import { styled } from '@mui/material/styles'
import { alpha, Link, Container, Typography, Divider, Stack, Button, Grid } from '@mui/material'
import cookie from 'react-cookies'
// components
import Attention from '../components/login/Attention'

// hooks
import useResponsive from '../hooks/useResponsive'
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  backgroundImage: "url('../assets/images/login-background.jpg')",
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  // [theme.breakpoints.up('md')]: {
  //   display: 'flex',
  // },
}))

const StyledSection = styled('div')(({ theme }) => ({
  margin: 'auto',
  textAlign: 'center',
  //  minHeight: '100%',
  boxShadow: theme.customShadows.dropdown,
  //  bgcolor: 'background.default',
  // borderLeftStyle: 'dashed',
  // justifyContent: 'center',
  // flexDirection: 'column',
  backgroundColor: alpha(theme.palette.grey[300], 0.5),
  padding: theme.spacing(36, 0),
}))

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

// ----------------------------------------------------------------------

// 删除所有cookie
const delAllCookie = () => {
  const domain = '.' + window.location.host
  const keys = document.cookie.match(/[^ =;]+(?=\=)/g)
  console.log('keys:', keys)
  if (keys) {
    keys.forEach((key) => {
      cookie.remove(key)
      // let date = new Date();
      // date.setTime(date.getTime() - 10000);
      // document.cookie = key + "=; expire=" + date.toUTCString() + "; Domain=" + domain + "; path=/";
    })
  }
}

export default function LoginPage() {
  localStorage.clear()
  const mdUp = useResponsive('up', 'md')
  delAllCookie()
  const handleClick = () => {
    // window.location.href = `${process.env.REACT_APP_OIDC_URL}/oidc/auth?response_type=code&client_id=sanfield-smps&scope=openid profile offline_access&redirect_uri=${process.env.REACT_APP_REDIRECTURL}/indexPage`
    window.location.href = `${process.env.REACT_APP_OIDC_URL}/oidc/auth?response_type=code&client_id=${process.env.REACT_APP_VPS_OAUTH_ID}&prompt=consent&scope=openid profile offline_access&redirect_uri=${process.env.REACT_APP_REDIRECTURL}/indexPage`
  }

  return (
    <>
      <StyledRoot>
        <Attention callback={handleClick} />
        <StyledSection sx={{ textAlign: 'center', margin: 'auto', minHeight: '100vh' }}>
          <Typography sx={{ px: 5, mb: 5, fontSize: '60px' }} gutterBottom>
            <b> Vendor Portal</b> <b style={{ color: '#7D7D7D' }}>System</b>
            {/* <br />
            <b>(Sanfield-Gammon Construction JV Company Limited)</b> */}
          </Typography>
          <Button sx={{ backgroundColor: '#007BFF', borderRadius: '8px', justifyContent: 'center', width: '400px' }} size="large" type="submit" variant="contained" onClick={handleClick}>
            Login
          </Button>
        </StyledSection>
      </StyledRoot>
    </>
  )
}
