import {
  Box,
  Button,
  Grid,
  Divider,
  MenuItem,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  Typography,
  TableContainer,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import * as api from "src/utils/api/workerAttendanceRecord/AutoPayApi";

export default function TableDetail(props) {
  const { handleBackDetail, getBack, data, peopleCount } = props;
  const { t } = useTranslation();

  useEffect(() => {}, []);

  return (
    <>
      <div
        style={{
          display: "block",
          textAlign: "left",
          marginTop: "0px",
        }}
      >
        <Button sx={{ float: "left", mr: "20px" }} onClick={handleBackDetail}>
          <ArrowBackIcon />
          {t("button_back")}
        </Button>
      </div>

      {/* <div style={{ display: "block", textAlign: "right" }}>
        <Button sx={{ float: "right", mr: "20px" }}>
          <DownloadIcon />
          {t("button_print")}
        </Button>
      </div> */}
      <Typography variant="h4" sx={{ display: "block", textAlign: "center" }}>
        <b>{t("Contractor_Autopay_Status")}</b>
      </Typography>
      <Typography variant="h6" sx={{ display: "block", mt: "10px" }}>
        <b>{t("icon1_autopay_button8_print_title")}</b>
      </Typography>
      <TableContainer>
        <Table sx={{ textAlign: "left" }} size="small">
          <TableBody>
            <TableRow>
              <TableCell sx={{ display: "block", borderBottom: "none" }}>
                {t("icon1_autopay_button8_print_label1")}
              </TableCell>
              <TableCell sx={{ textAlign: "left", borderBottom: "none" }}>
                {data.vendoridset?.vendorDesc}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: 300, borderBottom: "none" }}>
                {t("icon1_autopay_button8_print_label2")}:
              </TableCell>
              <TableCell sx={{ textAlign: "left", borderBottom: "none" }}>
                {data.periodset?.bankAccountName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: 200, borderBottom: "none" }}>
                {t("icon1_autopay_button8_print_label3")}:
              </TableCell>
              <TableCell sx={{ textAlign: "left", borderBottom: "none" }}>
                {data.periodset?.bankAccount}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ display: "block", borderBottom: "none" }}>
                {t("icon1_autopay_button8_print_label4")}:
              </TableCell>
              <TableCell sx={{ textAlign: "left", borderBottom: "none" }}>
                {data.periodset?.schemeNo}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ display: "block", borderBottom: "none" }}>
                {t("icon1_autopay_button8_print_label5")}:
              </TableCell>
              <TableCell sx={{ textAlign: "left", borderBottom: "none" }}>
                {data.periodset?.branchCode}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ display: "block", borderBottom: "none" }}>
                {t("icon1_autopay_button8_print_label6")}:
              </TableCell>
              <TableCell sx={{ textAlign: "left", borderBottom: "none" }}>
                {data.periodset?.contactPerson}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ display: "block", borderBottom: "none" }}>
                {t("icon1_autopay_button8_print_label7")}:
              </TableCell>
              <TableCell sx={{ textAlign: "left", borderBottom: "none" }}>
                {data.periodset?.phoneNumber}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ display: "block", borderBottom: "none" }}>
                {t("Payroll_and_Contribution_Period")}:
              </TableCell>
              <TableCell sx={{ textAlign: "left", borderBottom: "none" }}>
                {data.periodset?.dateFrom} - {data.periodset?.dateTo}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Divider sx={{ mt: "10px" }} />

      {/* table */}
      <Typography variant="h6" sx={{ display: "block", mt: "20px" }}>
        <b>{t("Workers_Income_And_Bank_Information")}</b>
      </Typography>
      <TableContainer>
        <Table
          sx={{
            pageBreakInside: "inherit",
            minWidth: 650,
            pr: "5px",
            "& .MuiTableCell-root": { paddingLeft: "7px", paddingRight: "7px" },
          }}
          size="small"
        >
          <TableHead sx={{ position: "sticky", top: "0" }}>
            <TableRow hover sx={{ border: "1px" }}>
              <TableCell sx={{ fontSize: "15px" }}>No.</TableCell>
              <TableCell sx={{ fontSize: "15px", wordBreak: "keep-all" }}>
                {t("EnglishName")}
              </TableCell>
              <TableCell sx={{ fontSize: "15px", wordBreak: "keep-all" }}>
                {t("ChineseName")}
              </TableCell>
              <TableCell sx={{ fontSize: "15px", wordBreak: "keep-all" }}>
                {t("ID_number")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  textAlign: "right",
                  wordBreak: "keep-all",
                }}
              >
                {t("Number_Of_Workers_Working_Days")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  textAlign: "right",
                  wordBreak: "keep-all",
                }}
              >
                {t("Actual_number_of_working_days")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  textAlign: "right",
                  wordBreak: "keep-all",
                }}
              >
                {t("Workers_income_in_this_period")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  textAlign: "right",
                  wordBreak: "keep-all",
                }}
              >
                {t("Actual_payroll_for_the_period")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  textAlign: "right",
                  wordBreak: "keep-all",
                }}
              >
                {t("Employer_MPF_contributions")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  textAlign: "right",
                  wordBreak: "keep-all",
                }}
              >
                {t("Employee_MPF_contributions")}
              </TableCell>
              <TableCell sx={{ fontSize: "15px", wordBreak: "keep-all" }}>
                {t("Employee_Account_Number")}
              </TableCell>
              <TableCell sx={{ fontSize: "15px", wordBreak: "keep-all" }}>
                {t("plan_Member_Number")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  textAlign: "right",
                  wordBreak: "keep-all",
                }}
              >
                {t("Paid_Salary")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  textAlign: "right",
                  wordBreak: "keep-all",
                }}
              >
                {t("Prepaid_MPF")}
              </TableCell>
              <TableCell sx={{ fontSize: "15px", wordBreak: "keep-all" }}>
                {t("Remark")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.WorkerDetailSet?.length > 0 ? (
              <>
                {data.WorkerDetailSet?.map((row, index) => (
                  <TableRow hover key={index}>
                    <TableCell
                      sx={{ fontSize: "15px" }}
                      component="th"
                      scope="row"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell sx={{ fontSize: "15px", whiteSpace: "nowrap" }}>
                      {row.englishName}{" "}
                    </TableCell>
                    <TableCell sx={{ fontSize: "15px", whiteSpace: "nowrap" }}>
                      {row.chineseName}{" "}
                      {row.age < 18 || row.ageMon >= 65 ? (
                        <b style={{ color: "red" }}>*</b>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell sx={{ fontSize: "15px" }}>{row.hkid}</TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontSize: "15px", textAlign: "right" }}
                    >
                      {row.workDay}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontSize: "15px", textAlign: "right" }}
                    >
                      {row.workDayAct}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontSize: "15px", textAlign: "right" }}
                    >
                      {row.relevantIncomeForMat}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontSize: "15px", textAlign: "right" }}
                    >
                      {row.netIncomeForMat || "0.00"}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontSize: "15px", textAlign: "right" }}
                    >
                      {row.employerMpfForMat || "0.00"}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontSize: "15px", textAlign: "right" }}
                    >
                      {row.employeeMpfForMat || "0.00"}
                    </TableCell>
                    <TableCell sx={{ fontSize: "15px" }}>
                      {row.bankAccount}
                    </TableCell>
                    <TableCell sx={{ fontSize: "15px" }}>
                      {row.mpfScheme}
                    </TableCell>
                    <TableCell sx={{ fontSize: "15px", textAlign: "right" }}>
                      {row.previousNetIncomeForMat}
                    </TableCell>
                    <TableCell sx={{ fontSize: "15px", textAlign: "right" }}>
                      {row.preEmployeeMPFForMat}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontSize: "15px", wordBreak: "keep-all" }}
                    >
                      {row.payrollRemark}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan="13" sx={{ textAlign: "center" }}>
                  No Records Found!
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ marginTop: "50px" }}>
        <TableContainer>
          <Table
            sx={{
              pageBreakInside: "inherit",
              minWidth: 650,
              wordBreak: "keep-all",
            }}
            size="small"
          >
            <TableHead>
              <TableRow hover sx={{ border: "1px" }}>
                <TableCell sx={{ fontSize: "15px", textAlign: "right" }}>
                  {t("icon1_autopay_button8_print_PeopleCount")}
                </TableCell>
                <TableCell sx={{ fontSize: "15px", textAlign: "right" }}>
                  {t("Workers_income_in_this_period")}
                </TableCell>
                <TableCell sx={{ fontSize: "15px", textAlign: "right" }}>
                  {t("Actual_payroll_for_the_period")}
                </TableCell>
                <TableCell sx={{ fontSize: "15px", textAlign: "right" }}>
                  {t("Employer_MPF_contributions")}
                </TableCell>
                <TableCell sx={{ fontSize: "15px", textAlign: "right" }}>
                  {t("Employee_MPF_contributions")}
                </TableCell>
                <TableCell sx={{ fontSize: "15px", textAlign: "right" }}>
                  {t("Total_contributions_from_both_parties")}
                </TableCell>
                <TableCell sx={{ fontSize: "15px", textAlign: "right" }}>
                  {t("Deductions_from_Contractor_Account")}
                </TableCell>
                <TableCell sx={{ fontSize: "15px", textAlign: "right" }}>
                  {t("Paid_Salary")}
                </TableCell>
                <TableCell sx={{ fontSize: "15px", textAlign: "right" }}>
                  {t("Prepaid_Employees_MPF")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover>
                <TableCell
                  sx={{ fontSize: "15px", textAlign: "right" }}
                  component="th"
                  scope="row"
                >
                  {data.WorkerDetailSet?.length}
                </TableCell>
                <TableCell sx={{ fontSize: "15px", textAlign: "right" }}>
                  {data.final_tot_rel_format || "0.00"}{" "}
                </TableCell>

                <TableCell sx={{ fontSize: "15px", textAlign: "right" }}>
                  {data.final_tot_net_format || "0.00"}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontSize: "15px", textAlign: "right" }}
                >
                  {data.final_tot_er_mpf_format || "0.00"}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontSize: "15px", textAlign: "right" }}
                >
                  {data.final_tot_ee_mpf_format || "0.00"}
                </TableCell>
                <TableCell sx={{ fontSize: "15px", textAlign: "right" }}>
                  {(data.final_tot_ee_mpf + data.final_tot_er_mpf)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") || "0.00"}
                </TableCell>
                <TableCell sx={{ fontSize: "15px", textAlign: "right" }}>
                  {(
                    data.final_tot_ee_mpf +
                    data.final_tot_er_mpf +
                    data.final_tot_net
                  )
                    ?.toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") || "0.00"}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontSize: "15px", textAlign: "right" }}
                >
                  {data.tot_PreviousNetIncome
                    ?.toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") || "0.00"}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontSize: "15px", textAlign: "right" }}
                >
                  {data.PrePay_EmployeeMPF_format || "0.00"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
