import { Button, DialogContent, Typography } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { CustomDialog } from 'src/components/common'

function MessageInformationForm(prop) {
  // 彈出框
  const [dialog, setDialog] = useState({
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        {t('Confirm')}
      </Button>
    ),
    open: false,
  })
  useEffect(() => {}, [])

  const [dump, setDump] = React.useState('')

  const handleDump = (event) => {
    setDump(event.target.value)
    // console.log('dump', event.target.value)
    // console.log('herf2', '/newsmps/otherDataSetting/' + dump)
  }

  const handleMessageDump = () => {
    if (dump) {
      console.log('herf2', '/newsmps/otherDataSetting')
      // window.location.href = '/newsmps/otherDataSetting' + dump
      window.location.href = '/newsmps/otherDataSetting/otherDataSetting' + dump
    } else {
      window.location.href = '/newsmps/otherDataSetting/'
    }
  }

  return (
    <>
      {/* 提示框 */}
      <CustomDialog sx={{ whiteSpace: 'pre-line' }} open={dialog.open} title={dialog.title} content={dialog.content} action={dialog.action} />
      <DialogContent dividers sx={{ pt: 3, pb: 3 }}>
        <Typography sx={{ whiteSpace: 'pre-wrap' }}>
          <div dangerouslySetInnerHTML={{ __html: prop.content }} />

          {/* {prop.content} */}
        </Typography>
        {prop.data.path !== null ? (
          <Button
            id="tiaohzuan"
            variant="contained"
            sx={{ mt: 3 }}
            onClick={() => {
              window.location.href = '/newsmps' + prop.data.path
            }}
          >
            {t('jump')}
          </Button>
        ) : null}
      </DialogContent>
    </>
  )
}
export default MessageInformationForm
