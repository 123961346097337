import { instance } from "../../instance";
// 承判商名称
export const getSubContractorList = async (site) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/vps/common/getvendorId`,
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

// 地盤名称
export const getSiteAndTeam = async (site) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/vps/common/getSiteAndTeam`,
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

// 工種名称
export const getfilljobNatureId = async (site) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/vps/common/getfilljobNatureId`,
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};
// 日期下拉框
export const getDate = async (site) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/vps/common/getfillDate`,
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

// 表格數據
export const getData = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/VendorAutoPayStatuslist `,
        data: data
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

// 表格地盤下拉框數據
export const getTableSiteData = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/selectOptionSite`,
        data: data
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

// 完成的短糧期
export const getTableReport = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/autoPayController/reportVendorAutoPayStatus`,
        data: data
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};
