import React, { useRef, useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer, Link,
  TableRow, Backdrop, CircularProgress,

} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import { useSearchParams, useNavigate, useParams, createSearchParams } from "react-router-dom";
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getComparator, stableSort } from '../TableSort'; 

export default function OtherDataSetting() {

  const componentRef = useRef()
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isArticelsBool, setIsArticelsBool] = React.useState(false);
  const params = useParams()
  const [loading, setLoading] = React.useState(true);
  const [categorys, setCategorys] = React.useState([]);
  const [articles, setArticles] = React.useState([]);
  const [dataSource, setDataSource] = React.useState({});
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('enterDatetime');

  const handleBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    console.log('params.menuId==', params.menuId)
    searchCategoryArticle()
  }, [params.menuId])


  const searchCategoryArticle = async () => {

    let url = '/othermodules/' + params.menuId + '/search-category-article'

    let list = await apiAtt.searchCategoryArticle(url)

    if (list !== null) {
      setIsArticelsBool(list.data.isArticles)

      if (list.data.isArticles) {
        setArticles(list.data.articles)
      } else {
        setCategorys(list.data.categorys)
      }

      setDataSource(list.data.categoryTable)

    }

    setLoading(false)
  }

  function clickCell(mainDataRow) {
    console.log(22222)
    navigate({
      pathname: '/otherDataSetting/otherDataSetting/' + mainDataRow.categoryId,
      search: `?${createSearchParams({

      }, { state: { fromSpecificPage: true } })}`
    })
  }

  const clickViewPdf = (mainDataRow) => {
    // window.location.href = mainDataRow.articleFilePath;
    const w = window.open('_black')  
    let url = mainDataRow.articleFilePath
    w.location.href = url
  };

  function clickHeadCell(index) {
    switch (index) {
      case 1: setOrderBy('enterDatetime'); break;
      case 2: setOrderBy('articleAbstract'); break;
      case 3: setOrderBy('articleOwnerName'); break;
      default: setOrderBy('enterDatetime'); break;
    }

    if (order === 'asc') {
      setOrder('desc')
    } else {
      setOrder('asc')
    }
  }

  // 解析后台返回的样式css
  const getCategoryName = (str) => {
    if (str == null) { return '' }
    console.log('str=', str)
    let pattern = /<[^>]+>/g;
    let str2 = str.match(pattern) && str.match(pattern).length > 0 ? str.replace(/<[^>]+>/g, "") : str;
    return str2
  }

  return (
    <Container>
      <div style={{ display: 'block', textAlign: 'left' }}>
        <Button onClick={handleBack}><ArrowBackIcon />{t('button_back')}</Button>
      </div>

      <div id='div' style={{}}   >
        <Container sx={{ textAlign: 'center' }}>
          <div  >
            <div style={{ display: 'block', marginBottom: '60px' }}>
              <Typography variant='h4' sx={{ textAlign: 'center' }}>
                <b>{getCategoryName(dataSource.categoryName)}</b></Typography>
            </div>

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>

          {isArticelsBool && (
            <Table>
              <TableHead>
                <TableRow hover  >
                  {/* (siteName, carid, engName, chName, joy, approveDate, inDate, inVateDate, applyDate */}
                  <TableCell sx={{ textAlign: 'left' }} >
                    <Link underline="none" sx={{}}
                      onClick={() => clickHeadCell(1)}>
                      {'最新修正日期'}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'left' }}>
                    <Link underline="none" sx={{}}
                      onClick={() => clickHeadCell(2)}>
                      {'標題'}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'left' }}>
                    <Link underline="none" sx={{}}
                      onClick={() => clickHeadCell(3)}>
                      {'撰寫人'}
                    </Link>
                  </TableCell>

                </TableRow>
              </TableHead>

              {stableSort(articles, getComparator(order, orderBy)).map((mainDataRow, index) => (

                <TableRow hover key={index + Math.random + 100} >
                  <TableCell sx={{ whiteSpace: "nowrap", fontSize: '15px', textAlign: 'left', }}>
                    {mainDataRow.articleDate}
                  </TableCell>

                  <TableCell sx={{ fontSize: '15px', textAlign: 'left', }}>
                    <Link underline="none" sx={{}}
                      onClick={() => clickViewPdf(mainDataRow)}>
                      {getCategoryName(mainDataRow.articleAbstract)}
                    </Link>
                  </TableCell>

                  <TableCell sx={{ whiteSpace: "nowrap", fontSize: '15px', textAlign: 'left' }}>
                    {mainDataRow.articleOwnerName}
                  </TableCell>
                </TableRow>

              ))}
            </Table>
          )}

          <Table>
            <TableBody>
              {!isArticelsBool && (categorys.map((mainDataRow, index) => (
                <>
                  <TableRow hover key={index + Math.random} >
                    <TableCell sx={{ fontSize: '15px', textAlign: 'center' }}>

                      <Link underline="none" sx={{ whiteSpace: "nowrap", }}
                        onClick={() => clickCell(mainDataRow)}>

                        {getCategoryName(mainDataRow.categoryName)}
                      </Link>

                    </TableCell>


                  </TableRow>
                </>
              )))}
            </TableBody>
          </Table>
        </Container> 
      </div >
    </Container >
  )
}

