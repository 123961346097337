import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {
    Button,
    Box,
    Card,
    Checkbox,
    Container,
    DialogContent,
    FormLabel,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    TableCell,
    TextField,
    Tooltip,
    Typography,
    RadioGroup,
    Radio,
    InputLabel,
    InputAdornment,
    OutlinedInput,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import { CustomDialog, CDatePicker, CAutocomplete, CTable, CPagination, CDialog, CTransferList, CAutocompleteVirtualization } from "src/components/common"

import CommonUtils from "src/utils/CommonUtils"

import $http from 'src/utils/api/services'
import { t } from 'i18next';



// 添加數據接口
const handleAddOrUpdateData = async (body) => {
    try {
        // 调用接口
        const data = async () => {
            let data = await $http('/autopay/function/vendor-auth-info/insertOrUpdate', body);
            return data.message;
        }
        return data();
    } catch (error) {
        return null;
    }
};
// 查詢詳情接口
const handleDetailData = async (body) => {
    try {
        // 调用接口
        const data = async () => {
            let data = await $http('/autopay/function/vendor-auth-info/select', body);
            return data.data;
        }
        return data();
    } catch (error) {
        return null;
    }
};

function TestPage(prop) {
    const { onClose, username, dialogTitle } = prop;
    // 搜索按钮loading
    const [openLoading, setOpenLoading] = React.useState(false);
    // 搜索按钮loading
    const [vendorIdDisable, setVendorIdDisable] = React.useState(true);

    // 表单内容
    const [formData, setFormData] = React.useState({
        userId: '',
        loginId: '',
        vendorId: '',
        vendorDesc: '',
        vendorDescC: '',
        vendorAuthors: [],
    });

    // 彈出框
    const [dialog, setDialog] = useState({
        content: "",
        action: (
            <Button
                variant="contained"
                onClick={() => {
                    setDialog({ ...dialog, open: false });
                }}
            >
                {t('Confirm')}
            </Button>
        ),
        open: false,
    });
    useEffect(() => {
        handleDetailData({ username }).then((value) => {
            let authors = [...value.vendorAuthors]
            if (authors.length < 2) {
                const emptyAuthor = {
                    authorizerName: "",
                    authorizerPhone: "",
                }
                for (let index = 0; index < 3 - authors.length; index++) {
                    authors = [...authors, emptyAuthor]
                }
            }
            setFormData({
                userId: value.userId,
                vendorId: value.vendorId,
                vendorDesc: value.vendorDesc,
                vendorDescC: value.vendorDescC,
                vendorAuthors: authors,
            });
        })
    }, [username]);

    // 打開新建授權人文本框
    const handleAddAuthorClick = () => {
        const newVendorAuthor = {
            authorizerName: "",
            authorizerPhone: "",
        }
        if (formData.vendorAuthors) {
            setFormData({
                ...formData,
                vendorAuthors: [...formData.vendorAuthors, newVendorAuthor]
            })
        } else {
            setFormData({
                ...formData,
                vendorAuthors: [newVendorAuthor]
            })
        }
    };
    return (
        <>
            {/* 提示框 */}
            <CustomDialog
                sx={{ whiteSpace: "pre-line" }}
                open={dialog.open}
                title={dialog.title}
                content={dialog.content}
                action={dialog.action}
            />
            <DialogContent dividers sx={{ pt: 3, pb: 3 }}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs={12}>
                        <CAutocompleteVirtualization
                            required
                            url='/vps/common/getVendorForAuthor'
                            name='vendorId'
                            label={t("company_name")}
                            value={!!formData.vendorId ? { id: formData.vendorId, text: formData.vendorDesc } : null}
                            onChange={(event, newData) => {
                                if (!!newData) {
                                    setFormData({
                                        ...formData,
                                        vendorId: !!newData ? newData.id : null,
                                        vendorDesc: !!newData ? newData.text : null,
                                    })
                                } else {
                                    setFormData({
                                        ...formData,
                                        vendorId: null,
                                        vendorDesc: null,
                                    })
                                }
                            }}
                        />
                        <Tooltip title="New Vendor Authorizer">
                            <IconButton onClick={(event) => handleAddAuthorClick(event)}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogContent>

        </>
    )
}
export default TestPage;