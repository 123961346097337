import { instance } from "../../instance";

// 文章 - 顯示
export const selectLists = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/managementController/article/select-all`,
        data: data,
    }).then(
        (result) => result.data,
        (error) => error
    );
};
// 文章 - 詳情
export const selectDetail = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/managementController/article/select`,
        data: data,
    }).then(
        (result) => result.data,
        (error) => error
    );
};
// 文章 - 新建
export const insertData = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/managementController/article/insert`,
        data: data,
    }).then(
        (result) => result.data,
        (error) => error
    );
};
// 文章 - 修改
export const updateData = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/managementController/article/update`,
        data: data,
    }).then(
        (result) => result.data,
        (error) => error
    );
};
// 文章 - 刪除
export const deleteData = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/managementController/article/delete`,
        data: data,
    }).then(
        (result) => result.data,
        (error) => error
    );
};