import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {
    Button,
    Box,
    Card,
    Checkbox,
    Container,
    DialogContent,
    FormLabel,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    TableCell,
    TextField,
    Tooltip,
    Typography,
    RadioGroup,
    Radio,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles'
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import SettingsIcon from '@mui/icons-material/Settings'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import moment from 'moment';
import { CustomDialog, CDatePicker, CAutocomplete, CTable, CPagination, CDialog, CTransferList, CTableExpandable } from "src/components/common"

import CommonUtils from "src/utils/CommonUtils"

import $http, { $download } from 'src/utils/api/services'
import { t } from 'i18next';
import VendorAuthorForm from "./VendorAuthorForm"


// 刪除數據接口
const handleDeleteData = async (body) => {
    try {
        // 调用接口
        const data = async () => {
            let data = await $http('/autopay/function/vendor-auth-info/delete', body);
            console.log(data.message);
            return data.message;
        }
        return data();
    } catch (error) {
        return null;
    }
};
// 查詢报表接口
const handleRefreshData = async (body) => {
    try {
        // 调用接口
        const data = async () => {
            let data = await $http('/autopay/function/vendor-auth-info/select-current', body);
            return data.data;
        }
        return data();
    } catch (error) {
        return null;
    }
};
// 查詢詳情接口
const handleDetailData = async (body) => {
    try {
        // 调用接口
        const data = async () => {
            let data = await $http('/autopay/function/vendor-auth-info/select', body);
            return data;
        }
        return data();
    } catch (error) {
        return null;
    }
};

// 查詢詳情接口
const handleUserData = async (body) => {
    try {
        // 调用接口
        const data = async () => {
            let data = await $http('/autopay/function/vendor-auth-info/select-vendor', body);
            return data.data;
        }
        return data();
    } catch (error) {
        return null;
    }
};

function VendorAuthorSetup() {
    // 國際化
    const { t } = useTranslation();
    // 表格数据
    const [rows, setRows] = React.useState([]);
    // 表格数据總數
    const [total, setTotal] = React.useState(0);

    // 頁數
    const [page, setPage] = React.useState(1);
    // 每頁數量
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    // 表格每頁數量設置
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 25, 100]);

    // 搜索按钮loading
    const [openLoading, setOpenLoading] = React.useState(false);
    // table loading
    const [tableLoading, setTableLoading] = React.useState(false);
    // 搜索表单
    const [searchForm, setSearchForm] = React.useState({
        vendorDesc: '',
        pageSize: rowsPerPage,
        pageNumber: page,
        orderBy: '',
        ascOrder: '',
    });
    // 行数据详情
    const [tableRowDetail, setTableRowDetail] = React.useState({
        userId: '',
        loginId: '',
        vendorId: '',
        vendorDesc: '',
        vendorDescC: '',
        vendorAuthors: [],
    });

    // 彈出框
    const [dialog, setDialog] = useState({
        content: "",
        action: (
            <Button
                variant="contained"
                onClick={() => {
                    setDialog({ ...dialog, open: false });
                }}
            >
                {t('Confirm')}
            </Button>
        ),
        open: false,
    });

    // 是否为添加表单
    const [isAddFlag, setIsAddFlag] = React.useState(false);
    // 表單开关
    const [formOpen, setFormOpen] = React.useState(false);
    // 弹出框标题
    const [dialogTitle, setDialogTitle] = React.useState('');

    // 頁面初始化
    useEffect(() => {
        handleRefreshData(searchForm).then((value) => {
            // 设置报表数据
            setRows(value?.records);
            setTotal(value?.total);
        })
    }, [page, rowsPerPage]);

    // 表單值改變
    const handleFormChange = (event) => {
        setSearchForm({
            ...searchForm,
            [event.target.name]: event.target.value,
        });
    };

    const handleTableRowDetailChange = (event) => {
        setTableRowDetail({
            ...tableRowDetail,
            [event.target.name]: event.target.value,
        });
    };

    // 刷新列表
    const refreshRows = async () => {
        // table loading open
        setTableLoading(true);
        handleRefreshData(searchForm).then((value) => {
            // 设置报表数据
            setRows(value?.records);
            setTotal(value?.total);
            // 判斷每頁數量是否存在,不存在就加入進去
            setRowsPerPageOptions(pushKey(rowsPerPageOptions, value ? value.size : 5));
            // table loading close
            setTableLoading(false);
        });
    }
    // 表单提交
    const handleSearchClick = async () => {
        // 打开按钮loading, 防止重复点击
        setOpenLoading(true);
        setSearchForm({
            ...searchForm,
            pageNumber: page,
            pageSize: rowsPerPage,
        })
        handleRefreshData(searchForm).then((value) => {
            // 关闭按钮loading
            setOpenLoading(false);
            // 设置报表数据
            setRows(value?.records);
            setTotal(value?.total);
        });
    };
    // 判斷數組是否含有某個元素, 沒有就加進去
    const pushKey = (arr, key) => {
        // arr是否為數組
        if (!Array.isArray(arr)) return;
        // 是否含有該key
        const _key = arr.some((item) => item === key);
        if (_key) {
            return arr;
        } else {
            return [...arr, key];
        }
    }

    // 重置查询表单
    const handleResetClick = () => {
        setSearchForm({
            vendorDesc: '',
        });
    };

    // 确定执行删除事件
    const handleDeleteClick = (row) => {
        if (CommonUtils.isEmptyOrBlank(row.userId)) {
            return;
        }
        // 打开按钮loading, 防止重复点击
        setOpenLoading(true);
        // 设置body
        const body = {
            userId: row.userId,
        }
        handleDeleteData(body).then((value) => {
            // 关闭按钮loading
            setOpenLoading(false);
            // 提示是否成功
            setDialog({
                ...dialog,
                content: value,
                action: (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setDialog({ ...dialog, open: false });
                        }}
                    >
                        {t('Confirm')}
                    </Button>
                ),
                open: true,
            });
            // 更新table
            refreshRows()
        });
    };

    // 刪除按鈕點擊事件
    const handleOpenDeleteClick = (row) => {
        // 删除时要打开提示框提示是否删除
        setDialog({
            ...dialog,
            content: t('sure_to_delete'),
            action: (
                <>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <FormControl>
                            <LoadingButton
                                loading={openLoading}
                                variant="contained"
                                sx={{
                                    backgroundColor: "#ff0000",
                                    color: "commom.white",
                                }}
                                onClick={() => handleDeleteClick(row)}
                            >
                                {t('Delete')}
                            </LoadingButton>
                        </FormControl>
                        <FormControl sx={{ marginLeft: "10px" }}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setDialog({ ...dialog, open: false });
                                }}
                                sx={{
                                    backgroundColor: "#9b9b9b",
                                    color: "commom.white",
                                    marginRight: "10px",
                                }}
                                label={t('Close')}
                            >
                                {t('Close')}
                            </Button>
                        </FormControl>
                    </Grid>
                </>
            ),
            open: true,
        });
    }
    // 关闭表单弹出框
    const handleCloseFormClick = () => {
        setFormOpen(false);
    }
    // 打開添加窗口
    const handleOpenAddClick = () => {
        // 添加时需要显示输入框
        setIsAddFlag(true)
        // 设置弹出框标题为-添加
        setDialogTitle(t('Add'));
        // 设置表单用户
        setTableRowDetail({
            ...tableRowDetail,
            loginId: null
        })
        // 打开添加表单
        setFormOpen(true);
    };

    const handleShowSizeChange = (page, rowsPerPage) => {
        setPage(page);
        setRowsPerPage(rowsPerPage > 0 ? rowsPerPage : undefined);
        setSearchForm({
            ...searchForm,
            pageNumber: page,
            pageSize: rowsPerPage,
        });
    }
    // 編輯按鈕點擊事件
    const handleOpenEditClick = (row) => {
        // setOpen(true)
        // 编辑时需要隐藏输入框
        setIsAddFlag(false)
        // 设置弹出框标题为-编辑
        setDialogTitle(t('Edit'));
        setTableRowDetail({
            ...tableRowDetail,
            loginId: row.loginId
        })
        // 打开修改表单
        setFormOpen(true);

        // // 設置查詢參數
        // const body = {
        //     userId: row.userId
        // }
        // handleDetailData(body).then((result) => {
        //     if (result.code === 200) {
        //         const value = result.data;
        //         let newValue = {
        //             ...tableRowDetail,
        //             userId: value.userId,
        //             vendorDesc: row?.vendorDesc,
        //         }
        //         // 回显表单内容
        //         setTableRowDetail(newValue);
        //         // 打开修改表单
        //         setFormOpen(true);
        //     } else {
        //         // 提示是否成功
        //         setDialog({
        //             ...dialog,
        //             content: result.message,
        //             action: (
        //                 <Button
        //                     variant="contained"
        //                     onClick={() => {
        //                         setDialog({ ...dialog, open: false });
        //                     }}
        //                 >
        //                     {t('Confirm')}
        //                 </Button>
        //             ),
        //             open: true,
        //         });
        //     }
        // });
    }

    function ChangeSize() {
        const [size, setSize] = useState({
            width: document.documentElement.clientWidth,
            hieght: document.documentElement.clientHeight
        })

        const onResize = useCallback(() => {
            setSize({
                width: document.documentElement.clientWidth,
                height: document.documentElement.clientHeight,
            })
        }, [])

        useEffect(() => {
            window.addEventListener('resize', onResize);
            return (() => {
                window.removeEventListener('resize', onResize)
            })
        }, [])

        return size;
    }

    // 获取浏览器宽高
    let windowsSize = ChangeSize();

    // 表頭
    const columns = [
        { dataIndex: 'loginId', label: t('login_id'), align: 'center', width: '20%', isOrder: false },
        { dataIndex: 'vendorDesc', label: t('company_name'), align: 'center', width: '30%', isOrder: false },
        { dataIndex: 'vendorDescC', label: t('company_name'), align: 'center', width: '30%', isOrder: false },
        {
            dataIndex: 'action', label: t('Action'), align: 'center', width: '20%', isOrder: false, render: (record) => {
                return (
                    <>
                        <Tooltip title={t('Edit')}>
                            <IconButton onClick={() => handleOpenEditClick(record)}>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('Delete')}>
                            <IconButton onClick={() => handleOpenDeleteClick(record)}>
                                <DeleteOutlineRoundedIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                )
            }
        },
    ];
    const expandable = [
        { dataIndex: 'authorizerName', label: t('vendor_author'), align: 'center', width: '50%' },
        { dataIndex: 'authorizerPhone', label: t('phone'), align: 'center', width: '50%' },
    ]

    return (
        <>
            <Container maxWidth={false} sx={{ mt: 5, mb: 8 }}>
                <Typography variant="h4" sx={{ mb: 3 }}>
                    {t('icon2_vendor_author_setup')}
                </Typography>

                <Card variant="outlined" sx={{ border: '1px solid rgba(5, 5, 5, .06)', borderRadius: '8px' }}>
                    <Box sx={{ padding: '32px 24px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    name='vendorDesc' value={searchForm.vendorDesc || ""} fullWidth size="small" label={t("company_name")} onChange={(event) => handleFormChange(event)} />
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                <FormControl>
                                    <LoadingButton
                                        loading={openLoading} variant="contained" onClick={handleSearchClick} sx={{ backgroundColor: "primaryBlue", color: "commom.white", }}
                                    >
                                        {t('Search')}
                                    </LoadingButton>
                                </FormControl>
                                <FormControl sx={{ marginLeft: "20px" }}>
                                    <Button
                                        variant="contained" onClick={handleResetClick} sx={{ backgroundColor: "primaryGreen", color: "commom.white", }} label={t('Reset')}
                                    >
                                        {t("Reset")}
                                    </Button>
                                </FormControl>
                                <FormControl sx={{ marginLeft: "20px" }}>
                                    <Button
                                        variant="contained" onClick={handleOpenAddClick} sx={{ backgroundColor: "#2d8d27", color: "commom.white", }} label={t('New')}
                                    >
                                        {t("New")}
                                    </Button>
                                </FormControl>
                            </Grid>

                        </Grid>
                    </Box>
                    <CTable disablePagination rowKey="userId" loading={tableLoading} columns={columns} data={rows} paperSx={{ border: 0 }}
                        expandable={{
                            expandedRowRender: (record) => (
                                <Grid container maxWidth="lg" spacing={1}>
                                    {record.vendorAuthors.map((value, index) => (
                                        <React.Fragment key={index}>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <CTableExpandable label={t("vendor_author") + '(' + ++index + ')'} value={value.authorizerName + ' ' + t("Mr/Ms")} />
                                            </Grid><Grid item xs={12} sm={6} md={6}>
                                                <CTableExpandable label={t("phone")} value={value.authorizerPhone} />
                                            </Grid>
                                        </React.Fragment>
                                    )
                                    )}
                                </Grid>
                            )
                        }}
                    />
                    <CPagination total={total} rowsPerPage={rowsPerPage} onChange={handleShowSizeChange} />
                </Card>
            </Container>
            {/* 提示框 */}
            <CustomDialog
                sx={{ whiteSpace: "pre-line" }}
                open={dialog.open}
                title={dialog.title}
                content={dialog.content}
                action={dialog.action}
            />

            {/* <EditForm open={formOpen} /> */}

            <CDialog
                fullWidth
                disableBackdropClick
                maxWidth={'md'}
                title={dialogTitle}
                open={formOpen}
                onClose={() => setFormOpen(false)}
            >
                <VendorAuthorForm onClose={() => {
                    setFormOpen(false)
                    refreshRows()
                }} username={tableRowDetail.loginId} dialogTitle={dialogTitle} />
            </CDialog>
        </>
    )
}

export default VendorAuthorSetup;
