import { Button, DialogContent, Typography } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { CustomDialog } from 'src/components/common'

function ITPolicyForm(prop) {
  // 彈出框
  const [dialog, setDialog] = useState({
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        {t('Confirm')}
      </Button>
    ),
    open: false,
  })

  const [dump, setDump] = React.useState('')

  const handleDump = (event) => {
    setDump(event.target.value)
    // console.log('dump', event.target.value)
    // console.log('herf2', '/newsmps/otherDataSetting/' + dump)
  }

  const handleMessageDump = () => {
    if (dump) {
      console.log('herf2', '/newsmps/otherDataSetting')
      // window.location.href = '/newsmps/otherDataSetting' + dump
      window.location.href = '/newsmps/otherDataSetting/otherDataSetting' + dump
    } else {
      window.location.href = '/newsmps/otherDataSetting/'
    }
  }

  return (
    <>
      {/* 提示框 */}
      <CustomDialog open={dialog.open} title={dialog.title} action={dialog.action} />

      <DialogContent dividers sx={{ pt: 3, pb: 3 }}>
        <Typography sx={{ whiteSpace: 'pre-wrap', lineHeight: '2.5' }}>{t('it_security_policy_zh')}</Typography>
      </DialogContent>
    </>
  )
}

export default ITPolicyForm
