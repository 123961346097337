import { React, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  CircularProgress,
  Typography,
  TextField,
  tableCellClasses,
  Divider,
  MenuItem,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import moment from "moment";
import CustomDialog from "src/components/common/CustomDialog";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MoveOrder from "src/components/icon/icon3/setupMenu/MoveOrder";
import * as api from "../../../utils/api/systemAdministrator/SetupMenuApi";

const ReportCenterTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    wordBreak: "keep-all",
    whiteSpace: "nowrap",
    fontSize: "15px",
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: "center",
  },
}));

const ReportLeftTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    wordBreak: "keep-all",
    whiteSpace: "nowrap",
    textAlign: "center",
    backgroundColor: "#c6c6c6",
    padding: "3px",
    fontSize: "14px",
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: "Left",
    padding: "3px",
    fontSize: "13px",
  },
}));

const ReportRightTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    wordBreak: "keep-all",
    whiteSpace: "nowrap",
    fontSize: "14px",
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: "right",
    padding: "3px",
    fontSize: "13px",
  },
}));

// setup user

export default function SetupMenu() {
  const [isEdit, setIsEdit] = useState(false);
  const [isSearchPage, setIsSearchPage] = useState(1);
  const { t } = useTranslation();
  const [backOpen, setBackOpen] = useState(false);
  const [sendData, setSendData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [dialog, setDialog] = useState({
    content: "",
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false });
        }}
      >
        {t("Confirm")}
      </Button>
    ),
    open: false,
  });
  const [rowData, setRowData] = useState({});
  const [editDialog, setEditDialog] = useState({});
  const [roleList, setRoleList] = useState([]);
  const dialogAction = (
    showCancelButton,
    handleConfirmDialog,
    handleCancelDialog
  ) => {
    if (showCancelButton) {
      return (
        <>
          <Button variant="contained" onClick={handleConfirmDialog}>
            {t("Confirm")}
          </Button>
          <Button variant="contained" onClick={handleCancelDialog}>
            {t("button_cancel")}
          </Button>
        </>
      );
    } else {
      return (
        <Button variant="contained" onClick={handleConfirmDialog}>
          {t("Confirm")}
        </Button>
      );
    }
  };
  const [moveDialog, setMoveDialog] = useState(false);
  const [reload, setReload] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setBackOpen(true);
    const getData = async () => {
      let allMenu = await api.getAllMenu();
      if (allMenu) {
        setTableData(allMenu);
      }
      setBackOpen(false);
    };
    getData();
  }, [reload]);

  const handleRole = (e) => {
    setRowData({ ...rowData, parentMenuId: e.target.value });
  };

  const handleSave = (e) => {
    if (!rowData.menuDescCn || !rowData.menuDescEn || !rowData.path) {
      setDialog({
        ...dialog,
        content: t("confirm_input"),
        open: true,
        action: dialogAction(
          false,
          () => {
            setDialog({
              ...dialog,
              open: false,
            });
          },
          null
        ),
      });
      return [];
    }
    setDialog({
      ...dialog,
      open: true,
      content: t("sure_save"),
      action: dialogAction(
        true,
        () => {
          const sendSave = async () => {
            setBackOpen(true);
            let send;
            if (rowData.menuId) {
              send = await api.editMenu(rowData);
            } else {
              send = await api.newMenu(rowData);
            }
            if (send.code === 200) {
              setBackOpen(false);
              setDialog({
                ...dialog,
                content: t("search_submit_success"),
                open: true,
                action: dialogAction(
                  false,
                  () => {
                    setDialog({
                      ...dialog,
                      open: false,
                    });
                    setEditDialog({ ...editDialog, open: false });
                    setReload(!reload);
                    setRowData({ ...rowData, open: false });
                  },
                  null
                ),
              });
            } else {
              setBackOpen(false);
              setDialog({
                ...dialog,
                open: true,
                content: send.message,
                action: dialogAction(
                  false,
                  () => {
                    setDialog({
                      ...dialog,
                      open: false,
                    });
                  },
                  null
                ),
              });
            }
          };
          sendSave();
        },
        () => {
          setDialog({
            ...dialog,
            open: false,
          });
        }
      ),
    });
  };

  const handleDeleteRow = (e) => {
    setDialog({
      ...dialog,
      open: true,
      content: t("delete"),
      action: dialogAction(
        true,
        () => {
          const deleteMenu = async () => {
            setBackOpen(true);
            let send;
            send = await api.deleteMenu(e);

            if (send.code === 200) {
              setBackOpen(false);
              setDialog({
                ...dialog,
                content: t("deletion_was_successful"),
                open: true,
                action: dialogAction(
                  false,
                  () => {
                    setDialog({
                      ...dialog,
                      open: false,
                    });
                    setReload(!reload);
                    setRowData({ ...rowData, open: false });
                  },
                  null
                ),
              });
            } else {
              setBackOpen(false);
              setDialog({
                ...dialog,
                open: true,
                content: send.message,
                action: dialogAction(
                  false,
                  () => {
                    setDialog({
                      ...dialog,
                      open: false,
                    });
                  },
                  null
                ),
              });
            }
          };
          deleteMenu();
        },
        () => {
          setDialog({
            ...dialog,
            open: false,
          });
        }
      ),
    });
  };

  const handleMove = async (move) => {
    setDialog({
      ...dialog,
      open: true,
      content: t("確認移動嗎？"),
      action: dialogAction(
        true,
        () => {
          const sendSave = async () => {
            setBackOpen(true);
            if (move === "up") {
              rowData.upType = true;
            } else {
              rowData.upType = false;
            }
            rowData.seqNum = rowData.seq;
            console.log("rowData", rowData);
            if (!rowData.parentMenuId) {
              rowData.parentMenuId = rowData.parentVo.parentMenuId;
            }
            let sendMove = await api.moveMenu(rowData);
            if (sendMove.code === 200) {
              setBackOpen(false);
              setDialog({
                ...dialog,
                content: t("移動成功！！"),
                open: true,
                action: dialogAction(
                  false,
                  () => {
                    setDialog({
                      ...dialog,
                      open: false,
                    });
                    setReload(!reload);
                  },
                  null
                ),
              });
            } else {
              setBackOpen(false);
              setDialog({
                ...dialog,
                open: true,
                content: send.message,
                action: dialogAction(
                  false,
                  () => {
                    setDialog({
                      ...dialog,
                      open: false,
                    });
                  },
                  null
                ),
              });
            }
          };
          sendSave();
          setDialog({
            ...dialog,
            open: false,
          });
        },
        () => {
          setDialog({
            ...dialog,
            open: false,
          });
        }
      ),
    });
  };

  const handleOpenChildren = (item, index) => {
    item.open = item.open ? !item.open : true;
    tableData[index] = { ...item };
    setTableData([...tableData]);
  };
  const handleMoveOpenChildren = (item, index) => {
    item.moveOpen = item.moveOpen ? !item.moveOpen : true;
    tableData[index] = { ...item };
    setTableData([...tableData]);
  };

  const handleAddRow = (data) => {
    console.log("parentMenuId", data.seq, data.parentMenuId);
    // const getAllRole = async () => {
    //     let get = await api.getRoleList()
    //     setRoleList(get)
    // }
    // getAllRole()
    let newParent = data.parentMenuId === null;
    setRowData({
      seq: data.seq,
      parentMenuId: data.parentMenuId,
      enableFlag: "Y",
      isParent: newParent,
    });
    setEditDialog({ ...editDialog, title: t("add"), open: true });
  };

  const handleEditRow = (data) => {
    console.log("before", isEdit);
    // const getAllRole = async () => {
    //     let get = await api.getRoleList()
    //     setRoleList(get)
    // }
    // getAllRole()
    console.log("rowData", data);
    setRowData({ ...data });
    setEditDialog({
      ...editDialog,
      title: t("button_edit"),
      open: true,
    });
    setIsEdit(true);
    console.log("after", isEdit);
  };

  const handleMoveRow = (data) => {
    setMoveDialog(true);
  };

  const handleCloseMove = () => {
    setMoveDialog(false);
  };

  const exportExcel = () => {};

  const handleBackTable = () => {
    setIsSearchPage(2);
  };

  // 承判商自動轉賬支薪報告
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomDialog
        sx={{ whiteSpace: "pre-line" }}
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        action={dialog.action}
      />
      <Container maxWidth={false} sx={{ textAlign: "center" }}>
        <Typography variant="h4" style={{ textAlign: "center" }}>
          <b>{t("Menu_Setup")}</b>
        </Typography>
        <TableContainer sx={{ my: "20px" }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <ReportCenterTableCell />
                <ReportCenterTableCell>{t("menu_code")} </ReportCenterTableCell>
                <ReportCenterTableCell>
                  {t("menu_desc_cn")}{" "}
                </ReportCenterTableCell>
                <ReportCenterTableCell>
                  {t("menu_desc_en")}{" "}
                </ReportCenterTableCell>
                <ReportCenterTableCell>{t("menu_path")} </ReportCenterTableCell>
                <ReportCenterTableCell>{t("menu_icon")} </ReportCenterTableCell>
                <ReportCenterTableCell sx={{ textAlign: "center" }}>
                  {t("operate")}
                </ReportCenterTableCell>
              </TableRow>
            </TableHead>

            {tableData.map((item, index) => (
              <TableBody key={index}>
                <TableRow hover>
                  <TableCell>
                    {item.childList?.length > 0 ? (
                      <IconButton
                        onClick={() => handleOpenChildren(item, index)}
                      >
                        {item.open ? (
                          <KeyboardArrowDownIcon />
                        ) : (
                          <KeyboardArrowUpIcon />
                        )}
                      </IconButton>
                    ) : null}
                  </TableCell>
                  <TableCell>{item.menuCode}</TableCell>
                  <TableCell>{item.menuDescCn}</TableCell>
                  <TableCell>{item.menuDescEn}</TableCell>
                  <TableCell>{item.path}</TableCell>
                  <TableCell>{item.iconId}</TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      onClick={() => handleAddRow(item)}
                      color="info"
                      sx={{ mx: "5px", my: "5px" }}
                      variant="outlined"
                    >
                      {t("add")}
                    </Button>
                    <Button
                      onClick={() => handleEditRow(item)}
                      size="small"
                      color="secondary"
                      sx={{ mx: "5px", my: "5px" }}
                      variant="outlined"
                    >
                      {t("button_edit")}
                    </Button>
                    <Button
                      onClick={() => handleMoveRow(item)}
                      size="small"
                      color="success"
                      sx={{ mx: "5px", my: "5px" }}
                      variant="outlined"
                    >
                      {t("button_move")}
                    </Button>
                    <Button
                      size="small"
                      color="error"
                      sx={{ mx: "5px", my: "5px" }}
                      variant="outlined"
                      onClick={() => handleDeleteRow(item)}
                    >
                      {t("button_delete")}
                    </Button>
                  </TableCell>
                </TableRow>
                {/* 子列表 */}
                <>
                  {item.open && (
                    <>
                      <TableRow>
                        <TableCell sx={{ my: "10px" }} colSpan={7} />
                      </TableRow>
                      {item.childList.map((child, i) => (
                        <TableRow sx={{ backgroundColor: "#f0f0f0" }} key={i}>
                          <TableCell />
                          <TableCell>{child.menuCode}</TableCell>
                          <TableCell>{child.menuDescCn}</TableCell>
                          <TableCell>{child.menuDescEn}</TableCell>
                          <TableCell>{child.path}</TableCell>
                          <TableCell>{child.iconId}</TableCell>
                          <TableCell>
                            <Button
                              size="small"
                              onClick={() => handleAddRow(child)}
                              color="secondary"
                              sx={{ mx: "5px", my: "5px" }}
                              variant="outlined"
                            >
                              {t("add")}
                            </Button>
                            <Button
                              size="small"
                              onClick={() => handleEditRow(child)}
                              color="secondary"
                              sx={{ mx: "5px", my: "5px" }}
                              variant="outlined"
                            >
                              {t("button_edit")}
                            </Button>
                            <Button
                              size="small"
                              onClick={() => handleMoveRow(child)}
                              color="success"
                              sx={{ mx: "5px", my: "5px" }}
                              variant="outlined"
                            >
                              {t("button_move")}
                            </Button>
                            <Button
                              size="small"
                              color="error"
                              sx={{ mx: "5px", my: "5px" }}
                              variant="outlined"
                              onClick={() => handleDeleteRow(child)}
                            >
                              {t("button_delete")}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell sx={{ my: "10px" }} colSpan={7} />
                      </TableRow>
                    </>
                  )}
                </>
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      </Container>
      {/* 编辑 */}
      <Dialog maxWidth="xs" fullWidth open={editDialog.open || false}>
        <DialogTitle>{editDialog.title}</DialogTitle>
        <Divider />
        <DialogContent>
          {/* <TextField error={!rowData.menuCode} onChange={(e) => { setRowData({ ...rowData, menuCode: e.target.value }) }} value={rowData.menuCode || ""} sx={{ my: "5px" }} inputProps={{ maxLength: 150 }} label={t("menu_code")} size="small" fullWidth /> */}
          <TextField
            error={!rowData.menuDescCn}
            onChange={(e) => {
              setRowData({ ...rowData, menuDescCn: e.target.value });
            }}
            value={rowData.menuDescCn || ""}
            sx={{ my: "5px" }}
            inputProps={{ maxLength: 150 }}
            label={t("menu_desc_cn")}
            size="small"
            fullWidth
          />
          <TextField
            error={!rowData.menuDescEn}
            onChange={(e) => {
              setRowData({ ...rowData, menuDescEn: e.target.value });
            }}
            value={rowData.menuDescEn || ""}
            sx={{ my: "5px" }}
            inputProps={{ maxLength: 150 }}
            label={t("menu_desc_en")}
            size="small"
            fullWidth
          />
          <TextField
            error={!rowData.path}
            onChange={(e) => {
              setRowData({ ...rowData, path: e.target.value });
            }}
            value={rowData.path || ""}
            sx={{ my: "5px" }}
            inputProps={{ maxLength: 150 }}
            label={t("menu_path")}
            size="small"
            fullWidth
          />
          <TextField
            onChange={(e) => {
              setRowData({ ...rowData, iconId: e.target.value });
            }}
            value={rowData.iconId || ""}
            sx={{ my: "5px" }}
            inputProps={{ maxLength: 150 }}
            label={t("menu_icon")}
            size="small"
            fullWidth
          />
          <TextField
            sx={{ textAlign: "left", my: "5px" }}
            disabled={rowData.parentMenuId === null}
            value={rowData.parentMenuId || ""}
            onChange={handleRole}
            fullWidth
            size="small"
            label={t("parent_memu")}
            select
          >
            {!isEdit && <MenuItem value="">None</MenuItem>}
            {tableData?.map((item, index) => (
              <MenuItem key={index} value={item.menuId}>
                {item.menuDescCn}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="contained" onClick={handleSave}>
            {t("button_save")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setEditDialog({ ...editDialog, open: false });
              setIsEdit(false);
            }}
          >
            {t("button_cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      {/* 移动 */}
      <MoveOrder
        handleMove={handleMove}
        setRowData={setRowData}
        handleMoveOpenChildren={handleMoveOpenChildren}
        handleCloseMove={handleCloseMove}
        moveDialog={moveDialog}
        menuData={tableData}
      />
    </>
  );
}
