import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'

/**
 * 弹窗标题
 */
function BootstrapDialogTitle(props) {
  const { children, disableCloseIcon, onClose, ...other } = props

  const handleCancel = () => {
    onClose()
  }

  return (
    <DialogTitle {...other}>
      {children}
      {disableCloseIcon ? (
        <IconButton aria-label="close" onClick={handleCancel} sx={{ position: 'absolute', right: 12, top: 12, color: (theme) => theme.palette.grey[500] }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

/**
 * CDialog
 *
 * API:
 *
 * - [Dialog API](https://mui.com/material-ui/api/dialog/)
 * - inherits [Modal API](https://mui.com/material-ui/api/modal/)
 */
export default function CDialog(porps) {
  const { open, children, disableBackdropClick = false, title, disableCloseIcon = true, onClose, message, ...other } = porps

  const handleCancel = () => {
    if (!disableBackdropClick) {
      onClose()
    }
  }

  return (
    <Dialog scroll="paper" aria-labelledby="scroll-dialog-title" maxWidth="lg" open={open} onClose={handleCancel} {...other}>
      {title ? (
        <BootstrapDialogTitle id="scroll-dialog-title" disableCloseIcon={disableCloseIcon} onClose={onClose}>
          {title}
        </BootstrapDialogTitle>
      ) : null}
      {children}
      {message && (
        <DialogContent dividers sx={{ pt: 3, pb: 3 }}>
          {message}
        </DialogContent>
      )}
    </Dialog>
  )
}
