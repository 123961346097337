import { instance } from "../../instance";

// PFS_Application_History_View.aspx
export const getPFSApplicationHistoryViewHeader = async (applicationId) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getPFSApplicationHistoryViewHeader`,
    data: {
        applicationId
    }
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      alert("請求出錯");
      return [];
    }
  );
};

export const getPFSApplicationHistoryViewList = async (
    applicationId,
    smartCardId,
    siteId,
    vendorId,
    yearmonth,
    effectiveDate) => {
    return instance({
      method: "POST",
      url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getPFSApplicationHistoryViewList`,
      data: {
        applicationId,
        smartCardId,
        siteId,
        vendorId,
        yearmonth,
        effectiveDate
    }
    }).then(
      (result) => {
        return result.data.data;
      },
      (error) => {
        alert("請求出錯");
        return [];
      }
    );
  };


  // PFS_Application_Apply
  // header
  export const getPFSApplicationApplyHeader = async (smartCardId, siteId, effectiveDate) => {
    return instance({
      method: "POST",
      url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getPFSApplicationApply`,
      data: {
       smartCardId,
       siteId,
       effectiveDate
      },
    }).then(
      (result) => {
        return result.data.data;
      },
      (error) => {
        alert("請求出錯");
        return [];
      }
    );
  };

  export const getPFSApplicationApplyList = async (siteId, smartCardId, vendorId, yearmonth, effectiveDate) => {
    return instance({
      method: "POST",
      url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getPFSApplicationApplyList`,
      data: {
       siteId,
       smartCardId,
       vendorId,
       yearmonth,
       effectiveDate
      },
    }).then(
      (result) => {
        return result.data.data;
      },
      (error) => {
        alert("請求出錯");
        return [];
      }
    );
  };

  // create
  export const createPFSApplicationApply = async (params) => {
    return instance({
      method: "POST",
      url: `${process.env.REACT_APP_WEB_URL}/attendance/report/createPFSApplicationApply`,
      data: params,
    }).then(
      (result) => {
        return result.data;
      },
      (error) => {
        alert("請求出錯");
        return [];
      }
    );
  };