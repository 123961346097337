// import { React, useState, useEffect } from "react";
// import { Button, Grid, Box, CircularProgress, Typography } from "@mui/material";
// import { useTranslation } from "react-i18next";
// import { instance } from "src/utils/instance";
// import Container from "@mui/material/Container";
// import { oneBatch } from "../../../../utils/api/OneBatchApi";

// export default function Button4(props) {
//   const [open, setOpen] = useState(true);
//   const [error, setError] = useState(false);
//   const { setType } = props;
//   const { t } = useTranslation();

//   const handleBackToFlowMenu = () => {
//     setType(0);
//   };

//   useEffect(() => {
//     // 執行邏輯
//     const doJob = async () => {
//       setOpen(true);
//       let data = await oneBatch();
//       if (data.code && data.code === 200) {
//         setError(false);
//       } else {
//         setError(true);
//       }
//       setOpen(false);
//     };
//     doJob();
//   }, []);

//   return (
//     // <Container maxWidth="xs" sx={{ marginTop: 20 }}>
//     //   <Grid container justifyContent="center" alignItems="center">
//     //     <Grid item xs={4.5}>

//     //     </Grid>
//     //     <Grid item xs={12}>

//     //     </Grid>
//     //     <Grid item xs={6}>

//     //     </Grid>
//     //   </Grid>
//     // </Container>

//     <div style={{ marginTop: "100px" }}>
//       {open ? (
//         <Box>
//           <CircularProgress />
//         </Box>
//       ) : (
//         <></>
//       )}

//       {!open ? (
//         !error ? (
//           <Typography>{t("icon1_autopay_button4_print_label1")}</Typography>
//         ) : (
//           <Typography>{t("icon1_autopay_button4_print_label2")}</Typography>
//         )
//       ) : (
//         <></>
//       )}

//       <Button onClick={handleBackToFlowMenu}>
//         {t("icon1_autopay_button4_print_label3")}
//       </Button>
//     </div>
//   );
// }





import { React, useState, useEffect } from "react";
import {
  Button,
  Box,
  Backdrop,
  CircularProgress,
  Typography,
  Container,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { oneBatch, changePayTpye } from "../../../../utils/api/workerAttendanceRecord/OneBatchApi";

export default function OneBatch(props) {
  const [inProgress, setInProgress] = useState(true);
  const [error, setError] = useState(false);
  const { setType } = props;
  const { t } = useTranslation();

  const handleBackToFlowMenu = () => {
    setType(0);
  };

  useEffect(() => {
    // 改變 payRollType
    // 執行邏輯
    const doJob = async () => {
      setInProgress(true);
      let changePollType = await changePayTpye()
      let res = await oneBatch();
      if (changePollType && res && res.code && res.code === 200) {
        setError(false);
      } else {
        setError(true);
      }
      setInProgress(false);
    };
    doJob();
  }, []);

  return (
    <Container style={{ marginTop: "100px", textAlign: "center", maxWidth: "1240px" }}>
      {inProgress ? (
        <Box>
          <Backdrop
            open={true}
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      ) : (
        <></>
      )}

      {!inProgress ? (
        !error ? (
          <Typography>{t("icon1_autopay_oneBatch_print_label1")}</Typography>
        ) : (
          <Typography>{t("icon1_autopay_oneBatch_print_label2")}</Typography>
        )
      ) : (
        <></>
      )}

      <Button onClick={handleBackToFlowMenu}>
        {t("icon1_autopay_oneBatch_print_label3")}
      </Button>
    </Container>
  );
}
