import React, { useRef, useEffect } from 'react';
import {
  Button,
  Container,
  Paper,
  Typography,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow, Backdrop, CircularProgress
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams,createSearchParams } from 'react-router-dom';
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function ReportMonthsDetailView() {
  const componentRef = useRef()
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);

  const handleBack = () => {
    navigate(-1)
  }

  const [listData, setListData] = React.useState([]);

  useEffect(() => {
    const getMonthlySummaryDetailView = async () => {

      let dict = await apiAtt.getMonthlySummaryDetailView(
        searchParams.get("siteId"),
        searchParams.get("vendorId"),
        searchParams.get("smartCardId"),
        searchParams.get("yearmonth").replace("-", ""),
      )
      if (dict !== null) {
        setListData(dict)
      }
      setLoading(false)
    }
    getMonthlySummaryDetailView()
  }, [])



  const getTotalCount = (personData) => {
    let total = 0

    personData.map((value) => {
      total += parseFloat(value.monthlyDay)

      return []
    })

    return total.toFixed(2)
  }

  const goDetail = (e, row) => {
    navigate({
      pathname: "/workerAttendanceRecord/adocspMain/applyList/applyEditOrView",
      // isViewData 为1的时候表示是查看数据 为0的时候表示编辑数据
      // 這裡只能觀看，所以設置為1
      search: `?${createSearchParams({
        isViewData: true,
        applicationId: row.applicationId,
        applicationSeqNo: row.applicationSeqNo,
        reportName: t("search_vendor_ss_detail_record"),
      })}`,
    });
  };

  return (
    <>
      <div style={{ display: 'block', textAlign: 'left', }}>
        <Button sx={{ float: 'left' }} onClick={handleBack}><ArrowBackIcon />{t('button_back')}</Button>
      </div>
      {/*       <div style={{ display: 'block', textAlign: 'right' }}>
        <Button sx={{ float: "right", mr: "20px" }} onClick={() => exportExcel(
          {
            "siteId": searchParams.get("siteId"),
            "siteDesc": searchParams.get("siteDesc"),  
            "dateFrom": searchParams.get("dateFrom"),
            "dateTo": searchParams.get("dateTo")
          }
        )}>
          <DownloadIcon />
          {t("button_export_excel")}
        </Button>
        <Button sx={{ float: "right", mr: "20px" }} onClick={() => exportPDF(
          {
            "siteId": searchParams.get("siteId"),
            "siteDesc": searchParams.get("siteDesc"),  
            "dateFrom": searchParams.get("dateFrom"),
            "dateTo": searchParams.get("dateTo")
      
          }
        )}>
          <DownloadIcon />
          {t("button_export_pdf")}
        </Button>
      </div> */}

      <div id='div' ref={componentRef} style={{ textAlign: 'cteter', }}>
        <Container maxWidth="false" >

          <div>
            <div style={{ display: 'block', marginBottom: '60px' }}>
              <Typography variant='h4' sx={{ textAlign: 'center' }}>
                <b>{t("search_vendor_malfcafsss_person")}</b></Typography>
            </div>

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <div  >
              <TableContainer sx={{ marginTop: '30px', }}>
                <Table sx={{ minWidth: 1000, p: '2px', justifyContent: 'center' }} size="small" scroll={{ x: true }} >
                <TableBody>
                  <TableRow    >
                    <TableCell sx={{ fontSize: '15px', px: '5px', width: 160, textAlign: 'left' }}>{t("search_site_title") + '：'}	</TableCell>
                    <TableCell sx={{ fontSize: '15px', px: '5px', minWidth: '160px', }}>{searchParams.get("siteDesc") === null ? 'All' : searchParams.get("siteDesc")}</TableCell>
                  </TableRow>
                  <TableRow    >
                    <TableCell sx={{ fontSize: '15px', px: '5px', width: 160, textAlign: 'left' }}>{t("Contractor") + '：'}</TableCell>
                    <TableCell sx={{ fontSize: '15px', px: '5px', minWidth: '160px', }}>{searchParams.get("vendorDesc") === null ? 'All' : searchParams.get("vendorDesc")}</TableCell>
                  </TableRow>

                  <TableRow sx={{ minHeight: 50 }}>
                    <TableCell sx={{ fontSize: '15px', px: '5px', width: 160, textAlign: 'left' }}>{t("search_employment_position")}</TableCell>
                      <TableCell  align="left" sx={{ fontSize: '15px', px: '5px', minWidth: '160px' }}>{searchParams.get("safetyPositionDesc")}  </TableCell>

                  </TableRow>

                  <TableRow  >
                    <TableCell sx={{ fontSize: '15px', px: '5px', width: 160, textAlign: 'left' }}>{t("search_english_name_dot")}</TableCell>
                      <TableCell  align="left" sx={{ fontSize: '15px', px: '5px', minWidth: '160px', }}>{searchParams.get("engName")}  </TableCell>
                  </TableRow>

                  <TableRow sx={{}} >
                    <TableCell sx={{ fontSize: '15px', px: '5px', width: 160, textAlign: 'left' }}>{t("search_chinese_name_dot")}</TableCell>
                      <TableCell  align="left" sx={{ fontSize: '15px', minHeight: '50px', px: '5px', minWidth: '160px', }}>{searchParams.get("chName")}  </TableCell>
                  </TableRow>

                  <TableRow sx={{ height: '50px' }} >
                    <TableCell sx={{ fontSize: '15px', px: '5px', width: 160, textAlign: 'left' }}>{t("search_smartCardId_dot")}</TableCell>
                      <TableCell  align="left" sx={{ fontSize: '15px', minHeight: '50px', px: '5px', minWidth: '160px', }}>{searchParams.get("smartCardId")}  </TableCell>
                  </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <TableContainer sx={{ marginTop: '30px', }}>
                <Table sx={{ minWidth: 800, p: '2px', justifyContent: 'center' }} size="small" scroll={{ x: true }} >
                <TableBody>
                  <TableRow sx={{ height: '50px' }} >
                    <TableCell sx={{ whiteSpace: "nowrap", fontSize: '18px', textAlign: 'left'  }}><b>{t("search_enttyValidDateFrom")}</b></TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap", fontSize: '18px', textAlign: 'left'  }}><b>{t("search_enttyValidDateTo")}</b></TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap", fontSize: '18px', textAlign: 'center'  }}><b>{t("search_approve_day")}</b></TableCell>
                  </TableRow>
                    {listData.map((row2, index) => (
                      <TableRow hover key={index + Math.random}>
                        <TableCell>{row2.effectiveDate}</TableCell>
                        <TableCell>{row2.effectiveDateTo}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: '18px', textAlign: 'center'  }}><Link style={{cursor:"pointer"}} onClick={(e) => goDetail(e, row2)}>{row2.monthlyDay}</Link></TableCell>
                      </TableRow>
                    ))}
                    <TableRow sx={{ height: '50px' }} >
                      <TableCell sx={{ whiteSpace: "nowrap", }}> </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap", fontSize: '18px', textAlign: 'center',color: "blue"  }}><b>{t("total")}</b></TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap", fontSize: '18px', textAlign: 'center' ,color: "blue" }}><b>{getTotalCount(listData)}</b></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Container>
      </div>

    </>
  )
}

