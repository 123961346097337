import { instance } from "../../instance";

// System Parameter - 顯示
export const selectLists = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/managementController/getStrSQL`,
        data: data,
    }).then(
        (result) => result.data,
        (error) => error
    );
};
// System Parameter - 新建
export const insertData = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/managementController/insertStrSQL`,
        data: data,
    }).then(
        (result) => result.data,
        (error) => error
    );
};
// System Parameter - 修改
export const updateData = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/managementController/adminSysParaEdit`,
        data: data,
    }).then(
        (result) => result.data,
        (error) => error
    );
};
// System Parameter - 刪除
export const deleteData = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/managementController/adminSysParaDelete`,
        data: data,
    }).then(
        (result) => result.data,
        (error) => error
    );
};