import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Divider,
  Container,
  Grid,
  FormControl,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  tableCellClasses,
  MenuItem,
} from '@mui/material'
import $http, { $download } from 'src/utils/api/services'
import html2canvas from 'html2canvas'
import { styled } from '@mui/material/styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import moment from 'moment'
import CustomDialog from 'src/components/common/CustomDialog'
import JSPDF from 'jspdf'
import * as api from '../../../utils/api/vendorAutopaySystem/AutopayPayrollReportAllApi'

const ReportCenterTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'center',
  },
}))

const ReportLeftTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    backgroundColor: '#c6c6c6',
    padding: '3px',
    fontSize: '14px',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'Left',
    padding: '3px',
    fontSize: '13px',
  },
}))

const ReportRightTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    fontSize: '14px',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'right',
    padding: '3px',
    fontSize: '13px',
  },
}))

// 承判商自動轉賬支薪報告

export default function AutopayPayrollReportAll() {
  const contentRef = useRef(null)
  const [isSearchPage, setIsSearchPage] = useState(true)
  const { t } = useTranslation()
  const [backOpen, setBackOpen] = useState(false)
  const [dateList, setDateList] = useState([])
  const [sendData, setSendData] = useState({})
  const [tableData, setTableData] = useState([])
  const [dialog, setDialog] = useState({
    content: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        確認
      </Button>
    ),
    open: false,
  })
  useEffect(() => {
    setBackOpen(true)
    const getData = async () => {
      let getDateList = await api.getDate()
      if (getDateList) {
        setDateList(getDateList)
      }
      setBackOpen(false)
    }
    getData()
  }, [])

  const handleBack = () => {
    setIsSearchPage(true)
  }

  const handleDateFrom = (e) => {
    setSendData({ ...sendData, dateFrom: e.target.value, dateTo: '' })
  }

  const handleDateTo = (e) => {
    if (new Date(e.target.value) < new Date(sendData.dateFrom)) {
      if (!sendData.dateFrom || !sendData.dateTo) {
        setDialog({
          ...dialog,
          content: '日期由不能大於日期至',
          open: true,
        })
        return []
      }
      return
    }
    setSendData({ ...sendData, dateTo: e.target.value })
  }

  const handleSubmit = (e) => {
    if (!sendData.dateFrom || !sendData.dateTo) {
      setDialog({
        ...dialog,
        content: '請選擇日期由/至',
        open: true,
      })
      return []
    }
    const send = async () => {
      setBackOpen(true)
      let sendApi = await api.getTable(sendData)
      if (sendApi) {
        setTableData(sendApi)
      }
      setBackOpen(false)
      setIsSearchPage(false)
    }
    send()
  }

  const resetForm = (e) => {
    setSendData({})
  }

  const exportExcel = (data) => {
    $download(`/payrollReportController/venDorAutoPayWorkDayListTwolistApitExcel`, 'application/vnd.ms-excel', data)
  }
  // 打印
  // const handlePrint = async () => {
  //   try {
  //     const content = contentRef.current
  //     const scale = 0.6
  //     if (content) {
  //       const canvas = await html2canvas(content, { scale: scale })
  //       const imgData = canvas.toDataURL('image/png')

  //       // 创建一个新窗口用于打印
  //       const printWindow = window.open('', '', 'width=600,height=600')
  //       printWindow.document.open()
  //       printWindow.document.write('<img src="' + imgData + '" />')
  //       printWindow.document.close()

  //       // 等待图像加载完成后再执行打印
  //       printWindow.onload = function () {
  //         printWindow.print()
  //         printWindow.close()
  //       }
  //     }
  //   } catch (error) {
  //     console.error('打印出错：', error)
  //   }
  // }
  const handlePrint = async (htmlTitle, currentTime) => {
    let element = document.getElementById('contentRef')
    html2canvas(element, {
      logging: false,
    }).then(function (canvas) {
      let pdf = new JSPDF('l', 'mm', 'a4') // A4纸，纵向
      let ctx = canvas.getContext('2d')
      let a4w = 275
      let a4h = 400 // A4大小，210mm x 297mm，四边各保留20mm的边距
      let imgHeight = Math.floor((a4h * canvas.width) / a4w) // 按A4显示比例换算一页图像的像素高度
      let renderedHeight = 0
      console.log('imgHeight', imgHeight)

      while (renderedHeight < canvas.height) {
        let page = document.createElement('canvas')
        page.width = canvas.width
        page.height = Math.min(imgHeight, canvas.height - renderedHeight) // 可能内容不足一页 // 用getImageData剪裁指定区域，并画到前面创建的canvas对象中

        page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0)
        pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 10, a4w, Math.min(a4h, (a4w * page.height) / page.width)) // 添加图像到页面，保留10mm边距

        renderedHeight += imgHeight
        if (renderedHeight < canvas.height) {
          pdf.addPage()
        } // 如果后面还有内容，添加一个空页 // delete page;
      }
      pdf.save(htmlTitle + currentTime)
    })
  }

  // 承判商自動轉賬支薪報告
  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomDialog sx={{ whiteSpace: 'pre-line' }} open={dialog.open} title={dialog.title} content={dialog.content} action={dialog.action} />
      {isSearchPage && (
        <form>
          <Container maxWidth="md" sx={{ textAlign: 'center' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                  <b>{t('autopayPayrollReportAll')}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ maxWidth: '400px', mt: '0px', textAlign: 'left' }}
                  value={sendData.dateFrom || ''}
                  error={!sendData.dateFrom}
                  fullWidth
                  size="small"
                  label={t('date_from')}
                  select
                  onChange={handleDateFrom}
                >
                  {dateList.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ maxWidth: '400px', mt: '0px', textAlign: 'left' }}
                  value={sendData.dateTo || ''}
                  error={!sendData.dateTo}
                  fullWidth
                  size="small"
                  label={t('search_date_to_noDot')}
                  select
                  onChange={handleDateTo}
                >
                  {dateList.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
                <Typography>{t('Maximum_range_12_months')}</Typography>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    backgroundColor: 'primaryBlue',
                    color: 'commom.white',
                  }}
                >
                  {t('button_submit')}
                </Button>
                <Button
                  variant="contained"
                  onClick={resetForm}
                  sx={{
                    marginLeft: '20px',
                    backgroundColor: 'primaryGreen',
                    color: 'commom.white',
                    marginRight: '10px',
                  }}
                >
                  {t('button_reset')}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </form>
      )}

      {!isSearchPage && (
        <Container maxWidth={false}>
          <div
            style={{
              display: 'block',
              textAlign: 'left',
              marginTop: '0px',
            }}
          >
            <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
              <ArrowBackIcon />
              {t('button_back')}
            </Button>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => handlePrint(t('autopayPayrollReportAll'), new Date())}>
              <DownloadIcon />
              {t('列印')}
            </Button>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportExcel(sendData)}>
              <DownloadIcon />
              {t('button_export_excel')}
            </Button>
          </div>

          <div id="contentRef">
            <Typography variant="h4" style={{ textAlign: 'center', mt: '-30px' }}>
              <b>
                {t('autopayPayrollReport')}({moment(new Date(sendData.dateFrom)).format('yyyy/MM')}到{moment(new Date(sendData.dateTo)).format('yyyy/MM')})
              </b>
            </Typography>
            <Typography sx={{ my: '10px' }}>
              {t('Production_Date')}:{moment(new Date().getTime()).format('yyyy/MM/DD')}{' '}
            </Typography>
            <Grid container>
              <Grid item xs={2} md={2} sx={{ border: '2px solid' }}>
                <Table hover padding="none" size="small">
                  <TableHead>
                    <TableRow>
                      <ReportLeftTableCell>不計算工種</ReportLeftTableCell>
                      <ReportLeftTableCell>工數</ReportLeftTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.WorkDaylist?.map((item, index) => (
                      <TableRow hover>
                        <ReportLeftTableCell>{item.jobNatureDesc}</ReportLeftTableCell>
                        <ReportRightTableCell>{item.noofDaysFromat}</ReportRightTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Divider sx={{ height: '25px', backgroundColor: '#e6e6e6' }} />
                <Table padding="none" size="small">
                  <TableBody>
                    <TableRow hover>
                      <ReportLeftTableCell>
                        與括免自動轉賬支薪公司和NSC工種重復的工數
                        {/* {t("NSC_Worker_Day")} */}
                      </ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.diffNoComputWorkdayformat}</ReportRightTableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>
                        總工數(A)
                        {/* {t("Total_number_of_workers")}(A) */}
                      </ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.nocomputedaysformat}</ReportRightTableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>
                        自動轉賬總工數(N)
                        {/* {t("Total_number_autopay")}(N) */}
                      </ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.autonocomputwkformat}</ReportRightTableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>
                        自動轉賬總金額$(M)
                        {/* {t("Total_Auto_Transfer_Amount")}$(M) */}
                      </ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.autonocomputincomeformat}</ReportRightTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={4} md={4} sx={{ border: '2px solid', borderLeftWidth: '0px' }}>
                <Table padding="none" size="small">
                  <TableHead>
                    <TableRow>
                      <ReportLeftTableCell>括免自動轉賬支薪公司</ReportLeftTableCell>
                      <ReportLeftTableCell>括免工數</ReportLeftTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.WorkThrees?.map((item, index) => (
                      <TableRow hover>
                        <ReportLeftTableCell>{item.vendorDesc}</ReportLeftTableCell>
                        <ReportRightTableCell>{item.noofDaysformat}</ReportRightTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Divider sx={{ height: '25px', backgroundColor: '#e6e6e6' }} />
                <Table padding="none" size="small">
                  <TableBody>
                    <TableRow hover>
                      <ReportLeftTableCell>與不計算工種和NSC工種重復的工數</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.diffWorkdayformat}</ReportRightTableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>總工數(B)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.exemptiondaysformat}</ReportRightTableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>新合約自動轉賬總工數(J)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.autoexemptiondaysformat}</ReportRightTableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>新合約自動轉賬總金額$(K)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.autoexemptionincomeformat}</ReportRightTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid
                item
                xs={2}
                md={2}
                sx={{
                  border: '2px solid',
                  borderRightWidth: '0px',
                  borderLeftWidth: '0px',
                }}
              >
                <Table padding="none" size="small">
                  <TableHead>
                    <TableRow>
                      <ReportLeftTableCell>NSC工種</ReportLeftTableCell>
                      <ReportLeftTableCell>NSC工數</ReportLeftTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.WorkFives?.map((item, index) => (
                      <TableRow hover>
                        <ReportLeftTableCell>{item.jobNatureDesc}</ReportLeftTableCell>
                        <ReportRightTableCell>{item.nscDaysformat}</ReportRightTableCell>
                      </TableRow>
                    ))}
                    <TableRow hover>
                      <TableCell>
                        <Divider sx={{ height: '25px', backgroundColor: '#e6e6e6' }} />
                      </TableCell>
                      <TableCell>
                        <Divider sx={{ height: '25px', backgroundColor: '#e6e6e6' }} />
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>與不計算工種和括免自動轉賬支薪公司重復的工數</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.diffNSCWorkdayformat}</ReportRightTableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>總工數(C)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.nscdaysformat}</ReportRightTableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>新合約自動轉賬總工數(H)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.nscautodaysformat}</ReportRightTableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>新合約自動轉賬總金額$(I)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.nscautoincomeformat}</ReportRightTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={4} md={4} sx={{ border: '2px solid' }}>
                <Table padding="none" size="small">
                  <TableHead>
                    <TableRow>
                      <ReportLeftTableCell colSpan={2}>總結</ReportLeftTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover>
                      <ReportLeftTableCell>地盤總工數(F)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.alldaysformat}</ReportRightTableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>DSC工數(F-A-B-C)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.dscworkdayformat}</ReportRightTableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>NSC工數(C)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.nscdaysformat}</ReportRightTableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>括免自動轉賬工數(B)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.exemptiondaysformat}</ReportRightTableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>不計算工數(A)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.nocomputedaysformat}</ReportRightTableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>自動轉賬工數(不包括H,J,N)(E)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.autopayworkdayformat}</ReportRightTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Divider sx={{ height: '25px', backgroundColor: '#e6e6e6' }} />
                <Table padding="none" size="small">
                  <TableBody>
                    <TableRow hover>
                      <ReportLeftTableCell>DSC自動轉賬百分率(E)/(F-A-B-C)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.autopayworkdaytageformat}</ReportRightTableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell
                        colSpan={2}
                        sx={{
                          textAlign: 'center',
                          fontSize: '13px',
                          py: '2px',
                        }}
                      >
                        {'自動轉賬工數'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Divider ariant="middle" sx={{ backgroundColor: '#000000' }} />
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell
                        colSpan={2}
                        sx={{
                          textAlign: 'center',
                          fontSize: '13px',
                          py: '2px',
                        }}
                      >
                        {'總工數-不計算工數-括免自動轉賬工數-NSC工數'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Divider sx={{ height: '25px', backgroundColor: '#e6e6e6' }} />
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>自動轉賬百分率(E+H)/(F-A-B)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.autopaynscautodaystageformat}</ReportRightTableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell
                        colSpan={2}
                        sx={{
                          textAlign: 'center',
                          fontSize: '13px',
                          py: '2px',
                        }}
                      >
                        {'自動轉賬工數+NSC自動轉賬工數'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Divider ariant="middle" sx={{ backgroundColor: '#000000' }} />
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell
                        colSpan={2}
                        sx={{
                          textAlign: 'center',
                          fontSize: '13px',
                          py: '2px',
                        }}
                      >
                        {'地盤總工數-不計算工數-括免自動轉賬工數'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Divider sx={{ height: '25px', backgroundColor: '#e6e6e6' }} />
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>DSC自動轉賬百分率(E+B)/(F-A-C)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.autopayexemptiondaystageformat}</ReportRightTableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell
                        colSpan={2}
                        sx={{
                          textAlign: 'center',
                          fontSize: '13px',
                          py: '2px',
                        }}
                      >
                        {'自動轉賬工數+括免自動轉賬工數'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Divider ariant="middle" sx={{ backgroundColor: '#000000' }} />
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell
                        colSpan={2}
                        sx={{
                          textAlign: 'center',
                          fontSize: '13px',
                          py: '2px',
                        }}
                      >
                        {'地盤總工數-不計算工數-NSC工數'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Divider sx={{ height: '25px', backgroundColor: '#e6e6e6' }} />
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>自動轉賬百分率(E+B+H)/(F-A)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.autopayexemptionnscautodaystageformat}</ReportRightTableCell>
                    </TableRow>

                    <TableRow hover>
                      <TableCell
                        colSpan={2}
                        sx={{
                          textAlign: 'center',
                          fontSize: '13px',
                          py: '2px',
                        }}
                      >
                        {'自動轉賬工數+括免自動轉賬工數+NSC自動轉賬工數'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Divider ariant="middle" sx={{ backgroundColor: '#000000' }} />
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell
                        colSpan={2}
                        sx={{
                          textAlign: 'center',
                          fontSize: '13px',
                          py: '2px',
                        }}
                      >
                        {'地盤總工數-不計算工數'}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={2}>
                        <Divider sx={{ height: '25px', backgroundColor: '#e6e6e6' }} />
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>自動轉賬支薪總金額$(不包括I,K,M)(G)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.autopayincomeformat}</ReportRightTableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>平均每日工資$(G/E)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.yueoneformat}</ReportRightTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Divider sx={{ height: '25px', backgroundColor: '#e6e6e6' }} />
                <Table padding="none" size="small">
                  <TableBody>
                    <TableRow hover>
                      <ReportLeftTableCell>自動轉賬支薪總金額$(L)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.yuetwoformat}</ReportRightTableCell>
                    </TableRow>
                    <TableRow hover>
                      <ReportLeftTableCell>平均每日工資$(L)/(E+H+J+N)</ReportLeftTableCell>
                      <ReportRightTableCell>{tableData.yuethreeformat}</ReportRightTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  px: '3px',
                  border: '2px solid',
                  borderTopWidth: '0',
                  fontSize: '13px',
                }}
              >
                備注: @:有做自動轉賬; #:完全未做自動轉賬; *:參與工数少於15%, ~:参與工数少於40%;
                <br />
                對数: 1.E+N+J+H=报表: "承判商/工人自動轉賬状况分析"的數據项:"參與自動轉賬日数"
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2.L=报表:"承判商/工人自動轉賬状况分析"的數據项:"工人總收入"
                <br />
                如果對数不等,则表示不計算工種或者括免自動轉賬支薪公司或者NSC工種有重複的數據
                <br />
                &nbsp;
              </Grid>
            </Grid>
          </div>
        </Container>
      )}
    </>
  )
}
