import React, { useRef, useEffect } from 'react'
import { Container, Typography, Paper, Table, TableBody, TableHead, TableCell, TableContainer, TableRow, Backdrop, CircularProgress } from '@mui/material'

import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts'
import html2canvas from 'html2canvas'
import { useTranslation } from 'react-i18next'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import $http, { $download } from 'src/utils/api/services'
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import DownloadIcon from '@mui/icons-material/Download'
import { priceFormat } from 'src/utils/priceFormat'

export default function CattpwspReportChartDetail() {
  const contentRef = useRef(null)
  const componentRef = useRef()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [dataSource, setDataSource] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  useEffect(() => {}, [])

  const handleBack = () => {
    navigate(-1)
  }

  const printComponentRef = useRef()

  useEffect(() => {
    getTradeReportList()
  }, [])

  const getTradeReportList = async () => {
    let list = await apiAtt.getTradeReportList(searchParams.get('dataYear'), searchParams.get('tradeId'))

    if (list !== null) {
      setDataSource(list)
    }
    setLoading(false)
  }

  const exportPdf = (data) => {
    $download(`/payrollReportController/tradeReportListPdf`, 'application/pdf', data)
  }

  const exportExcel = (data) => {
    $download(`/payrollReportController/tradeReportListVoExcel`, 'application/vnd.ms-excel', data)
  }

  // 打印
  const handlePrint = async () => {
    try {
      const content = contentRef.current
      // const width = content.clientWidth;
      // const height = content.clientHeight;
      //   contentRef.scrollLeft = 0;

      const scale = 0.6

      if (content) {
        const canvas = await html2canvas(content, { scale: scale })
        const imgData = canvas.toDataURL('image/png')

        // 创建一个新窗口用于打印
        const printWindow = window.open('', '', 'width=600,height=600')
        printWindow.document.open()
        printWindow.document.write('<img src="' + imgData + '" />')
        printWindow.document.close()

        // 等待图像加载完成后再执行打印
        printWindow.onload = function () {
          printWindow.print()
          printWindow.close()
        }
      }
    } catch (error) {
      console.error('打印出错：', error)
    }
  }

  return (
    <Container>
      <div style={{ display: 'block', textAlign: 'left' }}>
        <Button sx={{ float: 'left' }} onClick={handleBack}>
          <ArrowBackIcon />
          {t('button_back')}
        </Button>
      </div>

      <div style={{ display: 'block', textAlign: 'right' }}>
        <Button
          sx={{ float: 'right', mr: '20px' }}
          onClick={() =>
            exportPdf({
              year: searchParams.get('dataYear'),
              jobNatureId: searchParams.get('tradeId'),
            })
          }
        >
          <DownloadIcon />
          {t('button_export_pdf')}
        </Button>

        <Button
          sx={{ float: 'right', mr: '20px' }}
          onClick={() =>
            exportExcel({
              year: searchParams.get('dataYear'),
              jobNatureId: searchParams.get('tradeId'),
            })
          }
        >
          <DownloadIcon />
          {t('button_export_excel')}
        </Button>
        <Button sx={{ float: 'right', mr: '20px' }} onClick={() => handlePrint()}>
          <DownloadIcon />
          {t('列印')}
        </Button>
      </div>

      <div id="div" style={{}} ref={contentRef}>
        <Container>
          <div>
            <div style={{ display: 'block', marginBottom: '60px' }}>
              <Typography variant="h4" sx={{ textAlign: 'center' }}>
                <b>{t('search_autoTransPay_tips') + '(' + searchParams.get('dataYear') + ')'}</b>
              </Typography>
            </div>

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>

            {/* table */}
            <TableContainer sx={{ marginTop: '20px', maxHeight: 440, maxWidth: '100vw' }}>
              <Table sx={{ p: '2px' }} size="small" stickyHeader>
                <TableHead>
                  {/* TableRow是行，表示有多少行 */}
                  {/* TableCell是列，橫著看 */}
                  <TableRow hover>
                    <TableCell rowSpan={4} sx={{ whiteSpace: 'nowrap' }}>
                      {t('search_trade_title')}
                    </TableCell>
                    <TableCell rowSpan={4} sx={{ whiteSpace: 'nowrap' }}>
                      {t('search_join_totalWorkerIncome')}
                    </TableCell>
                    <TableCell rowSpan={4} sx={{ whiteSpace: 'nowrap' }}>
                      {t('search_join_totalWorker')}
                    </TableCell>
                    <TableCell rowSpan={4} sx={{ whiteSpace: 'nowrap' }}>
                      {t('search_join_totalWorkdays')}
                    </TableCell>
                    <TableCell rowSpan={4} sx={{ whiteSpace: 'nowrap' }}>
                      {t('search_average_income')}
                    </TableCell>
                  </TableRow>
                </TableHead>

                {dataSource.map((dataRow, index) => (
                  <TableRow
                    hover
                    key={index + Math.random}
                    sx={{
                      backgroundColor: index % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                    }}
                  >
                    {/* 行頭 */}
                    <TableCell>{dataRow.jobNatureDesc} </TableCell>
                    {/* 參與工人總收入 */}
                    <TableCell>{dataRow.salaryWithAutoPayformat} </TableCell>
                    {/* 參與總人數 */}
                    <TableCell>{dataRow.totalWorkersformat} </TableCell>
                    {/* 參與總工數 */}
                    <TableCell>{dataRow.totalWorkDayActformat} </TableCell>
                    {/* 平均每日工資 */}
                    <TableCell>{dataRow.averaGesalaryformat} </TableCell>
                  </TableRow>
                ))}
              </Table>
            </TableContainer>
          </div>

          {/* 參與工人總收入图表 */}
          <ResponsiveContainer height={300} width="100%">
            <LineChart data={dataSource} margin={{ left: 30, right: 20, top: 10 }}>
              {/* 背景虚线 */}
              <CartesianGrid strokeDasharray="3 3" />
              {/* x轴展示的数据，x轴底部的名字，就是给dataKey赋值 */}
              <XAxis dataKey="jobNatureDesc" padding={{ left: 30, right: 30 }} />
              {/* y轴展示的数据 y轴的值是根据datasource来自动变化的 */}
              <YAxis />
              {/* 鼠标放上去的提示 */}
              <Tooltip formatter={(value, name, props) => priceFormat(value, 2)} />
              <Legend />
              {/* 第一条线，线段是数据就是给dataKey赋值 */}
              <Line
                type="linear"
                dataKey="salaryWithAutoPay"
                stroke="#8884d8"
                name={t('search_join_totalWorkerIncome')}
                // label
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>

          {/* 參與總人數图表 */}
          <ResponsiveContainer height={300} width="100%">
            <LineChart data={dataSource} margin={{ right: 20, top: 10 }}>
              {/* 背景虚线 */}
              <CartesianGrid strokeDasharray="3 3" />
              {/* x轴展示的数据，x轴底部的名字，就是给dataKey赋值 */}
              <XAxis dataKey="jobNatureDesc" padding={{ left: 30, right: 30 }} />
              {/* y轴展示的数据 y轴的值是根据datasource来自动变化的 */}
              <YAxis />
              {/* 鼠标放上去的提示 */}
              <Tooltip formatter={(value, name, props) => priceFormat(value, 2)} />
              <Legend />
              {/* 第一条线，线段是数据就是给dataKey赋值 */}
              <Line
                type="linear"
                dataKey="totalWorkers"
                stroke="#8884d8"
                name={t('search_join_totalWorker')}
                // label
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>

          {/* 參與總工數图表 */}
          <ResponsiveContainer height={300} width="100%">
            <LineChart data={dataSource} margin={{ right: 20, top: 10 }}>
              {/* 背景虚线 */}
              <CartesianGrid strokeDasharray="3 3" />
              {/* x轴展示的数据，x轴底部的名字，就是给dataKey赋值 */}
              <XAxis dataKey="jobNatureDesc" padding={{ left: 30, right: 30 }} />
              {/* y轴展示的数据 y轴的值是根据datasource来自动变化的 */}
              <YAxis />
              {/* 鼠标放上去的提示 */}
              <Tooltip formatter={(value, name, props) => priceFormat(value, 2)} />
              <Legend />
              {/* 第一条线，线段是数据就是给dataKey赋值 */}
              <Line
                type="linear"
                dataKey="totalWorkDayAct"
                stroke="#8884d8"
                name={t('search_join_totalWorkdays')}
                // label
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>

          {/* 平均每日工資图表 */}
          <ResponsiveContainer height={300} width="100%">
            <LineChart data={dataSource} margin={{ right: 20, top: 10 }}>
              {/* 背景虚线 */}
              <CartesianGrid strokeDasharray="3 3" />
              {/* x轴展示的数据，x轴底部的名字，就是给dataKey赋值 */}
              <XAxis dataKey="jobNatureDesc" padding={{ left: 30, right: 30 }} />
              {/* y轴展示的数据 y轴的值是根据datasource来自动变化的 */}
              <YAxis />
              {/* 鼠标放上去的提示 */}
              <Tooltip formatter={(value, name, props) => priceFormat(value, 2)} />
              <Legend />
              {/* 第一条线，线段是数据就是给dataKey赋值 */}
              <Line
                type="linear"
                dataKey="averaGesalary"
                stroke="#8884d8"
                name={t('search_average_income')}
                // label
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Container>
      </div>
    </Container>
  )
}
