import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  DialogContent,
  Grid,
  TableCell,
  Divider,
  TextField,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LoadingButton from "@mui/lab/LoadingButton";
import AddIcon from "@mui/icons-material/Add";
import {
  CustomDialog,
  CDatePicker,
  CAutocomplete,
  CTable,
  CPagination,
  CDialog,
  CTransferList,
} from "src/components/common";

import CommonUtils from "src/utils/CommonUtils";

import $http from "src/utils/api/services";
import { t } from "i18next";

function ContactInformationForm(prop) {
  // 彈出框
  const [dialog, setDialog] = useState({
    content: "",
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false });
        }}
      >
        {t("Confirm")}
      </Button>
    ),
    open: false,
  });
  useEffect(() => {}, []);

  return (
    <>
      {/* 提示框 */}
      <CustomDialog
        sx={{ whiteSpace: "pre-line" }}
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        action={dialog.action}
      />
      <DialogContent dividers sx={{ pt: 3, pb: 3 }}>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Stack direction="row" spacing={2}>
            <Avatar src="/broken-image.jpg">Ip</Avatar>
          </Stack>
          <Grid item xs={12} />
        </Grid>
        <Grid item xs={12} />
        <Divider />

        <TableContainer dividers sx={{ pt: 3, pb: 3 }}>
          <Table sx={{ textAlign: "left" }} size="small">
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "20px",
                    width: 200,
                    borderBottom: "none",
                    py: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {t("Contact person")}:
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "20px",
                    textAlign: "left",
                    borderBottom: "none",
                  }}
                >
                  {"Loretta/葉小姐"}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "20px",
                    width: 200,
                    borderBottom: "none",
                    py: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {t("Phone")}:
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "20px",
                    textAlign: "left",
                    borderBottom: "none",
                  }}
                >
                  {"28285324"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "20px",
                    width: 100,
                    borderBottom: "none",
                    py: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {"Email"}:
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "20px",
                    textAlign: "left",
                    borderBottom: "none",
                  }}
                >
                  {"yhip@shkp.com"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Grid item xs={12} />
      </DialogContent>
    </>
  );
}
export default ContactInformationForm;
