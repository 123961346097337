import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Autocomplete,
  Container,
  Grid,
  FormControl,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  MenuItem,
  TableFooter,
} from '@mui/material'
import html2canvas from 'html2canvas'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import TablePagination from '@mui/material/TablePagination'
import moment from 'moment'
import JSPDF from 'jspdf'
import ReactEcharts from 'echarts-for-react'
import $http, { $download } from 'src/utils/api/services'
import * as api from '../../../utils/api/vendorAutopaySystem/RptVendorautopaystatusSearchApi'

export default function RptVendorautopaystatusSearch() {
  const [isSearchPage, setIsSearchPage] = useState(true)
  // 國際化
  const { t } = useTranslation()
  const contentRef = useRef(null)

  const [backOpen, setBackOpen] = useState(false)
  const [options, setOptions] = useState([])

  const [sendData, setSendData] = useState({})
  const [tableData, setTableData] = useState([])
  const [periodYearList, setPeriodYearList] = useState([])
  const [jobNatureIdList, setJobNatureIdList] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [dateNum, setdateNum] = useState([])
  const [beforeYearTime, setBeforeYearTime] = useState({})
  const [dateList, setDateList] = useState([])
  const [mainDataList, setMainDataList] = useState([])

  useEffect(() => {
    // setBackOpen(true)

    const getData = async () => {
      let getJobNatureIdList = await api.getfilljobNatureId()
      let getPeriodYearList = await api.getPeriodYear()

      if (getJobNatureIdList) {
        getJobNatureIdList.splice(0, 0, { id: '', text: 'ALL' })
        setJobNatureIdList(getJobNatureIdList)
      }

      if (getPeriodYearList) {
        setPeriodYearList(getPeriodYearList)
      }
    }
    getData()
  }, [])

  const handleBack = () => {
    setIsSearchPage(true)
  }

  const handlePeriodYear = (e, data) => {
    if (data !== null) {
      setSendData({
        ...sendData,
        dateFrom: data.id,
        periodYearDesc: data.text,
        periodYear: data,
      })
    } else {
      setSendData({
        ...sendData,
        dateFrom: null,
        periodYearDesc: null,
        periodYear: null,
      })
    }
  }

  const handleJobNatureId = (e, data) => {
    if (data !== null) {
      setSendData({
        ...sendData,
        jobNatureId: data.id,
        jobNatureIdDesc: data.text,
        jobNature: data,
      })
    } else {
      setSendData({
        ...sendData,
        jobNatureId: null,
        jobNatureIdDesc: null,
        jobNature: null,
      })
    }
  }

  const handleOrderBy = (e) => {
    setSendData({ ...sendData, orderBy: e.target.value })
  }

  const handleAscOrder = (e) => {
    setSendData({ ...sendData, ascOrder: e.target.value })
  }
  const handleChangeRowsPerPage = (e, data) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }
  const handleChangePage = (e, newPage) => {
    console.log('123', newPage)
    setPage(newPage)
  }
  const getBeforeYearTime = (data) => {
    let newdate = new Date(data)
    newdate = newdate.setFullYear(newdate.getFullYear() - 1)
    let aa = new Date(newdate)
    return aa.getFullYear() + '/' + (aa.getMonth() + 2) // 时间戳转日期格式
  }

  const handleSubmit = (e) => {
    if (!sendData.periodYear) {
      alert('請選擇日期')
      return
    }
    // 計算 日期范圍
    setBeforeYearTime(getBeforeYearTime(sendData.periodYear.id))

    console.log(sendData.periodYear.text.substring(5, 7))
    console.log('periodyear', sendData.periodYear.id)
    const getData = async () => {
      setBackOpen(true)

      let send = await api.getRPTvendorautopaystatuslist(sendData)

      let charList = []

      if (send) {
        setIsSearchPage(false)
        setTableData(send)

        let dateFormlist = send[0].dateList
        console.log('tesdare', dateFormlist)
        setDateList(dateFormlist)

        // send.map((row, index) => {
        //   row.aveList

        //   return ''
        // })

        setMainDataList()

        // send.map((row, i) => {
        //   row.dateList.map((dateRow, p) => {
        //     console.log('i', p)
        //     console.log('dateList', dateRow)
        //     return null
        //   })
        //   return null
        // })

        let isFlag = false

        let nameArr = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
        let nameArrUder = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

        // let indexNum = "";
        // // eslint-disable-next-line default-case
        // switch (sendData.periodYear.text.substring(5, 7)) {
        //   case "01":
        //     indexNum = 1;
        //     break;
        //   case "02":
        //     indexNum = 2;
        //     break;
        //   case "03":
        //     indexNum = 3;
        //     break;
        //   case "04":
        //     indexNum = 4;
        //     break;
        //   case "05":
        //     indexNum = "may";
        //     break;
        //   case "06":
        //     indexNum = "jun";
        //     break;
        //   case "07":
        //     indexNum = "jul";
        //     break;
        //   case "08":
        //     indexNum = "aug";
        //     break;
        //   case "09":
        //     indexNum = "sep";
        //     break;
        //   case "10":
        //     indexNum = "oct";
        //     break;
        //   case "11":
        //     indexNum = "nov";
        //     break;
        //   case "12":
        //     indexNum = "dec";
        //     break;
        // }

        // while (isFlag) {
        //   dateNum.push()
        // }

        // setdateNum(nameArr);

        // console.log(nameArr[0]);

        send.map((row, i) => {
          let arr = []
          let obj = {}

          dateFormlist.map((time, ind) => {
            arr.push(row.aveList[ind])
            // console.log(row[time]);
            // console.log(time);
            return ''
          })

          obj = {
            title: {
              text: row.vendorDesc,
            },
            grid: {
              right: '25%',
              bottom: '33%',
              width: '50%',
            },
            xAxis: {
              type: 'category',
              data: dateFormlist,
            },
            yAxis: {
              type: 'value',
            },
            toolbox: {
              // 保存图片
              show: true,
              orient: 'vertical',
              itemSize: 12,
              feature: {
                saveAsImage: {
                  name: '实时调用下载',
                },
              },
            },
            series: [
              {
                data: arr,
                type: 'bar',
                smooth: false,
                barWidth: '60%',
              },
            ],
            tooltip: {
              trigger: 'axis',
            },
          }
          console.log('arr', arr)

          charList.push(obj)
          return ''
        })
        setOptions(charList)
      }

      setBackOpen(false)
    }
    getData()
  }

  const resetForm = (e) => {
    setSendData({})
  }

  const exportExcel = (data) => {
    $download(`/payrollReportController/getAvgPayReportListExcel`, 'application/vnd.ms-excel', data)
  }

  // 打印
  const handlePrint1 = async (htmlTitle, currentTime) => {
    let element = document.getElementById('contentRef')
    html2canvas(element, {
      logging: false,
    }).then(function (canvas) {
      let pdf = new JSPDF('l', 'mm', 'a4') // A4纸，纵向
      let ctx = canvas.getContext('2d')
      let a4w = 275
      let a4h = 300 // A4大小，210mm x 297mm，四边各保留20mm的边距
      let imgHeight = Math.floor((a4h * canvas.width) / a4w) // 按A4显示比例换算一页图像的像素高度
      let renderedHeight = 0

      while (renderedHeight < canvas.height) {
        let page = document.createElement('canvas')
        page.width = canvas.width
        page.height = Math.min(imgHeight, canvas.height - renderedHeight) // 可能内容不足一页 // 用getImageData剪裁指定区域，并画到前面创建的canvas对象中

        page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0)
        pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 10, a4w, Math.min(a4h, (a4w * page.height) / page.width)) // 添加图像到页面，保留10mm边距

        renderedHeight += imgHeight
        if (renderedHeight < canvas.height) {
          pdf.addPage()
        } // 如果后面还有内容，添加一个空页 // delete page;
      }
      pdf.save(htmlTitle + currentTime)
    })
  }

  const exportPDF = (data) => {
    $download(`/payrollReportController/getAvgPayReportListPdf`, 'application/pdf', data)
  }

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div id="contentRef">
        {isSearchPage && (
          <form>
            <Container maxWidth="md">
              <Grid>
                {/* 承判商行業自動轉帳出糧工資分佈一覽表(每半年/每年) */}
                <Grid item xs={12}>
                  <Typography variant="h4" style={{ textAlign: 'center' }}>
                    <b>{t('List_of_wage_distribution_by_automatic_transfer_Semi_annual_title')}</b>
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={6}
                  sx={{
                    margin: 'auto',
                    width: '400px',
                    marginBottom: '20px',
                    marginTop: '10px',
                  }}
                >
                  <Autocomplete
                    value={sendData.jobNature || null}
                    onChange={handleJobNatureId}
                    fullWidth
                    size="small"
                    id="combo-box-demo"
                    getOptionLabel={(option) => {
                      return option.text
                    }}
                    options={jobNatureIdList}
                    renderInput={(params) => <TextField fullWidth size="small" {...params} label={t('Outfit')} />}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  sx={{
                    margin: 'auto',
                    width: '400px',
                    marginBottom: '20px',
                    marginTop: '10px',
                  }}
                >
                  <Autocomplete
                    value={sendData.periodYear || null}
                    onChange={handlePeriodYear}
                    fullWidth
                    size="small"
                    id="combo-box-demo"
                    getOptionLabel={(option) => {
                      return option.text
                    }}
                    options={periodYearList}
                    renderInput={(params) => <TextField error={!sendData.periodYear} fullWidth size="small" {...params} label={t('date')} />}
                  />
                </Grid>

                <Grid item xs={6} />
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <FormControl>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      sx={{
                        backgroundColor: 'primaryBlue',
                        color: 'commom.white',
                      }}
                    >
                      {t('button_submit')}
                    </Button>
                  </FormControl>
                  <FormControl sx={{ marginLeft: '20px' }}>
                    <Button
                      variant="contained"
                      onClick={resetForm}
                      sx={{
                        backgroundColor: 'primaryGreen',
                        color: 'commom.white',
                        marginRight: '10px',
                      }}
                      label="Reset"
                    >
                      {t('button_reset')}
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </Container>
          </form>
        )}

        {!isSearchPage && (
          <Container maxWidth={false}>
            <div
              style={{
                display: 'block',
                textAlign: 'left',
                marginTop: '0px',
              }}
            >
              <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
                <ArrowBackIcon />
                {t('button_back')}
              </Button>
              {/* <Button sx={{ float: 'right', mr: '20px' }} onClick={() => handlePrint1(t('List_of_wage_distribution_by_automatic_transfer_Semi_annual_title'), new Date())}>
                <DownloadIcon />
                {t('列印')}
              </Button> */}
              <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportPDF(sendData)}>
                <DownloadIcon />
                {t('列印')}
              </Button>
            </div>

            {/* <div style={{ display: 'block', textAlign: 'right' }}>
              <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportExcel(sendData)}>
                <DownloadIcon />
                {t('button_export_excel')}
              </Button>
              <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportPDF(sendData)}>
                <DownloadIcon />
                {t('button_export_pdf')}
              </Button>
            </div> */}
            <div>
              <Typography variant="h4" style={{ textAlign: 'center', mt: '-30px' }}>
                <b>
                  {t('List_of_wage_distribution_by_automatic_transfer_Semi_annual_title')} ({sendData.periodYearDesc} -- {sendData.jobNatureIdDesc})
                </b>
              </Typography>
              <Typography variant="h4" style={{ textAlign: 'center', mt: '-30px' }}>
                ({beforeYearTime}--{sendData.periodYearDesc}){' '}
              </Typography>
              <Typography sx={{ textAlign: 'right' }}>
                {'列印日期'}:{moment(new Date().getTime()).format('yyyy/MM/DD')}
              </Typography>
              <TableContainer sx={{ maxHeight: '80vh', maxWidth: '100vw' }}>
                <Table size="small" sx={{ mt: '20px' }} stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        rowSpan={2}
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {'承判商名稱'}
                      </TableCell>
                      <TableCell
                        colSpan={8}
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('自動轉賬出糧主要工資分佈')}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      {dateList.map((item, index) => (
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '15px',
                            wordBreak: 'keep-all',
                            whiteSpace: 'nowrap',
                            textAlign: 'right',
                          }}
                        >
                          {item}
                        </TableCell>
                      ))}
                    </TableRow>

                    {/* <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('Jan')}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('Feb')}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('Mar')}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('Apr')}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('May')}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('Jun')}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('Jul')}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('Aug')}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('Sep')}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('Oct')}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('Nov')}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('Dec')}
                      </TableCell>
                    </TableRow> */}
                  </TableHead>

                  {!!tableData && tableData.length > 0 && (
                    <TableBody>
                      {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, ind) => (
                        <>
                          <TableRow
                            hover
                            key={ind}
                            sx={{
                              backgroundColor: ind % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                            }}
                          >
                            <TableCell>{row.vendorDesc}</TableCell>
                            {row.aveList.map((row1, ind1) => (
                              <TableCell sx={{ textAlign: 'right' }}>{row1.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            ))}
                            {/* <TableCell sx={{ textAlign: 'right' }}>{row.jan.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{row.feb.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{row.mar.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{row.apr.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{row.may.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{row.jun.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{row.jul.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{row.aug.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{row.sep.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{row.oct.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{row.nov.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{row.dec.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell> */}
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  )}

                  {(!tableData || tableData.length === 0) && (
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ textAlign: 'center' }} colSpan={10}>
                          {'No data found !'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>

            <Grid item xs={2}>
              <Typography variant="h1" style={{ textAlign: 'right' }}>
                <b>
                  {options.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <ReactEcharts key={index} option={row} />
                  ))}
                </b>
              </Typography>
            </Grid>
            {/* 
          <div style={{ margin: "5px" }}>
            <Grid item xs={2}>
              <Typography variant="h1" style={{ textAlign: 'right' }}>
                <b>{options.map((row, index) =>
                  <ReactEcharts key={index}
                    option={row}
                  />)}</b></Typography>
            </Grid>
          </div> */}

            {/* <TablePagination
            rowsPerPageOptions={[5, 25, 100]}
            component="div"
            count={tableData.records.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          </Container>
        )}
      </div>
    </>
  )
}
