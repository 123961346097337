import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Divider,
  Container,
  Grid,
  FormControl,
  Button,
  FormLabel,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  tableCellClasses,
  MenuItem,
  Tab,
} from '@mui/material'
import { parseInt } from 'lodash'
import { styled } from '@mui/material/styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import moment from 'moment'
import TableAllData from 'src/components/icon/icon2/vendorAutoSystem/contractorAutopayStatus/TableAllData'
import TableDetail from 'src/components/icon/icon2/vendorAutoSystem/contractorAutopayStatus/TableDetail'
import * as api from '../../../utils/api/vendorAutopaySystem/ContractorAutopayStatusApi'

const ReportTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: '15px',
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'right',
    padding: '3px',
    fontSize: '13px',
  },
}))

// 承判商自動轉賬狀況

export default function ContractorAutopayStatus() {
  const [isSearchPage, setIsSearchPage] = useState(1)
  const { t } = useTranslation()
  const [backOpen, setBackOpen] = useState(false)
  const [siteList, setSiteList] = useState([])
  const [subList, setSubList] = useState([])
  const [jobTypelist, setJobTypelist] = useState([])
  const [sendData, setSendData] = useState({})
  const [tableData, setTableData] = useState([])
  const [tableReport, setTableReport] = useState({})
  const [reportData, setReportData] = useState({})
  const [goData1, setGoData1] = useState({})

  useEffect(() => {
    setBackOpen(true)
    const getData = async () => {
      let getSite = await api.getSiteAndTeam()
      let getSubContractor = await api.getSubContractorList()
      let getJobType = await api.getfilljobNatureId()
      if (getSite) {
        setSiteList(getSite)
      }
      if (getSubContractor) {
        setSubList(getSubContractor)
      }
      if (getJobType) {
        setJobTypelist(getJobType)
      }

      setBackOpen(false)
    }
    setSendData({
      ...sendData,
      dateFrom: moment(getPreMonth(moment(new Date()).format('yyyy-MM-DD'))).format('yyyy/MM/DD'),
      dateTo: moment(new Date()).format('yyyy/MM/DD'),
    })
    getData()
  }, [])
  const getPreMonth = (date) => {
    console.log('date', date)
    let arr = date.split('-')
    let year = arr[0] // 获取当前日期的年份
    let month = arr[1] // 获取当前日期的月份
    let day = arr[2] // 获取当前日期的日
    let days = new Date(year, month, 0)
    days = days.getDate() // 获取当前日期中月的天数
    let year2 = year
    let month2 = parseInt(month) - 1
    if (month2 === 0) {
      year2 = parseInt(year2) - 1
      month2 = 12
    }
    let day2 = day
    let days2 = new Date(year2, month2, 0)
    days2 = days2.getDate()
    if (day2 > days2) {
      day2 = days2
    }
    if (month2 < 10) {
      month2 = '0' + month2
    }
    let t2 = year2 + '-' + month2 + '-' + day2
    return t2
  }
  const handleBack = () => {
    setIsSearchPage(1)
  }
  const handleBackDetail = () => {
    console.log('senddata', sendData)
    setIsSearchPage(2)
  }

  const handleDateFrom = (e) => {
    setSendData({
      ...sendData,
      dateFrom: moment(new Date(e.target.value).getTime()).format('yyyy/MM/DD'),
      dateFrom1: moment(new Date(e.target.value).getTime()).format('yyyy/MM/DD'),

      statusDesc: '所有狀況',
    })
    console.log(e.target.value)
  }

  const handleDateTo = (e) => {
    setSendData({
      ...sendData,
      dateTo: moment(new Date(e.target.value).getTime()).format('yyyy/MM/DD'),
      dateTo1: moment(new Date(e.target.value).getTime()).format('yyyy/MM/DD'),
    })
  }

  const handleSubcontractors = (data) => {
    setSendData({
      ...sendData,
      vendorId: data.id,
      subcontractorDesc: data.text,
    })
  }
  const handleStatus = (data) => {
    setSendData({ ...sendData, status: data.id, statusDesc: data.text })
  }

  const handleSubmit = (e) => {
    if (!sendData.dateFrom || !sendData.dateTo) {
      alert('請選擇日期由/至')
      return
    }

    console.log(sendData)
    const send = async () => {
      setBackOpen(true)
      let getData = await api.getData(sendData)

      if (getData) {
        setTableData([...getData])
        setIsSearchPage(2)
      }
      setBackOpen(false)
    }
    send()
  }

  const handleSubmit3 = (e) => {
    setIsSearchPage(3)
  }

  const resetForm = (e) => {
    setSendData({})
  }

  const exportExcel = () => {}

  // 承判商自動轉賬狀況
  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {isSearchPage === 1 && (
        <form>
          <Container maxWidth="md" sx={{ textAlign: 'center' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                  <b>{t('Contractor_Autopay_Status')}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ maxWidth: '400px' }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ max: '2099-12-31' }}
                  value={moment(new Date(sendData.dateFrom).getTime()).format('yyyy-MM-DD') || ''}
                  fullWidth
                  size="small"
                  error={!sendData.dateFrom}
                  label={t('Autopay_Date') + '(' + t('from') + ')'}
                  type="date"
                  onChange={handleDateFrom}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  sx={{ maxWidth: '400px' }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: '2099-12-31',
                    min: moment(new Date(sendData.dateFrom).getTime()).format('yyyy-MM-DD') || '',
                  }}
                  value={moment(new Date(sendData.dateTo).getTime()).format('yyyy-MM-DD') || ''}
                  fullWidth
                  size="small"
                  error={!sendData.dateTo}
                  label={t('date_to')}
                  type="date"
                  onChange={handleDateTo}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField sx={{ textAlign: 'left', maxWidth: '400px' }} value={sendData.vendorId || ''} fullWidth size="small" label={t('Contractor')} select>
                  <MenuItem value=" " onClick={() => handleSubcontractors({ id: '', text: 'All' })}>
                    {' '}
                    所有承判商
                  </MenuItem>
                  {subList.map((item, index) => (
                    <MenuItem key={index} value={item.id} onClick={() => handleSubcontractors(item)}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField sx={{ textAlign: 'left', maxWidth: '400px' }} value={sendData.status || ''} fullWidth size="small" label={t('Status')} select>
                  <MenuItem value="" onClick={() => handleStatus({ id: '', text: '所有狀況' })}>
                    {' '}
                    所有狀況
                  </MenuItem>
                  <MenuItem value="I" onClick={() => handleStatus({ id: 'I', text: '未完成' })}>
                    {' '}
                    未完成
                  </MenuItem>
                  <MenuItem value="C" onClick={() => handleStatus({ id: 'C', text: '完成' })}>
                    {' '}
                    完成
                  </MenuItem>
                  <MenuItem value="invalid" onClick={() => handleStatus({ id: 'invalid', text: '無效' })}>
                    {' '}
                    無效
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    backgroundColor: 'primaryBlue',
                    color: 'commom.white',
                  }}
                >
                  {t('button_submit')}
                </Button>
                {/* <Button
                                    variant="contained"
                                    onClick={handleSubmit3}
                                    sx={{
                                        backgroundColor: "primaryBlue",
                                        color: "commom.white",
                                    }}
                                >
                                    {t("細節")}
                                </Button> */}
                <Button
                  variant="contained"
                  onClick={resetForm}
                  sx={{
                    marginLeft: '20px',
                    backgroundColor: 'primaryGreen',
                    color: 'commom.white',
                    marginRight: '10px',
                  }}
                >
                  {t('button_reset')}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </form>
      )}
      {isSearchPage === 2 && (
        <TableAllData
          reportData={reportData}
          setReportData={setReportData}
          setTableReport={setTableReport}
          setIsSearchPage={setIsSearchPage}
          setTableData={setTableData}
          handleBack={handleBack}
          sendData={sendData}
          tableData={tableData}
          setGoData1={setGoData1}
          goData1={goData1}
        />
      )}
      {isSearchPage === 3 && <TableDetail reportData={reportData} tableReport={tableReport} handleBackDetail={handleBackDetail} handleGoData={goData1} />}
    </>
  )
}
