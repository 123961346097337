import {
  Box,
  Button,
  DialogActions,
  Grid,
  Divider,
  MenuItem,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  Typography,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function ErrorUpdateTable(props) {
  const { errorData, errorOpen, setErrorOpen } = props;
  const { t } = useTranslation();

  useEffect(() => {
    console.log("1", errorData);
  }, [errorOpen]);

  //   function formatError(data) {
  //     console.log("data", data);
  //     let msg = "";
  //     for (let i = 0; i < data.length; i++) {
  //       msg = msg + data.errorMsg[i] + "  ";
  //     }
  //     return msg;
  //   }

  return (
    <Dialog open={errorOpen} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography variant="h6">
          <b>{t("User_Error_data")}</b>
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("錯誤信息")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {errorData?.map((item, index) => (
                <TableRow hover key={index}>
                  <TableCell>{item}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setErrorOpen(false)}>{t("button_back")}</Button>
      </DialogActions>
    </Dialog>
  );
}
