import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Collapse,
  Container,
  FormControl,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import * as api from "../../../utils/api/systemAdministrator/MPFRangeSetupApi";
import CustomDialog from "../../../components/common/CustomDialog";
import CDatePicker from "../../../components/common/CDatePicker";
import CNumberTextField from "../../../components/common/CNumberTextField";

// 添加數據接口
const handleAddData = async (body) => {
  try {
    // 调用接口
    const data = async () => {
      let data = await api.insertData(body);
      return data.message;
    };
    return data();
  } catch (error) {
    return null;
  }
};
// 修改數據接口
const handleUpdateData = async (body) => {
  try {
    // 调用接口
    const data = async () => {
      let data = await api.updateData(body);
      return data.message;
    };
    return data();
  } catch (error) {
    return null;
  }
};
// 刪除數據接口
const handleDeleteData = async (body) => {
  try {
    // 调用接口
    const data = async () => {
      let data = await api.deleteData(body);
      return data.message;
    };
    return data();
  } catch (error) {
    return null;
  }
};
// 查詢报表接口
const handleRefreshData = async (body) => {
  try {
    // 调用接口
    const data = async () => {
      let data = await api.selectLists(body);
      return data.data;
    };
    return data();
  } catch (error) {
    return null;
  }
};

function MPFRangeSetup() {
  // 國際化
  const { t } = useTranslation();
  // 搜索框开关
  const [searchOpen, setSearchOpen] = React.useState(true);
  // 搜索表单
  const [searchForm, setSearchForm] = React.useState({
    effectiveDateFrom: "",
    effectiveDateTo: "",
  });
  // 搜索按钮loading
  const [openLoading, setOpenLoading] = React.useState(false);
  // 表格数据
  const [rows, setRows] = React.useState([]);
  // 表格内容开关
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  // 表頭
  const columns = [
    {
      id: "effectiveDate",
      label: t("Effective_Date"),
      minWidth: 200,
      align: "left",
    },
    {
      id: "lowerbound",
      label: t("Lower_Bound"),
      minWidth: 150,
      align: "right",
    },
    {
      id: "upperbound",
      label: t("Upper_Bound"),
      minWidth: 150,
      align: "right",
    },
    {
      id: "employerAmount",
      label: t("Employer_Amount"),
      minWidth: 100,
      align: "right",
    },
    {
      id: "employeeAmount",
      label: t("Employee_Amount"),
      minWidth: 100,
      align: "right",
    },
    { id: "action", label: t("Action"), minWidth: 50, align: "center" },
  ];
  // 頁數
  const [page, setPage] = React.useState(0);
  // 每頁數量
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // 行数据详情
  const [tableRowDetail, setTableRowDetail] = React.useState({
    effectiveDate: "",
    lowerBound: "",
    upperBound: "",
    newLowerBound: null,
    newUpperBound: null,
    orgLowerBound: null,
    orgUpperBound: null,
    employerAmount: "",
    employeeAmount: "",
  });
  // 行数据禁用详情
  const [tableRowDetailDisable, setTableRowDetailDisable] = React.useState({
    effectiveDate: false,
  });
  // 彈出框
  const [dialog, setDialog] = useState({
    content: "",
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false });
        }}
      >
        {t("Confirm")}
      </Button>
    ),
    open: false,
  });

  // 添加表單内容开关
  const [addOpen, setAddOpen] = React.useState(false);
  // 弹出框标题
  const [dialogTitle, setDialogTitle] = React.useState("");

  // 頁面初始化
  useEffect(() => {}, []);

  // 表單值改變
  const handleFormChange = (event) => {
    setSearchForm({
      ...searchForm,
      [event.target.name]: event.target.value,
    });
  };
  const handleTableRowDetailChange = (event) => {
    setTableRowDetail({
      ...tableRowDetail,
      [event.target.name]: event.target.value,
    });
  };

  // 表单提交
  const handleSearchClick = async () => {
    // 打开按钮loading, 防止重复点击
    setOpenLoading(true);
    handleRefreshData(searchForm).then((value) => {
      // 关闭按钮loading
      setOpenLoading(false);
      // 改变key值, 使列表刷新
      // setRefreshRows((oldRefreshRows) => oldRefreshRows + 1);
      // 报表内容显示
      setDetailsOpen(!detailsOpen);
      // 搜索内容关闭
      setSearchOpen(!searchOpen);
      // 设置报表数据
      setRows(value);
    });
  };
  // 重置查询表单
  const handleResetClick = () => {
    setSearchForm({
      effectiveDateFrom: "",
      effectiveDateTo: "",
    });
  };
  // 重置添加表单
  const handleResetAddClick = () => {
    setTableRowDetail({
      effectiveDate: "",
      lowerBound: "",
      upperBound: "",
      newLowerBound: null,
      newUpperBound: null,
      orgLowerBound: null,
      orgUpperBound: null,
      employerAmount: "",
      employeeAmount: "",
    });
  };
  // 重置修改表单
  const handleResetEditClick = () => {
    setTableRowDetail({
      ...tableRowDetail,
      lowerBound: "",
      upperBound: "",
      newLowerBound: null,
      newUpperBound: null,
      orgLowerBound: null,
      orgUpperBound: null,
      employerAmount: "",
      employeeAmount: "",
    });
  };

  // 添加一行新數據
  const handleAddClick = () => {
    console.log(tableRowDetail);

    if (
      typeof tableRowDetail.effectiveDate === "undefined" ||
      tableRowDetail.effectiveDate.length === 0 ||
      tableRowDetail.effectiveDate === null
    ) {
      alert(t("Please_select_an_impact_date"));
      return;
    }
    if (
      typeof tableRowDetail.lowerBound === "undefined" ||
      tableRowDetail.lowerBound.length === 0 ||
      tableRowDetail.lowerBound === null
    ) {
      alert(t("Please_enter_the_lower_limit"));
      return;
    }
    if (
      typeof tableRowDetail.upperBound === "undefined" ||
      tableRowDetail.upperBound.length === 0 ||
      tableRowDetail.upperBound === null
    ) {
      alert(t("Please_enter_the_upper_limit"));
      return;
    }
    if (
      typeof tableRowDetail.employerAmount === "undefined" ||
      tableRowDetail.employerAmount.length === 0 ||
      tableRowDetail.employerAmount === null
    ) {
      alert(t("Please_enter_employer_contributions"));
      return;
    }
    if (
      typeof tableRowDetail.employeeAmount === "undefined" ||
      tableRowDetail.employeeAmount.length === 0 ||
      tableRowDetail.employeeAmount === null
    ) {
      alert(t("Please_enter_employee_contributions"));
      return;
    }

    // 打开按钮loading, 防止重复点击
    setOpenLoading(true);
    handleAddData(tableRowDetail).then((value) => {
      // 关闭按钮loading
      setOpenLoading(false);
      // 提示是否成功
      setDialog({
        ...dialog,
        // content: value ? t('AddSuccess') : t('AddFail'),
        content: value,
        action: (
          <Button
            variant="contained"
            onClick={() => {
              setDialog({ ...dialog, open: false });
              handleCloseAddClick();
            }}
          >
            {t("Confirm")}
          </Button>
        ),
        open: true,
      });
      // 更新table
      handleRefreshData(searchForm).then((value) => {
        // 设置报表数据
        setRows(value);
      });
    });
  };
  // 编辑一行新數據
  const handleEditClick = () => {
    if (
      typeof tableRowDetail.effectiveDate === "undefined" ||
      typeof tableRowDetail.lowerBound === "undefined" ||
      typeof tableRowDetail.upperBound === "undefined" ||
      typeof tableRowDetail.employerAmount === "undefined" ||
      typeof tableRowDetail.employeeAmount === "undefined"
    ) {
      return;
    }
    if (
      tableRowDetail.effectiveDate.length === 0 ||
      tableRowDetail.lowerBound.length === 0 ||
      tableRowDetail.upperBound.length === 0 ||
      tableRowDetail.employerAmount.length === 0 ||
      tableRowDetail.employeeAmount.length === 0
    ) {
      return;
    }
    if (
      tableRowDetail.effectiveDate === null ||
      tableRowDetail.lowerBound === null ||
      tableRowDetail.upperBound === null ||
      tableRowDetail.employerAmount === null ||
      tableRowDetail.employeeAmount === null
    ) {
      return;
    }
    // 打开按钮loading, 防止重复点击
    setOpenLoading(true);
    // 设置body
    const body = {
      ...tableRowDetail,
      newLowerBound: tableRowDetail.lowerBound,
      newUpperBound: tableRowDetail.upperBound,
    };
    handleUpdateData(body).then((value) => {
      // 关闭按钮loading
      setOpenLoading(false);
      // 提示是否成功
      setDialog({
        ...dialog,
        content: value,
        action: (
          <Button
            variant="contained"
            onClick={() => {
              setDialog({ ...dialog, open: false });
              handleCloseAddClick();
            }}
          >
            {t("Confirm")}
          </Button>
        ),
        open: true,
      });
      // 更新table
      handleRefreshData(searchForm).then((value) => {
        // 设置报表数据
        setRows(value);
      });
    });
  };
  // 确定执行删除事件
  const handleDeleteClick = (row) => {
    if (typeof row.effectiveDate === "undefined") {
      return;
    }
    if (row.effectiveDate.length === 0) {
      return;
    }
    if (row.effectiveDate === null) {
      return;
    }
    // 打开按钮loading, 防止重复点击
    setOpenLoading(true);
    // 设置body
    const body = {
      effectiveDate: row.effectiveDate,
      lowerBound: row.lowerbound,
      upperBound: row.upperbound,
    };
    handleDeleteData(body).then((value) => {
      // 关闭按钮loading
      setOpenLoading(false);
      // 提示是否成功
      setDialog({
        ...dialog,
        content: value,
        action: (
          <Button
            variant="contained"
            onClick={() => {
              setDialog({ ...dialog, open: false });
            }}
          >
            {t("Confirm")}
          </Button>
        ),
        open: true,
      });
      // 更新table
      handleRefreshData(searchForm).then((value) => {
        // 设置报表数据
        setRows(value);
      });
    });
  };
  // 表单为添加状态
  const addFieldsStatus = () => {
    // 关闭禁用
    setTableRowDetailDisable({
      ...tableRowDetailDisable,
      effectiveDate: false,
    });
  };
  // 表单为编辑状态
  const updateFieldsStatus = () => {
    // 开启禁用
    setTableRowDetailDisable({
      ...tableRowDetailDisable,
      effectiveDate: true,
    });
  };
  // 編輯按鈕點擊事件
  const handleOpenEditClick = (row) => {
    // 回显表单内容
    setTableRowDetail({
      effectiveDate: row.effectiveDate,
      lowerBound: row.lowerbound,
      upperBound: row.upperbound,
      newLowerBound: "",
      newUpperBound: "",
      orgLowerBound: row.lowerbound,
      orgUpperBound: row.upperbound,
      employerAmount: row.employerAmount,
      employeeAmount: row.employeeAmount,
    });
    // 设置表单状态为修改
    updateFieldsStatus();
    // 设置弹出框标题为-编辑
    setDialogTitle(t("Edit"));
    // 打开修改表单
    setAddOpen(true);
  };
  // 刪除按鈕點擊事件
  const handleOpenDeleteClick = (row) => {
    // 删除时要打开提示框提示是否删除
    setDialog({
      ...dialog,
      content: t("sure_to_delete"),
      action: (
        <>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <FormControl>
              <LoadingButton
                loading={openLoading}
                variant="contained"
                sx={{
                  backgroundColor: "#ff0000",
                  color: "commom.white",
                }}
                onClick={() => handleDeleteClick(row)}
              >
                {t("Delete")}
              </LoadingButton>
            </FormControl>
            <FormControl sx={{ marginLeft: "10px" }}>
              <Button
                variant="contained"
                onClick={() => {
                  setDialog({ ...dialog, open: false });
                }}
                sx={{
                  backgroundColor: "#9b9b9b",
                  color: "commom.white",
                  marginRight: "10px",
                }}
                label={t("Close")}
              >
                {t("Close")}
              </Button>
            </FormControl>
          </Grid>
        </>
      ),
      open: true,
    });
  };
  // 关闭表单弹出框
  const handleCloseAddClick = () => {
    setAddOpen(false);
  };
  // 打開添加窗口
  const handleOpenAddClick = () => {
    // 重置表单内容
    handleResetAddClick();
    // 设置弹出框标题为-添加
    setDialogTitle(t("Add"));
    // 设置表单状态为添加
    addFieldsStatus();
    // 打开添加表单
    setAddOpen(true);
  };

  // 顯示控制, 點擊過濾icon后更新表格數據
  const handleFilterClick = () => {
    if (searchOpen) {
      setOpenLoading(true);
      handleRefreshData(searchForm).then((value) => {
        // 关闭按钮loading
        setOpenLoading(false);
        // 改变key值, 使列表刷新
        // setRefreshRows((oldRefreshRows) => oldRefreshRows + 1);
        // 报表内容显示
        setDetailsOpen(!detailsOpen);
        // 搜索内容关闭
        setSearchOpen(!searchOpen);
        // 设置报表数据
        setRows(value);
      });
    } else {
      // 报表内容显示
      setDetailsOpen(!detailsOpen);
      // 搜索内容关闭
      setSearchOpen(!searchOpen);
    }
  };

  // 切換頁數
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // 切換每頁數量
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      {/* 提示框 */}
      <CustomDialog
        sx={{ whiteSpace: "pre-line" }}
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        action={dialog.action}
      />
      {/* 標題 */}
      <Container sx={{ textAlign: "center" }} maxWidth="sm">
        <Typography variant="h4">
          <b>{t("icon3_new_mpf_range")}</b>
        </Typography>
      </Container>

      <Container maxWidth="md">
        {/* 导航条 */}
        <Toolbar
          align="center"
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Tooltip title="New">
            <IconButton onClick={(event) => handleOpenAddClick(event)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Filter list">
            <IconButton onClick={(event) => handleFilterClick(event)}>
              <FilterAltOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>

        {/* 過濾條件 */}
        <Collapse
          sx={{ flexGrow: 1, textAlign: "center" }}
          in={searchOpen}
          timeout="auto"
          unmountOnExit
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {/* 搜索表单 */}
            <Grid item xs={6}>
              <CDatePicker
                name="effectiveDateFrom"
                label={t("Effective_Date_From")}
                value={searchForm.effectiveDateFrom || null}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    effectiveDateFrom: data
                      ? moment(new Date(data).getTime()).format("YYYY/MM/DD")
                      : null,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CDatePicker
                name="effectiveDateTo"
                label={t("Effective_Date_To")}
                value={searchForm.effectiveDateTo || null}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    effectiveDateTo: data
                      ? moment(new Date(data).getTime()).format("YYYY/MM/DD")
                      : null,
                  });
                }}
              />
            </Grid>
            {/* 间隔 */}
            <Grid item xs={6} />
            {/* 按钮 */}
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <FormControl>
                <LoadingButton
                  loading={openLoading}
                  variant="contained"
                  sx={{
                    backgroundColor: "primaryBlue",
                    color: "commom.white",
                  }}
                  onClick={handleSearchClick}
                >
                  {t("Search")}
                </LoadingButton>
              </FormControl>
              <FormControl sx={{ marginLeft: "20px" }}>
                <Button
                  variant="contained"
                  onClick={handleResetClick}
                  sx={{
                    backgroundColor: "primaryGreen",
                    color: "commom.white",
                    marginRight: "10px",
                  }}
                  label={t("Reset")}
                >
                  {t("Reset")}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Collapse>
      </Container>

      {/* 表格 */}
      <Collapse in={detailsOpen} timeout="auto" unmountOnExit>
        <Container maxWidth={false}>
          <TableContainer>
            <Table
              sx={{
                wordBreak: "keep-all",
                mt: "10px",
                "& .MuiTableCell-root": {
                  paddingRight: "10px",
                  paddingLeft: "10px",
                },
              }}
              size="small"
            >
              {/* 表头 */}
              <TableHead>
                <TableRow hover>
                  {columns.map((column) => (
                    <TableCell
                      sx={{ fontSize: "15px", textAlign: column.align }}
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {/* 行 */}
              <TableBody>
                {rows.length > 0 ? (
                  <>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.effectiveDate + "-" + index}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              if (column.id === "action") {
                                return (
                                  <TableCell
                                    sx={{
                                      fontSize: "15px",
                                      textAlign: column.align,
                                    }}
                                    key={column.id}
                                  >
                                    <Button
                                      variant="contained"
                                      onClick={() => handleOpenEditClick(row)}
                                      sx={{
                                        backgroundColor: "#257018",
                                        color: "commom.white",
                                        marginRight: "10px",
                                      }}
                                      label={t("Edit")}
                                    >
                                      {t("Edit")}
                                    </Button>
                                    <Button
                                      variant="contained"
                                      onClick={() => handleOpenDeleteClick(row)}
                                      sx={{
                                        backgroundColor: "#ff0000",
                                        color: "commom.white",
                                        marginRight: "10px",
                                      }}
                                      label={t("Delete")}
                                    >
                                      {t("Delete")}
                                    </Button>
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell
                                    sx={{
                                      fontSize: "15px",
                                      textAlign: column.align,
                                    }}
                                    key={column.id}
                                  >
                                    {column.id === "effectiveDate"
                                      ? value.slice(0, 10)
                                      : value}
                                  </TableCell>
                                );
                              }
                            })}
                          </TableRow>
                        );
                      })}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan="13" sx={{ textAlign: "center" }}>
                      No Records Found!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* 分頁 */}
          {rows.length > 0 ? (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <></>
          )}
        </Container>
      </Collapse>

      {/* 添加表單 */}
      <Dialog
        fullWidth
        maxWidth="xs"
        sx={{ whiteSpace: "pre-line" }}
        open={addOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <Container maxWidth="md">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              {/* 表单 */}
              <Grid item xs={12} />
              <Grid item xs={12}>
                <CDatePicker
                  name="effectiveDate"
                  label={t("Effective_Date")}
                  disabled={tableRowDetailDisable.effectiveDate}
                  required
                  value={tableRowDetail.effectiveDate || null}
                  onChange={(data) => {
                    setTableRowDetail({
                      ...tableRowDetail,
                      effectiveDate: data
                        ? moment(new Date(data).getTime()).format("YYYY/MM/DD")
                        : null,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CNumberTextField
                  name="lowerBound"
                  label={t("Lower_Bound")}
                  error={!tableRowDetail.lowerBound}
                  value={tableRowDetail.lowerBound || ""}
                  onChange={(event) => handleTableRowDetailChange(event)}
                />
              </Grid>
              <Grid item xs={12}>
                <CNumberTextField
                  name="upperBound"
                  label={t("Upper_Bound")}
                  error={!tableRowDetail.upperBound}
                  value={tableRowDetail.upperBound || ""}
                  onChange={(event) => handleTableRowDetailChange(event)}
                />
              </Grid>
              <Grid item xs={12}>
                <CNumberTextField
                  name="employerAmount"
                  label={t("Employer_Amount")}
                  error={!tableRowDetail.employerAmount}
                  value={tableRowDetail.employerAmount || ""}
                  onChange={(event) => handleTableRowDetailChange(event)}
                />
              </Grid>
              <Grid item xs={12}>
                <CNumberTextField
                  name="employeeAmount"
                  label={t("Employee_Amount")}
                  error={!tableRowDetail.employeeAmount}
                  value={tableRowDetail.employeeAmount || ""}
                  onChange={(event) => handleTableRowDetailChange(event)}
                />
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <FormControl>
              <LoadingButton
                loading={openLoading}
                variant="contained"
                sx={{
                  backgroundColor: "#2d8d27",
                  color: "commom.white",
                }}
                onClick={
                  dialogTitle === t("Add") ? handleAddClick : handleEditClick
                }
              >
                {dialogTitle}
              </LoadingButton>
            </FormControl>
            <FormControl sx={{ marginLeft: "10px" }}>
              <Button
                variant="contained"
                onClick={
                  dialogTitle === t("Add")
                    ? handleResetAddClick
                    : handleResetEditClick
                }
                sx={{
                  backgroundColor: "primaryGreen",
                  color: "commom.white",
                  marginRight: "10px",
                }}
                label={t("Reset")}
              >
                {t("Reset")}
              </Button>
            </FormControl>
            <FormControl>
              <Button
                variant="contained"
                onClick={handleCloseAddClick}
                sx={{
                  backgroundColor: "#9b9b9b",
                  color: "commom.white",
                  marginRight: "10px",
                }}
                label={t("Close")}
              >
                {t("Close")}
              </Button>
            </FormControl>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MPFRangeSetup;
