import { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormGroup,
  FormControlLabel,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Divider,
  Typography,
  TextField,
} from '@mui/material'
import moment from 'moment'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useTranslation } from 'react-i18next'

// 之后有空需要优化一下，满屏的moment格式化
export default function CalendarPart(props) {
  const { dateOpen, setDateOpen, setAllDateList, allDateList, datePartI, datePartIndex, datePartRow, setTableData, tableData, startDate, endDate, leftOrRightDesign, setDialog, dialog } = props
  // 第一种日期
  const [dateList, setDateList] = useState([])
  // 第二种日期
  const [dateListGreen, setDateListGreen] = useState([])
  const [selectAllDate, setSelectAllDate] = useState([])
  const [startMonth, setStartMonth] = useState('')
  const [endMonth, setEndMonth] = useState('')
  const [changeMonth, setChangeMonth] = useState(false)
  const [reload, setReload] = useState(false)
  const [checkSun, setCheckSun] = useState(false)
  const [isClick, setIsClick] = useState(false)

  const { t } = useTranslation()

  // const startDate = "2020/09/02";
  // const endDate = "2021/11/30";

  useEffect(() => {
    setIsClick(false)
    let initList = []
    let initRedList = []
    let initGreenList = []

    // 初始化選中 allDateList是用戶選中的list 將allDateList遍歷拿出每一個日期  { "startDate": "2020/09/09", "endDate": "2020/09/11"}
    for (let i = 0; i < allDateList.length; i++) {
      let item = allDateList[i]
      let nowDate = moment(item.startDate)
      const stopDate = moment(item.endDate)
      if (i % 2 === 0) {
        while (nowDate <= stopDate) {
          initList.push(moment(nowDate).format('yyyy/MM/DD'))
          initRedList.push(moment(nowDate).format('yyyy/MM/DD'))
          nowDate = moment(nowDate).add(1, 'days')
        }
      } else {
        while (nowDate <= stopDate) {
          initList.push(moment(nowDate).format('yyyy/MM/DD'))
          initGreenList.push(moment(nowDate).format('yyyy/MM/DD'))
          nowDate = moment(nowDate).add(1, 'days')
        }
      }
    }
    setChangeMonth(false)
    setStartMonth(startDate ? moment(startDate).format('yyyy/MM') : moment(new Date()).format('yyyy/MM'))
    setEndMonth(endDate ? moment(endDate).format('yyyy/MM') : moment(new Date()).format('yyyy/MM'))
    setSelectAllDate([...initList])
    setDateList([...initRedList])
    setDateListGreen([...initGreenList])
  }, [dateOpen, reload])

  const handleClose = () => {
    setDateOpen(!dateOpen)
  }
  const handleReset = () => {
    setReload(!reload)
  }

  // 计算指定日期之间的天数
  const getDaysBetweenDates = (start, end) => {
    // 如果是在同一個月内
    const dates = []
    let currentDate = {}
    let stopDate
    if (moment(startMonth).format('yyyy/MM') === moment(end).format('yyyy/MM')) {
      // 如果改過月份，startDate是新一個月的第一天
      if (!changeMonth) {
        // 沒改
        currentDate = moment(start)
      } else {
        // 改了
        currentDate = moment(moment(startMonth).format('yyyy/MM'))
      }
      stopDate = moment(end)
    } else {
      // 如果月份還是不等於最後的月份，endDate為這個月的最後一天
      // 還需要判斷是不是改過月份，改過第一天為月的第一天，沒改過第一天為startDate.
      stopDate = moment(startMonth).add(1, 'months')
      if (!changeMonth) {
        // 沒改過月份
        currentDate = moment(start)
      } else {
        // 改過月份
        currentDate = moment(startMonth)
      }
    }
    while (currentDate <= stopDate) {
      dates.push(moment(currentDate))
      if (dates.length === 1 && dates[0] !== null && dates[0].day() !== 0) {
        for (let i = dates[0].day(); i > 0; i--) {
          dates.unshift(null)
        }
      }
      currentDate = moment(currentDate).add(1, 'days')
    }
    return dates
  }

  const handleClick = (e, item) => {
    // 点击第一次变为红色，存入第一个日期list 第二次变为绿色，存入第二个日期list，第三次全部不存。
    let indexAll = selectAllDate.indexOf(item.format('yyyy/MM/DD'))
    let indexRed = dateList.indexOf(item.format('yyyy/MM/DD'))
    let indexGreen = dateListGreen.indexOf(item.format('yyyy/MM/DD'))
    if (indexRed >= 0) {
      // 存在红色list中，拿出来存到绿色中
      dateList.splice(indexRed, 1)
      dateListGreen.push(item.format('yyyy/MM/DD'))
    } else if (indexGreen >= 0) {
      // 存在绿色list中，直接拿掉
      dateListGreen.splice(indexGreen, 1)
    } else {
      // 都不存在，存到红色中
      dateList.push(item.format('yyyy/MM/DD'))
    }
    // 存到AllList中，用来做双击时候的判断
    if (indexAll >= 0) {
      selectAllDate.splice(indexAll, 1)
    } else {
      selectAllDate.push(item.format('yyyy/MM/DD'))
    }

    dateList.sort()
    dateListGreen.sort()
    setDateList([...dateList])
    setSelectAllDate([...selectAllDate])
    setDateListGreen([...dateListGreen])
  }
  // 双击单选日期（由/至）
  const handleDoubleClick = (e, item) => {
    // 判断点击左右日期选择框
    if (leftOrRightDesign) {
      //  tableData[datePartIndex].workerDetailsql[i].shortdatefrom =
      tableData[datePartIndex].workerDetailsql[datePartI].shortdatefrom = item.format('yyyy/MM/DD')
      // 左
    } else {
      // 右
      tableData[datePartIndex].workerDetailsql[datePartI].shortdateto = item.format('yyyy/MM/DD')
    }

    if (tableData[datePartIndex].workerDetailsql[datePartI].shortdateto < tableData[datePartIndex].workerDetailsql[datePartI].shortdatefrom) {
      tableData[datePartIndex].workerDetailsql[datePartI].shortdatefrom = null
      tableData[datePartIndex].workerDetailsql[datePartI].shortdateto = null
      setDialog({
        ...dialog,
        content: '日期由不能大於日期至！！！',
        open: true,
      })
    }
    // if (tableData[datePartIndex].workerDetailsql[datePartI].shortdatefrom < tableData[datePartIndex].workerDetailsql[datePartI].shortdateto) {
    //   tableData[datePartIndex].workerDetailsql[datePartI].shortdatefrom = null
    //   tableData[datePartIndex].workerDetailsql[datePartI].shortdateto = null
    //   setDialog({
    //     ...dialog,
    //     content: '日期至不能小於日期由！！！',
    //     open: true,
    //   })
    // }

    // 计算工作天数
    let workerday = new Date(tableData[datePartIndex].workerDetailsql[datePartI].shortdateto) - new Date(tableData[datePartIndex].workerDetailsql[datePartI].shortdatefrom)
    tableData[datePartIndex].workerDetailsql[datePartI].workDayAct = Math.ceil(workerday / 86400000) + 1
    tableData[datePartIndex].workerDetailsql[datePartI].relevantIncome =
      tableData[datePartIndex].workerDetailsql[datePartI].workDayAct * tableData[datePartIndex].workerDetailsql[datePartI].dailyRate || 0
    // newRow.
    // tableData[datePartIndex].workerDetailsql.splice(firstIndex + i, 0, newRow)

    setTableData([...tableData])
    setDateOpen(!dateOpen)
  }

  // 使用总天数计算行数和列数，并创建日期矩阵数组
  const days = getDaysBetweenDates(startDate, endDate)
  const rows = Math.ceil(days.length / 7)
  const cols = 7
  const totalCells = rows * cols
  const emptyCells = totalCells - days.length
  const matrixArray = [...days, ...Array(emptyCells).fill(null)]
  // 根据日期矩阵数组渲染数据到页面上
  const renderMatrix = () => {
    const result = []
    for (let i = 0; i < rows; i++) {
      const rowData = []
      for (let j = 0; j < cols; j++) {
        rowData.push(matrixArray[i * cols + j])
      }
      result.push(
        <div
          id="documentLabel"
          key={i}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {rowData.map((item, k) => (
            <Button
              disabled={item === null}
              key={k}
              style={{
                width: 30,
                height: 30,
                border: '1px solid #ccc',
                alignItems: 'center',
                margin: '5px 5px',
                color: item && dateList.indexOf(item.format('yyyy/MM/DD')) >= 0 ? '#ffffff' : item && dateListGreen.indexOf(item.format('yyyy/MM/DD')) >= 0 ? '#ffffff' : null,
                // backgroundColor: item && dateList.indexOf(item.format('yyyy/MM/DD')) >= 0 ? '#ffa07a' : null,
                backgroundColor: item && dateList.indexOf(item.format('yyyy/MM/DD')) >= 0 ? '#0c80d5' : item && dateListGreen.indexOf(item.format('yyyy/MM/DD')) >= 0 ? '#f4392c' : null,
              }}
              // 双击
              onDoubleClick={(e) => {
                // handleSave()
                handleDoubleClick(e, item)
              }}
              onMouseDown={(e) => {
                setIsClick(true)
                handleClick(e, item)
                //  console.log(dateList)
              }}
              onMouseOver={(e) => {
                if (isClick) {
                  handleClick(e, item)
                }
              }}
              onMouseUp={() => {
                setIsClick(false)
              }}
              onClick={(e) => handleClick(e, item)}
            >
              {item ? item.date() : ''}
            </Button>
          ))}
        </div>,
      )
    }
    return <>{result}</>
  }
  // 判断鼠标点击的位置是否在某个div区域内，可用在右键菜单的显示与隐藏等
  const isOutDiv = () => {
    setIsClick(false)
  }
  const isabort = () => {
    setIsClick(false)
  }

  const handleSelectAll = (e) => {
    const allDates = []
    if (e.target.checked) {
      let nowDate = moment(startDate)
      const stopDate = moment(endDate)
      while (nowDate <= stopDate) {
        allDates.push(moment(nowDate).format('yyyy/MM/DD'))
        nowDate = moment(nowDate).add(1, 'days')
      }
    }
    allDates.sort()
    setDateList([...allDates])
    setDateListGreen([])
    setCheckSun(false)
    const el = document.getElementById('documentLabel')
  }
  const handleSelectSun = (e) => {
    setCheckSun(!checkSun)
    let outOfSunRed = [...dateList]
    let outOfSunGreen = [...dateListGreen]
    if (dateList.length >= 0 && e.target.checked) {
      for (let item of dateList) {
        if (moment(item).day() === 0) {
          outOfSunRed.splice(outOfSunRed.indexOf(item), 1)
        }
      }
    }
    if (dateListGreen.length >= 0 && e.target.checked) {
      for (let item of dateListGreen) {
        if (moment(item).day() === 0) {
          outOfSunGreen.splice(outOfSunGreen.indexOf(item), 1)
        }
      }
    }
    setDateListGreen([...outOfSunGreen])
    setDateList([...outOfSunRed])
    // console.log("select", dateList);
  }
  function compare(obj1, obj2) {
    let val1 = obj1.selectStartDate
    let val2 = obj2.selectStartDate
    if (val1 < val2) {
      return -1
    } else if (val1 > val2) {
      return 1
    } else {
      return 0
    }
  }
  const handleSave = () => {
    // console.log('保存', shortDateFrom123)
    let list = []
    let cacheLsit = [...tableData[datePartIndex].workerDetailsql]
    if (dateList.length > 0) {
      let selectStartDate = dateList[0]
      let selectEndDate = dateList[0]

      for (let i = 0; i < dateList.length; i++) {
        let dateObj = {
          selectStartDate: selectStartDate,
          selectEndDate: selectEndDate,
        }
        let dateNext = moment(dateList[i]).add(1, 'days')
        if (i < dateList.length - 1 && moment(dateNext).format('yyyy/MM/DD') === moment(dateList[i + 1]).format('yyyy/MM/DD')) {
          selectEndDate = dateList[i + 1]
          //   selectEndDate = dateNext;
        } else {
          list.push(dateObj)
          selectStartDate = dateList[i + 1]
          selectEndDate = dateList[i + 1]
          if (i + 1 === dateList.length) {
            dateObj.selectEndDate = dateList[i]
          }
        }
      }
    }

    if (dateListGreen.length > 0) {
      let selectStartDate = dateListGreen[0]
      let selectEndDate = dateListGreen[0]

      for (let i = 0; i < dateListGreen.length; i++) {
        let dateObj = {
          selectStartDate: selectStartDate,
          selectEndDate: selectEndDate,
        }

        let dateNext = moment(dateListGreen[i]).add(1, 'days')

        // if (dateListGreen[i + 1] === null) {

        // }
        if (i < dateListGreen.length - 1 && moment(dateNext).format('yyyy/MM/DD') === moment(dateListGreen[i + 1]).format('yyyy/MM/DD')) {
          selectEndDate = dateListGreen[i + 1]
        } else {
          list.push(dateObj)
          selectStartDate = dateListGreen[i + 1]
          selectEndDate = dateListGreen[i + 1]
          if (i + 1 === dateListGreen.length) {
            dateObj.selectEndDate = dateListGreen[i]
          }
          //  list.push(dateObj);
        }
      }
    }
    list.sort(compare)

    // 到這裏拿到了 日期區間的list的 ，下面的是系統業務邏輯的東西 先將原有的全部清楚
    let item = {}
    let firstIndex = 0
    for (let index = cacheLsit.length - 1; index >= 0; index--) {
      if (cacheLsit[index].headerId === datePartRow.headerId && cacheLsit[index].cardHolderId === datePartRow.cardHolderId) {
        if (cacheLsit[index].newadd === 'N') {
          // 父item
          item = cacheLsit[index]
          firstIndex = index
          tableData[datePartIndex].workerDetailsql.splice(index, 1, {})
        } else {
          tableData[datePartIndex].workerDetailsql.splice(index, 1)
        }
      }
    }
    if (list.length <= 0) {
      item.shortdatefrom = null
      item.shortdateto = null
      item.workDayAct = 0
      tableData[datePartIndex].workerDetailsql[firstIndex] = { ...item }
      console.log('沒了')
      tableData[datePartIndex].workerDetailsql[firstIndex].relevantIncome = 0
    }

    for (let i = 0; i < list.length; i++) {
      if (i === 0) {
        let workerday = new Date(list[0].selectEndDate) - new Date(list[0].selectStartDate)
        item.workDayAct = Math.ceil(workerday / 86400000) + 1
        item.relevantIncome = item.workDayAct * item.dailyRate || 0
        item.typeAdd = 'edit'
        // // 修改位置 leftOrRightDesign == true 左边日期组件
        // console.log('123123132132', leftOrRightDesign)
        // tableData[datePartIndex].workerDetailsql[firstIndex] = { ...item }
        // if (leftOrRightDesign) {
        //   // 点击左边日期 true 设置日期由
        //   tableData[datePartIndex].workerDetailsql[firstIndex].shortdatefrom = list[0].selectStartDate
        // } else {
        //   // 设置日期至 false
        //   console.log('123456789')
        //   tableData[datePartIndex].workerDetailsql[firstIndex].shortdateto = list[0].selectEndDate
        // }
        tableData[datePartIndex].workerDetailsql[firstIndex] = { ...item }
        tableData[datePartIndex].workerDetailsql[firstIndex].shortdatefrom = list[0].selectStartDate
        tableData[datePartIndex].workerDetailsql[firstIndex].shortdateto = list[0].selectEndDate
      } else {
        let newRow = { ...item }
        newRow.newadd = 'Y'
        newRow.addByLocal = 'Y'
        newRow.workDay = 0
        newRow.typeAdd = 'new'
        newRow.dailyRate = item?.dailyRate
        // newRow.dailyRate = datePartRow?.dailyRate
        console.log('newDaily:', datePartRow.dailyRate)

        newRow.payrollRemark = ''

        newRow.shortdatefrom = list[i].selectStartDate
        newRow.shortdateto = list[i].selectEndDate

        // console.log('i', i)
        // if (leftOrRightDesign) {
        //   // 点击左边日期 true 设置日期由
        //   console.log('左')
        //   console.log('list[i]', list[i])
        //   newRow.shortdatefrom = list[i].selectStartDate
        // } else {
        //   // 设置日期至 false
        //   console.log('右')
        //   console.log('123456789')
        //   newRow.shortdateto = list[i].selectEndDate
        // }

        // 计算工作天数
        let workerday = new Date(list[i].selectEndDate) - new Date(list[i].selectStartDate)
        newRow.workDayAct = Math.ceil(workerday / 86400000) + 1
        newRow.relevantIncome = newRow.workDayAct * newRow.dailyRate || 0
        // newRow.
        tableData[datePartIndex].workerDetailsql.splice(firstIndex + i, 0, newRow)
      }
    }
    console.log('tableData:', tableData)

    setTableData([...tableData])
    setAllDateList([...list])
    setDateOpen(!dateOpen)
  }

  return (
    <Dialog open={dateOpen}>
      <DialogTitle>{t('Date_Selection_Box')}</DialogTitle>
      <Divider />
      <DialogContent onMouseLeave={isOutDiv}>
        <Typography sx={{ textAlign: 'center' }}>
          <IconButton
            size="small"
            disabled={moment(startMonth).format('yyyy/MM') === moment(startDate).format('yyyy/MM')}
            onClick={() => {
              let month = moment(startMonth)
              month = moment(startMonth).subtract(1, 'months')
              setStartMonth(moment(month).format('yyyy/MM'))
              if (moment(month).format('yyyy/MM') === moment(startDate).format('yyyy/MM')) {
                setChangeMonth(false)
              } else {
                setChangeMonth(true)
              }
            }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          {startMonth}
          <IconButton
            size="small"
            disabled={moment(startMonth).format('yyyy/MM') === moment(endMonth).format('yyyy/MM')}
            onClick={() => {
              let month = moment(startMonth)
              month = moment(startMonth).add(1, 'months')
              setStartMonth(moment(month).format('yyyy/MM'))
              setChangeMonth(true)
            }}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Typography>
        <Table onMouseEnter={isabort}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '14.28%', textAlign: 'center' }}>{t('Sun')}</TableCell>
              <TableCell sx={{ width: '14.28%', textAlign: 'center' }}>{t('Mon')}</TableCell>
              <TableCell sx={{ width: '14.28%', textAlign: 'center' }}>{t('Tue')}</TableCell>
              <TableCell sx={{ width: '14.28%', textAlign: 'center' }}>{t('Wed')}</TableCell>
              <TableCell sx={{ width: '14.28%', textAlign: 'center' }}>{t('Thur')}</TableCell>
              <TableCell sx={{ width: '14.28%', textAlign: 'center' }}>{t('Fri')}</TableCell>
              <TableCell sx={{ width: '14.28%', textAlign: 'center' }}>{t('Sat')}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {renderMatrix()}
        <FormGroup aria-label="position" row={false} onMouseEnter={isabort}>
          <FormControlLabel sx={{ mb: '-25px' }} labelPlacement="start" control={<Checkbox size="small" onClick={handleSelectAll} />} label={t('Select_All')} />
          <br />
          <FormControlLabel labelPlacement="start" control={<Checkbox checked={checkSun || false} size="small" onClick={handleSelectSun} />} label={t('Excluding_Sundays')} />
        </FormGroup>
      </DialogContent>
      <Divider />

      <DialogActions onMouseEnter={isabort}>
        <Button onClick={handleClose}>{t('button_cancel')}</Button>
        <Button onClick={handleSave}>{t('button_save')}</Button>
      </DialogActions>
    </Dialog>
  )
}
