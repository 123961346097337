import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Autocomplete,
  Container,
  Grid,
  FormControl,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  MenuItem,
  FormLabel,
  Link,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import moment from 'moment'
import * as api from '../../../utils/api/vendorAutopaySystem/WorkerSalaryAnalysisTradeApi'
import * as commonApi from '../../../utils/api/CommonApi'

export default function WorkerSalaryAnalysisTrade() {
  const [isSearchPage, setIsSearchPage] = useState(true)
  const { t } = useTranslation()
  const [backOpen, setBackOpen] = useState(false)
  const [jobNatureList, setJobNatureList] = useState([])
  const [dateMonthList, setDateMonthList] = useState([])
  const [sendData, setSendData] = useState({})
  const [reportData, setReportData] = useState({})
  const [tableData, setTableData] = useState([])
  const [isShowVendorColumn, setIsShowVendorColumn] = useState(false)
  const [reload, setReload] = useState(false)
  const [defaultReport, setDefaultReport] = useState('0')

  const [dialog, setDialog] = useState({
    content: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        確認
      </Button>
    ),
    open: false,
  })

  useEffect(() => {
    setBackOpen(true)
    const getData = async () => {
      // /*初始化值 */
      setSendData({ ...sendData, recordsCriteria: '0' })
      let getJobNatureList = await commonApi.getJobNatureList()
      let getDateMonthList = await commonApi.getDateMonthList()
      if (getJobNatureList) {
        setJobNatureList(getJobNatureList)
      }
      if (getDateMonthList) {
        setDateMonthList(getDateMonthList)
      }
      setBackOpen(false)
    }
    getData()
  }, [reload])

  const handleBack = () => {
    setIsSearchPage(true)
  }

  const handleDateFrom = (e) => {
    setSendData({ ...sendData, dateFrom: e.id, dateVo: e.text })
  }

  const handleJobNature = (e) => {
    setSendData({ ...sendData, jobNatureId: e.id, jobNatureDesc: e.text })
  }

  // 修改報表類型
  const handleReportType = (e) => {
    setDefaultReport(e.target.value)
    setSendData({ ...sendData, recordsCriteria: e.target.value })
  }

  const sortByColumn = (sort) => {
    const searchData = { ...sendData }

    if (searchData.sortBy === sort && searchData.orderBy) {
      searchData.orderBy = null
    } else {
      searchData.orderBy = 'desc'
    }

    searchData.sortBy = sort
    setSendData(searchData)

    handleSubmit(searchData)
  }

  const handleSubmit = (data) => {
    if (!sendData.dateFrom) {
      alert('請選擇日期')
      return
    }

    let searchData = {}
    if (data) {
      searchData = { ...data }
    } else {
      searchData = { ...sendData, orderBy: null }
    }

    const getData = async () => {
      setBackOpen(true)
      let data = await api.getReportData(searchData)
      if (data && data.list) {
        setIsSearchPage(false)
        setReportData(data)
        setTableData(data.list)
        setIsShowVendorColumn(searchData.recordsCriteria === '0')
      }
      setBackOpen(false)
    }
    getData()
  }

  const resetForm = (e) => {
    setDefaultReport()
    setDefaultReport('0')
    setSendData({ jobNatureId: '', dateFrom: '' })
    setSendData({ recordsCriteria: '0' })
  }

  const exportExcel = () => {
    const mpfPrint = async () => {
      setBackOpen(true)
      let sendApi = await commonApi.exportFile(sendData, '/payrollReportController/RptWorkersAutoPayStatuStradeListPdfExcel', 'xls', 'WorkerSalaryAnalysisTrade')
      if (sendApi) {
        if (sendApi.code === 500) {
          setDialog({
            ...dialog,
            content: '請求出錯',
            open: true,
          })
          return []
        }
        // setPrintData(sendApi.data || {})
      }
      setBackOpen(false)
    }
    mpfPrint()
  }

  const exportPdf = () => {
    const mpfPrint = async () => {
      setBackOpen(true)
      let sendApi = await commonApi.exportFile(sendData, '/payrollReportController/RptWorkersAutoPayStatuStradeListPdf', 'pdf', 'WorkerSalaryAnalysisTrade')
      if (sendApi) {
        if (sendApi.code === 500) {
          setDialog({
            ...dialog,
            content: '請求出錯',
            open: true,
          })
          return []
        }
        // setPrintData(sendApi.data || {})
      }
      setBackOpen(false)
    }
    mpfPrint()
  }

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {isSearchPage && (
        <form>
          <Container maxWidth="md">
            <Grid justifyContent="center" alignItems="center" spacing={2}>
              {/* 工人薪金分析（以行頭分類） */}
              <Grid item xs={12}>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                  <b>{t('icon_vas_wsat_title')}</b>
                </Typography>
              </Grid>
              <Grid item sx={{ margin: 'auto', width: '400px', marginBottom: '20px', marginTop: '10px' }}>
                <TextField id="jobNatureId" value={sendData.jobNatureId || ''} fullWidth size="small" label={t('icon_vas_wsat_trade')} select>
                  {jobNatureList.map((item, index) => (
                    <MenuItem key={index} value={item.id} onClick={() => handleJobNature(item)}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sx={{ margin: 'auto', width: '400px', marginBottom: '20px', marginTop: '10px' }}>
                <TextField error={!sendData.dateFrom} id="dateFrom" value={sendData.dateFrom || ''} fullWidth size="small" label={t('icon_vas_wsat_date_from')} select>
                  {dateMonthList.map((item, index) => (
                    <MenuItem key={index} value={item.id} onClick={() => handleDateFrom(item)}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item sx={{ margin: 'auto', width: '400px', marginBottom: '20px', marginTop: '10px' }}>
                <FormControl size="small">
                  <FormLabel>{t('icon_vas_wsat_report_type')}</FormLabel>
                  <RadioGroup row onChange={handleReportType} aria-labelledby="contentRadio" name="row-radio-buttons-group" value={defaultReport}>
                    <FormControlLabel value="0" label={t('icon_vas_wsat_report_type_detail')} control={<Radio />} />
                    <FormControlLabel value="1" label={t('icon_vas_wsat_report_type_summary')} control={<Radio />} />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item sx={{ margin: 'auto', width: '400px', marginBottom: '20px', marginTop: '10px' }}>
                <FormControl>
                  <Button
                    variant="contained"
                    onClick={() => handleSubmit()}
                    sx={{
                      backgroundColor: 'primaryBlue',
                      color: 'commom.white',
                    }}
                  >
                    {t('button_submit')}
                  </Button>
                </FormControl>
                <FormControl sx={{ marginLeft: '20px' }}>
                  <Button
                    variant="contained"
                    onClick={() => resetForm()}
                    sx={{
                      backgroundColor: 'primaryGreen',
                      color: 'commom.white',
                      marginRight: '10px',
                    }}
                    label="Reset"
                  >
                    {t('button_reset')}
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Container>
        </form>
      )}

      {!isSearchPage && (
        <>
          <Container maxWidth={false}>
            <div
              style={{
                display: 'block',
                textAlign: 'left',
                marginTop: '0px',
              }}
            >
              <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
                <ArrowBackIcon />
                {t('button_back')}
              </Button>
            </div>

            <div style={{ display: 'block', textAlign: 'right' }}>
              <Button sx={{ float: 'right', mr: '20px' }} onClick={exportExcel}>
                <DownloadIcon />
                {t('button_export_excel')}
              </Button>
              <Button sx={{ float: 'right', mr: '20px' }} onClick={exportPdf}>
                <DownloadIcon />
                {t('button_export_pdf')}
              </Button>
            </div>
            <div>
              <Typography variant="h4" style={{ textAlign: 'center', mt: '-30px' }}>
                <b>{t('icon_vas_wsat_title')}</b>
              </Typography>
              <TableContainer>
                <Table sx={{ textAlign: 'left' }} size="small">
                  <TableBody>
                    <TableRow>
                      {/* 製作日期 */}
                      <TableCell sx={{ fontWeight: 'bold', display: 'block', borderBottom: 'none' }}>{t('icon_label_creation_date')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{moment(new Date().getTime()).format('yyyy/MM/DD')}</TableCell>
                    </TableRow>
                    <TableRow>
                      {/* 日期（由) */}
                      <TableCell sx={{ fontWeight: 'bold', width: 200, borderBottom: 'none' }}>{t('icon_vas_wsat_date_from')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.dateVo}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 報表形式: 	 */}
                      <TableCell sx={{ fontWeight: 'bold', display: 'block', borderBottom: 'none' }}>{t('icon_vas_wsat_report_type')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>
                        {sendData.recordsCriteria === '0' ? t('icon_vas_wsat_report_type_detail') : t('icon_vas_wsat_report_type_summary')}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 行頭： 	 */}
                      <TableCell sx={{ fontWeight: 'bold', display: 'block', borderBottom: 'none' }}>{t('Outfit')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.jobNatureId == null ? t('all_outfits') : sendData.jobNatureDesc}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer style={{ maxHeight: '800px', maxWidth: '100vw' }}>
                <Table size="small" sx={{ mt: '20px' }} stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('1')}>
                          {t('icon_vas_wsat_trade')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('2')}>
                          {t('icon_vas_wsat_period')}
                        </Link>
                      </TableCell>

                      {isShowVendorColumn && (
                        <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                          <Link href="#" underline="none" onClick={() => sortByColumn('3')}>
                            {t('icon_vas_wsat_vendor')}
                          </Link>
                        </TableCell>
                      )}

                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('4')}>
                          {t('icon_vas_wsat_attendance_num')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('5')}>
                          {t('icon_vas_wsat_attendance_num_percent')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('6')}>
                          {t('icon_vas_wsat_absence_num')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('7')}>
                          {t('icon_vas_wsat_absence_num_percent')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('8')}>
                          {t('icon_vas_wsat_total_worker_num')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('9')}>
                          {t('icon_vas_wsat_attendance_days')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('10')}>
                          {t('icon_vas_wsat_attendance_days_percent')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('11')}>
                          {t('icon_vas_wsat_absence_days')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('12')}>
                          {t('icon_vas_wsat_absence_days_percent')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('13')}>
                          {t('icon_vas_wsat_total_days')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('14')}>
                          {t('icon_vas_wsat_total_salary')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('15')}>
                          {t('icon_vas_wsat_average_daily_salary')}
                        </Link>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {!!tableData && tableData.length > 0 && (
                    <>
                      <TableBody>
                        {tableData.map((row, ind) => (
                          <TableRow
                            hover
                            key={ind}
                            sx={{
                              backgroundColor: ind % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                            }}
                          >
                            <TableCell>{row.withAutopayJobNatureDesc}</TableCell>
                            <TableCell>{row.withAutopayDateFrom}</TableCell>
                            {isShowVendorColumn && <TableCell>{row.withAutopayVendorDesc}</TableCell>}

                            <TableCell>{row.workersWithAutopay}</TableCell>
                            <TableCell>{row.workersWithAutopayPercent}</TableCell>
                            <TableCell>{row.workersWithoutAutopay}</TableCell>
                            <TableCell>{row.workersWithoutAutopayPercent}</TableCell>
                            <TableCell>{row.totalWorkers}</TableCell>
                            <TableCell>{row.workdayActWithAutopay}</TableCell>
                            <TableCell>{row.workdayActWithAutopayPercent}</TableCell>
                            <TableCell>{row.workdayActWithoutAutopay}</TableCell>
                            <TableCell>{row.workdayActWithoutAutopayper}</TableCell>
                            <TableCell>{row.totalWorkdayAct}</TableCell>
                            <TableCell>{row.salaryWithAutopayFormat}</TableCell>
                            <TableCell>{row.averAgeSalaryFormat}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </>
                  )}

                  {(!tableData || tableData.length <= 0) && (
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ textAlign: 'center' }} colSpan={15}>
                          {'No data found !'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
            <Table padding="none" sx={{ maxWidth: '600px', mt: '40px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{''}</TableCell>
                  <TableCell>{t('icon_vas_wsat_total_num')}</TableCell>
                  <TableCell>{t('icon_vas_wsat_total_percent')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>{t('icon_vas_wsat_attendance_num')}</b>
                  </TableCell>
                  <TableCell>{reportData.workerswithautopay}</TableCell>
                  <TableCell>{reportData.workerswithautopaypercentage}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('icon_vas_wsat_absence_num')}</b>
                  </TableCell>
                  <TableCell>{reportData.workerswithoutautopay}</TableCell>
                  <TableCell>{reportData.workerswithoutautopaypercentage}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('icon_vas_wsat_total_worker_num')}</b>
                  </TableCell>
                  <TableCell>{reportData.totalworkers}</TableCell>
                  <TableCell>{''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('icon_vas_wsat_attendance_days')}</b>
                  </TableCell>
                  <TableCell>{reportData.workdayactwithautopay}</TableCell>
                  <TableCell>{reportData.workdayactwithautopaypercentage}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('icon_vas_wsat_absence_days')}</b>
                  </TableCell>
                  <TableCell>{reportData.workdayactwithoutautopay}</TableCell>
                  <TableCell>{reportData.workdayactwithoutautopaypercentage}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('icon_vas_wsat_total_days')}</b>
                  </TableCell>
                  <TableCell>{reportData.totalworkdayact}</TableCell>
                  <TableCell>{''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('icon_vas_wsat_total_salary')}</b>
                  </TableCell>
                  <TableCell>{reportData.salarywithautopay}</TableCell>
                  <TableCell>{''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('icon_vas_wsat_average_daily_salary')}</b>
                  </TableCell>
                  <TableCell>{reportData.averagesalary}</TableCell>
                  <TableCell>{''}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Container>

          <Typography style={{ paddingTop: '50px', paddingLeft: '20px', paddingRight: '20px' }}>
            <b>{t('icon_vas_wast_tips')}</b>
          </Typography>
        </>
      )}
    </>
  )
}
