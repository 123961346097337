import React, { useEffect } from 'react'
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import $http, { $link } from 'src/utils/api/services'
import * as api from "src/utils/api/common/index";

export default function RedirectOtherSystem() {
  // const params = useParams()
  // const api = params.api;
  const [search] = useSearchParams();
  const link = search.get('link')

  const token = localStorage.getItem("access_token")
  useEffect(async () => {
    const getUser = await api.getUser();
    const vendorId = getUser?.vendorId;
    axios({
      url: `${process.env.REACT_APP_WEB_URL}/oauth/redirect-other-system`,
      method: "POST",
      data: {
        "link": link, "vendorId": vendorId, "token": token
      },
    })
    // await $http("/oauth/redirect-other-system", { "link": link, "vendorId": vendorId, "token": token });
  }, [])
  return (
    <>
    </>
  );
}
