export default class CommonUtils {
    // 判斷一個內容是否為空或者空白字符串
    static isEmptyOrBlank(content) {
        // 如果是falsy值，包括空字符串、null、undefined、0、false、NaN，返回true
        if (!content) {
            return true;
        }
        // 如果是字符串，並且只包含空白字符，返回true
        if (typeof content === 'string' || content instanceof String) {
            return content.trim().length === 0;
        }
        // 其他情況，返回false
        return false;
    }
}