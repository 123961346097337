import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Box,
  Collapse,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  RadioGroup,
  Radio,
  Backdrop,
  CircularProgress,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import AddIcon from '@mui/icons-material/Add'
import moment from 'moment'
import * as api from 'src/utils/api/systemAdministrator/ArticleSetupApi'
import { CustomDialog, CDatePicker, CAutocomplete } from 'src/components/common'
import CommonUtils from 'src/utils/CommonUtils'

// 添加數據接口
const handleAddData = async (body) => {
  console.log(body)
  try {
    // 调用接口
    const data = async () => {
      let data = await api.insertData(body)
      return data.message
    }
    return data()
  } catch (error) {
    return null
  }
}
// 修改數據接口
const handleUpdateData = async (body) => {
  try {
    // 调用接口
    const data = async () => {
      let data = await api.updateData(body)
      return data.message
    }
    return data()
  } catch (error) {
    return null
  }
}
// 刪除數據接口
const handleDeleteData = async (body) => {
  try {
    // 调用接口
    const data = async () => {
      let data = await api.deleteData(body)
      return data.message
    }
    return data()
  } catch (error) {
    return null
  }
}
// 查詢报表接口
const handleRefreshData = async (body) => {
  try {
    // 调用接口
    const data = async () => {
      let data = await api.selectLists(body)
      return data.data
    }
    return data()
  } catch (error) {
    return null
  }
}

function ArticleSetup() {
  // 國際化
  const { t } = useTranslation()
  // 搜索框开关
  const [searchOpen, setSearchOpen] = React.useState(true)
  // 搜索表单
  const [searchForm, setSearchForm] = React.useState({
    articleDateFrom: '',
    articleDateTo: '',
    articleTitle: '',
    articleOwnerName: '',
    pageSize: '',
    pageNumber: '',
    orderBy: '',
    ascOrder: '',
  })
  // 搜索按钮loading
  const [openLoading, setOpenLoading] = React.useState(false)
  // table loading
  const [tableLoading, setTableLoading] = React.useState(false)
  // 表格数据
  const [rows, setRows] = React.useState([])
  // 表格数据總數
  const [total, setTotal] = React.useState(0)
  // 表格内容开关
  const [detailsOpen, setDetailsOpen] = React.useState(false)
  // 表頭
  const columns = [
    {
      id: 'articleDate',
      label: t('date'),
      minWidth: 80,
      maxWidth: 80,
      align: 'left',
    },
    {
      id: 'categoryName',
      label: t('Category'),
      minWidth: 200,
      maxWidth: 300,
      align: 'left',
    },
    {
      id: 'articleTitle',
      label: t('Title'),
      minWidth: 150,
      maxWidth: 150,
      align: 'left',
    },
    {
      id: 'articleOwnerName',
      label: t('Author'),
      minWidth: 150,
      maxWidth: 150,
      align: 'left',
    },
    {
      id: 'enable',
      label: t('State'),
      minWidth: 50,
      maxWidth: 50,
      align: 'center',
    },
    {
      id: 'action',
      label: t('Action'),
      minWidth: 50,
      maxWidth: 150,
      align: 'center',
    },
  ]
  // 頁數
  const [page, setPage] = React.useState(0)
  // 每頁數量
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  // 表格每頁數量設置
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 25, 100])

  // 行数据详情
  const [tableRowDetail, setTableRowDetail] = React.useState({
    articleId: '',
    articleDate: '',
    categoryId: null,
    categoryName: null,
    articleTitle: '',
    articleOwnerName: '',
    articleAbstract: '',
    articleFileId: '',
    filename: '',
    enable: '',
    file: null,
  })

  // 彈出框
  const [dialog, setDialog] = useState({
    content: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        {t('Confirm')}
      </Button>
    ),
    open: false,
  })

  // 是否为添加表单
  const [isAddFlag, setIsAddFlag] = React.useState(false)
  // 添加表單内容开关
  const [addOpen, setAddOpen] = React.useState(false)
  // 弹出框标题
  const [dialogTitle, setDialogTitle] = React.useState('')

  // 頁面初始化
  useEffect(() => {}, [])

  // 表單值改變
  const handleFormChange = (event) => {
    setSearchForm({
      ...searchForm,
      [event.target.name]: event.target.value,
    })
  }
  const handleTableRowDetailChange = (event) => {
    setTableRowDetail({
      ...tableRowDetail,
      [event.target.name]: event.target.value,
    })
  }

  // 刷新列表
  const refreshRowsPage = async (body) => {
    // table loading open
    setTableLoading(true)
    handleRefreshData(body).then((value) => {
      // 报表内容显示
      setDetailsOpen(true)
      // 搜索内容关闭
      setSearchOpen(false)
      // 设置报表数据
      setRows(value ? value.records : [])
      setTotal(value ? value.total : 0)
      // 設置分頁數據
      // setPage(value ? value.current - 1 : 0);
      // setRowsPerPage(value ? value.size : 1);
      // 判斷每頁數量是否存在,不存在就加入進去
      setRowsPerPageOptions(pushKey(rowsPerPageOptions, value ? value.size : 10))
      // table loading close
      setTableLoading(false)
    })
  }
  // 刷新列表
  const refreshRows = async () => {
    // table loading open
    setTableLoading(true)
    handleRefreshData(searchForm).then((value) => {
      // 报表内容显示
      setDetailsOpen(true)
      // 搜索内容关闭
      setSearchOpen(false)
      // 设置报表数据
      setRows(value ? value.records : [])
      setTotal(value ? value.total : 0)
      // 設置分頁數據
      // setPage(value ? value.current - 1 : 0);
      // setRowsPerPage(value ? value.size : 1);
      // 判斷每頁數量是否存在,不存在就加入進去
      setRowsPerPageOptions(pushKey(rowsPerPageOptions, value ? value.size : 10))
      // table loading close
      setTableLoading(false)
    })
  }
  // 表单提交
  const handleSearchClick = async () => {
    // 打开按钮loading, 防止重复点击
    setOpenLoading(true)
    setSearchForm({
      ...searchForm,
      pageNumber: page,
      pageSize: rowsPerPage,
    })
    handleRefreshData(searchForm).then((value) => {
      // 关闭按钮loading
      setOpenLoading(false)
      // 改变key值, 使列表刷新
      // setRefreshRows((oldRefreshRows) => oldRefreshRows + 1);
      // 报表内容显示
      setDetailsOpen(!detailsOpen)
      // 搜索内容关闭
      setSearchOpen(!searchOpen)
      // 设置报表数据
      setRows(value ? value.records : [])
      setTotal(value ? value.total : 0)
      // 設置分頁數據
      // setPage(value ? value.current - 1 : 0);
      // setRowsPerPage(value ? value.size : 1);
      // 判斷每頁數量是否存在,不存在就加入進去
      setRowsPerPageOptions(pushKey(rowsPerPageOptions, value ? value.size : 10))
    })
  }
  // 判斷數組是否含有某個元素, 沒有就加進去
  const pushKey = (arr, key) => {
    // arr是否為數組
    if (!Array.isArray(arr)) return
    // 是否含有該key
    const _key = arr.some((item) => item === key)
    if (_key) {
      return arr
    } else {
      return [...arr, key]
    }
  }

  // 重置查询表单
  const handleResetClick = () => {
    setSearchForm({
      articleDateFrom: '',
      articleDateTo: '',
    })
  }

  // 文件上传组件
  const inputRef = React.useRef(null)
  // 重置添加表单
  const handleResetAddClick = () => {
    setTableRowDetail({
      articleId: '',
      articleDate: '',
      categoryId: '',
      categoryName: '',
      category: '',
      articleTitle: '',
      articleOwnerName: '',
      articleAbstract: '',
      articleFileId: '',
      filename: '',
      enable: 1,
      file: null,
    })
    if (inputRef.current) {
      inputRef.current.value = null
    }
  }
  // 重置修改表单
  const handleResetEditClick = () => {
    setTableRowDetail({
      ...tableRowDetail,
      articleId: '',
      articleDate: '',
      categoryId: '',
      categoryName: '',
      articleTitle: '',
      articleOwnerName: '',
      articleAbstract: '',
      articleFileId: '',
      filename: '',
      enable: '',
    })
    if (inputRef.current) {
      inputRef.current.value = null
    }
  }

  // 禁用开关组件
  const radioRef = React.useRef(null)

  // 添加一行新數據
  const handleAddClick = () => {
    if (
      CommonUtils.isEmptyOrBlank(tableRowDetail.articleTitle) ||
      CommonUtils.isEmptyOrBlank(tableRowDetail.articleAbstract) ||
      CommonUtils.isEmptyOrBlank(tableRowDetail.articleDate) ||
      CommonUtils.isEmptyOrBlank(tableRowDetail.articleOwnerName) ||
      CommonUtils.isEmptyOrBlank(tableRowDetail.categoryId) ||
      CommonUtils.isEmptyOrBlank(tableRowDetail.enable) ||
      CommonUtils.isEmptyOrBlank(tableRowDetail.filename) ||
      CommonUtils.isEmptyOrBlank(tableRowDetail.file)
    ) {
      return
    }
    // 打开按钮loading, 防止重复点击
    setOpenLoading(true)
    // 设置JSON
    const json = JSON.stringify({
      articleTitle: tableRowDetail.articleTitle,
      articleAbstract: tableRowDetail.articleAbstract,
      articleDate: tableRowDetail.articleDate,
      articleOwnerName: tableRowDetail.articleOwnerName,
      categoryId: tableRowDetail.categoryId,
      enable: tableRowDetail.enable,
      // "file": tableRowDetail.file,
      filename: tableRowDetail.filename,
    })
    console.log(tableRowDetail.articleDate)
    console.log(moment(new Date(tableRowDetail.articleDate).getTime()).format('YYYY/MM/DD'))
    // 设置FormData参数
    const formData = new FormData()
    formData.append('dto', new Blob([json], { type: 'application/json' }))
    formData.append('file', tableRowDetail.file)
    handleAddData(formData).then((value) => {
      // 关闭按钮loading
      setOpenLoading(false)
      // 提示是否成功
      setDialog({
        ...dialog,
        // content: value ? t('AddSuccess') : t('AddFail'),
        content: value,
        action: (
          <Button
            variant="contained"
            onClick={() => {
              setDialog({ ...dialog, open: false })
              handleCloseAddClick()
            }}
          >
            {t('Confirm')}
          </Button>
        ),
        open: true,
      })
      // 更新table
      refreshRows()
    })
  }
  // 编辑一行新數據
  const handleEditClick = () => {
    if (
      CommonUtils.isEmptyOrBlank(tableRowDetail.articleTitle) ||
      CommonUtils.isEmptyOrBlank(tableRowDetail.articleAbstract) ||
      CommonUtils.isEmptyOrBlank(tableRowDetail.articleDate) ||
      CommonUtils.isEmptyOrBlank(tableRowDetail.articleOwnerName) ||
      CommonUtils.isEmptyOrBlank(tableRowDetail.enable) ||
      CommonUtils.isEmptyOrBlank(tableRowDetail.filename) ||
      CommonUtils.isEmptyOrBlank(tableRowDetail.articleId) ||
      CommonUtils.isEmptyOrBlank(tableRowDetail.articleFileId) ||
      CommonUtils.isEmptyOrBlank(tableRowDetail.categoryId)
    ) {
      return
    }
    // 打开按钮loading, 防止重复点击
    setOpenLoading(true)
    // 设置JSON
    const json = JSON.stringify({
      articleId: tableRowDetail.articleId,
      articleTitle: tableRowDetail.articleTitle,
      articleAbstract: tableRowDetail.articleAbstract,
      articleDate: moment(new Date(tableRowDetail.articleDate).getTime()).format('YYYY/MM/DD'),
      articleOwnerName: tableRowDetail.articleOwnerName,
      articleFileId: tableRowDetail.articleFileId,
      categoryId: tableRowDetail.categoryId,
      enable: tableRowDetail.enable,
      // "file": tableRowDetail.file,
      filename: tableRowDetail.filename,
    })
    // 设置FormData参数
    const formData = new FormData()
    formData.append('dto', new Blob([json], { type: 'application/json' }))
    formData.append('file', tableRowDetail.file)
    handleUpdateData(formData).then((value) => {
      // 关闭按钮loading
      setOpenLoading(false)
      // 提示是否成功
      setDialog({
        ...dialog,
        content: value,
        action: (
          <Button
            variant="contained"
            onClick={() => {
              setDialog({ ...dialog, open: false })
              handleCloseAddClick()
            }}
          >
            {t('Confirm')}
          </Button>
        ),
        open: true,
      })
      // 更新table
      refreshRows()
    })
  }
  // 确定执行删除事件
  const handleDeleteClick = (row) => {
    if (CommonUtils.isEmptyOrBlank(row.articleId) || CommonUtils.isEmptyOrBlank(row.articleFileId)) {
      return
    }
    // 打开按钮loading, 防止重复点击
    setOpenLoading(true)
    // 设置body
    const body = {
      articleId: row.articleId,
      articleFileId: row.articleFileId,
    }
    handleDeleteData(body).then((value) => {
      // 关闭按钮loading
      setOpenLoading(false)
      // 提示是否成功
      setDialog({
        ...dialog,
        content: value,
        action: (
          <Button
            variant="contained"
            onClick={() => {
              setDialog({ ...dialog, open: false })
            }}
          >
            {t('Confirm')}
          </Button>
        ),
        open: true,
      })
      // 更新table
      refreshRows()
    })
  }

  // 編輯按鈕點擊事件
  const handleOpenEditClick = (row) => {
    // 编辑时需要隐藏输入框
    setIsAddFlag(false)
    // 回显表单内容
    setTableRowDetail({
      articleId: row.articleId,
      articleDate: new Date(moment(row.articleDate, 'YYYY年MM月DD日').format()),
      categoryId: row.categoryId,
      categoryName: row.categoryName,
      articleTitle: row.articleTitle,
      articleOwnerName: row.articleOwnerName,
      articleAbstract: row.articleAbstract,
      articleFileId: row.articleFileId,
      filename: row.filename,
      enable: row.enable,
    })
    // 设置弹出框标题为-编辑
    setDialogTitle(t('Edit'))
    // 打开修改表单
    setAddOpen(true)
  }
  // 刪除按鈕點擊事件
  const handleOpenDeleteClick = (row) => {
    // 删除时要打开提示框提示是否删除
    setDialog({
      ...dialog,
      content: t('sure_to_delete'),
      action: (
        <>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <FormControl>
              <LoadingButton
                loading={openLoading}
                variant="contained"
                sx={{
                  backgroundColor: '#ff0000',
                  color: 'commom.white',
                }}
                onClick={() => handleDeleteClick(row)}
              >
                {t('Delete')}
              </LoadingButton>
            </FormControl>
            <FormControl sx={{ marginLeft: '10px' }}>
              <Button
                variant="contained"
                onClick={() => {
                  setDialog({ ...dialog, open: false })
                }}
                sx={{
                  backgroundColor: '#9b9b9b',
                  color: 'commom.white',
                  marginRight: '10px',
                }}
                label={t('Close')}
              >
                {t('Close')}
              </Button>
            </FormControl>
          </Grid>
        </>
      ),
      open: true,
    })
  }
  // 关闭表单弹出框
  const handleCloseAddClick = () => {
    setAddOpen(false)
  }
  // 打開添加窗口
  const handleOpenAddClick = () => {
    // 添加时需要显示输入框
    setIsAddFlag(true)
    // 重置表单内容
    handleResetAddClick()
    // 设置弹出框标题为-添加
    setDialogTitle(t('Add'))
    // 打开添加表单
    setAddOpen(true)
  }

  // 顯示控制, 點擊過濾icon后更新表格數據
  const handleFilterClick = () => {
    if (searchOpen) {
      setOpenLoading(true)
      handleRefreshData(searchForm).then((value) => {
        // 关闭按钮loading
        setOpenLoading(false)
        // 改变key值, 使列表刷新
        // setRefreshRows((oldRefreshRows) => oldRefreshRows + 1);
        // 报表内容显示
        setDetailsOpen(!detailsOpen)
        // 搜索内容关闭
        setSearchOpen(!searchOpen)
        // 设置报表数据
        setRows(value ? value.records : [])
        setTotal(value ? value.total : 0)
        // 設置分頁數據
        // setPage(value ? value.current - 1 : 0);
        // setRowsPerPage(value ? value.size : 1);
        // 判斷每頁數量是否存在,不存在就加入進去
        setRowsPerPageOptions(pushKey(rowsPerPageOptions, value ? value.size : 10))
      })
    } else {
      // 报表内容显示
      setDetailsOpen(!detailsOpen)
      // 搜索内容关闭
      setSearchOpen(!searchOpen)
    }
  }

  // 切換頁數
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setSearchForm({
      ...searchForm,
      pageNumber: newPage,
    })
    const body = {
      ...searchForm,
      pageNumber: newPage,
    }
    refreshRowsPage(body)
  }

  // 切換每頁數量
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
    setSearchForm({
      ...searchForm,
      pageSize: +event.target.value,
    })
    const body = {
      ...searchForm,
      pageNumber: newPage,
    }
    refreshRowsPage(body)
  }

  // 文件變化事件
  const handleFileUpload = (event) => {
    if (!event.target.files) {
      return
    }
    const file = event.target.files[0]
    const { name } = file
    const filename = moment(Date.now()).format('YYYYMMDDHHmmss') + name.slice(name.indexOf('.'))
    setTableRowDetail({
      ...tableRowDetail,
      filename: filename,
      file: file,
    })
    // event.target.value = null;
    // const reader = new FileReader();
    // reader.onload = (evt) => {
    //     if (!evt?.target?.result) {
    //         return;
    //     }
    //     const { result } = evt.target;
    //     console.log(result)
    // }
    // reader.readAsBinaryString(file);
  }
  return (
    <>
      {/* 提示框 */}
      <CustomDialog sx={{ whiteSpace: 'pre-line' }} open={dialog.open} title={dialog.title} content={dialog.content} action={dialog.action} />
      {/* 標題 */}
      <Container sx={{ textAlign: 'center' }} maxWidth="sm">
        <Typography variant="h4">
          <b>{t('icon3_article_setup')}</b>
        </Typography>
      </Container>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={tableLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="md">
        {/* 导航条 */}
        <Toolbar
          align="center"
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Tooltip title="New">
            <IconButton onClick={(event) => handleOpenAddClick(event)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Filter list">
            <IconButton onClick={(event) => handleFilterClick(event)}>
              <FilterAltOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>

        {/* 過濾條件 */}
        <Collapse sx={{ flexGrow: 1, textAlign: 'center' }} in={searchOpen} timeout="auto" unmountOnExit>
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            {/* 搜索表单 */}
            <Grid item xs={6}>
              <CDatePicker
                name="articleDateFrom"
                label={t('date_from')}
                value={searchForm.articleDateFrom || null}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    articleDateFrom: data ? moment(new Date(data).getTime()).format('YYYY/MM/DD') : null,
                  })
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CDatePicker
                name="articleDateTo"
                label={t('date_to')}
                value={searchForm.articleDateTo || null}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    articleDateTo: data ? moment(new Date(data).getTime()).format('YYYY/MM/DD') : null,
                  })
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField name="articleOwnerName" value={searchForm.articleOwnerName || ''} fullWidth size="small" label={t('Author')} onChange={(event) => handleFormChange(event)} />
            </Grid>
            <Grid item xs={6}>
              <TextField name="articleTitle" value={searchForm.articleTitle || ''} fullWidth size="small" label={t('Title')} onChange={(event) => handleFormChange(event)} />
            </Grid>
            {/* 间隔 */}
            <Grid item xs={6} />
            {/* 按钮 */}
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <FormControl>
                <LoadingButton
                  loading={openLoading}
                  variant="contained"
                  sx={{
                    backgroundColor: 'primaryBlue',
                    color: 'commom.white',
                  }}
                  onClick={handleSearchClick}
                >
                  {t('Search')}
                </LoadingButton>
              </FormControl>
              <FormControl sx={{ marginLeft: '20px' }}>
                <Button
                  variant="contained"
                  onClick={handleResetClick}
                  sx={{
                    backgroundColor: 'primaryGreen',
                    color: 'commom.white',
                    marginRight: '10px',
                  }}
                  label={t('Reset')}
                >
                  {t('Reset')}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Collapse>
      </Container>

      {/* 表格 */}
      <Collapse in={detailsOpen} timeout="auto" unmountOnExit>
        <Container maxWidth={false}>
          <TableContainer>
            <Table
              sx={{
                wordBreak: 'keep-all',
                mt: '10px',
                '& .MuiTableCell-root': {
                  paddingRight: '10px',
                  paddingLeft: '10px',
                },
              }}
              size="small"
            >
              {/* 表头 */}
              <TableHead>
                <TableRow hover>
                  {columns.map((column) => (
                    <TableCell
                      sx={{ fontSize: '15px', textAlign: column.align }}
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {/* 行 */}
              <TableBody>
                {rows.length > 0 ? (
                  <>
                    {rows
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.articleId + '-' + index}>
                            {columns.map((column) => {
                              const value = row[column.id]
                              if (column.id === 'action') {
                                return (
                                  <TableCell
                                    sx={{
                                      fontSize: '15px',
                                      textAlign: column.align,
                                      minWidth: column.minWidth,
                                      maxWidth: column.maxWidth,
                                    }}
                                    key={column.id}
                                  >
                                    <Button
                                      variant="contained"
                                      onClick={() => handleOpenEditClick(row)}
                                      sx={{
                                        backgroundColor: '#257018',
                                        color: 'commom.white',
                                        marginRight: '10px',
                                      }}
                                      label={t('Edit')}
                                    >
                                      {t('Edit')}
                                    </Button>
                                    <Button
                                      variant="contained"
                                      onClick={() => handleOpenDeleteClick(row)}
                                      sx={{
                                        backgroundColor: '#ff0000',
                                        color: 'commom.white',
                                        marginRight: '10px',
                                      }}
                                      label={t('Delete')}
                                    >
                                      {t('Delete')}
                                    </Button>
                                  </TableCell>
                                )
                              } else {
                                return (
                                  <TableCell
                                    sx={{
                                      fontSize: '15px',
                                      textAlign: column.align,
                                      minWidth: column.minWidth,
                                      maxWidth: column.maxWidth,
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                    key={column.id}
                                  >
                                    <Tooltip title={value}>
                                      <span>{column.id === 'articleDate' ? value.slice(0, 10) : column.id === 'enable' ? (value === 1 ? t('Enabled') : t('Disabled')) : value}</span>
                                    </Tooltip>
                                  </TableCell>
                                )
                              }
                            })}
                          </TableRow>
                        )
                      })}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan="13" sx={{ textAlign: 'center' }}>
                      No Records Found!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* 分頁 */}
          {rows.length > 0 ? (
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <></>
          )}
        </Container>
      </Collapse>

      {/* 添加表單 */}
      <Dialog fullWidth maxWidth="sm" sx={{ whiteSpace: 'pre-line' }} open={addOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <Container maxWidth="md">
            <Grid container justifyContent="center" alignItems="center" spacing={1}>
              {/* 表单 */}
              <Grid item xs={12} />
              <Grid item xs={12}>
                <TextField
                  error={tableRowDetail.articleTitle === ''}
                  required
                  name="articleTitle"
                  value={tableRowDetail.articleTitle || ''}
                  fullWidth
                  size="small"
                  label={t('Title')}
                  onChange={(event) => handleTableRowDetailChange(event)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={tableRowDetail.articleAbstract === ''}
                  required
                  name="articleAbstract"
                  value={tableRowDetail.articleAbstract || ''}
                  fullWidth
                  size="small"
                  label={t('Abstract')}
                  onChange={(event) => handleTableRowDetailChange(event)}
                />
              </Grid>
              <Grid item xs={12}>
                <CDatePicker
                  name="articleDate"
                  label={t('date')}
                  required
                  value={tableRowDetail.articleDate || null}
                  onChange={(data) => {
                    setTableRowDetail({
                      ...tableRowDetail,
                      articleDate: data ? moment(new Date(data).getTime()).format('YYYY/MM/DD') : null,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={tableRowDetail.articleOwnerName === ''}
                  required
                  name="articleOwnerName"
                  value={tableRowDetail.articleOwnerName || ''}
                  fullWidth
                  size="small"
                  label={t('Author')}
                  onChange={(event) => handleTableRowDetailChange(event)}
                />
              </Grid>
              <Grid item xs={12}>
                <CAutocomplete
                  // error={tableRowDetail.categoryId === ''}
                  required
                  url="/vps/common/getCategoryId"
                  name="categoryId"
                  label={t('Category')}
                  value={
                    tableRowDetail.categoryId
                      ? {
                          id: tableRowDetail.categoryId,
                          text: tableRowDetail.categoryName,
                        }
                      : null
                  }
                  onChange={(event, newData) => {
                    if (newData) {
                      setTableRowDetail({
                        ...tableRowDetail,
                        categoryId: newData ? newData.id : null,
                        categoryName: newData ? newData.text : null,
                      })
                    } else {
                      setTableRowDetail({
                        ...tableRowDetail,
                        categoryId: null,
                        categoryName: null,
                      })
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Button component="label" variant="outlined" startIcon={<UploadFileIcon />}>
                  {t('Upload_File')}
                  <input type="file" accept=".pdf" hidden ref={inputRef} onChange={handleFileUpload} />
                </Button>

                <Box component="span" sx={{ paddingLeft: 1, fontSize: '15px' }}>
                  {t('The_maximum_file_limit_is_10MB')}
                </Box>

                <Box component="span" sx={{ paddingLeft: 2 }}>
                  {tableRowDetail.filename}
                </Box>
              </Grid>
              {!isAddFlag && (
                <>
                  <Grid item xs={12}>
                    <FormControl>
                      <RadioGroup row name="enable" defaultValue="1" ref={radioRef} value={tableRowDetail.enable} onChange={(event) => handleTableRowDetailChange(event)}>
                        <FormControlLabel value="1" control={<Radio />} label={t('Enabled')} labelPlacement="end" />
                        <FormControlLabel value="0" control={<Radio />} label={t('Disabled')} labelPlacement="end" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <FormControl>
              <LoadingButton
                loading={openLoading}
                variant="contained"
                sx={{
                  backgroundColor: '#2d8d27',
                  color: 'commom.white',
                }}
                onClick={dialogTitle === t('Add') ? handleAddClick : handleEditClick}
              >
                {dialogTitle}
              </LoadingButton>
            </FormControl>
            <FormControl sx={{ marginLeft: '10px' }}>
              <Button
                variant="contained"
                onClick={dialogTitle === t('Add') ? handleResetAddClick : handleResetEditClick}
                sx={{
                  backgroundColor: 'primaryGreen',
                  color: 'commom.white',
                  marginRight: '10px',
                }}
                label={t('Reset')}
              >
                {t('Reset')}
              </Button>
            </FormControl>
            <FormControl>
              <Button
                variant="contained"
                onClick={handleCloseAddClick}
                sx={{
                  backgroundColor: '#9b9b9b',
                  color: 'commom.white',
                  marginRight: '10px',
                }}
                label={t('Close')}
              >
                {t('Close')}
              </Button>
            </FormControl>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ArticleSetup
