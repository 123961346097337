import { React, useEffect, useState, useRef } from "react";
import {
  Collapse,
  Checkbox,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Paper,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CustomDialog from "src/components/common/CustomDialog";
import CustomSnackbar from "src/components/common/CustomSnackbar";
import { confirm } from "src/components/common";
import {
  roleSetupMappingDelete,
  roleSetupMappingAdd,
  menuDataCovert,
} from "../../../../../../utils/api/systemAdministrator/RoleSetupApi";

const dialogAction = (
  showCancelButton,
  handleConfirmDialog,
  handleCancelDialog
) => {
  if (showCancelButton) {
    return (
      <>
        <Button variant="contained" onClick={handleConfirmDialog}>
          確認
        </Button>
        <Button variant="contained" onClick={handleCancelDialog}>
          取消
        </Button>
      </>
    );
  } else {
    return (
      <Button variant="contained" onClick={handleConfirmDialog}>
        確認
      </Button>
    );
  }
};

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList(props) {
  const { data, setDataConvert, rowData } = props;
  const [fatherList, setFatherList] = useState([]);
  const [left, setLeft] = useState([0, 1, 2, 3]);
  const [right, setRight] = useState([4, 5, 6, 7]);
  const [open, setOpen] = useState(false);
  const leftChecked = intersection(fatherList, left);
  const rightChecked = intersection(fatherList, right);
  const [childList, setChildList] = useState([]);
  const [reload, setReload] = useState(false);
  const [backOpen, setBackOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    message: "",
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setSnackbar({ ...snackbar, open: false });
        }}
      >
        確認
      </Button>
    ),
    open: false,
  });

  useEffect(() => {
    setChildList([]);
    const getData = async () => {
      setBackOpen(true);
      let send3 = await menuDataCovert(rowData);
      setBackOpen(true);
      if (send3) {
        setDataConvert(send3.data);
      }
    };
    getData();
  }, [reload]);

  const handleClick = (items, value, index) => {
    let isOpen = value.open;
    value.open = !isOpen;
    items[index] = value;
    setDataConvert({ ...data });
  };

  const [dialog, setDialog] = useState({
    title: "",
    content: "",
    action: dialogAction(
      false,
      () => {
        setDialog({
          ...dialog,
          open: false,
        });
      },
      null
    ),
    open: false,
  });

  const handleFather = (value, index) => {
    // 父
    // 判斷選擇的元素是不是已經在list中 先把父的放進去list
    let itemIndex = fatherList.indexOf(value);
    if (itemIndex === -1) {
      fatherList.push(value);
    } else {
      fatherList.splice(itemIndex, 1);
    }
    // 便利找出這個menu的子列表，全部選進去,順便去掉重複。子：childList
    for (let j of value.securityMenuTableList) {
      let chlidIndex = childList.indexOf(j);
      j.roleId = rowData.roleId;
      if (itemIndex === -1) {
        if (chlidIndex === -1) {
          childList.push(j);
        }
      } else {
        childList.splice(chlidIndex, 1);
      }
    }
    setFatherList([...fatherList]);
    setChildList([...childList]);
  };

  function includes(arr1, arr2) {
    return arr2.every((val) => arr1.includes(val));
  }

  const handleChlid = (parent, item, index) => {
    // 子操作
    item.roleId = rowData.roleId;
    let childIndex = childList.indexOf(item);
    if (childIndex === -1) {
      childList.push(item);
    } else {
      childList.splice(childIndex, 1);
    }
    // 遍歷父的securityMenuTableList    假如子的全選，吧父的選擇
    let allChecked = true;
    for (let value of parent.securityMenuTableList) {
      if (childList.indexOf(value) === -1) {
        allChecked = false;
      }
    }
    if (allChecked) {
      fatherList.push(parent);
    } else {
      fatherList.splice(fatherList.indexOf(parent), 1);
    }
    setChildList([...childList]);
    setFatherList([...fatherList]);
  };

  const deleteMenuList1 = (childList) => {
    let send = roleSetupMappingDelete(childList);
    if (send) {
      setReload(!reload);
    }
  };

  const addMenuList = () => {
    setDialog({
      ...dialog,
      open: true,
      content: "是否確定添加?",
      action: dialogAction(
        true,
        () => {
          const operate = async () => {
            let send = await roleSetupMappingAdd(childList);
            console.log("succ", send);

            if (send.code === 200) {
              // confirm({
              //   title: t("tip"),
              //   content: "操作成功",
              // });
              setSnackbar({
                ...snackbar,
                open: true,
                message: "操作成功",
              });
              setReload(!reload);
              setDialog({
                ...dialog,
                open: false,
              });
            } else {
              setDialog({
                ...dialog,
                open: false,
              });

              // confirm({
              //   title: t("tip"),
              //   content: "操作失敗",
              // });
              setSnackbar({
                ...snackbar,
                message: "操作失敗",
                open: true,
                action: (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setSnackbar({ ...snackbar, open: false });
                    }}
                  >
                    確認
                  </Button>
                ),
              });
            }
          };
          operate();
        },
        () => {
          setDialog({
            ...dialog,
            open: false,
          });
        }
      ),
    });
  };

  const deleteMenuList = () => {
    setDialog({
      ...dialog,
      open: true,
      content: "是否確定刪除?",
      action: dialogAction(
        true,
        () => {
          const operate = async () => {
            let send = await roleSetupMappingDelete(childList);
            if (send.code === 200) {
              // confirm({
              //   title: t("tip"),
              //   content: "操作成功",
              // });
              setSnackbar({
                ...snackbar,
                open: true,
                message: "操作成功",
              });
              setReload(!reload);
              setDialog({
                ...dialog,
                open: false,
              });
            } else {
              setDialog({
                ...dialog,
                open: false,
              });
              // confirm({
              //   title: t("tip"),
              //   content: "操作失敗",
              // });
              setSnackbar({
                ...snackbar,
                message: "操作失敗",
                open: true,
                action: (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setSnackbar({ ...snackbar, open: false });
                    }}
                  >
                    確認
                  </Button>
                ),
              });
            }
          };
          operate();
        },
        () => {
          setDialog({
            ...dialog,
            open: false,
          });
        }
      ),
    });
  };

  const customList = (items) => (
    <Paper
      sx={{
        height: 400,
        overflow: "auto",
        border: " 1px solid",
        borderColor: "#dcdcdc",
      }}
    >
      <List dense component="div" role="list">
        {items?.map((value, index) => {
          const labelId = `transfer-list-item-${value}-label`;
          return (
            <div key={index}>
              <ListItem role="listitem">
                {/* 父標簽 */}
                <ListItemIcon onClick={() => handleFather(value, index)}>
                  <Checkbox
                    checked={fatherList.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.parentMenuCnDesc} />
                {value.open ? (
                  <ExpandLess
                    onClick={() => handleClick(items, value, index)}
                  />
                ) : (
                  <ExpandMore
                    onClick={() => handleClick(items, value, index)}
                  />
                )}
              </ListItem>
              <Collapse in={value.open} timeout="auto" unmountOnExit>
                {/* 子列表 */}
                <List component="div" disablePadding>
                  {value.securityMenuTableList.map((item, i) => (
                    <ListItemButton
                      key={i}
                      sx={{ pl: 4 }}
                      onClick={() => handleChlid(value, item, index)}
                    >
                      <Checkbox
                        checked={childList.indexOf(item) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                      <ListItemIcon>{item.menuDescCn}</ListItemIcon>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </div>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={5} md={5}>
        {customList(data?.notInList)}
      </Grid>
      <Grid item xs={2} md={2}>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ m: 1 }}
            variant="contained"
            size="small"
            onClick={() => addMenuList(childList)}
            disabled={left.length === 0}
          >
            <KeyboardArrowRightIcon />
          </Button>

          <Button
            sx={{ m: 1 }}
            variant="contained"
            size="small"
            onClick={() => deleteMenuList(childList)}
            disabled={right.length === 0}
          >
            <KeyboardArrowLeftIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={5} md={5}>
        {customList(data?.inList)}
      </Grid>
      <CustomDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        action={dialog.action}
      />
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        action={snackbar.action}
      />
    </Grid>
  );
}
