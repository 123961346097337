import React, { useState, useRef, useEffect } from 'react'
import {
  Backdrop,
  Button,
  Box,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Select,
} from '@mui/material'
import CustomDialog from 'src/components/common/CustomDialog'
import moment from 'moment'

import { useTranslation } from 'react-i18next'
import PrintIcon from '@mui/icons-material/Print'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ReactToPrint from 'react-to-print'
import { instance } from 'src/utils/instance'
import * as api from 'src/utils/api/workerAttendanceRecord/AutoPayApi'
import { set } from 'lodash'
import GenerateGenerate from './GenerateGenerate'
import PartialBatchGenerate from './PartialBatchGenerate'
import GenerateSubGenerate from './GenerateSubGenerate'
import FanllyGenerate from './FanllyGenerate'
import ErrorTable from './ErrorTable'

export default function Button8(props) {
  const { type, setType, data } = props
  const componentRef = useRef()
  const { t } = useTranslation()
  const [saveData, setSaveData] = useState({})
  const [open, setOpen] = useState(false)
  const [period, setPeriod] = useState([])
  const [shprtPeriod, setShortPeriod] = useState([])
  const [tableList, setTableList] = useState([])
  const [bankData, setBankData] = useState({})
  const [step, setStep] = useState()
  const [sendData, setSendData] = useState({})
  console.log(1111, sendData)
  const [workerData, setWorkerData] = useState({})
  const [peopleCount, setPeopleCount] = useState(0)
  const [createOk, setCreateOk] = useState(false)
  const [createData, setCreateData] = useState({})
  const [printData, setPrintData] = useState({})
  const [payRollType, setPayrollType] = useState('')
  const [dialog, setDialog] = useState({
    content: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        確認
      </Button>
    ),
    open: false,
  })
  const [errorOpen, setErrorOpen] = useState(false)
  const [errorData, setErrorData] = useState({})

  useEffect(() => {
    if (step === 0 || !step) {
      setShortPeriod([])
      setSaveData({
        ...saveData,
        bigPay: null,
        shortPay: null,
        pdDateFrom: null,
        pdDateTo: null,
      })
      setPayrollType(data.payrollType)
    }
    const getBig = async () => {
      let getBigSelect = await api.getBigPeriod()
      if (getBigSelect) {
        setPeriod(getBigSelect)
      }
    }
    if (step === 0 || !step) {
      getBig()
      setCreateOk(false)
    }
    setOpen(false)
  }, [step])

  const handleBigPay = (e) => {
    if (e.target.value) {
      setOpen(true)
      const getShortSelect = async () => {
        let list = await api.getShortPeriod(e.target.value)
        setOpen(false)
        setShortPeriod(list.dateList)
        setPayrollType(list.payrollType)
      }
      getShortSelect()
    }
    setSaveData({
      ...saveData,
      bigPay: e.target.value,
      shortPay: null,
      pdDateFrom: null,
      pdDateTo: null,
    })
  }
  const handleShortPay = (item) => {
    if (payRollType !== 'P') {
      setSaveData({
        ...saveData,
        shortPay: item.value,
        pdDateFrom: item.pdDateFrom || null,
        pdDateTo: item.pdDateTo || null,
      })
    } else {
      if (item.value === 'F') {
        setSaveData({
          ...saveData,
          shortPay: item.value,
          pdDateFrom: item.pdDateFrom || null,
          pdDateTo: item.pdDateTo || null,
          shortBatchPeriod: item.shortBatchPeriod,
        })
      } else {
        setSaveData({
          ...saveData,
          shortPay: item.subBatchId,
          pdDateFrom: item.dateFrom || null,
          pdDateTo: item.dateTo || null,
        })
      }
    }
  }
  const handleCancel = () => {
    setType(0)
  }

  function showTable() {
    if (payRollType === 'P') {
      console.log('PartialBatchGenerate')
      return (
        <PartialBatchGenerate
          setOpen={setOpen}
          createData={createData}
          saveData={saveData}
          createOk={createOk}
          sendData={sendData}
          bankData={bankData}
          handleSubmitChannel={handleSubmitChannel}
          handleSubmitDate={handleSubmitDate}
          handlePrintOut={handlePrintOut}
          handleMpfDownload={handleMpfDownload}
          handleLetterPayrollPrint={handleLetterPayrollPrint}
          handleLetterPayrollDownload={handleLetterPayrollDownload}
          peopleCount={peopleCount}
          tableList={tableList}
          workerData={workerData}
        />
      )
    } else {
      if (saveData.shortPay === 'F' && bankData.batchCounterStr === 1 && payRollType !== 'S') {
        // console.log("GenerateGenerate")
        return (
          <GenerateGenerate
            setOpen={setOpen}
            saveData={saveData}
            createOk={createOk}
            sendData={sendData}
            bankData={bankData}
            handleSubmitChannel={handleSubmitChannel}
            handleSubmitDate={handleSubmitDate}
            handlePrintOut={handlePrintOut}
            handleMpfDownload={handleMpfDownload}
            handleLetterPayrollPrint={handleLetterPayrollPrint}
            handleLetterPayrollDownload={handleLetterPayrollDownload}
            peopleCount={peopleCount}
            tableList={tableList}
            workerData={workerData}
          />
        )
      } else {
        if (saveData.shortPay !== 'F') {
          console.log('GenerateSubGenerate')
          return (
            <GenerateSubGenerate
              setOpen={setOpen}
              saveData={saveData}
              createData={createData}
              createOk={createOk}
              sendData={sendData}
              bankData={bankData}
              handleSubmitChannel={handleSubmitChannel}
              handleSubmitDate={handleSubmitDate}
              handlePrintOut={handlePrintOut}
              handleMpfDownload={handleMpfDownload}
              handleLetterPayrollPrint={handleLetterPayrollPrint}
              handleLetterPayrollDownload={handleLetterPayrollDownload}
              peopleCount={peopleCount}
              tableList={tableList}
              workerData={workerData}
            />
          )
        } else {
          console.log('fanlly')
          return (
            <FanllyGenerate
              setOpen={setOpen}
              saveData={saveData}
              createOk={createOk}
              sendData={sendData}
              bankData={bankData}
              handleSubmitChannel={handleSubmitChannel}
              handleSubmitDate={handleSubmitDate}
              handlePrintOut={handlePrintOut}
              handleMpfDownload={handleMpfDownload}
              handleLetterPayrollPrint={handleLetterPayrollPrint}
              handleLetterPayrollDownload={handleLetterPayrollDownload}
              peopleCount={peopleCount}
              tableList={tableList}
              workerData={workerData}
            />
          )
        }
      }
    }
  }

  //
  const handleSave = () => {
    console.log('i', saveData)
    if (!saveData.bigPay) {
      setDialog({
        ...dialog,
        content: '請選擇大糧期',
        open: true,
      })
      return []
    }
    if (!saveData.shortPay) {
      setDialog({
        ...dialog,
        content: '請選擇小糧期或部分糧期',
        open: true,
      })
      return []
    }
    setOpen(true)
    const doApi = async () => {
      let setPeople = new Set()
      let getDetail = {}
      let getBank = await api.getBankacsetRs(saveData.bigPay, data.vendorSet.vendorId, saveData.shortPay, saveData.pdDateTo, saveData.pdDateFrom)

      console.log(getBank)
      if (payRollType === 'P') {
        console.log('PartialBatchGenerate')
        getDetail = await api.getWorkerDetail(saveData)
      } else {
        if (saveData.shortPay === 'F' && bankData.batchCounterStr === 1 && payRollType !== 'S') {
          console.log('GenerateGenerate')
          getDetail = await api.getWorkerDetail(saveData)
        } else {
          if (saveData.shortPay !== 'F') {
            console.log('GenerateSubGenerate')
            getDetail = await api.getWorkerDetailSub(saveData)
          } else {
            console.log('fanlly')
            getDetail = await api.getWorkerDetailFinall(saveData)
          }
        }
      }
      if (getDetail) {
        setOpen(false)
        setWorkerData(getDetail)
        setTableList(getDetail.WorkerDetailSet)
        for (let item of getDetail.WorkerDetailSet) {
          setPeople.add(item.cardHolderId)
        }
        setPeopleCount(setPeople.size)
      }
      if (getBank) {
        setOpen(false)
        // sendData.submitChannel = getBank.submitchannelset
        setSendData({
          ...sendData,
          submitChannel: getBank.submitchannelset,
          submitDate: getBank.submit_date,
        })
        setBankData(getBank)
      }

      setStep(1)
      setOpen(false)
    }
    doApi()
  }
  const handleBack = () => {
    if (step === 2 || step === 3) {
      setStep(1)
      return
    }
    setStep(0)

    console.log('step', step)
  }
  const handleSubmitDate = (e) => {
    setSendData({ ...sendData, submitDate: e.target.value })
    console.log(e.target.value)
  }
  const handleSubmitChannel = (e) => {
    setSendData({ ...sendData, submitChannel: e.target.value })
  }
  const handleCreateFiles = () => {
    setOpen(true)
    const sendApi = async () => {
      console.log('bankData.submitDate', moment(sendData.submitDate).format('yyyy-MM-DD'))
      console.log('new Date()', moment(new Date()).format('yyyy-MM-DD'))
      console.log(moment(sendData.submitDate).isBefore(new Date()))
      if (!bankData.submit_date && !sendData.submitDate) {
        setDialog({
          ...dialog,
          content: '請輸入自動轉賬授權書的執行日期',
          open: true,
        })
        setOpen(false)
        return
      } else if (bankData.dateController === '1') {
        console.log('saveData123', saveData)
        if (moment(moment(sendData.submitDate).format('YYYY-MM-DD')).isBefore(moment(new Date()).format('YYYY-MM-DD'))) {
          if (bankData.status !== 'C') {
            setDialog({
              ...dialog,
              content: '轉賬日期必須是今天或今天之後',
              open: true,
            })
            setOpen(false)
            return
          }
        }
      }

      // if (!bankData.submit_date && !sendData.submitDate) {
      //     if()
      //   setDialog({
      //     ...dialog,
      //     content: "請輸入自動轉賬授權書的執行日期",
      //     open: true,
      //   });
      //   setOpen(false);
      //   return;
      // }
      let apiData = {
        dateFrom: bankData.mainDate[0].dateFrom,
        submitDate: bankData.submit_date === '' ? moment(new Date(sendData.submitDate).getTime()).format('yyyy/MM/DD') : moment(new Date(sendData.submitDate).getTime()).format('yyyy/MM/DD'),
        submitchannel: sendData.submitChannel,
        vendorId: bankData.vendorData[0]?.vendorId,
        submit_date: moment(new Date(sendData.submitDate).getTime()).format('yyyy/MM/DD'),
      }
      apiData.selectShortBatchPeriod = saveData.shortPay
      let createFile = await api.salaryAndMpfAutopay(apiData)
      if (createFile.data) {
        if (createFile.code === 200) {
          console.log('11111', apiData)
          setDialog({
            ...dialog,
            content: t('create_success'),
            open: true,
          })
          setCreateOk(true)
          setCreateData(createFile.data)
          // setStep(2)
        } else {
          setErrorData(createFile.data)
          setErrorOpen(true)
          // setDialog({
          //     ...dialog,
          //     content: createFile.message,
          //     open: true
          // })
          setCreateOk(false)
          // setStep(2)
        }
      } else {
        setDialog({
          ...dialog,
          content: createFile.message,
          open: true,
        })
      }
      setOpen(false)
    }
    sendApi()
  }

  // 打印工人強積金自動轉賬授權書
  const handlePrintOut = () => {
    setStep(3)
    const mpfPrint = async () => {
      let sendApi = await api.mpfPrint(createData.mpf)
      if (sendApi) {
        if (sendApi.code === 500) {
          setDialog({
            ...dialog,
            content: '請求出錯',
            open: true,
          })
          return []
        }
        setPrintData(sendApi.data || {})
      }
    }
    mpfPrint()
  }

  // 打印工人強積金自動轉賬授權書-----PDF
  const handleMPFPrintPDF = () => {
    const mpfPrint = async () => {
      let sendApi = await api.mpfPrintPDF(printData)
      if (sendApi) {
        if (sendApi.code === 500) {
          setDialog({
            ...dialog,
            content: '請求出錯',
            open: true,
          })
          return []
        }
        // setPrintData(sendApi.data || {})
      }
    }
    mpfPrint()
  }

  // 下載工人強積金自動轉賬授權書
  const handleMpfDownload = () => {
    setOpen(true)
    const download = async () => {
      let sendApi = await api.mpfDownload(createData.mpf)
      if (sendApi) {
        if (sendApi.code === 500) {
          setDialog({
            ...dialog,
            content: '請求出錯',
            open: true,
          })
          return []
        }
      }
      setOpen(false)
    }
    download()
  }

  // 打印工人入息自動轉賬檔案--------pdf
  const handlePrintPayRollPDF = () => {
    const print = async () => {
      let sendApi = await api.letterPayrollPrintPDF(printData)
      if (sendApi) {
        if (sendApi.code === 500) {
          setDialog({
            ...dialog,
            content: '請求出錯',
            open: true,
          })
          return []
        }
      }
    }
    print()
  }
  // 打印工人入息自動轉賬檔案
  const handleLetterPayrollPrint = () => {
    setStep(2)
    const print = async () => {
      let sendApi = await api.letterPayrollPrint(createData.payroll)
      if (sendApi) {
        if (sendApi.code === 500) {
          setDialog({
            ...dialog,
            content: '請求出錯',
            open: true,
          })
          return []
        }
        setPrintData(sendApi.data || {})
      }
    }
    print()
  }
  // 下載工人入息自動轉賬檔案
  const handleLetterPayrollDownload = () => {
    setOpen(true)
    const download = async () => {
      let sendApi = await api.letterPayrollDownload(createData.payroll)
      if (sendApi) {
        if (sendApi.code === 500) {
          setDialog({
            ...dialog,
            content: '請求出錯',
            open: true,
          })
        }
      }
      setOpen(false)
    }
    download()
  }

  return (
    <>
      <ErrorTable setErrorOpen={setErrorOpen} errorOpen={errorOpen} errorData={errorData} />
      <CustomDialog sx={{ whiteSpace: 'pre-line' }} open={dialog.open} title={dialog.title} content={dialog.content} action={dialog.action} />
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {step === 0 || !step ? (
        <Container sx={{ textAlign: 'center' }} maxWidth="sm">
          <Typography variant="h4">
            <b>{t('create_auto-transfer_files')}</b>
          </Typography>
          <TextField
            value={saveData.bigPay || ''}
            onChange={handleBigPay}
            fullWidth
            sx={{ textAlign: 'left', maxWidth: '400px', mt: '30px' }}
            size="small"
            label={t('Big_Pay_Period')}
            variant="outlined"
            select
          >
            {/* <MenuItem key="" value="">None</MenuItem> */}
            {period.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.text}
              </MenuItem>
            ))}
          </TextField>
          <br />
          {payRollType !== 'P' ? (
            <TextField value={saveData.shortPay || ''} fullWidth sx={{ textAlign: 'left', maxWidth: '400px', mt: '20px' }} size="small" label={t('Short_Pay_Period')} variant="outlined" select>
              {shprtPeriod?.map((item, index) => (
                <MenuItem onClick={() => handleShortPay(item)} key={index} value={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <TextField value={saveData.shortPay || ''} fullWidth sx={{ textAlign: 'left', maxWidth: '400px', mt: '20px' }} size="small" label={t('Partial_pay_period')} variant="outlined" select>
              {shprtPeriod.map((item, index) => (
                <MenuItem onClick={() => handleShortPay(item)} key={index} value={item.subBatchId || item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </TextField>
          )}
          <br />
          <Button onClick={handleSave} sx={{ mt: '20px', width: '100px' }} variant="contained">
            {t('button_save')}
          </Button>
          <Button onClick={handleCancel} sx={{ mt: '20px', ml: '10px', width: '100px' }} variant="contained">
            {t('button_cancel')}
          </Button>
        </Container>
      ) : step === 1 ? (
        <Container maxWidth={false}>
          <div style={{ display: 'block', textAlign: 'left', marginTop: '0px' }}>
            <Button sx={{ float: 'left' }} onClick={handleBack}>
              <ArrowBackIcon />
              {t('button_back')}
            </Button>
          </div>

          <div ref={componentRef}>
            <div>
              {showTable()}
              {createOk ? null : (
                <Button variant="contained" sx={{ my: 3 }} onClick={handleCreateFiles}>
                  {' '}
                  {t('sure_create_auto-transfer_files')}
                </Button>
              )}
              <Typography>
                <b style={{ color: 'red' }}>*</b> {t('MPFRemark')}
              </Typography>
            </div>
          </div>
        </Container>
      ) : step === 2 ? (
        <Container>
          {/* 打印工人入息自動轉賬檔案 */}
          <div>
            <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
              <ArrowBackIcon />
              {t('button_back')}
            </Button>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={handlePrintPayRollPDF}>
              <PrintIcon />
              {t('button_print')}
            </Button>
            {/* <ReactToPrint
                                    trigger={() => (
                                        <Button sx={{ float: 'right', mr: '20px' }}>
                                            <PrintIcon />
                                            {t("button_print")}
                                        </Button>
                                    )}
                                    content={() => componentRef.current}
                                    copyStyles
                                    pageStyle={`
                                        @page { margin: 20px 20px !important; }
                                        @media print {
                                            @page {  }
                                        }`}
                                /> */}
          </div>
          <div ref={componentRef}>
            <Typography sx={{ fontSize: '20px', pt: '40px' }}>
              To: The Manager <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;THE BANK OF EAST ASIA, LTD.
              <br />
              <u>
                <b>LETTER OF AUTHORISATION FOR THE MAGNETIC AUTOPAY SERVICE</b>
              </u>
            </Typography>
            <Typography sx={{ fontSize: '20px', pt: '20px' }}>Please process payments in accordance with our diskette enclosed herewith and debit our account with the total.</Typography>
            <TableContainer>
              <Table sx={{ textAlign: 'left', mt: '10px' }} size="small">
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      <b>Account number:</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        textAlign: 'left',
                        borderBottom: 'none',
                      }}
                    >
                      {printData.bankAccount}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      <b>Execution Date :</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        textAlign: 'left',
                        borderBottom: 'none',
                      }}
                    >
                      {printData.datefmtset}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      <b>Total Amount:</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        textAlign: 'left',
                        borderBottom: 'none',
                      }}
                    >
                      {printData.totAmtPay}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        width: 400,
                        borderBottom: 'none',
                      }}
                    >
                      <b>No. of MAS Transactions :</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        textAlign: 'left',
                        borderBottom: 'none',
                      }}
                    >
                      {printData.numTran}
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                                                <TableCell sx={{ fontSize: '20px', width: 200, borderBottom: "none" }}><b>Transactions :</b></TableCell >
                                                <TableCell sx={{ fontSize: '20px', textAlign: 'left', borderBottom: "none" }}>{printData.numTran}</TableCell>
                                            </TableRow> */}
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      <b>Check Sum:</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        textAlign: 'left',
                        borderBottom: 'none',
                      }}
                    >
                      {printData.contents}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      <b>Contact Person:</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        textAlign: 'left',
                        borderBottom: 'none',
                      }}
                    >
                      {printData.bankacset?.contactPerson}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      <b>and Phone No.</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        textAlign: 'left',
                        borderBottom: 'none',
                      }}
                    >
                      ({printData.bankacset?.phoneNumber})
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography sx={{ fontSize: '20px', pt: '20px' }}>
              For And on Behalf of <br /> {printData.bankAccountName}
            </Typography>
            <Divider sx={{ mt: '30px', backgroundColor: '#000000', width: '400px' }} />
            <Typography sx={{ fontSize: '20px', pt: '20px' }}>Authorised Signature(s) and Chop</Typography>
            <Typography sx={{ fontSize: '20px', pt: '20px' }}>=============================================================================================</Typography>
            <Typography sx={{ fontSize: '20px' }}>(FOR BANK USE ONLY)</Typography>
            <Grid container sx={{ mt: '50px' }}>
              <Grid item xs={3}>
                <Divider sx={{ mt: '50px', backgroundColor: '#000000' }} />
                <Typography sx={{ textAlign: 'center' }}>A OFFICER</Typography>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={3}>
                <Divider sx={{ mt: '50px', backgroundColor: '#000000' }} />
                <Typography sx={{ textAlign: 'center' }}>B OFFICER</Typography>
              </Grid>
              <Grid item xs={4}>
                <Divider sx={{ mt: '70px', backgroundColor: '#000000' }} />
              </Grid>
              <Grid item>
                <Typography sx={{ mt: '50px', textAlign: 'center' }}>BRANCH</Typography>
              </Grid>
            </Grid>
            <Typography sx={{ mt: '50px' }}>REMARKS :</Typography>
          </div>
        </Container>
      ) : (
        <Container>
          {/* 打印工人基金檔案 */}
          <div>
            <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
              <ArrowBackIcon />
              {t('button_back')}
            </Button>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={handleMPFPrintPDF}>
              <PrintIcon />
              {t('button_print')}
            </Button>
            {/* <ReactToPrint
                                    trigger={() => (
                                        <Button sx={{ float: 'right', mr: '20px' }}>
                                            <PrintIcon />
                                            {t("button_print")}
                                        </Button>
                                    )}
                                    content={() => componentRef.current}
                                    copyStyles
                                    pageStyle={`
                                        @page { margin: 20px 20px !important; }
                                        @media print {
                                            @page {  }
                                        }`}
                                /> */}
          </div>
          <div ref={componentRef}>
            <Typography sx={{ fontSize: '20px', pt: '40px' }}>
              To: The Manager <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;THE BANK OF EAST ASIA, LTD.
              <br />
              <u>
                <b>LETTER OF AUTHORISATION FOR THE MPF INDUSTRY SCHEME CONTRIBUTIONS</b>
              </u>
            </Typography>
            <Typography sx={{ fontSize: '20px', pt: '20px' }}>Please process our diskette enclosed herewith and make contribution to the Bank of East Asia (Trustees) Limited.</Typography>
            <TableContainer>
              <Table sx={{ textAlign: 'left', mt: '10px' }} size="small">
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        width: 350,
                        borderBottom: 'none',
                      }}
                    >
                      <b>Scheme Number:</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        textAlign: 'left',
                        borderBottom: 'none',
                      }}
                    >
                      {printData.bankacset?.schemeNo}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        width: 350,
                        borderBottom: 'none',
                      }}
                    >
                      <b>Employer (Company) Name :</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        textAlign: 'left',
                        borderBottom: 'none',
                      }}
                    >
                      {printData.bankAccountName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        width: 350,
                        borderBottom: 'none',
                      }}
                    >
                      <b>Employer Code:</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        textAlign: 'left',
                        borderBottom: 'none',
                      }}
                    >
                      {printData.bankacset?.branchCode}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        width: 350,
                        borderBottom: 'none',
                      }}
                    >
                      <b>Expected Upload Date:</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        textAlign: 'left',
                        borderBottom: 'none',
                      }}
                    >
                      {printData.datefmtset}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        width: 350,
                        borderBottom: 'none',
                      }}
                    >
                      <b>Payment Method :</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        textAlign: 'left',
                        borderBottom: 'none',
                      }}
                    >
                      Debit A/C{printData.bankacset?.bankAccount}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        width: 350,
                        borderBottom: 'none',
                      }}
                    >
                      <b>Total Contribution Amount:</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        textAlign: 'left',
                        borderBottom: 'none',
                      }}
                    >
                      {printData.totAmtMpf}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        width: 350,
                        borderBottom: 'none',
                      }}
                    >
                      <b>Check Sum:</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        textAlign: 'left',
                        borderBottom: 'none',
                      }}
                    >
                      {printData.contents}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        width: 350,
                        borderBottom: 'none',
                      }}
                    >
                      <b>Contact Person:</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        textAlign: 'left',
                        borderBottom: 'none',
                      }}
                    >
                      {printData.bankacset?.contactPerson}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        width: 350,
                        borderBottom: 'none',
                      }}
                    >
                      <b>Contact No.</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '20px',
                        textAlign: 'left',
                        borderBottom: 'none',
                      }}
                    >
                      ({printData.bankacset?.phoneNumber})
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography sx={{ fontSize: '20px', pt: '20px' }}>
              For And on Behalf of <br /> {printData.bankAccountName}
            </Typography>
            <Grid container sx={{ mt: '50px' }}>
              <Grid item xs={3}>
                <Divider sx={{ mt: '50px', backgroundColor: '#000000' }} />
                <Typography sx={{ textAlign: 'center' }}>
                  Authorised Signature(s) and Chop
                  <br />
                  <em>As per Debit A/C record</em>
                </Typography>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={3}>
                <Divider sx={{ mt: '50px', backgroundColor: '#000000' }} />
                <Typography sx={{ textAlign: 'center' }}>
                  Authorised Signature(s) and Chop
                  <br />
                  <em>As per MPF record</em>
                </Typography>
              </Grid>
            </Grid>
            <Table
              padding="none"
              sx={{
                mt: '50px',
                '& .MuiTableCell-root': { border: 1 },
                pr: '5px',
              }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3}>(FOR BANK USE ONLY)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ pb: '70px' }}>BRANCH</TableCell>
                  <TableCell sx={{ pb: '70px' }}>A Officer</TableCell>
                  <TableCell sx={{ pb: '70px' }}>B Officer</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3}>
                    Remarks :<br />
                    Collection A/C Reference:99 {printData.bankacset?.schemeNo} {printData.bankacset?.branchCode}
                    <br />
                    Reference File : {printData.mpfExport}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </div>
        </Container>
      )}
    </>
  )
}
