import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {
    Button,
    Container,
    Typography,
    Switch,
    FormControlLabel,
    FormGroup,
    FormLabel,
    FormControl,
} from '@mui/material';
import * as api from "../../../utils/api/systemAdministrator/VendorAutopayReceiptControlApi";
import CustomDialog from "../../../components/common/CustomDialog";


function VendorAutopayReceiptControl() {
    const { t } = useTranslation();
    const [dialog, setDialog] = useState({
        content: "",
        action: (
            <Button
                variant="contained"
                onClick={() => {
                    setDialog({ ...dialog, open: false });
                }}
            >
                {t('Confirm')}
            </Button>
        ),
        open: false,
    });

    const [text, setText] = React.useState("");

    const [state, setState] = React.useState(true);

    // 頁面初始化
    useEffect(() => {
        const selectStatus = async () => {
            let data = await api.selectStatus();
            setState(!(data.data.flag === 'N'));
            handleText(!(data.data.flag === 'N'));
        }
        selectStatus();
    }, [])

    const handleChange = (event) => {
        const changeStatus = async () => {
            let data = await api.updateStatus();
            if (data.data) {
                setState(!event.target.checked);
                handleText(!event.target.checked);
                setDialog({
                    ...dialog,
                    content: returnText(!event.target.checked),
                    open: true
                })
            } else {
                setDialog({
                    ...dialog,
                    content: returnText(event.target.checked),
                    open: true
                })
            }
        }
        changeStatus();
    };
    const handleText = (open) => {
        if (open) {
            setText(t('icon3_can_update_vendor_autopay_receipt'));
        } else {
            setText(t('icon3_can_not_update_vendor_autopay_receipt'));
        }
    };
    const returnText = (open) => {
        if (open) {
            return t('icon3_vendor_autopay_receipt_be') + t('icon3_can_update_vendor_autopay_receipt');
        } else {
            return t('icon3_vendor_autopay_receipt_be') + t('icon3_can_not_update_vendor_autopay_receipt');
        }
    };
    return (
        <>
            {/* 提示框 */}
            <CustomDialog
                sx={{ whiteSpace: "pre-line" }}
                open={dialog.open}
                title={dialog.title}
                content={dialog.content}
                action={dialog.action}
            />
            {/* 標題 */}
            <Container sx={{ textAlign: 'center' }} maxWidth="sm">
                <Typography variant='h4'><b>{t('icon3_update_vendor_autopay_receipt')}</b></Typography>
            </Container>
            {/* 表單控件 */}
            <Container sx={{ textAlign: 'center' }} maxWidth="sm">
                <FormControl sx={{ marginTop: 10 }} component="fieldset" variant="standard">
                    <FormLabel component="legend">{t('icon3_now_status_is') + text}</FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch checked={state} onChange={handleChange} name="open" />
                            }
                            label={text || ''}
                        />
                    </FormGroup>
                </FormControl>
            </Container>
        </>
    )
}

export default VendorAutopayReceiptControl;