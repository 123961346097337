import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as apiAtt from "src/utils/api/workerAttendanceRecord/AutoPayApiAttend";
import * as pfsApi from "src/utils/api/workerAttendanceRecord/PFSApplicationApi";
import $http, { $download } from 'src/utils/api/services'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomDialog from "src/components/common/CustomDialog";


export default function ApplyEditOrView() {
  const componentRef = useRef();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [remarkLenghtValue, setRemarkLenghtValue] = React.useState(0);
  const [contentData, setContentData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [lastAppliedManday, setLastAppliedManday] = React.useState(0);
  const [remark, setRemark] = React.useState("");
  const [applicationSetup, SetApplicationSetup] = React.useState({});

  const handleBack = () => {
    if (searchParams.get("reportName")) {
      navigate(-1);
      return;
    }

    navigate({
      pathname: "/workerAttendanceRecord/adocspMain/applyList/applyListDetail",
      search: `?${createSearchParams({
        smartCardId: headerData.smartCardId,
        siteId: headerData.siteId,
        effectiveDate1: headerData.effectiveDate,
        effectiveDateTo: headerData.effectiveDateTo,
        spApprovedDate: headerData.spApproveDate,
        dateTo: headerData.dateTo || "",
      })}`,
    });
  };

  const handleChange = (event) => {
    const result = event.target.value;
    setRemark(event.target.value);
    setRemarkLenghtValue(result.length);
  };

  const handleCountChange = (event, underData, indexNumber) => {
    let tempmData = contentData;

    tempmData.map((value, index) => {
      if (index === indexNumber) {
        setLastAppliedManday(value.underData.appliedManday);
        value.underData.appliedManday = parseFloat(event.target.value);
      }
      return [];
    });

    setContentData([...tempmData]);
  };

  const handleCheckChange = (event, underData, indexNumber) => {
    if (event.target.value.length === 0 || event.target.value === "e") {
      let tempmData = contentData;

      tempmData.map((value, index) => {
        if (index === indexNumber) {
          value.underData.appliedManday = 0;
        }
        return [];
      });

      setContentData([...tempmData]);
      return;
    }

    if (
      event.target.value > 0 &&
      (event.target.value < applicationSetup.minApplyDay ||
        event.target.value > 1)
    ) {
      setDialog({
        ...dialog,
        content: t("search_number_tips"),
        open: true,
      });

      let tempmData = contentData;

      tempmData.map((value, index) => {
        if (index === indexNumber) {
          value.underData.appliedManday = parseFloat(value.underData.totalManday);
        }
        return [];
      });

      setContentData([...tempmData]);
    }
  };

  function clickSaveBtn() {
    // create
    if (!searchParams.get("applicationId")) {
      let params = {};
      params.siteId = searchParams.get("siteId");
      params.vendorId = searchParams.get("vendorId");
      params.yearmonth = searchParams.get("yearmonth");
      params.effectiveDate = searchParams.get("effectiveDate");
      params.smartCardId = searchParams.get("smartCardId");
      params.appliedRemark = remark;
      params.contentData = contentData;
      createPfsApply(params);
    } else {
      // edit
      let tempList = [];
      contentData.map((value) => {
        let temp = {};
        temp["appliedManday"] = value.underData.appliedManday;
        temp["applicationId"] = searchParams.get("applicationId");
        temp["applicationSeqNo"] = searchParams.get("applicationSeqNo");
        temp["accessDate"] = value.underData.accessDateValue;
        temp["appliedRemark"] = remark;
        tempList.push(temp);
        return [];
      });
      updatePFSApplicationViewForm1(tempList);
    }
  }

  const createPfsApply = async (params) => {
    let data = await pfsApi.createPFSApplicationApply(params);
    if (data.code === 200) {
      alert(t("search_saveSuccess_tips"));
      handleBack();
    } else {
      alert(data.message ? data.message : "請求錯誤！");
    }
  };

  function handleBottomButClick() {
    deletePFSApplicationViewForm1({
      applicationId: searchParams.get("applicationId"),
      applicationSeqNo: searchParams.get("applicationSeqNo"),
    });
  }

  function clickLookDataBtn() {
    navigate({
      pathname:
        "/workerAttendanceRecord/adocspMain/applyList/applyEditOrViewSubmit",
      // isViewData 为1的时候表示是查看数据 为0的时候表示编辑数据
      search: `?${createSearchParams({
        applicationId: searchParams.get("applicationId"),
        applicationSeqNo: searchParams.get("applicationSeqNo"),
      })}`,
    });
  }

  const handleReset = () => {
    setRemark("");
    setRemarkLenghtValue(0);
    let tempData = [...contentData];
    tempData.map((value) => {
      value.underData.appliedManday = null;
      return value;
    });
    setContentData(tempData);
  };

  const [headerData, setHeaderData] = React.useState({});
  const [bottomData, setBottomData] = React.useState([]);
  const [showApproveCol, setShowApproveCol] = React.useState(false);
  const [dialog, setDialog] = useState({
    title: "",
    content: "",
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false });
        }}
      >
        {t("Confirm")}
      </Button>
    ),
    open: false,
  });

  useEffect(() => {
    // create
    if (!searchParams.get("applicationId")) {
      const getPFSApplicationSetup = async () => {
        let data = await apiAtt.getPFSApplicationSetup();
        if (data !== null) {
          SetApplicationSetup(data);
        }
      };

      const getPFSApplicationApplyData = async () => {
        let headerData = await pfsApi.getPFSApplicationApplyHeader(
          searchParams.get("smartCardId"),
          searchParams.get("siteId"),
          searchParams.get("effectiveDate")
        );

        if (headerData && headerData.records) {
          headerData.records[0].yearMonth = searchParams.get("yearmonth");
          setHeaderData({ ...headerData.records[0] });
        }

        let content = await pfsApi.getPFSApplicationApplyList(
          searchParams.get("siteId"),
          searchParams.get("smartCardId"),
          searchParams.get("vendorId"),
          searchParams.get("yearmonth"),
          searchParams.get("effectiveDate")
        );

        if (content && content.length > 0) {
          setContentData(content);
          // Me.CommentsTable.Visible = True
          // Me.SaveBtn.Visible = True
          // Me.ResetBtn.Visible = True
        } else {
          // Me.CommentsTable.Visible = False
          // Me.SaveBtn.Visible = False
          // Me.ResetBtn.Visible = False
        }
        setLoading(false);
      };

      getPFSApplicationSetup();
      setShowApproveCol(false);
      getPFSApplicationApplyData();
    } else {
      const getPFSApplicationSetup = async () => {
        let data = await apiAtt.getPFSApplicationSetup();
        if (data !== null) {
          SetApplicationSetup(data);
        }
      };

      // edit or view
      const getPFSApplicationViewTitle = async () => {
        let data = await apiAtt.getPFSApplicationViewTitle(
          searchParams.get("applicationId"),
          searchParams.get("applicationSeqNo")
        );
        if (data.records !== null) {
          data.records.map((value) => {
            if (value.applicationStatusId === "01") {
              setShowApproveCol(true);
            } else {
              setShowApproveCol(false);
            }
            setHeaderData(value);
            return [];
          });
        }
      };

      const getPFSApplicationViewForm1 = async () => {
        let contentList = await apiAtt.getPFSApplicationViewForm1(
          searchParams.get("applicationId"),
          searchParams.get("applicationSeqNo")
        );
        if (contentList !== null) {
          setContentData(contentList);
        }
      };

      const getPFSApplicationViewForm2 = async () => {
        let contentList = await apiAtt.getPFSApplicationViewForm2(
          searchParams.get("applicationId"),
          searchParams.get("applicationSeqNo")
        );
        if (contentList !== null) {
          setBottomData(contentList.records);
        }
        setLoading(false);
      };
      const getRemarkView = async () => {
        let contentList = await apiAtt.getRemarkView(
          searchParams.get("applicationId")
        );
        if (contentList !== null) {
          setRemark(contentList.appliedRemark);
        }
        setLoading(false);
      };

      getPFSApplicationSetup();
      getPFSApplicationViewTitle();
      getPFSApplicationViewForm1();
      getPFSApplicationViewForm2();
      getRemarkView();
    }
  }, []);

  const updatePFSApplicationViewForm1 = async (underDataList) => {
    let data = await apiAtt.updatePFSApplicationViewForm1(underDataList);
    if (data.code === 200) {
      alert("保存成功");
      handleBack();
    }
  };

  const deletePFSApplicationViewForm1 = async (underDataList) => {
    let data = await apiAtt.deletePFSApplicationViewForm1(underDataList);
    if (data.code === 200) {
      alert("删除成功");
      handleBack();
    }
    console.log("sendData", underDataList);
  };

  const handleInTime = (row) => {
    if (row.inTime !== null) {
      return row.portable === "2" ? "P" : "入";
    }
    return "";
  };
  const handleOutTime = (row) => {
    if (row.outTime !== null) {
      return "出";
    }
    return "";
  };
  // 取模
  function mod(n, m) {
    return ((n % m) + m) % m;
  }
  const handleInOutTime1 = (row) => {
    if (row.outTime === null || row.inTime === null) {
      return "0:00";
    }
    let outMin = 0;
    let inMin = 0;
    let outHour = 0;
    let inHour = 0;
    let modHour = 0;
    let modMin = 0;
    let resultHour = 0;
    let resultMin = 0;
    // let result = parseFloat(row.outTime) - parseFloat(row.inTime);
    outMin = Number(row.outTime.slice(3, 5));
    inMin = Number(row.inTime.slice(3, 5));
    outHour = Number(row.outTime.slice(0, 2));
    inHour = Number(row.inTime.slice(0, 2));
    let totalTime = 0;

    if (outMin < inMin) {
      totalTime = (outHour - inHour) * 60 - inMin;
      totalTime += outMin;

      modMin = mod(totalTime, 60);

      modHour = parseInt(82 / 60, 10);
    } else {
      modMin = outMin - inMin;
      modHour = outHour - inHour;
    }

    //  let result = modHour + ":" + modMin;
    resultHour = modHour;
    resultMin = modMin;

    if (resultHour === 0 && resultMin === 0) {
      return "0:00";
    } else if (resultMin >= 60) {
      if (resultMin / 10 > 1) {
        resultHour += parseInt(resultMin / 60, 10);
        resultMin = mod(resultMin, 60);

        return resultHour + ":" + resultMin;
      }

      if (resultMin / 10 < 1) {
        return resultHour + ":0" + resultMin;
      }
    } else {
      if (resultMin / 10 > 1) {
        resultHour += parseInt(resultMin / 60, 10);
        resultMin = mod(resultMin, 60);

        return resultHour + ":" + resultMin;
      }

      if (resultMin / 10 < 1) {
        return resultHour + ":0" + resultMin;
      }
    }
    // return result;
  };
  const handleInOutTime3 = (contenData) => {
    let resultMax3 = 0;
    if (contenData !== null) {
      contenData.map((value) => {
        if (value.underData.appliedManday) {
          resultMax3 += parseFloat(value.underData.appliedManday);
        }
        return [];
      });
    }

    return resultMax3.toFixed(2);
  };
  const handleInOutTime4 = (contenData) => {
    let resultMax3 = 0;
    if (contenData !== null) {
      contenData.map((value) => {
        resultMax3 += parseFloat(value.underData.approvedManday);
        return [];
      });
    }

    return resultMax3.toFixed(2);
  };

  const handleInOutTime2 = (dataList) => {
    let resultMax3 = 0;
    let resultHour = 0;
    let resultMin = 0;

    dataList.form1List.map((row, index) => {
      if (row.outTime === null || row.inTime === null) {
        return "0:00";
      }
      let outMin = 0;
      let inMin = 0;
      let outHour = 0;
      let inHour = 0;
      let modHour = 0;
      let modMin = 0;
      //  let result = parseFloat(row.outTime) - parseFloat(row.inTime);
      outMin = Number(row.outTime.slice(3, 5));
      inMin = Number(row.inTime.slice(3, 5));
      outHour = Number(row.outTime.slice(0, 2));
      inHour = Number(row.inTime.slice(0, 2));
      let totalTime = 0;

      if (outMin < inMin) {
        totalTime = (outHour - inHour) * 60 - inMin;
        totalTime += outMin;

        modMin = mod(totalTime, 60);

        modHour = parseInt(totalTime / 60, 10);
      } else {
        modMin = outMin - inMin;
        modHour = outHour - inHour;
      }

      resultMin += modMin;
      resultHour += modHour;
      return [];

      // resultMax3 += parseFloat(value.underData.appliedManday);
    });

    if (resultHour === 0 && resultMin === 0) {
      return "0:00";
    } else if (resultMin >= 60) {
      if (resultMin / 10 > 1) {
        resultHour += parseInt(resultMin / 60, 10);
        resultMin = mod(resultMin, 60);

        return resultHour + ":" + resultMin;
      }

      if (resultMin / 10 < 1) {
        return resultHour + ":0" + resultMin;
      }
    } else {
      if (resultMin / 10 > 1) {
        resultHour += parseInt(resultMin / 60, 10);
        resultMin = mod(resultMin, 60);

        return resultHour + ":" + resultMin;
      }

      if (resultMin / 10 < 1) {
        return resultHour + ":0" + resultMin;
      }
      // return resultHour + ":" + resultMin;
    }
  };

  const dispFootnote = (footnote) => {
    return footnote.split(/[\r\n]+/).map((val) => <div>{val}</div>);
  };

  const exportPdf = () => {
    let params = {};
    params.applicationId = searchParams.get("applicationId");
    params.applicationSeqNo = searchParams.get("applicationSeqNo");
    $download(`/attendance/report/getApplyViewPdf`, 'application/pdf', params)
  };

  return (
    <>
      <div style={{ display: "block", textAlign: "left" }}>
        <Button sx={{ float: "left" }} onClick={handleBack}>
          <ArrowBackIcon />
          {t("button_back")}
        </Button>
      </div>
      <div id="div" ref={componentRef} style={{}}>
        <Container maxWidth="xl">
          <div>
            <div style={{ display: "block", marginBottom: "60px" }}>
              <Typography variant="h4" sx={{ textAlign: "center" }}>
                <b>
                  {searchParams.get("reportName")
                    ? searchParams.get("reportName")
                    : t("search_vendor_ss_outDays")}
                </b>
              </Typography>
            </div>

            {headerData.siteDesc && (
              <div>
                <TableContainer
                  component={Paper}
                  sx={{ marginBottom: "20px", backgroundColor: "transparent" }}
                >
                  <Table sx={{ textAlign: "left" }} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_site_title") + "："}{" "}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.siteId} - {headerData.siteDesc}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("Vendor") + "："}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.vendorDesc}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_subcontractor_dEmployer")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.subContractorDesc}
                        </TableCell>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_work_type_dot")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.jobNatureDesc}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_smartCardId_dot")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.smartCardId}
                        </TableCell>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_date_dot")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.yearMonth}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_chinese_name_dot")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.chineseName}
                        </TableCell>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_english_name_dot")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.englishName}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_employment_position")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.safetyPositionDesc}
                        </TableCell>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_approve_date_dot")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.spApproveDate}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_in_efficient_date")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.effectiveDate}
                        </TableCell>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_in_valite_date_to")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.effectiveDateTo}
                        </TableCell>
                      </TableRow>
                      {searchParams.get("applicationId") && !searchParams.get("reportName") &&
            searchParams.get("isViewData") === "true" &&
            <TableRow>
            <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
              {t("search_statues")}
            </TableCell>
            <TableCell sx={{ px: "5px", border: 0 }}>
              {headerData.statusDesc}
            </TableCell>
          </TableRow>}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <div>
              <TableContainer sx={{ marginTop: "60px" }}>
                <Table sx={{ minWidth: 650, p: "2px" }} size="small">
                  <TableHead>
                    <TableRow sx={{ border: "1px" }}>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {t("search_inOut_date")}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {t("search_in_times")}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {t("search_in_out")}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {t("search_out_times")}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {t("search_in_out")}
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                      >
                        {t("search_site_times")}
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                      >
                        {t("search_site_days")}
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                      >
                        {t("search_vendor_site_totalWorker")}
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                      >
                        {t("search_apply_day")}
                      </TableCell>
                      {showApproveCol && (
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {t("search_approve_day")}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>

                  {contentData.map((dataList, index) => (
                    <TableBody key={index + Math.random}>
                      {dataList.form1List.map((row, index) => (
                        <TableRow key={index + Math.random}>
                          <TableCell>
                            {index === 0 && row.accessDateText}
                          </TableCell>
                          <TableCell>{row.inTime}</TableCell>
                          <TableCell>{handleInTime(row)}</TableCell>
                          <TableCell>{row.outTime}</TableCell>
                          <TableCell>{handleOutTime(row)}</TableCell>
                          <TableCell sx={{ textAlign: "right" }}>
                            {handleInOutTime1(row)}
                          </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          {showApproveCol && <TableCell> </TableCell>}
                        </TableRow>
                      ))}

                      <TableRow>
                        <TableCell> </TableCell>
                        <TableCell> </TableCell>
                        <TableCell> </TableCell>
                        <TableCell> </TableCell>
                        <TableCell>
                          <b>{t("search_subtotal")}</b>
                        </TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                          {handleInOutTime2(dataList)}
                        </TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                          {dataList.underData.totalManday ? dataList.underData.totalManday
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "0.00"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                          {dataList.underData.vendorDailyAttendance}
                        </TableCell>
                        {(!searchParams.get("applicationId") ||
                          searchParams.get("isViewData") === "false") && (
                          <TableCell sx={{ textAlign: "right" }}>
                            <TextField
                              type="number"
                              size="small"
                              error={
                                dataList.underData.appliedManday !==
                                dataList.underData.totalManday
                              }
                              sx={{ width: "7ch" }}
                              inputProps={{ style: { height: 10 } }}
                              value={dataList.underData.appliedManday}
                              onChange={(e) =>
                                handleCountChange(e, dataList.underData, index)
                              }
                              onBlur={(e) =>
                                handleCheckChange(e, dataList.underData, index)
                              }
                            />
                          </TableCell>
                        )}
                        {searchParams.get("applicationId") &&
                          searchParams.get("isViewData") === "true" && (
                            <TableCell
                              sx={{
                                textAlign: "right",
                                color:
                                  dataList.underData.appliedManday !==
                                  dataList.underData.totalManday
                                    ? "red"
                                    : "black",
                              }}
                            >
                              {dataList.underData.appliedManday
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                            </TableCell>
                          )}
                        {showApproveCol && (
                          <TableCell sx={{ textAlign: "right",  color:
                          dataList.underData.appliedManday !==
                          dataList.underData.approvedManday
                            ? "red"
                            : "black"}}>
                            {" "}
                            {dataList.underData.approvedManday
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  ))}

                  <TableBody>
                    <TableRow>
                      <TableCell> </TableCell>
                      <TableCell> </TableCell>
                      <TableCell> </TableCell>
                      <TableCell> </TableCell>
                      <TableCell> </TableCell>
                      <TableCell> </TableCell>
                      <TableCell> </TableCell>
                      <TableCell sx={{ fontSize: "18px", textAlign: "right" }}>
                        <b>{t("search_total_dot")}</b>
                      </TableCell>
                      {searchParams.get("applicationId") &&
                        searchParams.get("isViewData") === "true" && (
                          <TableCell
                            sx={{ fontSize: "18px", textAlign: "right" }}
                          >
                            <b>{handleInOutTime3(contentData)}</b>
                          </TableCell>
                        )}
                      {(!searchParams.get("applicationId") ||
                        searchParams.get("isViewData") === "false") && (
                        <TableCell
                          sx={{ fontSize: "18px", textAlign: "right" }}
                        >
                          <b>{handleInOutTime3(contentData)}</b>
                        </TableCell>
                      )}
                      {showApproveCol && (
                        <TableCell
                          sx={{ fontSize: "18px", textAlign: "right" ,  color:
                          handleInOutTime3(contentData) !==
                          handleInOutTime4(contentData)
                            ? "red"
                            : "black"}}
                        >
                          <b>{handleInOutTime4(contentData)}</b>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            {((!searchParams.get("applicationId") &&
              contentData &&
              contentData.length > 0) ||
              searchParams.get("isViewData") === "false") && (
              <div>
                <TableContainer
                  sx={{ marginBottom: "20px", backgroundColor: "transparent" }}
                >
                  <Table sx={{ textAlign: "left" }} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "15px",
                            px: "5px",
                            width: 160,
                            border: 0,
                          }}
                        >
                          {t("search_remark_dot")}
                          <br />
                          (Max 100 chars)
                          <br />
                          Size：
                          <TextField
                            sx={{ width: "10ch", height: "1ch" }}
                            id="outlined-multiline-static"
                            rows={3}
                            value={remarkLenghtValue}
                            defaultValue={0}
                          />
                          chars
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "15px", px: "5px", border: 0 }}
                        >
                          <TextField
                            sx={{ width: "50ch" }}
                            id="outlined-multiline-static"
                            multiline
                            rows={3}
                            inputProps={{ maxLength: 100 }}
                            value={remark || ""}
                            onChange={handleChange}
                          />{" "}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "18px",
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      background: "yellow",
                      marginRight: 10,
                    }}
                    onClick={(e) => clickSaveBtn(e)}
                  >
                    {t("Save")}
                  </Button>
                  {!searchParams.get("applicationId") ? (
                    <Button
                      variant="outlined"
                      size="small"
                      style={{
                        background: "yellow",
                        marginRight: 10,
                      }}
                      onClick={handleReset}
                    >
                      {t("button_reset")}
                    </Button>
                  ) : null}

                  {searchParams.get("applicationId") &&
                  searchParams.get("isViewData") === "false" ? (
                    <>
                      <Button
                        variant="outlined"
                        size="small"
                        style={{
                          background: "yellow",
                          marginRight: 10,
                        }}
                        onClick={(e) => handleBottomButClick(e)}
                      >
                        {t("button_delete")}
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        style={{
                          background: "yellow",
                          marginRight: 10,
                        }}
                        onClick={(e) => clickLookDataBtn()}
                      >
                        {t("search_browse_title")}
                      </Button>
                    </>
                  ) : null}
                </div>
              </div>
            )}

            {searchParams.get("applicationId") &&
              searchParams.get("isViewData") === "true" && (
                <TableContainer
                  sx={{ marginBottom: "18px", marginTop: "60px" }}
                >
                  <Table sx={{ minWidth: 650, p: "2px" }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {t("search_name_title")}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {t("search_statues_not_dot")}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {t("search_date_not_dot")}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {t("search_remark_not_dot")}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {bottomData.map((row, index) => (
                      <TableBody key={index + Math.random}>
                        <TableRow>
                          <TableCell>{row.userId}</TableCell>
                          <TableCell>{row.status}</TableCell>
                          <TableCell>{row.dateTime}</TableCell>
                          <TableCell>{row.remarks}</TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                  </Table>
                </TableContainer>
              )}
            <CustomDialog
              open={dialog.open}
              title={dialog.title}
              content={dialog.content}
              action={dialog.action}
            />

            {searchParams.get("applicationId") && !searchParams.get("reportName") &&
            searchParams.get("isViewData") === "true" ? (
              <div style={{ textAlign: "center" }}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={exportPdf}
                  style={{
                    background: "yellow",
                    marginRight: 10,
                  }}
                >
                  {t("PDF")}
                </Button>
              </div>
            ) : null}

            {headerData.siteDesc && (
              <div>
                <Typography sx={{ textAlign: "left", marginTop: "60px" }}>
                  {dispFootnote(applicationSetup.footnote)}
                </Typography>
              </div>
            )}

            {/* <Dialog fullScreen onClose={handleCloseSubmitView} open={showSubmitView}>
              <RptSafetyBtn2MainDayListDetailEditOrViewSubmit
                setShowSubmitView={setShowSubmitView} 
                propsData={propsData}
              >3</RptSafetyBtn2MainDayListDetailEditOrViewSubmit>
            </Dialog> */}
          </div>
        </Container>
      </div>
    </>
  );
}
