import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IconButton,
  Container,
  Grid,
  Box,
  FormControl,
  Button,
  MenuItem,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  tableCellClasses,
  FormLabel,
  TableFooter,
  TablePagination,
} from '@mui/material'
import html2canvas from 'html2canvas'
import { styled, useTheme } from '@mui/material/styles'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import LastPageIcon from '@mui/icons-material/LastPage'
import moment from 'moment'
import JSPDF from 'jspdf'
import * as echarts from 'echarts'
import ReactEcharts from 'echarts-for-react'
import $http, { $download } from 'src/utils/api/services'
import * as api from '../../../utils/api/vendorAutopaySystem/AnalysischartMonthlyApi'

const ReportCenterTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    fontSize: '15px',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'center',
  },
}))

const ReportLeftTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    backgroundColor: '#c6c6c6',
    padding: '3px',
    fontSize: '14px',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'Left',
    padding: '3px',
    fontSize: '13px',
  },
}))

const ReportRightTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    fontSize: '14px',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'right',
    padding: '3px',
    fontSize: '13px',
  },
}))

function TablePaginationActions(props) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

// 承判商自動轉帳出糧人數及工數分析圖表(每月)

export default function AnalysischartMonthly() {
  const [isSearchPage, setIsSearchPage] = useState(true)
  const { t } = useTranslation()
  const [backOpen, setBackOpen] = useState(false)
  const [headList, setHeadList] = useState([])
  const [dateList, setDateList] = useState([])
  const [optionData, setOptionData] = useState([])
  const [sendData, setSendData] = useState({
    chartColor: 'color',
  })
  const [tableData, setTableData] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)

  // const data = [
  //     {
  //         "withoutautopayjobnaturedesc": "閘板",
  //         "withautopayjobnaturedesc": "閘板",
  //         "withautopayvendordesc": "NG CHIU CONSTRUCTION CO LTD",
  //         "withoutautopayvendordesc": "NG CHIU CONSTRUCTION CO LTD",
  //         "workdayactwithautopay": 9,
  //         "workdayactwithoutautopay": 0,
  //         "totalworkdayact": 9,
  //         "percentageofgrainworkers": 100,
  //         "salarywithautopay": 12916,
  //         "averagesalary": 1435.111111111111,
  //         "workerswithautopay": 3,
  //         "workerswithoutautopay": 0,
  //         "totalworkers": 3,
  //         "percentageoffoodcontributors": 100,
  //         "workerswithoutautopaypercent": 0
  //     },
  //     {
  //         "withoutautopayjobnaturedesc": "閘板",
  //         "withautopayjobnaturedesc": "閘板",
  //         "withautopayvendordesc": "KUEN CHEONG CONSTRUCTION & ENGINEERING CO LTD",
  //         "withoutautopayvendordesc": "KUEN CHEONG CONSTRUCTION & ENGINEERING CO LTD",
  //         "workdayactwithautopay": 1997,
  //         "workdayactwithoutautopay": 0,
  //         "totalworkdayact": 1997,
  //         "percentageofgrainworkers": 100,
  //         "salarywithautopay": 3452949,
  //         "averagesalary": 1729.0681021532298,
  //         "workerswithautopay": 90,
  //         "workerswithoutautopay": 6,
  //         "totalworkers": 96,
  //         "percentageoffoodcontributors": 93.75,
  //         "workerswithoutautopaypercent": 6.25
  //     },
  //     {
  //         "withoutautopayjobnaturedesc": "閘板",
  //         "withautopayjobnaturedesc": "閘板",
  //         "withautopayvendordesc": "YIU MAN DEVELOPMENT CO LTD",
  //         "withoutautopayvendordesc": "YIU MAN DEVELOPMENT CO LTD",
  //         "workdayactwithautopay": 294,
  //         "workdayactwithoutautopay": 0,
  //         "totalworkdayact": 294,
  //         "percentageofgrainworkers": 100,
  //         "salarywithautopay": 532587,
  //         "averagesalary": 1811.5204081632653,
  //         "workerswithautopay": 15,
  //         "workerswithoutautopay": 3,
  //         "totalworkers": 18,
  //         "percentageoffoodcontributors": 83.33333333333333,
  //         "workerswithoutautopaypercent": 16.666666666666668
  //     },
  //     {
  //         "withoutautopayjobnaturedesc": "閘板",
  //         "withautopayjobnaturedesc": "閘板",
  //         "withautopayvendordesc": "YIU MAN DEVELOPMENT CO LTD",
  //         "withoutautopayvendordesc": "YIU MAN DEVELOPMENT CO LTD",
  //         "workdayactwithautopay": 294,
  //         "workdayactwithoutautopay": 0,
  //         "totalworkdayact": 294,
  //         "percentageofgrainworkers": 100,
  //         "salarywithautopay": 532587,
  //         "averagesalary": 1811.5204081632653,
  //         "workerswithautopay": 15,
  //         "workerswithoutautopay": 3,
  //         "totalworkers": 18,
  //         "percentageoffoodcontributors": 83.33333333333333,
  //         "workerswithoutautopaypercent": 16.666666666666668
  //     }
  // ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const contentRef = useRef()

  useEffect(() => {
    setBackOpen(true)
    const getData = async () => {
      let getHeadList = await api.getfilljobNatureId()
      let getdateList = await api.getPeriodYear()
      if (getHeadList) {
        setHeadList(getHeadList)
      }
      if (getdateList.length !== 0) {
        setDateList(getdateList)
      } else {
        getdateList.splice(0, 0, { id: '', text: 'nothing' })
        setDateList([...getdateList])
      }
      setSendData({
        jobNatureId: '00',
        jobNatureDesc: '00 - NIL',
        chartColor: 'color',
      })

      setBackOpen(false)
    }
    getData()
  }, [])

  const handleBack = () => {
    setIsSearchPage(true)
  }

  const handleHeadLine = (data) => {
    setSendData({
      ...sendData,
      jobNatureId: data.id,
      jobNatureDesc: data.text,
    })
  }

  const handleDate = (data) => {
    setSendData({ ...sendData, dateFrom: data.id })
  }

  const handleChartColor = (e) => {
    setSendData({ ...sendData, chartColor: e.target.value })
    console.log(e.target.value)
  }
  const exportPdf = (data) => {
    $download(`/payrollReportController/getRPTworkersautopaystatustradelistPdf`, 'application/pdf', data)
  }

  const exportExcel = (data) => {
    $download(`/payrollReportController/getRPTworkersautopaystatustradelistExcel`, 'application/vnd.ms-excel', data)
  }

  const handleSubmit = (e) => {
    if (!sendData.dateFrom) {
      alert('请选择日期')
      return
    }
    console.log(sendData)
    let charList = []
    const submit = async () => {
      setBackOpen(true)
      let send = await api.submitData(sendData)
      if (send) {
        setIsSearchPage(false)
        setTableData(send)
        let x = []

        for (let i = 0; i < send.length; i++) {
          x.push(i + 1)
        }
        //   console.log("s", x);
        let arr1 = []
        let arr2 = []
        let obj = {}
        let vendorDesc = []
        send.map((row, i) => {
          arr1.push(row.percentageofgrainworkers.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))
          arr2.push(row.percentageoffoodcontributors.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))
          vendorDesc.push(row.withautopayvendordesc)

          return ''
        })
        // console.log("arr1", arr1);
        //   console.log("arr2", sendData.jobNatureDesc.split("-"));
        let numr
        if (sendData.jobNatureDesc) {
          numr = sendData.jobNatureDesc.split('-')
        }
        console.log(numr)

        // let myChart1

        // myChart1 = echarts.init(document.getElementById('chart-visitTrend'))

        obj = {
          // color: ["#000000", "#123456"],
          title: {
            text: t('AnalysischartMonthly') + (sendData.dateFrom.substring(0, 7) + '--' + (sendData.jobNatureDesc === 'All' ? 'All' : sendData.jobNatureDesc.substring(5))),
            //   sendData?.jobNatureDesc.splice("-")[1],
          },

          grid: {
            right: '25%',
            bottom: '33%',
            width: '50%',
          },

          legend: {
            data: ['出糧人數百分比/%', '出糧工數百分比/%'],
          },
          xAxis: {
            type: 'category',
            data: x,
          },
          yAxis: {
            type: 'value',
            name: t('Percentage'),
          },
          toolbox: {
            // 保存图片
            show: true,
            orient: 'vertical',
            itemSize: 12,
            feature: {
              saveAsImage: {
                name: '实时调用下载',
              },
            },
          },
          series: [
            {
              name: '出糧人數百分比/%',
              data: arr2,
              type: 'bar',
              smooth: true,
              barWidth: '30%',
              itemStyle: {
                color: sendData.chartColor !== 'color' ? 'black' : '#67E0E3',
              },
            },
            {
              name: '出糧工數百分比/%',
              data: arr1,
              type: 'bar',
              smooth: true,
              barWidth: '30%',
              itemStyle: {
                color: sendData.chartColor !== 'color' ? 'gray' : '#37A2DA',
              },
            },
          ],
          tooltip: {
            trigger: 'axis',
          },
        }

        // let picBase64Info = myChart1.getDataURL()
        // let con = getCondition() // 其它条件
        // con['picBase64Info'] = picBase64Info

        // let form = document.createElement('form')
        // form.style.display = 'none'
        // form.action = '/reportform/exportform'
        // form.method = 'post'
        // document.body.append(form)

        // for (let i = 0; i < keypairs.length; i++) {
        //   let input = document.createElement('input')
        //   input.type = 'hidden'
        //   input.name = key
        //   input.value = con[key]

        //   let form111 = document.getElementById('yourFormId') // 请将"yourFormId"替换为你的表单的id
        //   form.target = 'exportFrame'
        //   form.appendChild(input)
        // }

        // form111.submit()
        // form111.remove()

        //    charList.push(obj);
        setOptionData(obj)

        //    console.log("charList", charList);
        setBackOpen(false)
        setIsSearchPage(false)
        return ''
      } else {
        alert('error')
      }
      setBackOpen(false)
    }
    submit()
  }

  const resetForm = (e) => {
    setSendData({})
    setSendData({
      jobNatureId: '00',
      jobNatureDesc: '00 - NIL',
      chartColor: 'color',
    })
  }

  // 打印
  const handlePrint1 = async (htmlTitle, currentTime) => {
    let element = document.getElementById('contentRef')
    html2canvas(element, {
      logging: false,
    }).then(function (canvas) {
      let pdf = new JSPDF('l', 'mm', 'a4') // A4纸，纵向
      let ctx = canvas.getContext('2d')
      let a4w = 275
      let a4h = 300 // A4大小，210mm x 297mm，四边各保留20mm的边距
      let imgHeight = Math.floor((a4h * canvas.width) / a4w) // 按A4显示比例换算一页图像的像素高度
      let renderedHeight = 0

      while (renderedHeight < canvas.height) {
        let page = document.createElement('canvas')
        page.width = canvas.width
        page.height = Math.min(imgHeight, canvas.height - renderedHeight) // 可能内容不足一页 // 用getImageData剪裁指定区域，并画到前面创建的canvas对象中

        page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0)
        pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 10, a4w, Math.min(a4h, (a4w * page.height) / page.width)) // 添加图像到页面，保留10mm边距

        renderedHeight += imgHeight
        if (renderedHeight < canvas.height) {
          pdf.addPage()
        } // 如果后面还有内容，添加一个空页 // delete page;
      }
      pdf.save(htmlTitle + currentTime)
    })
  }

  // 承判商自動轉賬支薪報告
  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div id="contentRef">
        {isSearchPage && (
          <form>
            <Container maxWidth="md" sx={{ textAlign: 'center' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4" style={{ textAlign: 'center' }}>
                    <b>{t('AnalysischartMonthly')}</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField sx={{ textAlign: 'left', maxWidth: '400px' }} value={sendData.jobNatureId || ' '} fullWidth size="small" label={t('Head_of_line')} select>
                    {/* <MenuItem
                    value=" "
                    onClick={() => handleHeadLine({ id: " ", text: "All" })}
                  >
                    {" "}
                    {t("all_outfits")}
                  </MenuItem> */}
                    <MenuItem value=" " onClick={() => handleHeadLine({ id: '', text: 'All' })}>
                      {' '}
                      {t('all_outfits')}
                    </MenuItem>
                    {headList.map((item, index) => (
                      <MenuItem key={index} value={item.id} onClick={() => handleHeadLine(item)}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField error={!sendData.dateFrom} sx={{ textAlign: 'left', maxWidth: '400px' }} value={sendData.dateFrom || ''} fullWidth size="small" label={t('date')} select>
                    {dateList.map((item, index) => (
                      <MenuItem key={index} value={item.id} onClick={() => handleDate(item)}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField sx={{ textAlign: 'left', maxWidth: '400px' }} onChange={handleChartColor} value={sendData.chartColor || 'color'} fullWidth size="small" label={t('Chart_Color')} select>
                    <MenuItem value="color">{t('Color')}</MenuItem>
                    <MenuItem value="black">{t('Black_and_White')}</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                      backgroundColor: 'primaryBlue',
                      color: 'commom.white',
                    }}
                  >
                    {t('button_submit')}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={resetForm}
                    sx={{
                      marginLeft: '20px',
                      backgroundColor: 'primaryGreen',
                      color: 'commom.white',
                      marginRight: '10px',
                    }}
                  >
                    {t('button_reset')}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </form>
        )}

        {!isSearchPage && (
          <Container maxWidth={false}>
            <div
              style={{
                display: 'block',
                textAlign: 'left',
                marginTop: '0px',
              }}
            >
              <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
                <ArrowBackIcon />
                {t('button_back')}
              </Button>
              <Button sx={{ float: 'right', mr: '20px' }} onClick={() => handlePrint1(t('AnalysischartMonthly'), new Date())}>
                <DownloadIcon />
                {t('列印')}
              </Button>
            </div>
            <div style={{ display: 'block', textAlign: 'right' }}>
              <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportExcel(sendData)}>
                <DownloadIcon />
                {t('button_export_excel')}
              </Button>
              <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportPdf(sendData)}>
                <DownloadIcon />
                {t('button_export_pdf')}
              </Button>
            </div>
            <Typography variant="h4" style={{ textAlign: 'center' }}>
              <b>{t('AnalysischartMonthly')}</b> ({sendData.dateFrom.substring(0, 7)} -- {sendData.jobNatureDesc === 'All' ? 'All' : sendData.jobNatureDesc.substring(5)})
            </Typography>
            <Typography sx={{ my: '10px' }}>
              {t('Print_Date')}:{moment(new Date()).format('yyyy/MM/DD')}
            </Typography>

            <TableContainer sx={{ mb: '20px', maxHeight: '80vh', maxWidth: '100vw' }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <ReportCenterTableCell />
                    <ReportCenterTableCell>{t('Contractor_Name')}</ReportCenterTableCell>
                    <ReportCenterTableCell>{t('Head_of_line')}</ReportCenterTableCell>
                    <ReportCenterTableCell sx={{ textAlign: 'right' }}>{t('Non_participants_Number_of_participants')}</ReportCenterTableCell>
                    <ReportCenterTableCell sx={{ textAlign: 'right' }}>{t('Participants_Number_of_participants')}</ReportCenterTableCell>
                    <ReportCenterTableCell sx={{ textAlign: 'right' }}>{t('Total_Number')}</ReportCenterTableCell>
                    <ReportCenterTableCell sx={{ textAlign: 'right' }}>{t('Percentage')}</ReportCenterTableCell>
                    <ReportCenterTableCell sx={{ textAlign: 'right' }}>{t('Non_participating_Workers')}</ReportCenterTableCell>
                    <ReportCenterTableCell sx={{ textAlign: 'right' }}>{t('Number_of_workers_involved')}</ReportCenterTableCell>
                    <ReportCenterTableCell sx={{ textAlign: 'right' }}>{t('Total_number_of_workers')}</ReportCenterTableCell>
                    <ReportCenterTableCell sx={{ textAlign: 'right' }}>{t('Percentage')}</ReportCenterTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage.length > 0 ? rowsPerPage.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tableData).map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: index % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                      }}
                    >
                      <TableCell sx={{ textAlign: 'right' }}>{index + 1}</TableCell>
                      <TableCell>{row.withautopayvendordesc}</TableCell>
                      <TableCell>{row.withautopayjobnaturedesc}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{row.workerswithoutautopay}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{row.workerswithautopay}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{row.totalworkers}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{row.percentageoffoodcontributors.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}%</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{row.workdayactwithoutautopay}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{row.workdayactwithautopay}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{row.totalworkdayact}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{row.percentageofgrainworkers}%</TableCell>
                    </TableRow>
                  ))}
                  {/* <TableRow>
                  <TableCell colSpan={11}>
                    {optionData.map((row, index) => (
                      <ReactEcharts key={index} option={row} />
                    ))}
                  </TableCell>
                </TableRow> */}
                  <TableRow>
                    <TableCell colSpan={11}>
                      <ReactEcharts option={optionData} />
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                  <TableCell colSpan={11}>
                    <ReactEcharts key={index} option={row} />
                  </TableCell>
                </TableRow> */}
                </TableBody>
                {/* <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    // colSpan={3}
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      //  native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter> */}
              </Table>
            </TableContainer>
          </Container>
        )}
      </div>
    </>
  )
}
