import React, { useRef, useState, useEffect } from 'react'
import { Container, Typography, Paper, Table, TableBody, TableHead, TableCell, TableContainer, Link, TableRow, Backdrop, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSearchParams, useNavigate, createSearchParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import DownloadIcon from '@mui/icons-material/Download'
import $http, { $download } from 'src/utils/api/services'
import moment from 'moment'
import * as api from '../../../utils/api/vendorAutopaySystem/WorkerSalaryMpfSafetyApi'

// //
export default function WSMSDetail2() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [mainData, setMainData] = React.useState([])
  const [dataSource, setDataSource] = React.useState({})
  const [loading, setLoading] = React.useState(true)

  useEffect(() => {}, [])

  const handleBack = () => {
    navigate(-1)
    navigate({
      pathname: '/vendorAutopaySystem/WorkerSalaryMpfSafety',
      search: `?${createSearchParams(
        {
          dateFrom: searchParams.get('dateFromId'),
          dateTo: searchParams.get('dateToId'),
          siteId: searchParams.get('siteId'),
          siteDesc: searchParams.get('siteDesc'),
          vendorDesc: searchParams.get('vendorDesc'),
          vendorId: searchParams.get('vendorId'),
          reportType: searchParams.get('reportType'),
        },
        { state: { fromSpecificPage: true } },
      )}`,
    })
  }

  const printComponentRef = useRef()

  useEffect(() => {
    getRPTworkersautopayMPFpfsdtl()
  }, [])

  const getRPTworkersautopayMPFpfsdtl = async () => {
    let list = {}

    list = await api.getRPTworkersautopayMPFpfsdtl({
      siteId: searchParams.get('siteId'),
      siteDesc: searchParams.get('siteDesc'),
      vendorId: searchParams.get('vendorId'),
      vendorDesc: searchParams.get('vendorDesc'),
      dateFrom: searchParams.get('dateFromId'),
      dateTo: searchParams.get('dateToId'),
      reportType: searchParams.get('reportType'),
    })

    if (list !== null) {
      console.log(list)
      setMainData(list.mainData)
      setDataSource(list)
    }
    setLoading(false)
  }

  const exportExcel = (data) => {
    $download(`/payrollReportController/getRPTworkersautopayMPFpfsdtlExcel`, 'application/vnd.ms-excel', data)
  }

  const exportPdf = (data) => {
    $download(`/payrollReportController/getRPTworkersautopayMPFpfsdtlPdf`, 'application/pdf', data)
  }

  // t("search_vendor_autoPay_statues")
  return (
    <Container>
      <div style={{ display: 'block', textAlign: 'left' }}>
        <Button sx={{ float: 'left' }} onClick={handleBack}>
          <ArrowBackIcon />
          {t('button_back')}
        </Button>
      </div>

      <div style={{ display: 'block', textAlign: 'right' }}>
        <Button
          sx={{ float: 'right', mr: '20px' }}
          onClick={() =>
            exportPdf({
              siteId: searchParams.get('siteId'),
              siteDesc: searchParams.get('siteDesc'),
              vendorId: searchParams.get('vendorId'),
              vendorDesc: searchParams.get('vendorDesc'),
              dateFrom: searchParams.get('dateFromId'),
              dateTo: searchParams.get('dateToId'),
            })
          }
        >
          <DownloadIcon />
          {t('button_export_pdf')}
        </Button>
      </div>

      <div style={{ display: 'block', textAlign: 'right' }}>
        <Button
          sx={{ float: 'right', mr: '20px' }}
          onClick={() =>
            exportExcel({
              siteId: searchParams.get('siteId'),
              siteDesc: searchParams.get('siteDesc'),
              vendorId: searchParams.get('vendorId'),
              vendorDesc: searchParams.get('vendorDesc'),
              dateFrom: searchParams.get('dateFromId'),
              dateTo: searchParams.get('dateToId'),
            })
          }
        >
          <DownloadIcon />
          {t('button_export_excel')}
        </Button>
      </div>

      <div id="div" style={{}} ref={printComponentRef}>
        <Container>
          <div>
            <div style={{ display: 'block', marginBottom: '60px' }}>
              <Typography variant="h4" sx={{ textAlign: 'center' }}>
                <b>{t('icon_vas_wsms_report_title_safety')}</b>
              </Typography>
            </div>

            <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>

            {
              <div>
                <TableContainer sx={{ marginBottom: '20px', backgroundColor: 'transparent' }}>
                  <Table sx={{ textAlign: 'left' }} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>
                          {t('Contractor')} : {''}
                          {searchParams.get('vendorDesc') !== null && searchParams.get('vendorDesc') !== '' && searchParams.get('vendorDesc') !== 'null'
                            ? searchParams.get('vendorDesc')
                            : t('icon_vas_wsmszo_vendor')}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>
                          {t('search_site_title2')} : {''}
                          {searchParams.get('siteId') !== null && searchParams.get('siteId') !== '' && searchParams.get('siteId') !== 'null' ? searchParams.get('siteId') : t('All_site1')}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('icon_vas_wsms_date_from') + '：' + searchParams.get('dateFromText')}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('icon_vas_wsms_date_to') + '：' + searchParams.get('dateToText')}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }
          </div>

          {/* table */}
          <TableContainer sx={{ marginTop: '20px', maxHeight: '80vh', maxWidth: '100vw' }}>
            <Table sx={{ p: '2px' }} size="small" stickyHeader>
              <TableHead>
                <TableRow hover>
                  {/* (siteName, carid, engName, chName, joy, approveDate, inDate, inVateDate, applyDate */}
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(1)}>
                      {t('icon1_useraccesslog_smart_card_label')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(2)}>
                      {t('icon1_useraccesslog_eng_name_label')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(3)}>
                      {t('icon1_accrecsearch_cn_name_column')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(4)}>
                      {t('icon_vas_wsms_report_table_header_position')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{}}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(5)}>
                      {t('icon_vas_wsms_report_table_header_year_month')}
                    </Link>
                  </TableCell>

                  <TableCell sx={{}}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(5)}>
                      {t('icon_vas_wsms_report_table_header_approval_days')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{}}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(6)}>
                      {t('icon_vas_wsms_report_table_header_amount')}
                    </Link>
                  </TableCell>
                </TableRow>
              </TableHead>

              <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>

              {mainData.map((mainBigDataRow, index) => (
                <>
                  {mainBigDataRow.mainDate.map((mainDataRow, index2) => (
                    <>
                      {/* 單個人數據，需要循環遍歷 */}
                      <TableRow
                        hover
                        key={index2 + Math.random}
                        sx={{
                          backgroundColor: index2 % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                        }}
                      >
                        {/* 智能卡編號 */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{index2 > 0 ? '' : mainDataRow.smartCardId} </TableCell>
                        {/* 英文姓名 */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{index2 > 0 ? '' : mainDataRow.englishName} </TableCell>
                        {/* 中文姓名 */}
                        <TableCell
                          sx={{
                            whiteSpace: 'nowrap',
                            fontSize: '15px',
                            textAlign: 'left',
                          }}
                        >
                          {index2 > 0 ? '' : mainDataRow.chineseName}
                        </TableCell>
                        {/* 受僱職位 */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{index2 > 0 ? '' : mainDataRow.safetyPositionDesc} </TableCell>
                        {/* 年月 */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{mainDataRow.yearmonth}</TableCell>
                        {/* 批核天數 */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{mainDataRow.monthlyApprovedManday} </TableCell>
                        {/* 費用 */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{mainDataRow.monthlyAmount?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'} </TableCell>
                      </TableRow>
                    </>
                  ))}

                  {/* 小計 */}
                  <TableRow hover key={index + Math.random}>
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{} </TableCell>

                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{} </TableCell>

                    <TableCell
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: '15px',
                        textAlign: 'left',
                      }}
                    >
                      {}
                    </TableCell>

                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{} </TableCell>

                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{t('icon_vas_wsms_label_subtotal') + '：'}</TableCell>
                    {/* 批核天數 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{mainBigDataRow.mandaySubTotal} </TableCell>
                    {/* 費用 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{mainBigDataRow.amountSubTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'} </TableCell>
                  </TableRow>
                </>
              ))}

              <TableRow hover key={Math.random + 1000}>
                <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{} </TableCell>

                <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{} </TableCell>

                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'left',
                  }}
                >
                  {}
                </TableCell>

                <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{} </TableCell>

                <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{t('icon_vas_wsms_label_total') + '：'}</TableCell>
                {/* 批核天數 */}
                <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{dataSource.mandayTotal} </TableCell>
                {/* 費用 */}
                <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{dataSource.amountTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'} </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </Container>
      </div>
    </Container>
  )
}
