import { instance } from "../../instance";

// get Report
export const getReport = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getAccRecVendorApprovedList`,
    data: data,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 承判商名称
export const getVendorDesc = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getVendorDesc`,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
