import React, { useEffect, useState } from 'react'
import { instance } from 'src/utils/instance'

import { confirm } from 'src/components/common/index'
import * as commonApi from 'src/utils/api/CommonApi'

function getRefreshToken() {
  const token = localStorage.getItem('refresh_token')
  return token
}
// let isFlag = false
function RefreshToken(props) {
  const [expTime, setExpTime] = useState(localStorage.getItem('expTime'))
  const [isFlag, setIsFlag] = useState(false)

  const confimFunc = () => {
    confirm({
      title: '提示',
      content: 'token即将过期,请保存你的数据',
      onOk: () => {
        setIsFlag(false)
      },
    })
  }

  useEffect(() => {
    // 1秒钟加1
    const interval = setInterval(() => {
      const diff = expTime - new Date().getTime() / 1000
      // console.log('diff:', diff)
      if (diff && diff <= 800) {
        if (!isFlag) {
          // setIsFlag(true)
          // confimFunc()
        }

        // let headers = {
        //   'Content-Type': 'application/json',
        //   Authorization: getRefreshToken(),
        // }
        // instance.post(`${process.env.REACT_APP_WEB_URL}/oauth/refreshToken`, {}, { headers: headers }).then(
        //   (result) => {
        //     const {
        //       data: { data },
        //     } = result
        //     if (data?.access_token !== undefined) {
        //       localStorage.setItem('access_token', data?.access_token)
        //       localStorage.setItem('refresh_token', data?.refresh_token)
        //       localStorage.setItem('userId', data?.sub)
        //       // localStorage.setItem('expTime', data?.exp)
        //       commonApi.getRefreshTokenTime({ token: data?.access_token }).then((res) => {
        //         localStorage.setItem('expTime', res.exp)
        //         setExpTime(res.exp)
        //         setIsFlag(false)
        //       })
        //     }
        //     return result.data.data
        //   },
        //   (error) => {
        //     console.log('🚀 ~ file: RefreshToken.js:56 ~ interval ~ error:', error)
        //     return []
        //   },
        // )
        return ''
      }
    }, 1000)
    // 卸载前清除计时器
    return () => clearInterval(interval)
  }, [expTime]) // [] 表示只有在组件挂载时运行
}

export default RefreshToken
