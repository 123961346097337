import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IconButton,
  Container,
  Grid,
  Box,
  FormControl,
  Button,
  MenuItem,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  tableCellClasses,
  FormLabel,
  TableFooter,
  TablePagination,
  Link,
  DialogTitle,
  Dialog,
  Divider,
  DialogContent,
  DialogActions,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import LastPageIcon from '@mui/icons-material/LastPage'
import moment from 'moment'
import ReactEcharts from 'echarts-for-react'
import CustomDialog from 'src/components/common/CustomDialog'
import TableDetail from 'src/components/icon/icon3/editAutotransferFile/TableDetail'
import TableGap from 'src/components/icon/icon3/editAutotransferFile/TableGap'
import CustomSnackbar from 'src/components/common/CustomSnackbar'
import { confirm } from 'src/components/common'
import * as api from '../../../utils/api/systemAdministrator/EditAutopayFileApi'

const ReportCenterTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    fontSize: '15px',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'center',
  },
}))

const ReportLeftTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    backgroundColor: '#c6c6c6',
    padding: '3px',
    fontSize: '14px',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'Left',
    padding: '3px',
    fontSize: '13px',
  },
}))

const ReportRightTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    fontSize: '14px',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'right',
    padding: '3px',
    fontSize: '13px',
  },
}))

// 删除或更改自动转账档案

export default function EditAutotransferFile() {
  const [isSearchPage, setIsSearchPage] = useState(1)
  const { t } = useTranslation()
  const [backOpen, setBackOpen] = useState(false)
  const [subList, setSubList] = useState([])
  const [dateList, setDateList] = useState([])
  const [sendData, setSendData] = useState({})
  const [tableData, setTableData] = useState([])
  const [reload, setReload] = useState(false)
  const [dialog, setDialog] = useState({
    content: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        確認
      </Button>
    ),
    open: false,
  })
  const [snackbar, setSnackbar] = useState({
    message: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setSnackbar({ ...snackbar, open: false })
        }}
      >
        確認
      </Button>
    ),
    open: false,
  })
  const [tablePage, setTablePage] = useState(1)
  const [rowData, setRowData] = useState({})
  const [tablePageData, setTablePageData] = useState({})
  const dialogAction = (showCancelButton, handleConfirmDialog, handleCancelDialog) => {
    if (showCancelButton) {
      return (
        <>
          <Button variant="contained" onClick={handleConfirmDialog}>
            確認
          </Button>
          <Button variant="contained" onClick={handleCancelDialog}>
            取消
          </Button>
        </>
      )
    } else {
      return (
        <Button variant="contained" onClick={handleConfirmDialog}>
          確認
        </Button>
      )
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    const getData = async () => {
      setBackOpen(true)
      let getSub = await api.getSubContractorList()
      let getDateApi = await api.getDate()
      if (getSub) {
        setSubList(getSub)
      }
      if (getDateApi) {
        setDateList(getDateApi)
      }
      setBackOpen(false)
    }
    getData()
  }, [reload])

  const handleBack = () => {
    setIsSearchPage(1)
  }
  const handleBackDetail = () => {
    console.log('senddata', sendData)
    setIsSearchPage(2)
  }

  const handleDateFrom = (e) => {
    setSendData({
      ...sendData,
      dateFrom: e.target.value,
      dateTo: null,
      statusDesc: '所有狀況',
    })
  }

  const handleDateTo = (e) => {
    setSendData({ ...sendData, dateTo: e.target.value })
  }

  const handleSubcontractors = (data) => {
    setSendData({
      ...sendData,
      vendorId: data.id,
      subcontractorDesc: data.text,
    })
  }

  const handleStatus = (data) => {
    setSendData({ ...sendData, status: data.id, statusDesc: data.text })
  }

  const resetForm = (e) => {
    setSendData({})
  }
  const handleDelete = (row) => {
    setRowData({ ...row, open: true })
  }
  const handleEdit = (row) => {
    setDialog({
      ...dialog,
      content: t('sure_edit'),
      open: true,
      action: dialogAction(
        true,
        () => {
          const sendEdit = async () => {
            let editApi = await api.changeRow(row)
            if (editApi.code === 200) {
              setDialog({
                ...dialog,
                content: '修改成功',
                open: true,
                action: dialogAction(
                  false,
                  () => {
                    setDialog({
                      ...dialog,
                      open: false,
                    })
                    setDialog({ ...dialog, open: false })
                    setReload(!reload)
                    setRowData({ ...rowData, open: false })
                    send()
                  },
                  null,
                ),
              })
            } else {
              setDialog({
                ...dialog,
                content: '修改失敗',
                open: true,
                action: dialogAction(
                  false,
                  () => {
                    setDialog({
                      ...dialog,
                      open: false,
                    })
                  },
                  null,
                ),
              })
            }
          }
          sendEdit()
          setDialog({
            ...dialog,
            open: false,
          })
          setRowData({ ...rowData, open: false })
        },
        () => {
          setDialog({
            ...dialog,
            open: false,
          })
        },
      ),
    })
  }

  const send = async () => {
    setBackOpen(true)
    let sendApi = await api.getFirstTable(sendData)
    if (sendApi) {
      setTableData(sendApi)
      setIsSearchPage(2)
    }
    setBackOpen(false)
  }

  const send2 = async () => {
    setBackOpen(true)
    let sendApi = await api.getFirstTable(sendData)
    if (sendApi) {
      setTableData(sendApi)
      setIsSearchPage(2)
    }
    setBackOpen(false)
  }

  const handleSubmit = () => {
    if (!sendData.dateFrom || !sendData.dateTo) {
      alert('請選擇日期由/至')
      return
    }

    console.log(sendData)
    send()
  }

  useEffect(() => {
    const getData = async () => {
      setBackOpen(true)
      let getSub = await api.getSubContractorList()
      let getDateApi = await api.getDate()
      if (getSub) {
        setSubList(getSub)
      }
      if (getDateApi) {
        setDateList(getDateApi)
      }
      setBackOpen(false)
    }
    getData()
  }, [])

  const handleDatePage = (row) => {
    if (row.status === 'C' || row.status !== 'invalid') {
      // 详情报表
      const detailTable = async () => {
        let send = await api.getDetailTable({
          dateFrom: row.dateFrom,
          header: row.headerId,
          vendorId: row.vendorId,
          vendorDesc: row.vendorDesc,
          subBatchId: row.noofbatch,
          pdDateFrom: row.pdDateFrom,
          pdDateTo: row.pdDateTo,
        })
        setTablePageData(send)
      }
      detailTable()
      setIsSearchPage(3)
      setTablePage(1)
    } else {
      const gapTable = async () => {
        let sendGap = await api.getGapTable({
          dateFrom: row.dateFrom,
          vendorId: row.vendorId,
        })
        setTablePageData(sendGap)
      }
      gapTable()
      setIsSearchPage(3)
      setTablePage(2)
    }
  }
  const exportExcel = () => {}

  const handleBackTable = () => {
    setIsSearchPage(2)
  }

  const handleSendDelete = (rowData) => {
    if (!rowData.remark) {
      setDialog({
        ...dialog,
        content: '請填寫備注',
        open: true,
      })
      return
    }
    setDialog({
      ...dialog,
      open: true,
      content: t('confirm_deletion'),
      action: dialogAction(
        true,
        () => {
          const deleteData = async () => {
            const { code } = await api.deleteRowApi(rowData)
            if (code === 200) {
              setDialog({
                content: t('deletion_was_successful'),
                open: true,
                action: dialogAction(
                  false,
                  () => {
                    setDialog({ ...dialog, open: false })
                    setReload(!reload)
                    setRowData({ ...rowData, open: false })
                    send()
                  },
                  null,
                ),
              })
            } else {
              setDialog({
                ...dialog,
                content: '删除失败！！',
                open: true,
              })
              setReload(!reload)
              return []
            }
          }
          deleteData()
        },
        () => {
          setDialog({
            ...dialog,
            open: false,
          })
        },
      ),
    })
  }
  // const handleSendDelete = (rowData) => {
  //   // if (!rowData.remark) {
  //   //   setDialog({
  //   //     ...dialog,
  //   //     content: "請填寫備注",
  //   //     open: true,
  //   //   });
  //   //   return;
  //   // }
  //   () => {
  //     const deleteData = async () => {
  //       let deleteApi = api.deleteRowApi(rowData);
  //       if (deleteApi.code === 200) {
  //         setDialog({
  //           ...dialog,
  //           content: "刪除成功",
  //           open: true,
  //           action: dialogAction(
  //             false,
  //             () => {
  //               setDialog({
  //                 ...dialog,
  //                 open: false,
  //               });
  //               setReload(!reload);
  //               setRowData({ ...rowData, open: false });
  //             },
  //             null
  //           ),
  //         });
  //         return [];
  //       } else {
  //         setDialog({
  //           ...dialog,
  //           content: "刪除失敗",
  //           open: true,
  //         });
  //         return [];
  //       }
  //     };
  //     deleteData();
  //   };
  // };

  // 承判商自動轉賬支薪報告
  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomDialog sx={{ whiteSpace: 'pre-line' }} open={dialog.open} title={dialog.title} content={dialog.content} action={dialog.action} />
      <Dialog maxWidth="xs" fullWidth open={rowData.open || false}>
        <DialogTitle>{t('sure_delete')}</DialogTitle>
        <DialogContent>
          <TextField
            onChange={(e) => {
              setRowData({ ...rowData, remark: e.target.value })
            }}
            value={rowData.remark || ''}
            sx={{ my: '10px' }}
            inputProps={{ maxLength: 150 }}
            multiline
            label={t('Remark')}
            size="small"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => handleSendDelete(rowData)}>
            {t('button_save')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setRowData({ ...rowData, open: false })
            }}
          >
            {t('button_cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      {isSearchPage === 1 && (
        <form>
          <Container maxWidth="md" sx={{ textAlign: 'center' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                  <b>{t('Contractor_Autopay_Status')}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ max: '2099-12-31' }}
                  onChange={handleDateFrom}
                  sx={{ textAlign: 'left', maxWidth: '400px' }}
                  value={sendData.dateFrom || ' '}
                  fullWidth
                  error={!sendData.dateFrom}
                  size="small"
                  label={t('Autopay_Date') + '(' + t('from') + ')'}
                  type="date"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: '2099-12-31',
                    min: sendData.dateFrom,
                  }}
                  onChange={handleDateTo}
                  sx={{ textAlign: 'left', maxWidth: '400px' }}
                  value={sendData?.dateTo || ' '}
                  fullWidth
                  error={!sendData.dateTo}
                  size="small"
                  label={t('Autopay_Date') + '(' + t('to') + ')'}
                  type="date"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField sx={{ textAlign: 'left', maxWidth: '400px' }} value={sendData.vendorId || ' '} fullWidth size="small" label={t('Contractor')} select>
                  <MenuItem value=" " onClick={() => handleSubcontractors({ id: ' ', text: 'All' })}>
                    {' '}
                    所有承判商
                  </MenuItem>
                  {subList.map((item, index) => (
                    <MenuItem key={index} value={item.id} onClick={() => handleSubcontractors(item)}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField sx={{ textAlign: 'left', maxWidth: '400px' }} value={sendData.status || ' '} fullWidth size="small" label={t('Status')} select>
                  <MenuItem value=" " onClick={() => handleStatus({ id: ' ', text: '所有狀況' })}>
                    {' '}
                    所有狀況
                  </MenuItem>
                  <MenuItem value="I" onClick={() => handleStatus({ id: 'I', text: '未完成' })}>
                    {' '}
                    未完成
                  </MenuItem>
                  <MenuItem value="C" onClick={() => handleStatus({ id: 'C', text: '完成' })}>
                    {' '}
                    完成
                  </MenuItem>
                  <MenuItem value="invalid" onClick={() => handleStatus({ id: 'invalid', text: '無效' })}>
                    {' '}
                    無效
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    backgroundColor: 'primaryBlue',
                    color: 'commom.white',
                  }}
                >
                  {t('button_submit')}
                </Button>
                <Button
                  variant="contained"
                  onClick={resetForm}
                  sx={{
                    marginLeft: '20px',
                    backgroundColor: 'primaryGreen',
                    color: 'commom.white',
                    marginRight: '10px',
                  }}
                >
                  {t('button_reset')}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </form>
      )}
      {isSearchPage === 2 && (
        <Container maxWidth={false}>
          <div
            style={{
              display: 'block',
              textAlign: 'left',
              marginTop: '0px',
            }}
          >
            <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
              <ArrowBackIcon />
              {t('button_back')}
            </Button>
          </div>
          <div style={{ display: 'block', textAlign: 'right' }}>
            {/* <Button sx={{ float: "right", mr: "20px" }} onClick={exportExcel}>
              <DownloadIcon />
              {t("button_export_excel")}
            </Button> */}
            {/* <Button sx={{ float: "right", mr: "20px" }}>
              <DownloadIcon />
              {t("button_export_pdf")}
            </Button> */}
          </div>
          <Typography variant="h4" style={{ textAlign: 'center' }}>
            <b>{t('Contractor_Autopay_Status')}</b>
          </Typography>
          <TableContainer>
            <Table sx={{ textAlign: 'left' }} size="small">
              <TableBody>
                <TableRow>
                  {/* 承判商: */}
                  <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('Contractor')}:</TableCell>
                  <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.subcontractorDesc ? sendData.subcontractorDesc : 'All'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('Autopay_Date') + ' ( ' + t('from') + ' ) '}:</TableCell>
                  <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{moment(sendData.dateFrom).format('YYYY/MM/DD')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('Autopay_Date') + ' ( ' + t('to') + ' ) '}:</TableCell>
                  <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{moment(sendData.dateTo).format('YYYY/MM/DD')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('Status')}:</TableCell>
                  <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.statusDesc}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer sx={{ my: '20px' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <ReportCenterTableCell>{t('Contractor')}</ReportCenterTableCell>
                  <ReportCenterTableCell>{t('Autopay_Date')}</ReportCenterTableCell>
                  <ReportCenterTableCell>{t('icon1_autopay_maintWorkerAccount_label8')}</ReportCenterTableCell>
                  <ReportCenterTableCell>{t('icon1_autopay_maintWorkerAccount_label9')}</ReportCenterTableCell>
                  <ReportCenterTableCell>{t('Payroll_method_and_total_times')}</ReportCenterTableCell>
                  <ReportCenterTableCell>{t('Status')}</ReportCenterTableCell>
                  <ReportCenterTableCell sx={{ textAlign: 'right' }}>{t('Relevant_income')}</ReportCenterTableCell>
                  <ReportCenterTableCell sx={{ textAlign: 'right' }}>{t('Total_MPF_contribution')}</ReportCenterTableCell>
                  <ReportCenterTableCell sx={{ textAlign: 'right' }}>{t('Total_workers')}</ReportCenterTableCell>
                  <ReportCenterTableCell sx={{ textAlign: 'right' }}>{t('Payroll_numbers')}</ReportCenterTableCell>
                  <ReportCenterTableCell>{t('button_delete') + ' / ' + t('Change')}</ReportCenterTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, ind) => (
                  <TableRow hover key={row.headerId}>
                    <TableCell>{row.vendorDesc}</TableCell>
                    <TableCell>
                      <Button onClick={() => handleDatePage(row)}> {row.autoPayDate}</Button>
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => handleDatePage(row)}>{row.dateFrom}</Button>
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => handleDatePage(row)}>{row.dateTo}</Button>
                    </TableCell>
                    <TableCell>
                      {row.payrollTypeTxt +
                        (row.payrollType === 'P' ? ' (' + row.noofbatch + ')' : '' || row.payrollType === 'S' ? (row.pdDateFrom !== null ? '(' + row.pdDateFrom + '~' + row.pdDateTo + ')' : '') : '')}
                    </TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>{row.relevantIncome.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>{row.employerMpf.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>{row.employeeNum.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>{row.employeeAuto.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                    <TableCell sx={{ textAlign: 'left' }}>
                      {row.lastPay === 1 ? (
                        <Button size="small" variant="contained" onClick={() => handleDelete(row)}>
                          {t('button_delete')}
                        </Button>
                      ) : row.lastPay === 2 ? (
                        <Button size="small" variant="contained" onClick={() => handleEdit(row)}>
                          {t('button_edit')}
                        </Button>
                      ) : (
                        <font style={{ color: 'red' }}>{'此檔不是最新的檔案，不能對此檔作任何更改'}</font>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              {(!tableData || tableData.length === 0) && (
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }} colSpan={12}>
                      {'No data found !'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Container>
      )}
      {isSearchPage === 3 && tablePage === 1 && <TableDetail getBack={handleBackTable} data={tablePageData} handleBackDetail={handleBackDetail} />}
      {isSearchPage === 3 && tablePage === 2 && <TableGap getBack={handleBackTable} data={tablePageData} />}
    </>
  )
}
