import { React, useEffect, useState, useRef } from 'react'
import {
  Autocomplete,
  Container,
  Grid,
  FormControl,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  tableCellClasses,
  TextField,
  MenuItem,
} from '@mui/material'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import { parseInt } from 'lodash'
import { useForm } from 'react-hook-form'
import InputDropDown from 'src/components/use-form/InputDropDown'
import InputRadioGroup from 'src/components/use-form/InputRadioGroup'
import InputDatePicker from 'src/components/use-form/InputDatePicker'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import $http, { $download } from 'src/utils/api/services'
import * as api from '../../../utils/api/workerAttendanceRecord/AccRecSearchApi'
import * as reportApi from '../../../utils/api/workerAttendanceRecord/AccRecVendorSearchApi'

export default function AccRecVendorSearch() {
  const [isSearchPage, setIsSearchPage] = useState(true)
  const { t } = useTranslation()
  const printComponentRef = useRef()
  const [backOpen, setBackOpen] = useState(false)
  const [siteList, setSiteList] = useState([])
  const [subList, setSubList] = useState([])
  const [sendData, setSendData] = useState({ downloadType: 'Screen' })
  const [tableData, setTableData] = useState({})
  const [orderType, setOrderType] = useState('ASC')
  const [defaultOrderBy, setDefaultOrderBy] = useState('subContractorDesc')
  const [vendorDesc, setVendorDesc] = useState({})

  useEffect(() => {
    setBackOpen(true)
    const getData = async () => {
      let getSiteList = await api.getSiteList()
      let getSmartCard = await api.getSmartCard()
      if (getSiteList) {
        setSiteList(getSiteList)
      }
      setSendData({
        ...sendData,
        downloadType: 'Screen',
        orderBy: 'subContractorDesc',
        dateFrom: moment(getPreMonth(moment(new Date()).format('yyyy-MM-DD'))).format('yyyy/MM/DD'),
        dateTo: moment(new Date()).format('yyyy/MM/DD'),
      })
      setDefaultOrderBy('subContractorDesc')
      setBackOpen(false)
    }
    getData()
  }, [])
  const getPreMonth = (date) => {
    console.log('date', date)
    let arr = date.split('-')
    let year = arr[0] // 获取当前日期的年份
    let month = arr[1] // 获取当前日期的月份
    let day = arr[2] // 获取当前日期的日
    let days = new Date(year, month, 0)
    days = days.getDate() // 获取当前日期中月的天数
    let year2 = year
    let month2 = parseInt(month) - 1
    if (month2 === 0) {
      year2 = parseInt(year2) - 1
      month2 = 12
    }
    let day2 = day
    let days2 = new Date(year2, month2, 0)
    days2 = days2.getDate()
    if (day2 > days2) {
      day2 = days2
    }
    if (month2 < 10) {
      month2 = '0' + month2
    }
    let t2 = year2 + '-' + month2 + '-' + day2
    return t2
  }

  const handleSubmit = () => {
    const getReport = async () => {
      if (!sendData.siteId) {
        alert('请选择地盘！！！')
        return
      }
      if (!sendData.dateFrom) {
        alert('请輸入日期（由）！！！')
        return
      }
      if (!sendData.dateTo) {
        alert('请选择日期（至）！！！')
        return
      }

      let fromDate = moment(sendData.dateFrom, 'YYYY/MM/DD')
      let toDate = moment(sendData.dateTo, 'YYYY/MM/DD')

      if (fromDate > toDate) {
        alert('日期（由） 不能大於 日期（至）!')
        return
      }

      if (sendData.downloadType === 'Screen') {
        setBackOpen(true)
        let data = await reportApi.getReport(sendData)
        if (data) {
          setIsSearchPage(false)
          setTableData(data)
        }
        setBackOpen(false)
      }
      if (sendData.downloadType === 'Excel') {
        setBackOpen(true)
        exportExcel(sendData)
      } else if (sendData.downloadType === 'PDF') {
        setBackOpen(true)
        exportPDF(sendData)
      }
      setBackOpen(false)
      console.log(sendData)
    }
    getReport()
  }

  const resetForm = () => {
    setSendData({})
  }

  const handleBack = () => {
    setIsSearchPage(true)
  }

  const handleSiteId = (data) => {
    setBackOpen(true)
    const getSub = async () => {
      let getSubConList = await api.getSubContractorList(data.id)
      if (getSubConList) {
        setSubList(getSubConList)
      }
      let getVendorDesc = await api.getVendorDesc()
      if (getVendorDesc) {
        setVendorDesc(getVendorDesc)
      }
      setSendData({
        ...sendData,
        siteId: data.id,
        siteDesc: data.text,
        subContractorId: '',
      })
      setBackOpen(false)
    }
    getSub()
  }

  const handleSubcontractors = (data) => {
    setSendData({
      ...sendData,
      subContractorId: data.id,
      subContractorDesc: data.text,
    })
  }
  const handleDateFrom = (e) => {
    setSendData({
      ...sendData,
      dateFrom: moment(new Date(e.target.value).getTime()).format('yyyy/MM/DD'),
      dateTo: '',
    })
  }

  const handleDateTo = (e) => {
    setSendData({
      ...sendData,
      dateTo: moment(new Date(e.target.value).getTime()).format('yyyy/MM/DD'),
    })
  }

  const handleOrderBy = (e) => {
    setSendData({ ...sendData, orderBy: e.target.value })
  }

  const handleOrderType = (e) => {
    setOrderType(e.target.value)
    setSendData({ ...sendData, ascOrder: e.target.value === 'ASC' })
  }

  const handleDownloadType = (e) => {
    setSendData({ ...sendData, downloadType: e.target.value })
  }

  const exportExcel = (data) => {
    $download(`/attendance/report/getAccRecVendorListExcel`, 'application/vnd.ms-excel', data)
  }

  const exportPDF = (data) => {
    $download(`/attendance/report/getAccRecVendorListPdf`, 'application/pdf', data)
  }

  const ReportTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      wordBreak: 'keep-all',
      whiteSpace: 'nowrap',
      fontSize: '15px',
      // backgroundColor: palette.report.header,
      // color: palette.common.white,
      //  fontSize: 15,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }))

  const ReportTableRow = styled(TableRow)(({ theme }) => ({
    // "&:nth-of-type(odd)": {
    //   backgroundColor: palette.report.record,
    // },
  }))

  return (
    // 承判商工人出入次數記錄
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        {isSearchPage && (
          <div>
            <form>
              <Container maxWidth="md">
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4" style={{ textAlign: 'center' }}>
                      <b>{t('icon1_accrecvendorsearch_title')}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField error={!sendData.siteId} id="siteDesc" value={sendData.siteId || ''} fullWidth size="small" label={t('Site_Name')} select>
                      <MenuItem value="" onClick={() => handleSiteId({ id: null, text: 'All' })}>
                        None
                      </MenuItem>
                      {siteList.map((item, index) => (
                        <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleSiteId(item)}>
                          {item.id} -{item.text}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField id="subContractorDesc" value={sendData.subContractorId || ''} fullWidth size="small" label={t('Subcontractors_(direct employers)')} select>
                      <MenuItem value="" onClick={() => handleSubcontractors({ id: null, text: 'All' })}>
                        None
                      </MenuItem>
                      {subList.map((item, index) => (
                        <MenuItem key={index} value={item.id} onClick={() => handleSubcontractors(item)}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: '2099-12-31',
                      }}
                      value={moment(new Date(sendData.dateFrom).getTime()).format('yyyy-MM-DD') || ''}
                      fullWidth
                      size="small"
                      label={t('date_from')}
                      type="date"
                      error={!sendData.dateFrom}
                      onChange={handleDateFrom}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: '2099-12-31',
                        min: moment(new Date(sendData.dateFrom).getTime()).format('yyyy-MM-DD') || '',
                      }}
                      value={moment(new Date(sendData.dateTo).getTime()).format('yyyy-MM-DD') || ''}
                      fullWidth
                      size="small"
                      label={t('date_to')}
                      type="date"
                      error={!sendData.dateTo}
                      onChange={handleDateTo}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField value={sendData.orderBy || ''} fullWidth size="small" label={t('icon1_useraccesslog_order_label')} select onChange={handleOrderBy}>
                      <MenuItem value={null}>None</MenuItem>
                      <MenuItem value="subContractorDesc">{t('search_subcontractor_dEmployer_bk')}</MenuItem>
                      <MenuItem value="jobNatureDesc">{t('search_work_type_dot_bk')}</MenuItem>
                      <MenuItem value="manCount"> {t('icon1_accrecvendorsearch_access_people_count_column')}</MenuItem>
                      <MenuItem value="totalCount"> {t('icon1_accrecvendorsearch_access_times_column')}</MenuItem>
                      <MenuItem value="inCount"> {t('icon1_accrecvendorsearch_gate_entry_times_column')} </MenuItem>
                      <MenuItem value="outCount"> {t('icon1_accrecvendorsearch_gete_leave_times_column')} </MenuItem>
                      <MenuItem value="portCount"> {t('Portable_time')}</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl size="small">
                      <RadioGroup row onChange={handleOrderType} defaultValue={orderType}>
                        <FormControlLabel value="ASC" label="ASC" control={<Radio />} />
                        <FormControlLabel value="DESC" label="DESC" control={<Radio />} />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField value={sendData.downloadType || ''} fullWidth size="small" label={t('icon1_useraccesslog_export_type_label')} select onChange={handleDownloadType}>
                      <MenuItem value="Screen">Screen</MenuItem>
                      <MenuItem value="Excel">Excel</MenuItem>
                      <MenuItem value="PDF">PDF</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6} />
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <FormControl size="small">
                      <Button
                        sx={{
                          backgroundColor: 'primaryBlue',
                          color: 'commom.white',
                        }}
                        variant="contained"
                        onClick={handleSubmit}
                      >
                        {t('button_submit')}
                      </Button>
                    </FormControl>

                    <FormControl size="small">
                      <Button
                        variant="contained"
                        onClick={resetForm}
                        label="Reset"
                        sx={{
                          backgroundColor: 'primaryGreen',
                          color: 'commom.white',
                          marginLeft: '20px',
                        }}
                      >
                        {t('button_reset')}
                      </Button>
                    </FormControl>
                  </Grid>
                </Grid>
              </Container>
            </form>
          </div>
        )}
      </Container>

      {!isSearchPage && (
        <Container maxWidth={false}>
          <div
            style={{
              display: 'block',
              textAlign: 'left',
              marginTop: '0px',
            }}
          >
            <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
              <ArrowBackIcon />
              {t('button_back')}
            </Button>
          </div>
          <div style={{ display: 'block', textAlign: 'right' }}>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportExcel(sendData)}>
              <DownloadIcon />
              {t('button_export_excel')}
            </Button>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportPDF(sendData)}>
              <DownloadIcon />
              {t('button_export_pdf')}
            </Button>
          </div>
          <div ref={printComponentRef}>
            <Typography variant="h4" style={{ textAlign: 'center' }}>
              <b>{t('icon1_accrecvendorsearch_title')}</b>
            </Typography>
            <TableContainer>
              <Table sx={{ textAlign: 'left' }} size="small">
                <TableBody>
                  {sendData.dateFrom && (
                    <TableRow>
                      {/* 日期（由) */}
                      <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('date_from')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.dateFrom}</TableCell>
                    </TableRow>
                  )}
                  {sendData.dateTo && (
                    <TableRow>
                      {/* 日期（至) */}
                      <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('date_to')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.dateTo}</TableCell>
                    </TableRow>
                  )}

                  <TableRow>
                    {/* 地盤名稱 */}
                    <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('Site_Name')}:</TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.siteDesc}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 承判商: 	 */}
                    <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('icon1_accrecsearch_sub_condition')}</TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{vendorDesc}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 分判商: 	 */}
                    <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('search_vendor_title_dot')}</TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.subContractorId !== '' ? sendData.subContractorDesc : 'ALL'}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 總數: */}
                    <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('icon1_accrecsearch_total_condition')}：</TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>
                      <Typography sx={{ display: 'block', fontSize: '15px' }}>{tableData && tableData.length}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer sx={{ maxHeight: '80vh', maxWidth: '100vw' }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <ReportTableRow>
                    <ReportTableCell>{t('Subcontractors_(direct employers)')}</ReportTableCell>
                    <ReportTableCell>{t('icon1_accrecvendorsearch_work_type_column')}</ReportTableCell>
                    <ReportTableCell sx={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{t('icon1_accrecvendorsearch_access_people_count_column')}</ReportTableCell>
                    <ReportTableCell sx={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{t('icon1_accrecvendorsearch_access_times_column')}</ReportTableCell>
                    <ReportTableCell sx={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{t('icon1_accrecvendorsearch_gate_entry_times_column')}</ReportTableCell>
                    <ReportTableCell sx={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{t('icon1_accrecvendorsearch_gete_leave_times_column')}</ReportTableCell>
                    <ReportTableCell sx={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{t('Portable_time')}</ReportTableCell>
                  </ReportTableRow>
                </TableHead>
                {!!tableData && tableData.length > 0 && (
                  <TableBody>
                    {tableData.map((row, ind) => (
                      <ReportTableRow
                        hover
                        key={ind}
                        sx={{
                          backgroundColor: ind % 2 !== 0 ? '#DCDCDC' : '#ffffff',
                        }}
                      >
                        <ReportTableCell component="th" scope="row">
                          {row.subContractorDesc}
                        </ReportTableCell>
                        <ReportTableCell align="left">{row.jobNatureDesc}</ReportTableCell>
                        <ReportTableCell sx={{ textAlign: 'right' }}>{row.manCount}</ReportTableCell>
                        <ReportTableCell sx={{ textAlign: 'right' }}>{row.totalCount}</ReportTableCell>
                        <ReportTableCell sx={{ textAlign: 'right' }}>{row.inCount}</ReportTableCell>
                        <ReportTableCell sx={{ textAlign: 'right' }}>{row.outCount}</ReportTableCell>
                        <ReportTableCell sx={{ textAlign: 'right' }}>{row.portCount}</ReportTableCell>
                      </ReportTableRow>
                    ))}
                  </TableBody>
                )}

                {(!tableData || tableData.length === 0) && (
                  <TableBody>
                    <ReportTableRow>
                      <ReportTableCell sx={{ textAlign: 'center' }} colSpan={7}>
                        {'No data found!'}
                      </ReportTableCell>
                    </ReportTableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        </Container>
      )}
    </>
  )
}
