import React, { useState, useEffect } from "react";
import { Button, Container } from "@mui/material";
import CustomBackdrop from "src/components/common/CustomBackdrop";
import CustomDialog from "src/components/common/CustomDialog";
import CustomSnackbar from "src/components/common/CustomSnackbar";
import { confirm } from "src/components/common"
import GetRecord from "./GetRecord";
import AddPartialGrainPeriod from "./AddPartialGrainPeriod";
import { distributePartialBatch, changePayTpye } from "../../../../../utils/api/workerAttendanceRecord/DistributePartialBatch";

export default function DistributePartialBatch(props) {
  const { setType } = props;
  const [control, setControl] = useState({
    diasbled: false,
    fullDisabled: false,
  });
  const [step, setStep] = useState(0);
  const [inProgress, setInProgress] = useState(true);
  const [dialog, setDialog] = useState({
    title: "",
    content: "",
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false });
        }}
      >
        確認
      </Button>
    ),
    open: false,
  });
  const [snackbar, setSnackbar] = useState({
    message: "",
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setSnackbar({ ...snackbar, open: false });
        }}
      >
        確認
      </Button>
    ),
    open: false,
  });
  const [detail, setDetail] = useState({
    headerSet: [],
    batchSet: [],
    rsbatch: [],
    workerSet: [],
    detailStatusSet: [],
  });
  const [reload, setReload] = useState(true);

  useEffect(() => {
    // 執行邏輯
    const getDistributePartialBatch = async () => {
      let changeTpye = await changePayTpye()
      setInProgress(true);
      let res = await distributePartialBatch();
      if (changeTpye && res && res.code && res.code === 200) {
        setDetail({
          ...detail,
          headerSet: res.data.headerSet,
          batchSet: res.data.batchSet,
          rsbatch: res.data.rsbatch,
          workerSet: res.data.workerSet,
          detailStatusSet: res.data.detailStatusSet,
          diasbled: res.data.disabled,
          fullDisabled: res.data.fulldisabled
        });
      } else {
        setDialog({
          ...dialog,
          content: "請求錯誤",
          open: true,
        });
      }
      setInProgress(false);
    };
    getDistributePartialBatch();
  }, [reload]);

  const handleBackToFlowMenu = () => {
    setType(0);
  };

  const handleAddPartialGrainPeriod = () => {
    setStep(1);
  };

  const handleBackToMainReport = () => {
    setStep(0);
    setReload(!reload)
  };

  return (
    <Container maxWidth={false} sx={{ textAlign: "center" }}>
      {!step ? (
        <GetRecord
          reload={reload}
          setReload={setReload}
          workerSet={detail.workerSet}
          headerSet={detail.headerSet}
          batchSet={detail.batchSet}
          rsbatch={detail.rsbatch}
          disabled={detail.diasbled}
          fullDisabled={detail.fullDisabled}
          setInProgress={setInProgress}
          snackbar={snackbar}
          setSnackbar={setSnackbar}
          dialog={dialog}
          setDialog={setDialog}
          handleBackToFlowMenu={handleBackToFlowMenu}
          handleAddPartialGrainPeriod={handleAddPartialGrainPeriod}
        />
      ) : (
        <AddPartialGrainPeriod
          dialog={dialog}
          snackbar={snackbar}
          setInProgress={setInProgress}
          setSnackbar={setSnackbar}
          setDialog={setDialog}
          handleBackToMainReport={handleBackToMainReport}
        />
      )}
      <CustomBackdrop open={inProgress} />
      <CustomDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        action={dialog.action}
      />
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        action={snackbar.action}
      />
    </Container>
  );
}
