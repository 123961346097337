import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { set } from "lodash";
import * as commonApi from "../../../utils/api/CommonApi";
import * as sfapi from "../../../utils/api/vendorAutopaySystem/WorkerSalaryMpfSafetyApi";

export default function WorkerSalaryMpfSafety() {
  const [searchParams] = useSearchParams();
  const [isSearchPage, setIsSearchPage] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [backOpen, setBackOpen] = useState(false);
  const [dateMonthList, setDateMonthList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [reportType, setReportType] = useState(searchParams.get("reportType"));
  const [sendData, setSendData] = useState({
    recordsCriteria: reportType,
    site: {
      id: searchParams.get("siteId"),
      text: searchParams.get("siteDesc"),
    },
    vendor: {
      id: searchParams.get("vendorId"),
      text: searchParams.get("vendorDesc"),
    },
  });
  const [vendorId, setVendorId] = useState(searchParams.get("vendorId"));
  const [vendorDesc, setVendorDesc] = useState(searchParams.get("vendorDesc"));
  const [siteId, setSiteId] = useState(searchParams.get("siteId"));
  const [siteDesc, setSiteDesc] = useState(searchParams.get("siteDesc"));
  const [dateFromId, setDateFromId] = useState(searchParams.get("dateFromId"));
  const [dateToId, setDateToId] = useState(searchParams.get("dateToId"));
  const [dateFromText, setDateFromText] = useState("");
  const [dateToText, setDateToText] = useState("");

  const [dialog, setDialog] = useState({
    content: "",
    action: (
      <Button
        variant="contained"
        onClick={() => setDialog({ ...dialog, open: false })}
      >
        確認
      </Button>
    ),
    open: false,
  });

  useEffect(() => {
    setBackOpen(true);
    const getData = async () => {
      let vendorList = await commonApi.getVendorList();
      let dateMonthList = await commonApi.getDateYearMonth();
      let siteList = await sfapi.getFillSite();
      if (vendorList) {
        console.log(vendorList);
        setVendorList(vendorList);
      }
      if (dateMonthList) {
        setDateMonthList(dateMonthList);
        sendData.dateFrom = dateMonthList[0].id;
        sendData.dateTo = dateMonthList[dateMonthList.length - 1].id;
      }
      if (siteList) {
        setSiteList(siteList);
      }

      //  console.log("test", searchParams.get("dateFrom").substring(0, 7));
      if (searchParams.get("dateFrom") !== null) {
        setSendData({
          recordsCriteria: reportType,
          site: {
            id: searchParams.get("siteId"),
            text: searchParams.get("siteDesc"),
          },
          vendor: {
            id: searchParams.get("vendorId"),
            text: searchParams.get("vendorDesc"),
          },
          //  dateFromId: searchParams.get("dateFrom").substring(0, 7),
        });
        setDateFromId(searchParams.get("dateFrom"));
        setDateFromText(searchParams.get("dateFrom").substring(0, 7));

        setDateToId(searchParams.get("dateTo"));
        setDateToText(searchParams.get("dateTo").substring(0, 7));
      }

      console.log("sadasdsadsa", reportType);
      if (reportType === null) {
        setReportType("0");
      } else {
        setReportType(searchParams.get("reportType"));
      }
      setBackOpen(false);
    };
    getData();
  }, []);

  const handleBack = () => {
    setIsSearchPage(true);
  };

  const handleDateFrom = (e) => {
    setDateFromId(e.id);
    setDateFromText(e.text);
    setSendData({
      ...sendData,
      dateFrom: e.id,
    });
  };

  const handleDateTo = (e) => {
    setDateToId(e.id);
    setDateToText(e.text);
    setSendData({
      ...sendData,
      dateTo: e.id,
    });
  };

  const handleVendorId = (e, data) => {
    if (data !== null) {
      setVendorId(data.id);
      setVendorDesc(data.text);
      setSendData({
        ...sendData,
        vendorId: data.id,
        vendorDesc: data.text,
        vendor: data,
      });
    } else {
      setVendorId(null);
      setVendorDesc(null);
      setSendData({
        ...sendData,
        vendorId: null,
        vendorDesc: null,
        vendor: null,
      });
    }

    console.log("3233", sendData);
  };
  // const handleVendor = (e) => {
  //   setVendorId(e.id);
  //   setVendorDesc(e.text);
  // };

  // const handleSite = (e) => {
  //   setSiteId(e.id);
  // };
  const handleSite = (e, data) => {
    if (data !== null) {
      setSiteId(data.id);
      setSiteDesc(data.text);
      setSendData({
        ...sendData,
        siteId: data.id,
        siteDesc: data.text,
        site: data,
      });
    } else {
      setSiteId(null);
      setSiteDesc(null);
      setSendData({
        ...sendData,
        siteId: null,
        siteDesc: null,
        site: null,
      });
    }
  };

  const handleReportType = (e) => {
    setReportType(e.target.value);
  };

  const sortByColumn = (sort) => {
    const searchData = { ...sendData };

    if (searchData.sortBy === sort && searchData.orderBy) {
      searchData.orderBy = null;
    } else {
      searchData.orderBy = "desc";
    }

    searchData.sortBy = sort;
    setSendData(searchData);

    handleSubmit(searchData);
  };

  const handleSubmit = (data) => {
    // if (!vendorDesc) {
    //   setDialog({
    //     ...dialog,
    //     content: t("search_chooseDatetips") + '(' + t("search_to_tips") + ')',
    //     open: true,
    //   });
    //   return;
    // }

    console.log("senddata", sendData);
    if (!dateFromId) {
      alert(t("search_chooseDatetips") + "(" + t("search_from_tips") + ")");
      // setDialog({
      //   ...dialog,
      //   content: t("search_chooseDatetips") + "(" + t("search_from_tips") + ")",
      //   open: true,
      // });
      return;
    }
    if (!dateToId) {
      alert(t("search_chooseDatetips") + "(" + t("search_to_tips") + ")");
      // setDialog({
      //   ...dialog,
      //   content: t("search_chooseDatetips") + "(" + t("search_to_tips") + ")",
      //   open: true,
      // });
      return;
    }

    console.log("type", reportType);
    if (reportType === "0") {
      navigate({
        pathname: "/vendorAutopaySystem/WorkerSalaryMpfSafety/wSMSDetail1",
        search: `?${createSearchParams(
          {
            siteId: siteId,
            dateToId: dateToId,
            dateFromId: dateFromId,
            dateToText: dateToText,
            dateFromText: dateFromText,
            vendorId: vendorId,
            siteDesc: siteDesc,
            vendorDesc: vendorDesc,
            reportType: reportType,
          },
          { state: { fromSpecificPage: true } }
        )}`,
      });
    } else if (reportType === "1") {
      navigate({
        pathname: "/vendorAutopaySystem/WorkerSalaryMpfSafety/wSMSDetail2",
        search: `?${createSearchParams(
          {
            siteId: siteId,
            dateToId: dateToId,
            dateFromId: dateFromId,
            dateToText: dateToText,
            dateFromText: dateFromText,
            vendorId: vendorId,
            siteDesc: siteDesc,
            vendorDesc: vendorDesc,
            reportType: reportType,
          },
          { state: { fromSpecificPage: true } }
        )}`,
      });
    } else {
      navigate({
        pathname: "/vendorAutopaySystem/WorkerSalaryMpfSafety/wSMSDetail3",
        search: `?${createSearchParams(
          {
            siteId: siteId,
            siteDesc: siteDesc,
            dateToId: dateToId,
            dateFromId: dateFromId,
            dateToText: dateToText,
            dateFromText: dateFromText,
            vendorId: vendorId,
            vendorDesc: vendorDesc,
            reportType: reportType,
          },
          { state: { fromSpecificPage: true } }
        )}`,
      });
    }
  };

  const resetForm = (e) => {
    setReportType("0");
    setVendorId("");
    setVendorDesc("");
    setSiteId("");
    setSiteDesc("");
    setDateFromId("");
    setDateFromText("");
    setDateToId("");
    setDateToText("");
    setSendData({});
  };

  const exportExcel = () => {
    const mpfPrint = async () => {
      setBackOpen(true);
      let sendApi = await commonApi.exportFile(
        sendData,
        "/payrollReportController/RptWorkersAutoPayStatuStradeListPdfExcel",
        "xls",
        "WorkerSalaryAnalysisTrade"
      );
      if (sendApi) {
        if (sendApi.code === 500) {
          setDialog({
            ...dialog,
            content: "請求出錯",
            open: true,
          });
          return [];
        }
        // setPrintData(sendApi.data || {})
      }
      setBackOpen(false);
    };
    mpfPrint();
  };

  const exportPdf = () => {
    const mpfPrint = async () => {
      setBackOpen(true);
      let sendApi = await commonApi.exportFile(
        sendData,
        "/payrollReportController/RptWorkersAutoPayStatuStradeListPdf",
        "pdf",
        "WorkerSalaryAnalysisTrade"
      );
      if (sendApi) {
        if (sendApi.code === 500) {
          setDialog({
            ...dialog,
            content: "請求出錯",
            open: true,
          });
          return [];
        }
        // setPrintData(sendApi.data || {})
      }
      setBackOpen(false);
    };
    mpfPrint();
  };

  const renderReportType = (param) => {
    switch (param) {
      case "0":
        return t("icon_vas_wsms_report_type_salary_mpf");
      case "2":
        return t("icon_vas_wsat_report_type_detail");
      case "1":
        return t("icon_vas_wsms_report_type_summary");
      default:
        return "";
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <form>
        <Container maxWidth="md">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {/* 工人薪金及強積金及安全人員狀況分析 */}
            <Grid item xs={12}>
              <Typography variant="h4" style={{ textAlign: "center" }}>
                <b>{t("icon_vas_wsms_title")}</b>
              </Typography>
            </Grid>

            <Container
              sx={{ width: "500px", borderColor: "#d3d3d3", borderRadius: 4 }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  margin: "auto",
                  width: "400px",
                  marginBottom: "20px",
                  marginTop: "10px",
                }}
              >
                <Autocomplete
                  sx={{ width: "400px" }}
                  value={sendData.vendor || null}
                  onChange={handleVendorId}
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  getOptionLabel={(option) => {
                    if (
                      option.id !== null &&
                      option.id !== "null" &&
                      option.id !== ""
                    ) {
                      return option.text;
                    } else {
                      return "";
                    }
                  }}
                  options={vendorList}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size="small"
                      {...params}
                      label={t("Vendor")}
                    />
                  )}
                />
              </Grid>
              {/* <Grid
                item
                xs={12}
                sx={{ margin: "auto", width: "400px", marginTop: "10px" }}
              >
                <TextField
                  error={!vendorDesc}
                  id="vendorId"
                  value={vendorDesc || ""}
                  fullWidth
                  size="small"
                  label={t("icon_vas_wsms_vendor")}
                  select
                >
                  {vendorList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.text}
                      onClick={() => handleVendor(item)}
                    >
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid> */}

              {/* <Grid
                item
                xs={12}
                sx={{ margin: "auto", width: "400px", marginTop: "10px" }}
              >
                <TextField
                  id="siteId"
                  value={siteId || ""}
                  fullWidth
                  size="small"
                  label={t("icon_vas_wsms_site")}
                  select
                >
                  {siteList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}
                      onClick={() => handleSite(item)}
                    >
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid> */}

              <Grid
                item
                xs={12}
                sx={{
                  margin: "auto",
                  width: "400px",
                  marginBottom: "20px",
                  marginTop: "10px",
                }}
              >
                <Autocomplete
                  sx={{ width: "400px" }}
                  value={sendData.site || null}
                  onChange={handleSite}
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  getOptionLabel={(option) => {
                    console.log("option", option);
                    if (
                      option.id !== null &&
                      option.id !== "null" &&
                      option.id !== ""
                    ) {
                      console.log("1321312", option.id);
                      return option.id + " ~" + option.text;
                    } else {
                      return "";
                    }
                  }}
                  options={siteList}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size="small"
                      {...params}
                      label={t("icon1_autopay_setSiteUserMapping_tableLabel1")}
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ margin: "auto", width: "400px", marginTop: "10px" }}
              >
                <TextField
                  id="dateForm"
                  error={!dateFromId}
                  value={dateFromText || ""}
                  fullWidth
                  size="small"
                  label={t("icon_vas_wsms_date_from")}
                  select
                >
                  {dateMonthList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.text}
                      onClick={() => handleDateFrom(item)}
                    >
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ margin: "auto", width: "400px", marginTop: "10px" }}
              >
                <TextField
                  id="dateTo"
                  error={!dateToId}
                  value={dateToText || ""}
                  fullWidth
                  size="small"
                  label={t("icon_vas_wsms_date_to")}
                  select
                >
                  {dateMonthList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.text}
                      onClick={() => handleDateTo(item)}
                    >
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ margin: "auto", width: "400px", marginTop: "10px" }}
              >
                <FormControl size="small">
                  <FormLabel>{t("icon_vas_wsms_report_type")}</FormLabel>
                  <RadioGroup
                    row
                    onChange={handleReportType}
                    value={reportType}
                  >
                    <FormControlLabel
                      value="0"
                      checked={reportType === "0"}
                      label={t("icon_vas_wsms_report_type_salary_mpf")}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="1"
                      checked={reportType === "1"}
                      label={t("icon_vas_wsms_report_type_safety")}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="2"
                      checked={reportType === "2"}
                      label={t("icon_vas_wsms_report_type_summary")}
                      control={<Radio />}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Container>

            <Grid item xs={6} />
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <FormControl>
                <Button
                  variant="contained"
                  onClick={() => handleSubmit()}
                  sx={{
                    backgroundColor: "primaryBlue",
                    color: "commom.white",
                  }}
                >
                  {t("button_submit")}
                </Button>
              </FormControl>
              <FormControl sx={{ marginLeft: "20px" }}>
                <Button
                  variant="contained"
                  onClick={() => resetForm()}
                  sx={{
                    backgroundColor: "primaryGreen",
                    color: "commom.white",
                    marginRight: "10px",
                  }}
                  label="Reset"
                >
                  {t("button_reset")}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      </form>
    </>
  );
}
