import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { confirm } from "src/components/common"
import {
  addSubBatchAction,
  selectThreeSand,
  selectThreeSandAction,
} from "../../../../../utils/api/workerAttendanceRecord/DistributeSubBatchApi";

export default function AddShortGrainPeriod(props) {
  const {
    headerSet,
    handleBackToMainReport,
    dialog,
    setDialog,
    snackbar,
    inProgress,
    setSnackbar,
    setInProgress,
  } = props;
  const [detail, setDetail] = useState({
    workerSet: [],
  });
  const [checkSubContractor, setCheckSubContractor] = useState({});
  const [checkWorker, setCheckWorker] = useState({});

  const [isSearchPage, setIsSearchPage] = useState(true);

  const [shortPeriodDateFrom, setShortPeriodDateFrom] = useState("");
  const [shortPeriodDateTo, setShortPeriodDateTo] = useState("");
  const [verify, setVerify] = useState({
    shortPeriodDateFrom: false,
    shortPeriodDateTo: false,
  });

  const { t } = useTranslation();

  let lastSubContractorId = useRef(0);

  useEffect(() => {
    lastSubContractorId.current = 0;
  });

  const getSubContractorDesc = (row) => {
    if (
      lastSubContractorId.current === 0 ||
      lastSubContractorId.current !== row.subContractorId
    ) {
      lastSubContractorId.current = row.subContractorId;
      return (
        <FormControlLabel
          label={row.subContractorDesc}
          control={
            <Checkbox
              size="small"
              checked={
                checkSubContractor["sub-contractor-" + row.subContractorId] ===
                  undefined
                  ? false
                  : checkSubContractor["sub-contractor-" + row.subContractorId]
              }
              onChange={(event) => {
                let newCheckSubContractor = { ...checkSubContractor };
                newCheckSubContractor["sub-contractor-" + row.subContractorId] =
                  event.target.checked;
                setCheckSubContractor(newCheckSubContractor);
                let newCheckWorker = { ...checkWorker };
                Object.keys(newCheckWorker).forEach((key) => {
                  if (
                    key.substring(0, key.lastIndexOf("-")) ===
                    "sub-contractor-" + row.subContractorId
                  ) {
                    newCheckWorker[key] = event.target.checked;
                  }
                });
                setCheckWorker(newCheckWorker);
              }}
            />
          }
        />
      );
    } else {
      return "";
    }
  };

  const getChineseNameDesc = (row) => (
    <FormControlLabel
      label={row.chineseName}
      size="small"
      control={
        <Checkbox
          size="small"
          checked={
            checkWorker[
              "sub-contractor-" + row.subContractorId + "-" + row.cardHolderId
            ] === undefined
              ? false
              : checkWorker[
              "sub-contractor-" +
              row.subContractorId +
              "-" +
              row.cardHolderId
              ]
          }
          onChange={(event) => {
            let newCheckWorker = { ...checkWorker };
            newCheckWorker[
              "sub-contractor-" + row.subContractorId + "-" + row.cardHolderId
            ] = event.target.checked;
            setCheckWorker(newCheckWorker);
          }}
        />
      }
    />
  );

  const handleSubmitSearch = () => {
    let errorIndex = Object.values(verify).findIndex((val) => val === false);
    if (errorIndex === -1) {
      const getThreeSand = async () => {
        setInProgress(true);
        let searchCondition = {
          dateFrom: Date.parse(
            new Date(shortPeriodDateFrom.replaceAll("-", "/"))
          ),
          dateTo: Date.parse(new Date(shortPeriodDateTo.replaceAll("-", "/"))),
          headerDateFrom: headerSet[0].dateFrom,
          headerDateTo: headerSet[0].dateTo,
          vendorId:
            headerSet.length > 0 && headerSet[0].vendorId
              ? headerSet[0].vendorId
              : "",
        };

        // Date.parse(new Date("2012-01-01")) !== Date.parse(new Date("2012/01/01"))
        let checkRes = await addSubBatchAction(searchCondition);
        if (checkRes && checkRes.code === 200) {
          let res = await selectThreeSand(searchCondition);
          if (res.code && res.code === 200) {
            setDetail({ ...detail, workerSet: res.data.workerSet });
            if (res.data && res.data.workerSet) {
              let newCheckSubContractor = { ...checkSubContractor };
              let newCheckWorker = { ...checkWorker };
              res.data.workerSet.forEach((row) => {
                if (
                  newCheckSubContractor[
                  "sub-contractor-" + row.subContractorId
                  ] === undefined
                ) {
                  newCheckSubContractor[
                    "sub-contractor-" + row.subContractorId
                  ] = false;
                }
              });
              setCheckSubContractor(newCheckSubContractor);

              res.data.workerSet.forEach((row) => {
                if (
                  newCheckWorker[
                  "sub-contractor-" +
                  row.subContractorId +
                  "-" +
                  row.cardHolderId
                  ] === undefined
                ) {
                  newCheckWorker[
                    "sub-contractor-" +
                    row.subContractorId +
                    "-" +
                    row.cardHolderId
                  ] = false;
                }
              });
              setCheckWorker(newCheckWorker);
            }
            setIsSearchPage(false);
          } else {
            setDialog({
              ...dialog,
              content: res.message ? res.message : "",
              open: true,
            });
          }
        } else {
          setDialog({
            ...dialog,
            content: checkRes.message ? checkRes.message : "",
            open: true,
          });
        }
        setInProgress(false);
      };
      getThreeSand();
    }
  };

  const handleSubmitRecord = () => {
    const submitSubBatchSelectThreeSand = async () => {
      setInProgress(true);
      let cardHolderIdBox = [];
      if (checkWorker) {
        Object.keys(checkWorker).forEach((val) => {
          if (checkWorker[val]) {
            // val: sub-contractor-100000-100000500, get card holder id 100000500
            cardHolderIdBox.push(val.substring(val.lastIndexOf("-") + 1));
          }
        });
      }
      if (cardHolderIdBox.length === 0) {
        setDialog({
          ...dialog,
          content: "請選擇工人",
          open: true,
        });
        setInProgress(false);
        return;
      }
      let data = {};
      data.cardHolderIdBox = cardHolderIdBox;
      data.selectedWorker = cardHolderIdBox.length;
      data.dateFrom = shortPeriodDateFrom.replaceAll("-", "/");
      data.dateTo = shortPeriodDateTo.replaceAll("-", "/");
      data.headerDateFrom = headerSet[0].dateFrom.replaceAll("-", "/");
      data.headerDateTo = headerSet[0].dateTo.replaceAll("-", "/");
      let res = await selectThreeSandAction(data);
      setInProgress(false);
      if (res && res.code && res.code === 200) {

        confirm({
          title: t('tip'),
          content: "保存成功",
          onOk: () => {
            handleBackToMainReport()
          }
        })
        // setSnackbar({
        //   ...snackbar,
        //   open: true,
        //   message: "保存成功",
        //   action: (
        //     <Button
        //       variant="contained"
        //       onClick={() => {
        //         setSnackbar({ ...snackbar, open: false });
        //         handleBackToMainReport();
        //       }}
        //     >
        //       確認
        //     </Button>
        //   ),
        // });
      } else {
        setDialog({
          ...dialog,
          content: "提交失敗",
          open: true,
        });
      }
      // setInProgress(false);
    };
    submitSubBatchSelectThreeSand();
  };

  return (
    <>
      {isSearchPage ? (
        <Container sx={{ textAlign: "center" }} maxWidth="sm">
          <Typography sx={{ fontSize: "35px" }}>
            <b>{t("Add_a_new_short_pay_period")}</b>
          </Typography>
          <TextField
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: "2099-12-31"
            }}
            value={
              headerSet.length > 0 && headerSet[0].dateFrom
                ? moment(headerSet[0].dateFrom, "YYYY/MM/DD").format(
                  "YYYY-MM-DD"
                )
                : ""
            }
            fullWidth
            sx={{ textAlign: "left", maxWidth: "400px", mt: "20px" }}
            size="small"
            label={t("icon1_autopay_distributeSubBatch_input1")}
            variant="outlined"
            disabled
          />
          <br />
          <TextField
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: "2099-12-31"
            }}
            value={
              headerSet.length > 0 && headerSet[0].dateTo
                ? moment(headerSet[0].dateTo, "YYYY/MM/DD").format("yyyy-MM-DD")
                : ""
            }
            fullWidth
            sx={{ textAlign: "left", maxWidth: "400px", mt: "20px" }}
            size="small"
            label={t("icon1_autopay_distributeSubBatch_input2")}
            variant="outlined"
            disabled
          />
          <br />

          <TextField
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: "2099-12-31"
            }}
            size="small"
            sx={{ textAlign: "left", maxWidth: "400px", mt: "20px" }}
            label={t("icon1_autopay_distributeSubBatch_input3")}
            error={!shortPeriodDateFrom}
            helperText={!shortPeriodDateFrom ? "沒有短糧期（由）" : ""}
            onChange={(event) => {
              setShortPeriodDateFrom(event.target.value);
              setVerify({
                ...verify,
                shortPeriodDateFrom: event.target.value !== "",
              });
            }}
            format="yyyy/MM/dd"
          />

          <br />

          <TextField
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: "2099-12-31"
            }}
            size="small"
            sx={{ textAlign: "left", maxWidth: "400px", mt: "20px" }}
            label={t("icon1_autopay_distributeSubBatch_input4")}
            error={!shortPeriodDateTo}
            helperText={!shortPeriodDateFrom ? "沒有短糧期（至）" : ""}
            onChange={(event) => {
              setShortPeriodDateTo(event.target.value);
              setVerify({
                ...verify,
                shortPeriodDateTo: event.target.value !== "",
              });
            }}
            format="yyyy/MM/dd"
          />

          <br />
          <Button
            onClick={handleSubmitSearch}
            sx={{ mt: "20px", width: "100px" }}
            variant="contained"
          >
            {t("button_save")}
          </Button>
          <Button
            onClick={handleBackToMainReport}
            sx={{ mt: "20px", ml: "10px", width: "100px" }}
            variant="contained"
          >
            {t("button_cancel")}
          </Button>
        </Container>
      ) : (
        <div>
          <Typography
            variant="h4"
            sx={{
              //   display: "block",
              //  fontSize: "35px",
              textAlign: "center",
            }}
          >
            <b>{t("Please_select_worker")}</b>
          </Typography>
          <table style={{ marginTop: "10px" }}>
            <tbody>
              <tr>
                <td style={{ textAlign: "left" }}>
                  {t("icon1_autopay_distributeSubBatch_print_label1")}
                </td>
                <td style={{ textAlign: "left" }}>
                  {headerSet.length > 0 && headerSet[0].dateFrom
                    ? headerSet[0].dateFrom
                    : ""}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>
                  {t("icon1_autopay_distributeSubBatch_print_label2")}
                </td>
                <td style={{ textAlign: "left" }}>
                  {headerSet.length > 0 && headerSet[0].dateTo
                    ? headerSet[0].dateTo
                    : ""}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>
                  {t("icon1_autopay_distributeSubBatch_print_label7")}
                </td>
                <td style={{ textAlign: "left" }}>
                  {shortPeriodDateFrom.replaceAll("-", "/")}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>
                  {" "}
                  {t("icon1_autopay_distributeSubBatch_print_label8")}
                </td>
                <td style={{ textAlign: "left" }}>
                  {" "}
                  {shortPeriodDateTo.replaceAll("-", "/")}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>
                  {" "}
                  {t("icon1_autopay_distributeSubBatch_print_label9")}
                </td>
                <td style={{ textAlign: "left" }}>
                  {detail.workerSet ? detail.workerSet.length : 0}
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: "block", textAlign: "left" }}>
            {/* table */}
            <TableContainer sx={{ display: "block" }}>
              <Table
                // padding="none"
                sx={{
                  pageBreakInside: "inherit",
                  minWidth: 650,
                  //  "& .MuiTableCell-root": { border: 1 },
                  pr: "5px",
                }}
                size="small"
              >
                <TableHead>
                  <TableRow hover sx={{ border: "1px" }}>
                    <TableCell sx={{ fontSize: "15px" }}>
                      {t("No.")}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "15px" }}>
                      {t("Subcontractors")}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "15px" }}>
                      {t("icon1_autopay_distributeSubBatch_print_table_label3")}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "15px" }}>
                      {t("icon1_autopay_distributeSubBatch_print_table_label4")}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "15px" }}>
                      {t("icon1_autopay_distributeSubBatch_print_table_label5")}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "15px" }}>
                      {t("icon1_autopay_distributeSubBatch_print_table_label6")}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "15px" }}>
                      {t("icon1_autopay_distributeSubBatch_print_table_label7")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detail.workerSet && detail.workerSet.length > 0 ? (
                    detail.workerSet.map((row, index) => (
                      <TableRow
                        hover
                        key={index}
                      >
                        <TableCell
                          sx={{ fontSize: "15px" }}
                          component="th"
                          scope="row"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontSize: "15px" }}
                        >
                          {getSubContractorDesc(row)}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontSize: "15px" }}
                        >
                          {getChineseNameDesc(row)}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontSize: "15px" }}
                        >
                          {row.englishName}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontSize: "15px" }}
                        >
                          {row.cardHolderId}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontSize: "15px" }}
                        >
                          {row.hkid}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontSize: "15px" }}
                        >
                          {row.bankAccount}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow hover>
                      <TableCell colSpan={7}>No Records Found</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div>
              {detail.workerSet && detail.workerSet.length > 0 ? (
                <Button
                  onClick={handleSubmitRecord}
                  sx={{ mt: "20px", ml: "10px", width: "100px" }}
                  variant="contained"
                >
                  {t("button_save")}
                </Button>
              ) : (
                <></>
              )}

              <Button
                onClick={handleBackToMainReport}
                sx={{ mt: "20px", ml: "10px", width: "100px" }}
                variant="contained"
              >
                {t("button_cancel")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
