import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'

export default function CustomDialog(props) {
  const { open, title, content, action, dialogProps } = props

  return (
    <Dialog fullWidth maxWidth="xs" open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" sx={{ whiteSpace: 'pre-line', ...dialogProps }}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{typeof content === 'string' ? <DialogContentText id="alert-dialog-description">{content}</DialogContentText> : content}</DialogContent>
      <DialogActions>{action}</DialogActions>
    </Dialog>
  )
}
