import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import { Backdrop, CircularProgress, Container, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { $download } from 'src/utils/api/services'
import * as api from '../../../utils/api/vendorAutopaySystem/WorkerSalaryMpfSafetyApi'

// //
export default function WSMSDetail1() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [mainData, setMainData] = React.useState([])
  const [dataSource, setDataSource] = React.useState({})
  const [loading, setLoading] = React.useState(true)

  useEffect(() => {}, [])

  const handleBack = () => {
    console.log(123)
    navigate(-1)
    navigate({
      pathname: '/vendorAutopaySystem/WorkerSalaryMpfSafety',
      search: `?${createSearchParams(
        {
          dateFrom: searchParams.get('dateFromId'),
          dateTo: searchParams.get('dateToId'),
          siteId: searchParams.get('siteId'),
          siteDesc: searchParams.get('siteDesc'),
          vendorDesc: searchParams.get('vendorDesc'),
          vendorId: searchParams.get('vendorId'),
          reportType: searchParams.get('reportType'),
        },
        { state: { fromSpecificPage: true } },
      )}`,
    })
  }

  const printComponentRef = useRef()

  useEffect(() => {
    getDetailSQLlistWS()
  }, [])

  const getDetailSQLlistWS = async () => {
    let list = {}

    list = await api.getDetailSQLlistWS({
      siteId: searchParams.get('siteId'),
      vendorId: searchParams.get('vendorId'),
      dateFrom: searchParams.get('dateFromId'),
      vendorDesc: searchParams.get('vendorDesc'),
      siteDesc: searchParams.get('siteDesc'),
      dateTo: searchParams.get('dateToId'),
    })

    if (list !== null) {
      console.log(list)
      setMainData(list.mainData)
      setDataSource(list)
    }
    setLoading(false)
  }

  const exportExcel = (data) => {
    $download(`/payrollReportController/getDetailSQLlistWSExcel`, 'application/vnd.ms-excel', data)
  }

  const exportPdf = (data) => {
    $download(`/payrollReportController/getDetailSQLlistWSPdf`, 'application/pdf', data)
  }

  // t("search_vendor_autoPay_statues")
  return (
    <Container maxWidth="mx">
      <div style={{ display: 'block', textAlign: 'left' }}>
        <Button sx={{ float: 'left' }} onClick={handleBack}>
          <ArrowBackIcon />
          {t('button_back')}
        </Button>
      </div>

      <div style={{ display: 'block', textAlign: 'right' }}>
        <Button
          sx={{ float: 'right', mr: '20px' }}
          onClick={() =>
            exportPdf({
              siteId: searchParams.get('siteId'),
              siteDesc: searchParams.get('siteDesc'),
              vendorId: searchParams.get('vendorId'),
              vendorDesc: searchParams.get('vendorDesc'),
              dateFrom: searchParams.get('dateFromId'),
              dateTo: searchParams.get('dateToId'),
            })
          }
        >
          <DownloadIcon />
          {t('button_export_pdf')}
        </Button>
      </div>

      <div style={{ display: 'block', textAlign: 'right' }}>
        <Button
          sx={{ float: 'right', mr: '20px' }}
          onClick={() =>
            exportExcel({
              siteId: searchParams.get('siteId'),
              siteDesc: searchParams.get('siteDesc'),
              vendorId: searchParams.get('vendorId'),
              vendorDesc: searchParams.get('vendorDesc'),
              dateFrom: searchParams.get('dateFromId'),
              dateTo: searchParams.get('dateToId'),
            })
          }
        >
          <DownloadIcon />
          {t('button_export_excel')}
        </Button>
      </div>

      <div id="div" style={{}} ref={printComponentRef}>
        <Container maxWidth="mx">
          <div>
            <div style={{ display: 'block', marginBottom: '60px' }}>
              <Typography variant="h4" sx={{ textAlign: 'center' }}>
                <b>{t('icon_vas_wsms_report_title_salary_mpf_detail')}</b>
              </Typography>
            </div>

            <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>

            {
              <div>
                <TableContainer sx={{ marginBottom: '20px', backgroundColor: 'transparent' }}>
                  <Table sx={{ textAlign: 'left' }} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('icon_vas_wsms_report_print_date') + '：' + moment(new Date()).format('yyyy/MM/DD')}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>
                          {t('Vendor')} : {''}
                          {searchParams.get('vendorDesc') !== null && searchParams.get('vendorDesc') !== '' && searchParams.get('vendorDesc') !== 'null'
                            ? searchParams.get('vendorDesc')
                            : t('All_contractors')}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>
                          {t('search_site_title2')} : {''}
                          {searchParams.get('siteId') !== null && searchParams.get('siteId') !== '' && searchParams.get('siteId') !== 'null' ? searchParams.get('siteId') : t('All_site1')}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('icon_vas_wsms_date_from') + '：' + searchParams.get('dateFromText')}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('icon_vas_wsms_date_to') + '：' + searchParams.get('dateToText')}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('data_detailrecord')}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }
          </div>

          {/* table */}
          <TableContainer sx={{ marginTop: '20px', maxWidth: '100vw' }}>
            <Table sx={{ p: '2px' }} size="small" stickyHeader>
              <TableHead>
                <TableRow hover>
                  {/* (siteName, carid, engName, chName, joy, approveDate, inDate, inVateDate, applyDate */}
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(1)}>
                      {t('icon_vas_wsms_site')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(2)}>
                      {t('icon_vas_wsms_vendor')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(3)}>
                      {t('icon_vas_wsms_report_table_header_period')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(4)}>
                      {t('icon_vas_wsms_report_table_header_worker_name')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{}}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(5)}>
                      {t('icon1_useraccesslog_smart_card_label')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{}}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(5)}>
                      {t('icon_vas_wsms_report_table_header_salary')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{}}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(5)}>
                      {t('icon_vas_wsms_report_table_header_employer_mpf')}
                    </Link>
                  </TableCell>

                  <TableCell sx={{}}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(5)}>
                      {t('icon_vas_wsms_report_table_header_employee_mpf')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{}}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(6)}>
                      {t('icon_vas_wsms_report_table_header_work_days')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{}}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(7)}>
                      {t('icon_vas_wsms_report_table_header_average_salary')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{}}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(8)}>
                      {t('icon_vas_wsms_report_table_header_remark')}
                    </Link>
                  </TableCell>
                </TableRow>
              </TableHead>

              <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>

              {mainData.map((mainDataRow, index) => (
                <>
                  {/* 單個人數據，需要循環遍歷 */}
                  <TableRow
                    hover
                    key={index + Math.random}
                    sx={{
                      backgroundColor: index % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                    }}
                  >
                    {/* 地盤 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{mainDataRow.siteDesc} </TableCell>
                    {/* 承判商 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{mainDataRow.vendorDesc} </TableCell>
                    {/* 時段 */}
                    <TableCell
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: '15px',
                        textAlign: 'left',
                      }}
                    >
                      {mainDataRow.dateFrom + '~' + mainDataRow.dateTo}
                    </TableCell>
                    {/* 	工人姓名 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{mainDataRow.englishName} </TableCell>
                    {/* 智能卡編號 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{mainDataRow.cardHolderId}</TableCell>
                    {/* 薪金 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{mainDataRow.relevantIncome?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'} </TableCell>
                    {/* 僱主MPF */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{mainDataRow.employerMPF?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'} </TableCell>
                    {/* 僱員MPF */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{mainDataRow.employeeMPF?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'} </TableCell>
                    {/* 實際上班日數 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{mainDataRow.workDayAct} </TableCell>
                    {/* 工人平均日薪 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{mainDataRow.aveSalary?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'} </TableCell>
                    {/* 備註 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{mainDataRow.payrollRemark} </TableCell>
                  </TableRow>
                </>
              ))}

              <TableRow key={Math.random + 100}>
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'left',
                  }}
                >
                  {}{' '}
                </TableCell>

                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'left',
                  }}
                >
                  {}{' '}
                </TableCell>

                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'left',
                  }}
                >
                  {}
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {'總人次:'}{' '}
                </TableCell>

                {/* 總人數  */}
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {dataSource.member}{' '}
                </TableCell>
                {/* 薪金總數  */}
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {dataSource.relevantIncomeTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}{' '}
                </TableCell>
                {/* 僱主MPF  */}
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {dataSource.employerMPFTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}{' '}
                </TableCell>
                {/* 僱員MPF */}
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {dataSource.employeeMPFTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}{' '}
                </TableCell>
                {/* 實際上班日數 */}
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {dataSource.workDayActTotal}{' '}
                </TableCell>
                {/* 工人平均日薪  */}
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'right',
                  }}
                >
                  {dataSource.averagesal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}{' '}
                </TableCell>
                {/* 備註 */}
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '15px',
                    textAlign: 'left',
                  }}
                >
                  {}{' '}
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </Container>
      </div>
    </Container>
  )
}
