import { Table, TableCell, TableHead, TableRow, TableBody, TableContainer } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function TablePart(props) {
  const { tableList, loanFlag, setNumbers, numbers } = props
  const { t } = useTranslation()
  const [countWorketIncome, setCountWorketIncome] = useState(0)
  const [aPayroll, setAPayroll] = useState(0)
  const [erMPF, setErMPF] = useState(0)
  const [eeMPF, setEeMPF] = useState(0)

  useEffect(() => {
    let wcount = 0
    let rcount = 0
    let employeeCount = 0
    let employercount = 0
    tableList.map((value, index) => {
      wcount += value.relevantIncome
      rcount += value.netIncome
      employeeCount += value.employeeMpf
      employercount += value.employerMpf
      return []
    })
    setAPayroll(rcount)
    setErMPF(employercount)
    setEeMPF(employeeCount)
    setCountWorketIncome(wcount)
  }, [])

  return (
    <>
      <TableContainer sx={{ maxHeight: '80vh', maxWidth: '100vw' }}>
        <Table
          sx={{
            mt: '10px',
            '& .MuiTableCell-root': {
              paddingLeft: '8px',
              paddingRight: '8px',
            },
          }}
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow hover>
              <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>No.</TableCell>
              {/* 英文姓名 */}
              <TableCell sx={{ fontSize: '15px' }}>{t('EnglishName')}</TableCell>
              {/* 中文姓名 */}
              <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all' }}>{t('ChineseName')}</TableCell>
              {/* 身份証號碼 */}
              <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all' }}>{t('ID_number')}</TableCell>
              {/* 工人上班日數 */}
              <TableCell
                sx={{
                  fontSize: '15px',
                  textAlign: 'right',
                  wordBreak: 'keep-all',
                }}
              >
                {t('Number_Of_Workers_Working_Days')}
              </TableCell>
              {/* 實際上班日數 */}
              <TableCell
                sx={{
                  fontSize: '15px',
                  textAlign: 'right',
                  wordBreak: 'keep-all',
                }}
              >
                {t('Actual_number_of_working_days')}
              </TableCell>
              {/* 工人入息 */}
              <TableCell
                sx={{
                  fontSize: '15px',
                  textAlign: 'right',
                  wordBreak: 'keep-all',
                }}
              >
                {t('Worker_income')}
              </TableCell>
              {loanFlag === 'Y' ? (
                <>
                  <TableCell
                    sx={{
                      fontSize: '15px',
                      textAlign: 'right',
                      wordBreak: 'keep-all',
                    }}
                  >
                    {t('Borrowing')}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '15px',
                      textAlign: 'right',
                      wordBreak: 'keep-all',
                    }}
                  >
                    {t('Payback')}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '15px',
                      textAlign: 'right',
                      wordBreak: 'keep-all',
                    }}
                  >
                    {t('Income_after_repayment')}
                  </TableCell>
                </>
              ) : null}
              {/* 發薪實額 */}
              <TableCell
                sx={{
                  fontSize: '15px',
                  textAlign: 'right',
                  wordBreak: 'keep-all',
                }}
              >
                {t('Actual_Payroll')}
              </TableCell>
              {/* 僱主強積金供款 */}
              <TableCell
                sx={{
                  fontSize: '15px',
                  textAlign: 'right',
                  wordBreak: 'keep-all',
                }}
              >
                {t('Employer_MPF_contributions')}
              </TableCell>
              {/* 僱員強積金供款 */}
              <TableCell
                sx={{
                  fontSize: '15px',
                  textAlign: 'right',
                  wordBreak: 'keep-all',
                }}
              >
                {t('Employee_MPF_contributions')}
              </TableCell>
              {/* 平均入息 */}
              <TableCell
                sx={{
                  fontSize: '15px',
                  textAlign: 'right',
                  wordBreak: 'keep-all',
                }}
              >
                {t('Average_income')}
              </TableCell>
              {/* 員工戶口號碼 */}
              <TableCell sx={{ fontSize: '15px' }}>{t('Employee_Account_Number')}</TableCell>
              {/* 計劃成員編號 */}
              <TableCell sx={{ fontSize: '15px' }}>{t('plan_Member_Number')}</TableCell>
              {/* 備註 */}
              <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all' }}>{t('Remark')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableList.length > 0 ? (
              <>
                {tableList.map((row, index) => (
                  <TableRow
                    hover
                    key={index}
                    sx={{
                      backgroundColor: index % 2 !== 0 ? '#DCDCDC' : '#ffffff',
                    }}
                  >
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }} component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell sx={{ fontSize: '15px', whiteSpace: 'nowrap' }}>{row.englishName} </TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>{row.chineseName}</TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>{row.hkid}</TableCell>
                    <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                      {row.workDay}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                      {row.workDayAct}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                      {row.relevantIncomeForMat && row.relevantIncomeForMat}
                    </TableCell>
                    {loanFlag === 'Y' ? (
                      <>
                        <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{row.advPayTotamt && row.advPayTotamt.toFixed(2)}</TableCell>
                        <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{row.advPayTotamt && row.advPayTotamt.toFixed(2)}</TableCell>
                        <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>
                          欠款:
                          {row.advPayOutstd && row.advPayOutstd.toFixed(2)}
                        </TableCell>
                      </>
                    ) : null}
                    <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                      {row.netIncomeForMat && row.netIncomeForMat}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                      {row.employerMpfForMat && row.employerMpfForMat}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                      {row.employeeMpfForMat && row.employeeMpfForMat}
                    </TableCell>
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{row.dailyIncomeForMat && row.dailyIncomeForMat}</TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>{row.bankAccount}</TableCell>
                    <TableCell align="left" sx={{ fontSize: '15px' }}>
                      {row.mpfScheme}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: '15px', wordBreak: 'keep-all' }}>
                      {row.payrollRemark}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan="14" sx={{ textAlign: 'center', fontSize: '15px', px: '5px' }}>
                  No Record
                </TableCell>
              </TableRow>
            )}
            <TableRow hover>
              {/* <TableCell sx={{ fontSize: '15px', px: '5px' }} component="th" scope="row" />
                        <TableCell  sx={{ fontSize: '15px', px: '5px' }} /> */}
              <TableCell colSpan="6" sx={{ textAlign: 'right', fontSize: '15px' }}>
                {' '}
                {t('Total_Contractors')}:
              </TableCell>
              <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                {countWorketIncome.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
              </TableCell>
              {loanFlag === 'Y' ? (
                <>
                  <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>0</TableCell>
                  <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>0</TableCell>
                  <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>0</TableCell>
                </>
              ) : null}
              <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                {aPayroll.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
              </TableCell>
              <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                {erMPF.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
              </TableCell>
              <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                {eeMPF.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
              </TableCell>
              <TableCell align="right" sx={{ fontSize: '15px' }} />
              <TableCell align="right" sx={{ fontSize: '15px' }} />
              <TableCell align="right" sx={{ fontSize: '15px' }} />
              <TableCell align="right" sx={{ fontSize: '15px' }} />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
