import { Navigate, useRoutes } from 'react-router-dom'
// layouts
import DashboardLayout from './layouts/dashboard'
import SimpleLayout from './layouts/simple'
//

import LoginPage from './pages/LoginPage'
import IndexPage from './pages/IndexPage'
import Page404 from './pages/Page404'
import HomePage from './pages/HomePage'
import Autopay from './pages/icon/workerAttendanceRecord/AutoPay'
import SearchSalaryAndMpf from './pages/icon/workerAttendanceRecord/SearchSalaryAndMpf'
import AccRecSearch from './pages/icon/workerAttendanceRecord/AccRecSearch'
import AccRecSearchWithVendor from './pages/icon/workerAttendanceRecord/AccRecSearchWithVendor'
import AccRecVendorSearch from './pages/icon/workerAttendanceRecord/AccRecVendorSearch'
import AccRecVendorApprovedSearch from './pages/icon/workerAttendanceRecord/AccRecVendorApprovedSearch'
import PersonalEnterSearch from './pages/icon/workerAttendanceRecord/PersonalEnterSearch'
import PersonalEnterSearchWithVendor from './pages/icon/workerAttendanceRecord/PersonalEnterSearchWithVendor'
import RptEstimateEmployerMPFSearch from './pages/icon/vendorAutopaySystem/RptEstimateEmployerMPFSearch'
import ReportVendorBasicSalarySearch from './pages/icon/vendorAutopaySystem/ReportVendorBasicSalarySearch'
import RptPFSASVendorBonusList from './pages/icon/vendorAutopaySystem/RptPFSASVendorBonusList'
import RptVendorautopaystatuslist2Search from './pages/icon/vendorAutopaySystem/RptVendorautopaystatuslist2Search'
import RptVendorautopaystatusSearch from './pages/icon/vendorAutopaySystem/RptVendorautopaystatusSearch'
import RoleSetupIndex from './pages/icon/systemAdministrator/RoleSetupIndex'
import NscVendorList from './pages/icon/systemAdministrator/NscVendorList'
import NscJobNatureList from './pages/icon/systemAdministrator/NscJobNatureList'
import JobNatureList from './pages/icon/systemAdministrator/JobNatureList'

import RegistrationRecordSearch from './pages/adocsp/registrationRecord/RegistrationRecordSearch'
import RegistrationRecordDetail from './pages/adocsp/registrationRecord/RegistrationRecordDetail'

import AdocspMain from './pages/adocsp/AdocspMain'
import SearchChoosePerson from './pages/adocsp/SearchChoosePerson'
//
import ApplyList from './pages/adocsp/apply/ApplyList'
import ApplyListDetail from './pages/adocsp/apply/ApplyListDetail'
import ApplyEditOrViewSubmit from './pages/adocsp/apply/ApplyEditOrViewSubmit'
import ApplyEditOrView from './pages/adocsp/apply/ApplyEditOrView'
import ApplyDayEditOrView from './pages/adocsp/apply/ApplyDayEditOrView'
import ApplyHisrotyView from './pages/adocsp/apply/ApplyHistoryView'

import ReportMonthsDetailView from './pages/adocsp/reportMonths/ReportMonthsDetailView'
//
import ReportDetailSearch from './pages/adocsp/reportDetail/ReportDetailSearch'
import ReportDetailSearchResult from './pages/adocsp/reportDetail/ReportDetailSearchResult'
//
import ReportDaysSearch from './pages/adocsp/reportDays/ReportDaysSearch'
import ReportDaysSearchResult from './pages/adocsp/reportDays/ReportDaysSearchResult'
//
import ReportMonthsSearch from './pages/adocsp/reportMonths/ReportMonthsSearch'
import ReportMonthsSearchResult from './pages/adocsp/reportMonths/ReportMonthsSearchResult'
//
import LoeaerSearch from './pages/loeaer/LoeaerSearch'
import LoeaerDetail from './pages/loeaer/LoeaerDetail'
import LoeaerDetailAndDailyIncome from './pages/loeaer/LoeaerDetailAndDailyIncome'
import LoeaerIncomeDetail from './pages/loeaer/LoeaerIncomeDetail'
//
import CattpwspPerQuarterly from './pages/vas/CattpwspPerQuarterly'
import CattpwspPerQuarterlyDetail from './pages/vas/CattpwspPerQuarterlyDetail'
import CattpwspPerMonth from './pages/vas/CattpwspPerMonth'
import CattpwspReport from './pages/vas/CattpwspReport'
import CattpwspPerMonthDetail from './pages/vas/CattpwspPerMonthDetail'
import CattpwspReportDetail from './pages/vas/CattpwspReportDetail'
import CattpwspReportChartDetail from './pages/vas/CattpwspReportChartDetail'

import CwprfSearch from './pages/cwprf/CwprfSearch'
import ToweafMain from './pages/toweaf/ToweafMain'
import CwprfDetail from './pages/cwprf/CwprfDetail'
import QtoweafSearch from './pages/toweaf/QtoweafSearch'
import QtoweafDetail from './pages/toweaf/QtoweafDetail'
import CarSearch from './pages/car/CarSearch'
import ToweafSearch from './pages/toweaf/ToweafSearch'
import ToweafDetail from './pages/toweaf/ToweafDetail'
import CarSearchDetail from './pages/car/CarSearchDetail'
import CarDateDetailShort from './pages/car/CarDateDetailShort'
import WorkerSalaryAnalysisTrade from './pages/icon/vendorAutopaySystem/WorkerSalaryAnalysisTrade'
import WorkerSalaryMpfSafety from './pages/icon/vendorAutopaySystem/WorkerSalaryMpfSafety'
import WSMSDetail1 from './pages/icon/vendorAutopaySystem/WSMSDetail1'
import WSMSDetail2 from './pages/icon/vendorAutopaySystem/WSMSDetail2'
import WSMSDetail3 from './pages/icon/vendorAutopaySystem/WSMSDetail3'
import ContactInformationForm from './pages/otherDataSetting/ContactInformationForm'
import OtherDataSetting from './pages/otherDataSetting/OtherDataSetting'
import AutopayPayrollReportAll from './pages/icon/vendorAutoSystem/AutopayPayrollReportAll'
import AverageDailyWageIncreaseReport from './pages/icon/vendorAutoSystem/AverageDailyWageIncreaseReport'
import RptWorkersautopaystatusMPFlist from './pages/icon/vendorAutopaySystem/RptWorkersautopaystatusMPFlist'
import ContractorAutopayStatus from './pages/icon/vendorAutoSystem/ContractorAutopayStatus'
import RPTworkersautopaystatusvendorlistwholemonth from './pages/icon/vendorAutopaySystem/RPTworkersautopaystatusvendorlistwholemonth'
import RptVendorAutoPaySummaryMain from './pages/icon/vendorAutopaySystem/RptVendorAutoPaySummaryMain'
import AutopayPayrollReportByPeriod from './pages/icon/vendorAutoSystem/AutopayPayrollReportByPeriod'
import RptSuspendpaymentvendor from './pages/icon/vendorAutopaySystem/RptSuspendpaymentvendor'
import RPToutstandingvendorlist from './pages/icon/vendorAutopaySystem/RPToutstandingvendorlist'
import AnalysischartMonthly from './pages/icon/vendorAutoSystem/AnalysischartMonthly'
import AnalysisChartYear from './pages/icon/vendorAutoSystem/AnalysisChartYear'
import EditAutotransferFile from './pages/icon/systemAdministrator/EditAutotransferFile'
import SetUpUser from './pages/icon/systemAdministrator/SetupUser'
import ChangeEmail from './pages/icon/systemAdministrator/ChangeEmail'
import FAQs from './pages/icon/systemAdministrator/FAQs'
import SetupMenu from './pages/icon/systemAdministrator/SetupMenu'
import RptWorkerSautoPayStatusSiteListTeamA from './pages/icon/vendorAutopaySystem/RptWorkerSautoPayStatusSiteListTeamA'
import RptWorkerSautoPayStatusSiteListTeamB from './pages/icon/vendorAutopaySystem/RptWorkerSautoPayStatusSiteListTeamB'
import RptWorkersAutoPayStatusSiteList from './pages/icon/vendorAutopaySystem/RptWorkersAutoPayStatusSiteList'
import RptWorkersAutoPayStatusSiteSumMaryB from './pages/icon/vendorAutopaySystem/RptWorkersAutoPayStatusSiteSumMaryB'

import ReportContractorAutopayStatus from './pages/icon/vendorAutopaySystem/ReportContractorAutopayStatus'

// code by hwf ↓
import NewPayScheduleControl from './pages/icon/systemAdministrator/NewPayScheduleControl'
import VendorAutopayReceiptControl from './pages/icon/systemAdministrator/VendorAutopayReceiptControl'
import SystemParameter from './pages/icon/systemAdministrator/SystemParameter'
import NonVendorCategorySetup from './pages/icon/systemAdministrator/NonVendorCategorySetup'
import MPFRangeSetup from './pages/icon/systemAdministrator/MPFRangeSetup'
import ArticleSetup from './pages/icon/systemAdministrator/ArticleSetup'
import CategorySetup from './pages/icon/systemAdministrator/CategorySetup'
import NotificationItemSetup from './pages/icon/notificationItem/NotificationItemSetup'
import VendorAuthorSetup from './pages/icon/vendorAutoSystem/vendorAuthor/VendorAuthorSetup'
import VendorAuthorReport from './pages/icon/vendorAutoSystem/vendorAuthor/VendorAuthorReport'
import RedirectLogin from './pages/RedirectLogin'
import RedirectOtherSystem from './pages/RedirectOtherSystem'
import TestPage from './pages/TestPage'
import LoeaerDetailAndDailyIncomeWithVendor from './pages/loeaerMainWithVendor/LoeaerDetailAndDailyIncomeWithVendor'
import LoeaerDetailWithVendor from './pages/loeaerMainWithVendor/LoeaerDetailWithVendor'
import LoeaerIncomeDetailWithVendor from './pages/loeaerMainWithVendor/LoeaerIncomeDetailWithVendor'
import LoeaerSearchWithVendor from './pages/loeaerMainWithVendor/LoeaerSearchWithVendor'

// ----------------------------------------------------------------------
// 接触这个项目会变得非常的不幸
// +1
// +1+1
// 不幸你個頭 合併代碼的時候看著點行嗎 給你一拳

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to="indexPage" />, index: true },
        { path: 'indexPage', element: <IndexPage /> },
      ],
    },
    {
      path: '/redirect-login',
      //  element: <DashboardLayout type={"index"} />,
      children: [{ path: '', element: <RedirectLogin /> }],
    },
    {
      path: '/other-system',
      element: <DashboardLayout type={'index'} />,
      children: [{ path: '', element: <RedirectOtherSystem /> }],
    },
    {
      path: '/homePage',
      element: <DashboardLayout type={'index'} />,
      children: [
        { path: '', element: <HomePage /> },
        { path: 'changeEmail', element: <ChangeEmail /> },
        { path: 'faqs', element: <FAQs /> },
      ],
    },
    {
      path: '/workerAttendanceRecord',
      element: <DashboardLayout type={'ICON1'} />,
      children: [
        { path: 'accRecSearch', element: <AccRecSearch /> },
        { path: 'accRecVendorSearch', element: <AccRecVendorSearch /> },
        {
          path: 'accRecVendorApprovedSearch',
          element: <AccRecVendorApprovedSearch />,
        },
        { path: 'personalEnterSearch', element: <PersonalEnterSearch /> },
        { path: 'personalEnterSearchWithVendor', element: <PersonalEnterSearchWithVendor /> },
        // 承判上安全人员出席天数主页 路由
        { path: 'adocspMain', element: <AdocspMain /> },
        { path: 'searchChoosePerson', element: <SearchChoosePerson /> },
        // 承判商派駐地盤安全主任/安全督導員登記記錄 路由
        {
          path: 'adocspMain/registrationRecord/registrationRecordSearch',
          element: <RegistrationRecordSearch />,
        },
        {
          path: 'adocspMain/registrationRecord/registrationRecordDetail',
          element: <RegistrationRecordDetail />,
        },
        //
        { path: 'adocspMain/applyList/ApplyList', element: <ApplyList /> },
        {
          path: 'adocspMain/applyList/applyListDetail',
          element: <ApplyListDetail />,
        },
        {
          path: 'adocspMain/applyList/applyHistoryView',
          element: <ApplyHisrotyView />,
        },
        {
          path: 'adocspMain/applyList/applyEditOrViewSubmit',
          element: <ApplyEditOrViewSubmit />,
        },
        {
          path: 'adocspMain/applyList/applyEditOrView',
          element: <ApplyEditOrView />,
        },
        {
          path: 'adocspMain/applyList/applyDayEditOrView',
          element: <ApplyDayEditOrView />,
        },
        {
          path: 'adocsp/reportMonths/ReportMonthsDetailView',
          element: <ReportMonthsDetailView />,
        },
        //
        {
          path: 'adocspMain/reportDetailSearch',
          element: <ReportDetailSearch />,
        },
        {
          path: 'adocspMain/reportDetailSearch/ReportDetailSearchResult',
          element: <ReportDetailSearchResult />,
        },
        //
        { path: 'adocspMain/reportDaysSearch', element: <ReportDaysSearch /> },
        {
          path: 'adocspMain/reportDaysSearch/reportDaysSearchResult',
          element: <ReportDaysSearchResult />,
        },
        //
        {
          path: 'adocspMain/reportMonthsSearch',
          element: <ReportMonthsSearch />,
        },
        {
          path: 'adocspMain/reportMonthsSearch/reportMonthsSearchResult',
          element: <ReportMonthsSearchResult />,
        },
        // 工人出入記錄一覽表
        { path: 'loeaerMain', element: <LoeaerSearch /> },
        { path: 'loeaerMain/loeaerDetail', element: <LoeaerDetail /> },
        {
          path: 'loeaerMain/loeaerDetailAndDailyIncome',
          element: <LoeaerDetailAndDailyIncome />,
        },
        {
          path: 'loeaerMain/loeaerIncomeDetail',
          element: <LoeaerIncomeDetail />,
        },
        //
        { path: 'accRecSearchWithVendor', element: <AccRecSearchWithVendor /> },

        // 工人出入記錄一覽表攜帶VendorT條件/workerAttendanceRecord/loeaerMainWithVendor
        { path: 'loeaerMainWithVendor', element: <LoeaerSearchWithVendor /> },
        {
          path: 'loeaerMainWithVendor/loeaerDetailWithVendor',
          element: <LoeaerDetailWithVendor />,
        },
        {
          path: 'loeaerMainWithVendor/loeaerDetailAndDailyIncomeWithVendor',
          element: <LoeaerDetailAndDailyIncomeWithVendor />,
        },
        {
          path: 'loeaerMainWithVendor/loeaerIncomeDetailWithVendor',
          element: <LoeaerIncomeDetailWithVendor />,
        },

        // 承判商工人支薪記錄表
        { path: 'cwprfSearch', element: <CwprfSearch /> },
        { path: 'cwprfSearch/cwprfDetail', element: <CwprfDetail /> },
        { path: 'cwprfSearch/cwprfDetail', element: <CwprfDetail /> },
        {
          path: 'rptPFSASVendorBonusList',
          element: <RptPFSASVendorBonusList />,
        },
        //
        { path: 'toweafMain', element: <ToweafMain /> },
        { path: 'toweafMain/qtoweafSearch', element: <QtoweafSearch /> },
        {
          path: 'toweafMain/qtoweafSearch/qtoweafDetail',
          element: <QtoweafDetail />,
        },
        { path: 'toweafMain/toweafSearch', element: <ToweafSearch /> },
        {
          path: 'toweafMain/toweafSearch/toweafDetail',
          element: <ToweafDetail />,
        },
        //
        { path: 'autoPay', element: <Autopay /> },
        { path: 'searchSalaryAndMpf', element: <SearchSalaryAndMpf /> },
      ],
    },

    {
      path: '/vendorAutopaySystem',
      element: <DashboardLayout type={'ICON2'} />,
      children: [
        { path: 'cattpwspPerQuarterly', element: <CattpwspPerQuarterly /> },
        {
          path: 'workerSalaryAnalysisTrade',
          element: <WorkerSalaryAnalysisTrade />,
        },
        {
          path: 'rptWorkerSautoPayStatusSiteListTeamA',
          element: <RptWorkerSautoPayStatusSiteListTeamA />,
        },
        {
          path: 'rptWorkerSautoPayStatusSiteListTeamB',
          element: <RptWorkerSautoPayStatusSiteListTeamB />,
        },
        {
          path: 'rptWorkersAutoPayStatusSiteList',
          element: <RptWorkersAutoPayStatusSiteList />,
        },
        {
          path: 'rptWorkersAutoPayStatusSiteSumMaryBList',
          element: <RptWorkersAutoPayStatusSiteSumMaryB />,
        },
        { path: 'WorkerSalaryMpfSafety', element: <WorkerSalaryMpfSafety /> },
        { path: 'WorkerSalaryMpfSafety/wSMSDetail1', element: <WSMSDetail1 /> },
        { path: 'WorkerSalaryMpfSafety/wSMSDetail2', element: <WSMSDetail2 /> },
        { path: 'WorkerSalaryMpfSafety/wSMSDetail3', element: <WSMSDetail3 /> },
        {
          path: 'cattpwspPerQuarterlyDetail',
          element: <CattpwspPerQuarterlyDetail />,
        },
        { path: 'cattpwspPerMonth', element: <CattpwspPerMonth /> },
        { path: 'cattpwspReport', element: <CattpwspReport /> },
        { path: 'cattpwspPerMonthDetail', element: <CattpwspPerMonthDetail /> },
        { path: 'cattpwspReportDetail', element: <CattpwspReportDetail /> },
        {
          path: 'autopayPayrollReportAll',
          element: <AutopayPayrollReportAll />,
        },
        {
          path: 'averageDailyWageIncreaseReport',
          element: <AverageDailyWageIncreaseReport />,
        },
        {
          path: 'cattpwspReportChartDetail',
          element: <CattpwspReportChartDetail />,
        },
        {
          path: 'contractorAutopayStatus',
          element: <ContractorAutopayStatus />,
        },
        {
          path: 'autopayPayrollReportByPeriod',
          element: <AutopayPayrollReportByPeriod />,
        },
        { path: 'analysischartMonthly', element: <AnalysischartMonthly /> },
        { path: 'analysisChartYear', element: <AnalysisChartYear /> },
        {
          path: 'rptEstimateEmployerMPFSearch',
          element: <RptEstimateEmployerMPFSearch />,
        },
        {
          path: 'reportVendorBasicSalarySearch',
          element: <ReportVendorBasicSalarySearch />,
        },
        {
          path: 'rPTworkersautopaystatusvendorlistwholemonth',
          element: <RPTworkersautopaystatusvendorlistwholemonth />,
        },
        {
          path: 'rptPFSASVendorBonusList',
          element: <RptPFSASVendorBonusList />,
        },
        {
          path: 'rPToutstandingvendorlist',
          element: <RPToutstandingvendorlist />,
        },
        {
          path: 'rptSuspendpaymentvendor',
          element: <RptSuspendpaymentvendor />,
        },
        {
          path: 'rptVendorautopaystatuslist2Search',
          element: <RptVendorautopaystatuslist2Search />,
        },
        {
          path: 'rptVendorAutoPaySummaryMain',
          element: <RptVendorAutoPaySummaryMain />,
        },
        {
          path: 'rptWorkersautopaystatusMPFlist',
          element: <RptWorkersautopaystatusMPFlist />,
        },
        {
          path: 'rptVendorautopaystatusSearch',
          element: <RptVendorautopaystatusSearch />,
        },
        { path: 'carSearch/carSearchDetail', element: <CarSearchDetail /> },
        {
          path: 'carSearch/carSearchDetail/carDateDetailShort',
          element: <CarDateDetailShort />,
        },
        {
          path: 'reportContractorAutopayStatus',
          element: <ReportContractorAutopayStatus />,
        },
      ],
    },
    {
      path: '/systemAdministrator',
      element: <DashboardLayout type={'ICON3'} />,
      children: [
        { path: 'newPayScheduleControl', element: <NewPayScheduleControl /> },
        { path: 'roleSetupIndex', element: <RoleSetupIndex /> },
        { path: 'nscVendorList', element: <NscVendorList /> },
        { path: 'jobNatureList', element: <JobNatureList /> },
        { path: 'nscJobNatureList', element: <NscJobNatureList /> },
        {
          path: 'vendorAutopayReceiptControl',
          element: <VendorAutopayReceiptControl />,
        },
        { path: 'systemParameter', element: <SystemParameter /> },
        { path: 'nonVendorCategorySetup', element: <NonVendorCategorySetup /> },
        { path: 'mPFRangeSetup', element: <MPFRangeSetup /> },
        { path: 'carSearch', element: <CarSearch /> },
        { path: 'carSearch/carSearchDetail', element: <CarSearchDetail /> },
        {
          path: 'carSearch/carSearchDetail/carDateDetailShort',
          element: <CarDateDetailShort />,
        },
        { path: 'articleSetup', element: <ArticleSetup /> },
        { path: 'categorySetup', element: <CategorySetup /> },
        { path: 'editAutotransferFile', element: <EditAutotransferFile /> },
        { path: 'setupUser', element: <SetUpUser /> },
        { path: 'setupMenu', element: <SetupMenu /> },
      ],
    },
    {
      path: '/system',
      element: <DashboardLayout type={'ICON12'} />,
      children: [],
    },
    {
      path: '/vendorAutopaySystemNew',
      element: <DashboardLayout type={'ICON14'} />,
      children: [
        { path: 'vendorAuthorSetup', element: <VendorAuthorSetup /> },
        { path: 'vendorAuthorReport', element: <VendorAuthorReport /> },
      ],
    },
    {
      path: '/test',
      element: <DashboardLayout type={'ICON4'} />,
      children: [{ path: 'testPage', element: <TestPage /> }],
    },
    {
      path: '/notificationItem',
      element: <DashboardLayout type={'ICON4'} />,
      children: [{ path: 'notificationItemSetup', element: <NotificationItemSetup /> }],
    },
    {
      path: '/otherDataSetting',
      element: <DashboardLayout type={'ICON5'} />,
      children: [
        { path: 'otherDataSetting/:menuId', element: <OtherDataSetting /> },
        { path: 'vendorAuthorSetup', element: <VendorAuthorSetup /> },
        { path: 'vendorAuthorReport', element: <VendorAuthorReport /> },
        { path: 'contactInformationForm', element: <ContactInformationForm /> },
      ],
    },

    {
      path: '/tender',
      element: <DashboardLayout type={'ICON6'} />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/" replace={false} /> },
      ],
    },
  ])

  return routes
}
