import React, { useRef, useState, useEffect} from 'react';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableCell, Radio, RadioGroup,
  TableContainer, Link, Grid, FormControl, FormControlLabel,
  TableRow, Backdrop, CircularProgress,

} from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import TablePagination from '@mui/material/TablePagination';
import DownloadIcon from "@mui/icons-material/Download";
import CustomDialog from "src/components/common/CustomDialog";
import moment from 'moment'
import { sub } from 'date-fns';
import $http, { $download } from 'src/utils/api/services'
import { getComparator, stableSort } from '../TableSort';

// 終止工人進入地盤申請表----終止--詳情
export default function ToweafDetail() {

  const componentRef = useRef()
  const [searchParams, createSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [mainData, setMainData] = React.useState([]);
  const [titleData1, setTitleData1] = React.useState({});
  const [titleData2, setTitleData2] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [isChangeData, setIsChangeData] = React.useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('siteDesc');

  const handleBack = () => {
    navigate(-1)
  }

  const printComponentRef = useRef();

  const getApprovedDesc = (rejected) => {
    return rejected === '1' ? <label>{t("search_keep_title")}</label> : <label>{t("button_delete")}</label>
  }

  const getDeleteCount = () => {
    return mainData.filter((value) => { return value.approved === "1" }).length;
  }

  const getKeepCount = () => {
    return mainData.filter((value) => { return value.rejected === "1" }).length;
  }

  const getLastDestinMonthDate = (nmonthsWithoutAr) => {
    const myMomentObject = moment(searchParams.get("applyDate"), 'YYYY/MM/DD')

    let t = moment(myMomentObject).subtract(nmonthsWithoutAr, 'months').format('YYYY/MM/DD')
    return t
  }

  const getLastDestinDayDate = (nmonthsWithoutAr) => {
    const myMomentObject = moment(searchParams.get("applyDate"), 'YYYY/MM/DD')

    let t = moment(myMomentObject).subtract(1, 'days').format('YYYY/MM/DD')
    return t
  }

  const exportExcel = (data) => {
    $download(`/attendance/report/getUpdateDataExcel`, 'application/vnd.ms-excel', data)
  };



  const exportPDF = (data) => {
    console.log("tset", searchParams.get("applyDate"))

    $download(`/attendance/report/getUpdateDataPdf`, 'application/pdf', data)

  };

  function clickCell(index) {
    switch (index) {
      case 1: setOrderBy('siteDesc'); break;
      case 2: setOrderBy('subContractorDesc'); break;
      case 3: setOrderBy('jobNatureDesc'); break;
      case 4: setOrderBy('smartCardId'); break;
      case 5: setOrderBy('chineseName'); break;
      case 6: setOrderBy('englishName'); break;
      case 7: setOrderBy('effectiveDate'); break;
      case 8: setOrderBy('rejected'); break;
      default: setOrderBy('siteDesc'); break;
    }

    if (order === 'asc') {
      setOrder('desc')
    } else {
      setOrder('asc')
    }
  }

  const getVendorDesc = () => {
    if (titleData1 === null) {
      return ''
    } else {
      return titleData1.vendorDesc
    }
  }

  // 修改approve状态
  const changeApproveStatues = (event, row) => {
    let tempmData = mainData.map((value) => {
      if (value.applicationDate === row.applicationDate && value.cardHolderId === row.cardHolderId && value.siteId === row.siteId && value.effectiveDate === row.effectiveDate) {
        value.rejected = event.target.value
        value.approved = (event.target.value === "1" ? "0" : "1")
      }
      return value
    })

    setMainData([...tempmData])
    setIsChangeData(true)
  };

  useEffect(() => {

    getUpdateData()
  }, [])


  const [dialog, setDialog] = useState({
    title: "",
    content: "",
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false });
        }}
      >
        {t("Confirm")}
      </Button>
    ),
    open: false,
  });

  const getUpdateData = async () => {
    setLoading(true)
    let list = await apiAtt.getUpdateData(searchParams.get("applyDate"),)
    if (list !== null) {
      setMainData([...list.mainData])
      setTitleData1(list.titleData1)
      setTitleData2(list.titleData2)
    }
    setIsChangeData(false)
    setLoading(false)
  }

  // 存储数据
  const saveData = async () => {
    setLoading(true)
    let saveResult = await apiAtt.updateData(mainData)

    if (saveResult !== null) {
      setDialog({
        ...dialog,
        content: t("search_saveSuccess_tips"),
        action: (
          <Button
            variant="contained"
            onClick={() => {
              getUpdateData()
              setDialog({ ...dialog, open: false });
            }}
          >
            {t("Confirm")}
          </Button>
        ),
        open: true,
      });

    }

    setLoading(false)
  }

  // 進入查詢搜索界面
  const goCheckSearchPage = async () => {

    navigate('/workerAttendanceRecord/toweafMain/qtoweafSearch');
  }

  const isSubmit = () => {
    return (titleData1.submitted === '1')
  }

  //  递交数据請求
  const requestSumbitData = async () => {
    setLoading(true)
    let saveResult = await apiAtt.submitDataAcsArTermination(mainData)

    if (saveResult !== null) {
      setDialog({
        ...dialog,
        content: "遞交成功",
        action: (
          <Button
            variant="contained"
            onClick={() => {
              getUpdateData()
              setDialog({ ...dialog, open: false });
            }}
          >
            {t("Confirm")}
          </Button>
        ),
        open: true,
      });

    }

    setLoading(false)

  }

  // 递交数据
  const sumbitData = async () => {
    setDialog({
      ...dialog,
      content: t("search_confirm_sumbit_data_title"),
      action: (
        <div>
          <Button sx={{ marginRight: '10px' }}
            variant="contained"
            onClick={async () => {
              setDialog({ ...dialog, open: false });
            }}
          >
            {t("button_cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={async () => {
              requestSumbitData()

              setDialog({ ...dialog, open: false });
            }}
          >
            {t("Confirm")}
          </Button>
        </div>
      ),
      open: true,
    });

  }


  return (
    <Container maxWidth="false"   >
      <div style={{ display: 'block', textAlign: 'left' }}>
        <Button sx={{ float: 'left' }}
          onClick={handleBack}><ArrowBackIcon />{t('button_back')}</Button>
      </div>

      <div style={{ display: "block", textAlign: "right" }}>
        <Button sx={{ float: "right", mr: "20px" }} onClick={() => exportExcel({ applicationDate: searchParams.get("applyDate") })}>
          <DownloadIcon />
          {t("button_export_excel")}
        </Button>
        <Button sx={{ float: "right", mr: "20px" }} onClick={() => exportPDF({ applicationDate: searchParams.get("applyDate") })}>
          <DownloadIcon />
          {t("button_export_pdf")}
        </Button>
      </div>

      <div id='div' style={{}} ref={printComponentRef} >
        <Container maxWidth="false">
          <div  >
            <div style={{ display: 'block', marginBottom: '60px' }}>
              <Typography variant='h4' sx={{ textAlign: 'center' }}>
                <b>{t("search_teminate_site_apply")}</b></Typography>
            </div>

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            {(<div  >
              <TableContainer component={Paper} sx={{ marginBottom: '20px', backgroundColor: 'transparent' }}>
                <Table sx={{ textAlign: 'left' }} size="small"  >
                  <TableBody>
                    <TableRow    >
                      <TableCell sx={{ px: '5px', border: 0 }}>{t("search_apply_date_dot") + searchParams.get("applyDate")}</TableCell>
                    </TableRow>

                    <TableRow    >
                      <TableCell sx={{ px: '5px', border: 0 }}>{t("search_vendor_desc") + getVendorDesc()}</TableCell>
                    </TableRow>


                    <TableRow    >

                      <TableCell sx={{ px: '5px', border: 0 }}>{t("search_suggest_teminate_siteDate") + titleData1.proposedEffectiveEndDate}</TableCell>

                    </TableRow>
                    <TableRow    >
                      <TableCell sx={{ px: '5px', border: 0 }}>{
                        t("search_apply_list_remark") + titleData2.monthlyCutOffDay +
                        t("search_day_aotuo_submit")
                      }</TableCell>
                    </TableRow>
                    <TableRow    >
                      <TableCell sx={{ px: '5px', border: 0 }}>
                        {
                          t("search_teminate_title") + titleData2.nmonthsWithoutAr +
                          t("search_in_month") + getLastDestinMonthDate(titleData2.nmonthsWithoutAr) +
                          t("search_to_tips") +
                          getLastDestinDayDate(titleData2.nmonthsWithoutAr) +
                          t("search_noPermit_to_site")
                        }
                      </TableCell>
                    </TableRow>

                    <TableRow    >
                      <TableCell sx={{ px: '5px', border: 0 }}>
                        {
                          t("search_totals_title") + mainData.length +
                          t("search_record_desc_title") + getDeleteCount() +
                          t("search_delete_desc") +
                          getKeepCount() +
                          t("search_itemKeep_desc")
                        }
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </div>)}
          </div>

          {/* 沒有確認前可以操作 */}
          {!isSubmit() && (
            <Grid item xs={12} sx={{ textAlign: "center" }}>

              {/* 存儲 */}
              <FormControl size="small">
                <Button
                  sx={{
                    backgroundColor: "primaryBlue",
                    color: "commom.white",
                  }}
                  variant="contained"
                  onClick={saveData}
                  disabled={isChangeData ? null : true}
                >
                  {t("search_btn_save")}
                </Button>
              </FormControl>

              {/* 遞交 */}

              <FormControl size="small">
                <Button
                  variant="contained"
                  onClick={sumbitData}
                  label="Submit"
                  sx={{
                    backgroundColor: "primaryGreen",
                    color: "commom.white",
                    marginLeft: "20px",
                  }}
                >
                  {t("button_submit")}
                </Button>
              </FormControl>

              {/* 重置 */}
              <FormControl size="small">
                <Button
                  variant="contained"
                  onClick={getUpdateData}
                  label="Reset"
                  sx={{
                    backgroundColor: "primaryGray",
                    color: "commom.white",
                    marginLeft: "20px",
                  }}
                >
                  {t("button_reset")}
                </Button>
              </FormControl>
            </Grid>)}

          {/* 確認后只能查詢 */}
          {isSubmit() && (
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <FormControl size="small">
                <Button
                  sx={{
                    backgroundColor: "primaryBlue",
                    color: "commom.white",
                  }}
                  variant="contained"
                  onClick={goCheckSearchPage}
                >
                  {t("search_btn_title")}
                </Button>
              </FormControl>
            </Grid>
          )}

          {/* table */}
          <TableContainer sx={{ marginTop: '20px' }}>
            <Table sx={{ p: '2px' }} size="small" >
              <TableHead>
                <TableRow hover  >
                  <TableCell sx={{ whiteSpace: "nowrap", }} >
                    <Link style={{ cursor: 'pointer' }} underline="none" sx={{ whiteSpace: "nowrap", }}
                      onClick={() => clickCell(1)}>
                      {t("search_site_title")}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", }}>
                    <Link style={{ cursor: 'pointer' }} underline="none" sx={{ whiteSpace: "nowrap", }}
                      onClick={() => clickCell(2)}>
                      {t("Subcontractors")}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", }}>
                    <Link style={{ cursor: 'pointer' }} underline="none" sx={{ whiteSpace: "nowrap", }}
                      onClick={() => clickCell(3)}>
                      {t("search_work_type")}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", }}>
                    <Link style={{ cursor: 'pointer' }} underline="none" sx={{ whiteSpace: "nowrap", }}
                      onClick={() => clickCell(4)}>
                      {t("search_smartcardId")}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", }}>
                    <Link style={{ cursor: 'pointer' }} underline="none" sx={{ whiteSpace: "nowrap", }}
                      onClick={() => clickCell(5)}>
                      {t("search_chinese_not_dot")}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", }}>
                    <Link style={{ cursor: 'pointer' }} underline="none" sx={{ whiteSpace: "nowrap", }}
                      onClick={() => clickCell(6)}>
                      {t("search_english_not_dot")}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", }}>
                    <Link style={{ cursor: 'pointer' }} underline="none" sx={{ whiteSpace: "nowrap", }}
                      onClick={() => clickCell(7)}>
                      {t("search_effectiveDate")}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", }}>
                    <Link style={{ cursor: 'pointer' }} underline="none" sx={{ whiteSpace: "nowrap", }}
                      onClick={() => clickCell(8)}>
                      {t("search_keepOrDelete")}
                    </Link>
                  </TableCell>
                </TableRow>
              </TableHead>

              <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>

              {stableSort(mainData, getComparator(order, orderBy)).map((mainDataRow, index) => (
                <>
                  {/* 單個人數據，需要循環遍歷 */}
                  <TableRow hover key={index + Math.random} >
                    {/* 地盤 */}
                    <TableCell sx={{ whiteSpace: "nowrap", fontSize: '15px', textAlign: 'left' }}>
                      {mainDataRow.siteDesc} </TableCell>
                    {/* 分判商 */}
                    <TableCell sx={{ whiteSpace: "nowrap", fontSize: '15px', textAlign: 'left' }}>
                      {mainDataRow.subContractorDesc} </TableCell>
                    {/* 工作類別 */}
                    <TableCell sx={{ whiteSpace: "nowrap", fontSize: '15px', textAlign: 'left' }}>
                      {mainDataRow.jobNatureDesc} </TableCell>
                    {/* 智能卡編號 */}
                    <TableCell sx={{ whiteSpace: "nowrap", fontSize: '15px', textAlign: 'left' }}>
                      {mainDataRow.smartCardId} </TableCell>
                    {/* 中文姓名 */}
                    <TableCell sx={{ whiteSpace: "nowrap", fontSize: '15px', textAlign: 'left' }}>
                      {mainDataRow.chineseName} </TableCell>
                    {/* 英文姓名 */}
                    <TableCell sx={{ whiteSpace: "nowrap", fontSize: '15px', textAlign: 'left' }}>
                      {mainDataRow.englishName} </TableCell>
                    {/* 生效日期 */}
                    <TableCell sx={{ whiteSpace: "nowrap", fontSize: '15px', textAlign: 'left' }}>
                      {mainDataRow.effectiveDate} </TableCell>

                    {/* 刪除/保留 */}
                    {isSubmit() && (
                      <TableCell sx={{ whiteSpace: "nowrap", fontSize: '15px', textAlign: 'left' }}>
                        {getApprovedDesc(mainDataRow.rejected)} </TableCell>)}

                    {!isSubmit() && (<TableCell sx={{ whiteSpace: "nowrap", fontSize: '15px', textAlign: 'left' }}>
                      <FormControl >
                        <RadioGroup
                          // row表示横向排列
                          row
                          aria-labelledby="contentRadio"
                          name="row-radio-buttons-group"
                          value={mainDataRow.rejected}
                          onChange={(e) => changeApproveStatues(e, mainDataRow)}
                        >
                          {/* 加入参数disabled 可以禁止选择 */}
                          <FormControlLabel value="0" control={<Radio />} label={t("button_delete")} />
                          <FormControlLabel value="1" control={<Radio />} label={t("search_keep_title")} />
                        </RadioGroup>
                      </FormControl>
                    </TableCell>)}
                  </TableRow>
                </>
              ))}
            </Table>
          </TableContainer>
        </Container>
      </div>


      <CustomDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        action={dialog.action}
      />
    </Container>

  )
}

