import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Autocomplete,
  Container,
  Grid,
  FormControl,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  MenuItem,
  FormLabel,
  Link,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import moment from 'moment'
import * as api from '../../../utils/api/vendorAutopaySystem/RptWorkerSautoPayStatusSiteListTeamBApi'
import * as commonApi from '../../../utils/api/CommonApi'

export default function RptWorkerSautoPayStatusSiteListTeamB() {
  const [isSearchPage, setIsSearchPage] = useState(true)
  const { t } = useTranslation()
  const [backOpen, setBackOpen] = useState(false)
  const [siteList, setSiteList] = useState([])
  const [dateMonthList, setDateMonthList] = useState([])
  const [sendData, setSendData] = useState({})
  const [reportData, setReportData] = useState({})
  const [tableData, setTableData] = useState([])
  const [isShowVendorColumn, setIsShowVendorColumn] = useState(false)
  const [isShowVendorColumnOne, setIsShowVendorColumnOne] = useState(false)
  const [defaultReport, setDefaultReport] = useState('0')
  const [defaultValue, setDefaultValue] = useState('0')
  const [defaultIssml, setDefaultIssml] = useState('0')
  const [defaultCombinedForms, setDefaultCombinedForms] = useState('0')

  const [dialog, setDialog] = useState({
    content: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        確認
      </Button>
    ),
    open: false,
  })

  useEffect(() => {
    setBackOpen(true)
    const getData = async () => {
      // /*初始化值 */
      setSendData({ ...sendData, autoPay: '0', recordScriteria: '0', isSml: '0', combinedForms: '0' })

      let getSiteList = await commonApi.getTeamBSite()
      let getDateMonthList = await commonApi.getDateMonthList()

      if (getSiteList) {
        getSiteList.splice(0, 0, { id: '', text: 'All' })
        setSiteList(getSiteList)
      }
      if (getDateMonthList) {
        setDateMonthList(getDateMonthList)
      }
      setBackOpen(false)
    }
    getData()
  }, [])

  const handleBack = () => {
    setIsSearchPage(true)
  }

  const handleDateFrom = (e) => {
    setSendData({ ...sendData, dateFrom: e.id, dateVo: e.text })
  }

  const handleSite = (e) => {
    setSendData({ ...sendData, siteId: e.id, siteDesc: e.text })
  }

  const handleReportType = (e) => {
    setDefaultReport(e.target.value)
    setSendData({ ...sendData, recordScriteria: e.target.value })
  }

  const handleCombinedForms = (e) => {
    setDefaultCombinedForms(e.target.value)
    setSendData({ ...sendData, combinedForms: e.target.value })
  }

  const handleAutoPayType = (e) => {
    setDefaultValue(e.target.value)
    setSendData({ ...sendData, autoPay: e.target.value })
  }

  const handleIsSmlType = (e) => {
    setDefaultIssml(e.target.value)
    setSendData({ ...sendData, isSml: e.target.value })
  }

  const sortByColumn = (sort) => {
    const searchData = { ...sendData }

    if (searchData.sortBy === sort && searchData.orderBy) {
      searchData.orderBy = null
    } else {
      searchData.orderBy = 'desc'
    }

    searchData.sortBy = sort
    setSendData(searchData)

    handleSubmit(searchData)
  }

  const handleSubmit = (data) => {
    if (!sendData.dateFrom) {
      alert('請選擇日期')
    }

    let searchData = {}
    if (data) {
      searchData = { ...data }
    } else {
      searchData = { ...sendData, orderBy: null }
    }

    const getData = async () => {
      setBackOpen(true)
      let data = await api.getReportData(searchData)
      if (data && data.selectAutopay) {
        setIsSearchPage(false)
        setReportData(data)
        setTableData(data.selectAutopay)
        setIsShowVendorColumn(searchData.recordScriteria === '0')
        setIsShowVendorColumnOne(searchData.recordScriteria === '1')
      }
      setBackOpen(false)
    }
    getData()
  }

  const resetForm = (e) => {
    setDefaultReport()
    setDefaultValue()
    setDefaultIssml()
    setDefaultReport('0')
    setDefaultValue('0')
    setDefaultIssml('0')
    setDefaultCombinedForms('0')
    setSendData({ dateFrom: '', siteId: '', recordScriteria: '0', autoPay: '0', isSml: '0', combinedForms: '0' })
  }

  const exportExcel = () => {
    const mpfPrint = async () => {
      setBackOpen(true)
      let sendApi = await commonApi.exportFile(sendData, '/payrollReportController/rptWorkersAutoPayStatusSiteListTeamBExcel', 'xls', 'RptWorkerSautoPayStatusSiteListTeamB')
      if (sendApi) {
        if (sendApi.code === 500) {
          setDialog({
            ...dialog,
            content: '請求出錯',
            open: true,
          })
          return []
        }
        // setPrintData(sendApi.data || {})
      }
      setBackOpen(false)
    }
    mpfPrint()
  }

  const exportPdf = () => {
    const mpfPrint = async () => {
      setBackOpen(true)
      let sendApi = await commonApi.exportFile(sendData, '/payrollReportController/rptWorkersAutoPayStatusSiteListTeamBPDF', 'pdf', 'RptWorkerSautoPayStatusSiteListTeamB')
      if (sendApi) {
        if (sendApi.code === 500) {
          setDialog({
            ...dialog,
            content: '請求出錯',
            open: true,
          })
          return []
        }
        // setPrintData(sendApi.data || {})
      }
      setBackOpen(false)
    }
    mpfPrint()
  }

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {isSearchPage && (
        <form>
          <Container maxWidth="md">
            <Grid justifyContent="center" alignItems="center" spacing={2}>
              {/* 工人薪金分析(以地盤分類)-B */}
              <Grid item xs={12}>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                  <b>{t('icon_vas_wspsab_title')}</b>
                </Typography>
              </Grid>

              <Grid item sx={{ margin: 'auto', width: '400px', marginBottom: '20px', marginTop: '10px' }}>
                <TextField id="siteId" value={sendData.siteId || ''} fullWidth size="small" label={t('icon_vas_wsms_site')} select>
                  {siteList.map((item, index) => (
                    <MenuItem key={index} value={item.id} onClick={() => handleSite(item)}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item sx={{ margin: 'auto', width: '400px', marginBottom: '20px', marginTop: '10px' }}>
                <TextField error={!sendData.dateFrom} id="dateFrom" value={sendData.dateFrom || ''} fullWidth size="small" label={t('icon_vas_wsat_date_from')} select>
                  {dateMonthList.map((item, index) => (
                    <MenuItem key={index} value={item.id} onClick={() => handleDateFrom(item)}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item sx={{ margin: 'auto', width: '400px', marginBottom: '20px', marginTop: '10px' }}>
                <FormControl size="small">
                  <FormLabel>{t('icon_vas_wsat_report_type')}</FormLabel>
                  <RadioGroup row onChange={handleReportType} aria-labelledby="contentRadio" name="row-radio-buttons-group" value={defaultReport}>
                    <FormControlLabel value="0" label={t('icon_vas_wsat_report_type_detail')} control={<Radio />} />
                    <FormControlLabel value="1" label={t('icon_vas_wsat_report_type_summary')} control={<Radio />} />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item sx={{ margin: 'auto', width: '400px', marginBottom: '20px', marginTop: '10px' }}>
                <FormControl size="small">
                  <FormLabel>{t('icon_vas_wsat_group_type')}</FormLabel>
                  <RadioGroup row onChange={handleCombinedForms} aria-labelledby="contentRadio" name="row-radio-buttons-group" value={defaultCombinedForms}>
                    <FormControlLabel value="0" label={t('icon_vas_wsat_group_type_salary_period')} control={<Radio />} />
                    <FormControlLabel value="1" label={t('icon_vas_wsms_site')} control={<Radio />} />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item sx={{ margin: 'auto', width: '400px', marginBottom: '20px', marginTop: '10px' }}>
                <FormControl size="small">
                  <FormLabel>{t('icon_vas_wsms_vendor')}</FormLabel>
                  <RadioGroup row onChange={handleAutoPayType} aria-labelledby="contentRadio" name="row-radio-buttons-group" value={defaultValue}>
                    <FormControlLabel value="0" label={t('icon_vas_wsmszo_vendor')} control={<Radio />} />
                    <FormControlLabel value="1" label={t('icon_vas_wsmsnzo_vendor')} control={<Radio />} />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item sx={{ margin: 'auto', width: '400px', marginBottom: '20px', marginTop: '10px' }}>
                <FormControl size="small">
                  <FormLabel>{t('icon_vas_wsmszo_missml')}</FormLabel>
                  <RadioGroup row onChange={handleIsSmlType} aria-labelledby="contentRadio" name="row-radio-buttons-group" value={defaultIssml}>
                    <FormControlLabel value="0" label={t('icon_vas_wsmszo_noissml')} control={<Radio />} />
                    <FormControlLabel value="1" label={t('icon_vas_wsmszo_issml')} control={<Radio />} />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={6} />
              <Grid item sx={{ margin: 'auto', width: '400px', marginBottom: '20px', marginTop: '10px' }}>
                <FormControl>
                  <Button
                    variant="contained"
                    onClick={() => handleSubmit()}
                    sx={{
                      backgroundColor: 'primaryBlue',
                      color: 'commom.white',
                    }}
                  >
                    {t('button_submit')}
                  </Button>
                </FormControl>
                <FormControl sx={{ marginLeft: '20px' }}>
                  <Button
                    variant="contained"
                    onClick={() => resetForm()}
                    sx={{
                      backgroundColor: 'primaryGreen',
                      color: 'commom.white',
                      marginRight: '10px',
                    }}
                    label="Reset"
                  >
                    {t('button_reset')}
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Container>
        </form>
      )}

      {!isSearchPage && (
        <>
          <Container maxWidth={false}>
            <div
              style={{
                display: 'block',
                textAlign: 'left',
                marginTop: '0px',
              }}
            >
              <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
                <ArrowBackIcon />
                {t('button_back')}
              </Button>
            </div>

            <div style={{ display: 'block', textAlign: 'right' }}>
              <Button sx={{ float: 'right', mr: '20px' }} onClick={exportExcel}>
                <DownloadIcon />
                {t('button_export_excel')}
              </Button>
              <Button sx={{ float: 'right', mr: '20px' }} onClick={exportPdf}>
                <DownloadIcon />
                {t('button_export_pdf')}
              </Button>
            </div>
            <div>
              <Typography variant="h4" style={{ textAlign: 'center', mt: '-30px' }}>
                <b>{t('icon_vas_wspsab_title')}</b>
              </Typography>
              <TableContainer>
                <Table sx={{ textAlign: 'left' }} size="small">
                  <TableBody>
                    <TableRow>
                      {/* 製作日期 */}
                      <TableCell sx={{ fontWeight: 'bold', display: 'block', borderBottom: 'none' }}>{t('icon_label_creation_date')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{moment(new Date().getTime()).format('yyyy/MM/DD')}</TableCell>
                    </TableRow>
                    <TableRow>
                      {/* 日期（由) */}
                      <TableCell sx={{ fontWeight: 'bold', width: 200, borderBottom: 'none' }}>{t('icon_vas_wsat_date_from')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.dateVo}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 報表形式: 	 */}
                      <TableCell sx={{ fontWeight: 'bold', display: 'block', borderBottom: 'none' }}>{t('icon_vas_wsat_report_type')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>
                        {sendData.recordScriteria === '0' ? t('icon_vas_wsat_report_type_detail') : t('icon_vas_wsat_report_type_summary')}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 地盤： 	 */}
                      <TableCell sx={{ fontWeight: 'bold', display: 'block', borderBottom: 'none' }}>{t('icon1_autopay_setSiteUserMapping_tableLabel1')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.siteId === '' ? t('All_site1') : sendData.siteId == null ? t('All_site1') : sendData.siteDesc}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 組合形式： 	 */}
                      <TableCell sx={{ fontWeight: 'bold', display: 'block', borderBottom: 'none' }}>{t('組合形式')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.combinedForms === '0' ? t('糧期') : t('地盤')}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 承判商: 	 */}
                      <TableCell sx={{ fontWeight: 'bold', display: 'block', borderBottom: 'none' }}>{t('icon_vas_wsms_vendor')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.autoPay === '0' ? t('icon_vas_wsmszo_vendor') : t('icon_vas_wsmsnzo_vendor')}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 包括 SANFIELD (MANAGEMENT) LTD 	 */}
                      <TableCell sx={{ fontWeight: 'bold', display: 'block', borderBottom: 'none' }}>{t('icon_vas_wsms_ssml')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.isSml === '0' ? t('icon_vas_wsmszo_noissml') : t('icon_vas_wsmszo_issml')}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer style={{ maxHeight: '800px', maxWidth: '100vw' }}>
                <Table size="small" sx={{ mt: '20px' }} stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('1')}>
                          {t('icon_vas_wsms_site')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('2')}>
                          {t('icon_vas_wsat_period')}
                        </Link>
                      </TableCell>

                      {isShowVendorColumn && (
                        <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                          <Link href="#" underline="none" onClick={() => sortByColumn('3')}>
                            {t('icon_vas_wsat_vendor')}
                          </Link>
                        </TableCell>
                      )}

                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('4')}>
                          {t('icon_vas_wsat_lei_vendor')}
                        </Link>
                      </TableCell>

                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('5')}>
                          {t('icon_vas_wsat_attendance_num')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('6')}>
                          {t('icon_vas_wsat_attendance_num_percent')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('7')}>
                          {t('icon_vas_wsat_absence_num')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('8')}>
                          {t('icon_vas_wsat_absence_num_percent')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('9')}>
                          {t('icon_vas_wsat_self')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('10')}>
                          {t('icon_vas_wsat_self_percent')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('11')}>
                          {t('icon_vas_wsat_total_worker_num')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('12')}>
                          {t('icon_vas_wsat_attendance_days')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('13')}>
                          {t('icon_vas_wsat_attendance_days_percent')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('14')}>
                          {t('icon_vas_wsat_absence_days')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('15')}>
                          {t('icon_vas_wsat_absence_days_percent')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('16')}>
                          {t('icon_vas_wsat_selfday')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('17')}>
                          {t('icon_vas_wsat_selfday_percent')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('18')}>
                          {t('icon_vas_wsat_total_days')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('19')}>
                          {t('icon_vas_wsat_total_salary')}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link href="#" underline="none" onClick={() => sortByColumn('20')}>
                          {t('icon_vas_wsat_average_daily_salary')}
                        </Link>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {!!tableData && tableData.length > 0 && (
                    <>
                      <TableBody>
                        {tableData.map((row, ind) => (
                          <TableRow
                            hover
                            key={ind}
                            sx={{
                              backgroundColor: ind % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                            }}
                          >
                            <TableCell>{row.siteDesc}</TableCell>
                            <TableCell>
                              {row.dateFrom}~{row.dateTo}
                            </TableCell>
                            {isShowVendorColumn && <TableCell>{row.vendorDesc}</TableCell>}

                            <TableCell>{row.status}</TableCell>
                            <TableCell>{row.workersWa}</TableCell>
                            <TableCell>{row.workersWaperCent}</TableCell>
                            <TableCell>{row.workersWoa}</TableCell>
                            <TableCell>{row.workersWoaperCent}</TableCell>
                            <TableCell>{row.sworkers}</TableCell>
                            <TableCell>{row.sworkersperCent}</TableCell>
                            <TableCell>{row.totalWorkers}</TableCell>
                            <TableCell>{row.wdWa}</TableCell>
                            <TableCell>{row.wdWaperCent}</TableCell>
                            <TableCell>{row.wdWoa}</TableCell>
                            <TableCell>{row.wdWoaperCent}</TableCell>
                            <TableCell>{row.swd}</TableCell>
                            <TableCell>{row.swdperCent}</TableCell>
                            <TableCell>{row.totalWd}</TableCell>
                            <TableCell>{row.salaryWaFormat}</TableCell>
                            <TableCell>{row.averAgeSalaryFormat}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </>
                  )}

                  {(!tableData || tableData.length <= 0) && (
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ textAlign: 'center' }} colSpan={15}>
                          {'No data found !'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
            <Table padding="none" sx={{ maxWidth: '740px', mt: '40px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{''}</TableCell>
                  <TableCell>{t('icon_vas_wsat_total_num')}</TableCell>
                  <TableCell>{t('icon_vas_wsat_total_percent')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {/* 參與人數 * 	 */}
                  <TableCell>
                    <b>{t('icon_vas_wsat_attendance_num')}</b>
                  </TableCell>
                  <TableCell>{reportData.workersWaFormat}</TableCell>
                  <TableCell>{reportData.statuscountZoTage}</TableCell>
                </TableRow>

                {isShowVendorColumn && (
                  <>
                    <TableRow>
                      {/* 己參與自動轉賬之承判商的非參與人數 (承判商總數=  */}
                      <TableCell>
                        <b>
                          {t('icon_vas_wsat_attendaq')}
                          {reportData.count5}
                          {') *'}
                        </b>
                      </TableCell>
                      <TableCell>{reportData.statuscountWu}</TableCell>
                      <TableCell>{reportData.statuscountWuTage}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 未加入自動轉賬計劃之承判商的非參與人數 (承判商總數=  */}
                      <TableCell>
                        <b>
                          {t('icon_vas_wsat_attendaw')}
                          {reportData.count2}
                          {') *'}
                        </b>
                      </TableCell>
                      <TableCell>{reportData.statuscountEr}</TableCell>
                      <TableCell>{reportData.statuscountErTage}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 括免自動轉賬計劃之承判商的非參與人數 (承判商總數=  */}
                      <TableCell>
                        <b>
                          {t('icon_vas_wsat_attendae')}
                          {reportData.count1}
                          {') *'}
                        </b>
                      </TableCell>
                      <TableCell>{reportData.statuscountYi}</TableCell>
                      <TableCell>{reportData.statuscountYiTage}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* NSC 未加入自動轉賬計劃之承判商的非參與人數 (承判商總數=  */}
                      <TableCell>
                        <b>
                          {t('icon_vas_wsat_attendar')}
                          {reportData.count3}
                          {') *'}
                        </b>
                      </TableCell>
                      <TableCell>{reportData.statuscountSan}</TableCell>
                      <TableCell>{reportData.statuscountSanTage}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 選擇地盤及日期內未加入自動轉賬計劃之承判商的非參與人數 (承判商總數=  */}
                      <TableCell>
                        <b>
                          {t('icon_vas_wsat_attendat')}
                          {reportData.count4}
                          {') *'}
                        </b>
                      </TableCell>
                      <TableCell>{reportData.statuscountSi}</TableCell>
                      <TableCell>{reportData.statuscountSiTage}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 自僱人士人數 * 	 */}
                      <TableCell>
                        <b>{t('icon_vas_wsat_absence_numq')}</b>
                      </TableCell>
                      <TableCell>{reportData.sWorkersFormat}</TableCell>
                      <TableCell>{reportData.sWorkersFormatTage}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 總人數 (承判商總數=261) * 	 */}
                      <TableCell>
                        <b>
                          {t('icon_vas_wsat_absence_nume')}
                          {reportData.count6}
                          {') *'}
                        </b>
                      </TableCell>
                      <TableCell>{reportData.totalWorkersFormat}</TableCell>
                      <TableCell>{''}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 參與日數 	 */}
                      <TableCell>
                        <b>{t('icon_vas_wsat_absence_numr')}</b>
                      </TableCell>
                      <TableCell>{reportData.wdWaFormat}</TableCell>
                      <TableCell>{reportData.statusworkZoTage}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 己參與自動轉賬之承判商的非參與日數 (承判商總數=  */}
                      <TableCell>
                        <b>
                          {t('icon_vas_wsat_attenday')}
                          {reportData.count5}
                          {')'}
                        </b>
                      </TableCell>
                      <TableCell>{reportData.statusworkWu}</TableCell>
                      <TableCell>{reportData.statusworkWuTage}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 未加入自動轉賬計劃之承判商的非參與日數 (承判商總數=  */}
                      <TableCell>
                        <b>
                          {t('icon_vas_wsat_attendau')}
                          {reportData.count2}
                          {')'}
                        </b>
                      </TableCell>
                      <TableCell>{reportData.statusworkEr}</TableCell>
                      <TableCell>{reportData.statusworkErTage}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 括免自動轉賬計劃之承判商的非參與日數 (承判商總數=  */}
                      <TableCell>
                        <b>
                          {t('icon_vas_wsat_attendai')}
                          {reportData.count1}
                          {')'}
                        </b>
                      </TableCell>
                      <TableCell>{reportData.statusworkYi}</TableCell>
                      <TableCell>{reportData.statusworkYiTage}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* NSC 未加入自動轉賬計劃之承判商的非參與日數 (承判商總數=  */}
                      <TableCell>
                        <b>
                          {t('icon_vas_wsat_attendao')}
                          {reportData.count3}
                          {')'}
                        </b>
                      </TableCell>
                      <TableCell>{reportData.statusworkSan}</TableCell>
                      <TableCell>{reportData.statusworkSanTage}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 選擇地盤及日期內未加入自動轉賬計劃之承判商的非參與日數 (承判商總數=  */}
                      <TableCell>
                        <b>
                          {t('icon_vas_wsat_attendap')}
                          {reportData.count4}
                          {')'}
                        </b>
                      </TableCell>
                      <TableCell>{reportData.statusworkSi}</TableCell>
                      <TableCell>{reportData.statusworkSiTage}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 自僱人士日數 	 */}
                      <TableCell>
                        <b>{t('icon_vas_wsat_absence_numt')}</b>
                      </TableCell>
                      <TableCell>{reportData.sWdFormat}</TableCell>
                      <TableCell>{reportData.sWdFormatTage}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 總日數 (承判商總數=261) 	 */}
                      <TableCell>
                        <b>
                          {t('icon_vas_wsat_absence_numu')}
                          {reportData.count6}
                          {')'}
                        </b>
                      </TableCell>
                      <TableCell>{reportData.totalWdFormat}</TableCell>
                      <TableCell>{''}</TableCell>
                    </TableRow>
                  </>
                )}

                {isShowVendorColumnOne && (
                  <>
                    <TableRow>
                      {/* 自僱人士人數 * 	 */}
                      <TableCell>
                        <b>{t('icon_vas_wsat_absence_numq')}</b>
                      </TableCell>
                      <TableCell>{reportData.sWorkersFormat}</TableCell>
                      <TableCell>{reportData.sWorkersFormatTage}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 非參與人數 * 	 */}
                      <TableCell>
                        <b>{t('icon_vas_wsat_absence_numw')}</b>
                      </TableCell>
                      <TableCell>{reportData.workersWoaFormat}</TableCell>
                      <TableCell>{reportData.workersWoaFormatTage}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 總人數 (承判商總數=261) * 	 */}
                      <TableCell>
                        <b>
                          {t('icon_vas_wsat_absence_nume')}
                          {reportData.count6}
                          {') *'}
                        </b>
                      </TableCell>
                      <TableCell>{reportData.totalWorkersFormat}</TableCell>
                      <TableCell>{''}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 參與日數 	 */}
                      <TableCell>
                        <b>{t('icon_vas_wsat_absence_numr')}</b>
                      </TableCell>
                      <TableCell>{reportData.wdWaFormat}</TableCell>
                      <TableCell>{reportData.statusworkZoTage}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 自僱人士日數 	 */}
                      <TableCell>
                        <b>{t('icon_vas_wsat_absence_numt')}</b>
                      </TableCell>
                      <TableCell>{reportData.sWdFormat}</TableCell>
                      <TableCell>{reportData.sWdFormatTage}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 非參與日數 	 */}
                      <TableCell>
                        <b>{t('icon_vas_wsat_absence_numy')}</b>
                      </TableCell>
                      <TableCell>{reportData.wdWoaFormat}</TableCell>
                      <TableCell>{reportData.wdWoaFormatTage}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 總日數 (承判商總數=261) 	 */}
                      <TableCell>
                        <b>
                          {t('icon_vas_wsat_absence_numu')}
                          {reportData.count6}
                          {')'}
                        </b>
                      </TableCell>
                      <TableCell>{reportData.totalWdFormat}</TableCell>
                      <TableCell>{''}</TableCell>
                    </TableRow>
                  </>
                )}

                <TableRow>
                  {/* 工人總收入 HKD 	 */}
                  <TableCell>
                    <b>{t('icon_vas_wsat_absence_numi')}</b>
                  </TableCell>
                  <TableCell>{reportData.salaryWaFormat}</TableCell>
                  <TableCell>{''}</TableCell>
                </TableRow>

                <TableRow>
                  {/* 工人每日平均收入 HKD 	 */}
                  <TableCell>
                    <b>{t('icon_vas_wsat_absence_numo')}</b>
                  </TableCell>
                  <TableCell>{reportData.averAgeSalary}</TableCell>
                  <TableCell>{''}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Container>

          <Typography style={{ paddingTop: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
            <b>{t('icon_vas_wast')}</b>
          </Typography>
          <Typography style={{ paddingTop: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
            <b>{t('icon_vas_wastq_tips')}</b>
          </Typography>
          <Typography style={{ paddingTop: '28px', paddingLeft: '20px', paddingRight: '20px' }}>
            <b>{t('icon_vas_wastw_tips')}</b>
          </Typography>
          <Typography style={{ paddingTop: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
            <b>{t('icon_vas_waste_tips')}</b>
          </Typography>
          <Typography style={{ paddingTop: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
            <b>{t('icon_vas_wastr_tips')}</b>
          </Typography>
          <Typography style={{ paddingTop: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
            <b>{t('icon_vas_wastt_tips')}</b>
          </Typography>
          <Typography style={{ paddingTop: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
            <b>{t('icon_vas_wasty_tips')}</b>
          </Typography>
        </>
      )}
    </>
  )
}
