import React from "react";
import { Container, Typography, Grid } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

// 承判商安全人員出席天數--首页
export default function AdocspMain() {
  // const { setType } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleButton1 = () => {
    navigate(
      "/workerAttendanceRecord/adocspMain/registrationRecord/registrationRecordSearch"
    );
  };
  const handleButton2 = () => {
    navigate("/workerAttendanceRecord/adocspMain/applyList/ApplyList");
  };
  const handleButton3 = () => {
    navigate("/workerAttendanceRecord/adocspMain/reportDetailSearch");
  };
  const handleButton4 = () => {
    navigate("/workerAttendanceRecord/adocspMain/reportDaysSearch");
  };
  const handleButton5 = () => {
    navigate("/workerAttendanceRecord/adocspMain/reportMonthsSearch");
  };

  return (
    <Container sx={{ textAlign: "center" }}>
      <div style={{ display: "block" }}>
        <Typography
          variant="h4"
          style={{ color: "black", textAlign: "center", marginBottom: "18px" }}
        >
          <b>{t("search_attendance_docs_days")}</b>
        </Typography>
      </div>
      {/* btn1 */}
      <Container
        sx={{
          marginTop: "4px",
          marginBottom: "10px",
          border: 1,
          width: "400px",
          borderBlockColor: "grey",
          borderRadius: "8px",
        }}
      >
        <Typography
          style={{ marginTop: "8px", color: "black", textAlign: "center" }}
        >
          <b>{t("search_registration_record")}</b>
        </Typography>
        <Grid item xs={12} md={12} sx={{ marginBottom: "16px" }}>
          <Button
            onClick={handleButton1}
            sx={{ mt: "8px" }}
            variant="contained"
          >
            {t("search_atrrot_records")}
          </Button>
        </Grid>
      </Container>
      <Grid item xs={12} md={12}>
        <ArrowDropDownIcon />
      </Grid>
      {/* btn2 */}
      <Container
        sx={{
          marginTop: "4px",
          marginBottom: "10px",
          border: 1,
          width: "400px",
          borderBlockColor: "grey",
          borderRadius: "8px",
        }}
      >
        <Typography
          style={{ marginTop: "8px", color: "black", textAlign: "center" }}
        >
          <b>{t("search_apply_title")}</b>
        </Typography>
        <Grid item xs={12} md={12} sx={{ marginBottom: "16px" }}>
          <Button
            onClick={handleButton2}
            sx={{ mt: "8px" }}
            variant="contained"
          >
            {t("search_vendor_ss_outDays")}
          </Button>
        </Grid>
      </Container>
      <Grid item xs={12} md={12}>
        <ArrowDropDownIcon />
      </Grid>
      {/* btn3 */}
      <Container
        sx={{
          marginTop: "4px",
          marginBottom: "10px",
          border: 1,
          width: "400px",
          borderBlockColor: "grey",
          borderRadius: "8px",
        }}
      >
        <Typography
          style={{ marginTop: "8px", color: "black", textAlign: "center" }}
        >
          <b>{t("search_report_title")}</b>
        </Typography>
        <Grid item xs={12} md={12} sx={{ marginBottom: "16px" }}>
          <Button
            onClick={handleButton3}
            sx={{ mt: "8px" }}
            variant="contained"
          >
            {t("search_vendor_ss_detail_record")}
          </Button>
        </Grid>

        <Grid item xs={12} md={12}>
          <ArrowDropDownIcon />
        </Grid>

        <Grid item xs={12} md={12} sx={{ marginBottom: "10px" }}>
          <Button
            onClick={handleButton4}
            sx={{ mt: "8px" }}
            variant="contained"
          >
            {t("search_vendor_daily_records")}
          </Button>
        </Grid>

        <Grid item xs={12} md={12}>
          <ArrowDropDownIcon />
        </Grid>

        <Grid item xs={12} md={12} sx={{ marginBottom: "30px" }}>
          <Button
            onClick={handleButton5}
            sx={{ mt: "8px" }}
            variant="contained"
          >
            {t("search_vendor_malfcafsss_person")}
          </Button>
        </Grid>
      </Container>
    </Container>
  );
}
