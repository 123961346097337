import React, { useState, useRef, useEffect } from 'react'
import { Button, Backdrop, CircularProgress, Container, Grid, Typography } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
// utils
import Xarrow from 'react-xarrows'
import Snackbar from 'src/utils/TransitionSnackbar'
import { downloadBook } from 'src/utils/api/workerAttendanceRecord/AutoPayApi'
// i18n
import { useTranslation } from 'react-i18next'
// component

export default function Main(props) {
  const { setType, data, open, setOpen, loading } = props
  const { t } = useTranslation()
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [color, setColor] = useState('success')

  function Button1() {
    // 旧代码逻辑是 canSubmit = 1 && showSetSiteUser = 1 出来按钮
    if (data.canSubmit === 1 && data.showSetSiteUser === 1) {
      return (
        <>
          <Grid item xs={12} md={12}>
            <Button onClick={handleSetSiteUserMapping} sx={{ mt: '20px' }} variant="contained">
              {t('icon1_autopay_setSiteUserMapping')}
              {data.warn === 1 ? <> {t('icon1_autopay_setSiteUserMapping_remark')} </> : null}
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <ArrowDropDownIcon />
          </Grid>
        </>
      )
    }
    return (
      <>
        <Grid item xs={12} md={12}>
          <Typography sx={{ mt: '20px' }}>{t('icon1_autopay_setSiteUserMapping')}</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <ArrowDropDownIcon />
        </Grid>
      </>
    )
  }
  function DistributeSubBatch() {
    //  可以出来按钮： headerSet不为空， payrollType === "N" && data.status === "I"  可以出来按钮
    if (data.headerSet !== null) {
      if (data.payrollType === 'N' || (data.payrollType === 'S' && data.status === 'I')) {
        if (data.Flag === 'N') {
          return (
            <Button id="line3" disabled onClick={handleDistributeSubBatch} sx={{ mt: '100px' }} variant="contained">
              {t('icon1_autopay_distributeSubBatch')}
            </Button>
          )
        } else if (data.payrollType === 'N' && data.status === 'I') {
          return (
            <Button id="line3" onClick={handleDistributeSubBatch} sx={{ mt: '100px' }} variant="contained">
              {t('icon1_autopay_distributeSubBatch')}
            </Button>
          )
        } else {
          return (
            <Button id="line3" onClick={handleDistributeSubBatch} sx={{ mt: '100px' }} variant="contained">
              {t('icon1_autopay_distributeSubBatch')}
            </Button>
          )
        }
      } else {
        return (
          <Typography id="line3" sx={{ mt: '100px', py: '6px' }}>
            {t('icon1_autopay_distributeSubBatch')}
          </Typography>
        )
      }
    } else {
      return (
        <Typography id="line3" sx={{ mt: '100px', py: '6px' }}>
          {t('icon1_autopay_distributeSubBatch')}
        </Typography>
      )
    }
  }

  // 第六個 分配部分糧期
  function DistributePartialBatch() {
    if (data.headerSet !== null) {
      if (data.payrollType === 'N' || (data.payrollType === 'P' && data.status === 'I')) {
        if (data.Flag === 'N' || new Date(data.headerSet[0].dateTo) >= new Date()) {
          return (
            <Button disabled id="line4" onClick={handleDistributePartialBatch} sx={{ mt: '100px' }} variant="contained">
              {t('icon1_autopay_distributePartialBatch')}
            </Button>
          )
        } else if (data.payrollType === 'N' && data.status === 'I') {
          return (
            <Button id="line4" onClick={handleDistributePartialBatch} sx={{ mt: '100px' }} variant="contained">
              {t('icon1_autopay_distributePartialBatch')}
            </Button>
          )
        } else {
          return (
            <Button id="line4" onClick={handleDistributePartialBatch} sx={{ mt: '100px' }} variant="contained">
              {t('icon1_autopay_distributePartialBatch')}
            </Button>
          )
        }
      } else {
        return (
          <Typography id="line4" sx={{ mt: '100px', py: '6px' }}>
            {t('icon1_autopay_distributePartialBatch')}
          </Typography>
        )
      }
    } else {
      return (
        <Typography id="line4" sx={{ mt: '100px', py: '6px' }}>
          {t('icon1_autopay_distributePartialBatch')}
        </Typography>
      )
    }
  }

  const handleSetSiteUserMapping = () => {
    setType(1)
  }
  const handleMaintWorkerAccount = () => {
    setType(2)
  }
  const handleMaintWorkerAutopayMainCreateSearch = () => {
    setType(3)
  }
  const handleOneBatch = () => {
    setType(4)
  }
  const handleDistributeSubBatch = () => {
    setType(5)
  }
  const handleDistributePartialBatch = () => {
    setType(6)
  }
  const handleButton7 = () => {
    setType(7)
  }
  const handleButton8 = () => {
    setType(8)
  }
  const handleButton9 = () => {
    setType(9)
  }
  const getVIPMeaasge = () => {
    setOpenSnackBar(true)
    let time = new Date(data.headerSet[0].dateTo)
  }

  const downloadHandbook = async () => {
    setOpen(true)
    let download = await downloadBook()
    if (download && download.code === 500) {
      alert('下載出錯！')
    }
    setOpen(false)
  }

  return (
    <>
      {loading ? (
        <Snackbar msg={data.alertSiteStr || ''} color={color} open={openSnackBar} setOpen={setOpenSnackBar} />
      ) : (
        <Container sx={{ textAlign: 'center' }}>
          <Typography variant="h4">
            <b>{t('icon1_autopay_title')}</b>
          </Typography>

          {data.Flag === 'N' ? <Typography sx={{ color: 'red' }}>*** {t('icon1_autopay_Attention')} ***</Typography> : null}
          <Grid container>
            {/* 第一个button  設置地盤輸入帳戶   */}
            {Button1()}

            {/* 第二个button  修改工人薪金/強積金戶口資料   HeaderSet为空，SpacePeriodSet不为空出来按钮 */}
            <Grid item xs={12} md={12}>
              {data.headerSet === null && data.spacePeriodSet !== null ? (
                <Button onClick={handleMaintWorkerAccount} variant="contained">
                  {t('icon1_autopay_maintWorkerAccount')}
                </Button>
              ) : (
                <Typography>{t('icon1_autopay_maintWorkerAccount')}</Typography>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <ArrowDropDownIcon />
            </Grid>

            {/* 第三个button  新增工人支薪及供款糧期表  HeaderSet為空 && SpacePeriodSet不爲空出來按鈕   */}
            <Grid item xs={12} md={12}>
              <div id="line1">
                {data.headerSet === null && data.spacePeriodSet !== null ? (
                  <Button disabled={data.Flag !== 'Y'} onClick={handleMaintWorkerAutopayMainCreateSearch} variant="contained">
                    {t('icon1_autopay_maintWorkerAutopayMainCreateSearch')}
                  </Button>
                ) : (
                  <Typography id="line1">{t('icon1_autopay_maintWorkerAutopayMainCreateSearch')}</Typography>
                )}
              </div>
            </Grid>

            {/* 第四个button  完成整個糧期  HeaderSet不為空 && payrollType === "N"出來按鈕   */}
            <Grid item xs={4} md={4}>
              <div>
                {data.payrollType === 'N' && data.headerSet !== null ? (
                  <Button id="line2" disabled={data.disabled === 'Disabled'} onClick={handleOneBatch} sx={{ mt: '100px' }} variant="contained">
                    {t('icon1_autopay_oneBatch')}
                  </Button>
                ) : (
                  <Typography id="line2" sx={{ mt: '100px', py: '6px' }}>
                    {t('icon1_autopay_oneBatch')}
                  </Typography>
                )}
              </div>
            </Grid>
            <Xarrow
              startAnchor="bottom"
              endAnchor="top"
              path="grid"
              start="line1" // can be react ref
              end="line2" // or an id
            />
            {/* 第五個 分配短糧期 */}
            <Grid item xs={4} md={4}>
              {DistributeSubBatch()}
            </Grid>
            <Xarrow
              startAnchor="bottom"
              endAnchor="top"
              path="grid"
              start="line1" // can be react ref
              end="line3" // or an id
            />
            <Grid item xs={4} md={4}>
              {/* 第六個 分配部分糧期 */}
              {DistributePartialBatch()}
            </Grid>
            <Xarrow
              startAnchor="bottom"
              endAnchor="top"
              path="grid"
              start="line1" // can be react ref
              end="line4" // or an id
            />
            <Grid item xs={12} md={12}>
              {/* 第七個 修改/輸入工人基金 */}
              {data.headerSet !== null && data.detailStatusSet?.length > 0 ? (
                <Button onClick={handleButton7} variant="contained" id="line5" sx={{ mt: '100px' }}>
                  {t('Enter/amend-worker_salary/MPF_information')}
                </Button>
              ) : (
                <Typography id="line5" sx={{ mt: '100px', py: '6px' }}>
                  {t('Enter/amend-worker_salary/MPF_information')}
                </Typography>
              )}
            </Grid>

            <Xarrow
              startAnchor="bottom"
              endAnchor="top"
              path="grid"
              start="line2" // can be react ref
              end="line5" // or an id
            />
            <Xarrow
              startAnchor="bottom"
              endAnchor="top"
              path="grid"
              start="line3" // can be react ref
              end="line5" // or an id
            />
            <Xarrow
              startAnchor="bottom"
              endAnchor="top"
              path="grid"
              start="line4" // can be react ref
              end="line5" // or an id
            />
            <Grid item xs={12} md={12}>
              <ArrowDropDownIcon />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button onClick={handleButton8} variant="contained">
                {t('create_auto-transfer_files')}
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <ArrowDropDownIcon />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button onClick={handleButton9} variant="contained">
                {t('icon1_autopay_Button9')}
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              {data.canSubmit === 1 ? (
                data.showSetSiteUser === 1 ? (
                  <Button onClick={getVIPMeaasge} sx={{ mt: '10px' }}>
                    *{t('icon1_autopay_VIP')}
                  </Button>
                ) : null
              ) : (
                <Typography sx={{ mt: '10px' }}>*{t('icon1_autopay_General')}</Typography>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <Button onClick={downloadHandbook} sx={{ mt: '10px' }}>
                *<LibraryBooksIcon />
                {t('icon1_autopay_Download')}
              </Button>
            </Grid>
          </Grid>

          {/* 劃綫部分 */}
        </Container>
      )}
    </>
  )
}
