import { instance } from "../../instance";

// 新增Data for security_role_table角色
export const roleSetupAdd = async (securityRoleTable) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/userController/roleSetupAdd`,
        data: securityRoleTable,
    }).then(
        (result) => result.data,
        (error) => error
    );
};
// 修改Data for security_role_table角色
export const roleSetupEidte = async (securityRoleTable) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/userController/roleSetupEidte`,
        data: securityRoleTable,
    }).then(
        (result) => result.data,
        (error) => error
    );
};

// 查詢Data for security_role_table角色
export const roleSetupView = async () => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/userController/roleSetupView`,
       
    }).then(
        (result) => result.data,
        (error) => error
    );
};

// 刪除Data for security_role_table角色
export const roleSetupDelete = async (securityRoleTable) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/userController/roleSetupDelete`,
        data: securityRoleTable,
       
    }).then(
        (result) => result.data,
        (error) => error
    );
};



// 查詢security_role_menu_mapping表 匹配關係
export const roleSetupMappingView = async (roleId) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/userController/roleSetupMappingView`,
        data: roleId,
       
    }).then(
        (result) => result.data,
        (error) => error
    );
};

// 增加/更新security_role_menu_mapping表 匹配關係
export const roleSetupMappingAdd = async (securityRoleMenuMappingList) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/userController/roleSetupMappingAdd`,
        data: securityRoleMenuMappingList,
       
    }).then(
        (result) => result.data,
        (error) => error
    );
};

// 刪除security_role_menu_mapping表 匹配關係
export const roleSetupMappingDelete = async (securityRoleMenuMappingList) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/userController/roleSetupMappingDelete`,
        data: securityRoleMenuMappingList,
       
    }).then(
        (result) => result.data,
        (error) => error
    );
};

// 查詢所有menu-->分層（根據parentId）
export const menuDataByParentId = async () => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/userController/menuDataByParentId`,
 
    }).then(
        (result) => result.data,
        (error) => error
    );
};


// 查詢反選--已選-->分層menu
export const menuDataCovert = async (securityRoleTable) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/userController/menuDataCovert`,
        data: securityRoleTable,
   
 
    }).then(
        (result) => result.data,
        (error) => error
    );
};

// get NSC 承判商
export const getZoVendorId = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getZoVendorId`,
        data: data
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

// 新增 NSC 承判商
export const insertVendorId = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/autoPayController/insertVendorId`,
        data: data,
    }).then(
        (result) => result.data,
        (error) => error
    );
};

// 删除 NSC 承判商
export const deleteVendorId = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/autoPayController/deleteVendorId`,
        data: data,
    }).then(
        (result) => result.data,
        (error) => error
    );
};

// get 不計算工種
export const getZojobNatureList = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getZojobNatureList`,
        data: data
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

// 新增 不計算工種
export const insertJobNatureById = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/autoPayController/insertJobNatureById`,
        data: data,
    }).then(
        (result) => result.data,
        (error) => error
    );
};

// 删除 不計算工種
export const deleteJobNatureById = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/autoPayController/deleteJobNatureById`,
        data: data,
    }).then(
        (result) => result.data,
        (error) => error
    );
};