import { instance } from '../../instance'

export default async function $http(url, data = {}, options = {}) {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}` + url,
    data: data || {},
    ...(options || {}),
  }).then(
    (result) => result?.data,
    (error) => error?.response?.data,
  )
}

export async function $link(url, data = {}, options = {}) {
  return instance({
    method: 'POST',
    url: url,
    data: data || {},
    ...(options || {}),
  }).then(
    (result) => result?.data,
    (error) => error?.response?.data,
  )
}

export async function $download(url, type, data = {}, options = {}) {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}` + url,
    data: data || {},
    responseType: 'arraybuffer',
    ...(options || {}),
  })
    .then((res) => {
      console.log('res', res)

      // 创建下载的链接
      const url = window.URL.createObjectURL(
        new Blob(
          [res.data],
          // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式
          // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
          { type: type },
        ),
      )
      // 从header中获取服务端命名的文件名
      let filename = 'fileName'
      const disposition = res.headers.get('content-disposition')
      if (disposition) {
        const splitDis = disposition.split(';')
        if (splitDis[0] === 'attachment') {
          const fileNameDefault = splitDis[1].split('filename=')[1]
          const fileNameUnicode = disposition.split('filename*=')[1]
          filename = fileNameUnicode ? decodeURIComponent(fileNameUnicode.split("''")[1]) : fileNameDefault
        }
      }
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)
    })
    .catch((error) => {
      console.log(error)
    })
}
