import React, { useState, useRef, useEffect } from 'react'
import {
  Backdrop,
  Button,
  Box,
  Container,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import PrintIcon from '@mui/icons-material/Print'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ReactToPrint from 'react-to-print'
import * as api from 'src/utils/api/workerAttendanceRecord/AutoPayApi'
import CustomDialog from 'src/components/common/CustomDialog'
import $http, { $download } from 'src/utils/api/services'
import TablePart from './TablePart'

export default function Button9(props) {
  const { type, setType, data } = props
  const componentRef = useRef()
  const [step, setStep] = useState(0)
  const { t } = useTranslation()
  const [saveData, setSaveData] = useState({
    site: '',
    vendor: '',
    status: '',
  })
  const [open, setOpen] = useState(false)
  const [periodList, setPeriodList] = useState([])
  const [siteList, setSiteList] = useState([])
  const [vendorList, setVendorList] = useState()
  const [tableData, setTableData] = useState([])
  const [allData, setAllData] = useState({})
  const [gapData, setGapData] = useState({})
  const [numbers, setNumbers] = useState(0)
  const [dialog, setDialog] = useState({
    content: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        確認
      </Button>
    ),
    open: false,
  })

  useEffect(() => {
    setOpen(true)
    const getData = async () => {
      let getPeriodList = await api.getCompleteSearchPeriod() // 支薪及供款期
      if (getPeriodList) {
        setPeriodList(getPeriodList)
      }
      setOpen(false)
    }

    getData()
  }, [])
  const handlePeriod = (e) => {}
  const handleSelectPeriod = (item) => {
    setOpen(true)
    const getData = async () => {
      let getSiteList = await api.getCompleteSelectvendorsite(item.id) // 地盤
      let getVendorList = await api.getCompleteSubcontractor(item.id) // 分判商
      if (getSiteList) {
        setSiteList(getSiteList)
      }
      if (getVendorList) {
        setVendorList(getVendorList)
      }
      setOpen(false)
    }
    getData()
    const list = item.text.split('-')
    const dateFrom = list[0]
    const dateTo = list[1]
    setSaveData({
      ...saveData,
      dateFrom: dateFrom,
      dateTo: dateTo,
      period: item.id,
      status: item.status,
    })
    console.log('item', item)
    console.log('saveData', saveData)
  }

  const handleSite = (e) => {
    setSaveData({ ...saveData, site: e.target.value })
  }
  const handleVendor = (e) => {
    setSaveData({ ...saveData, vendor: e.target.value })
  }
  const handleCancel = () => {
    setType(0)
  }
  const handleSave = () => {
    if (!saveData.dateFrom && !saveData.dateTo) {
      setDialog({
        ...dialog,
        content: '必須選擇支薪及供款期',
        open: true,
      })
      return
    }
    setOpen(true)
    const getData = async () => {
      let getData = await api.getWorkerDetailRs(data.vendorSet.vendorId, saveData.dateFrom, saveData.dateTo, saveData.site, saveData.vendor)
      let getGapData = await api.getWorkerDetailRsGap(saveData.dateFrom, data.vendorSet.vendorId)

      if (getGapData) {
        setGapData(getGapData)
      }
      if (getData) {
        let count = 0
        let list = []
        getData.subDeatilData.map((item) => {
          count += item.workerDetailSet?.length
          if (saveData.site === '' && item.workerDetailSet?.length > 0) {
            for (let v of item.workerDetailSet) {
              list.push(v)
            }
          }
          return []
        })
        setNumbers(count)
        setTableData([...list])
        setAllData(getData)
      }
      setStep(1)
      setOpen(false)
    }
    getData()
  }
  const handleBack = () => {
    setStep(0)
  }
  const handlePrintPDF = () => {
    console.log('1')

    setOpen(true)
    let pdfData = {
      vendorId: data.vendorSet.vendorId,
      dateFrom: saveData.dateFrom,
      dateTo: saveData.dateTo,
      siteId: saveData.site,
      selectSubConId: saveData.vendor,
      pdDateTo: saveData.dateTo,
      pdDateFrom: saveData.dateFrom,
    }

    $download(`/autoPayController/ReportVendorAutoPayStatusDetailPdf`, 'application/pdf', pdfData)
    // let send = await api.searchAutoFilePrintPDF(pdfData)

    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {step === 0 || step === undefined ? (
        <Container sx={{ textAlign: 'center' }}>
          <CustomDialog open={dialog.open} title={dialog.title} content={dialog.content} action={dialog.action} />
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
            open={open}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Typography variant="h4">
            <b>{t('icon1_autopay_button9_title')}</b>
          </Typography>
          <TextField
            value={saveData.period || ''}
            onChange={handlePeriod}
            fullWidth
            sx={{ textAlign: 'left', maxWidth: '400px', mt: '20px' }}
            size="small"
            label={t('Payroll_and_Contribution_Period')}
            variant="outlined"
            select
          >
            {(periodList.length > 0 ? periodList : []).map((item) => (
              <MenuItem onClick={() => handleSelectPeriod(item)} key={item.id} value={item.id}>
                {item.text}
              </MenuItem>
            ))}
          </TextField>
          <br />
          <TextField
            value={saveData.site || ''}
            onChange={handleSite}
            fullWidth
            sx={{ textAlign: 'left', maxWidth: '400px', mt: '20px' }}
            size="small"
            label={t('Site_Name')}
            variant="outlined"
            select
          >
            <MenuItem key="" value="">
              All
            </MenuItem>
            {(siteList.length > 0 ? siteList : []).map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.text}
              </MenuItem>
            ))}
          </TextField>
          <br />
          <TextField
            disabled={saveData === ''}
            value={saveData.vendor || ''}
            onChange={handleVendor}
            fullWidth
            sx={{ textAlign: 'left', maxWidth: '400px', mt: '20px' }}
            size="small"
            label={t('Subcontractor_Name')}
            variant="outlined"
            select
          >
            <MenuItem key="" value="">
              All
            </MenuItem>
            {(vendorList || []).map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.text}
              </MenuItem>
            ))}
          </TextField>
          <br />
          <Button onClick={handleSave} sx={{ mt: '20px', width: '100px' }} variant="contained">
            {t('button_save')}
          </Button>
          <Button onClick={handleCancel} sx={{ mt: '20px', ml: '10px', width: '100px' }} variant="contained">
            {t('button_cancel')}
          </Button>
        </Container>
      ) : saveData.status === 'real' ? (
        <div id="div" ref={componentRef}>
          <Container maxWidth="false">
            <div style={{ marginTop: '0px' }}>
              <div
                style={{
                  display: 'block',
                  textAlign: 'left',
                  marginTop: '0px',
                }}
              >
                <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
                  <ArrowBackIcon />
                  {t('button_back')}
                </Button>
              </div>
              <div
                style={{
                  display: 'block',
                  textAlign: 'right',
                  marginTop: '0px',
                }}
              >
                <Button onClick={() => handlePrintPDF()}>
                  <PrintIcon />
                  {t('button_print')}
                </Button>
                {/* <ReactToPrint
                                    pageStyle={`@media print { body { -webkit-print-color-adjust: exact; width: 90% !important; } }`}
                                    // pageStyle="@page { transform: 'scale(0.8)'} @media print {html, body {width: 110%}}"
                                    trigger={() => <Button><PrintIcon />{t('button_print')}</Button>}
                                    content={() => componentRef.current}
                                    copyStyles
                                /> */}
              </div>
              <Typography variant="h4" sx={{ mt: '-40px', display: 'block', textAlign: 'center' }}>
                <b>{t('Contractor_Autopay_Status')}</b>
              </Typography>
              <div style={{ display: 'block', textAlign: 'left' }}>
                <Typography variant="h6" sx={{ display: 'block' }}>
                  <b>{t('icon1_autopay_button8_print_title')}</b>
                </Typography>
                <Grid container columnSpacing={1}>
                  <Grid item xs={6} md={2.5}>
                    {/* 僱主名稱 */}
                    <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('icon1_autopay_button8_print_label1')}</Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography sx={{ display: 'block', fontSize: '16px' }}>{allData.vendoridset && allData.vendoridset.vendorDesc}</Typography>
                  </Grid>
                  <Box width="100%" />
                  <Grid item xs={6} md={2.5}>
                    {/* 銀行戶口名稱 */}
                    <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('icon1_autopay_button8_print_label2')}</Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography sx={{ display: 'block', fontSize: '16px' }}>{allData.bankacset && allData.bankacset[0].bankAccountName}</Typography>
                  </Grid>
                  <Box width="100%" />
                  <Grid item xs={6} md={2.5}>
                    {/* 銀行戶口號碼 */}
                    <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('icon1_autopay_button8_print_label3')}</Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography sx={{ display: 'block', fontSize: '16px' }}>{allData.bankacset && allData.bankacset[0].bankAccount}</Typography>
                  </Grid>
                  <Box width="100%" />

                  <Grid item xs={6} md={2.5}>
                    {/* 計劃成員編號 / 東亞(強積金)行業計劃編號	 */}
                    {saveData.site ? (
                      <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('plan_Member_Number')}</Typography>
                    ) : (
                      <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('icon1_autopay_button8_print_label4')}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography sx={{ display: 'block', fontSize: '16px' }}>{allData.bankacset && allData.bankacset[0].schemeNo}</Typography>
                  </Grid>
                  <Box width="100%" />
                  {/* 僱主代號 */}
                  <Grid item xs={6} md={2.5}>
                    <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('icon1_autopay_button8_print_label5')}</Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography sx={{ display: 'block', fontSize: '16px' }}>{allData.bankacset && allData.bankacset[0].branchCode}</Typography>
                  </Grid>
                  <Box width="100%" />
                  {/* 聯絡人姓名 */}
                  <Grid item xs={6} md={2.5}>
                    <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('icon1_autopay_button8_print_label6')}</Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography sx={{ display: 'block', fontSize: '16px' }}>{allData.bankacset && allData.bankacset[0].contactPerson}</Typography>
                  </Grid>
                  <Box width="100%" />
                  {/* 電話號碼 */}
                  <Grid item xs={6} md={2.5}>
                    <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('icon1_autopay_button8_print_label7')}</Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography sx={{ display: 'block', fontSize: '16px' }}>{allData.bankacset && allData.bankacset[0].phoneNumber}</Typography>
                  </Grid>
                  <Box width="100%" />
                  {/* 支薪及供款期 */}
                  <Grid item xs={6} md={2.5}>
                    <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('Payroll_and_Contribution_Period')}</Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography sx={{ display: 'block', fontSize: '16px' }}>{saveData.dateFrom + '~' + saveData.dateTo}</Typography>
                  </Grid>
                  <Box width="100%" />
                  {/* 自動轉賬授權書的執行日期 */}
                  <Grid item xs={6} md={2.5}>
                    <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('icon1_autopay_button8_print_label8')}</Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography sx={{ display: 'block', fontSize: '16px' }}>{allData.autopaydateset[0]}</Typography>
                  </Grid>
                  <Box width="100%" />
                </Grid>
                <Divider sx={{ mt: '10px' }} />
                {/* table */}
                {/* All地盤 */}
                {!saveData.site ? (
                  <>
                    <Typography variant="h6" sx={{ display: 'block', mt: '20px' }}>
                      <b>{t('Workers_Income_And_Bank_Information')}</b>
                    </Typography>
                    <TablePart tableList={tableData} loanFlag={allData.bankacset[0].loanFlag} />
                  </>
                ) : (
                  <>
                    {(allData.subDeatilData ? allData.subDeatilData : []).map((item, index) => (
                      <div key={index}>
                        <Typography variant="h6" sx={{ display: 'block', mt: '20px' }}>
                          <b>{t('Workers_Income_And_Bank_Information')}</b>
                        </Typography>
                        <Grid sx={{ mt: '10px' }} container columnSpacing={1} key={index}>
                          <Grid item xs={6} md={2.5}>
                            <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('Payroll_and_Contribution_Period')}:</Typography>
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <Typography sx={{ display: 'block', fontSize: '16px' }}>
                              {saveData.dateFrom}~{saveData.dateTo}
                            </Typography>
                          </Grid>
                          <Box width="100%" />
                          <Grid item xs={6} md={2.5}>
                            <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('Site_Name')}:</Typography>
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <Typography sx={{ display: 'block', fontSize: '16px' }}>{saveData.site}</Typography>
                          </Grid>
                          <Box width="100%" />
                          <Grid item xs={6} md={2.5}>
                            <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('Subcontractor_Name')}:</Typography>
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <Typography sx={{ display: 'block', fontSize: '16px' }}>{allData.subDeatilData[index].subcondescset.subContractorDesc}</Typography>
                          </Grid>
                        </Grid>
                        <TablePart tableList={item.workerDetailSet} loanFlag={allData.bankacset[0].loanFlag} />
                      </div>
                    ))}
                  </>
                )}
                <div style={{ marginTop: '50px' }}>
                  <TableContainer>
                    <Table
                      sx={{
                        pageBreakInside: 'inherit',
                        minWidth: 650,
                        wordBreak: 'keep-all',
                      }}
                      size="small"
                    >
                      <TableHead>
                        <TableRow hover sx={{ border: '1px' }}>
                          <TableCell align="center" sx={{ fontSize: '15px' }} />
                          <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                            {t('icon1_autopay_button8_print_PeopleCount')}
                          </TableCell>
                          <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                            {t('Worker_income')}
                          </TableCell>
                          {allData.bankacset[0].loanFlag === 'Y' ? (
                            <>
                              <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                                {t('Borrowing')}
                              </TableCell>
                              <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                                {t('Deduct_loan')}
                              </TableCell>
                              <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                                {t('Income_after_borrowings')}
                              </TableCell>
                            </>
                          ) : null}
                          <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                            {t('Actual_Payroll')}
                          </TableCell>
                          <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                            {t('Employer_MPF_contributions')}
                          </TableCell>
                          <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                            {t('Employee_MPF_contributions')}
                          </TableCell>
                          {!saveData.site ? (
                            <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                              {t('Total_contributions_from_both_parties')}
                            </TableCell>
                          ) : null}
                          <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                            {t('Deductions_from_Contractor_Account')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow hover>
                          <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }} component="th" scope="row">
                            {t('Total_Contractors')}:
                          </TableCell>
                          <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                            {numbers}{' '}
                          </TableCell>
                          <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                            {' '}
                            {allData.final_tot_rel_format}{' '}
                          </TableCell>
                          {allData.bankacset[0].loanFlag === 'Y' ? (
                            <>
                              <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                                {allData.final_tot_adv_pay_format}
                              </TableCell>
                              <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                                {allData.final_tot_rtn_pay_format}
                              </TableCell>
                              <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                                {allData.final_tot_new_pay.toFixed(2)}
                              </TableCell>
                            </>
                          ) : null}
                          <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                            {allData.final_tot_net_format}
                          </TableCell>
                          <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                            {allData.final_tot_er_mpf_format}
                          </TableCell>
                          <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                            {allData.final_tot_ee_mpf_format}
                          </TableCell>
                          {!saveData.site ? (
                            <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                              {(allData.final_tot_ee_mpf + allData.final_tot_er_mpf).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                            </TableCell>
                          ) : null}
                          <TableCell align="center" sx={{ fontSize: '15px', textAlign: 'right' }}>
                            {(allData.final_tot_ee_mpf + allData.final_tot_er_mpf + allData.final_tot_net).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </Container>
        </div>
      ) : (
        <div
          style={{
            display: 'block',
            textAlign: 'left',
            marginTop: '50px',
          }}
        >
          <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
            <ArrowBackIcon />
            {t('button_back')}
          </Button>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
            open={open}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Typography variant="h4" sx={{ mt: '-40px', display: 'block', textAlign: 'center' }}>
            <b>{t('已制作沒有供款的糧期表')}</b>
          </Typography>
          <Typography variant="h6" sx={{ display: 'block' }}>
            <b>{t('icon1_autopay_button8_print_title')}</b>
          </Typography>

          <Grid container columnSpacing={1}>
            <Grid item xs={6} md={2.5}>
              {/* 僱主名稱 */}
              <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('icon1_autopay_button8_print_label1')}</Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography sx={{ display: 'block', fontSize: '16px' }}>{gapData.vendorId + ' - ' + gapData.vendorDesc}</Typography>
            </Grid>
            <Box width="100%" />
            {/* 支薪及供款期 */}
            <Grid item xs={6} md={2.5}>
              <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('Payroll_and_Contribution_Period')}</Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography sx={{ display: 'block', fontSize: '16px' }}>{saveData.dateFrom + '~' + saveData.dateTo}</Typography>
            </Grid>
            <Box width="100%" />
            {/* 備註 */}
            <Grid item xs={6} md={2.5}>
              <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('Remark')}</Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography sx={{ display: 'block', fontSize: '16px' }}>{gapData.remark}</Typography>
            </Grid>
            <Box width="100%" />
          </Grid>
        </div>
      )}
    </>
  )
}
