import { Box, CircularProgress, IconButton, Paper, Table, TableBody, TableContainer, TableFooter, TablePagination, TableRow } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
// import type { SxProps } from '@mui/material/styles'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import React, { useEffect, useMemo, useState } from 'react'

import CTableRow from './CTableRow'
import CTableHead from './CTableHead'

import './index.css'

const StyledTableFooter = styled(TableFooter)(() => ({
    '.MuiTableCell-footer': {
        borderBottom: 0
    }
}))

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}
function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

function CTablePaginationActions(props) {
    const theme = useTheme()
    const { count, page, rowsPerPage, onPageChange } = props

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0)
    }
    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1)
    }
    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1)
    }
    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    )
}


export default function CTable(props) {

    const { columns = [], data = [], disableHeadHide = false, disablePagination = false, expandable, rowSelection, loading = false, rowKey, sort, tableMaxHeight = 'none', cellSx, paperSx, onClick } = props

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const handleChangePage = (_event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    // 排序 Start
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('')

    const handleRequestSort = (_event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }
    // 排序 End

    const newRows = useMemo(() => {
        if (!disablePagination) {
            return rowsPerPage > 0 ? stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : stableSort(data, getComparator(order, orderBy))
        }
        return data
    }, [data, disablePagination, orderBy, order, page, rowsPerPage])

    // 獲取表頭信息
    useEffect(() => {
        if (sort) {
            setOrder(sort.order)
            setOrderBy(sort.orderBy)
        }
    }, [data, sort])

    const [selected, setSelected] = React.useState([])

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const selectedRowKeys = data.map((n) => n[rowKey])
            setSelected(selectedRowKeys)
            rowSelection?.onChange(selectedRowKeys, data)
            return
        }
        setSelected([])
        rowSelection?.onChange([], [])
    }

    const handleSelectClick = (event, name) => {
        const selectedIndex = selected.indexOf(name)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
        }

        setSelected(newSelected)
    }

    const isSelected = (name) => selected.indexOf(name) !== -1

    return (
        <Box sx={{ position: 'relative' }}>
            <TableContainer component={Paper} variant="outlined" sx={{ maxHeight: tableMaxHeight, borderRadius: '8px', ...paperSx }}>
                <Table stickyHeader aria-label="sticky simple table">
                    {/* 頭部 */}
                    <CTableHead
                        columns={columns}
                        expandable={expandable}
                        hide={disableHeadHide}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        rowCount={data.length}
                        rowSelection={rowSelection}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                    />

                    {/* 内容 */}
                    <TableBody>
                        {newRows.map((row, index) => (
                            <CTableRow
                                cellSx={cellSx}
                                columns={columns}
                                expandable={expandable}
                                index={index}
                                key={row[rowKey] || index}
                                row={row}
                                rowKey={rowKey}
                                rowSelection={rowSelection}
                                isSelected={isSelected}
                                onClick={onClick}
                                onSelectClick={handleSelectClick}
                            />
                        ))}
                    </TableBody>

                    {/* 分页 */}
                    {disablePagination ? null : (
                        <StyledTableFooter>
                            <TableRow>
                                <TablePagination
                                    ActionsComponent={CTablePaginationActions}
                                    colSpan={columns.length + (expandable ? 1 : 0) + (rowSelection ? 1 : 0)}
                                    count={data.length}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    SelectProps={{ inputProps: { 'aria-label': 'rows per page' }, native: true }}
                                />
                            </TableRow>
                        </StyledTableFooter>
                    )}
                </Table>

                {/* 數據加載 */}
                {data.length === 0 ? (
                    <Box sx={{ pt: 6, pb: 6 }}>
                        <></>
                    </Box>
                ) : null}
            </TableContainer>

            {loading ? (
                <>
                    <Box className="table-backdrop-loading" />
                    <Box className="table-backdrop-loading-circular-progress">
                        <CircularProgress />
                    </Box>
                </>
            ) : null}
        </Box>
    )

}