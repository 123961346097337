import React, { useState, useEffect } from 'react';
import {
    TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function CDatePicker(props) {
    const errorFun = (props, reason) => {
        return !!props.required && !!!props.value || reason === 'invalidDate';
    }
    const [reason, setReason] = React.useState("");
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                mask='____/__/__'
                inputFormat='YYYY/MM/DD'
                onError={(reason) => {
                    setReason(reason)
                }}
                {...props}
                // error={!!props.required && !!!props.value && !!params.onError}
                renderInput={(params) => <TextField {...params} error={errorFun(props, reason)} fullWidth required={props.required} size="small" />}
            />
        </LocalizationProvider>

    )
}
export default CDatePicker;