import React, { useRef, useState, useEffect } from 'react'
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  Link,
  Grid,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  TableRow,
  Backdrop,
  CircularProgress,
  TextField,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSearchParams, useNavigate, createSearchParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import DownloadIcon from '@mui/icons-material/Download'
import CustomDialog from 'src/components/common/CustomDialog'
import moment from 'moment'
import $http, { $download } from 'src/utils/api/services'
import { color } from 'echarts'

// 承判商自動轉賬收條--详情界面
export default function CarSearchDetail() {
  const componentRef = useRef()
  const [searchParams] = useSearchParams()
  const [recordscriteriatxt, setRecordscriteriatxt] = React.useState()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [mainData, setMainData] = React.useState([])
  const [adminColum, setAdminColum] = React.useState('')
  const [isCanChooseAll, setIsCanChooseAll] = React.useState('')
  const [loading, setLoading] = React.useState(true)
  const [checkBoxChooseAll, setCheckBoxChooseAll] = React.useState(false)

  const handleBack = () => {
    navigate(-1)
  }

  const [dialog, setDialog] = useState({
    title: '',
    content: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        {t('Confirm')}
      </Button>
    ),
    open: false,
  })

  const exportExcel = (data) => {
    $download(`/autoPayController/getMaintDebitAdviceSearchExcel`, 'application/vnd.ms-excel', data)
  }

  const printComponentRef = useRef()
  const getPayRollTypeDesc = (payrollType) => {
    let payRollTypeDesc = '整個糧期'
    if (payrollType === 'F') {
      payRollTypeDesc = '整個糧期'
    } else if (payrollType === 'P') {
      payRollTypeDesc = '部分糧期'
    } else if (payrollType === 'S') {
      payRollTypeDesc = '短糧期'
    }

    return payRollTypeDesc
  }

  // 点击日期跳转
  function clickDate(mainDataRow) {
    console.log('mainDataRow', mainDataRow)
    navigate({
      pathname: '/systemAdministrator/carSearch/carSearchDetail/carDateDetailShort',
      search: `?${createSearchParams(
        {
          dateFrom: mainDataRow.phDateFrom,
          pdDateFrom: mainDataRow.pdDateFrom,
          pdDateTo: mainDataRow.pdDateTo,
          phDateTo: mainDataRow.phDateTo,
          payrollType: mainDataRow.payrollType,
          vendorId: mainDataRow.vendorId,
          autopayDate: mainDataRow.autopayDate,
          vendorDesc: mainDataRow.vendorDesc,
          subBatchId: mainDataRow.pdSubbatchId,
        },
        { state: { fromSpecificPage: true } },
      )}`,
    })
  }

  // 单条修改
  const chickChange = async (mainDataRow) => {
    let list = await apiAtt.updateMaintAdm(mainDataRow)
    setLoading(false)
    if (list !== null) {
      setLoading(true)
      // 重新刷新数据
      getMaintDebitAdviceSearch()
    }
  }

  // 获取日期列表最大的日期
  const getMaxDate = () => {
    let dateList = []
    mainData.map((value) => {
      dateList.push(value.effectiveDate)
      return []
    })
    let moments = dateList.map((d) => moment(d))

    return moment.max(moments).format('YYYY/MM/DD')
  }

  function clickCell(index) {}

  // 重置数据
  // 将数据的状态重置回到和judgeMainData的approve一致
  const resetApproveStatues = () => {
    getMaintDebitAdviceSearch()
  }

  // 勾選---薪金轉賬收條
  const handleCheckbox1 = (mainDataRow) => {
    // mainDataRow.payrollFlag
    // 修改原數據這個字段
    let tempData = mainData

    tempData.map((value) => {
      if (
        value.headerId === mainDataRow.headerId &&
        value.payrollType === mainDataRow.payrollType &&
        value.pdSubbatchId === mainDataRow.pdSubbatchId &&
        value.pdDateFrom === mainDataRow.pdDateFrom &&
        value.pdDateTo === mainDataRow.pdDateTo
      ) {
        // 修改
        value.payrollFlag = mainDataRow.payrollFlag === 'Y' ? 'N' : 'Y'
      }
      console.log('payrollFlag', value.payrollFlag)
      return []
    })

    setMainData([...tempData])
  }

  // 勾選---強積金轉賬收條
  const handleCheckbox2 = (mainDataRow) => {
    // mainDataRow.payrollFlag
    // 修改原數據這個字段
    let tempData = mainData

    tempData.map((value) => {
      if (
        value.headerId === mainDataRow.headerId &&
        value.payrollType === mainDataRow.payrollType &&
        value.pdSubbatchId === mainDataRow.pdSubbatchId &&
        value.pdDateFrom === mainDataRow.pdDateFrom &&
        value.pdDateTo === mainDataRow.pdDateTo
      ) {
        // 修改
        value.mpfFlag = mainDataRow.mpfFlag === 'Y' ? 'N' : 'Y'
      }
      return []
    })

    setMainData([...tempData])
  }

  // 勾選---Supervisor批核
  const handleCheckbox3 = (mainDataRow) => {
    // mainDataRow.payrollFlag
    // 修改原數據這個字段
    let tempData = mainData

    tempData.map((value) => {
      if (
        value.headerId === mainDataRow.headerId &&
        value.payrollType === mainDataRow.payrollType &&
        value.pdSubbatchId === mainDataRow.pdSubbatchId &&
        value.pdDateFrom === mainDataRow.pdDateFrom &&
        value.pdDateTo === mainDataRow.pdDateTo
      ) {
        // 修改
        value.supFlag = mainDataRow.supFlag === 'Y' ? 'N' : 'Y'

        console.log(' value.supFlag', value.supFlagNew)
      }
      return []
    })

    setMainData([...tempData])
  }

  // 勾選---Supervisor批核
  const handleCheckboxAll = () => {
    // mainDataRow.payrollFlag
    // 修改原數據這個字段
    let tempData = mainData

    let data = ''
    if (checkBoxChooseAll === true) {
      data = 'N'
      setCheckBoxChooseAll(false)
    } else {
      data = 'Y'
      setCheckBoxChooseAll(true)
    }
    tempData.map((value) => {
      // 修改
      value.supFlag = data
      return []
    })

    setMainData([...tempData])
  }

  // 遞交數據
  const xuPayrollHeaderBea = async () => {
    setLoading(true)
    let list = await apiAtt.xuPayrollHeaderBea(mainData)

    setLoading(false)
    console.log(list)

    if (list.code === 500) {
      setDialog({
        ...dialog,
        content: list.message,
        open: true,
      })
      //   alert(list.error)
    } else {
      setDialog({
        ...dialog,
        content: t('search_submit_success'),
        action: (
          <Button
            variant="contained"
            onClick={() => {
              getMaintDebitAdviceSearch()
              setDialog({ ...dialog, open: false })
            }}
          >
            {t('Confirm')}
          </Button>
        ),
        open: true,
      })
    }
  }

  const getDateFrom = () => {
    let dateF = ''
    dateF = searchParams.get('accessDateFrom')
    while (dateF.indexOf('-') >= 0) {
      dateF = dateF.replace('-', '/')
    }
    return dateF
  }

  const getDateTo = () => {
    let dateF = ''
    dateF = searchParams.get('accessDateTo')
    while (dateF.indexOf('-') >= 0) {
      dateF = dateF.replace('-', '/')
    }
    return dateF
  }

  const getMaintDebitAdviceSearch = async () => {
    let list = await apiAtt.getMaintDebitAdviceSearch(
      searchParams.get('searChingCriteria'),
      getDateFrom(),
      getDateTo(),
      searchParams.get('vendorId'),
      searchParams.get('bankAccount'),
      searchParams.get('mpfScheme'),
      searchParams.get('recordsCriteria'),
    )
    console.log(list)
    if (list.code === 200) {
      setMainData(list.data.DebitAdvice)
      setAdminColum(list.data.Flag)
      setIsCanChooseAll(list.data.HeaderFlag)
      setRecordscriteriatxt(list.data.recordscriteriatxt)
    }
    setLoading(false)
  }

  useEffect(() => {
    getMaintDebitAdviceSearch()
  }, [])

  return (
    <Container maxWidth={false}>
      <CustomDialog open={dialog.open} title={dialog.title} content={dialog.content} action={dialog.action} />

      <div style={{ display: 'block', textAlign: 'left' }}>
        <Button sx={{ float: 'left' }} onClick={handleBack}>
          <ArrowBackIcon />
          {t('button_back')}
        </Button>
      </div>

      <div id="div" style={{}} ref={printComponentRef}>
        <Container maxWidth={false}>
          <div>
            <div style={{ display: 'block', marginBottom: '60px' }}>
              <Typography variant="h4" sx={{ textAlign: 'center' }}>
                <b>{t('search_vendor_autoPay_receipt')}</b>
              </Typography>

              <Button
                sx={{ float: 'right', mr: '20px' }}
                onClick={() =>
                  exportExcel({
                    searChingCriteria: searchParams.get('searChingCriteria'),
                    dateFrom: getDateFrom(),
                    dateTo: getDateTo(),
                    vendorId: searchParams.get('vendorId'),
                    vendorDesc: searchParams.get('vendorDesc'),
                    bankAccount: searchParams.get('bankAccount'),
                    mpfScheme: searchParams.get('mpfScheme'),
                    recordsCriteria: searchParams.get('recordsCriteria'),
                  })
                }
              >
                <DownloadIcon />
                {t('button_export_excel')}
              </Button>
            </div>

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>

            {
              <div>
                <TableContainer component={Paper} sx={{ marginBottom: '20px', backgroundColor: 'transparent' }}>
                  <Table sx={{ textAlign: 'left' }} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('Vendor') + '：' + searchParams.get('vendorDesc') === null ? t('all_contractors') : searchParams.get('vendorDesc')}</TableCell>
                      </TableRow>

                      {searchParams.get('accessDateFrom').length > 1 && (
                        <TableRow>
                          <TableCell sx={{ px: '5px', border: 0 }}>{t('search_autoPay_date_from') + getDateFrom()}</TableCell>
                        </TableRow>
                      )}

                      {searchParams.get('accessDateTo').length > 1 && (
                        <TableRow>
                          <TableCell sx={{ px: '5px', border: 0 }}>{t('search_autoPay_date_to') + getDateTo()}</TableCell>
                        </TableRow>
                      )}

                      {searchParams.get('bankAccount').length > 1 && (
                        <TableRow>
                          <TableCell sx={{ px: '5px', border: 0 }}>{t('search_bank_account_title') + searchParams.get('bankAccount')}</TableCell>
                        </TableRow>
                      )}

                      {searchParams.get('mpfScheme').length > 1 && (
                        <TableRow>
                          <TableCell sx={{ px: '5px', border: 0 }}>{t('search_plan_person_number') + searchParams.get('mpfScheme')}</TableCell>
                        </TableRow>
                      )}

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>
                          {t('search_relate_file')} {recordscriteriatxt}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }
          </div>

          {/* table */}
          <TableContainer sx={{ marginTop: '20px' }}>
            <Table sx={{ p: '2px' }} size="small">
              <TableHead>
                <TableRow hover>
                  {/* (siteName, carid, engName, chName, joy, approveDate, inDate, inVateDate, applyDate */}
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('Vendor')}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(2)}>
                      {t('search_delivery_method')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(3)}>
                      {t('search_autopay_date')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{}}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(4)}>
                      {t('search_big_grainPeriod_by')}
                      <br /> {t('search_big_grainPeriod_to')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{}}>
                    <Link underline="none" sx={{}} onClick={() => clickCell(5)}>
                      {t('search_small_grainPeriod_by')}
                      <br /> {t('search_small_grainPeriod_to')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(6)}>
                      {t('search_relevantIncome')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(7)}>
                      {t('search_total_mpf_contributions')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(8)}>
                      {t('search_number_of_people_paying')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(8)}>
                      {t('icon1_autopay_button8_print_label3')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(8)}>
                      {t('plan_Member_Number')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(8)}>
                      {t('search_income_receipt')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(8)}>
                      {t('search_mpf_receipt')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(8)}>
                      {t('search_pass_allChoose')}
                    </Link>
                    <FormGroup aria-label="position" row={true}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <FormControlLabel
                        labelPlacement="end"
                        checked={checkBoxChooseAll === true}
                        control={<Checkbox disabled={isCanChooseAll === 'N'} onClick={() => handleCheckboxAll()} size="small" />}
                        label={'全选'}
                      />
                    </FormGroup>
                  </TableCell>

                  {/* IT Admin更改 */}
                  {adminColum === 'Y' && (
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(8)}>
                        {'IT Admin更改'}
                      </Link>
                    </TableCell>
                  )}
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('備註')}</TableCell>
                </TableRow>
              </TableHead>

              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>

              {mainData.map((mainDataRow, index) => (
                <>
                  {/* 單個人數據，需要循環遍歷 */}
                  <TableRow hover key={index + Math.random}>
                    {/* 承判商 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{mainDataRow.vendorDesc} </TableCell>
                    {/* 出糧方式 */}
                    <TableCell
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: '15px',
                        textAlign: 'left',
                      }}
                    >
                      {getPayRollTypeDesc(mainDataRow.payrollType)}{' '}
                    </TableCell>
                    {/* 自動轉賬日期 */}
                    <TableCell
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: '15px',
                        textAlign: 'left',
                      }}
                    >
                      <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickDate(mainDataRow)}>
                        {mainDataRow.autopayDate}
                      </Link>
                    </TableCell>
                    {/* 	大糧期（由）~大糧期（至） */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{mainDataRow.phDateFrom + '~' + mainDataRow.phDateTo} </TableCell>
                    {/* 短糧期（由）~短糧期（至） */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                      <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickDate(mainDataRow)}>
                        {mainDataRow.payrollType === 'P'
                          ? mainDataRow.pdDateFrom + '~' + mainDataRow.pdDateTo + '(' + mainDataRow.pdSubbatchId + ')'
                          : mainDataRow.pdDateFrom + '~' + mainDataRow.pdDateTo}
                        {/* {mainDataRow.pdDateFrom + '~' + mainDataRow.pdDateTo + '(' + mainDataRow.autopayLevel + ')'} */}
                      </Link>
                    </TableCell>
                    {/* 有關收入 */}
                    <TableCell
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: '15px',
                        textAlign: 'left',
                      }}
                    >
                      {mainDataRow.netIncomeformat}{' '}
                    </TableCell>
                    {/* 強積金供款總數 */}
                    <TableCell
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: '15px',
                        textAlign: 'left',
                      }}
                    >
                      {mainDataRow.totalMpfformat}{' '}
                    </TableCell>
                    {/* 出糧人數 */}
                    <TableCell
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: '15px',
                        textAlign: 'left',
                      }}
                    >
                      {mainDataRow.workerNum}{' '}
                    </TableCell>
                    {/* 銀行戶口號碼 */}
                    <TableCell
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: '15px',
                        textAlign: 'left',
                      }}
                    >
                      {mainDataRow.bankAccount}{' '}
                    </TableCell>
                    {/* 計劃成員編號 */}
                    <TableCell
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: '15px',
                        textAlign: 'left',
                      }}
                    >
                      {mainDataRow.schemeNo}{' '}
                    </TableCell>
                    {/* 薪金轉賬收條 */}
                    <TableCell
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: '15px',
                        textAlign: 'left',
                      }}
                    >
                      <FormGroup aria-label="position" row={true}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <FormControlLabel
                          labelPlacement="end"
                          control={<Checkbox disabled={mainDataRow.payrollFlagIlable === 'N'} checked={mainDataRow.payrollFlag === 'Y'} size="small" onClick={() => handleCheckbox1(mainDataRow)} />}
                        />
                      </FormGroup>
                    </TableCell>
                    {/* 強積金轉賬收條 */}
                    <TableCell
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: '15px',
                        textAlign: 'left',
                      }}
                    >
                      {mainDataRow.totalMpfformat !== '0.00' ? (
                        <FormGroup aria-label="position" row={true}>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <FormControlLabel
                            labelPlacement="end"
                            control={<Checkbox disabled={mainDataRow.mpfFlagNew === 'Y'} checked={mainDataRow.mpfFlag === 'Y'} size="small" onClick={() => handleCheckbox2(mainDataRow)} />}
                          />
                        </FormGroup>
                      ) : (
                        <font color="red">此糧期無須提供強積金轉帳收條</font>
                      )}
                    </TableCell>
                    {/* Supervisor批核全選 */}
                    <TableCell
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: '15px',
                        textAlign: 'left',
                      }}
                    >
                      <FormGroup aria-label="position" row={true}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <FormControlLabel
                          labelPlacement="end"
                          control={<Checkbox disabled={mainDataRow.supFlagNew === 'Y'} checked={mainDataRow.supFlag === 'Y'} size="small" onClick={() => handleCheckbox3(mainDataRow)} />}
                        />
                      </FormGroup>
                    </TableCell>

                    {adminColum === 'Y' && (
                      <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                        <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => chickChange(mainDataRow)}>
                          {'Change'}
                        </Link>
                      </TableCell>
                    )}
                    <TableCell
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: '15px',
                        textAlign: 'left',
                      }}
                    >
                      {''}{' '}
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </Table>
          </TableContainer>

          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <FormControl size="small" style={{ paddingTop: '20px' }}>
              <Button
                variant="contained"
                onClick={xuPayrollHeaderBea}
                label="Submit"
                disabled={mainData.length === 0}
                sx={{
                  backgroundColor: 'primaryGreen',
                  color: 'commom.white',
                  marginLeft: '20px',
                }}
              >
                {t('button_submit')}
              </Button>
            </FormControl>

            {/* 重置 */}
            <FormControl size="small" style={{ paddingTop: '20px' }}>
              <Button
                variant="contained"
                onClick={resetApproveStatues}
                label="Reset"
                disabled={mainData.length === 0}
                sx={{
                  backgroundColor: 'primaryGray',
                  color: 'commom.white',
                  marginLeft: '20px',
                }}
              >
                {t('button_reset')}
              </Button>
            </FormControl>
          </Grid>
        </Container>
      </div>
    </Container>
  )
}
