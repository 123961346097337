import React, { useState, useRef, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography, MenuItem,
  TextField, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Backdrop, CircularProgress

} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, createSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InputDropDown from "src/components/use-form/InputDropDown";
import { useForm } from "react-hook-form";
import CustomDialog from "src/components/common/CustomDialog";
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'

// 終止工人進入地盤申請表----查詢
export default function QtoweafSearch() {

  const navigate = useNavigate();
  const componentRef = useRef()
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [applyDate, setApplyDate] = useState("");
  const [applyDateList, setApplyDateList] = useState([]);


  const handleBack = () => {
    navigate(-1)
  }

  const { control, handleSubmit, setValue, getValues, reset } = useForm({
    defaultValues: {
      applyDate: "",
    },
  });

  const submitForm = (data) => {
  };

  const resetForm = (data) => {
    reset({
      applyDate: "",
    });
  };

  const [dialog, setDialog] = useState({
    title: "",
    content: "",
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false });
        }}
      >
        {t("Confirm")}
      </Button>
    ),
    open: false,
  }); 

  const showDetailResult = (data) => {
    if (!applyDate) {
      setDialog({
        ...dialog,
        content: t("search_chooseDatetips"),
        open: true,
      });
      return;
    }

    navigate({
      pathname: '/workerAttendanceRecord/toweafMain/qtoweafSearch/qtoweafDetail',
      search: `?${createSearchParams({
        'applyDate': applyDate,
      }, { state: { fromSpecificPage: true } })}`
    })
  }

  // 修改報表類型
  const handleApplicationDate = (item) => { 
    setApplyDate(item.id)
  };

  useEffect(() => {
    const getApplication = async () => {
      let getApplicationSelect = await apiAtt.getApplication()
      if (getApplicationSelect) {
        const result = getApplicationSelect
        setApplyDateList(result)
      }
    }

    getApplication()
  }, [])


  return (

    < >
      {(
        <>
          <div style={{ display: 'block', textAlign: 'left' }}>
            <Button sx={{ float: 'left' }} onClick={handleBack}><ArrowBackIcon />{t('button_back')}</Button>
          </div>
          <div id='div' ref={componentRef}   >
            <Container  >
              <div>
                <div style={{ display: 'block' }}  >
                  <Typography variant='h4' style={{ color: 'black', textAlign: 'center',   }}
                  >
                    <b>{t("search_check_teminateApplayCase_title")}</b>
                  </Typography>

                  <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>

                  <Container sx={{ width: '500px', borderColor: '#d3d3d3', borderRadius: 4 }}>

                    <Grid item xs={6} sx={{ margin: 'auto', width: '400px',  marginTop: '10px' }}>
                      <TextField error={!applyDate} id="siteDesc" value={applyDate || ""} fullWidth size="small" label={t("search_apply_date_title")} select >
                      <MenuItem value="" onClick={() => handleApplicationDate({ id: null, text: 'All' })}>None</MenuItem>
                        {applyDateList.map((item, index) => (
                          <MenuItem key={index} value={item.id} name={item.id} onClick={() => handleApplicationDate(item)}>
                            {item.id}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                  </Container>
                </div>
              </div>

              <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '18px'
              }} >
                {/* 提交按钮 */}
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <FormControl size="small">
                    <Button
                      sx={{
                        backgroundColor: "primaryBlue",
                        color: "commom.white",
                      }}
                      variant="contained"
                      onClick={handleSubmit(showDetailResult)}
                    >
                      {t("button_submit")}
                    </Button>
                  </FormControl>

                  {/* 重置按钮 
                  <FormControl size="small">
                    <Button
                      variant="contained"
                      onClick={resetForm}
                      label="Reset"
                      sx={{
                        backgroundColor: "primaryGreen",
                        color: "commom.white",
                        marginLeft: "20px",
                      }}
                    >
                      {t("button_reset")}
                    </Button>
                  </FormControl>
                  */}
                </Grid>
              </div>
            </Container>
          </div>
        </>
      )}

      <CustomDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        action={dialog.action}
      />
    </>
  )
}

