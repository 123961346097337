import React, { useRef, useEffect } from "react";
import {
  Button,
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Link,
  TableHead,
  TableRow,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as api from "src/utils/api/workerAttendanceRecord/PFSApplicationApi";

export default function ApplyHisrotyView() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const componentRef = useRef();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [headerData, setHeaderData] = React.useState({});
  const [contentData, setContentData] = React.useState([]);

  useEffect(() => {
    const getHistoryViewData = async () => {
      let contentList = await api.getPFSApplicationHistoryViewList(
        applicationId,
        smartCardId,
        siteId,
        vendorId,
        yearmonth,
        effectiveDate
      );
      if (contentList) {
        if(contentList.length === 1){
          showEditDialog(contentList[0]);
        }else{
          let data = await api.getPFSApplicationHistoryViewHeader(applicationId);
          if (data !== null) {
            data.records.map((value) => {
              setHeaderData(value);
              return [];
            });
          }
          setContentData(contentList);
        }
      }
    };

    let applicationId = searchParams.get("applicationId");
    let smartCardId = searchParams.get("smartCardId");
    let siteId = searchParams.get("siteId");
    let vendorId = searchParams.get("vendorId");
    let yearmonth = searchParams.get("yearmonth");
    let effectiveDate = searchParams.get("effectiveDate");
    getHistoryViewData();
    setLoading(false);
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const showEditDialog = (value) => {
    let isViewData = "true";
    navigate({
      pathname: "/workerAttendanceRecord/adocspMain/applyList/applyEditOrView",
      // isViewData 为true的时候表示是查看数据 为false的时候表示编辑数据
      search: `?${createSearchParams({
        isViewData: isViewData,
        applicationId: value.applicationId,
        applicationSeqNo: value.applicationSeqNo,
      })}`,
    });
  };

  return (
    <>
      <div style={{ display: "block", textAlign: "left" }}>
        <Button sx={{ float: "left" }} onClick={handleBack}>
          <ArrowBackIcon />
          {t("button_back")}
        </Button>
      </div>
      <div id="div" ref={componentRef} style={{}}>
        <Container maxWidth="false">
          <div>
            <div style={{ display: "block", marginBottom: "60px" }}>
              <Typography variant="h4" sx={{ textAlign: "center" }}>
                <b>{t("search_vendor_ss_outDays")}</b>
              </Typography>
            </div>

            {/*  */}

            <div>
              <TableContainer
                component={Paper}
                sx={{ marginBottom: "30px", backgroundColor: "transparent" }}
              >
                <Table sx={{ textAlign: "left" }} size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_site_title_dot")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.siteDesc || ""}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("icon1_accrecvendorapprovedsearch_sub_condition")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.vendorDesc || ""}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_subcontractor_dEmployer")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.subContractorDesc || ""}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_work_type_dot")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.jobNatureDesc || ""}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_smartCardId_dot")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.smartCardId || ""}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_date_dot")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.yearmonth || ""}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_chinese_name_dot")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.chineseName || ""}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_english_name_dot")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.englishName || ""}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_employment_position")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.safetyPositionDesc || ""}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_employment_position_approve_date")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.spApprovalDate || ""}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_in_efficient_date")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.effectiveDate || ""}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_in_valite_date_to")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.effectiveDateTo || ""}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            {/*  */}
            <div>
              <TableContainer>
                <Table sx={{ minWidth: 650, p: "2px" }} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("search_handle_title")}</TableCell>
                      <TableCell>{t("search_submitDate")}</TableCell>
                      <TableCell>{t("button_submit")}</TableCell>
                      <TableCell>{t("search_statues_not_dot")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {contentData.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Link
                            underline="none"
                            sx={{ whiteSpace: "nowrap" }}
                            onClick={() => showEditDialog(row)}
                          >
                            View
                          </Link>
                        </TableCell>
                        <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                          {row.submitDate || ""}
                        </TableCell>
                        <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                          {row.submittedBy || ""}
                        </TableCell>
                        <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                          {row.applicationStatusDesc || ""}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
