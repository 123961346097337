import React, { useRef, useState, useEffect } from 'react'
import { Container, Typography, Paper, Table, TableBody, TableHead, TableCell, TableContainer, Link, TableRow, Backdrop, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import DownloadIcon from '@mui/icons-material/Download'
import moment from 'moment'

// // 承判商自動轉賬收條---点击日期详情界面----短糧期
export default function CarDateDetailShort() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [mainData, setMainData] = React.useState([])
  const [headData, setHeadData] = React.useState({})
  const [vendorDataList, setVendorDataList] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  useEffect(() => {}, [])

  const handleBack = () => {
    navigate(-1)
  }

  const printComponentRef = useRef()

  useEffect(() => {
    getMaintDebit()
  }, [])

  const getMaintDebit = async () => {
    try {
      let list = {}

      if (searchParams.get('payrollType') === 'F' || searchParams.get('payrollType') === 'P') {
        console.log('searchParams', searchParams.get('subBatchId'))
        // 整個糧期或者部分糧期
        // 這兩個接口一致
        list = await apiAtt.searchGenerateAndPartialBatchReport({
          dateFrom: searchParams.get('dateFrom'),
          vendorId: searchParams.get('vendorId'),
          subBatchId: searchParams.get('subBatchId'),
        })
      } else {
        if (searchParams.get('phDateTo') === searchParams.get('pdDateTo')) {
          // 最后短糧期
          list = await apiAtt.searchFinalGenerateReport(searchParams.get('dateFrom'), searchParams.get('headerId'), searchParams.get('vendorId'))
        } else {
          // 短糧期
          list = await apiAtt.searchGenerateSubGenerateReport(
            searchParams.get('dateFrom'),
            searchParams.get('pdDateFrom'),
            searchParams.get('pdDateTo'),
            searchParams.get('headerId'),
            searchParams.get('vendorId'),
          )
        }
      }

      if (list !== null) {
        setMainData(list.data)
        setVendorDataList(list.data.WorkerDetailSet)
        setHeadData(list.data.periodset)
      }
      setLoading(false)
    } catch (error) {
      console.log('🚀 ~ file: CarDateDetailShort.js:69 ~ getMaintDebit ~ error:', error)
    }
  }

  // 获取日期列表最大的日期
  const getDescTitle = () => {
    if (searchParams.get('payrollType') === 'F') {
      // 整個糧期或者部分糧期
      // 這兩個接口一致
      return '整個糧期'
    } else if (searchParams.get('payrollType') === 'P') {
      return '部分糧期'
    } else {
      if (searchParams.get('phDateTo') === searchParams.get('pdDateTo')) {
        // 最后短糧期
        return '最后糧期'
      } else {
        // 短糧期
        return '短糧期'
      }
    }
  }

  function clickCell(index) {}

  return (
    <Container maxWidth={false}>
      <div style={{ display: 'block', textAlign: 'left' }}>
        <Button sx={{ float: 'left' }} onClick={handleBack}>
          <ArrowBackIcon />
          {t('button_back')}
        </Button>
      </div>

      {/* <div style={{ display: 'block', textAlign: 'right' }}>
        <Button sx={{ float: "right", mr: "20px" }}>
          <DownloadIcon />
          {t("button_export_pdf")}
        </Button>
      </div> */}

      <div id="div" style={{}} ref={printComponentRef}>
        <Container maxWidth={false}>
          <div>
            <div style={{ display: 'block', marginBottom: '60px' }}>
              <Typography variant="h4" sx={{ textAlign: 'center' }}>
                <b>{t('search_vendor_autoPay_statues')}</b>
              </Typography>
            </div>

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>

            {
              <div>
                <TableContainer sx={{ marginBottom: '20px', backgroundColor: 'transparent' }} maxWidth={false}>
                  <Table sx={{ textAlign: 'left' }} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('icon1_autopay_button8_print_title')}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('icon1_autopay_button8_print_label1') + '：' + searchParams.get('vendorDesc')}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('icon1_autopay_button8_print_label2') + '：' + headData.bankAccountName}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('search_bank_account_title') + headData.bankAccount}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('icon1_autopay_button8_print_label4') + '：' + headData.schemeNo}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('icon1_autopay_button8_print_label5') + '：' + headData.branchCode}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('icon1_autopay_button8_print_label6') + '：' + headData.contactPerson}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('icon1_autopay_button8_print_label7') + '：' + headData.phoneNumber}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('Big_Pay_Period') + '：' + headData.dateFrom + '~' + headData.dateTo}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('Small_pay_period') + '：' + getDescTitle()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('icon1_autopay_button8_print_label8') + ':' + searchParams.get('autopayDate')}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }
          </div>

          {/* table */}
          <TableContainer sx={{ marginTop: '20px' }} maxWidth={false}>
            <Table sx={{ p: '2px' }} size="small">
              <TableHead>
                <TableRow hover>
                  {/* (siteName, carid, engName, chName, joy, approveDate, inDate, inVateDate, applyDate */}
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(1)}>
                      {}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(2)}>
                      {t('search_english_not_dot')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(3)}>
                      {t('search_chinese_not_dot')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(4)}>
                      {t('ID_number')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(5)}>
                      {t('Number_Of_Workers_Working_Days')}
                    </Link>
                  </TableCell>

                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(5)}>
                      {t('Actual_number_of_working_days')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(6)}>
                      {t('Worker_income')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(7)}>
                      {t('Actual_Payroll')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(8)}>
                      {t('Employer_MPF_contributions')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(8)}>
                      {t('Employee_MPF_contributions')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(8)}>
                      {t('Employee_Account_Number')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(8)}>
                      {t('plan_Member_Number')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(8)}>
                      {t('search_remark_not_dot')}
                    </Link>
                  </TableCell>
                </TableRow>
              </TableHead>

              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>

              {vendorDataList.map((mainDataRow, index) => (
                <>
                  {/* 單個人數據，需要循環遍歷 */}
                  <TableRow hover key={index + Math.random}>
                    {/* 序號 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{index + 1} </TableCell>
                    {/* 英文姓名 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainDataRow.englishName} </TableCell>
                    {/* 中文姓名 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainDataRow.chineseName}</TableCell>
                    {/* 	身份証號碼 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainDataRow.hkid} </TableCell>
                    {/* 工人上班日數 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainDataRow.workDay}</TableCell>
                    {/* 實際上班日數 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainDataRow.workDayAct} </TableCell>
                    {/* 工人入息 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainDataRow.relevantIncomeForMat} </TableCell>
                    {/* 發薪實額 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainDataRow.netIncomeForMat} </TableCell>
                    {/* 僱主強積金供款 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainDataRow.employerMpfForMat} </TableCell>
                    {/* 僱員強積金供款 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainDataRow.employeeMpfForMat} </TableCell>
                    {/* 員工戶口號碼 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainDataRow.bankAccount} </TableCell>
                    {/* 計劃成員編號 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainDataRow.mpfScheme} </TableCell>
                    {/* 備註 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{mainDataRow.payrollRemark} </TableCell>
                  </TableRow>
                </>
              ))}

              {!!mainData && mainData.length > 0 && (
                <>
                  <TableRow>
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{} </TableCell>

                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{} </TableCell>

                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{}</TableCell>
                    {/* 	身份証號碼 */}
                    <TableCell colSpan={3} sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'right' }}>
                      {t('Total_Contractors') + ':'}{' '}
                    </TableCell>

                    {/* 工人入息总数 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainData?.final_tot_rel_format} </TableCell>
                    {/* 發薪實額总数 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainData.final_tot_net_format} </TableCell>
                    {/* 僱主強積金供款总数 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainData.final_tot_er_mpf_format} </TableCell>
                    {/* 僱員強積金供款总数 */}
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainData.final_tot_ee_mpf_format} </TableCell>

                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{} </TableCell>

                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{} </TableCell>

                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{} </TableCell>
                  </TableRow>
                </>
              )}
            </Table>
          </TableContainer>

          {/* table */}
          <TableContainer sx={{ marginTop: '60px' }}>
            <Table sx={{ p: '2px' }} size="small">
              <TableHead>
                <TableRow hover>
                  {/* (siteName, carid, engName, chName, joy, approveDate, inDate, inVateDate, applyDate */}
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(1)}>
                      {}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(2)}>
                      {t('icon1_autopay_distributeSubBatch_print_label4')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(3)}>
                      {t('Worker_income')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(4)}>
                      {t('Actual_Payroll')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(5)}>
                      {t('Employer_MPF_contributions')}
                    </Link>
                  </TableCell>

                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(5)}>
                      {t('Employee_MPF_contributions')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(6)}>
                      {t('Total_contributions_from_both_parties')}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Link underline="none" sx={{ whiteSpace: 'nowrap' }} onClick={() => clickCell(7)}>
                      {t('Deductions_from_Contractor_Account')}
                    </Link>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableRow>
                <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{t('Total_Contractors') + ':'} </TableCell>

                <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{vendorDataList.length} </TableCell>
                {/* 工人入息	 */}
                <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainData?.final_tot_rel_format}</TableCell>
                {/* 	發薪實額	 */}
                <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'right' }}>{mainData.final_tot_net_format} </TableCell>

                {/* 僱主強積金供款 */}
                <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainData.final_tot_er_mpf_format} </TableCell>
                {/* 僱員強積金供款 */}
                <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>{mainData.final_tot_ee_mpf_format} </TableCell>
                {/* 雙方合共供款	前端实现千分位 */}
                <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>
                  {(mainData.final_tot_er_mpf + mainData.final_tot_ee_mpf).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}{' '}
                </TableCell>
                {/* 承判商賬戶中扣除款項 */}
                <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px', textAlign: 'left' }}>
                  {(mainData.final_tot_er_mpf + mainData.final_tot_ee_mpf + mainData.final_tot_net).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}{' '}
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </Container>
      </div>

      <div>
        <Container sx={{ marginTop: '60px', textAlign: 'center' }}>*** 資料完結 ***</Container>
      </div>
    </Container>
  )
}
