import { React, useState, useEffect } from 'react'
// import LockOutlinedIcon from '@material-ui/icons'
// import { makeStyles } from '@mui/styles'
import { Button, DialogContent, Typography, Container, TextField, FormLabel, Grid, CssBaseline, Avatar, getDialogActionsUtilityClass, Backdrop, CircularProgress, Divider, Link } from '@mui/material'
import { useSearchParams, useNavigate, createSearchParams } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { CDialog, CDelay } from 'src/components/common'
import CustomDialog from 'src/components/common/CustomDialog'
import Input from 'src/theme/overrides/Input'
import * as commonApi from '../../../utils/api/common/index'

const CryptoJS = require('crypto-js')

export default function ChangeEmail() {
  const [backOpen, setBackOpen] = useState(false)
  const formData = new FormData()
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState('')
  const [changeSuccess, setChangeSuccess] = useState(false)
  const [oldEmailStatus, setOldEmailStatus] = useState(true)
  const [searchParams] = useSearchParams()
  const [newEmailStatus, setNewEmailStatus] = useState(false)
  const [user, setUser] = useState({})
  const [dialogOpen, setDialogOpen] = useState(false)
  const [repeat, setRepeat] = useState('')
  const [emailModel, setEmailModel] = useState(null)
  const [inputData, setInputData] = useState({
    oldEmail: '',
    newEmail: '',
    confirmEmail: '',
  })
  const [loading, setLoading] = useState(false)
  const [loadingRest, setLoadingRest] = useState(false)

  const { t } = useTranslation()
  const [dialog, setDialog] = useState({
    content: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        {t('sure')}
      </Button>
    ),
    open: false,
  })

  useEffect(() => {
    // 判斷 判頭賬號是否有 郵箱賬號
    const getData = async () => {
      const getUser = await commonApi.getUser()
      if (getUser) {
        setUser(getUser)
        setInputData({ ...inputData, oldEmail: getUser.email })
        setEmailModel(getUser.email)
      }
      // setInputData({ ...inputData, oldEmail: searchParams.get('email') })
      // setEmailModel(emailModel, searchParams.get('email'))
      // 解密

      const Decrypt = (str, key = 'szewecszewecszew') => {
        const keys = CryptoJS.enc.Utf8.parse(key)
        const decrypt = CryptoJS.AES.decrypt(str, keys, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        })
        // console.log('解密', CryptoJS.enc.Utf8.stringify(decrypt).toString())
        return CryptoJS.enc.Utf8.stringify(decrypt).toString()
      }
      const deStep = Decrypt(searchParams.get('step'))

      setStep(deStep)
    }
    getData()
    setBackOpen(false)
  }, [])

  const handleEmailCheck = (value) => {
    if (!/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(value)) {
      console.log('请输入正确的Email')
      return false
    } else {
      return true
    }
  }
  const handleClick = () => {
    setLoading(true)
  }
  const handOldEmail = (event) => {
    setInputData({ ...inputData, oldEmail: event.target.value })
    if (handleEmailCheck(event.target.value)) {
      setOldEmailStatus(true)
    } else {
      setOldEmailStatus(false)
    }
  }
  const handNewEmail = (event) => {
    setInputData({ ...inputData, newEmail: event.target.value })
    if (handleEmailCheck(event.target.value)) {
      setNewEmailStatus(true)
    } else {
      setNewEmailStatus(false)
    }
  }

  const handleResetEmail = () => {
    setInputData({
      oldEmail: '',
      newEmail: '',
      confirmEmail: '',
    })
  }

  //

  const handleSave = () => {
    // 簡單非空 驗證
    // 驗證 與 舊 郵箱是否相等
    if (step === '1') {
      //  有郵箱賬號
      if (!inputData.oldEmail) {
        setDialog({
          ...dialog,
          content: t('didn_enter_an_email_address'),
          open: true,
        })
        return
      } else {
        if (!oldEmailStatus) {
          setDialog({
            ...dialog,
            content: t('the_mailbox_is_not_in_the_correct_format'),
            open: true,
          })
          return
        }
        if (inputData.oldEmail !== user.email) {
          setDialog({
            ...dialog,
            content: t('not_the_same_as_the_email_address_bound_to_the_account'),
            open: true,
          })
          return
        }
      }

      setBackOpen(true)

      commonApi.sendEmail(inputData.oldEmail).then((respone) => {
        if (respone.data.code === 200) {
          setDialog(
            {
              ...dialog,
              // content: '確認鏈接 已發送至郵箱 請確認！！！',
              content: t('verification_link_has_been_sent_to_your_mailboxn'),
              open: true,
            },
            setBackOpen(false),
            setLoading(true),
          )
        } else {
          setDialog({
            ...dialog,
            content: t('failed_to_send_the_message'),
            open: true,
          })
        }
      })
    }
    if (!emailModel) {
      // 没有账号
      if (!inputData.newEmail) {
        setDialog({
          ...dialog,
          content: t('didn_enter_an_email_address'),
          open: true,
        })
        return
      }
      if (!newEmailStatus) {
        setDialog({
          ...dialog,
          content: t('the_mailbox_is_not_in_the_correct_format'),
          open: true,
        })
        return
      }
    }
    //  調用api
    if (step === '2') {
      if (!inputData.newEmail) {
        setDialog({
          ...dialog,
          content: t('didn_enter_an_email_address'),
          open: true,
        })
        return
      } else {
        if (!newEmailStatus) {
          setDialog({
            ...dialog,
            content: t('the_mailbox_is_not_in_the_correct_format'),
            open: true,
          })
          return
        }
      }

      setBackOpen(true)
      let formData = new FormData()
      formData.append('username', user.username)
      formData.append('email', inputData.newEmail)
      commonApi.changeEmail(formData).then((respone) => {
        if (respone.status === 200) {
          setDialog({
            ...dialog,
            content: t('successfully_modified'),
            open: true,
            action: dialogAction(
              false,
              () => {
                window.location.href = `${process.env.REACT_APP_REDIRECTURL}/indexPage`
              },
              null,
            ),
          })
        } else {
          setDialog({
            ...dialog,
            content: t('fail_to_edit'),
            open: true,
            action: dialogAction(
              false,
              () => {
                window.location.href = `${process.env.REACT_APP_REDIRECTURL}/indexPage`
              },
              null,
            ),
          })
        }
      })
    }
  }

  const dialogAction = (showCancelButton, handleConfirmDialog, handleCancelDialog) => {
    if (showCancelButton) {
      return (
        <>
          <Button variant="contained" onClick={handleConfirmDialog}>
            {t('sure')}
          </Button>
          <Button variant="contained" onClick={handleCancelDialog}>
            {t('button_cancel')}
          </Button>
        </>
      )
    } else {
      return (
        <Button variant="contained" onClick={handleConfirmDialog}>
          {t('sure')}
        </Button>
      )
    }
  }

  return (
    <CDelay>
      <>
        <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <CustomDialog sx={{ whiteSpace: 'pre-line' }} open={dialog.open} title={dialog.title} content={dialog.content} action={dialog.action} />
        <Container component="main" maxWidth="xs">
          <CssBaseline />

          <Grid item xs={12}>
            <Typography variant="h4" style={{ textAlign: 'center', lineHeight: '2' }}>
              {step === '1' ? t('verification_email') : t('change_email')}
            </Typography>
          </Grid>

          <Grid container spacing={3}>
            {step === '1' && (
              <Grid item xs={12}>
                <TextField error={!oldEmailStatus} label={t('ole_email')} variant="outlined" required fullWidth name="oldemail" value={inputData.oldEmail} onChange={handOldEmail} />
                <FormLabel component="legend">{t('please_enter_the_email_address_bound_to_your_account')}</FormLabel>
              </Grid>
            )}
            {step === '2' && (
              <Grid item xs={12}>
                <TextField error={!newEmailStatus} variant="outlined" label={t('new_email')} required fullWidth name="newemail" value={inputData.newEmail} onChange={handNewEmail} />
              </Grid>
            )}
          </Grid>

          <div style={{ textAlign: 'center' }}>
            {/* <Button onClick={handleSave} sx={{ mt: '20px', ml: '10px', width: '170px' }} variant="contained">
            {t('button_save')}
          </Button> */}
            <LoadingButton onClick={handleSave} loading={loading} loadingIndicator={t('button_submit')} sx={{ mt: '20px', ml: '10px', width: '170px' }} variant="contained">
              {t('button_submit')}
            </LoadingButton>

            {/* <Button onClick={handleResetEmail} sx={{ mt: '20px', ml: '10px', width: '170px' }} variant="contained">
              {t('button_reset')}
            </Button> */}
          </div>
        </Container>
        <Divider sx={{ mt: '20px', ml: '10px' }} />

        {/* <CustomizedSnackbars alert={alert} handleAlertClose={handleAlertClose} /> */}
      </>
    </CDelay>
  )
}
