import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Box,
  Container,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Checkbox,
  Select,
  MenuItem,
  TableSortLabel,
} from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import CustomBackdrop from "src/components/common/CustomBackdrop";
import CustomDialog from "src/components/common/CustomDialog";
import CustomSnackbar from "src/components/common/CustomSnackbar";
import { confirm } from "src/components/common"
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import {
  setSiteUserMapping,
  updateSiteUserMapping,
  deleteSiteUserMapping,
} from "../../../../utils/api/workerAttendanceRecord/SetSiteUserMapping";

const EDIT_ACTION = "EDIT";
const CANCEL_ACTION = "CANCEL";
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
export default function SetSiteUserMapping(props) {
  const dialogAction = (
    showCancelButton,
    handleConfirmDialog,
    handleCancelDialog
  ) => {
    if (showCancelButton) {
      return (
        <>
          <Button variant="contained" onClick={handleConfirmDialog}>
            確認
          </Button>
          <Button variant="contained" onClick={handleCancelDialog}>
            取消
          </Button>
        </>
      );
    } else {
      return (
        <Button variant="contained" onClick={handleConfirmDialog}>
          確認
        </Button>
      );
    }
  };
  const { setType } = props;
  const [detail, setDetail] = useState({
    records: [],
    total: 0,
    size: 0,
    current: 1,
    pages: 0,
  });
  const [sort, setSort] = useState({
    orderByKey: "site",
    ascOrder: true,
  });

  const [inProgress, setInProgress] = useState(true);
  const [dialog, setDialog] = useState({
    title: "",
    content: "",
    action: dialogAction(
      false,
      () => {
        setDialog({
          ...dialog,
          open: false,
        });
      },
      null
    ),
    open: false,
  });
  const [snackbar, setSnackbar] = useState({
    message: "",
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setSnackbar({ ...snackbar, open: false });
        }}
      >
        確認
      </Button>
    ),
    open: false,
  });
  const componentRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    // pageNumber: 1, pageSize: 5
    let searchCondition = {};
    searchCondition.pageNumber = 1;
    searchCondition.pageSize = 5;
    searchCondition.orderByKey = (sort.ascOrder ? "asc" : "desc") + sort.orderByKey;
    // searchCondition.orderBy = sort.orderBy;
    // searchCondition.ascOrder = sort.ascOrder;
    getPaginationSetSiteUserMapping(searchCondition);
  }, []);

  const getPaginationSetSiteUserMapping = async (searchCondition) => {
    setInProgress(true);
    let res = await setSiteUserMapping(searchCondition);
    if (res && res.code === 200) {
      if (res.data && res.data.records) {
        res.data.records.forEach((val) => {
          val.deleteChecked = false;
          val.operation = CANCEL_ACTION;
          val.newUserId = val.userId;
        });
        setDetail({
          ...detail,
          records: res.data.records,
          total: res.data.total,
          size: res.data.size,
          current: res.data.current,
          orders: res.data.orders,
          optimizeCountSql: res.data.optimizeCountSql,
          hitCount: res.data.hitCount,
          countId: res.data.countId,
          maxLimit: res.data.maxLimit,
          searchCount: res.data.searchCount,
          pages: res.data.pages,
        });
      }
    } else {
      setDialog({
        ...dialog,
        content: "請求錯誤",
        open: true,
      });
    }
    setInProgress(false);
  };

  const handleChangePage = (event, newPage) => {
    let searchCondition = {};
    searchCondition.pageNumber = newPage + 1;
    searchCondition.pageSize = detail.pageSize;
    searchCondition.orderByKey = (sort.ascOrder ? "asc" : "desc") + sort.orderByKey;
    // searchCondition.orderBy = sort.orderBy;
    // searchCondition.ascOrder = sort.ascOrder;
    getPaginationSetSiteUserMapping(searchCondition);
  };

  const handleChangeRowsPerPage = (event) => {
    let searchCondition = {};
    searchCondition.pageNumber = detail.current;
    searchCondition.pageSize = event.target.value;
    searchCondition.orderByKey = (sort.ascOrder ? "asc" : "desc") + sort.orderByKey;
    // searchCondition.orderBy = sort.orderBy;
    // searchCondition.ascOrder = sort.ascOrder;
    getPaginationSetSiteUserMapping(searchCondition);
  };

  const handleUserChange = (siteId) => {
    return (event) => {
      let newRecords = detail.records;
      if (newRecords) {
        newRecords.forEach((val) => {
          if (val.siteId === siteId) {
            val.newUserId = event.target.value;
          }
        });
      }
      setDetail({ ...detail, records: newRecords });
    };
  };

  const handleEdit = (siteId) => {
    return () => {
      let newRecords = detail.records;
      if (newRecords) {
        newRecords.forEach((val) => {
          if (val.siteId === siteId) {
            val.operation = EDIT_ACTION;
          }
        });
      }
      setDetail({ ...detail, records: newRecords });
    };
  };

  const handleCancelEdit = (siteId) => {
    return () => {
      let newRecords = detail.records;
      if (newRecords) {
        newRecords.forEach((val) => {
          if (val.siteId === siteId) {
            val.operation = CANCEL_ACTION;
          }
        });
      }
      setDetail({ ...detail, records: newRecords });
    };
  };

  const handleDeleteOnChange = (siteId) => {
    return (event) => {
      let newRecords = detail.records;
      if (newRecords) {
        newRecords.forEach((val) => {
          if (val.siteId === siteId) {
            val.deleteChecked = event.target.checked;
          }
        });
      }
      setDetail({ ...detail, records: newRecords });
    };
  };

  const saveSetSiteUserMapping = async () => {
    setInProgress(true);
    let newSetSiteUserMappings = [];
    detail.records.forEach((val) => {
      if (val.operation === EDIT_ACTION) {
        let setSiteUserMapping = {};
        setSiteUserMapping.userId = val.newUserId;
        setSiteUserMapping.siteId = val.siteId;
        newSetSiteUserMappings.push(setSiteUserMapping);
      }
    });
    let res = await updateSiteUserMapping(newSetSiteUserMappings);
    
    setInProgress(false);
    if (res && res.code === 200) {
      setDialog({
        ...dialog,
        open: false,
      });
      confirm({
        title: t('tip'),
        content: "保存成功",
      })
      // setSnackbar({
      //   ...snackbar,
      //   message: "保存成功",
      //   open: true,
      // });
    } else {
      setDialog({
        ...dialog,
        content: "請求錯誤",
        open: true,
        action: dialogAction(
          false,
          () => {
            setDialog({
              ...dialog,
              open: false,
            });
          },
          null
        ),
      });
    }
    // setInProgress(false);
    handleReset();
  };

  const handleSave = () => {
    setDialog({
      ...dialog,
      content: "是否確定保存？",
      open: true,
      action: dialogAction(
        true,
        () => {
          saveSetSiteUserMapping();
        },
        () => {
          setDialog({
            ...dialog,
            open: false,
          });
        }
      ),
    });
  };

  const handleReset = () => {
    let searchCondition = {};
    searchCondition.pageNumber = 1;
    searchCondition.pageSize = 5;
    searchCondition.orderByKey = "ascsite";
    setSort({
      orderByKey: "site",
      ascOrder: true,
    });
    getPaginationSetSiteUserMapping(searchCondition);
  };

  const deleteSetSiteUserMapping = async () => {
    setInProgress(true);
    let deleteSetSiteUserMappings = [];
    if (detail.records && detail.records.length > 0) {
      detail.records.forEach((val) => {
        if (val.deleteChecked) {
          let setSiteUserMapping = {};
          setSiteUserMapping.userId = val.userId;
          setSiteUserMapping.siteId = val.siteId;
          deleteSetSiteUserMappings.push(setSiteUserMapping);
        }
      });
    }

    let res = await deleteSiteUserMapping(deleteSetSiteUserMappings);
    setInProgress(false);
    if (res && res.code === 200) {
      setDialog({
        ...dialog,
        open: false,
      });
      
      confirm({
        title: t('tip'),
        content: "刪除成功",
      })
      // setSnackbar({
      //   ...snackbar,
      //   message: "刪除成功",
      //   open: true,
      // });
    } else {
      setDialog({
        ...dialog,
        content: "刪除失敗",
        open: true,
        action: dialogAction(
          false,
          () => {
            setDialog({
              ...dialog,
              open: false,
            });
          },
          null
        ),
      });
    }
    // setInProgress(false);
    handleReset();
  };

  const handleDelete = () => {
    let delCount = 0;
    let message = "";
    if (detail.records && detail.records.length > 0) {
      detail.records.forEach((val) => {
        if (val.deleteChecked) {
          delCount++;
          if (val.count > 0) {
            message += `地盤${val.siteDesc}人數大於0，刪除無效！\n`;
          }
        }
      });
    }

    if (delCount === 0 || message) {
      setDialog({
        ...dialog,
        open: true,
        content: delCount === 0 ? "請選擇要刪除的數據!" : message,
        action: dialogAction(
          false,
          () => {
            setDialog({
              ...dialog,
              open: false,
            });
          },
          null
        ),
      });
      return;
    }

    setDialog({
      ...dialog,
      content: "是否確定刪除？",
      open: true,
      action: dialogAction(
        true,
        () => {
          deleteSetSiteUserMapping();
        },
        () => {
          setDialog({
            ...dialog,
            open: false,
          });
        }
      ),
    });
  };

  const handleCancel = () => {
    setType(0);
  };

  return (
    <Container
      maxWidth={false}
      ref={componentRef}
      sx={{ textAlign: "center" }}
    >
      <Typography variant="h4" >
        <b>{t("icon1_autopay_setSiteUserMapping")}</b>
      </Typography>
      <Table
        sx={{
          pageBreakInside: "inherit",
          //  "& .MuiTableCell-root": { border: 1 },
          mt: "30px",
        }}
        size="small"
      >
        <TableHead>
          <TableRow hover sx={{ border: "1px" }}>
            <TableCell
              sortDirection={
                sort.orderByKey && sort.orderByKey === "site"
                  ? sort.ascOrder
                    ? "asc"
                    : "desc"
                  : false
              }
            >
              <TableSortLabel
                active={sort.orderByKey && sort.orderByKey === "site"}
                direction={
                  sort.orderByKey && sort.orderByKey === "site"
                    ? sort.ascOrder
                      ? "asc"
                      : "desc"
                    : "asc"
                }
                onClick={() => {
                  let searchCondition = {};
                  searchCondition.pageNumber = detail.current;
                  searchCondition.pageSize = detail.size;
                  if (sort.orderByKey && sort.orderByKey === "site") {
                    searchCondition.ascOrder = !sort.ascOrder;
                  } else {
                    searchCondition.ascOrder = true;
                  }
                  setSort({
                    ...sort,
                    orderByKey: "site",
                    ascOrder: searchCondition.ascOrder,
                  });
                  searchCondition.orderByKey =
                    (searchCondition.ascOrder ? "asc" : "desc") + "site";
                  //   (searchCondition.ascOrder ? "asc" : "desc") + "site";
                  getPaginationSetSiteUserMapping(searchCondition);
                }}
              >
                {t("search_site_title")+'：'}
              </TableSortLabel>
            </TableCell>
            <TableCell
              sortDirection={
                sort.orderByKey && sort.orderByKey === "count"
                  ? sort.ascOrder
                    ? "asc"
                    : "desc"
                  : false
              }
            >
              <TableSortLabel
                active={sort.orderByKey && sort.orderByKey === "count"}
                direction={
                  sort.orderByKey && sort.orderByKeyKey === "count"
                    ? sort.ascOrder
                      ? "asc"
                      : "desc"
                    : "asc"
                }
                onClick={() => {
                  let searchCondition = {};
                  searchCondition.pageNumber = detail.current;
                  searchCondition.pageSize = detail.size;
                  if (sort.orderByKey && sort.orderByKey === "count") {
                    searchCondition.ascOrder = !sort.ascOrder;
                  } else {
                    searchCondition.ascOrder = true;
                  }
                  setSort({
                    ...sort,
                    orderByKey: "count",
                    ascOrder: searchCondition.ascOrder,
                  });
                  searchCondition.orderByKey =
                    (searchCondition.ascOrder ? "asc" : "desc") + "count";

                  getPaginationSetSiteUserMapping(searchCondition);
                }}
              >
                {t("icon1_autopay_setSiteUserMapping_tableLabel2")}
              </TableSortLabel>
            </TableCell>
            <TableCell
              sortDirection={
                sort.orderByKey && sort.orderByKey === "user"
                  ? sort.ascOrder
                    ? "asc"
                    : "desc"
                  : false
              }
            >
              <TableSortLabel
                active={sort.orderByKey && sort.orderByKey === "user"}
                direction={
                  sort.orderByKey && sort.orderByKey === "user"
                    ? sort.ascOrder
                      ? "asc"
                      : "desc"
                    : "asc"
                }
                onClick={() => {
                  let searchCondition = {};
                  searchCondition.pageNumber = detail.current;
                  searchCondition.pageSize = detail.size;
                  if (sort.orderByKey && sort.orderByKey === "user") {
                    searchCondition.ascOrder = !sort.ascOrder;
                  } else {
                    searchCondition.ascOrder = true;
                  }
                  setSort({
                    ...sort,
                    orderByKey: "user",
                    ascOrder: searchCondition.ascOrder,
                  });
                  searchCondition.orderByKey =
                    (searchCondition.ascOrder ? "asc" : "desc") + "user";
                  getPaginationSetSiteUserMapping(searchCondition);
                }}
              >
                {t("icon1_autopay_setSiteUserMapping_tableLabel3")}
              </TableSortLabel>
            </TableCell>
            <TableCell>{t("button_edit")}</TableCell>
            <TableCell>{t("button_delete")}</TableCell>
          </TableRow>
        </TableHead> 
        <TableBody>
          {detail.records.length > 0 ? (
            detail.records.map((row, index) => (
              <TableRow hover key={index}>
                <TableCell>{row.siteDesc}</TableCell>
                <TableCell>{row.count}</TableCell>
                <TableCell>
                  {row.operation === undefined ||
                    row.operation === CANCEL_ACTION ? (
                    row.userId
                  ) : (
                    <Select
                      id="userlist"
                      value={row.newUserId}
                      onChange={handleUserChange(row.siteId)}
                      fullWidth
                      size="small"
                      sx={{ maxHeight: "30px", backgroundColor: "#FFFFFF" }}
                    >
                      {row.userlist && row.userlist.length > 0 ? (
                        row.userlist.map((val) => (
                          <MenuItem key={val.id} value={val.id}>
                            {val.text}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="" />
                      )}
                    </Select>
                  )}
                </TableCell>
                <TableCell sx={{ maxWidth: "10px" }}>
                  {row.operation === undefined ||
                    row.operation === CANCEL_ACTION ? (
                    <Button onClick={handleEdit(row.siteId)} size="small">
                      {t("button_edit")}
                    </Button>
                  ) : (
                    <Button onClick={handleCancelEdit(row.siteId)} size="small">
                      {t("button_cancel")}
                    </Button>
                  )}
                </TableCell>
                <TableCell sx={{ maxWidth: "10px" }}>
                  {row.operation === undefined ||
                    row.operation === CANCEL_ACTION ? (
                    <Checkbox
                      checked={
                        row.deleteChecked === undefined
                          ? false
                          : row.deleteChecked
                      }
                      onChange={handleDeleteOnChange(row.siteId)}
                    />
                  ) : (
                    <></>
                  )}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} sx={{ textAlign: 'center' }}>No Records Found!</TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: 5 }]}
              //    colSpan={3}
              count={detail.total}
              rowsPerPage={detail.size}
              page={detail.current - 1}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <Button
        onClick={handleSave}
        sx={{ mt: "20px", ml: "10px", width: "100px" }}
        variant="contained"
      >
        {t("button_save")}
      </Button>
      <Button
        onClick={handleReset}
        sx={{ mt: "20px", ml: "10px", width: "100px" }}
        variant="contained"
      >
        {t("button_reset")}
      </Button>
      <Button
        onClick={handleDelete}
        sx={{ mt: "20px", ml: "10px", width: "100px" }}
        variant="contained"
      >
        {t("button_delete")}
      </Button>

      <Button
        onClick={handleCancel}
        sx={{ mt: "20px", ml: "10px", width: "100px" }}
        variant="contained"
      >
        {t("button_cancel")}
      </Button>
      <table style={{ marginTop: "50px" }}>
        <tbody>
          <tr align="left">
            <td>
              <font color="#074C8A" size="4">
                <b id="labelrmk">
                  *上一個已完成的糧期（至）到今日，進出地盤的人數。
                </b>
              </font>
            </td>
          </tr>
          <tr align="left">
            <td>
              <font color="#074C8A" size="4">
                <b>
                  *總人數大於0的地盤，必須設置輸入帳戶，否則，輸入版面將不顯示相應的地盤。
                </b>
              </font>
            </td>
          </tr>
          <tr align="left">
            <td>
              <font color="#074C8A" size="4">
                <b>
                  *如未設置任何輸入帳戶，則承判商下每個帳戶均可對所有地盤進行輸入。
                </b>
              </font>
            </td>
          </tr>
        </tbody>
      </table>
      <CustomBackdrop open={inProgress} />
      <CustomDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        action={dialog.action}
      />
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        action={snackbar.action}
      />
    </Container>
  );
}
