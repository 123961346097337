import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as apiAtt from "src/utils/api/workerAttendanceRecord/AutoPayApiAttend";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomDialog from "src/components/common/CustomDialog";
import { forEach } from "lodash";

export default function ApplyDayEditOrView() {
  const componentRef = useRef();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [dataList, setDataList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [totalMandayResult,setTotalMandayResult] = React.useState("0");
  const [applicationSetup, SetApplicationSetup] = React.useState({});

  const handleBack = () => {
    navigate(-1);
  }


  const [headerData, setHeaderData] = React.useState({});

  useEffect(() => {
    const getPFSApplicationSetup = async () => {
      let data = await apiAtt.getPFSApplicationSetup()
      if(data !== null){
        SetApplicationSetup(data);
      }
    }

    const getPFSApplicationViewTitle = async () => {
      let data = await apiAtt.getPFSApplicationViewTitle(
        searchParams.get("applicationId"),
        searchParams.get("applicationSeqNo")
      );
      if (data.records !== null) {
        data.records.map((value) => {
          setHeaderData(value);
          return [];
        });
      }
    };

    const getPFSApplicationViewList = async () => {
      let data = await apiAtt.getPFSApplicationDayView(
        searchParams.get("applicationId"),
        searchParams.get("applicationSeqNo")
      );
        setDataList(data);
    };

    getPFSApplicationSetup();
    getPFSApplicationViewTitle();
    getPFSApplicationViewList();
    setLoading(false);
  }, []);

  const handleTotal = (data) => {
    let result = 0;
    if (data !== null) {
      data.map((value) => {
        result += parseFloat(value.approvedManday);
        return [];
      });
    }

    return result.toFixed(2);
  };

  const dispFootnote = (footnote) => {
    return footnote.split(/[\r\n]+/).map((val) => <div >{val}</div>);
  }


  return (
    <>
      <div style={{ display: "block", textAlign: "left" }}>
        <Button sx={{ float: "left" }} onClick={handleBack}>
          <ArrowBackIcon />
          {t("button_back")}
        </Button>
      </div>
      <div id="div" ref={componentRef} style={{}}>
        <Container maxWidth="xl">
          <div>
            <div style={{ display: "block", marginBottom: "60px" }}>
              <Typography variant="h4" sx={{ textAlign: "center" }}>
                <b>{t("search_vendor_daily_records")}</b>
              </Typography>
            </div>

            {headerData.siteDesc && (
              <div>
                <TableContainer
                  component={Paper}
                  sx={{ marginBottom: "20px", backgroundColor: "transparent" }}
                >
                  <Table sx={{ textAlign: "left" }} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_site_title") + "："}{" "}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.siteId} - {headerData.siteDesc}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("Vendor") + "："}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.vendorDesc}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_subcontractor_dEmployer")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.subContractorDesc}
                        </TableCell>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_work_type_dot")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.jobNatureDesc}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_smartCardId_dot")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.smartCardId}
                        </TableCell>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_date_dot")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.yearMonth}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_chinese_name_dot")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.chineseName}
                        </TableCell>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_english_name_dot")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.englishName}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_employment_position")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.safetyPositionDesc}
                        </TableCell>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_approve_date_dot")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.spApproveDate}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_in_efficient_date")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.effectiveDate}
                        </TableCell>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_in_valite_date_to")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {headerData.effectiveDateTo}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <div>
              <TableContainer sx={{ marginTop: "60px" }}>
                <Table sx={{ minWidth: 650, p: "2px" }} size="small">
                  <TableHead>
                    <TableRow sx={{ border: "1px" }}>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {t("search_inOut_date")}
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: "nowrap"}}
                      >
                        {t("search_site_times")}
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                      >
                        {t("search_vendor_site_totalWorker")}
                      </TableCell>                  
                        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "right" }}>
                          {t("search_approve_day")}
                        </TableCell>                     
                    </TableRow>
                  </TableHead>

                    <TableBody>
                      {dataList.map((row, index) => (
                        <TableRow key={index + Math.random}>
                          <TableCell>
                            {row.accessDateText}
                          </TableCell>
                          <TableCell>{row.totalManday}</TableCell>
                          <TableCell sx={{ textAlign: "right" }}>{row.vendorDailyAttendance}</TableCell>
                          <TableCell sx={{ textAlign: "right" }}>
                            {row.approvedManday}
                          </TableCell>
                        </TableRow>
                      ))}

                    </TableBody>

                    <TableBody>
                    <TableRow>
                      <TableCell> </TableCell>
                      <TableCell> </TableCell>
                      <TableCell sx={{ fontSize: "18px", textAlign: "right" }}>
                        <b>{t("search_total_dot")}</b>
                      </TableCell>
                      <TableCell sx={{ fontSize: "18px", textAlign: "right" }}>
                        <b>{handleTotal(dataList)}</b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            {headerData.siteDesc && (
              <div>
                <Typography sx={{ textAlign: "left", marginTop: "60px" }}>
                  {dispFootnote(applicationSetup.footnote)}
                </Typography>
              </div>
            )}

            {/* <Dialog fullScreen onClose={handleCloseSubmitView} open={showSubmitView}>
              <RptSafetyBtn2MainDayListDetailEditOrViewSubmit
                setShowSubmitView={setShowSubmitView} 
                propsData={propsData}
              >3</RptSafetyBtn2MainDayListDetailEditOrViewSubmit>
            </Dialog> */}
          </div>
        </Container>
      </div>
    </>
  );
}
