import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { Box, IconButton, TablePagination } from '@mui/material'
import { SxProps, useTheme } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";

function CTablePaginationActions(props) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }
  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }
  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

export default function CPagination(props) {
  const { sx, total = 0, onChange = () => {}, onShowSizeChange = () => {} } = props
  
  // 國際化
  const { t } = useTranslation();

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage || 10)

  const handleChangePage = (_event, newPage) => {
    setPage(newPage)
    onChange(newPage + 1, rowsPerPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    onChange(1, parseInt(event.target.value, 10))
  }

  useEffect(() => {
    setRowsPerPage(props.rowsPerPage || 10)
  }, [props.rowsPerPage])

  useEffect(() => {
    onShowSizeChange(page + 1, rowsPerPage)
  }, [rowsPerPage])

  return (
    <TablePagination
      sx={sx}
      component="div"
      ActionsComponent={CTablePaginationActions}
      count={total}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      page={page}
      rowsPerPage={rowsPerPage}
      // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
      rowsPerPageOptions={[5, 10, 25, 50]}
      SelectProps={{ inputProps: { 'aria-label': 'rows per page' }, native: true }}
      labelRowsPerPage={t('rowsPerPage')}
      labelDisplayedRows={({ from, to, count }) => {
        return `${from}–${to} ${t('of')} ${count !== -1 ? count : `${t('moreThan')} ${to}`}`
      }}
    />
  )
}
