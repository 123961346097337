

import { React, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
    Divider,
    Container,
    Grid,
    FormControl,
    Button,
    FormLabel,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Table,
    Backdrop,
    Radio,
    CircularProgress,
    FormControlLabel,
    RadioGroup,
    Typography,
    TextField,
    tableCellClasses,
    MenuItem,
    Tab,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import moment from 'moment'
import CustomDialog from "src/components/common/CustomDialog";
import * as api from "../../../utils/api/vendorAutopaySystem/AverageDailyReportApi";



const ReportTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: '15px', wordBreak: "keep-all", whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        textAlign: 'right',
        padding: "3px",
        fontSize: "13px"
    },
}));

// 承判商自動轉脹狀況 

export default function ReportContractorAutopayStatus() {
    const [isSearchPage, setIsSearchPage] = useState(true);
    const { t } = useTranslation();
    const [backOpen, setBackOpen] = useState(false);
    const [siteList, setSiteList] = useState([]);
    const [subList, setSubList] = useState([]);
    const [jobTypelist, setJobTypelist] = useState([]);
    const [sendData, setSendData] = useState({})
    const [tableData, setTableData] = useState([])
    const [dateList, setDateList] = useState([]);
    const [dialog, setDialog] = useState({
        content: "",
        action: (
            <Button
                variant="contained"
                onClick={() => {
                    setDialog({ ...dialog, open: false });
                }}
            >
                確認
            </Button>
        ),
        open: false,
    });
    useEffect(() => {
        setBackOpen(true)
        const getData = async () => {
            let getSite = await api.getSiteAndTeam();
            let getSubContractor = await api.getSubContractorList();
            let getJobType = await api.getfilljobNatureId()
            let getDateList = await api.getDate()
            if (getDateList) {
                setDateList(getDateList)
            }
            if (getSite) {
                setSiteList(getSite)
            }
            if (getSubContractor) {
                setSubList(getSubContractor)
            }
            if (getJobType) {
                setJobTypelist(getJobType)
            }

            setBackOpen(false)
        }
        getData()
    }, [])


    const handleBack = () => {
        setIsSearchPage(true);
    };

    const handleSubcontractors = (data) => {
        setSendData({ ...sendData, vendorId: data.id, vendorDesc: data.text })
    }

    const handleSiteId = (data) => {
        setSendData({ ...sendData, siteId: data.id, siteDesc: data.text })
    }

    const handleJobType = (data) => {
        setSendData({ ...sendData, jobNatureId: data.id, jobTypeDesc: data.text })
    }

    const handleDateOneFrom = (item) => {
        setSendData({ ...sendData, dateOneFrom: item.id, dateOneTo: "" })
    }
    const handleDateOneTo = (item) => {
        if (new Date(item.id) < new Date(sendData.dateOneFrom)) {
            if (!sendData.dateFrom || !sendData.dateTo) {
                setDialog({
                    ...dialog,
                    content: "日期由不能大於日期至",
                    open: true
                })
                return []
            }
            return
        }
        setSendData({ ...sendData, dateOneTo: item.id })
    }
    const handleDateTwoFrom = (item) => {
        setSendData({ ...sendData, dateTwoFrom: item.id, dateTwoTo: "" })
    }
    const handleDateTwoTo = (item) => {
        if (new Date(item.id) < new Date(sendData.dateTwoFrom)) {
            if (!sendData.dateFrom || !sendData.dateTo) {
                setDialog({
                    ...dialog,
                    content: "日期由不能大於日期至",
                    open: true
                })
                return []
            }
            return
        }
        setSendData({ ...sendData, dateTwoTo: item.id })
    }

    const handleUpGroup = (e) => {
        console.log("e", e.target)
        setSendData({ ...sendData, upGroup: e.target.value, upBy: e.target.name })
    }


    const handleSubmit = (e) => {
        if (!sendData.dateOneFrom || !sendData.dateTwoFrom || !sendData.dateOneTo || !sendData.dateTwoTo) {
            setDialog({
                ...dialog,
                content: "必須選擇日期",
                open: true
            })
            return
        }
        const send = async () => {
            setBackOpen(true)
            let getData = await api.getData(sendData)
            if (getData) {
                setTableData(getData)
                setIsSearchPage(false)
            }
            setBackOpen(false)
        }
        send()
    }

    const resetForm = (e) => {
        setSendData({})
    }


    const exportExcel = () => { };


    // 承判商自動轉賬支薪報告 
    return (
        <>
            <Backdrop sx={{ color: "#fff", zIndex: 1 }} open={backOpen}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <CustomDialog
                sx={{ whiteSpace: "pre-line" }}
                open={dialog.open}
                title={dialog.title}
                content={dialog.content}
                action={dialog.action}
            />
            {isSearchPage && (
                <form>
                    <Container maxWidth="md" >
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid item xs={12}>
                                <Typography variant="h4" style={{ textAlign: 'center' }}><b>{t("Average_daily_wage_increase_statements")}</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField id="subcontractorDesc" value={sendData.vendorId || ""} fullWidth size="small" label={t("Contractor")} select >
                                    <MenuItem value="" onClick={() => handleSubcontractors({ id: "", text: '所有承判商' })}>所有承判商</MenuItem>
                                    {subList.map((item, index) => (
                                        <MenuItem key={index} value={item.id} onClick={() => handleSubcontractors(item)}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField id="siteDesc" value={sendData.siteId || ""} fullWidth size="small" label={t("Site_Name")} select >
                                    <MenuItem value="" onClick={() => handleSiteId({ id: "", text: "所有地盤" })}>
                                        所有地盤
                                    </MenuItem>
                                    {siteList.map((item, index) => (
                                        <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleSiteId(item)}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField error={!sendData.dateOneFrom} value={sendData.dateOneFrom || ""} fullWidth size="small" label={t("date") + 1 + "(" + t("from") + ")"} select >
                                    {dateList.map((item, index) => (
                                        <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleDateOneFrom(item)}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField error={!sendData.dateOneTo} value={sendData.dateOneTo || ""} fullWidth size="small" label={t("date") + 1 + "(" + t("to") + ")"} select >
                                    {dateList.map((item, index) => (
                                        <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleDateOneTo(item)}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField error={!sendData.dateTwoFrom} value={sendData.dateTwoFrom || ""} fullWidth size="small" label={t("date") + 2 + "(" + t("from") + ")"} select >
                                    {dateList.map((item, index) => (
                                        <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleDateTwoFrom(item)}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField error={!sendData.dateTwoTo} value={sendData.dateTwoTo || ""} fullWidth size="small" label={t("date") + 2 + "(" + t("to") + ")"} select >
                                    {dateList.map((item, index) => (
                                        <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleDateTwoTo(item)}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField value={sendData.jobNatureId || ""} fullWidth size="small" label={t("Job_Type")} select >
                                    <MenuItem value="" onClick={() => handleJobType({ id: "", text: "所有工種" })}>
                                        所有工種
                                    </MenuItem>
                                    {jobTypelist.map((item, index) => (
                                        <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleJobType(item)}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl size="small">
                                    <FormLabel>{t("Grouping")}</FormLabel>
                                    <RadioGroup row onChange={handleUpGroup}>
                                        <FormControlLabel
                                            label={t("Contractor")}
                                            control={<Radio defaultChecked value="vendor" name={t("Contractor")} />}
                                        />
                                        <FormControlLabel
                                            label={t("Site_Name")}
                                            control={<Radio value="site" name={t("Site_Name")} />}
                                        />
                                        <FormControlLabel
                                            label={t("Job_Type")}
                                            control={<Radio value="job" name={t("Job_Type")} />}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: "center" }}>
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit}
                                    sx={{
                                        backgroundColor: "primaryBlue",
                                        color: "commom.white",
                                    }}
                                >
                                    {t("button_submit")}
                                </Button>
                                <Button
                                    variant="contained"
                                    // onClick={resetForm}
                                    sx={{
                                        marginLeft: "20px",
                                        backgroundColor: "primaryGreen",
                                        color: "commom.white",
                                        marginRight: "10px",
                                    }}
                                >
                                    {t("button_reset")}
                                </Button>

                            </Grid>
                        </Grid>
                    </Container>
                </form>
            )
            }

            {
                !isSearchPage && (
                    <Container maxWidth={false}>
                        <div
                            style={{
                                display: "block",
                                textAlign: "left",
                                marginTop: "0px",
                            }}
                        >
                            <Button sx={{ float: "left", mr: "20px" }} onClick={handleBack}>
                                <ArrowBackIcon />
                                {t("button_back")}
                            </Button>
                        </div>

                        <div style={{ display: "block", textAlign: "right" }}>
                            <Button sx={{ float: "right", mr: "20px" }} onClick={exportExcel}>
                                <DownloadIcon />
                                {t("button_export_excel")}
                            </Button>
                            <Button sx={{ float: "right", mr: "20px" }}>
                                <DownloadIcon />
                                {t("button_export_pdf")}
                            </Button>
                        </div>
                        <div >
                            <Typography variant="h4" style={{ textAlign: 'center', mt: "-30px" }}><b>{t("Average_daily_wage_increase_statements")}</b></Typography>
                            <TableContainer>
                                <Table sx={{ textAlign: 'left' }} size="small"  >
                                    <TableBody>
                                        <TableRow>
                                            {/* 承判商: */}
                                            <TableCell sx={{ display: 'block', borderBottom: "none" }}>{t('icon1_accrecsearch_sub_condition')}</TableCell>
                                            <TableCell sx={{ textAlign: 'left', borderBottom: "none" }}>
                                                {sendData.vendorDesc}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            {/* 地盤名稱 */}
                                            <TableCell sx={{ display: 'block', borderBottom: "none" }}>{t('Site_Name')}:</TableCell>
                                            <TableCell sx={{ textAlign: 'left', borderBottom: "none" }}>
                                                {sendData.siteDesc}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            {/* 工種 */}
                                            <TableCell sx={{ display: 'block', borderBottom: "none" }}>{t('Job_Type')}:</TableCell>
                                            <TableCell sx={{ textAlign: 'left', borderBottom: "none" }}>
                                                {sendData.jobTypeDesc}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            {/* 日期1 */}
                                            <TableCell sx={{ width: 200, borderBottom: "none" }}>{t('date') + 1}:</TableCell >
                                            <TableCell sx={{ textAlign: 'left', borderBottom: "none" }}>{sendData.dateOneFrom + " " + t("to") + " " + sendData.dateOneTo}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            {/* 日期2 */}
                                            <TableCell sx={{ width: 200, borderBottom: "none" }}>{t('date') + 2}:</TableCell >
                                            <TableCell sx={{ textAlign: 'left', borderBottom: "none" }}>
                                                {sendData.dateTwoFrom + " " + t("to") + " " + sendData.dateTwoTo}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TableContainer>
                                <Table size="small" sx={{ mt: "20px" }} >
                                    <TableHead>
                                        <TableRow>
                                            <ReportTableCell />
                                            <ReportTableCell colSpan={3} sx={{ textAlign: "center" }}> {sendData.dateOneFrom + "~" + sendData.dateOneTo}</ReportTableCell>
                                            <ReportTableCell colSpan={3} sx={{ textAlign: "center" }}>{sendData.dateTwoFrom + "~" + sendData.dateTwoTo}</ReportTableCell>
                                            <ReportTableCell />
                                        </TableRow>
                                        <TableRow>
                                            <ReportTableCell>{sendData.upGroup === "vendor" ? t('Contractor') : sendData.upGroup === "job" ? t('Job_Type') : t('Site_Name')}</ReportTableCell>
                                            <ReportTableCell sx={{ textAlign: "right" }}>{t('Work_Number')}</ReportTableCell>
                                            <ReportTableCell sx={{ textAlign: "right" }}>{t('Total_Payroll')}</ReportTableCell>
                                            <ReportTableCell sx={{ textAlign: "right" }}>{t('Average_daily_wages')}</ReportTableCell>
                                            <ReportTableCell sx={{ textAlign: "right" }}>{t('Work_Number')}</ReportTableCell>
                                            <ReportTableCell sx={{ textAlign: "right" }}>{t('Total_Payroll')}</ReportTableCell>
                                            <ReportTableCell sx={{ textAlign: "right" }}>{t('Average_daily_wages')}</ReportTableCell>
                                            <ReportTableCell sx={{ textAlign: "right" }}>{t('Increase')}</ReportTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{item.momoDesc}</TableCell>
                                                <TableCell sx={{ textAlign: "right" }}>{item.workDaysF}</TableCell>
                                                <TableCell sx={{ textAlign: "right" }}>{item.allinComeF}</TableCell>
                                                <TableCell sx={{ textAlign: "right" }}>{item.averAgeF}</TableCell>
                                                <TableCell sx={{ textAlign: "right" }}>{item.workDaysT}</TableCell>
                                                <TableCell sx={{ textAlign: "right" }}>{item.allinComeT}</TableCell>
                                                <TableCell sx={{ textAlign: "right" }}>{item.averAgeT}</TableCell>
                                                <TableCell sx={{ textAlign: "right" }}>{item.inCrease}</TableCell>
                                            </TableRow>
                                        ))}
                                        {(!tableData || tableData.length === 0) && (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ textAlign: 'center' }} colSpan={8}>{"No data found !"}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Container>
                )
            }
        </>
    );
}
