import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Box,
  Card,
  Checkbox,
  Container,
  DialogContent,
  FormLabel,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  TableCell,
  TextField,
  Tooltip,
  Typography,
  RadioGroup,
  Radio,
  MenuItem,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import LoadingButton from '@mui/lab/LoadingButton'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import SettingsIcon from '@mui/icons-material/Settings'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import moment from 'moment'
import { CustomDialog, CDatePicker, CAutocomplete, CTable, CPagination, CDialog, CTransferList } from 'src/components/common'

import CommonUtils from 'src/utils/CommonUtils'

import $http, { $download } from 'src/utils/api/services'
import { t } from 'i18next'

// 添加數據接口
const handleAddData = async (body) => {
  try {
    // 调用接口
    const data = async () => {
      let data = await $http('/notificationController/message/insert', body)
      return data.message
    }
    return data()
  } catch (error) {
    return null
  }
}
// 修改數據接口
const handleUpdateData = async (body) => {
  try {
    // 调用接口
    const data = async () => {
      let data = await $http('/notificationController/message/update', body)
      return data.message
    }
    return data()
  } catch (error) {
    return null
  }
}
// 刪除數據接口
const handleDeleteData = async (body) => {
  try {
    // 调用接口
    const data = async () => {
      let data = await $http('/notificationController/message/delete', body)
      console.log(data)
      return data.message
    }
    console.log(data)
    return data()
  } catch (error) {
    return null
  }
}
// 查詢报表接口
const handleRefreshData = async (body) => {
  try {
    // 调用接口
    const data = async () => {
      let data = await $http('/notificationController/message/select-all', body)
      return data.data
    }
    return data()
  } catch (error) {
    return null
  }
}

// 查詢其他資料庫 類目 接口
const handleOtherMenu = async (body) => {
  try {
    // 调用接口
    const data = async () => {
      let data = await $http('/othermodules/searchOtherMenu')
      return data.data
    }
    return data()
  } catch (error) {
    return null
  }
}
// 查詢詳情接口
const handleDetailData = async (body) => {
  try {
    // 调用接口
    const data = async () => {
      let data = await $http('/notificationController/message/select', body)
      return data
    }
    return data()
  } catch (error) {
    return null
  }
}

function NotificationItemSetup() {
  // 國際化
  const { t } = useTranslation()
  // 表格数据
  const [rows, setRows] = React.useState([])
  // 表格数据總數
  const [total, setTotal] = React.useState(0)
  const [otherMenuList, setOtherMenuList] = React.useState([])

  // 頁數
  const [page, setPage] = React.useState(1)
  // 每頁數量
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  // 表格每頁數量設置
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 25, 100])

  // 搜索按钮loading
  const [openLoading, setOpenLoading] = React.useState(false)
  // table loading
  const [tableLoading, setTableLoading] = React.useState(false)
  // 搜索表单
  const [searchForm, setSearchForm] = React.useState({
    dateFrom: '',
    messageDesc: '',
    pageSize: rowsPerPage,
    pageNumber: page,
    orderBy: '',
    ascOrder: '',
  })
  // 行数据详情
  const [tableRowDetail, setTableRowDetail] = React.useState({
    messageId: '',
    dateFrom: '',
    dateTo: '',
    messageDesc: '',
    messageDescEng: '',
    messageTypeId: 0,
    vendorList: [],
    siteList: [],
    jobNatureList: [],
  })

  // 彈出框
  const [dialog, setDialog] = useState({
    content: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        {t('Confirm')}
      </Button>
    ),
    open: false,
  })

  // 是否为添加表单
  const [isAddFlag, setIsAddFlag] = React.useState(false)
  // 表單开关
  const [formOpen, setFormOpen] = React.useState(false)
  // 弹出框标题
  const [dialogTitle, setDialogTitle] = React.useState('')

  // const [vendorTargetKeys, setVendorTargetKeys] = useState([])
  // const [siteTargetKeys, setSiteTargetKeys] = useState([])
  // const [jobTargetKeys, setJobTargetKeys] = useState([])
  // const [vendorDataSource, setVendorDataSource] = useState([])
  // const [siteDataSource, setSiteDataSource] = useState([])
  // const [jobDataSource, setJobDataSource] = useState([])
  // 頁面初始化
  useEffect(() => {
    handleRefreshData(searchForm).then((value) => {
      // 设置报表数据
      setRows(value?.records)
      setTotal(value?.total)
    })

    handleOtherMenu().then((value) => {
      setOtherMenuList(value)
    })
  }, [page, rowsPerPage])

  // 表單值改變
  const handleFormChange = (event) => {
    setSearchForm({
      ...searchForm,
      [event.target.name]: event.target.value,
    })
  }

  const handleTableRowDetailChange = (event) => {
    setTableRowDetail({
      ...tableRowDetail,
      [event.target.name]: event.target.value,
    })
    // 加載源數據
    // if (event.target.value === "1") {
    //     $http('/vps/common/getfillVendorId', {}).then((value) => {
    //         console.log(value);
    //         setVendorDataSource(value.data);
    //     })
    // } else if (event.target.value === "2") {
    //     $http('/vps/common/getFillSite', {}).then((value) => {
    //         console.log(value);
    //         setSiteDataSource(value.data);
    //     })
    // } else if (event.target.value === "3") {
    //     $http('/vps/common/getfilljobNatureId', {}).then((value) => {
    //         console.log(value);
    //         setJobDataSource(value.data);
    //     })
    // }
  }

  // 刷新列表
  const refreshRows = async () => {
    // table loading open
    setTableLoading(true)
    handleRefreshData(searchForm).then((value) => {
      // 设置报表数据
      setRows(value?.records)
      setTotal(value?.total)
      // 設置分頁數據
      // setPage(value ? value.current - 1 : 0);
      // setRowsPerPage(value ? value.size : 1);
      // 判斷每頁數量是否存在,不存在就加入進去
      setRowsPerPageOptions(pushKey(rowsPerPageOptions, value ? value.size : 5))
      // table loading close
      setTableLoading(false)
    })
  }
  // 表单提交
  const handleSearchClick = async () => {
    console.log('tableRowDetail', tableRowDetail)
    // 打开按钮loading, 防止重复点击
    setOpenLoading(true)
    setSearchForm({
      ...searchForm,
      pageNumber: page,
      pageSize: rowsPerPage,
    })
    handleRefreshData(searchForm).then((value) => {
      // 关闭按钮loading
      setOpenLoading(false)
      // 改变key值, 使列表刷新
      // setRefreshRows((oldRefreshRows) => oldRefreshRows + 1);
      // 设置报表数据
      setRows(value?.records)
      setTotal(value?.total)
    })
  }
  // 判斷數組是否含有某個元素, 沒有就加進去
  const pushKey = (arr, key) => {
    // arr是否為數組
    if (!Array.isArray(arr)) return
    // 是否含有該key
    const _key = arr.some((item) => item === key)
    if (_key) {
      return arr
    } else {
      return [...arr, key]
    }
  }

  // 重置查询表单
  const handleResetClick = () => {
    setSearchForm({
      dateFrom: '',
      messageDesc: '',
    })
  }

  // 重置添加表单
  const handleResetAddClick = () => {
    setTableRowDetail({
      messageId: '',
      dateFrom: '',
      dateTo: '',
      messageDesc: '',
      messageDescEng: '',
      messageTypeId: 0,
      vendorList: [],
      siteList: [],
      jobNatureList: [],
    })
  }
  // 重置修改表单
  const handleResetEditClick = () => {
    setTableRowDetail({
      ...tableRowDetail,
      messageId: '',
      dateFrom: '',
      dateTo: '',
      messageDesc: '',
      messageDescEng: '',
      messageTypeId: 0,
      vendorList: [],
      siteList: [],
      jobNatureList: [],
    })
  }

  // 禁用开关组件
  const radioRef = React.useRef(null)

  // 添加一行新數據
  const handleAddClick = () => {
    console.log(tableRowDetail)
    if (CommonUtils.isEmptyOrBlank(tableRowDetail.dateFrom) || CommonUtils.isEmptyOrBlank(tableRowDetail.dateTo) || CommonUtils.isEmptyOrBlank(tableRowDetail.messageDesc)) {
      setDialog({
        ...dialog,
        content: t('Please_fill_in_the_information_below'),
        action: (
          <Button
            variant="contained"
            onClick={() => {
              setDialog({ ...dialog, open: false })
            }}
          >
            {t('Confirm')}{' '}
          </Button>
        ),
        open: true,
      })
      return
    }
    // 打开按钮loading, 防止重复点击

    // 类型处理
    if (String(tableRowDetail.messageTypeId) === '1') {
      // 承判商
      if (tableRowDetail.vendorList.length === 0) {
        setDialog({
          ...dialog,
          content: t('Please_select_a_contractor'),
          action: (
            <Button
              variant="contained"
              onClick={() => {
                setDialog({ ...dialog, open: false })
              }}
            >
              {t('Confirm')}{' '}
            </Button>
          ),
          open: true,
        })
        return
      }
    } else if (String(tableRowDetail.messageTypeId) === '2') {
      // 地盘
      if (tableRowDetail.siteList.length === 0) {
        setDialog({
          ...dialog,
          content: t('Please_select_a_site'),
          action: (
            <Button
              variant="contained"
              onClick={() => {
                setDialog({ ...dialog, open: false })
              }}
            >
              {t('Confirm')}{' '}
            </Button>
          ),
          open: true,
        })
        return
      }
    } else if (String(tableRowDetail.messageTypeId) === '3') {
      // 行头
      if (tableRowDetail.jobNatureList.length === 0) {
        setDialog({
          ...dialog,
          content: t('Please_select_a_job'),
          action: (
            <Button
              variant="contained"
              onClick={() => {
                setDialog({ ...dialog, open: false })
              }}
            >
              {t('Confirm')}{' '}
            </Button>
          ),
          open: true,
        })
        return
      }

      setOpenLoading(true)
    }

    // 设置body
    const body = {
      dateFrom: moment(new Date(tableRowDetail.dateFrom).getTime()).format('YYYY/MM/DD'),
      dateTo: moment(new Date(tableRowDetail.dateTo).getTime()).format('YYYY/MM/DD'),
      messageDesc: tableRowDetail.messageDesc,
      messageTypeId: tableRowDetail.messageTypeId,
      vendorList: tableRowDetail.vendorList,
      siteList: tableRowDetail.siteList,
      jobNatureList: tableRowDetail.jobNatureList,
      path: tableRowDetail.path,
    }
    handleAddData(body).then((value) => {
      // 关闭按钮loading
      setOpenLoading(false)
      // 提示是否成功
      setDialog({
        ...dialog,
        // content: value ? t('AddSuccess') : t('AddFail'),
        content: value,
        action: (
          <Button
            variant="contained"
            onClick={() => {
              setDialog({ ...dialog, open: false })
              handleCloseFormClick()
            }}
          >
            {t('Confirm')}
          </Button>
        ),
        open: true,
      })
      // 更新table
      refreshRows()
    })
  }
  // 编辑一行新數據
  const handleEditClick = () => {
    console.log(tableRowDetail)
    if (
      CommonUtils.isEmptyOrBlank(tableRowDetail.dateFrom) ||
      CommonUtils.isEmptyOrBlank(tableRowDetail.dateTo) ||
      CommonUtils.isEmptyOrBlank(tableRowDetail.messageId) ||
      CommonUtils.isEmptyOrBlank(tableRowDetail.messageDesc) ||
      CommonUtils.isEmptyOrBlank(tableRowDetail.messageTypeId)
    ) {
      return
    }
    // 打开按钮loading, 防止重复点击

    // 设置body
    const body = {
      messageId: tableRowDetail.messageId,
      messageDesc: tableRowDetail.messageDesc,
      messageTypeId: tableRowDetail.messageTypeId,
      dateFrom: moment(new Date(tableRowDetail.dateFrom).getTime()).format('YYYY/MM/DD'),
      dateTo: moment(new Date(tableRowDetail.dateTo).getTime()).format('YYYY/MM/DD'),
      vendorList: tableRowDetail.messageTypeId === '1' ? tableRowDetail.vendorList : [],
      siteList: tableRowDetail.messageTypeId === '2' ? tableRowDetail.siteList : [],
      jobNatureList: tableRowDetail.messageTypeId === '3' ? tableRowDetail.jobNatureList : [],
      path: tableRowDetail.path,
    }

    console.log(tableRowDetail)
    if (body.messageTypeId !== '0') {
      if (body.vendorList.length === 0 && body.siteList.length === 0 && body.jobNatureList.length === 0) {
        console.log('1131', body)
        alert('未選中注意事項種類！！！')
        return
      }
    }

    setOpenLoading(true)
    handleUpdateData(body).then((value) => {
      console.log(body)

      // 关闭按钮loading
      setOpenLoading(false)
      // 提示是否成功
      setDialog({
        ...dialog,
        content: value,
        action: (
          <Button
            variant="contained"
            onClick={() => {
              setDialog({ ...dialog, open: false })
              handleCloseFormClick()
            }}
          >
            {t('Confirm')}
          </Button>
        ),
        open: true,
      })
      // 更新table
      refreshRows()
    })
  }
  // 确定执行删除事件
  const handleDeleteClick = (row) => {
    if (CommonUtils.isEmptyOrBlank(row.messageId)) {
      return
    }

    // 打开按钮loading, 防止重复点击
    setOpenLoading(true)
    // 设置body
    const body = {
      messageId: row.messageId,
    }
    console.log(1)
    handleDeleteData(body).then((value) => {
      // 关闭按钮loading
      setOpenLoading(false)
      // 提示是否成功
      setDialog({
        ...dialog,
        content: value,
        action: (
          <Button
            variant="contained"
            onClick={() => {
              setDialog({ ...dialog, open: false })
            }}
          >
            {t('Confirm')}
          </Button>
        ),
        open: true,
      })
      // 更新table
      refreshRows()
      console.log(value)
    })

    console.log(row)
  }

  // 刪除按鈕點擊事件
  const handleOpenDeleteClick = (row) => {
    // 删除时要打开提示框提示是否删除
    setDialog({
      ...dialog,
      content: t('sure_to_delete'),
      action: (
        <>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <FormControl>
              <LoadingButton
                loading={openLoading}
                variant="contained"
                sx={{
                  backgroundColor: '#ff0000',
                  color: 'commom.white',
                }}
                onClick={() => handleDeleteClick(row)}
              >
                {t('Delete')}
              </LoadingButton>
            </FormControl>
            <FormControl sx={{ marginLeft: '10px' }}>
              <Button
                variant="contained"
                onClick={() => {
                  setDialog({ ...dialog, open: false })
                }}
                sx={{
                  backgroundColor: '#9b9b9b',
                  color: 'commom.white',
                  marginRight: '10px',
                }}
                label={t('Close')}
              >
                {t('Close')}
              </Button>
            </FormControl>
          </Grid>
        </>
      ),
      open: true,
    })
  }
  // 关闭表单弹出框
  const handleCloseFormClick = () => {
    setFormOpen(false)
  }
  // 打開添加窗口
  const handleOpenAddClick = () => {
    // 添加时需要显示输入框
    setIsAddFlag(true)
    // 重置表单内容
    handleResetAddClick()
    // 设置弹出框标题为-添加
    setDialogTitle(t('Add'))
    // 打开添加表单
    setFormOpen(true)
  }

  const handleShowSizeChange = (page, rowsPerPage) => {
    setPage(page)
    setRowsPerPage(rowsPerPage > 0 ? rowsPerPage : undefined)
    setSearchForm({
      ...searchForm,
      pageNumber: page,
      pageSize: rowsPerPage,
    })
  }
  // 編輯按鈕點擊事件
  const handleOpenEditClick = (row) => {
    // setOpen(true)
    // 编辑时需要隐藏输入框
    setIsAddFlag(false)
    // 设置弹出框标题为-编辑
    setDialogTitle(t('Edit'))
    // 設置查詢參數
    const body = {
      messageId: row.messageId,
    }
    handleDetailData(body).then((result) => {
      if (result.code === 200) {
        console.log(result)
        const value = result.data
        let newValue = {
          ...tableRowDetail,
          messageId: row?.messageId,
          messageDesc: row?.messageDesc,
          messageDescEng: row?.messageDescEng,
          dateFrom: new Date(moment(row?.dateFrom, 'YYYY/MM/DD').format()),
          dateTo: new Date(moment(row?.dateTo, 'YYYY/MM/DD').format()),
          messageTypeId: String(value?.messageTypeId),
          path: row.path,
        }
        if (value?.messageTypeId === 1) {
          newValue = {
            ...newValue,
            vendorList: value?.vendorList,
            siteList: [],
            jobNatureList: [],
          }
        } else if (value?.messageTypeId === 2) {
          newValue = {
            ...newValue,
            vendorList: [],
            siteList: value?.siteList,
            jobNatureList: [],
          }
        } else if (value?.messageTypeId === 3) {
          newValue = {
            ...newValue,
            vendorList: [],
            siteList: [],
            jobNatureList: value?.jobNatureList,
          }
        } else if (value?.messageTypeId === 0) {
          newValue = {
            ...newValue,
            vendorList: [],
            siteList: [],
            jobNatureList: [],
          }
        }
        // 回显表单内容
        setTableRowDetail(newValue)
        // 加載源數據
        // if (value?.messageTypeId === "1") {
        //     $http('/vps/common/getfillVendorId', {}).then((value) => {
        //         setVendorDataSource(value.data);
        //     })
        // } else if (value?.messageTypeId === "2") {
        //     $http('/vps/common/getFillSite', {}).then((value) => {
        //         setSiteDataSource(value.data);
        //     })
        // } else if (value?.messageTypeId === "3") {
        //     $http('/vps/common/getfilljobNatureId', {}).then((value) => {
        //         setJobDataSource(value.data);
        //     })
        // }
        // 打开修改表单
        setFormOpen(true)
      } else {
        // 提示是否成功
        setDialog({
          ...dialog,
          content: result.message,
          action: (
            <Button
              variant="contained"
              onClick={() => {
                setDialog({ ...dialog, open: false })
              }}
            >
              {t('Confirm')}
            </Button>
          ),
          open: true,
        })
      }
    })
  }

  function ChangeSize() {
    const [size, setSize] = useState({
      width: document.documentElement.clientWidth,
      hieght: document.documentElement.clientHeight,
    })

    const onResize = useCallback(() => {
      setSize({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      })
    }, [])

    useEffect(() => {
      window.addEventListener('resize', onResize)
      return () => {
        window.removeEventListener('resize', onResize)
      }
    }, [])

    return size
  }

  // 获取浏览器宽高
  let windowsSize = ChangeSize()

  // 表頭
  const columns = [
    {
      dataIndex: 'dateFrom',
      label: t('publication_date'),
      align: 'center',
      width: '20%',
      isOrder: false,
    },
    {
      dataIndex: 'messageDesc',
      label: t('message_desc'),
      align: 'left',
      width: '60%',
      isOrder: false,
      cellSx: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      render: (record) => {
        return (
          <Tooltip title={record.messageDesc}>
            <Box
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: windowsSize.width * 0.6,
              }}
            >
              {record.messageDesc}
            </Box>
          </Tooltip>
        )
      },
    },
    {
      dataIndex: 'action',
      label: t('Action'),
      align: 'center',
      width: '20%',
      isOrder: false,
      render: (record) => {
        return (
          <>
            <Tooltip title={t('Edit')}>
              <IconButton onClick={() => handleOpenEditClick(record)}>
                <SettingsIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('Delete')}>
              <IconButton onClick={() => handleOpenDeleteClick(record)}>
                <DeleteOutlineRoundedIcon />
              </IconButton>
            </Tooltip>
          </>
        )
      },
    },
  ]

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  // const handleVendorChecked = (nextTargetKeys, direction, moveKeys) => {
  //     setVendorTargetKeys(nextTargetKeys)
  // }
  // const handleSiteChecked = (nextTargetKeys, direction, moveKeys) => {
  //     setSiteTargetKeys(nextTargetKeys)
  // }
  // const handleJobChecked = (nextTargetKeys, direction, moveKeys) => {
  //     setJobTargetKeys(nextTargetKeys)
  // }

  const handleCategory = (item) => {
    setTableRowDetail({ ...tableRowDetail, path: item.path })
  }

  return (
    <>
      <Container maxWidth={false} sx={{ mt: 5, mb: 8 }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          {t('icon4_message_setup')}
        </Typography>

        <Card variant="outlined" sx={{ border: '1px solid rgba(5, 5, 5, .06)', borderRadius: '8px' }}>
          <Box sx={{ padding: '32px 24px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <TextField name="messageDesc" value={searchForm.messageDesc || ''} fullWidth size="small" label={t('message_desc')} onChange={(event) => handleFormChange(event)} />
              </Grid>
              <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControl>
                  <LoadingButton
                    loading={openLoading}
                    variant="contained"
                    onClick={handleSearchClick}
                    sx={{
                      backgroundColor: 'primaryBlue',
                      color: 'commom.white',
                    }}
                  >
                    {t('Search')}
                  </LoadingButton>
                </FormControl>
                <FormControl sx={{ marginLeft: '20px' }}>
                  <Button
                    variant="contained"
                    onClick={handleResetClick}
                    sx={{
                      backgroundColor: 'primaryGreen',
                      color: 'commom.white',
                    }}
                    label={t('Reset')}
                  >
                    {t('Reset')}
                  </Button>
                </FormControl>
                <FormControl sx={{ marginLeft: '20px' }}>
                  <Button variant="contained" onClick={handleOpenAddClick} sx={{ backgroundColor: '#2d8d27', color: 'commom.white' }} label={t('New')}>
                    {t('New')}
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <CTable disablePagination rowKey="messageId" loading={tableLoading} columns={columns} data={rows} paperSx={{ border: 0 }} />
          <CPagination total={total} rowsPerPage={rowsPerPage} onChange={handleShowSizeChange} />
        </Card>
      </Container>
      {/* 提示框 */}
      <CustomDialog sx={{ whiteSpace: 'pre-line' }} open={dialog.open} title={dialog.title} content={dialog.content} action={dialog.action} />

      {/* <EditForm open={formOpen} /> */}

      <CDialog fullWidth disableBackdropClick maxWidth={'md'} title={dialogTitle} open={formOpen} onClose={() => setFormOpen(false)}>
        <DialogContent dividers sx={{ pt: 3, pb: 3 }}>
          {/* <Card variant="outlined" sx={{ mb: 3, borderRadius: '8px', border: '1px solid rgba(5, 5, 5, .06)' }}> */}
          <Grid container justifyContent="center" alignItems="center" spacing={1}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <CDatePicker
                name="dateFrom"
                label={t('publication_date_from')}
                required
                value={tableRowDetail.dateFrom || null}
                onChange={(data) => {
                  setTableRowDetail({
                    ...tableRowDetail,
                    dateFrom: data ? moment(new Date(data).getTime()).format('YYYY/MM/DD') : null,
                  })
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CDatePicker
                name="dateTo"
                label={t('publication_date_to')}
                required
                value={tableRowDetail.dateTo || null}
                onChange={(data) => {
                  setTableRowDetail({
                    ...tableRowDetail,
                    dateTo: data ? moment(new Date(data).getTime()).format('YYYY/MM/DD') : null,
                  })
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={tableRowDetail.messageDesc === ''}
                multiline
                required
                fullWidth
                name="messageDesc"
                value={tableRowDetail.messageDesc || ''}
                size="small"
                label={t('message_desc')}
                onChange={(event) => handleTableRowDetailChange(event)}
              />
            </Grid>
            <Grid item xs={12}>
              {/* 類目 */}
              <TextField label={t('Category')} value={tableRowDetail.path || ''} size="small" fullWidth select>
                <MenuItem
                  onClick={() => {
                    setTableRowDetail({
                      ...tableRowDetail,
                      path: null,
                    })
                  }}
                  value={null}
                >
                  {'ALL'}
                </MenuItem>
                {otherMenuList &&
                  otherMenuList.map((item, index) => (
                    <MenuItem key={index} value={item.path} onClick={() => handleCategory(item)}>
                      {item.menuDescCn}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="row-radio-buttons-group-label">{t('message_type')}</FormLabel>
                <RadioGroup
                  name="messageTypeId"
                  aria-labelledby="row-radio-buttons-group-label"
                  ref={radioRef}
                  value={tableRowDetail.messageTypeId}
                  onChange={(event) => handleTableRowDetailChange(event)}
                >
                  <FormControlLabel value={0} control={<Radio />} label={t('message_type_0')} labelPlacement="end" />
                  <FormControlLabel value={1} control={<Radio />} label={t('message_type_1')} labelPlacement="end" />
                  <FormControlLabel value={2} control={<Radio />} label={t('message_type_2')} labelPlacement="end" />
                  <FormControlLabel value={3} control={<Radio />} label={t('message_type_3')} labelPlacement="end" />
                </RadioGroup>
              </FormControl>
            </Grid>
            {tableRowDetail.messageTypeId === '1' && (
              <Grid item xs={12}>
                {/* <CTransferList
                                    itemKey="id"
                                    height={300}
                                    dataSource={vendorDataSource}
                                    targetKeys={vendorTargetKeys}
                                    titles={[t('unselected'), t('selected')]}
                                    render={(item) => `${item.text}`}
                                    onChange={handleVendorChecked}
                                /> */}
                <CAutocomplete
                  // error={tableRowDetail.categoryId === ''}
                  multiple
                  required
                  disableCloseOnSelect
                  url="/vps/common/getfillVendorId"
                  name="vendorList"
                  label={t('Vendor')}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                      {option.text}
                    </li>
                  )}
                  value={tableRowDetail.vendorList}
                  onChange={(event, newData) => {
                    if (newData) {
                      setTableRowDetail({
                        ...tableRowDetail,
                        vendorList: newData,
                      })
                      console.log(tableRowDetail)
                    } else {
                      setTableRowDetail({
                        ...tableRowDetail,
                        vendorList: [],
                      })
                    }
                  }}
                />
              </Grid>
            )}
            {tableRowDetail.messageTypeId === '2' && (
              <Grid item xs={12}>
                {/* <CTransferList
                                    itemKey="id"
                                    height={300}
                                    dataSource={siteDataSource}
                                    targetKeys={siteTargetKeys}
                                    titles={[t('unselected'), t('selected')]}
                                    render={(item) => `${item.text}`}
                                    onChange={handleSiteChecked}
                                /> */}

                <CAutocomplete
                  // error={tableRowDetail.categoryId === ''}
                  multiple
                  required
                  disableCloseOnSelect
                  url="/vps/common/getFillSite"
                  name="siteList"
                  label={t('Site')}
                  renderOption={(props, option, { selected }) => {
                    console.log(option)
                    return (
                      <li {...props}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.text}
                      </li>
                    )
                  }}
                  value={tableRowDetail.siteList}
                  onChange={(event, newData) => {
                    console.log(newData)
                    if (newData) {
                      setTableRowDetail({
                        ...tableRowDetail,
                        siteList: newData,
                      })
                    } else {
                      setTableRowDetail({
                        ...tableRowDetail,
                        siteList: [],
                      })
                    }
                  }}
                />
              </Grid>
            )}
            {tableRowDetail.messageTypeId === '3' && (
              <Grid item xs={12}>
                {/* <CTransferList
                                    itemKey="id"
                                    height={300}
                                    dataSource={jobDataSource}
                                    targetKeys={jobTargetKeys}
                                    titles={[t('unselected'), t('selected')]}
                                    render={(item) => `${item.text}`}
                                    onChange={handleJobChecked}
                                /> */}
                <CAutocomplete
                  // error={tableRowDetail.categoryId === ''}
                  multiple
                  required
                  disableCloseOnSelect
                  url="/vps/common/getfilljobNatureId"
                  name="jobNatureList"
                  label={t('Job_Nature')}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li {...props}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.text}
                      </li>
                    )
                  }}
                  value={tableRowDetail.jobNatureList}
                  onChange={(event, newData) => {
                    console.log(newData)
                    if (newData) {
                      setTableRowDetail({
                        ...tableRowDetail,
                        jobNatureList: newData,
                      })
                    } else {
                      setTableRowDetail({
                        ...tableRowDetail,
                        jobNatureList: [],
                      })
                    }
                  }}
                />
              </Grid>
            )}

            {!isAddFlag && <></>}
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', marginTop: 2 }}>
            <FormControl>
              <LoadingButton
                loading={openLoading}
                variant="contained"
                sx={{
                  backgroundColor: '#2d8d27',
                  color: 'commom.white',
                }}
                onClick={dialogTitle === t('Add') ? handleAddClick : handleEditClick}
              >
                {dialogTitle}
              </LoadingButton>
            </FormControl>
            <FormControl sx={{ marginLeft: '10px' }}>
              <Button
                variant="contained"
                onClick={dialogTitle === t('Add') ? handleResetAddClick : handleResetEditClick}
                sx={{
                  backgroundColor: 'primaryGreen',
                  color: 'commom.white',
                  marginRight: '10px',
                }}
                label={t('Reset')}
              >
                {t('Reset')}
              </Button>
            </FormControl>
            <FormControl>
              <Button
                variant="contained"
                onClick={handleCloseFormClick}
                sx={{
                  backgroundColor: '#9b9b9b',
                  color: 'commom.white',
                  marginRight: '10px',
                }}
                label={t('Close')}
              >
                {t('Close')}
              </Button>
            </FormControl>
          </Grid>
          {/* </Card> */}
        </DialogContent>
      </CDialog>
    </>
  )
}

export default NotificationItemSetup
