import React, { useRef, useEffect, useState } from 'react';
import {
  Button,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, Link,
  TableRow, Backdrop, CircularProgress
} from '@mui/material';

import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getComparator, stableSort } from '../../TableSort';

export default function ReportDaysSearchResult(props) {
  const componentRef = useRef()
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchResultList, setSearchResultList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('chineseName');

  function clickCell(index) {
    switch (index) {
      case 1: setOrderBy('smartCardId'); break;
      case 2: setOrderBy('englishName'); break;
      case 3: setOrderBy('chineseName'); break;
      case 4: setOrderBy('safetyPositionDesc'); break;
      case 5: setOrderBy('yearmonth'); break;
      case 6: setOrderBy('effectiveDate'); break;
      case 7: setOrderBy('effectiveDateTo'); break;
      case 8: setOrderBy('spApprovalDate'); break;
      default: setOrderBy('smartCardId'); break;
    }

    if (order === 'asc') {
      setOrder('desc')
    } else {
      setOrder('asc')
    }
  }

  const handleBack = () => {
    navigate(-1)
  }


  const goDetail = (e, row) => {
    navigate({
      pathname: '/workerAttendanceRecord/adocspMain/applyList/applyDayEditOrView',
      // isViewData 为1的时候表示是查看数据 为0的时候表示编辑数据
      // 這裡只能觀看，所以設置為1
      search: `?${createSearchParams({
        'isViewData': true,
        'applicationId': row.applicationId,
        'applicationSeqNo': row.applicationSeqNo,

      })}`
    })
  }

  useEffect(() => {
    const getPTPFSApprovedByDateList = async () => {
      let cardHolderId = searchParams.get("holderId")
      let siteId = searchParams.get("siteId")
      let cd=searchParams.get("cd");
      let dateFrom = searchParams.get("dateFrom");
      let dateTo = searchParams.get("dateTo");
      let list = await apiAtt.getPTPFSApprovedByDateList(siteId, cardHolderId,cd,dateFrom,dateTo);
      if (list !== null) {
        setSearchResultList(list.records)
      }

      setLoading(false)
    }
    getPTPFSApprovedByDateList()

  }, [])

  return (
    <>
      <div style={{ display: 'block', textAlign: 'left', }}>
        <Button sx={{ float: 'left' }} onClick={handleBack}><ArrowBackIcon />{t('button_back')}</Button>
      </div>
      <div id='div' ref={componentRef} style={{}}>
        <Container maxWidth="false" >

          <div>
            <div style={{ display: 'block', marginBottom: '60px' }}>
              <Typography variant='h4' sx={{ textAlign: 'center' }}>
                <b>{t("search_vendor_daily_records")}</b></Typography>
            </div>

            <div  >
              <TableContainer sx={{ marginBottom: '30px', backgroundColor: 'transparent' }}>
              <Table sx={{ textAlign: "left" }} size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 80,
                          border: 0,
                        }}
                      >
                        {t("search_site_title_dot")}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          border: 0,
                          textAlign: "left",
                        }}
                      >
                        {searchParams.get("siteDesc")}
                      </TableCell>
                    </TableRow>
                    <TableRow    >
                    <TableCell sx={{ fontSize: '15px', px: '5px', width: 160, border: 0 }}>
                      Total: </TableCell>
                    <TableCell sx={{ fontSize: '15px', px: '5px', border: 0 }}>
                      {searchResultList.length} Record(s)</TableCell>
                  </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>


            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <div  > <TableContainer  >
              <Table sx={{ minWidth: 650, p: '2px' }} size="small" >
                <TableHead>
                  <TableRow hover sx={{ border: '1px' }}>
                    <TableCell sx={{ whiteSpace: "nowrap", }} >
                      <Link underline="none" sx={{ whiteSpace: "nowrap", }}
                        onClick={() => clickCell(1)}>
                        {t("search_smartcardId")}
                      </Link>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap", }} >
                      <Link underline="none" sx={{ whiteSpace: "nowrap", }}
                        onClick={() => clickCell(2)}>
                        {t("search_englishName_notDot")}
                      </Link>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap", }} >
                      <Link underline="none" sx={{ whiteSpace: "nowrap", }}
                        onClick={() => clickCell(3)}>
                        {t("search_chinese_name_notDot")}
                      </Link>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap", }} >
                      <Link underline="none" sx={{ whiteSpace: "nowrap", }}
                        onClick={() => clickCell(4)}>
                        {t("search_employmentPosition")}
                      </Link>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap", }} >
                      <Link underline="none" sx={{ whiteSpace: "nowrap", }}
                        onClick={() => clickCell(5)}>
                        {t("search_years_title")}
                      </Link>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap", }} >
                      <Link underline="none" sx={{ whiteSpace: "nowrap", }}
                        onClick={() => clickCell(6)}>
                        {t("search_effectiveDate")}
                      </Link>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap", }} >
                      <Link underline="none" sx={{ whiteSpace: "nowrap", }}
                        onClick={() => clickCell(7)}>
                        {t("search_validate_to")}
                      </Link>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap", }} >
                      <Link underline="none" sx={{ whiteSpace: "nowrap", }}
                        onClick={() => clickCell(8)}>
                        {t("search_approveDate_title")}
                      </Link>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>  </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(searchResultList, getComparator(order, orderBy)).map((row2, index) => (
                    <TableRow hover key={index + Math.random} >
                      <TableCell align="center" >{row2.smartCardId}</TableCell>
                      <TableCell align="center" >{row2.englishName}</TableCell>
                      <TableCell align="right" >{row2.chineseName}</TableCell>
                      <TableCell align="right" >{row2.safetyPositionDesc}</TableCell>
                      <TableCell align="right" >{row2.yearmonth}</TableCell>
                      <TableCell align="right" >{row2.effectiveDate}</TableCell>
                      <TableCell align="right" >{row2.effectiveDateTo}</TableCell>
                      <TableCell align="right" >{row2.spApprovalDate}</TableCell>
                      <TableCell align="right" ><Button sx={{ backgroundColor: 'transparent', border: 'none' }} onClick={(e) => goDetail(e, row2)}>{t("button_view")}</Button></TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </TableContainer>
            </div>
          </div>
        </Container>
      </div>

    </>
  )
}

