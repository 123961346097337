import React, { useRef, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow, Backdrop, CircularProgress,

} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import $http, { $download } from "src/utils/api/services";
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import DownloadIcon from "@mui/icons-material/Download";

export default function CattpwspReportDetail() {

  const componentRef = useRef()
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [dataSource, setDataSource] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {

  }, [])

  const handleBack = () => {
    navigate(-1)
  }

  const printComponentRef = useRef();

  useEffect(() => {
    getTradeReportList()
  }, [])

  const getTradeReportList = async () => {

    let list = await apiAtt.getTradeReportList(
      searchParams.get("dataYear"),
      searchParams.get("tradeId"),
     
    )
    
    if (list !== null) {
      setDataSource(list)

    }
    setLoading(false)
  }

  const exportPdf = (data) => {
    $download(
      `/payrollReportController/tradeReportListPdf`,
      "application/pdf",
      data
    );
  };

  const exportExcel = (data) => {
    $download(
      `/payrollReportController/tradeReportListVoExcel`,
      "application/vnd.ms-excel",
      data
    );
  };

  return (
    <Container    >
      <div style={{ display: 'block', textAlign: 'left' }}>
        <Button sx={{ float: 'left' }}
          onClick={handleBack}><ArrowBackIcon />{t('button_back')}</Button>
      </div>

      <div style={{ display: 'block', textAlign: 'right' }}>
        <Button sx={{ float: "right", mr: "20px" }} onClick={() => exportPdf({"year":searchParams.get("dataYear"),
                 "jobNatureId":  searchParams.get("tradeId")})}>
          <DownloadIcon />
          {t("button_export_pdf")}
        </Button>
        <Button sx={{ float: "right", mr: "20px" }} onClick={() => exportExcel({"year":searchParams.get("dataYear"),
                 "jobNatureId":  searchParams.get("tradeId")})}>
          <DownloadIcon />
          {t("button_export_excel")}
        </Button>
      </div>

      <div id='div' style={{}} ref={printComponentRef} >
        <Container  >
          <div  >
            <div style={{ display: 'block', marginBottom: '60px' }}>
              <Typography variant='h4' sx={{ textAlign: 'center' }}>
                <b>{t("search_autoTransPay_tips") + '(' + searchParams.get("dataYear") + ')'}</b></Typography>
            </div>

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            {/* table */}
            <TableContainer sx={{ marginTop: '20px' }}>
              <Table sx={{ p: '2px' }} size="small" >

                <TableHead>
                  {/* TableRow是行，表示有多少行 */}
                  {/* TableCell是列，橫著看 */}
                  <TableRow hover  >
                    <TableCell rowSpan={4} sx={{ whiteSpace: "nowrap", }} >
                      {t("search_trade_title")}</TableCell>
                    <TableCell rowSpan={4} sx={{ whiteSpace: "nowrap", }} >
                      {t("search_join_totalWorkerIncome")}</TableCell>
                    <TableCell rowSpan={4} sx={{ whiteSpace: "nowrap", }} >
                      {t("search_join_totalWorker")}</TableCell>
                    <TableCell rowSpan={4} sx={{ whiteSpace: "nowrap", }} >
                      {t("search_join_totalWorkdays")}</TableCell>
                    <TableCell rowSpan={4} sx={{ whiteSpace: "nowrap", }} >
                      {t("search_average_income")}</TableCell>
                  </TableRow>

                </TableHead>

                {dataSource.map((dataRow, index) => (
                  <TableRow hover key={index + Math.random} >
                    {/* 行頭 */}
                    <TableCell  >
                      {dataRow.jobNatureDesc} </TableCell>
                    {/* 參與工人總收入 */}
                    <TableCell  >
                      {dataRow.salaryWithAutoPayformat} </TableCell>
                    {/* 參與總人數 */}
                    <TableCell  >
                      {dataRow.totalWorkersformat} </TableCell>
                    {/* 參與總工數 */}
                    <TableCell  >
                      {dataRow.totalWorkDayActformat} </TableCell>
                    {/* 平均每日工資 */}
                    <TableCell  >
                      {dataRow.averaGesalaryformat} </TableCell>
                  </TableRow>
                ))}


              </Table>

            </TableContainer>
          </div>

        </Container>
      </div>
    </Container>

  )
}

