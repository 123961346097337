import React, { useRef, useEffect } from 'react'
import { Container, Typography, Paper, Table, TableBody, TableHead, TableCell, TableContainer, TableRow, Backdrop, CircularProgress } from '@mui/material'
import html2canvas from 'html2canvas'
import { useTranslation } from 'react-i18next'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import DownloadIcon from '@mui/icons-material/Download'
import $http, { $download } from 'src/utils/api/services'
import moment from 'moment'

// 工人出入記錄一覽表---
export default function LoeaerDetailAndDailyIncome() {
  const componentRef = useRef()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [mainData, setMainData] = React.useState([])
  const [dataSource, setDataSource] = React.useState([])
  // 記錄的days天 格式為20
  const [recordDays, setRecordDays] = React.useState([])
  // 記錄的dates日期 格式為2021/11/01
  const [recordDates, setRecordDates] = React.useState([])
  const [newRecordDates, setNewRecordDates] = React.useState([])
  const contentRef = useRef(null)

  const [loading, setLoading] = React.useState(true)
  useEffect(() => {}, [])

  const handleBack = () => {
    navigate(-1)
  }

  const printComponentRef = useRef()

  useEffect(() => {
    getDays()
    getDates()
    getInOutRecordAccrualList()
  }, [])

  const getDateFrom = () => {
    let dateF = ''
    dateF = searchParams.get('accessDateFrom')
    while (dateF.indexOf('-') >= 0) {
      dateF = dateF.replace('-', '/')
    }
    return dateF
  }

  const getDateTo = () => {
    let dateF = ''
    dateF = searchParams.get('accessDateTo')
    while (dateF.indexOf('-') >= 0) {
      dateF = dateF.replace('-', '/')
    }
    return dateF
  }

  const getDays = () => {
    let startDateString = getDateFrom()
    let endDateString = getDateTo()
    let startDate = new Date(startDateString)
    let endDate = new Date(endDateString)

    let list = []
    while (startDate <= endDate) {
      list.push(moment(startDate).format('DD'))
      startDate = moment(startDate).add(1, 'days')
    }

    setRecordDays([...new Set(list)])
    console.log('days==', [...new Set(list)])
  }

  const exportExcel = (data) => {
    $download(`/attendance/report/getInOutRecordAccrualListExcel`, 'application/vnd.ms-excel', data)
  }

  const exportPDF = (data) => {
    $download(`/attendance/report/getInOutRecordAccrualListPdf`, 'application/pdf', data)
  }

  const getDates = () => {
    let startDateString = getDateFrom()
    let endDateString = getDateTo()
    let startDate = new Date(startDateString)
    let endDate = new Date(endDateString)

    let list = []
    while (startDate <= endDate) {
      list.push(moment(startDate).format('yyyy/MM/DD'))
      startDate = moment(startDate).add(1, 'days')
    }

    setRecordDates([...new Set(list)])
    console.log('recordDates==', [...new Set(list)])

    setNewRecordDates([...new Set(list)])
  }

  const getInOutRecordAccrualList = async () => {
    let list = await apiAtt.getInOutRecordAccrualList(getDateFrom(), getDateTo(), searchParams.get('siteId'), searchParams.get('smartCardId'), searchParams.get('subContractorId'))

    if (list !== null) {
      setMainData(list.mainData)
      setDataSource(list.titleData)
    }
    setLoading(false)
  }

  const getRecord = (subData, destinDate) => {
    let record = ''
    subData.map((value) => {
      if (value.accessdate === destinDate) {
        if (value.isValue === 'Y') {
          if (value.accessdatetimeminutes.length < 2) {
            record = value.accessdatetimehour + ':0' + value.accessdatetimeminutes
          } else {
            record = value.accessdatetimehour + ':' + value.accessdatetimeminutes
          }
        } else {
          record = 'M'
        }
      }
      return []
    })
    return record
  }

  // 每個人每天的入息數據
  const getRecordIncome = (subData, destinDate) => {
    let record = ''
    subData.map((value) => {
      if (value.accessdate === destinDate) {
        if (value.dailyRateSum) {
          if (value.dailyRateSum.dailyRate) {
            record = value.dailyRateSum.dailyRate.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          }
        }
      }
      return []
    })
    return record
  }

  // 每個人每天的入息數據new
  const getRecordIncomeNew = (subData, destinDate) => {
    let record = ''
    subData.map((value) => {
      if (value.shortdate === destinDate) {
        if (value.dailyRate) {
          console.log('record', value)
          record = value.dailyRate.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
      }
      return []
    })

    console.log('record', record)
    return record
  }

  const getAverageRecordIncome = (subData) => {
    let record = 0.0
    subData.map((value) => {
      if (value.averageSalary) {
        if (value.averageSalary) {
          record = Number(value.averageSalary)
        }
      }
      return []
    })
    return record
  }

  const getAverageRecordIncomeNew = (subData) => {
    // 獲取每天的日息，有數據就相加
    let record = 0.0

    subData.map((value) => {
      console.log('total', value)
      if (value.dailyRate) {
        record += value.dailyRate
      }

      return []
    })

    return record
  }

  // 每個分判僱主MPF
  const getEmployerMPFData = (subData) => {
    let record = 0
    subData.map((value) => {
      if (value.dailyRateSum) {
        if (value.dailyRateSum.employerMpf) {
          record += value.dailyRateSum.employerMpf
        }
      }
      return []
    })
    return record
  }

  // 每個分判僱主MPF NEW
  const getEmployerMPFDataNew = (subData) => {
    let record = 0
    subData.map((value) => {
      if (value.employerMpf) {
        record += value.employerMpf
      }

      return []
    })
    return record
  }

  // 每個分判僱主MPF總數
  const getAllEmployerMPFData = (countList) => {
    let recordCount = 0
    countList.map((value) => {
      recordCount += getEmployerMPFData(value.subData)
      return []
    })
    return recordCount
  }

  // 每個分判僱主MPF總數New
  const getAllEmployerMPFDataNew = (countList) => {
    let recordCount = 0
    countList.map((value) => {
      recordCount += getEmployerMPFDataNew(value.subDataImcome)
      return []
    })
    return recordCount
  }

  // 全部分判僱主MPF總數
  const getAllVendorEmployerMPFData = (mainData) => {
    let recordCount = 0
    mainData.map((mainDataValue) => {
      mainDataValue.coutList.map((childValue) => {
        childValue.subData.map((subDataValue) => {
          if (subDataValue.dailyRateSum) {
            if (subDataValue.dailyRateSum.employerMpf) {
              recordCount += subDataValue.dailyRateSum.employerMpf
            }
          }
          return []
        })
        return []
      })
      return []
    })
    return recordCount
  }

  // 全部分判僱主MPF總數New
  const getAllVendorEmployerMPFDataNew = (mainData) => {
    let recordCount = 0
    mainData.map((mainDataValue) => {
      mainDataValue.coutList.map((childValue) => {
        childValue.subDataImcome.map((subDataValue) => {
          if (subDataValue.employerMpf) {
            recordCount += subDataValue.employerMpf
          }

          return []
        })
        return []
      })
      return []
    })
    return recordCount
  }

  // 每個分判僱員MPF
  const getEmployeeMPFData = (subData) => {
    let record = 0
    subData.map((value) => {
      if (value.dailyRateSum) {
        if (value.dailyRateSum.employeeMpf) {
          record += value.dailyRateSum.employeeMpf
        }
      }
      return []
    })
    return record
  }

  // 每個分判僱員MPF New
  const getEmployeeMPFDataNew = (subData) => {
    let record = 0
    subData.map((value) => {
      if (value.employeeMpf) {
        record += value.employeeMpf
      }

      return []
    })
    return record
  }

  // 每個分判僱員MPF總數
  const getAllEmployeeMPFData = (countList) => {
    let recordCount = 0
    countList.map((value) => {
      recordCount += getEmployeeMPFData(value.subData)
      return []
    })
    return recordCount
  }

  // 每個分判僱員MPF總數New
  const getAllEmployeeMPFDataNew = (countList) => {
    let recordCount = 0
    countList.map((value) => {
      recordCount += getEmployeeMPFDataNew(value.subDataImcome)
      return []
    })
    return recordCount
  }

  // 全部分判僱員MPF總數
  const getAllVendorEmployeeMPFData = (mainData) => {
    let recordCount = 0
    mainData.map((mainDataValue) => {
      mainDataValue.coutList.map((childValue) => {
        childValue.subData.map((subDataValue) => {
          if (subDataValue.dailyRateSum) {
            if (subDataValue.dailyRateSum.employeeMpf) {
              recordCount += subDataValue.dailyRateSum.employeeMpf
            }
          }
          return []
        })
        return []
      })
      return []
    })
    return recordCount
  }

  // 全部分判僱員MPF總數New
  const getAllVendorEmployeeMPFDataNew = (mainData) => {
    let recordCount = 0
    mainData.map((mainDataValue) => {
      mainDataValue.coutList.map((childValue) => {
        childValue.subDataImcome.map((subDataValue) => {
          if (subDataValue.employeeMpf) {
            recordCount += subDataValue.employeeMpf
          }

          return []
        })
        return []
      })
      return []
    })
    return recordCount
  }

  // 獲取每個日期的總數
  const getEveryDaysTotalRecord = (mainData, destinDate, isOneDay) => {
    let record = 0

    mainData.map((mainDataRow) => {
      if (mainDataRow.coutList) {
        mainDataRow.coutList.map((countListRow) => {
          countListRow.subData.map((subDataRow) => {
            if (isOneDay) {
              if (subDataRow.accessdate === destinDate) {
                if (subDataRow.isValue === 'Y') {
                  record += 1
                }
              }
            } else {
              if (subDataRow.isValue === 'Y') {
                record += 1
              }
            }

            return []
          })
          return []
        })
      }

      return []
    })
    return record
  }

  // 獲取工人的總數
  const getTotalPersonCount = (mainData) => {
    let record = 0

    mainData.map((mainDataRow) => {
      if (mainDataRow.coutList) {
        record += mainDataRow.coutList.length
      }

      return []
    })
    return record
  }

  // 獲取每個人的總數
  const getVailablePersonRecordCount = (subData) => {
    // 只要個人的數據isValue為Y就算一條有效數據
    let recordCount = 0
    subData.map((value) => {
      if (value.isValue === 'Y') {
        recordCount += 1
      }
      return []
    })
    return recordCount
  }

  const getVailablePersonRecordCountNew = (subData) => {
    // 只要有個人的數據isValue就算一條有效數據
    let recordCount = 0
    subData.map((value) => {
      recordCount += 1
      return []
    })
    return recordCount
  }

  // 獲取每個人的總入息
  const getPersonTotalIncom = (subData) => {
    return getAverageRecordIncome(subData) * getVailablePersonRecordCount(subData)
  }

  // 獲取每個人的總入息New
  const getPersonTotalIncomNew = (subData) => {
    return getAverageRecordIncomeNew(subData)
  }

  // 獲取每個分判下面全部的人的總數
  const getVailableVendorRecordCount = (countList) => {
    let recordCount = 0
    countList.map((value) => {
      recordCount += getVailablePersonRecordCount(value.subData)
      return []
    })
    return recordCount
  }

  // 獲取每個分判下面全部的人的總數New
  const getVailableVendorRecordCountNew = (countList) => {
    let recordCount = 0
    countList.map((value) => {
      recordCount += getVailablePersonRecordCount(value.subData)
      return []
    })
    return recordCount
  }

  // 獲取每個分判下面總入息總數
  const getVailableVendorIncomeCount = (countList) => {
    let recordCount = 0
    countList.map((value) => {
      recordCount += getPersonTotalIncom(value.subData)
      return []
    })
    return recordCount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  // 獲取每個分判下面總入息總數New
  const getVailableVendorIncomeCountNew = (countList) => {
    let recordCount = 0
    countList.map((value) => {
      recordCount += getPersonTotalIncomNew(value.subDataImcome)
      return []
    })
    return recordCount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  // 獲取全部分判下面總入息總數
  const getVailableAllVendorIncomeCount = (mainData) => {
    let recordCount = 0
    mainData.map((mainDataValue) => {
      mainDataValue.coutList.map((countListValue) => {
        recordCount += getPersonTotalIncom(countListValue.subData)
        return []
      })
      return []
    })

    return recordCount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  // 獲取全部分判下面總入息總數New
  const getVailableAllVendorIncomeCountNew = (mainData) => {
    let recordCount = 0
    mainData.map((mainDataValue) => {
      mainDataValue.coutList.map((countListValue) => {
        recordCount += getPersonTotalIncomNew(countListValue.subDataImcome)
        return []
      })
      return []
    })

    return recordCount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  // 打印
  const handlePrint = async () => {
    try {
      const content = contentRef.current
      // const width = content.clientWidth;
      const height = content.clientHeight
      contentRef.scrollLeft = 0
      const scale = 0.4
      if (content) {
        const canvas = await html2canvas(content, { scale: scale })
        const imgData = canvas.toDataURL('image/png')

        // 创建一个新窗口用于打印
        const printWindow = window.open('', '', 'width=600,height=600')
        printWindow.document.open()
        printWindow.document.write('<img src="' + imgData + '" />')
        printWindow.document.close()

        // 等待图像加载完成后再执行打印
        printWindow.onload = function () {
          printWindow.print()
          printWindow.close()
        }
      }
    } catch (error) {
      console.error('打印出错：', error)
    }
  }

  return (
    <Container maxWidth={false}>
      <div style={{ display: 'block', textAlign: 'left' }}>
        <Button sx={{ float: 'left' }} onClick={handleBack}>
          <ArrowBackIcon />
          {t('button_back')}
        </Button>
      </div>

      <div style={{ display: 'block', textAlign: 'right' }}>
        <Button
          sx={{ float: 'right', mr: '20px' }}
          onClick={() =>
            exportPDF({
              siteId: searchParams.get('siteId'),
              siteDesc: searchParams.get('siteDesc'),
              smartCardId: searchParams.get('smartCardId'),
              subContractorId: searchParams.get('subContractorId'),
              subContractorDesc: searchParams.get('subContractorDesc'),
              dateFrom: searchParams.get('accessDateFrom'),
              dateTo: searchParams.get('accessDateTo'),
              vendorId: dataSource.vendorId,
              vendorDesc: dataSource.vendorDesc,
            })
          }
        >
          <DownloadIcon />
          {t('button_export_pdf')}
        </Button>
        <Button
          sx={{ float: 'right', mr: '20px' }}
          onClick={() =>
            exportExcel({
              siteId: searchParams.get('siteId'),
              siteDesc: searchParams.get('siteDesc'),
              smartCardId: searchParams.get('smartCardId'),
              subContractorId: searchParams.get('subContractorId'),
              subContractorDesc: searchParams.get('subContractorDesc'),
              dateFrom: searchParams.get('accessDateFrom'),
              dateTo: searchParams.get('accessDateTo'),
              vendorId: dataSource.vendorId,
              vendorDesc: dataSource.vendorDesc,
            })
          }
        >
          <DownloadIcon />
          {t('button_export_excel')}
        </Button>
        <Button
          sx={{ float: 'right', mr: '20px' }}
          //  onClick={() => handlePrint()}
          onClick={() =>
            exportPDF({
              siteId: searchParams.get('siteId'),
              siteDesc: searchParams.get('siteDesc'),
              smartCardId: searchParams.get('smartCardId'),
              subContractorId: searchParams.get('subContractorId'),
              subContractorDesc: searchParams.get('subContractorDesc'),
              dateFrom: searchParams.get('accessDateFrom'),
              dateTo: searchParams.get('accessDateTo'),
              vendorId: dataSource.vendorId,
              vendorDesc: dataSource.vendorDesc,
            })
          }
        >
          <DownloadIcon />
          {t('列印')}
        </Button>
      </div>

      <div id="div" style={{}} ref={contentRef}>
        <Container maxWidth={false}>
          <div>
            <div style={{ display: 'block', marginBottom: '60px' }}>
              <Typography variant="h4" sx={{ textAlign: 'center' }}>
                <b>{t('search_incomeAndRelax_chat')}</b>
              </Typography>
            </div>

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>

            {
              <div>
                <TableContainer component={Paper} sx={{ marginBottom: '20px', backgroundColor: 'transparent' }}>
                  <Table sx={{ textAlign: 'left' }} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('search_company_name')}</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{''}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', width: 200, border: 0 }}>{t('Vendor') + '：'}</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{dataSource.vendorId + '-' + dataSource.vendorDesc}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('search_form_date_dot')}</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{searchParams.get('accessDateFrom').replace('-', '/').replace('-', '/')}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ px: '5px', width: 160, border: 0 }}>{t('search_to_date_dot')}</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{searchParams.get('accessDateTo').replace('-', '/').replace('-', '/')}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('Site_Name') + '：'}</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{!searchParams.get('siteDesc') ? 'All' : searchParams.get('siteDesc')}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('search_name_of_vendor')}</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{!searchParams.get('subContractorDesc') ? 'All' : searchParams.get('subContractorDesc')}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }
          </div>

          {/* table */}
          <TableContainer sx={{ marginTop: '20px', maxHeight: '80vh', maxWidth: '100vw' }}>
            <Table sx={{ p: '2px' }} size="small" stickyHeader>
              <TableHead>
                <TableRow hover>
                  {/* (siteName, carid, engName, chName, joy, approveDate, inDate, inVateDate, applyDate */}
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>序號</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('search_name_title')}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('search_smartCardId_notDot')}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('search_type_title')}</TableCell>

                  {recordDays.map((dayDesc, index) => (
                    <TableCell key={index + Math.random} sx={{ fontSize: '15px', textAlign: 'left' }}>
                      {dayDesc}
                    </TableCell>
                  ))}

                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('search_totalDays')}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('search_total_incom')}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('icon1_autopay_button7_label6')}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('search_emplor_mpf')}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('search_emplee_mpf')}</TableCell>
                </TableRow>
              </TableHead>

              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>

              {mainData.map((mainDataRow, index) => (
                <>
                  <TableRow hover key={index + Math.random}>
                    <TableCell colSpan={recordDays.length + 3} sx={{ fontSize: '15px', textAlign: 'left' }}>
                      {mainDataRow.employerName}{' '}
                    </TableCell>
                  </TableRow>

                  {/* test start */}
                  {mainDataRow.coutList.map((coutListRow, coutListRowIndex) => (
                    <>
                      {/* 第一行數據（一個人兩行數據） */}
                      {/* 單個人數據，需要循環遍歷 */}
                      <TableRow
                        hover
                        key={coutListRowIndex + Math.random}
                        sx={{
                          backgroundColor: coutListRowIndex % 2 !== 0 ? '#DCDCDC' : '#ffffff',
                        }}
                      >
                        {/* 序號 */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{coutListRowIndex + 1} </TableCell>
                        {/* 名字 */}
                        <TableCell
                          sx={{
                            fontSize: '15px',
                            textAlign: 'left',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {coutListRow.chineseName}{' '}
                        </TableCell>
                        {/* 智能卡編號 */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{coutListRow.smartCardId} </TableCell>
                        {/* 類型 */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>In out record </TableCell>
                        {/* 每天的数据 */}
                        {recordDates.map((recordDatesRow, recordDatesRowIndex) => (
                          <TableCell key={recordDatesRowIndex + Math.random} sx={{ fontSize: '15px', textAlign: 'left' }}>
                            {getRecord(coutListRow.subData, recordDatesRow)}
                          </TableCell>
                        ))}
                        {/* 总日数 */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{getVailablePersonRecordCount(coutListRow.subData)}</TableCell>
                        {/* 總入息 */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{}</TableCell>
                        {/* 登記平均入息 */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{}</TableCell>
                        {/* 僱主MPF */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{}</TableCell>
                        {/* 僱員MPF */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{}</TableCell>
                      </TableRow>

                      {/* 第二行數據（一個人兩行） */}
                      {/* 單個人數據，需要循環遍歷 */}
                      <TableRow
                        hover
                        key={coutListRowIndex + Math.random + 99}
                        sx={{
                          backgroundColor: coutListRowIndex % 2 !== 0 ? '#DCDCDC' : '#ffffff',
                        }}
                      >
                        {/* 序號 */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{} </TableCell>
                        {/* 名字 */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{coutListRow.chineseName} </TableCell>
                        {/* 智能卡編號 */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{coutListRow.smartCardId} </TableCell>
                        {/* 類型 */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>Salary </TableCell>
                        {/* 每天的入息数据 */}
                        {recordDates.map((recordDatesRow, recordDatesRowIndex) => (
                          <TableCell key={recordDatesRowIndex + Math.random + 88} sx={{ fontSize: '15px', textAlign: 'left' }}>
                            {getRecordIncomeNew(coutListRow.subDataImcome, recordDatesRow)}
                          </TableCell>
                        ))}
                        {/* 总日数 */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{}</TableCell>
                        {/* 總入息 */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                          {getPersonTotalIncomNew(coutListRow.subDataImcome) === 0
                            ? ''
                            : getPersonTotalIncomNew(coutListRow.subDataImcome)
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        </TableCell>
                        {/* 登記平均入息 */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                          {getAverageRecordIncome(coutListRow.subData)
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        </TableCell>
                        {/* 僱主MPF */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                          {getEmployerMPFDataNew(coutListRow.subDataImcome) === 0
                            ? ''
                            : getEmployerMPFDataNew(coutListRow.subDataImcome)
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        </TableCell>
                        {/* 僱員MPF */}
                        <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                          {getEmployeeMPFDataNew(coutListRow.subDataImcome) === 0
                            ? ''
                            : getEmployeeMPFDataNew(coutListRow.subDataImcome)
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}

                  {/* test end */}

                  {/* 总数 */}
                  {/* test start two */}
                  <TableRow hover key={index + Math.random + 3}>
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{}</TableCell>
                    {/* colSpan表示合併單元格 */}
                    <TableCell colSpan={recordDays.length + 3} sx={{ fontSize: '15px', textAlign: 'left' }}>
                      {t('search_totals_title')}
                    </TableCell>

                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{getVailableVendorRecordCount(mainDataRow.coutList)} </TableCell>

                    {/* 總入息的小總數 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{getVailableVendorIncomeCountNew(mainDataRow.coutList)} </TableCell>
                    {/* 平均入息總數 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{} </TableCell>
                    {/* 僱主MPF的小總數 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                      {getAllEmployerMPFDataNew(mainDataRow.coutList)
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}{' '}
                    </TableCell>
                    {/* 僱員MPF的小總數 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                      {getAllEmployeeMPFDataNew(mainDataRow.coutList)
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}{' '}
                    </TableCell>
                  </TableRow>

                  {/* test end two */}

                  <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{}</TableCell>
                </>
              ))}

              {/* 最后的总数 */}
              {/* test start three */}
              <TableRow hover>
                <TableCell colSpan={4} sx={{ fontSize: '15px', textAlign: 'left' }}>
                  {t('search_totals_title')}
                </TableCell>
                {/* 每天的總数据 */}
                {recordDates.map((recordDatesRow, index) => (
                  <TableCell key={index + Math.random} sx={{ fontSize: '15px', textAlign: 'left' }}>
                    {getEveryDaysTotalRecord(mainData, recordDatesRow, true)}
                  </TableCell>
                ))}
                {/* 每天總數據的和 */}
                <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{getEveryDaysTotalRecord(mainData, null, false)}</TableCell>
                {/* 總入息的大總數 */}
                <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{getVailableAllVendorIncomeCountNew(mainData)} </TableCell>
                {/* 平均入息大總數 */}
                <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{} </TableCell>
                {/* 僱主MPF的大總數 */}
                <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                  {getAllVendorEmployerMPFDataNew(mainData)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}{' '}
                </TableCell>
                {/* 僱員MPF的大總數 */}
                <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                  {getAllVendorEmployeeMPFDataNew(mainData)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}{' '}
                </TableCell>
              </TableRow>

              {/* test end three */}

              {/* 承判商工人總數： */}
              <TableRow hover>
                {/* colSpan表示合併單元格 */}
                <TableCell colSpan={recordDays.length + 3} sx={{ fontSize: '15px', textAlign: 'left' }}>
                  {t('search_total_vendors')}
                  {getTotalPersonCount(mainData)}{' '}
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
          {
            <div>
              <Typography sx={{ textAlign: 'left', marginTop: '60px' }}>
                數字 (小時:分鐘) - 工人當天最先入閘時間及最後出閘時間的差額
                <br />
                "M" - 工人出入閘記錄不正確
                <br />
                " " - 當天沒有出入閘記錄
                <br />
              </Typography>
            </div>
          }
        </Container>
      </div>
    </Container>
  )
}
