/* eslint-disable react/style-prop-object */
import { React, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Collapse,
  Stack,
  Container,
  Grid,
  FormControl,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  MenuItem,
  TableFooter,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormLabel,
  Checkbox,
  CardHeader,
  Card,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  ListItemButton,
  AccordionDetails,
  Box,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";

import moment from "moment";
import CustomDialog from "src/components/common/CustomDialog";
import TransferList from "src/components/icon/icon1/autoPay/icon3/roleSetupIndex/TransferList";

import { color } from "echarts";
import * as api from "../../../utils/api/systemAdministrator/RoleSetupApi";

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function RoleSetupIndex() {
  // 國際化
  const { t } = useTranslation();

  const [backOpen, setBackOpen] = useState(false);
  const dialogAction = (
    showCancelButton,
    handleConfirmDialog,
    handleCancelDialog
  ) => {
    if (showCancelButton) {
      return (
        <>
          <Button variant="contained" onClick={handleConfirmDialog}>
            {t("Confirm")}
          </Button>
          <Button variant="contained" onClick={handleCancelDialog}>
            {t("button_cancel")}
          </Button>
        </>
      );
    } else {
      return (
        <Button variant="contained" onClick={handleConfirmDialog}>
          {t("Confirm")}
        </Button>
      );
    }
  };
  //
  const [isSearchPage, setIsSearchPage] = useState(true);

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([1, 2, 3]);
  const [right, setRight] = useState([]);
  const [data, setData] = useState([]);
  const [roleData, setRoleData] = useState({});
  const [dataConvert, setDataConvert] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [rowData, setRowData] = useState({});

  const [dialogOpenAddMenu, setDialogOpenAddMenu] = useState(false);
  const [newOrAddType, setNewOrAddType] = useState(false);

  const [sendData, setSendData] = useState({});
  const [newTitle, setNewTitle] = useState("");
  const [tableData, setTableData] = useState({});
  const [reload, setReload] = useState(false);
  const [dialog, setDialog] = useState({
    title: "",
    content: "",
    action: dialogAction(
      false,
      () => {
        setDialog({
          ...dialog,
          open: false,
        });
      },
      null
    ),
    open: false,
  });
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const getData = async () => {
      setBackOpen(true);
      let send = await api.roleSetupView();
      let send2 = await api.menuDataByParentId();

      if (send) {
        setTableData(send.data);
      }

      if (send2) {
        setData(send2.data);
      }
      setBackOpen(false);
    };
    getData();
  }, [reload]);

  const handleBack = () => {
    setIsSearchPage(true);
  };

  const exportExcel = () => {};

  const handleEnableFlag = (e) => {
    setSendData({ ...sendData, enableFlag: e.target.value });
  };

  const handleRoleId = (e) => {
    setSendData({ ...sendData, roleId: e.target.value });
  };

  const handleRoleCode = (e) => {
    setSendData({ ...sendData, roleCode: e.target.value });
  };

  const handleEnDesc = (e) => {
    setSendData({ ...sendData, roleDescEn: e.target.value });
  };

  const handleCnDesc = (e) => {
    setSendData({ ...sendData, roleDescCn: e.target.value });
  };
  const handleClick = () => {
    setOpen(!open);
  };

  function deleteData1(row) {
    setDialog({
      ...dialog,
      open: true,
      content: t("confirm_deletion"),
      action: dialogAction(
        true,
        () => {
          let send = api.roleSetupDelete(row);
          if (send) {
            setDialog({
              open: false,
            });
            setReload(!reload);
          }
        },
        () => {
          setDialog({
            ...dialog,
            open: false,
          });
        }
      ),
    });
  }

  //
  const handleAdd = (e) => {
    if (!sendData.roleCode) {
      setDialog({
        ...dialog,
        content: t("please_enter_the_role_code"),
        open: true,
        action: dialogAction(
          false,
          () => {
            setSendData({ ...sendData });
            handleOpenDialogAdd(t("new"));
            setDialog({
              ...dialog,
              open: false,
            });
          },
          null
        ),
      });
      return;
    }
    if (!sendData.roleDescEn) {
      setDialog({
        ...dialog,
        content: t("please_enter_a_role_description_Chinese"),
        open: true,
        action: dialogAction(
          false,
          () => {
            setSendData({ ...sendData });
            handleOpenDialogAdd(t("new"));
            setDialog({
              ...dialog,
              open: false,
            });
          },
          null
        ),
      });
      return;
    }
    if (!sendData.roleDescCn) {
      setDialog({
        ...dialog,
        content: t("please_enter_a_role_description_english"),
        open: true,
        action: dialogAction(
          false,
          () => {
            setSendData({ ...sendData });
            handleOpenDialogAdd(t("new"));
            setDialog({
              ...dialog,
              open: false,
            });
          },
          null
        ),
      });
      return;
    }
    //
    setDialog({
      ...dialog,
      open: true,
      content: t("sure_save"),
      action: dialogAction(
        true,
        () => {
          const sendSave = async () => {
            setBackOpen(true);
            let send;

            send = await api.roleSetupAdd(sendData);

            if (send.code === 200) {
              setDialog({
                ...dialog,
                content: t("created_successfully"),
                open: true,
                action: dialogAction(
                  false,
                  () => {
                    setDialog({
                      ...dialog,
                      open: false,
                    });
                    setRowData({ ...rowData, open: false });
                    setTableData(send);
                    setReload(!reload);
                    setSendData({});
                  },
                  null
                ),
              });
              return [];
            } else {
              setDialog({
                ...dialog,
                content: send.message,
                open: true,
              });
              setReload(!reload);
              return [];
            }
          };
          sendSave();
        },
        () => {
          setDialog({
            ...dialog,
            open: false,
          });
        }
      ),
    });
  };
  //

  const handleOpenDialog = (row, title) => {
    console.log("row", row);
    setSendData({ ...row });
    setNewTitle(title);
    setDialogOpen(true);
    setRowData(row);
    setNewOrAddType(false);
  };

  const handleOpenDialogAdd = (title) => {
    setNewTitle(title);
    setDialogOpen(true);
    setNewOrAddType(true);
  };

  const handleOpenDialogAddChildMenu = async (row, title) => {
    setBackOpen(true);
    let send3 = await api.menuDataCovert(row);
    setBackOpen(false);
    if (send3) {
      for (let value of send3.data.inList) {
        value.open = false;
      }
      for (let item of send3.data.notInList) {
        item.open = false;
      }
      setDataConvert(send3.data);
      setSendData({ ...send3.data });
      setNewTitle(title);
      setDialogOpenAddMenu(true);
      setRowData(send3);
      setRoleData(row);
    }
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={""}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value.menuId}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value.menuId)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value.menuId) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.menuDescCn}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  const deleteData = (row) => {
    setDialog({
      ...dialog,
      open: true,
      content: t("confirm_deletion"),
      action: dialogAction(
        true,

        () => {
          const deleteData = async () => {
            let send;
            send = await api.roleSetupDelete(row);
            if (send.code === 200) {
              setDialog({
                content: t("deletion_was_successful"),
                open: true,
                action: dialogAction(
                  false,
                  () => {
                    setDialog({
                      ...dialog,
                      open: false,
                    });
                    setBackOpen(false);
                    setReload(!reload);
                  },
                  null
                ),
              });
            } else {
              setDialog({
                ...dialog,
                content: send.message,
                open: true,
              });
              setReload(!reload);
              setBackOpen(false);
              return [];
            }
          };
          deleteData();
        },
        () => {
          setDialog({
            ...dialog,
            open: false,
          });
        }
      ),
    });
  };

  const handleEdit = (e) => {
    if (!sendData.roleCode) {
      setDialog({
        ...dialog,
        content: t("please_enter_the_role_code"),
        open: true,
        action: dialogAction(
          false,
          () => {
            setSendData({ ...sendData });
            handleOpenDialogAdd(t("Revise"));
            setDialog({
              ...dialog,
              open: false,
            });
          },
          null
        ),
      });
      return;
    }

    if (!sendData.roleDescEn) {
      setDialog({
        ...dialog,
        content: t("please_enter_a_role_description_Chinese"),
        open: true,
        action: dialogAction(
          false,
          () => {
            setSendData({ ...sendData });
            handleOpenDialogAdd(t("Revise"));
            setDialog({
              ...dialog,
              open: false,
            });
          },
          null
        ),
      });
      return;
    }
    if (!sendData.roleDescCn) {
      setDialog({
        ...dialog,
        content: t("please_enter_a_role_description_english"),
        open: true,
        action: dialogAction(
          false,
          () => {
            setSendData({ ...sendData });
            handleOpenDialogAdd(t("Revise"));
            setDialog({
              ...dialog,
              open: false,
            });
          },
          null
        ),
      });
      return;
    }
    // let send = api.roleSetupEidte(sendData);
    // if (send) {
    //   setTableData(send);
    //   setReload(!reload);
    // }
    setDialog({
      ...dialog,
      open: true,
      content: t("sure_save"),
      action: dialogAction(
        true,
        () => {
          const sendEdit = async () => {
            setBackOpen(true);
            let send;

            send = await api.roleSetupEidte(sendData);
            if (send.code === 200) {
              setDialog({
                ...dialog,
                content: t("successfully_modified"),
                open: true,
                action: dialogAction(
                  false,
                  () => {
                    setDialog({
                      ...dialog,
                      open: false,
                    });
                    setRowData({ ...rowData, open: false });
                    setTableData(send);
                    setReload(!reload);
                    setSendData({});
                  },
                  null
                ),
              });

              return [];
            } else {
              setDialog({
                ...dialog,
                content: send.message,
                open: true,
              });
              setSendData({});
              setReload(!reload);
              return [];
            }
          };
          sendEdit();
        },
        () => {
          setDialog({
            ...dialog,
            open: false,
          });
        }
      ),
    });
  };

  const handleAddback = async () => {
    if (!sendData.roleCode) {
      setDialog({
        ...dialog,
        content: t("please_enter_the_role_code"),
        open: true,
        action: dialogAction(
          false,
          () => {
            setSendData({ ...sendData });
            handleOpenDialogAdd(t("new"));
            setDialog({
              ...dialog,
              open: false,
            });
          },
          null
        ),
      });
      return;
    }
    if (!sendData.roleDescEn) {
      setDialog({
        ...dialog,
        content: t("please_enter_a_role_description_Chinese"),
        open: true,
        action: dialogAction(
          false,
          () => {
            setSendData({ ...sendData });
            handleOpenDialogAdd(t("new"));
            setDialog({
              ...dialog,
              open: false,
            });
          },
          null
        ),
      });
      return;
    }
    if (!sendData.roleDescCn) {
      setDialog({
        ...dialog,
        content: t("please_enter_a_role_description_english"),
        open: true,
        action: dialogAction(
          false,
          () => {
            setSendData({ ...sendData });
            handleOpenDialogAdd(t("new"));
            setDialog({
              ...dialog,
              open: false,
            });
          },
          null
        ),
      });
      return;
    }

    let send = await api.roleSetupAdd(sendData);

    if (send.code === 200) {
      setDialog({
        ...dialog,
        content: t("created_successfully"),
        open: true,
        action: dialogAction(
          false,
          () => {
            setDialog({
              ...dialog,
              open: false,
            });
            setRowData({ ...rowData, open: false });
            setTableData(send);
            setReload(!reload);
          },
          null
        ),
      });
      return [];
    } else {
      setDialog({
        ...dialog,
        content: send.message,
        open: true,
      });
      console.log(send.message);
      return [];
    }
  };

  const [checked1, setChecked1] = useState([true, false]);

  const handleChange2 = (event) => {
    setChecked1([event.target.checked1, checked1[1]]);
  };
  const handleChange3 = (event) => {
    setChecked1([checked1[0], event.target.checked1]);
  };

  const handleChange1 = (event) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const children = (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      <FormControlLabel
        label="Child 6"
        control={<Checkbox checked={checked1[0]} onChange={handleChange2} />}
      />
      <FormControlLabel
        label="Child 2"
        control={<Checkbox checked={checked[1]} onChange={handleChange3} />}
      />
    </Box>
  );

  return (
    <>
      <CustomDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        action={dialog.action}
      />
      <Backdrop sx={{ color: "#fff", zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="mx">
        <div>
          <Typography variant="h4" sx={{ textAlign: "center", my: "10px" }}>
            <b>{t("Role_Setup")}</b>
          </Typography>
          <Typography variant="h6" sx={{ textAlign: "left", my: "6px" }}>
            <Button
              onClick={() => handleOpenDialogAdd(t("new"))}
              color="secondary"
              variant="outlined"
            >
              {t("new")}
            </Button>
          </Typography>

          <TableContainer>
            <Table size="small" sx={{ mt: "20px" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                      textAlign: "left",
                    }}
                  >
                    {t("ROLE_ID")}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                      textAlign: "left",
                    }}
                  >
                    {t("ROLE_CODE")}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                      textAlign: "left",
                    }}
                  >
                    {t("ROLE_DESC_EN")}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                      textAlign: "left",
                    }}
                  >
                    {t("ROLE_DESC_CN")}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                      textAlign: "left",
                    }}
                  >
                    {t("ENABLE_FLAG")}
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    {t("operate")}
                  </TableCell>
                </TableRow>
              </TableHead>
              {!!tableData && tableData.length > 0 && (
                <TableBody>
                  {tableData.map((row, ind) => (
                    <TableRow hover key={row.roleId}>
                      <TableCell sx={{ textAlign: "left" }}>
                        {row.roleId}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {row.roleCode}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {row.roleDescEn}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {row.roleDescCn}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {row.enableFlag}
                      </TableCell>
                      {/* <TableCell sx={{ textAlign: "center" }} >
                                            </TableCell>

                                            <TableCell sx={{ textAlign: "center" }} >
                                            </TableCell> */}

                      <TableCell sx={{ textAlign: "center" }}>
                        <Stack
                          spacing={2}
                          direction="row"
                          justifyContent="center"
                        >
                          <Button
                            size="small"
                            onClick={() => handleOpenDialog(row, t("Revise"))}
                            variant="outlined"
                            color="secondary"
                          >
                            {t("Revise")}
                          </Button>
                          <Button
                            size="small"
                            onClick={() =>
                              handleOpenDialogAddChildMenu(row, t("add"))
                            }
                            variant="outlined"
                            color="success"
                          >
                            {t("add_menu")}
                          </Button>
                          <Button
                            size="small"
                            onClick={() => deleteData(row)}
                            variant="outlined"
                            color="error"
                          >
                            {t("delete")}
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
              {(!tableData || tableData.length === 0) && (
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ textAlign: "center" }} colSpan={10}>
                      {"No data found !"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      </Container>

      <Dialog open={dialogOpen} maxWidth="xs">
        <DialogTitle>{newTitle}</DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            value={sendData.roleCode || ""}
            sx={{ my: "10px" }}
            fullWidth
            size="small"
            label={t("ROLE_CODE")}
            onChange={handleRoleCode}
          />
          <TextField
            value={sendData.roleDescEn || ""}
            sx={{ my: "10px" }}
            fullWidth
            size="small"
            label={t("ROLE_DESC_EN")}
            onChange={handleEnDesc}
          />
          <TextField
            value={sendData.roleDescCn || ""}
            sx={{ my: "10px" }}
            fullWidth
            size="small"
            label={t("ROLE_DESC_CN")}
            onChange={handleCnDesc}
          />
          <FormControl>
            <FormLabel component="legend"> {t("ENABLE_FLAG")}</FormLabel>
            <RadioGroup
              defaultValue={"Y"}
              value={sendData.enableFlag}
              row
              onChange={handleEnableFlag}
            >
              <FormControlLabel label="Y" value="Y" control={<Radio />} />
              <FormControlLabel label="N" value="N" control={<Radio />} />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={() => {
              if (newOrAddType === false) {
                handleEdit();
              } else {
                handleAdd();
              }
              setDialogOpen(false);
            }}
          >
            {t("Confirm")}
          </Button>

          <Button
            onClick={() => {
              setDialogOpen(false);
            }}
          >
            {t("button_cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogOpenAddMenu} fullWidth maxWidth="md">
        <DialogTitle>{t("add_menu")}</DialogTitle>
        <Divider />
        <DialogContent>
          <TransferList
            setDialog={setDialog}
            dialog={dialog}
            data={dataConvert}
            setDataConvert={setDataConvert}
            rowData={roleData}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpenAddMenu(false);
            }}
          >
            {t("button_cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
