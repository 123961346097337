import React, { useState, useRef, useEffect } from 'react'
import { Container, Grid, Typography, MenuItem, TextField, FormControl, Backdrop, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, createSearchParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useForm } from 'react-hook-form'
import CustomDialog from 'src/components/common/CustomDialog'
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'

export default function CattpwspPerQuarterly() {
  const navigate = useNavigate()
  const componentRef = useRef()
  const { t } = useTranslation()

  const [siteId, setSiteId] = useState('')
  const [siteDesc, setSiteDesc] = useState('')
  const [vendorId, setVendorId] = useState('')
  const [vendorDesc, setVendorDesc] = useState('')
  const [tradeId, setTradeId] = useState('')
  const [tradeDesc, setTradeDesc] = useState('')
  const [dataYear, setDataYear] = useState('')

  const [loading, setLoading] = React.useState(false)
  //
  const [siteList, setSiteList] = useState([])
  const [vendorList, setVendorList] = useState([])
  const [fillJobNatureList, setFillJobNatureList] = useState([])
  const [fillYearList, setFillYearList] = useState([])

  const [verify, setVerify] = useState({
    shortPeriodDateFrom: false,
    shortPeriodDateTo: false,
  })

  useEffect(() => {}, [])

  const goDetail = (event) => {
    console.log(1)
  }

  const handleBack = () => {
    navigate(-1)
  }

  const { control, handleSubmit, setValue, getValues, reset } = useForm({
    defaultValues: {
      subContract: '',
      dateFrom: '',
      dateTo: '',
      outputType: '',
    },
  })

  const submitForm = (data) => {}

  const resetForm = (data) => {
    setSiteId('')
    setSiteDesc('')
    setDataYear('')
    setVendorId('')
    setVendorDesc('')
    setTradeId('')
    setTradeDesc('')
  }

  const [dialog, setDialog] = useState({
    title: '',
    content: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        {t('Confirm')}
      </Button>
    ),
    open: false,
  })

  const showDetailResult = (data) => {
    if (!siteId) {
      setDialog({
        ...dialog,
        content: t('search_chooseSite_tips'),
        open: true,
      })
      return
    }

    if (!dataYear) {
      setDialog({
        ...dialog,
        content: t('search_chooseYear_tips'),
        open: true,
      })
      return
    }

    navigate({
      pathname: '/vendorAutopaySystem/cattpwspPerQuarterlyDetail',
      search: `?${createSearchParams(
        {
          siteId: siteId,
          siteDesc: siteDesc,
          vendorId: vendorId,
          vendorDesc: vendorDesc,
          tradeId: tradeId,
          tradeDesc: tradeDesc,
          dataYear: dataYear,
        },
        { state: { fromSpecificPage: true } },
      )}`,
    })
  }

  // 选了地盘后，需要请求分判商
  const handleSiteId = (data) => {
    setSiteId(data.id)
    setSiteDesc(data.text)
  }

  const handleVendorId = (data) => {
    setVendorId(data.id)
    setVendorDesc(data.text)
  }

  const handleTradeId = (data) => {
    setTradeId(data.id)
    setTradeDesc(data.text)
  }

  const handeDataYearId = (data) => {
    setDataYear(data.id)
  }

  useEffect(() => {
    setLoading(true)

    const getFillSite = async () => {
      let getFillSiteSelect = await apiAtt.getNewSite()
      if (getFillSiteSelect) {
        const result = getFillSiteSelect
        setSiteList(result)
      }
    }

    // 獲取承判商
    const getvendorId = async () => {
      let getvendorIdSelect = await apiAtt.getvendorId()
      if (getvendorIdSelect) {
        const result = getvendorIdSelect
        setVendorList(result)
      }
    }

    // 獲取行頭
    const getfilljobNatureId = async () => {
      let getfilljobNatureIdSelect = await apiAtt.getfilljobNatureId()
      if (getfilljobNatureIdSelect) {
        const result = getfilljobNatureIdSelect
        setFillJobNatureList(result)
      }
    }

    // 獲取年份
    const getfillYear = async () => {
      let getfillYearSelect = await apiAtt.getfillYear()
      if (getfillYearSelect) {
        const result = getfillYearSelect
        setFillYearList(result)
      }
    }

    getFillSite()
    getvendorId()
    getfilljobNatureId()
    getfillYear()
    setLoading(false)
  }, [])

  return (
    <>
      {
        <>
          <div style={{ display: 'block', textAlign: 'left' }}>
            <Button sx={{ float: 'left' }} onClick={handleBack}>
              <ArrowBackIcon />
              {t('button_back')}
            </Button>
          </div>
          <div id="div" ref={componentRef}>
            <Container>
              <div>
                <div style={{ display: 'block' }}>
                  <Typography variant="h4" style={{ color: 'black', textAlign: 'center' }}>
                    <b>{t('search_vendorPerQuarterly_title')}</b>
                  </Typography>

                  <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                  </Backdrop>

                  <Container sx={{ borderColor: '#d3d3d3', borderRadius: 4 }}>
                    <Grid item xs={6} sx={{ margin: 'auto', width: '400px', marginTop: '10px' }}>
                      <TextField error={!siteId} id="siteDesc" value={siteId || ''} fullWidth size="small" label={t('Site_Name')} select>
                        {siteList.map((item, index) => (
                          <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleSiteId(item)}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={6} sx={{ margin: 'auto', width: '400px', marginTop: '10px' }}>
                      <TextField id="vendorId" value={vendorId || ''} fullWidth size="small" label={t('Vendor')} select>
                        {vendorList.map((item, index) => (
                          <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleVendorId(item)}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={6} sx={{ margin: 'auto', width: '400px', marginTop: '10px' }}>
                      <TextField id="subContractId" value={tradeId || ''} fullWidth size="small" label={t('search_trade_title')} select>
                        {fillJobNatureList.map((item, index) => (
                          <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleTradeId(item)}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={6} sx={{ margin: 'auto', width: '400px', marginBottom: '20px', marginTop: '10px' }}>
                      <TextField id="subContractId" error={!dataYear} value={dataYear || ''} fullWidth size="small" label={t('search_year_title')} select>
                        {fillYearList.map((item, index) => (
                          <MenuItem key={index} value={item.id} name={item.text} onClick={() => handeDataYearId(item)}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Container>
                </div>
              </div>

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '18px',
                }}
              >
                {/* 提交按钮 */}
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <FormControl size="small">
                    <Button
                      sx={{
                        backgroundColor: 'primaryBlue',
                        color: 'commom.white',
                      }}
                      variant="contained"
                      onClick={handleSubmit(showDetailResult)}
                    >
                      {t('button_submit')}
                    </Button>
                  </FormControl>

                  {/* 重置按钮 */}
                  <FormControl size="small">
                    <Button
                      variant="contained"
                      onClick={resetForm}
                      label="Reset"
                      sx={{
                        backgroundColor: 'primaryGreen',
                        color: 'commom.white',
                        marginLeft: '20px',
                      }}
                    >
                      {t('button_reset')}
                    </Button>
                  </FormControl>
                </Grid>
              </div>
            </Container>
          </div>
        </>
      }

      <CustomDialog open={dialog.open} title={dialog.title} content={dialog.content} action={dialog.action} />
    </>
  )
}
