import React from 'react'
import PropTypes from 'prop-types'
// @mui
import moment from 'moment'
import ITPolicyForm from 'src/layouts/dashboard/header/ITPolicyForm'

import { AppBar, Box, Button, Stack, Toolbar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

// utils
import { t } from 'i18next'
import { CDialog } from 'src/components/common'
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify'
import ToggleButton from '@mui/material/ToggleButton'
import { bgBlur } from '../../../utils/cssStyles'
// components
//
import Logo from '../../../components/logo'
import AccountPopover from './AccountPopover'
import LanguagePopover from './LanguagePopover'

// ----------------------------------------------------------------------

const HEADER_MOBILE = 64

const HEADER_DESKTOP = 45

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  // boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    // width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    width: '100%',
  },
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.primaryBackgroundColor,
  height: HEADER_MOBILE,
  padding: '0 !important',
  [theme.breakpoints.up('lg')]: {
    height: HEADER_DESKTOP,
  },
  [theme.breakpoints.up('xl')]: {
    height: HEADER_DESKTOP,
  },
}))

// ----------------------------------------------------------------------

Header.propTypes = {
  handelDevice: PropTypes.func,
}

export default function Header(props) {
  const { user, handelDevice } = props
  const [time, setTime] = React.useState()
  // const [refreshTime, setRefreshTime] = React.useState()

  React.useEffect(() => {
    const expTime = localStorage.getItem('expTime')
    const tick = setInterval(() => {
      if (expTime) {
        let bet = moment(expTime * 1000)
        bet = formatTime(bet.diff(moment(new Date()), 'seconds'))
        setTime(bet)
      }
    }, 1000)

    return () => clearInterval(tick)
  })
  // 转换时分秒
  const formatTime = (seconds) => {
    let hours = Math.floor(seconds / 3600)

    seconds %= 3600

    let minutes = Math.floor(seconds / 60)

    seconds %= 60

    return `${hours}:${padNumber(minutes)}:${padNumber(seconds)}`
  }

  const padNumber = (num) => {
    return num.toString().padStart(2, '0')
  }

  function handleChange(value) {
    // setOptionValue(value)
    handelDevice(value)
  }

  const [messageInformation, setMessageInformation] = React.useState(false)

  return (
    <StyledRoot>
      <StyledToolbar>
        <ToggleButton color="standard" onClick={() => handleChange()} value="left" aria-label="module" sx={{ marginBottom: '2px', color: '#F0FFFF', padding: '21px' }}>
          <FormatAlignJustifyIcon />
        </ToggleButton>
        <Box>
          <Logo />
        </Box>
        <Typography variant="h6" sx={{ display: { xs: 'none', lg: 'block' }, mx: '10px' }}>
          <b>{user.vendorDesc || ''}</b>
        </Typography>
        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />
        <Button
          variant="h5"
          sx={{ display: { xs: 'none', lg: 'block' }, mx: '25px' }}
          onClick={() => {
            setMessageInformation(true)
          }}
        >
          <Typography sx={{ display: { xs: 'none', lg: 'block' }, mx: '10px' }}>{t('it_security_policy')}</Typography>
        </Button>
        <p>{time}</p>
        <Box sx={{ flexGrow: 0.05 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1 }}>
          <LanguagePopover />
          {/* <NotificationsPopover /> */}
          <AccountPopover user={user} handelDevice={handelDevice} />
        </Stack>
      </StyledToolbar>

      <CDialog title={t('it_security_policy')} open={messageInformation} onClose={() => setMessageInformation(false)} PaperProps={{ sx: { zIndex: 2000 } }} sx={{ zIndex: 2000 }}>
        <ITPolicyForm />
      </CDialog>
    </StyledRoot>
  )
}
