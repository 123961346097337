import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    Collapse,
    Container,
    FormControl,
    Grid,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TablePagination,
    IconButton,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
import * as api from "../../../utils/api/systemAdministrator/SystemParameterApi";
import CustomDialog from "../../../components/common/CustomDialog";
import CDatePicker from "../../../components/common/CDatePicker";
import SystemParameterSetup from "./SystemParameterSetup";


function SystemParameter() {
    // 國際化
    const { t } = useTranslation();
    // 搜索框开关
    const [searchOpen, setSearchOpen] = React.useState(true);
    // 搜索表单
    const [searchForm, setSearchForm] = React.useState({
        id: '',
        description: '',
    });
    // 搜索按钮loading
    const [openLoading, setOpenLoading] = React.useState(false);
    // 表格数据
    const [rows, setRows] = React.useState([]);
    // 表格内容开关
    const [detailsOpen, setDetailsOpen] = React.useState(false);
    // 表頭
    const columns = [
        { id: 'id', label: t('ID'), minWidth: 200, align: 'left' },
        { id: 'description', label: t('Description'), minWidth: 200, align: 'left' },
        { id: 'dataType', label: t('Data_Type'), minWidth: 50, align: 'left' },
        { id: 'value', label: t('Value'), minWidth: 50, align: 'center' },
        { id: 'action', label: t('Action'), minWidth: 50, align: 'center' },
    ];
    // 頁數
    const [page, setPage] = React.useState(0);
    // 每頁數量
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    // 行数据详情
    const [tableRowDetail, setTableRowDetail] = React.useState({
        id: '',
        description: '',
        dataType: '',
        effectiveDate: null,
        value: '',
    });
    // 行数据禁用详情
    const [tableRowDetailDisable, setTableRowDetailDisable] = React.useState({
        id: false,
        description: false,
        dataType: false,
        effectiveDate: false,
        value: false,
    });
    // 点击Assign - 值数据详情
    const [assign, setAssign] = React.useState({
        id: '',
        description: '',
        dataType: '',
    });
    // Assign弹出框控制
    const [assignOpen, setAssignOpen] = React.useState(false);
    // 彈出框
    const [dialog, setDialog] = useState({
        content: "",
        action: (
            <Button
                variant="contained"
                onClick={() => {
                    setDialog({ ...dialog, open: false });
                }}
            >
                {t('Confirm')}
            </Button>
        ),
        open: false,
    });

    // 添加表單内容开关
    const [addOpen, setAddOpen] = React.useState(false);
    // 是否为添加表单
    const [isAddFlag, setIsAddFlag] = React.useState(false);
    // 弹出框标题
    const [dialogTitle, setDialogTitle] = React.useState('');

    // 頁面初始化
    useEffect(() => {
    }, [])

    // 表單值改變
    const handleFormChange = (event) => {
        setSearchForm({
            ...searchForm,
            [event.target.name]: event.target.value,
        });
    };
    const handleTableRowDetailChange = (event) => {
        setTableRowDetail({
            ...tableRowDetail,
            [event.target.name]: event.target.value,
        });
    };

    // 添加數據接口
    const handleAddData = async (body) => {
        try {
            // 调用接口
            const data = async () => {
                let data = await api.insertData(body);
                return data.message;
            }
            return data();
        } catch (error) {
            return null;
        }
    };
    // 修改數據接口
    const handleUpdateData = async (body) => {
        try {
            // 调用接口
            const data = async () => {
                let data = await api.updateData(body);
                return data.message;
            }
            return data();
        } catch (error) {
            return null;
        }
    };
    // 刪除數據接口
    const handleDeleteData = async (body) => {
        try {
            // 调用接口
            const data = async () => {
                let data = await api.deleteData(body);
                return data.message;
            }
            return data();
        } catch (error) {
            return null;
        }
    };
    // 查詢报表接口
    const handleRefreshData = async (body) => {
        try {
            // 调用接口
            const data = async () => {
                let data = await api.selectLists(body);
                return data.data;
            }
            return data();
        } catch (error) {
            return null;
        }
    };
    // 表单提交
    const handleSearchClick = async () => {
        // 打开按钮loading, 防止重复点击
        setOpenLoading(true);
        handleRefreshData(searchForm).then((value) => {
            // 关闭按钮loading
            setOpenLoading(false);
            // 改变key值, 使列表刷新
            // setRefreshRows((oldRefreshRows) => oldRefreshRows + 1);
            // 报表内容显示
            setDetailsOpen(!detailsOpen);
            // 搜索内容关闭
            setSearchOpen(!searchOpen);
            // 设置报表数据
            setRows(value);
        });
    };
    // 重置查询表单
    const handleResetClick = () => {
        setSearchForm({
            id: '',
            description: '',
        });
    };
    // 重置添加表单
    const handleResetAddClick = () => {
        setTableRowDetail({
            id: '',
            description: '',
            dataType: '',
            effectiveDate: null,
            value: '',
        });
    };
    // 重置修改表单
    const handleResetEditClick = () => {
        setTableRowDetail({
            ...tableRowDetail,
            description: '',
        });
    };

    // 添加一行新數據
    const handleAddClick = () => {
        if (typeof tableRowDetail.id === 'undefined' || typeof tableRowDetail.description === 'undefined' || typeof tableRowDetail.dataType === 'undefined' || typeof tableRowDetail.effectiveDate === 'undefined') {
            return;
        }
        if (tableRowDetail.id.length === 0 || tableRowDetail.description.length === 0 || tableRowDetail.dataType.length === 0 || tableRowDetail.effectiveDate === null) {
            return;
        }
        if (tableRowDetail.id === null || tableRowDetail.description === null || tableRowDetail.dataType === null || tableRowDetail.effectiveDate === null) {
            return;
        }
        // 打开按钮loading, 防止重复点击
        setOpenLoading(true);
        handleAddData(tableRowDetail).then((value) => {
            // 关闭按钮loading
            setOpenLoading(false);
            // 提示是否成功
            setDialog({
                ...dialog,
                // content: value ? t('AddSuccess') : t('AddFail'),
                content: value,
                action: (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setDialog({ ...dialog, open: false });
                        }}
                    >
                        {t('Confirm')}
                    </Button>
                ),
                open: true,
            });
            // 更新table
            handleRefreshData(searchForm).then((value) => {
                // 设置报表数据
                setRows(value);
            });
        });

    }
    // 编辑一行新數據
    const handleEditClick = () => {
        if (typeof tableRowDetail.id === 'undefined' || typeof tableRowDetail.description === 'undefined' || typeof tableRowDetail.dataType === 'undefined') {
            return;
        }
        if (tableRowDetail.id.length === 0 || tableRowDetail.description.length === 0 || tableRowDetail.dataType.length === 0) {
            return;
        }
        if (tableRowDetail.id === null || tableRowDetail.description === null || tableRowDetail.dataType === null) {
            return;
        }
        // 打开按钮loading, 防止重复点击
        setOpenLoading(true);
        handleUpdateData(tableRowDetail).then((value) => {
            // 关闭按钮loading
            setOpenLoading(false);
            // 提示是否成功
            setDialog({
                ...dialog,
                content: value,
                action: (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setDialog({ ...dialog, open: false });
                        }}
                    >
                        {t('Confirm')}
                    </Button>
                ),
                open: true,
            });
            // 更新table
            handleRefreshData(searchForm).then((value) => {
                // 设置报表数据
                setRows(value);
            });
        });

    }
    // 确定执行删除事件
    const handleDeleteClick = (row) => {
        if (typeof row.id === 'undefined') {
            return;
        }
        if (row.id.length === 0) {
            return;
        }
        if (row.id === null) {
            return;
        }
        // 打开按钮loading, 防止重复点击
        setOpenLoading(true);
        handleDeleteData(row).then((value) => {
            // 关闭按钮loading
            setOpenLoading(false);
            // 提示是否成功
            setDialog({
                ...dialog,
                content: value,
                action: (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setDialog({ ...dialog, open: false });
                        }}
                    >
                        {t('Confirm')}
                    </Button>
                ),
                open: true,
            });
            // 更新table
            handleRefreshData(searchForm).then((value) => {
                // 设置报表数据
                setRows(value);
            });
        });
    };
    // 表单为添加状态
    const addFieldsStatus = () => {
        // 关闭禁用
        setTableRowDetailDisable({
            ...tableRowDetailDisable,
            id: false,
            dataType: false,
        })
    };
    // 表单为编辑状态
    const updateFieldsStatus = () => {
        // 开启禁用
        setTableRowDetailDisable({
            ...tableRowDetailDisable,
            id: true,
            dataType: true,
        })
    };
    // 編輯按鈕點擊事件
    const handleOpenEditClick = (row) => {
        // 编辑时需要隐藏effectiveDate和value两个输入框
        setIsAddFlag(false)
        // 回显表单内容
        setTableRowDetail({
            id: row.id,
            description: row.description,
            dataType: row.dataType,
            effectiveDate: null,
            value: '',
        });
        // 设置表单状态为修改
        updateFieldsStatus();
        // 设置弹出框标题为-编辑
        setDialogTitle(t('Edit'));
        // 打开修改表单
        setAddOpen(true);
    }
    // 刪除按鈕點擊事件
    const handleOpenDeleteClick = (row) => {
        // 删除时要打开提示框提示是否删除
        setDialog({
            ...dialog,
            content: t('sure_to_delete'),
            action: (
                <>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <FormControl>
                            <LoadingButton
                                loading={openLoading}
                                variant="contained"
                                sx={{
                                    backgroundColor: "#ff0000",
                                    color: "commom.white",
                                }}
                                onClick={() => handleDeleteClick(row)}
                            >
                                {t('Delete')}
                            </LoadingButton>
                        </FormControl>
                        <FormControl sx={{ marginLeft: "10px" }}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setDialog({ ...dialog, open: false });
                                }}
                                sx={{
                                    backgroundColor: "#9b9b9b",
                                    color: "commom.white",
                                    marginRight: "10px",
                                }}
                                label={t('Close')}
                            >
                                {t('Close')}
                            </Button>
                        </FormControl>
                    </Grid>
                </>
            ),
            open: true,
        });
    }
    // 关闭并初始化弹出窗口
    const handleCloseClick = () => {
        setDialog({
            ...dialog,
            content: '',
            action: (
                <Button
                    variant="contained"
                    onClick={() => {
                        setDialog({ ...dialog, open: false });
                    }}
                >
                    {t('Confirm')}
                </Button>
            ),
            open: false,
        });
    }
    // 关闭表单弹出框
    const handleCloseAddClick = () => {
        setAddOpen(false);
    }
    // 打開添加窗口
    const handleOpenAddClick = () => {
        // 添加时需要显示effectiveDate和value两个输入框
        setIsAddFlag(true)
        // 重置表单内容
        handleResetAddClick();
        // 设置弹出框标题为-添加
        setDialogTitle(t('Add'));
        // 设置表单状态为添加
        addFieldsStatus();
        // 打开添加表单
        setAddOpen(true);
    };

 
    // 顯示控制, 點擊過濾icon后更新表格數據
    const handleFilterClick = () => {
        if (searchOpen) {
            setOpenLoading(true);
            handleRefreshData(searchForm).then((value) => {
                // 关闭按钮loading
                setOpenLoading(false);
                // 改变key值, 使列表刷新
                // setRefreshRows((oldRefreshRows) => oldRefreshRows + 1);
                // 报表内容显示
                setDetailsOpen(!detailsOpen);
                // 搜索内容关闭
                setSearchOpen(!searchOpen);
                // 设置报表数据
                setRows(value);
            });
        } else {
            // 报表内容显示
            setDetailsOpen(!detailsOpen);
            // 搜索内容关闭
            setSearchOpen(!searchOpen);
        }
    };

    // 切換頁數
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // 切換每頁數量
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // 打开Assign弹窗
    const handleOpenAssignClick = (row) => {
        setAssign(row);
        setAssignOpen(true);
    };
    return (
        <>
            {/* 提示框 */}
            <CustomDialog
                sx={{ whiteSpace: "pre-line" }}
                open={dialog.open}
                title={dialog.title}
                content={dialog.content}
                action={dialog.action}
            />
            {/* 標題 */}
            <Container sx={{ textAlign: 'center' }} maxWidth="sm">
                <Typography variant='h4'><b>{t('icon3_system_parameter')}</b></Typography>
            </Container>

            <Container maxWidth="md">
                {/* 导航条 */}
                <Toolbar
                    align='center'
                    sx={{
                        pl: { sm: 2 },
                        pr: { xs: 1, sm: 1 },
                    }}
                >
                    <Tooltip title="New">
                        <IconButton onClick={(event) => handleOpenAddClick(event)}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Filter list">
                        <IconButton onClick={(event) => handleFilterClick(event)}>
                            <FilterAltOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>

                {/* 過濾條件 */}
                <Collapse
                    sx={{ flexGrow: 1, textAlign: 'center' }}
                    in={searchOpen}
                    timeout="auto"
                    unmountOnExit
                >
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        {/* 搜索表单 */}
                        <Grid item xs={6}>
                            <TextField name='id' value={searchForm.id || ""} fullWidth size="small" label={t("ID")} onChange={(event) => handleFormChange(event)} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField name='description' value={searchForm.description || ""} fullWidth size="small" label={t("Description")} onChange={(event) => handleFormChange(event)} />
                        </Grid>
                        {/* 间隔 */}
                        <Grid item xs={6} />
                        {/* 按钮 */}
                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                            <FormControl>
                                <LoadingButton
                                    loading={openLoading}
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "primaryBlue",
                                        color: "commom.white",
                                    }}
                                    onClick={handleSearchClick}
                                >
                                    {t('Search')}
                                </LoadingButton>
                            </FormControl>
                            <FormControl sx={{ marginLeft: "20px" }}>
                                <Button
                                    variant="contained"
                                    onClick={handleResetClick}
                                    sx={{
                                        backgroundColor: "primaryGreen",
                                        color: "commom.white",
                                        marginRight: "10px",
                                    }}
                                    label={t('Reset')}
                                >
                                    {t("Reset")}
                                </Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Collapse>
            </Container>

            {/* 表格 */}
            <Collapse in={detailsOpen} timeout="auto" unmountOnExit>
                <Container maxWidth={false}>
                    <TableContainer>
                        <Table sx={{ wordBreak: "keep-all", mt: '10px', "& .MuiTableCell-root": { paddingRight: "10px", paddingLeft: "10px" }, }} size="small" >
                            {/* 表头 */}
                            <TableHead>
                                <TableRow hover >
                                    {columns.map((column) => (
                                        <TableCell
                                            sx={{ fontSize: '15px', textAlign: column.align }}
                                            key={column.id}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {/* 行 */}
                            <TableBody>
                                {rows.length > 0 ?
                                    <>
                                        {rows
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            if (column.id === 'action') {
                                                                return (
                                                                    <TableCell sx={{ fontSize: '15px', textAlign: column.align }} key={column.id} >
                                                                        <Button
                                                                            variant="contained"
                                                                            onClick={() => handleOpenEditClick(row)}
                                                                            sx={{
                                                                                backgroundColor: "#257018",
                                                                                color: "commom.white",
                                                                                marginRight: "10px",
                                                                            }}
                                                                            label={t('Edit')}
                                                                        >
                                                                            {t("Edit")}
                                                                        </Button>
                                                                        <Button
                                                                            variant="contained"
                                                                            onClick={() => handleOpenDeleteClick(row)}
                                                                            sx={{
                                                                                backgroundColor: "#ff0000",
                                                                                color: "commom.white",
                                                                                marginRight: "10px",
                                                                            }}
                                                                            label={t('Delete')}
                                                                        >
                                                                            {t("Delete")}
                                                                        </Button>
                                                                    </TableCell>
                                                                )
                                                            } else {
                                                                return (
                                                                    <TableCell sx={{ fontSize: '15px', textAlign: column.align }} key={column.id} >
                                                                        {column.id === 'value' ? (
                                                                            <Button
                                                                                variant="contained"
                                                                                onClick={() => handleOpenAssignClick(row)}
                                                                                sx={{
                                                                                    backgroundColor: "#257018",
                                                                                    color: "commom.white",
                                                                                    marginRight: "10px",
                                                                                }}
                                                                                label={t('Assign')}
                                                                            >
                                                                                {t("Assign")}
                                                                            </Button>
                                                                        ) : value}
                                                                    </TableCell>
                                                                );
                                                            }
                                                        })}
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </> :
                                    <TableRow>
                                        <TableCell colSpan="13" sx={{ textAlign: 'center' }}>No Records Found!</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* 分頁 */}
                    {
                        rows.length > 0 ?
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            /> : <></>
                    }
                </Container>
            </Collapse>

            {/* 添加表單 */}
            <Dialog
                fullWidth
                maxWidth="xs"
                sx={{ whiteSpace: "pre-line" }}
                open={addOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <Container maxWidth="md">
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                        >
                            {/* 表单 */}
                            <Grid item xs={12} />
                            <Grid item xs={12}>
                                <TextField name='id' disabled={tableRowDetailDisable.id} value={tableRowDetail.id || ""} error={tableRowDetail.id === ''} fullWidth required size="small" label={t("ID")} onChange={(event) => handleTableRowDetailChange(event)} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name='description' value={tableRowDetail.description || ""} error={tableRowDetail.description === ''} fullWidth required size="small" label={t("Description")} onChange={(event) => handleTableRowDetailChange(event)} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name='dataType' disabled={tableRowDetailDisable.dataType} value={tableRowDetail.dataType || ""} error={tableRowDetail.dataType !== 'String' && tableRowDetail.dataType !== 'Number'} fullWidth required size="small" label={t("Data_Type")} select onChange={(event) => handleTableRowDetailChange(event)} >
                                    <MenuItem value="String">{t("String")}</MenuItem>
                                    <MenuItem value="Number">{t("Number")}</MenuItem>
                                </TextField>
                            </Grid>
                            {isAddFlag && (
                                <>
                                    <Grid item xs={12}>
                                        <CDatePicker name='effectiveDate' label={t("Effective_Date")} value={tableRowDetail.effectiveDate || null} required
                                            onChange={(data) => {
                                                setTableRowDetail({
                                                    ...tableRowDetail,
                                                    effectiveDate: !!data ? moment(new Date(data).getTime()).format('YYYY/MM/DD') : null
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField name='value' value={tableRowDetail.value || ""} fullWidth size="small" label={t("Value")} onChange={(event) => handleTableRowDetailChange(event)} />
                                    </Grid>
                                </>
                            )}

                        </Grid>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <FormControl>
                            <LoadingButton
                                loading={openLoading}
                                variant="contained"
                                sx={{
                                    backgroundColor: "#2d8d27",
                                    color: "commom.white",
                                }}
                                onClick={dialogTitle === t('Add') ? handleAddClick : handleEditClick}
                            >
                                {dialogTitle}
                            </LoadingButton>
                        </FormControl>
                        <FormControl sx={{ marginLeft: "10px" }}>
                            <Button
                                variant="contained"
                                onClick={dialogTitle === t('Add') ? handleResetAddClick : handleResetEditClick}
                                sx={{
                                    backgroundColor: "primaryGreen",
                                    color: "commom.white",
                                    marginRight: "10px",
                                }}
                                label={t('Reset')}
                            >
                                {t('Reset')}
                            </Button>
                        </FormControl>
                        <FormControl >
                            <Button
                                variant="contained"
                                onClick={handleCloseAddClick}
                                sx={{
                                    backgroundColor: "#9b9b9b",
                                    color: "commom.white",
                                    marginRight: "10px",
                                }}
                                label={t('Close')}
                            >
                                {t('Close')}
                            </Button>
                        </FormControl>
                    </Grid>
                </DialogActions>
            </Dialog>

            {/* 点击分配 */}
            <Dialog
                fullWidth
                maxWidth="md"
                scroll="paper"
                sx={{ whiteSpace: "pre-line" }}
                open={assignOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <SystemParameterSetup fatherProp={assign} closeAction={() => { setAssignOpen(false) }} />
            </Dialog>
        </>
    )
}

export default SystemParameter;