import { instance } from "../../instance";

export const oneBatch = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/oneBatch`,
    // data: "pvoxoz",
    // headers: {
    //   "Content-Type": "text/plain",
    // },
  }).then(
    (result) => result.data,
    (error) => error
  );
}
export const changePayTpye = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/selectType1`,
    data: "F",
    headers: {
      "Content-Type": "text/plain",
    },
  }).then(
    (result) => result.data,
    (error) => error
  );
}