import React, { useState, useRef, useEffect } from 'react'
import { parseInt } from 'lodash'
import { Container, Grid, Typography, MenuItem, TextField, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Backdrop, CircularProgress, Autocomplete } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, createSearchParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import CustomDialog from 'src/components/common/CustomDialog'
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import { da } from 'date-fns/locale'
import { AlternateEmail } from '@mui/icons-material'

// 工人出入記錄一覽表---搜索页面
export default function LoeaerSearchWithVendor() {
  const navigate = useNavigate()
  const componentRef = useRef()
  const { t } = useTranslation()
  const [siteId, setSiteId] = useState('')
  const [site, setSite] = useState({})
  const [siteDesc, setSiteDesc] = useState('')
  const [vendorDesc, setVendorDesc] = useState('')
  const [vendorId, setVendorId] = useState('')
  const [vendor, setVendor] = useState({})
  const [reportType, setReportType] = useState('1')
  const [loading, setLoading] = React.useState(false)
  const [siteList, setSiteList] = useState([])
  const [vendorList, setVendorList] = useState([])
  const [subContractList, setSubContractList] = useState([])
  const [sendData, setSendData] = useState([])
  const [shortPeriodDateFrom, setShortPeriodDateFrom] = useState('')
  const [shortPeriodDateTo, setShortPeriodDateTo] = useState('')
  const [smartCardId, setSmartCardId] = useState('')
  const [subContractorId, setSubContractorId] = useState('')
  const [subContractorDesc, setSubContractorDesc] = useState('')
  const [subContractor, setSubContractor] = useState({})

  useEffect(() => {}, [])

  const handleBack = () => {
    navigate(-1)
  }

  const { control, handleSubmit, setValue, getValues, reset } = useForm({
    defaultValues: {
      subContract: '',
      dateFrom: '',
      dateTo: '',
      outputType: '',
    },
  })

  const resetForm = (data) => {
    setShortPeriodDateFrom('')
    setShortPeriodDateTo('')
    setSiteId('')
    setSiteDesc('')
    setSmartCardId('')
    setSubContractorId('')
    setSubContractorDesc('')
    setReportType('1')
    setSiteList([])
    setSubContractList([])
    setVendorId('')
    setVendorDesc('')
    setSendData({})
    setReportType('1')
  }

  const [dialog, setDialog] = useState({
    title: '',
    content: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        {t('Confirm')}
      </Button>
    ),
    open: false,
  })

  // 修改報表類型
  const handleChangeReportType = (event) => {
    setReportType(event.target.value)
  }

  const showDetailResult = (data) => {
    // console.log("data", vendorId);
    // if (!siteId) {
    //   setDialog({
    //     ...dialog,
    //     content: t("search_chooseSite_tips"),
    //     open: true,
    //   });
    //   return;
    // }
    if (!vendorId) {
      setDialog({
        ...dialog,
        content: t('Please_select_a_contractor'),
        open: true,
      })
      return
    }
    if (!shortPeriodDateFrom) {
      setDialog({
        ...dialog,
        content: t('search_chooseDatetips') + '(' + t('search_from_tips') + ')',
        open: true,
      })
      return
    }
    if (!shortPeriodDateTo) {
      setDialog({
        ...dialog,
        content: t('search_chooseDatetips') + '(' + t('search_to_tips') + ')',
        open: true,
      })
      return
    }

    let fromDate = moment(shortPeriodDateFrom, 'YYYY/MM/DD')
    let toDate = moment(shortPeriodDateTo, 'YYYY/MM/DD')
    if (toDate.diff(fromDate, 'months') > 1) {
      setDialog({
        ...dialog,
        content: '不能選取多於一個月!',
        open: true,
      })
      return
    }

    if (fromDate > toDate) {
      setDialog({
        ...dialog,
        content: '日期（由） 不能大於 日期（至）!',
        open: true,
      })
      return
    }

    if (reportType === '1') {
      navigate({
        pathname: '/workerAttendanceRecord/loeaerMainWithVendor/loeaerDetailWithVendor',
        search: `?${createSearchParams(
          {
            accessDateFrom: shortPeriodDateFrom,
            accessDateTo: shortPeriodDateTo,
            siteId: siteId,
            siteDesc: siteDesc,
            smartCardId: smartCardId,
            subContractorId: subContractorId,
            subContractorDesc: subContractorDesc,
            vendorId: vendorId,
            vendorDesc: vendorDesc,
          },
          { state: { fromSpecificPage: true } },
        )}`,
      })
    } else if (reportType === '2') {
      navigate({
        pathname: '/workerAttendanceRecord/loeaerMainWithVendor/loeaerDetailAndDailyIncomeWithVendor',
        search: `?${createSearchParams(
          {
            accessDateFrom: shortPeriodDateFrom,
            accessDateTo: shortPeriodDateTo,
            siteId: siteId,
            siteDesc: siteDesc,
            smartCardId: smartCardId,
            subContractorId: subContractorId,
            subContractorDesc: subContractorDesc,
            vendorId: vendorId,
            vendorDesc: vendorDesc,
          },
          { state: { fromSpecificPage: true } },
        )}`,
      })
    } else {
      navigate({
        pathname: '/workerAttendanceRecord/loeaerMainWithVendor/loeaerIncomeDetailWithVendor',
        search: `?${createSearchParams(
          {
            accessDateFrom: shortPeriodDateFrom,
            accessDateTo: shortPeriodDateTo,
            siteId: siteId,
            siteDesc: siteDesc,
            smartCardId: smartCardId,
            subContractorId: subContractorId,
            subContractorDesc: subContractorDesc,
            vendorId: vendorId,
            vendorDesc: vendorDesc,
          },
          { state: { fromSpecificPage: true } },
        )}`,
      })
    }
  }
  // 選了分判商要選擇地盤
  const handleVendorList = (_event, data) => {
    console.log('2', data)
    const getSub = async () => {
      setLoading(true)
      if (data !== null) {
        setVendorId(data.id)
        setVendorDesc(data.text)
        setVendor(data)
        setSendData({
          ...sendData,
          vendorId: data.id,
          vendorDesc: data.text,
          vendor: data,
        })

        let siteList = await apiAtt.getFillSiteWithVendor({
          vendorId: data.id,
        })
        if (siteList) {
          setSiteList(siteList)
        }
      } else {
        setVendorId(null)
        setVendorDesc(null)
        setVendor(null)
        setSendData({
          ...sendData,
          vendorId: null,
          vendorDesc: null,
          vendor: null,
        })
      }
      // getFillSiteWithVendor(data);
      console.log('2', data)

      //   console.log("handleVendorList", siteList);
      setLoading(false)

      // periodList.splice(0, periodList.length);
      // siteList.splice(0, siteList.length);
      // subcontractorList.splice(0, subcontractorList.length);
      // setShortBatchList({});
      // let sendPeriod = await api.getPeriod({
      //   ...sendData,
      //   vendorId: item.id,
      // });
      // if (sendPeriod) {
      //   setPeriodList([...sendPeriod]);
      // }
      // setSendData({
      //   ...sendData,
      //   vendorId: item.id,
      //   vendorDesc: item.text,
      // });
    }

    getSub()
    console.log('loeaerMain', sendData)
  }

  // 选了地盘后，需要请求分判商
  const handleSiteId = (_event, data) => {
    setLoading(true)
    const getSub = async () => {
      if (data !== null) {
        let getSubConList = await apiAtt.getSubContractorId(data.id)
        if (getSubConList) {
          setSubContractList(getSubConList)
        }
        setSite(data)
        setSendData({
          ...sendData,
          siteId: data.id,
          siteDesc: data.text,
          site: data,
        })
        setLoading(false)
        setSiteId(data.id)
        setSiteDesc(data.text)
      } else {
        setSite(null)
        setSendData({
          ...sendData,
          siteId: null,
          siteDesc: null,
          site: null,
        })
        setLoading(false)
        setSiteId(null)
        setSiteDesc(null)
      }
    }
    getSub()
  }

  const handleSubContractorId = (_event, data) => {
    if (data !== null) {
      console.log('sdasd', data)
      setSubContractorId(data.id)
      setSubContractorDesc(data.text)
      setSubContractor(data)
      setSendData({
        ...sendData,
        subContractorId: data.id,
        subContractorDesc: data.text,
        subContractor: data,
      })
    } else {
      setSubContractorId(null)
      setSubContractorDesc(null)
      setSubContractor(null)
      setSendData({
        ...sendData,
        subContractorId: null,
        subContractorDesc: null,
        subContractor: null,
      })
    }
    console.log(subContractorId)
  }

  useEffect(() => {
    const getFillSite = async () => {
      // getFillSiteWithVendorAll getFillSiteWithVendor(data)
      setLoading(true)
      let getFillSiteWithVendorAll = await apiAtt.getFillSiteWithVendorAll()
      //  let getFillSiteSelect = await apiAtt.getFillSite();
      if (getFillSiteWithVendorAll) {
        const result = getFillSiteWithVendorAll
        setVendorList(result)
      }
      //  console.log("sadasda", vendorList);
      // if (getFillSiteSelect) {
      //   const result = getFillSiteSelect;
      //   setSiteList(result);
      // }
      setLoading(false)
    }

    setShortPeriodDateFrom(moment(getPreMonth(moment(new Date()).format('yyyy-MM-DD'))).format('yyyy-MM-DD'))
    setShortPeriodDateTo(moment(new Date()).format('yyyy-MM-DD'))

    getFillSite()
  }, [])
  const getPreMonth = (date) => {
    console.log('date', date)
    let arr = date.split('-')
    let year = arr[0] // 获取当前日期的年份
    let month = arr[1] // 获取当前日期的月份
    let day = arr[2] // 获取当前日期的日
    let days = new Date(year, month, 0)
    days = days.getDate() // 获取当前日期中月的天数
    let year2 = year
    let month2 = parseInt(month) - 1
    if (month2 === 0) {
      year2 = parseInt(year2) - 1
      month2 = 12
    }
    let day2 = day
    let days2 = new Date(year2, month2, 0)
    days2 = days2.getDate()
    if (day2 > days2) {
      day2 = days2
    }
    if (month2 < 10) {
      month2 = '0' + month2
    }
    let t2 = year2 + '-' + month2 + '-' + day2
    return t2
  }

  return (
    <>
      {
        <>
          {/* <div style={{ display: "block", textAlign: "left" }}>
            <Button sx={{ float: "left" }} onClick={handleBack}>
              <ArrowBackIcon />
              {t("button_back")}
            </Button>
          </div> */}
          <div id="div" ref={componentRef}>
            <Container>
              <div>
                <div style={{ display: 'block' }}>
                  <Typography variant="h4" style={{ color: 'black', textAlign: 'center' }}>
                    <b>{t('query_the_list_of_worker_entry_and_exit_records')}</b>
                  </Typography>

                  <Backdrop
                    sx={{
                      color: '#fff',
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>

                  <Container
                    sx={{
                      width: '500px',
                      borderColor: '#d3d3d3',
                      borderRadius: 4,
                    }}
                  >
                    {/* <Grid
                      item
                      xs={6}
                      sx={{ margin: "auto", width: "400px", marginTop: "10px" }}
                    >
                      <TextField
                        id="vendorDesc"
                        value={vendorId || ""}
                        error={!vendorId}
                        fullWidth
                        size="small"
                        label={t("Name_of_the_contractor")}
                        select
                      >
                        {vendorList.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item.id}
                            name={item.text}
                            onClick={() => handleVendorList(item)}
                          >
                            {item.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid> */}

                    <Grid
                      item
                      xs={12}
                      sx={{
                        margin: 'auto',
                        width: '400px',
                        marginBottom: '10px',
                        marginTop: '20px',
                      }}
                    >
                      <Autocomplete
                        sx={{ width: '400px' }}
                        value={sendData.vendor || null}
                        onChange={handleVendorList}
                        fullWidth
                        size="small"
                        id="combo-box-demo"
                        getOptionLabel={(option) => {
                          // console.log("option", option);
                          return option.text
                        }}
                        options={vendorList}
                        renderInput={(params) => <TextField error={!sendData.vendor} fullWidth size="small" {...params} label={t('Vendor')} />}
                      />
                    </Grid>

                    {/* <Grid
                      item
                      xs={6}
                      sx={{ margin: "auto", width: "400px", marginTop: "10px" }}
                    >
                      <TextField
                        id="siteDesc"
                        value={siteId || ""}
                        fullWidth
                        size="small"
                        label={t("Site_Name")}
                        select
                      >
                        <MenuItem
                          value=""
                          onClick={() =>
                            handleSiteId({ id: null, text: "All" })
                          }
                        >
                          None
                        </MenuItem>
                        {siteList.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item.id}
                            name={item.text}
                            onClick={() => handleSiteId(item)}
                          >
                            {item.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid> */}

                    <Grid
                      item
                      xs={6}
                      sx={{
                        margin: 'auto',
                        width: '400px',
                        marginBottom: '10px',
                        marginTop: '5px',
                      }}
                    >
                      <Autocomplete
                        sx={{ width: '400px' }}
                        value={sendData.site || null}
                        onChange={handleSiteId}
                        fullWidth
                        size="small"
                        id="combo-box-demo"
                        getOptionLabel={(option) => {
                          // console.log("option", option);
                          return option.text
                        }}
                        options={siteList}
                        renderInput={(params) => (
                          <TextField
                            // error={!sendData.vendor}
                            fullWidth
                            size="small"
                            {...params}
                            label={t('Site_Name')}
                          />
                        )}
                      />
                    </Grid>

                    {/* <Grid
                      item
                      xs={6}
                      sx={{ margin: "auto", width: "400px", marginTop: "10px" }}
                    >
                      <TextField
                        id="subContractId"
                        value={subContractorId || ""}
                        fullWidth
                        size="small"
                        label={t("Subcontractors_(direct employers)")}
                        select
                      >
                        <MenuItem value={null}>None</MenuItem>
                        {subContractList.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item.id}
                            name={item.text}
                            onClick={() => handleSubContractorId(item)}
                          >
                            {item.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid> */}

                    <Grid
                      item
                      xs={12}
                      sx={{
                        margin: 'auto',
                        width: '400px',
                      }}
                    >
                      <Autocomplete
                        sx={{ width: '400px' }}
                        value={sendData.subContractor || null}
                        onChange={handleSubContractorId}
                        fullWidth
                        size="small"
                        id="combo-box-demo"
                        getOptionLabel={(option) => {
                          // console.log("option", option);
                          return option.text
                        }}
                        options={subContractList}
                        renderInput={(params) => (
                          <TextField
                            // error={!sendData.vendor}
                            fullWidth
                            size="small"
                            {...params}
                            label={t('Subcontractors_(direct employers)')}
                          />
                        )}
                      />
                    </Grid>

                    {/* 日期由 */}
                    <Grid item xs={6} sx={{ margin: 'auto', width: '400px' }}>
                      <TextField
                        type="date"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        sx={{
                          textAlign: 'left',
                          maxWidth: '400px',
                          mt: '20px',
                        }}
                        label={t('search_form_date_dot')}
                        error={!shortPeriodDateFrom}
                        value={shortPeriodDateFrom}
                        inputProps={{
                          max: '2099-12-31',
                        }}
                        onChange={(event) => {
                          setShortPeriodDateFrom(event.target.value)
                        }}
                        format="yyyy/MM/dd"
                      />
                    </Grid>

                    {/* 日期至 */}
                    <Grid item xs={6} sx={{ margin: 'auto', width: '400px' }}>
                      <TextField
                        type="date"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        sx={{
                          textAlign: 'left',
                          maxWidth: '400px',
                          mt: '20px',
                        }}
                        label={t('search_to_date_dot')}
                        error={!shortPeriodDateTo}
                        value={shortPeriodDateTo}
                        inputProps={{
                          max: '2099-12-31',
                        }}
                        onChange={(event) => {
                          setShortPeriodDateTo(event.target.value)
                        }}
                        format="yyyy/MM/dd"
                      />
                    </Grid>
                    <div />

                    {/* 智能卡編號 */}
                    <Grid item xs={6} sx={{ margin: 'auto', width: '400px' }}>
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        sx={{
                          textAlign: 'left',
                          maxWidth: '400px',
                          mt: '20px',
                        }}
                        label={t('search_smartCardId_notDot')}
                        // 設置了value可以進行重置
                        value={smartCardId}
                        onChange={(event) => {
                          setSmartCardId(event.target.value)
                        }}
                      />
                    </Grid>

                    {/* Content */}
                    <Grid item xs={12} sx={{ margin: 'auto', width: '400px', marginTop: '10px' }}>
                      <FormLabel id="contentTitle">{t('search_report_type')}</FormLabel>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <FormControl>
                        <RadioGroup
                          // row表示横向排列
                          row
                          aria-labelledby="contentRadio"
                          name="row-radio-buttons-group"
                          value={reportType}
                          onChange={handleChangeReportType}
                        >
                          {/* 加入参数disabled 可以禁止选择 */}
                          <FormControlLabel value="1" control={<Radio />} label={t('search_entryAndExit_records')} />
                          <FormControlLabel value="2" control={<Radio />} label={t('search_inAndOut_rad_income')} />
                          <FormControlLabel value="3" control={<Radio />} label={t('search_detailofEmployee_income')} />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Container>
                </div>
              </div>

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '18px',
                }}
              >
                {/* 提交按钮 */}
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <FormControl size="small">
                    <Button
                      sx={{
                        backgroundColor: 'primaryBlue',
                        color: 'commom.white',
                      }}
                      variant="contained"
                      onClick={handleSubmit(showDetailResult)}
                    >
                      {t('button_submit')}
                    </Button>
                  </FormControl>

                  {/* 重置按钮 */}
                  <FormControl size="small">
                    <Button
                      variant="contained"
                      onClick={resetForm}
                      label="Reset"
                      sx={{
                        backgroundColor: 'primaryGreen',
                        color: 'commom.white',
                        marginLeft: '20px',
                      }}
                    >
                      {t('button_reset')}
                    </Button>
                  </FormControl>
                </Grid>
              </div>
            </Container>
          </div>
        </>
      }

      <CustomDialog open={dialog.open} title={dialog.title} content={dialog.content} action={dialog.action} />
    </>
  )
}
