import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import $http from 'src/utils/api/services'

// 从localStorage中获取token
function getLocalToken() {
  const token = localStorage.getItem('access_token')
  return token
}
function getRefreshToken() {
  const token = localStorage.getItem('refresh_token')
  return token
}
function getLang() {
  const lang = localStorage.getItem('lang')
  return lang
}
export const instance = axios.create({
  headers: {
    Authorization: getLocalToken(),
    'Accept-Language': getLang(),
  },
  withCredentials: false,
})
// 给实例添加一个setToken方法，用于登录后将最新token动态添加到header，同时将token保存在localStorage中
instance.setToken = (token) => {
  instance.defaults.headers['Authorization'] = token
  localStorage.setItem('access_token', token)
}
instance.setRefreshToken = (token) => {
  instance.defaults.headers['Authorization'] = token
  localStorage.setItem('refresh_token', token)
}

// instance.defaults.headers.common["Authorization"] = localStorage.getItem('access_token');
instance.interceptors.request.use(
  (config) => {
    // const token = localStorage.getItem('access_token');
    // const lang = localStorage.getItem('lang');
    // let headers = {
    //     'Authorization': token,
    //     'Accept-Language': lang,
    // }
    // config.headers = { ...headers, ...config.headers }
    return config
  },
  (error) => error,
)

async function requestRefreshToken(token) {
  await $http('/oauth/refreshTokenDetail', { token }).then((res) => {
    console.log('res?.data:', res?.data)
    localStorage.setItem('expTime', res?.data?.exp)
  })
}
let hasAlert = false
const originalAlert = window.alert
window.alert = (message) => {
  hasAlert = true
  originalAlert(message)
}
// 是否正在刷新的标记
let isRefreshing = false
// 重试队列，每一项将是一个待执行的函数形式
let requests = []
instance.interceptors.response.use(
  (response) => {
    // if (response.headers["Authentication"]) {
    //     localStorage.setItem('access_token', response.headers["Authentication"]);
    // }
    // if (response.headers["refresh_token"]) {
    //     localStorage.setItem('refresh_token', response.headers["refresh_token"]);
    // }
    const { code } = response.data
    console.log('请求拦截-code:', code)
    // 是否过期
    if (code === 401) {
      // 是否有refreshToken
      if (!getRefreshToken()) {
        // 没有refresh_token就直接跳转登录页
        window.location.href = process.env.REACT_APP_REDIRECTURL + '/login'
        return response
      } else {
        // 是否有刷新请求, 没有才请求刷新, 避免重复刷新token
        const config = response.config
        if (!isRefreshing) {
          isRefreshing = true
          // 调用刷新token api, 得到结果, 如果refresh_token也过期则去往登录页, 否则得到新的token
          return refreshToken()
            .then((res) => {
              const { access_token: accessToken, refresh_token: refreshToken, sub, exp } = res?.data
              instance.setToken(accessToken)
              instance.setRefreshToken(refreshToken)
              localStorage.setItem('userId', sub)
              //   localStorage.setItem('expTime', exp)

              requestRefreshToken(accessToken)

              config.headers['Authorization'] = accessToken
              // 已经刷新了token，将所有队列中的请求进行重试
              requests.forEach((cb) => cb(accessToken))
              // 重试完了别忘了清空这个队列
              requests = []
              console.log('test error')
              return instance(config)
            })
            .catch((res) => {
              console.error('refresh token error =>', res)
              window.location.href = process.env.REACT_APP_REDIRECTURL + '/login'
            })
            .finally(() => {
              isRefreshing = false
            })
        } else {
          // 正在刷新token，返回一个未执行resolve的promise
          return new Promise((resolve) => {
            // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
            requests.push((token) => {
              config.headers['Authorization'] = token
              resolve(instance(config))
            })
          })
        }
      }
    }
    return response
  },
  (error) => {
    if (error.response.status === 403) {
      localStorage.clear()
      if (!hasAlert) {
        alert('登錄過期，請重新登陸')
      }
      // const topWin = (function () { let p = window.parent; while (p !== p.window.parent) { p = p.window.parent; } return p; })();
      // topWin.location.reload();
      // topWin.location.href = process.env.REACT_APP_REDIRECTURL + "/login"
      window.location.href = process.env.REACT_APP_REDIRECTURL + '/login'
      return
    } else {
      alert('請求出錯')
    }
    return Promise.reject(error)
  },
)

async function refreshToken() {
  // instance是当前request.js中已创建的axios实例
  let headers = {
    'Content-Type': 'application/json',
    Authorization: getRefreshToken(),
  }
  const res = await instance.post(`${process.env.REACT_APP_WEB_URL}/oauth/refreshToken`, {}, { headers: headers })
  return res.data
}
