import React from 'react';
import {
  Autocomplete,
  Box,
  TextField,
} from '@mui/material';
import * as api from "src/utils/api/common/index";

function CAutocomplete(props) {
  const { url, requestFun } = props
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const loading = open && options.length === 0

  React.useEffect(() => {
    let active = true

    if (!loading || options.length !== 0) {
      return undefined
    }

    ; (async () => {
      try {
        if (requestFun) {
          const { data } = await requestFun()
          if (active) {
            setOptions(data)
          }
        } else if (url) {
          const { data } = await api.getComboboxList(url)
          if (active) {
            setOptions(data)
          }
        }
      } catch (err) {
        console.error(err)
      }
    })()

    return () => {
      active = false
    }
  }, [loading, options.length, requestFun, url])

  // // 使用正则表达式来匹配font标签内的文本
  // const regex = /<font.*?>(.*?)<\/font>/;
  // const inputValue = props.value;
  // const match = inputValue ? inputValue.text.match(regex) : null;
  // // 如果匹配成功，将文本分为前缀和后缀
  // let prefix = inputValue;
  // let suffix = "";
  // if (match) {
  //   prefix = inputValue.text.slice(0, match.index);
  //   suffix = match[0];
  //   console.log(prefix);
  //   console.log(suffix);
  // }
  return (
    <Autocomplete
      renderOption={(props, option) => (
        // <Box component="li" {...props} dangerouslySetInnerHTML={{ __html: option.text }}>
        <Box component="li" {...props}>
          {option.text}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          required={props.required}
          error={!!props.required && !!!props.value}
          fullWidth
          size="small"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
        // InputProps={{
        //   ...params.InputProps,
        //   value: match ? prefix : props.value ? props.value : '',
        //   endAdornment: (
        //     <span
        //       style={{
        //         width: 145, // 设置endAdornment的宽度
        //       }} 
        //       dangerouslySetInnerHTML={{ __html: suffix }}
        //     />
        //   )
        // }}
        />
      )}
      {...props}
      loading={loading}
      options={options}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      autoHighlight
      getOptionLabel={(option) => option.text ? String(option.text) : ''}
      isOptionEqualToValue={(option, value) => String(option.id) === String(value.id)}
    />
  )
}
export default CAutocomplete;