import { React, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Container,
  Grid,
  FormControl,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  MenuItem,
  TableFooter,
  FormLabel,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import moment from 'moment'
import $http, { $download } from 'src/utils/api/services'

import * as api from "../../../utils/api/vendorAutopaySystem/RptSuspendpaymentvendorApi";

export default function RptSuspendpaymentvendor() {
  const [isSearchPage, setIsSearchPage] = useState(true);
  // 國際化
  const { t } = useTranslation();
  const [backOpen, setBackOpen] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const [dateTo, setDateTo] = useState([]);
  const [dateFrom, setDateFrom] = useState([]);
  const [sendData, setSendData] = useState({})
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    // setBackOpen(true)
    const getData = async () => {
    }
    getData()
  }, [])


  const handleBack = () => {
    setIsSearchPage(true);
  };


  const handleMoreThanNumber = (e) => {
    setSendData({ ...sendData, noofentry: e.target.value })
  }


  const handleSubmit = (e) => {

    if (!sendData.noofentry) {
      alert("多於最新出入閘記錄與糧期(至)之日數差額的承判商")
      return
    } 

    const getData = async () => {
      setBackOpen(true)
      let send = await api.getRPToutstandingvendorlist(sendData);
      if (send) {
        setIsSearchPage(false)
        setTableData(send)
      }
      setBackOpen(false)
    }
    getData()
  }

  const resetForm = (e) => {
    setSendData({})
  }


  const exportExcel = (data) => {
    $download(`/payrollReportController/detailSQLlistRVBSDownloadExcel`, 'application/vnd.ms-excel', data)
  };



  const exportPDF = (data) => {

    $download(`/payrollReportController/getDetailSQLlistRVBSPdfTest`, 'application/pdf', data)

  };


  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {isSearchPage && (
        <form>
          <Container maxWidth="md">
            <Grid
              container
              spacing={2}
            >
              {/* 暫停使用自動轉賬的承判商 */}
              <Grid item xs={12}>
                <Typography variant="h4" style={{ textAlign: 'center' }}><b>{t('suspension_of_the_use_of_autopay_contractors')}</b></Typography>
              </Grid>
              <Grid item xs={12} container justifyContent="center" >
                <TextField sx={{ width: '400px' }} error={!sendData.noofentry} value={sendData.noofentry || ""} fullWidth size="small" label={t('contractorsmore_than_to')} onChange={handleMoreThanNumber} />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <FormControl>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                      backgroundColor: "primaryBlue",
                      color: "commom.white",
                    }}
                  >
                    {t("button_submit")}
                  </Button>
                </FormControl>
                <FormControl sx={{ marginLeft: "20px" }}>
                  <Button
                    variant="contained"
                    onClick={resetForm}
                    sx={{
                      backgroundColor: "primaryGreen",
                      color: "commom.white",
                      marginRight: "10px",
                    }}
                    label="Reset"
                  >
                    {t("button_reset")}
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Container>
        </form>
      )}

      {!isSearchPage && (
        <Container maxWidth={false}>
          <div
            style={{
              display: "block",
              textAlign: "left",
              marginTop: "0px",
            }}
          >
            <Button sx={{ float: "left", mr: "20px" }} onClick={handleBack}>
              <ArrowBackIcon />
              {t("button_back")}
            </Button>
          </div>

          <div style={{ display: "block", textAlign: "right" }}>
            <Button sx={{ float: "right", mr: "20px" }} onClick={() => exportExcel(sendData)}>
              <DownloadIcon />
              {t("button_export_excel")}
            </Button>
            <Button sx={{ float: "right", mr: "20px" }} onClick={() => exportPDF(sendData)}>
              <DownloadIcon />
              {t("button_export_pdf")}
            </Button>
          </div>
          <div >
            <Typography variant="h4" style={{ textAlign: 'center', mt: "-30px" }}><b>{t("suspension_of_the_use_of_autopay_contractors")}</b></Typography>
            <TableContainer>
              <Table sx={{ textAlign: 'left' }} size="small"  >
                <TableBody>
                  <TableRow>
                    {/* 製作日期： */}
                    <TableCell sx={{ fontWeight: 'bold', width: 200, borderBottom: "none" }}>{t('date_of_production')}</TableCell >
                    <TableCell sx={{ textAlign: 'left', borderBottom: "none" }}>{moment(new Date().getTime()).format('yyyy/MM/DD')}</TableCell>
                  </TableRow>                
                  <TableRow>
                    {/*  多於此人數之承判商： */}
                    <TableCell sx={{ fontWeight: 'bold', width: 200, borderBottom: "none" }}>{t('contractorsmore_than_to')}:</TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: "none" }}>
                      {sendData.noofentry}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer>
              <Table size="small" sx={{ mt: "20px" }} >
                <TableHead>
                  <TableRow >
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '15px', wordBreak: "keep-all", whiteSpace: "nowrap" }} >
                      {t('icon_vas_wsms_report_table_header_vendor')}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '15px', wordBreak: "keep-all", whiteSpace: "nowrap" }} >
                      {t("Contractor")}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '15px', wordBreak: "keep-all", whiteSpace: "nowrap" }} >
                      {t("number_of_people")}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '15px', wordBreak: "keep-all", whiteSpace: "nowrap" }} >
                      {t("number_of_people_but_do_not_Base")}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '15px', wordBreak: "keep-all", whiteSpace: "nowrap" }} >
                      {t("Total_Number")}
                    </TableCell>
                  </TableRow>
                </TableHead>


                {!!tableData && tableData.mainData.length > 0 && (
                  <TableBody>
                    {tableData.mainData.map((row, ind) => (<>
                      <TableRow
                        hover
                        key={ind}
                      >
                        <TableCell >{ind + 1}</TableCell>
                        <TableCell >{row.vendorDesc}</TableCell>
                        <TableCell >{row.arshkpCountcardholderid}</TableCell>
                        <TableCell >{row.narshkpCountcardholderid}</TableCell>
                        <TableCell >{row.total}</TableCell>
                      </TableRow>

                    </>
                    ))}
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '15px', wordBreak: "keep-all", whiteSpace: "nowrap" }} >
                        {t("icon_vas_wsat_total_num")}:
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '15px', wordBreak: "keep-all", whiteSpace: "nowrap" }} >
                        { }
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '15px', wordBreak: "keep-all", whiteSpace: "nowrap" }} >
                        {tableData.arTotal}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '15px', wordBreak: "keep-all", whiteSpace: "nowrap" }} >
                        {tableData.narTotal}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '15px', wordBreak: "keep-all", whiteSpace: "nowrap" }} >
                        {tableData.sumTotal}
                      </TableCell>
                    </TableRow>
                    <div>
                      <Typography sx={{ fontWeight: 'bold', fontSize: '15px', wordBreak: "keep-all", whiteSpace: "nowrap" }}><b />{t('total_number_of_contractors')}：{tableData.rows}</Typography>
                      <Typography sx={{ fontWeight: 'bold', fontSize: '15px', wordBreak: "keep-all", whiteSpace: "nowrap" }}>{t('outstanding_description')}</Typography>
                    </div>

                  </TableBody>
                )}
                {(!tableData || tableData.mainData.length === 0) && (
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ textAlign: 'center' }} colSpan={10}>{"No data found !"}</TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>

        </Container>
      )}
    </>
  );
}
