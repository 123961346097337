import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import cookie from 'react-cookies'
import * as commonApi from 'src/utils/api/CommonApi'
import $http from 'src/utils/api/services'

export default function IndexPage() {
  const [token, setToken] = useState('')
  const location = useLocation()
  const navigate = useNavigate()

  // 删除所有cookie
  const delAllCookie = () => {
    const domain = '.' + window.location.host
    const keys = document.cookie.match(/[^ =;]+(?=\=)/g)
    if (keys) {
      keys.forEach((key) => {
        console.log('key:', key)
        cookie.remove(key)
        // let date = new Date();
        // date.setTime(date.getTime() - 10000);
        // document.cookie = key + "=; expire=" + date.toUTCString() + "; Domain=" + domain + "; path=/";
      })
    }
  }

  async function getRefreshToken(token) {
    await $http('/oauth/refreshTokenDetail', { token }).then((res) => {
      console.log('res?.data:', res?.data)
      localStorage.setItem('expTime', res?.data?.exp)
    })
  }

  async function getToken(code) {
    const response = await axios({
      url: `${process.env.REACT_APP_WEB_URL}/oauth/getToken`,
      method: 'POST',
      data: {
        redirectUri: `${process.env.REACT_APP_REDIRECTURL}/indexPage`,
        code: code,
        grantType: 'authorization_code',
      },
    })
      .then((result) => {
        const { data } = result?.data
        if (data?.access_token !== undefined) {
          localStorage.setItem('access_token', data?.access_token)
          localStorage.setItem('refresh_token', data?.refresh_token)
          localStorage.setItem('userId', data?.sub)
          // localStorage.setItem("expTime", result.data.data.exp);

          setToken(result.data.data.access_token)
          // window.location.reload();
        } else {
          alert('登錄未成功，請刷新頁面重試')
        }
        return data?.access_token
      })
      .catch((error) => {
        console.log(error)
        navigate('/indexPage')
      })
    return response
  }

  useEffect(() => {
    delAllCookie()
    let search = location.search
    let urlParams = new URLSearchParams(search)
    const code = urlParams.get('code')
    if (!code && localStorage.getItem('access_token') === null) {
      navigate('/login')
      return
    }
    console.log('code:', code)

    if (code) {
      getToken(code).then((result) => {
        console.log('result:', result)
        getRefreshToken(result).then(() => {
          window.location.href = process.env.REACT_APP_REDIRECTURL + '/homePage'
        })
      })
    }
    if (localStorage.getItem('access_token')) {
      window.location.href = process.env.REACT_APP_REDIRECTURL + '/homePage'
    }
  }, [])
}
