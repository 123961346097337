import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  FormControl,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, createSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InputDropDown from "src/components/use-form/InputDropDown";
import { useForm } from "react-hook-form";
import CustomDialog from "src/components/common/CustomDialog";
import * as apiAtt from "src/utils/api/workerAttendanceRecord/AutoPayApiAttend";

// 承判商派駐地盤安全主任/安全督導員登記記錄--搜索界面
export default function RegistrationRecordSearch() {
  const navigate = useNavigate();
  const componentRef = useRef();
  const { t } = useTranslation();
  const [siteId, setSiteId] = useState("");
  const [jobType, setJobType] = useState("");
  const [job, setJob] = useState("");
  const [siteDesc, setSiteDesc] = useState("");
  const [siteList, setSiteList] = useState([]);
  const [jobTypeList, setJobTypeList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [shortPeriodDateFrom, setShortPeriodDateFrom] = useState("");
  const [shortPeriodDateTo, setShortPeriodDateTo] = useState("");

  useEffect(() => {}, []);

  const handleBack = () => {
    navigate(-1);
  };

  const { control, handleSubmit, setValue, getValues, reset } = useForm({
    defaultValues: {
      jobType: "",
      job: "",
      dateFrom: "",
      dateTo: "",
      outputType: "",
    },
  });

  const resetForm = (data) => {
    setShortPeriodDateFrom("");
    setShortPeriodDateTo("");
    setJobType("");
    setJob("");
    setSiteId("");
    setSiteDesc("");
  };

  const outPutList = [
    { val: "", text: "Please Select..." },
    { val: "1", text: "Screen" },
    { val: "2", text: "Excel" },
    { val: "3", text: "Pdf" },
  ];

  const handleSiteId = (data) => {
    setSiteId(data.id);
    setSiteDesc(data.text);
  };

  const handleJobType = (data) => {
    setJobType(data.id);
  };

  const handleJob = (data) => {
    setJob(data.id);
  };
  const [dialog, setDialog] = useState({
    title: "",
    content: "",
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false });
        }}
      >
        {t("Confirm")}
      </Button>
    ),
    open: false,
  });

  const showDetailResult = (data) => {
    if (!shortPeriodDateFrom) {
      setDialog({
        ...dialog,
        content: t("search_chooseApproveDateFrom_tips"),
        open: true,
      });
      return;
    }
    if (!shortPeriodDateTo) {
      setDialog({
        ...dialog,
        content: t("search_chooseApproveDateTo_tips"),
        open: true,
      });
      return;
    }

    navigate({
      pathname:
        "/workerAttendanceRecord/adocspMain/registrationRecord/registrationRecordDetail",
      search: `?${createSearchParams(
        {
          dateFrom: shortPeriodDateFrom,
          dateTo: shortPeriodDateTo,
          jobType: jobType,
          job: job,
          siteId: siteId,
          siteDesc: siteDesc,
        },
        { state: { fromSpecificPage: true } }
      )}`,
    });
  };

  useEffect(() => {
    const getFillSite = async () => {
      let getFillSiteSelect = await apiAtt.getFillSite();
      if (getFillSiteSelect) {
        const result = getFillSiteSelect;
        setSiteList(result);
      }
    };
    const getfilljobNatureId = async () => {
      let getfilljobNatureIdSelect = await apiAtt.getfilljobNatureId();
      if (getfilljobNatureIdSelect) {
        const result = getfilljobNatureIdSelect;

        setJobTypeList(result);
      }
    };
    const getfillSafetyPosition = async () => {
      let getfillSafetyPositionSelect = await apiAtt.getfillSafetyPosition();
      if (getfillSafetyPositionSelect) {
        const result = getfillSafetyPositionSelect;

        setJobList(result);
      }
    };
    getFillSite();
    getfilljobNatureId();
    getfillSafetyPosition();
  }, []);

  return (
    <>
      {
        <>
          <div style={{ display: "block", textAlign: "left" }}>
            <Button sx={{ float: "left" }} onClick={handleBack}>
              <ArrowBackIcon />
              {t("button_back")}
            </Button>
          </div>
          <div id="div" ref={componentRef}>
            <Container>
              <div>
                <div style={{ display: "block" }}>
                  <Typography
                    variant="h4"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    <b>{t("search_atrrot_records")}</b>
                  </Typography>
                  <Container
                    sx={{
                      width: "500px",
                      borderColor: "#d3d3d3",
                      borderRadius: 4,
                    }}
                  >
                    <Grid
                      item
                      xs={6}
                      sx={{ margin: "auto", width: "400px", marginTop: "10px" }}
                    >
                      <TextField
                        id="siteDesc"
                        value={siteId || ""}
                        fullWidth
                        size="small"
                        label={t("Site_Name")}
                        select
                      >
                        {siteList.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item.id}
                            name={item.text}
                            onClick={() => handleSiteId(item)}
                          >
                            {item.id} - {item.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      sx={{ margin: "auto", width: "400px", marginTop: "10px" }}
                    >
                      <TextField
                        id="siteDesc"
                        value={jobType || ""}
                        fullWidth
                        size="small"
                        label={t("search_work_type")}
                        select
                      >
                        {jobTypeList.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item.id}
                            name={item.text}
                            onClick={() => handleJobType(item)}
                          >
                            {item.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      sx={{ margin: "auto", width: "400px", marginTop: "10px" }}
                    >
                      <TextField
                        id="siteDesc"
                        value={job || ""}
                        fullWidth
                        size="small"
                        label={t("search_employmentPosition")}
                        select
                      >
                        {jobList.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item.id}
                            name={item.text}
                            onClick={() => handleJob(item)}
                          >
                            {item.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={6} sx={{ margin: "auto", width: "400px" }}>
                      <TextField
                        type="date"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        sx={{
                          textAlign: "left",
                          maxWidth: "400px",
                          mt: "20px",
                        }}
                        label={t("search_approve_date_from")}
                        error={!shortPeriodDateFrom}
                        inputProps={{
                          max: "2099-12-31",
                        }}
                        value={shortPeriodDateFrom}
                        onChange={(event) => {
                          setShortPeriodDateFrom(event.target.value);
                        }}
                        format="yyyy/MM/dd"
                      />
                    </Grid>

                    <Grid item xs={6} sx={{ margin: "auto", width: "400px" }}>
                      <TextField
                        type="date"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        sx={{
                          textAlign: "left",
                          maxWidth: "400px",
                          mt: "20px",
                        }}
                        label={t("search_approve_date_to")}
                        error={!shortPeriodDateTo}
                        inputProps={{
                          max: "2099-12-31",
                        }}
                        value={shortPeriodDateTo}
                        onChange={(event) => {
                          setShortPeriodDateTo(event.target.value);
                        }}
                        format="yyyy/MM/dd"
                      />
                    </Grid>

                    {/* <Grid item xs={12} sx={{ margin: 'auto', width: '400px', marginTop: '10px' }}>
                      <InputDropDown
                        control={control}
                        data={outPutList}
                        label={t("search_output_type")}
                        name="outputType"
                        size="small"
                      />
                    </Grid> */}
                  </Container>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "18px",
                }}
              >
                {/* 提交按钮 */}
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <FormControl size="small">
                    <Button
                      sx={{
                        backgroundColor: "primaryBlue",
                        color: "commom.white",
                      }}
                      variant="contained"
                      onClick={handleSubmit(showDetailResult)}
                    >
                      {t("button_submit")}
                    </Button>
                  </FormControl>

                  {/* 重置按钮 */}
                  <FormControl size="small">
                    <Button
                      variant="contained"
                      onClick={resetForm}
                      label="Reset"
                      sx={{
                        backgroundColor: "primaryGreen",
                        color: "commom.white",
                        marginLeft: "20px",
                      }}
                    >
                      {t("button_reset")}
                    </Button>
                  </FormControl>
                </Grid>
              </div>
            </Container>
          </div>
        </>
      }

      <CustomDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        action={dialog.action}
      />
    </>
  );
}
