import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import html2canvas from 'html2canvas'
import DownloadIcon from '@mui/icons-material/Download'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import $http, { $download } from 'src/utils/api/services'
import './Print.css'

export default function Checking(props) {
  const { t } = useTranslation()
  const { setStep, checkTable, sendData } = props
  const [peopleCount, setPeopleCount] = useState(0)
  const [incomeCount, setIncomeCount] = useState(0)
  const contentRef = useRef(null)

  const exportExcel = (data) => {
    $download(`/payrollReportController/checkListExcel`, 'application/vnd.ms-excel', data)
  }

  useEffect(() => {
    let iCount = 0
    let setList = new Set()
    for (let item of checkTable.subDataMain || []) {
      for (let value of item.subdataValue) {
        iCount += value.relevantIncome
        setList.add(value.cardHolderId)
      }
    }
    setPeopleCount(setList.size)
    setIncomeCount(iCount)
  }, [checkTable])
  const handleBack = () => {
    setStep(0)
  }
  function handleCountIncome(index) {
    let count = 0
    let list = checkTable?.subDataMain[index]?.subdataValue || []
    for (let item of list) {
      count += item.relevantIncome
    }
    return count.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const handlePrint = async () => {
    try {
      const content = contentRef.current
      // 定义截图的比例，例如设置为2表示生成的图像分辨率是原始的两倍
      const scale = 0.5
      const dpi = 300 // 处理模糊问题
      if (content) {
        const canvas = await html2canvas(content, { scale: scale, dpi: dpi })
        const imgData = canvas.toDataURL('image/png')

        // 创建一个新窗口用于打印
        const printWindow = window.open('', '', 'width=600,height=600')
        printWindow.document.open()
        printWindow.document.write('<img src="' + imgData + '" />')
        printWindow.document.close()

        // 等待图像加载完成后再执行打印
        printWindow.onload = function () {
          printWindow.print()

          printWindow.close()
        }
      }
    } catch (error) {
      console.error('打印出错：', error)
    }
  }

  return (
    <>
      <div>
        <div style={{ display: 'block', textAlign: 'left', marginTop: '0px' }}>
          <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
            <ArrowBackIcon />
            {t('button_back')}
          </Button>
        </div>

        <div ref={contentRef} class="print-content">
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h4" textAlign="center">
              <b>{t('Verify_worker_salary/MPF_information')}</b>
            </Typography>
          </div>
          <div style={{ display: 'block', textAlign: 'right' }}>
            {/* <Button id="capture" sx={{ float: 'right', mr: '20px' }} onClick={() => handlePrint()}>
              {t('列印')}
            </Button> */}
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportExcel(sendData)}>
              <DownloadIcon />
              {t('button_export_excel')}
            </Button>
          </div>

          {/* 表頭數據 */}

          <TableContainer>
            <Table padding="none" sx={{ textAlign: 'left', mt: '10px' }} size="small">
              <TableBody>
                <TableRow>
                  {/* 大糧期:	 */}
                  <TableCell sx={{ fontSize: '15px', width: 200, borderBottom: 'none' }}>
                    <b>{t('Big_Pay_Period')}:</b>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '15px',
                      textAlign: 'left',
                      borderBottom: 'none',
                    }}
                  >
                    {sendData.dateFrom}~{sendData.dateTo}
                  </TableCell>
                </TableRow>
                <TableRow>
                  {sendData.payrollType === 'P' ? (
                    <>
                      {/* 部分糧期: */}
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          width: 200,
                          borderBottom: 'none',
                        }}
                      >
                        <b>{t('Partial_pay_period')}:</b>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          textAlign: 'left',
                          borderBottom: 'none',
                        }}
                      >
                        {sendData.subBatchId}-{sendData.dateFrom}-{sendData.dateTo}
                      </TableCell>
                    </>
                  ) : (
                    <>
                      {/* 短糧期: */}
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          width: 200,
                          borderBottom: 'none',
                        }}
                      >
                        <b>{t('Short_Pay_Period')}:</b>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          textAlign: 'left',
                          borderBottom: 'none',
                        }}
                      >
                        {sendData.subDesc}
                      </TableCell>
                    </>
                  )}
                </TableRow>
                <TableRow>
                  {/* 地盤名稱: */}
                  <TableCell sx={{ fontSize: '15px', width: 200, borderBottom: 'none' }}>
                    <b>{t('Site_Name')}:</b>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '15px',
                      textAlign: 'left',
                      borderBottom: 'none',
                    }}
                  >
                    {sendData.siteDesc}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {/* 循環出分盤商數據 */}
          {checkTable.subDataMain?.length > 0 ? (
            <>
              {checkTable.subDataMain.map((item, index) => (
                <div key={index}>
                  <Typography sx={{ display: 'block', fontSize: '15px', pt: '20px' }}>
                    <b>
                      {t('Subcontractor_Name')}: {item.subdataKey}
                    </b>
                  </Typography>
                  <TableContainer sx={{ maxHeight: '80vh', maxWidth: '100vw' }}>
                    <Table
                      sx={{
                        mt: '10px',
                        minWidth: 650,
                        '& .MuiTableCell-root': {
                          paddingLeft: '6px',
                          paddingRight: '6px',
                        },
                      }}
                      size="small"
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow hover sx={{ border: '0px' }}>
                          <TableCell
                            sx={{
                              fontSize: '15px',
                              textAlign: 'left',
                              width: '1%',
                            }}
                          >
                            No
                          </TableCell>
                          {/* 英文姓名 */}
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              textAlign: 'left',
                              width: '5%',
                            }}
                          >
                            {t('EnglishName')}
                          </TableCell>
                          {/* 中文姓名 */}
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              textAlign: 'left',
                              width: '3%',
                            }}
                          >
                            {t('ChineseName')}
                          </TableCell>
                          {/* 身份証號碼 */}
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              width: '5%',
                            }}
                          >
                            {t('ID_number')}
                          </TableCell>
                          {/* 實際上班日期(由) */}
                          <TableCell
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              width: '5%',
                            }}
                          >
                            {t('Actual_start_date_(From)')}
                          </TableCell>
                          {/* 實際上班日期(至) */}
                          <TableCell
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              width: '5%',
                            }}
                          >
                            {t('Actual_start_date_(To)')}
                          </TableCell>
                          {/* 工人上班日數 */}
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              textAlign: 'right',
                              width: '3%',
                            }}
                          >
                            {t('Number_Of_Workers_Working_Days')}
                          </TableCell>
                          {/* 實際上班日數 */}
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              textAlign: 'right',
                              width: '3%',
                            }}
                          >
                            {t('Actual_number_of_working_days')}
                          </TableCell>
                          {/* 每天入息 */}
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              textAlign: 'right',
                              width: '5%',
                            }}
                          >
                            {t('Daily_Income')}
                          </TableCell>
                          {/* 工人入息 */}
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              textAlign: 'right',
                              width: '5%',
                            }}
                          >
                            {t('Worker_income')}
                          </TableCell>
                          {/* 登記平均入息 */}
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              textAlign: 'right',
                              width: '5%',
                            }}
                          >
                            {t('icon1_autopay_button7_label6')}
                          </TableCell>
                          {/* 員工戶口號碼 */}
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              textAlign: 'left',
                              width: '7%',
                            }}
                          >
                            {t('Employee_Account_Number')}
                          </TableCell>
                          {/* 計劃成員編號 */}
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              textAlign: 'left',
                              width: '7%',
                            }}
                          >
                            {t('plan_Member_Number')}
                          </TableCell>

                          {/* 日薪/月薪 */}
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              width: '5%',
                            }}
                          >
                            {t('Daily/Monthly_Salary')}
                          </TableCell>
                          {/* 備註 */}
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              width: '5%',
                            }}
                          >
                            {t('Remark')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {checkTable.subDataMain[index].subdataValue.length > 0 ? (
                          <>
                            {checkTable.subDataMain[index].subdataValue.map((row, i) => (
                              <TableRow
                                sx={{
                                  backgroundColor: row.shortdatefrom === null || row.shortdateto === null ? '#deba84' : '#ffffff',
                                }}
                                hover
                                key={i}
                              >
                                <TableCell sx={{ fontSize: '15px' }} component="th" scope="row">
                                  {i + 1}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: '15px',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {row.englishName}{' '}
                                </TableCell>
                                <TableCell align="center" sx={{ fontSize: '15px' }}>
                                  {row.chineseName}
                                </TableCell>
                                <TableCell align="center" sx={{ fontSize: '15px' }}>
                                  {row.hkid}
                                </TableCell>
                                <TableCell align="right" sx={{ fontSize: '15px' }}>
                                  {row.shortdatefrom}
                                </TableCell>
                                <TableCell align="right" sx={{ fontSize: '15px' }}>
                                  {row.shortdateto}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    fontSize: '15px',
                                    textAlign: 'right',
                                  }}
                                >
                                  {row.workDay}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    fontSize: '15px',
                                    textAlign: 'right',
                                  }}
                                >
                                  {row.workDayAct}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    fontSize: '15px',
                                    textAlign: 'right',
                                  }}
                                >
                                  {row.dailyRateForMat}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    fontSize: '15px',
                                    textAlign: 'right',
                                  }}
                                >
                                  {row.relevantIncomeForMat}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    fontSize: '15px',
                                    textAlign: 'right',
                                  }}
                                >
                                  {row.minbasicsalaryForMat}
                                </TableCell>
                                <TableCell align="right" sx={{ fontSize: '15px' }}>
                                  {row.bankAccount}
                                </TableCell>
                                <TableCell align="right" sx={{ fontSize: '15px' }}>
                                  {row.mpfScheme}
                                </TableCell>
                                {sendData.payrollType === 'S' ? (
                                  sendData.subBatchPeriod === 'F' ? (
                                    <>
                                      <TableCell
                                        align="center"
                                        sx={{
                                          fontSize: '15px',
                                          textAlign: 'right',
                                        }}
                                      >
                                        {row.dailyIncome}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        sx={{
                                          fontSize: '15px',
                                          textAlign: 'right',
                                        }}
                                      >
                                        {row.workDayAct}
                                      </TableCell>
                                    </>
                                  ) : null
                                ) : sendData.payrollType === 'F' ? (
                                  <>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        fontSize: '15px',
                                        textAlign: 'right',
                                      }}
                                    >
                                      {0}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        fontSize: '15px',
                                        textAlign: 'right',
                                      }}
                                    >
                                      {0}
                                    </TableCell>
                                  </>
                                ) : null}

                                <TableCell align="center" sx={{ fontSize: '15px' }}>
                                  {row.type === 'D' ? '日薪' : '月薪'}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: '15px',
                                    wordBreak: 'keep-all',
                                  }}
                                >
                                  {row.payrollRemark}
                                </TableCell>
                                {/* <TableCell align="left" sx={{ fontSize: '15px' }}>{row.mpfScheme}</TableCell>
                                                        <TableCell align="left" sx={{ fontSize: '15px' }}>{row.payrollRemark}</TableCell> */}
                              </TableRow>
                            ))}
                            <TableRow hover>
                              <TableCell colSpan="9" sx={{ textAlign: 'right', fontSize: '15px' }}>
                                {t('Total_income_of_subcontractor_workers')}
                              </TableCell>
                              <TableCell colSpan="8" align="right" sx={{ fontSize: '15px' }}>
                                {handleCountIncome(index)}
                              </TableCell>
                            </TableRow>
                          </>
                        ) : (
                          <TableRow>
                            <TableCell colSpan="17" sx={{ textAlign: 'center', fontSize: '15px' }}>
                              No Record
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ))}
            </>
          ) : (
            <Typography sx={{ textAlign: 'center', fontSize: '20px', mt: '20px' }}>No Record!</Typography>
          )}
          <Typography sx={{ fontSize: '15px', mt: '20px' }}>
            <b>
              {t('Total_income')}:{incomeCount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
            </b>
          </Typography>
          <Typography sx={{ fontSize: '15px' }}>
            <b>
              {t('Total_Number')}:{peopleCount}
            </b>
          </Typography>
          <Typography sx={{ fontSize: '15px', mt: '10px' }}>
            <b>{t('Orange_Color_User_Not_input')}</b>
          </Typography>
        </div>
      </div>
    </>
  )
}
