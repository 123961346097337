import React, { useRef, useEffect } from "react";
import {
  Button,
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Link,
  TableHead,
  TableRow,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as apiAtt from "src/utils/api/workerAttendanceRecord/AutoPayApiAttend";
import { getComparator, stableSort } from "../../TableSort";

export default function ApplyListDetail() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const componentRef = useRef();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("yearmonth");
  const [hasApproval, setHasApproval] = React.useState(false);
  const [hasHistory, setHasHistory] = React.useState(false);

  const showEditDialog = (value) => {
    if(!value.editBtnText){
      return;
    }

    let isViewData = "false";
    if (value.editBtnText === "View") {
      isViewData = "true";
    } else {
      isViewData = "false";
    }
    console.log("showEditDialog=-=applicationId", value);

    navigate({
      pathname: "/workerAttendanceRecord/adocspMain/applyList/applyEditOrView",
      // isViewData 为true的时候表示是查看数据 为false的时候表示编辑数据
      search: `?${createSearchParams({
        isViewData: isViewData,
        applicationId: value.applicationId || "",
        applicationSeqNo: value.applicationSeqNo || "",
        smartCardId: value.smartCardId,
        siteId: value.siteId,
        vendorId: value.vendorId,
        yearmonth: value.yearmonth,
        effectiveDate: value.effectiveDate,
      })}`,
    });
  };

  const handleBack = () => {
    navigate("/workerAttendanceRecord/adocspMain/applyList/ApplyList");
  };

  function clickHeaderSortButton(index) {
    // eslint-disable-next-line default-case
    switch (index) {
      case 1:
        setOrderBy("yearmonth");
        break;
    }


    if (order === "asc") {
      setOrder("desc");
    } else {
      setOrder("asc");
    }
  }

  const showHistory =(row) =>{
    navigate({
      pathname: "/workerAttendanceRecord/adocspMain/applyList/applyHistoryView",
      search: `?${createSearchParams({
        applicationId: row.applicationId,
        smartCardId: row.smartCardId,
        siteId: row.siteId,
        vendorId: row.vendorId,
        yearmonth: row.yearmonth,
        effectiveDate: row.effectiveDate,
      })}`,
    });
  }

  function showOperation(row) {
    if (row.editBtnText === "New") {
      return <Link
        style={{ cursor: "pointer" }}
        underline="none"
        sx={{ whiteSpace: "nowrap" }}
        onClick={() => showEditDialog(row)}
      >
        {t("button_new")}
      </Link>
    } else if (row.editBtnText === "Edit") {
      return <Link
        style={{ cursor: "pointer" }}
        underline="none"
        sx={{ whiteSpace: "nowrap" }}
        onClick={() => showEditDialog(row)}
      >
        {t("button_edit")}
      </Link>
    } else if (row.editBtnText === "View") {
      return <Link
        style={{ cursor: "pointer" }}
        underline="none"
        sx={{ whiteSpace: "nowrap" }}
        onClick={() => showEditDialog(row)}
      >
        {t("button_view")}
      </Link>
    }

    return null;
  }

  const [headerData, setHeaderData] = React.useState({});
  const [contentData, setContentData] = React.useState([]);

  useEffect(() => {
    const getPFSApplicationApplyDetail = async () => {
      let data = await apiAtt.getPFSApplicationApplyDetail(
        searchParams.get("smartCardId"),
        searchParams.get("siteId"),
        searchParams.get("effectiveDate1")
      );
      console.log(searchParams.get("dateTo"));
      if (data !== null) {
        setHeaderData(data);
      }

      setLoading(false);
    };

    const getPFSApplicationApplyDetailVoForm = async () => {
      let contentList = await apiAtt.getPFSApplicationApplyDetailVoForm(
        searchParams.get("smartCardId"),
        searchParams.get("siteId"),
        searchParams.get("effectiveDate1")
      );
      if (contentList !== null) {
        setHasApproval(contentList.filter((item)=> item.editBtnText==="View" && item.applicationStatusId !== "00").length>0);
        setHasHistory(contentList.filter((item)=> item.noOfHistory>0).length>0);
        setContentData(contentList);
      }
    };

    getPFSApplicationApplyDetailVoForm();
    getPFSApplicationApplyDetail();
  }, []);

  return (
    <>
      <div style={{ display: "block", textAlign: "left" }}>
        <Button sx={{ float: "left" }} onClick={handleBack}>
          <ArrowBackIcon />
          {t("button_back")}
        </Button>
      </div>
      <div style={{ display: "block" }}>
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          <b>{t("search_vendor_ss_outDays")}</b>
        </Typography>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div id="div" ref={componentRef} style={{}}>
        <Container maxWidth="100%">
          {/*  */}
          {headerData.smartCardId && (
            <div>
              <TableContainer
                component={Paper}
                sx={{ marginBottom: "30px", backgroundColor: "transparent" }}
              >
                <Table sx={{ textAlign: "left" }} size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_site_title_dot")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.siteDescription}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_smartCardId_dot")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.smartCardId}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_chinese_name_dot")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.chineseName}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_english_name_dot")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.englishName}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_employment_position")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.safetyPositionDesc}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_approve_date_dot")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {searchParams.get("spApprovedDate") !== "undefined"
                          ? searchParams.get("spApprovedDate")
                          : " "}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_in_efficient_date")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {searchParams.get("effectiveDate1") !== null
                          ? searchParams.get("effectiveDate1")
                          : " "}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_in_valite_date_to")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {searchParams.get("dateTo")
                          ? searchParams.get("dateTo")
                          : null}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
          {/*  */}
          <div>
            <TableContainer>
              <Table sx={{ minWidth: 650, p: "2px" }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Link
                        style={{ cursor: "pointer" }}
                        underline="none"
                        sx={{ whiteSpace: "nowrap" }}
                        onClick={() => clickHeaderSortButton(1)}
                      >
                        {t("search_date_title")}
                      </Link>
                    </TableCell>
                    <TableCell>{t("search_handle_title")}</TableCell>
                    <TableCell>{t("button_submit")}</TableCell>
                    <TableCell>{t("search_submitDate")}</TableCell>
                    <TableCell>{t("search_statues_not_dot")}</TableCell>
                    {hasApproval &&
                    <TableCell>{t("search_approveDateTime")}</TableCell>}
                    {hasHistory &&
                    <TableCell>{t("search_history")}</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {排序} attendDayList.sort(currentSortType[currentSort].fn)  */}
                  {stableSort(
                    contentData,
                    getComparator(order, orderBy)
                  ).map((row, index) => (
                    <TableRow key={index + Math.random}>
                      <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                        {row.yearmonth}
                      </TableCell>
                      <TableCell>
                        {showOperation(row)}
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                        {row.submitText}
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                        {row.submitDate !== null
                          ? row.submitDate + " " + row.submitTime
                          : ""}
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                        {row.statusDesc}
                      </TableCell>
                      {hasApproval &&
                      <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                        {row.approvalDatetime}
                      </TableCell>}
                      {hasHistory &&
                      <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                        {
                          row.noOfHistory > 0 && <Link
                            style={{ cursor: "pointer" }}
                            underline="none"
                            sx={{ whiteSpace: "nowrap" }}
                            onClick={() => showHistory(row)}
                          >
                            {t("details")}
                          </Link>
                        }
                      </TableCell>}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Container>
      </div>
    </>
  );
}
