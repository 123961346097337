import { Button, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save'
import { useState } from 'react'
import { createRoot } from 'react-dom/client'
import { useTranslation } from "react-i18next";

import CDialog from './CDialog'

function Confirm(props) {
  const { t } = useTranslation();
  const { title, content, okText, okColor = 'primary', onOk = () => { }, cancelText, onCancel } = props
  const [open, setOpen] = useState(true)
  const [loading, setLoading] = useState(false)

  const handleOk = () => {
    const result = onOk()
      ?.then(() => {
        setOpen(false)
      })
      ?.catch(() => {
        setLoading(false)
      })
    // 判断是否异步关闭弹窗
    if (result instanceof Promise) {
      setLoading(true)
    } else {
      setOpen(false)
    }
  }

  return (
    <CDialog fullWidth maxWidth="sm" title={title} open={open} disableCloseIcon={false} disableEscapeKeyDown={true} onClose={onCancel || (() => { })}>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {onCancel ? (
          <Button disabled={loading} onClick={() => { onCancel(); setOpen(false) }}>
            {cancelText || t('button_cancel')}
          </Button>
        ) : null}
        <LoadingButton color={okColor} loadingPosition={loading ? 'start' : 'center'} loading={loading} startIcon={loading && <SaveIcon />} onClick={handleOk}>
          {okText || t('Confirm')}
        </LoadingButton>
      </DialogActions>
    </CDialog>
  )
}

export default function ConfirmDialog(data) {
  document.getElementById('dialog-confirm')?.remove()
  const div = document.createElement('div')
  div.id = 'dialog-confirm'
  document.body.appendChild(div)
  const root = createRoot(div)
  root.render(<Confirm {...data} />)
}
