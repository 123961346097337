import React from "react";
import { Snackbar } from "@mui/material";

export default function CustomSnackbar(props) {
  const { open, action, message } = props;
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      message={message}
      action={action}
    />
  );
}
