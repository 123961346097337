import React, { useRef, useEffect } from 'react'
import { Container, Typography, Paper, Table, TableBody, TableHead, TableCell, TableContainer, TableRow, Backdrop, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import DownloadIcon from '@mui/icons-material/Download'
import $http, { $download } from 'src/utils/api/services'
import moment from 'moment'
import { sub } from 'date-fns'

export default function CattpwspPerMonthDetail() {
  const componentRef = useRef()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [dataSource, setDataSource] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  useEffect(() => {}, [])

  const handleBack = () => {
    navigate(-1)
  }

  const printComponentRef = useRef()

  useEffect(() => {
    getVendorAutoPaybeHaveMonthListTwolist()
  }, [])

  const getVendorAutoPaybeHaveMonthListTwolist = async () => {
    let list = await apiAtt.getVendorAutoPaybeHaveMonthListTwolist(searchParams.get('dataYear'), searchParams.get('siteId'), searchParams.get('vendorId'), searchParams.get('tradeId'))

    if (list !== null) {
      setDataSource(list)
    }
    setLoading(false)
  }

  const getLastMonth = () => {
    const myMomentObject = moment(searchParams.get('dataYear'), 'YYYY/MM')
    let t = moment(myMomentObject).subtract(11, 'months').format('YYYY/MM')
    return t + '到' + searchParams.get('dataYear')
  }

  const exportPdf = (data) => {
    $download(`/payrollReportController/vendorAutoPaybeHaveMonthListTwolistPdf`, 'application/pdf', data)
  }

  const exportExcel = (data) => {
    $download(`/payrollReportController/vendorAutoPaybeHaveMonthListTwolistExcel`, 'application/vnd.ms-excel', data)
  }

  return (
    <Container maxWidth={false}>
      <div style={{ display: 'block', textAlign: 'left' }}>
        <Button sx={{ float: 'left' }} onClick={handleBack}>
          <ArrowBackIcon />
          {t('button_back')}
        </Button>
      </div>

      <div style={{ display: 'block', textAlign: 'right' }}>
        <Button
          sx={{ float: 'right', mr: '20px' }}
          onClick={() =>
            exportPdf({
              year: searchParams.get('dataYear'),
              jobNatureId: searchParams.get('tradeId'),
              siteId: searchParams.get('siteId'),
              vendorId: searchParams.get('vendorId'),
              vendorDesc: searchParams.get('vendorDesc'),
              jobNatureDesc: searchParams.get('tradeDesc'),
            })
          }
        >
          <DownloadIcon />
          {t('button_export_pdf')}
        </Button>
        <Button
          sx={{ float: 'right', mr: '20px' }}
          onClick={() =>
            exportExcel({
              year: searchParams.get('dataYear'),
              jobNatureId: searchParams.get('tradeId'),
              siteId: searchParams.get('siteId'),
              vendorId: searchParams.get('vendorId'),
              vendorDesc: searchParams.get('vendorDesc'),
              jobNatureDesc: searchParams.get('tradeDesc'),
            })
          }
        >
          <DownloadIcon />
          {t('button_export_excel')}
        </Button>
      </div>

      <div id="div" style={{}} ref={printComponentRef}>
        <Container maxWidth={false}>
          <div>
            <div style={{ display: 'block', marginBottom: '60px' }}>
              <Typography variant="h4" sx={{ textAlign: 'center' }}>
                <b>{t('search_vendor_aotuoPayMoneyDispay') + getLastMonth() + ')'}</b>
              </Typography>
            </div>

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>

            {
              <div>
                <TableContainer component={Paper} sx={{ marginBottom: '20px', backgroundColor: 'transparent' }}>
                  <Table sx={{ textAlign: 'left' }} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('search_site_title') + '：'} </TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{searchParams.get('siteDesc')}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', width: 200, border: 0 }}>{t('Vendor') + '：'}</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>
                          {searchParams.get('vendorId') === '' ? t('all_contractors') : searchParams.get('vendorId') + '-' + searchParams.get('vendorDesc')}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('search_trade_title') + '：'}</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{searchParams.get('tradeId') === '' ? t('all_outfits') : searchParams.get('tradeDesc')}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>Total:</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{dataSource.length}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }

            {/* table */}
            <TableContainer sx={{ marginTop: '20px', maxHeight: '80vh', maxWidth: '100vw' }}>
              <Table sx={{ p: '2px' }} size="small" stickyHeader>
                <TableHead>
                  {/* TableRow是行，表示有多少行 */}
                  {/* TableCell是列，橫著看 */}
                  <TableRow hover>
                    <TableCell rowSpan={4} sx={{ whiteSpace: 'nowrap' }}>
                      {t('Site_Name')}
                    </TableCell>
                    <TableCell rowSpan={4} sx={{ whiteSpace: 'nowrap' }}>
                      {t('search_vendor_title')}
                    </TableCell>
                    <TableCell rowSpan={4} sx={{ whiteSpace: 'nowrap' }}>
                      行業
                    </TableCell>
                  </TableRow>
                  {/* 第一行 */}
                  <TableRow>
                    <TableCell colSpan={15} sx={{ textAlign: 'center' }}>
                      {getLastMonth()}
                    </TableCell>
                  </TableRow>

                  {/* 第二行 */}
                  <TableRow>
                    {/* 第1-2個 */}
                    <TableCell rowSpan={2} sx={{ textAlign: 'center' }}>
                      {t('search_autopay_date')}
                    </TableCell>
                    <TableCell rowSpan={2} sx={{ textAlign: 'center' }}>
                      {t('search_autopay_days')}
                    </TableCell>

                    <TableCell sx={{ textAlign: 'center' }}>{t('search_autopay_payroll_period_from')}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{t('search_autopay_payroll_period_to')}</TableCell>

                    {/* 後面的數據 */}
                    <TableCell rowSpan={2} sx={{ textAlign: 'center' }}>
                      {t('search_participants_persons')}
                    </TableCell>
                    <TableCell rowSpan={2} sx={{ textAlign: 'center' }}>
                      {t('search_percent')}
                    </TableCell>
                    <TableCell rowSpan={2} sx={{ textAlign: 'center' }}>
                      {t('search_non_participatine')}
                    </TableCell>
                    <TableCell rowSpan={2} sx={{ textAlign: 'center' }}>
                      {t('search_percent')}
                    </TableCell>
                    <TableCell rowSpan={2} sx={{ textAlign: 'center' }}>
                      {t('search_total_people')}
                    </TableCell>
                    <TableCell rowSpan={2} sx={{ textAlign: 'center' }}>
                      {t('search_participatin_days')}
                    </TableCell>
                    <TableCell rowSpan={2} sx={{ textAlign: 'center' }}>
                      {t('search_percent')}
                    </TableCell>
                    <TableCell rowSpan={2} sx={{ textAlign: 'center' }}>
                      {t('search_non_participatin_days')}
                    </TableCell>
                    <TableCell rowSpan={2} sx={{ textAlign: 'center' }}>
                      {t('search_percent')}
                    </TableCell>
                    <TableCell rowSpan={2} sx={{ textAlign: 'center' }}>
                      {t('search_totalDays')}
                    </TableCell>
                    <TableCell rowSpan={2} sx={{ textAlign: 'center' }}>
                      {t('search_total_income_ofWorker')}
                    </TableCell>
                    <TableCell rowSpan={2} sx={{ textAlign: 'center' }}>
                      {t('search_average_daily_mefi_worker')}
                    </TableCell>
                    <TableCell rowSpan={2} sx={{ textAlign: 'center' }}>
                      {t('search_average_dmeoi_worker')}
                    </TableCell>
                    <TableCell rowSpan={2} sx={{ textAlign: 'center' }}>
                      {t('search_worker_average_income')}
                    </TableCell>
                  </TableRow>
                </TableHead>

                {dataSource.map((dataRow, index) => (
                  <TableRow
                    hover
                    key={index + Math.random}
                    sx={{
                      backgroundColor: index % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                    }}
                  >
                    {/* 地盤名稱 */}
                    <TableCell>{dataRow.withAutoPaySiteDesc} </TableCell>
                    {/* 承判商名稱 */}
                    <TableCell>{dataRow.withAutoPayvenDorDesc} </TableCell>
                    {/* 行業 */}
                    <TableCell>{dataRow.withAutoPayjobNatureDesc} </TableCell>
                    {/* 自動轉賬日期 */}
                    <TableCell>{dataRow.withAutoPayDate} </TableCell>
                    {/* 自動轉賬日數 */}
                    <TableCell>{dataRow.withAutoPayDateact} </TableCell>

                    <TableCell>{dataRow.withAutoPayDateFrom} </TableCell>
                    <TableCell>{dataRow.withAutoPayDateTo} </TableCell>

                    {/* 	參與人數 */}
                    <TableCell>{dataRow.workerSwithAutoPay} </TableCell>
                    <TableCell>{dataRow.workerSwithAutoPaye} </TableCell>
                    {/* 	非參與人數 */}
                    <TableCell>{dataRow.workerSwithOutAutoPay} </TableCell>
                    <TableCell>{dataRow.workerSwithOutAuto} </TableCell>
                    {/* 總人數 */}
                    <TableCell>{dataRow.totalWorkers} </TableCell>
                    {/* 參與日數 */}
                    <TableCell>{dataRow.workDayacTwithAutoPay} </TableCell>
                    <TableCell>{dataRow.workDayacTwithAutoPaye}</TableCell>
                    {/* 非參與日數 */}
                    <TableCell>{dataRow.workDayacTwithOutAuto} </TableCell>
                    <TableCell>{dataRow.workDayacTwithOutAutoe}</TableCell>
                    {/* 總日數 */}
                    <TableCell>{dataRow.totalWorkDayact} </TableCell>
                    {/* 工人總收入 */}
                    <TableCell>{dataRow.salaryWithAutoPayformat}</TableCell>
                    {/* 個別工人平均每日最低收入$ */}
                    <TableCell>{dataRow.minavGrelevanTincomeformat}</TableCell>
                    <TableCell>{dataRow.maxavGrelevanTincomeformat}</TableCell>
                    <TableCell>{dataRow.averaGesalaryformat}</TableCell>
                  </TableRow>
                ))}
              </Table>
            </TableContainer>
          </div>
        </Container>
      </div>
    </Container>
  )
}
