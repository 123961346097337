import { instance } from "../../instance";

// 日期下拉框
export const getDate = async (site) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getPeriodYear`,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
// 承判商名称
export const getSubContractorList = async (site) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getvendorId`,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 提交的第一個表格
export const getFirstTable = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/managementController/getMaintVendorAutoPayStatusadminR`,
    data: data,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 详情的报表
export const getDetailTable = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/searchGenerateAndPartialBatchReport`,
    data: data,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 空白的报表
export const getGapTable = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/managementController/maintAutoPayGapData`,
    data: data,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 刪除一行
export const deleteRowApi = async (row) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/managementController/maintVendorAutoPayStatusdetaildeleteaction`,
    data: row,
  }).then(
    (result) => {
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return null;
    }
  );
};

// 更改
export const changeRow = async (row) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/managementController/maintVendorAutoPayStatusdetailamendaction`,
    data: row,
  }).then(
    (result) => {
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return null;
    }
  );
};
