import { instance } from '../../instance'

// 地盘名称
export const getSiteList = async () => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getSite`,
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}
// 分判商名称
export const getSubContractorList = async (site) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getSubContractorId`,
    data: {
      siteId: site,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}

// 承判商名称
export const getVendorDesc = async () => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getVendorDesc`,
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}

// smartCard名称
export const getSmartCard = async (site) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getSmartCardId`,
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}

// get Report
export const getReport = async (data) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getAccRecList`,
    data: data,
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}
// get Report WithVendor
export const getReportWithVendor = async (data) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getAccRecListWithVendor`,
    data: data,
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}

// get Report
export const getPersonalReport = async (data) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getPersonalEnterList`,
    data: data,
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}

// get Report
export const getPersonalReportWithVendor = async (data) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getPersonalEnterListWithVendor`,
    data: data,
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}
