import { instance } from "../../instance";

// 地盘名称
export const getSiteList = async () => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/vps/common/getSiteAndTeam`,
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};
// 承判商名称
export const getVendorIdList = async () => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/vps/common/getfillVendorId`,
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

// 分判商名称
export const getSubContractorList = async (site) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/vps/common/getSubContractorId`,
        data: {
            siteId: site
        }
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

// 出粮方式
export const getEmpTypeId = async () => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/vps/common/getfillEmpTypeId`,
       
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};



// get Report  (一覽表)
export const getSummaryReport = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/getSummarySQLlistRVBS`,
        data: data
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

// get Report  (詳細記錄)
export const getDetailReport = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/getDetailSQLlistRVBS`,
        data: data
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

// 輸出PDF
export const getDetailSQLlistRVBSPdf = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/getDetailSQLlistRVBSPdf`,
        data: data,
        responseType: 'arraybuffer',
    })
        .then(
            (result) => {
                let url = window.URL.createObjectURL(new Blob([result.data]))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                const fileName = decodeURI(result.headers['content-disposition'].split(";")[1].split("filename=")[1]);
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};















