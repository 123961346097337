import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {
    Button,
    Collapse,
    Container,
    FormControl,
    Grid,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TablePagination,
    IconButton,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import * as api from "../../../utils/api/systemAdministrator/SystemParameterSetupApi";
import CustomDialog from "../../../components/common/CustomDialog";
import CDatePicker from "../../../components/common/CDatePicker";


// 添加數據接口
const handleAddData = async (body) => {
    try {
        // 调用接口
        const data = async () => {
            let data = await api.insertData(body);
            return data.message;
        }
        return data();
    } catch (error) {
        return null;
    }
};
// 修改數據接口
const handleUpdateData = async (body) => {
    try {
        // 调用接口
        const data = async () => {
            let data = await api.updateData(body);
            return data.message;
        }
        return data();
    } catch (error) {
        return null;
    }
};
// 刪除數據接口
const handleDeleteData = async (body) => {
    try {
        // 调用接口
        const data = async () => {
            let data = await api.deleteData(body);
            return data.message;
        }
        return data();
    } catch (error) {
        return null;
    }
};
// 查詢报表接口
const handleRefreshData = async (body) => {
    try {
        // 调用接口
        const data = async () => {
            let data = await api.selectLists(body);
            return data.data;
        }
        return data();
    } catch (error) {
        return null;
    }
};

function SystemParameterSetup(prop) {
    const { fatherProp, closeAction } = prop
    const { t } = useTranslation();
    // 按钮loading
    const [openLoading, setOpenLoading] = React.useState(false);
    // 彈出框
    const [dialog, setDialog] = useState({
        content: "",
        action: (
            <Button
                variant="contained"
                onClick={() => {
                    setDialog({ ...dialog, open: false });
                }}
            >
                {t('Confirm')}
            </Button>
        ),
        open: false,
    });

    // 表格数据
    const [rows, setRows] = React.useState([]);
    // 表頭
    const columns = [
        { id: 'effectiveDate', label: t('Effective_Date'), minWidth: 200, align: 'left' },
        { id: 'value', label: t('Value'), minWidth: 50, align: 'left' },
        { id: 'action', label: t('Action'), minWidth: 50, align: 'center' },
    ];
    // 頁數
    const [page, setPage] = React.useState(0);
    // 每頁數量
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // 行数据详情
    const [tableRowDetail, setTableRowDetail] = React.useState({
        id: '',
        description: '',
        dataType: '',
        effectiveDate: null,
        value: '',
    });

    // 添加表單内容开关
    const [addOpen, setAddOpen] = React.useState(false);
    // 弹出框标题
    const [dialogTitle, setDialogTitle] = React.useState('');

    // 頁面初始化
    useEffect(() => {
        // 刷新table
        handleRefreshData(fatherProp).then((value) => {
            // 设置报表数据
            setRows(value.subDate);
        });
        // 回显表单内容
        setTableRowDetail({
            id: fatherProp.id,
        });
    }, [])
    // 切換頁數
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    // 切換每頁數量
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // 添加一行新數據
    const handleAddClick = () => {
        if (typeof tableRowDetail.id === 'undefined' || typeof tableRowDetail.effectiveDate === 'undefined') {
            return;
        }
        if (tableRowDetail.id.length === 0 || tableRowDetail.effectiveDate === null) {
            return;
        }
        if (tableRowDetail.id === null || tableRowDetail.effectiveDate === null) {
            return;
        }
        // 打开按钮loading, 防止重复点击
        setOpenLoading(true);
        handleAddData(tableRowDetail).then((value) => {
            // 关闭按钮loading
            setOpenLoading(false);
            // 提示是否成功
            setDialog({
                ...dialog,
                content: value,
                action: (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setDialog({ ...dialog, open: false });
                        }}
                    >
                        {t('Confirm')}
                    </Button>
                ),
                open: true,
            });
            // 更新table
            handleRefreshData(fatherProp).then((value) => {
                // 设置报表数据
                setRows(value.subDate);
            });
        });

    }

    // 编辑一行新數據
    const handleEditClick = () => {
        if (typeof tableRowDetail.id === 'undefined' || typeof tableRowDetail.effectiveDate === 'undefined') {
            return;
        }
        if (tableRowDetail.id.length === 0 || tableRowDetail.effectiveDate === null) {
            return;
        }
        if (tableRowDetail.id === null || tableRowDetail.effectiveDate === null) {
            return;
        }
        // 打开按钮loading, 防止重复点击
        setOpenLoading(true);
        handleUpdateData(tableRowDetail).then((value) => {
            // 关闭按钮loading
            setOpenLoading(false);
            // 提示是否成功
            setDialog({
                ...dialog,
                content: value,
                action: (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setDialog({ ...dialog, open: false });
                        }}
                    >
                        {t('Confirm')}
                    </Button>
                ),
                open: true,
            });
            // 更新table
            handleRefreshData(fatherProp).then((value) => {
                // 设置报表数据
                setRows(value.subDate);
            });
        });

    }
    // 确定执行删除事件
    const handleDeleteClick = (row) => {
        if (typeof row.id === 'undefined' || typeof row.effectiveDate === 'undefined') {
            return;
        }
        if (row.id === null || row.effectiveDate === null) {
            return;
        }
        // 打开按钮loading, 防止重复点击
        setOpenLoading(true);
        handleDeleteData(row).then((value) => {
            // 关闭按钮loading
            setOpenLoading(false);
            // 提示是否成功
            setDialog({
                ...dialog,
                content: value,
                action: (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setDialog({ ...dialog, open: false });
                        }}
                    >
                        {t('Confirm')}
                    </Button>
                ),
                open: true,
            });
            // 更新table
            handleRefreshData(fatherProp).then((value) => {
                // 设置报表数据
                setRows(value.subDate);
            });
        });
    };

    // 打開添加窗口
    const handleOpenAddClick = () => {
        // 重置表单内容
        handleResetAddClick();
        // 设置弹出框标题为-添加
        setDialogTitle(t('Add'));
        // 打开添加表单
        setAddOpen(true);
    };
    // 編輯按鈕點擊事件
    const handleOpenEditClick = (row) => {
        // 回显表单内容
        setTableRowDetail({
            id: fatherProp.id,
            effectiveDate: row.effectiveDate,
            value: row.value,
        });
        // 设置弹出框标题为-编辑
        setDialogTitle(t('Edit'));
        // 打开修改表单
        setAddOpen(true);
    }
    // 刪除按鈕點擊事件
    const handleOpenDeleteClick = (row) => {
        // 设置接口参数
        const body = {
            id: row.paraId,
            effectiveDate: row.effectiveDate,
        }
        // 删除时要打开提示框提示是否删除
        setDialog({
            ...dialog,
            content: t('sure_to_delete'),
            action: (
                <>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <FormControl>
                            <LoadingButton
                                loading={openLoading}
                                variant="contained"
                                sx={{
                                    backgroundColor: "#ff0000",
                                    color: "commom.white",
                                }}
                                onClick={() => handleDeleteClick(body)}
                            >
                                {t('Delete')}
                            </LoadingButton>
                        </FormControl>
                        <FormControl sx={{ marginLeft: "10px" }}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setDialog({ ...dialog, open: false });
                                }}
                                sx={{
                                    backgroundColor: "#9b9b9b",
                                    color: "commom.white",
                                    marginRight: "10px",
                                }}
                                label={t('Close')}
                            >
                                {t('Close')}
                            </Button>
                        </FormControl>
                    </Grid>
                </>
            ),
            open: true,
        });
    }
    // 重置添加表单
    const handleResetAddClick = () => {
        setTableRowDetail({
            ...tableRowDetail,
            effectiveDate: null,
            value: '',
        });
    };
    // 重置修改表单
    const handleResetEditClick = () => {
        setTableRowDetail({
            ...tableRowDetail,
            effectiveDate: null,
            value: '',
        });
    };
    // 关闭表单弹出框
    const handleCloseAddClick = () => {
        setAddOpen(false);
    }
    // 修改表单值
    const handleTableRowDetailChange = (event) => {
        setTableRowDetail({
            ...tableRowDetail,
            [event.target.name]: event.target.value,
        });
    };
    return (
        <Container maxWidth="md">
            {/* 提示框 */}
            <CustomDialog
                sx={{ whiteSpace: "pre-line" }}
                open={dialog.open}
                title={dialog.title}
                content={dialog.content}
                action={dialog.action}
            />

            {/* 导航条 */}
            <div
                style={{
                    textAlign: "right", marginRight: '15px'
                }}
            >
                <Tooltip sx={{ position: 'fixed' }} title="Close">
                    <IconButton onClick={closeAction}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            </div>
            {/* 標題 */}
            <Container sx={{ textAlign: 'center', paddingTop: "10px", paddingBottom: "10px" }} maxWidth="sm">
                <Typography variant='h6'><b>{t('icon3_system_parameter_setup')}</b></Typography>

            </Container>
            {/* 左侧信息 */}
            <TableContainer>
                <Table sx={{ textAlign: 'left' }} size="small"  >
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{ width: 100, borderBottom: "none" }}>{t('ID')}:</TableCell >
                            <TableCell sx={{ textAlign: 'left', borderBottom: "none" }}>{fatherProp.id}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ width: 100, borderBottom: "none" }}>{t('Description')}:</TableCell>
                            <TableCell sx={{ textAlign: 'left', borderBottom: "none" }}>{fatherProp.description}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ width: 100, borderBottom: "none" }}>{t('Data_Type')}:</TableCell>
                            <TableCell sx={{ textAlign: 'left', borderBottom: "none" }}>{fatherProp.dataType}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Container maxWidth="md">
                {/* 导航条 */}
                <Toolbar
                    align='center'
                    sx={{
                        pl: { sm: 2 },
                        pr: { xs: 1, sm: 1 },
                    }}
                >
                    <Tooltip title="New">
                        <IconButton onClick={(event) => handleOpenAddClick(event)}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Close">
                        <IconButton onClick={closeAction}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </Container>
            {/* 表格 */}
            <Collapse in={true} timeout="auto" unmountOnExit>
                <Container maxWidth={false}>
                    <TableContainer sx={{ paddingBottom: "50px" }}>
                        <Table sx={{ wordBreak: "keep-all", mt: '10px', "& .MuiTableCell-root": { paddingRight: "10px", paddingLeft: "10px" }, }} size="small" >
                            {/* 表头 */}
                            <TableHead>
                                <TableRow hover >
                                    {columns.map((column) => (
                                        <TableCell
                                            sx={{ fontSize: '15px', textAlign: column.align }}
                                            key={column.id}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {/* 行 */}
                            <TableBody>
                                {rows.length > 0 ?
                                    <>
                                        {rows
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.effectiveDate}>
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            if (column.id === 'action') {
                                                                return (
                                                                    <TableCell sx={{ fontSize: '15px', textAlign: column.align }} key={column.id}>
                                                                        <Button
                                                                            variant="contained"
                                                                            onClick={() => handleOpenEditClick(row)}
                                                                            sx={{
                                                                                backgroundColor: "#257018",
                                                                                color: "commom.white",
                                                                                marginRight: "10px",
                                                                            }}
                                                                            label={t('Edit')}
                                                                        >
                                                                            {t("Edit")}
                                                                        </Button>
                                                                        <Button
                                                                            variant="contained"
                                                                            onClick={() => handleOpenDeleteClick(row)}
                                                                            sx={{
                                                                                backgroundColor: "#ff0000",
                                                                                color: "commom.white",
                                                                                marginRight: "10px",
                                                                            }}
                                                                            label={t('Delete')}
                                                                        >
                                                                            {t("Delete")}
                                                                        </Button>
                                                                    </TableCell>
                                                                )
                                                            } else {
                                                                return (
                                                                    <TableCell sx={{ fontSize: '15px', textAlign: column.align }} key={column.id}>
                                                                        {value}
                                                                    </TableCell>
                                                                );
                                                            }
                                                        })}
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </> :
                                    <TableRow>
                                        <TableCell colSpan="13" sx={{ textAlign: 'center' }}>No Records Found!</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* 分頁 */}
                    {
                        rows.length > 0 ?
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            /> : <></>
                    }
                </Container>
            </Collapse>

            {/* 添加表單 */}
            <Dialog
                fullWidth
                maxWidth="xs"
                sx={{ whiteSpace: "pre-line" }}
                open={addOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <Container maxWidth="md">
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                        >
                            {/* 表单 */}
                            <Grid item xs={12} />
                            <Grid item xs={12}>
                                <TextField name='id' disabled value={tableRowDetail.id || ""} error={tableRowDetail.id === ''} fullWidth required size="small" label={t("ID")} onChange={(event) => handleTableRowDetailChange(event)} />
                            </Grid>
                            <Grid item xs={12}>
                                <CDatePicker name='effectiveDate' label={t("Effective_Date")} value={tableRowDetail.effectiveDate || null} required
                                    onChange={(data) => {
                                        setTableRowDetail({
                                            ...tableRowDetail,
                                            effectiveDate: !!data ? moment(new Date(data).getTime()).format('YYYY/MM/DD') : null
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name='value' value={tableRowDetail.value || ""} fullWidth size="small" label={t("Value")} onChange={(event) => handleTableRowDetailChange(event)} />
                            </Grid>

                        </Grid>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <FormControl>
                            <LoadingButton
                                loading={openLoading}
                                variant="contained"
                                sx={{
                                    backgroundColor: "#2d8d27",
                                    color: "commom.white",
                                }}
                                onClick={dialogTitle === t('Add') ? handleAddClick : handleEditClick}
                            >
                                {dialogTitle}
                            </LoadingButton>
                        </FormControl>
                        <FormControl sx={{ marginLeft: "10px" }}>
                            <Button
                                variant="contained"
                                onClick={dialogTitle === t('Add') ? handleResetAddClick : handleResetEditClick}
                                sx={{
                                    backgroundColor: "primaryGreen",
                                    color: "commom.white",
                                    marginRight: "10px",
                                }}
                                label={t('Reset')}
                            >
                                {t('Reset')}
                            </Button>
                        </FormControl>
                        <FormControl >
                            <Button
                                variant="contained"
                                onClick={handleCloseAddClick}
                                sx={{
                                    backgroundColor: "#9b9b9b",
                                    color: "commom.white",
                                    marginRight: "10px",
                                }}
                                label={t('Close')}
                            >
                                {t('Close')}
                            </Button>
                        </FormControl>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default SystemParameterSetup;