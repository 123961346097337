
import { React, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    ListItem,
    ListItemText,
    Button,
    MenuItem,
    Backdrop,
    CircularProgress,
    List,
    Divider,
    Collapse,
    ListItemButton
} from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CustomDialog from "src/components/common/CustomDialog";
import { fontSize } from "@mui/system";

// MoveOrder
export default function MoveOrder(props) {
    const { moveDialog, menuData, handleCloseMove, handleMoveOpenChildren, setRowData, handleMove } = props
    const { t } = useTranslation();
    const [checked, setChecked] = useState(0);
    const [dialog, setDialog] = useState({
        content: "",
        action: (
            <Button
                variant="contained"
                onClick={() => {
                    setDialog({ ...dialog, open: false });
                }}
            >
                確認
            </Button>
        ),
        open: false,
    });
    const [first, setFirst] = useState(false)
    const [last, setLast] = useState(false)
    const [editDialog, setEditDialog] = useState({})
    const [roleList, setRoleList] = useState([])
    const dialogAction = (
        showCancelButton,
        handleConfirmDialog,
        handleCancelDialog
    ) => {
        if (showCancelButton) {
            return (
                <>
                    <Button variant="contained" onClick={handleConfirmDialog}>
                        確認
                    </Button>
                    <Button variant="contained" onClick={handleCancelDialog}>
                        取消
                    </Button>
                </>
            );
        } else {
            return (
                <Button variant="contained" onClick={handleConfirmDialog}>
                    確認
                </Button>
            );
        }
    };

    useEffect(() => {

    }, [])

    const handleToggle = (value, i, last) => () => {
        if (i === last - 1) {
            setLast(true)
        }
        else {
            setLast(false)
        }
        if (i === 0) {
            setFirst(true)
        } else {
            setFirst(false)
        }
        setRowData({ ...value })
        setChecked(value.menuId);
    };

    // 承判商自動轉賬支薪報告 
    return (
        <>
            <CustomDialog
                sx={{ whiteSpace: "pre-line" }}
                open={dialog.open}
                title={dialog.title}
                content={dialog.content}
                action={dialog.action}
            />
            <Container maxWidth={false} sx={{ textAlign: "center" }}>
                <Dialog maxWidth="sm" fullWidth open={moveDialog}>
                    <DialogTitle>
                        {t("button_move")}
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <List>
                            {menuData
                                .sort((v1, v2) => {
                                    return v1.seq - v2.seq;
                                })
                                .map((value, index) => {
                                    return (
                                        <div key={index} >
                                            <ListItem

                                                dense
                                                onClick={handleToggle(value, index, menuData.length)}
                                                style={
                                                    checked === value.menuId
                                                        ? { backgroundColor: "#00ced1" }
                                                        : { backgroundColor: "#fff" }
                                                }
                                            >
                                                <ListItemButton sx={{ fontSize: "15px" }} >
                                                    <ListItemText
                                                        primary={value.menuDescCn}
                                                        sx={{ fontSize: "15px" }}
                                                    />

                                                    {value.moveOpen ? <ExpandLess onClick={() => { handleMoveOpenChildren(value, index) }} /> : <ExpandMore onClick={() => { handleMoveOpenChildren(value, index) }} />}
                                                </ListItemButton>
                                            </ListItem>
                                            <Collapse in={value.moveOpen || false} timeout="auto" unmountOnExit>
                                                <List >
                                                    {value.childList?.map((child, i) => (
                                                        <ListItem
                                                            dense
                                                            key={i}
                                                            onClick={handleToggle(child, i, value.childList.length)}
                                                            style={
                                                                checked === child.menuId
                                                                    ? { backgroundColor: "#00ced1" }
                                                                    : { backgroundColor: "#fff" }
                                                            }>
                                                            <ListItemButton sx={{ pl: " 50px", fontSize: "15px" }}>
                                                                <ListItemText
                                                                    primary={child.menuDescCn}
                                                                />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Collapse>
                                        </div>
                                    );
                                })}
                        </List>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={handleCloseMove}>{t("Confirm")}</Button>
                        <Button disabled={first} onClick={() => handleMove("up")} color="success">{t("move_up")}</Button>
                        <Button disabled={last} onClick={() => handleMove("down")} color="error">{t("move_down")}</Button>
                    </DialogActions>
                </Dialog>
            </Container>


        </>
    )

}
