import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as apiAtt from "src/utils/api/workerAttendanceRecord/AutoPayApiAttend";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomDialog from "src/components/common/CustomDialog";

export default function ApplyEditOrViewSubmit(props) {
  const componentRef = useRef();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [remarkLenghtValue, setRemarkLenghtValue] = React.useState(0);
  const [contentData, setContentData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [remark, setRemark] = React.useState({});

  const handleBack = () => {
    navigate(-1);
  };

  const handleChange = (event) => {
    console.log(event.target.value);
    const result = event.target.value;
    setRemark(event.target.value);
    setRemarkLenghtValue(result.length);
  };

  function clickSaveBtn() {
    let tempList = [];

    console.log(contentData);
    contentData.map((value) => {
      let temp = {};
      temp["appliedManday"] = value.underData.appliedManday;
      temp["applicationId"] = searchParams.get("applicationId");
      temp["applicationSeqNo"] = searchParams.get("applicationSeqNo");
      temp["accessDate"] = value.underData.accessDateValue;
      temp["appliedRemark"] = remark;

      tempList.push(temp);
      return [];
    });

    console.log(tempList);

    updatePFSApplicationViewForm2(tempList);
  }

  const [headerData, setHeaderData] = React.useState({});
  const [bottomData, setBottomData] = React.useState([]);
  const [showApproveCol, setShowApproveCol] = React.useState(false);
  const [dialog, setDialog] = useState({
    title: "",
    content: "",
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false });
        }}
      >
        {t("Confirm")}
      </Button>
    ),
    open: false,
  });

  useEffect(() => {
    console.log(searchParams.get("applicationId"));
    console.log(searchParams.get("applicationSeqNo"));

    const getPFSApplicationViewTitle = async () => {
      let data = await apiAtt.getPFSApplicationViewTitle(
        searchParams.get("applicationId"),
        searchParams.get("applicationSeqNo")
      );

      if (data.records !== null) {
        data.records.map((value) => {
          if (value.applicationStatusId === "01") {
            setShowApproveCol(true);
          } else {
            setShowApproveCol(false);
          }
          setHeaderData(value);
          return [];
        });
      }
    };

    const getPFSApplicationViewForm1 = async () => {
      let contentList = await apiAtt.getPFSApplicationViewForm1(
        searchParams.get("applicationId"),
        searchParams.get("applicationSeqNo")
      );
      if (contentList !== null) {
        setContentData(contentList);
      }
    };

    const getPFSApplicationViewForm2 = async () => {
      let contentList = await apiAtt.getPFSApplicationViewForm2(
        searchParams.get("applicationId"),
        searchParams.get("applicationSeqNo")
      );
      if (contentList !== null) {
        setBottomData(contentList.records);
      }
      setLoading(false);
    };

    const getRemarkView = async () => {
      let contentList = await apiAtt.getRemarkView(
        searchParams.get("applicationId")
      );
      if (contentList !== null) {
        setRemark(contentList.appliedRemark);
      }
      setLoading(false);
    };

    getPFSApplicationViewTitle();
    getPFSApplicationViewForm1();
    getPFSApplicationViewForm2();
    getRemarkView();
  }, []);


  const updatePFSApplicationViewForm2 = async (underDataList) => {
    let data = await apiAtt.updatePFSApplicationViewForm2(underDataList);
    console.log(data.code);
    if (data.code === 200) {
      alert("提交成功");
      navigate({
        pathname: "/workerAttendanceRecord/adocspMain/applyList/applyEditOrView",
        search: `?${createSearchParams({
          isViewData: true,
          applicationId: searchParams.get("applicationId") || "",
          applicationSeqNo: searchParams.get("applicationSeqNo") || ""
        })}`,
      });
    }
  };

  const handleInTime = (row) => {
    if (row.inTime !== null) {
      return "入";
    }
    return "";
  };
  const handleOutTime = (row) => {
    if (row.outTime !== null) {
      return "出";
    }
    return "";
  };

  // 取模
  function mod(n, m) {
    return ((n % m) + m) % m;
  }
  const handleInOutTime1 = (row) => {
    if (row.outTime === null || row.inTime === null) {
      return "0:00";
    }
    let outMin = 0;
    let inMin = 0;
    let outHour = 0;
    let inHour = 0;
    let modHour = 0;
    let modMin = 0;
    let resultHour = 0;
    let resultMin = 0;
    // let result = parseFloat(row.outTime) - parseFloat(row.inTime);
    outMin = Number(row.outTime.slice(3, 5));
    inMin = Number(row.inTime.slice(3, 5));
    outHour = Number(row.outTime.slice(0, 2));
    inHour = Number(row.inTime.slice(0, 2));
    let totalTime = 0;

    if (outMin < inMin) {
      totalTime = (outHour - inHour) * 60 - inMin;
      totalTime += outMin;

      modMin = mod(totalTime, 60);

      modHour = parseInt(82 / 60, 10);
    } else {
      modMin = outMin - inMin;
      modHour = outHour - inHour;
    }

    //  let result = modHour + ":" + modMin;
    resultHour = modHour;
    resultMin = modMin;

    if (resultHour === 0 && resultMin === 0) {
      return "0:00";
    } else if (resultMin >= 60) {
      if (resultMin / 10 > 1) {
        resultHour += parseInt(resultMin / 60, 10);
        resultMin = mod(resultMin, 60);

        return resultHour + ":" + resultMin;
      }

      if (resultMin / 10 < 1) {
        return resultHour + ":0" + resultMin;
      }
    } else {
      if (resultMin / 10 > 1) {
        resultHour += parseInt(resultMin / 60, 10);
        resultMin = mod(resultMin, 60);

        return resultHour + ":" + resultMin;
      }

      if (resultMin / 10 < 1) {
        return resultHour + ":0" + resultMin;
      }
    }
    // return result;
  };
  const handleInOutTime3 = (contenData) => {
    let resultMax3 = 0;
    if (contenData !== null) {
      contenData.map((value) => {
        resultMax3 += parseFloat(value.underData.appliedManday);
        return [];
      });
    }

    return resultMax3.toFixed(2);
  };

  const handleInOutTime2 = (dataList) => {
    let resultMax3 = 0;
    let resultHour = 0;
    let resultMin = 0;

    dataList.form1List.map((row, index) => {
      if (row.outTime === null || row.inTime === null) {
        return "0:00";
      }
      let outMin = 0;
      let inMin = 0;
      let outHour = 0;
      let inHour = 0;
      let modHour = 0;
      let modMin = 0;
      console.log("row", row);
      //  let result = parseFloat(row.outTime) - parseFloat(row.inTime);
      outMin = Number(row.outTime.slice(3, 5));
      inMin = Number(row.inTime.slice(3, 5));
      outHour = Number(row.outTime.slice(0, 2));
      inHour = Number(row.inTime.slice(0, 2));
      let totalTime = 0;

      if (outMin < inMin) {
        totalTime = (outHour - inHour) * 60 - inMin;
        totalTime += outMin;

        modMin = mod(totalTime, 60);

        modHour = parseInt(totalTime / 60, 10);
      } else {
        modMin = outMin - inMin;
        modHour = outHour - inHour;
      }

      resultMin += modMin;
      resultHour += modHour;
      return [];

      // resultMax3 += parseFloat(value.underData.appliedManday);
    });

    if (resultHour === 0 && resultMin === 0) {
      return "0:00";
    } else if (resultMin >= 60) {
      if (resultMin / 10 > 1) {
        resultHour += parseInt(resultMin / 60, 10);
        resultMin = mod(resultMin, 60);

        return resultHour + ":" + resultMin;
      }

      if (resultMin / 10 < 1) {
        return resultHour + ":0" + resultMin;
      }
    } else {
      if (resultMin / 10 > 1) {
        resultHour += parseInt(resultMin / 60, 10);
        resultMin = mod(resultMin, 60);

        return resultHour + ":" + resultMin;
      }

      if (resultMin / 10 < 1) {
        return resultHour + ":0" + resultMin;
      }
      console.log("1111", resultHour + ":" + resultMin);
      // return resultHour + ":" + resultMin;
    }
  };

  return (
    <>
      <div style={{ display: "block", textAlign: "left" }}>
        <Button sx={{ float: "left" }} onClick={handleBack}>
          <ArrowBackIcon />
          {t("button_back")}
        </Button>
      </div>
      <div id="div" ref={componentRef} style={{}}>
        <Container maxWidth="xl">
          <div>
            <div style={{ display: "block", marginBottom: "60px" }}>
              <Typography variant="h4" sx={{ textAlign: "center" }}>
                <b>{t("search_vendor_ss_outDays")}</b>
              </Typography>
            </div>

            <div>
              <TableContainer
                component={Paper}
                sx={{ marginBottom: "20px", backgroundColor: "transparent" }}
              >
                <Table sx={{ textAlign: "left" }} size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_site_title") + "："}{" "}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.siteDesc}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("Vendor") + "："}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.vendorDesc}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_subcontractor_dEmployer")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.subContractorDesc}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_work_type_dot")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.jobNatureDesc}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_smartCardId_dot")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.smartCardId}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_date_dot")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.smartCardId}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_chinese_name_dot")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.chineseName}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_english_name_dot")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.englishName}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_employment_position")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.vendorDesc}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_approve_date_dot")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.vendorDesc}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_in_efficient_date")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.effectiveDate}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "15px",
                          px: "5px",
                          width: 160,
                          border: 0,
                        }}
                      >
                        {t("search_in_valite_date_to")}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "15px", px: "5px", border: 0 }}
                      >
                        {headerData.effectiveDateTo}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            {/*  */}
            <div>
              <TableContainer sx={{ marginBottom: "18px" }}>
                <Table sx={{ minWidth: 650, p: "2px" }} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {t("search_inOut_date")}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {t("search_in_times")}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {t("search_in_out")}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {t("search_out_times")}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {t("search_in_out")}
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                      >
                        {t("search_site_times")}
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                      >
                        {t("search_site_days")}
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                      >
                        {t("search_vendor_site_totalWorker")}
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                      >
                        {t("search_apply_day")}
                      </TableCell>
                      {showApproveCol && (
                        <TableCell
                          align="center"
                          sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                        >
                          {t("search_approve_day")}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>

                  {contentData.map((dataList, index) => (
                    <TableBody key={index + Math.random}>
                      {dataList.form1List.map((row, index) => (
                        <TableRow key={index + Math.random}>
                          <TableCell>
                            {index === 0 && row.accessDateValue}
                          </TableCell>
                          <TableCell>{row.inTime}</TableCell>
                          <TableCell>{handleInTime(row)}</TableCell>
                          <TableCell>{row.outTime}</TableCell>
                          <TableCell>{handleOutTime(row)}</TableCell>
                          <TableCell sx={{ textAlign: "right" }}>
                            {handleInOutTime1(row)}
                          </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          {showApproveCol && <TableCell> </TableCell>}
                        </TableRow>
                      ))}

                      <TableRow>
                        <TableCell> </TableCell>
                        <TableCell> </TableCell>
                        <TableCell> </TableCell>
                        <TableCell> </TableCell>
                        <TableCell>
                          <b>{t("search_subtotal")}</b>
                        </TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                          {handleInOutTime2(dataList)}
                        </TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                          {dataList.underData.totalManday
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                        </TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                          {dataList.underData.vendorDailyAttendance}
                        </TableCell>

                        {
                          <TableCell sx={{ textAlign: "right" , color:
                          dataList.underData.appliedManday !==
                          dataList.underData.totalManday
                            ? "red"
                            : "black",}}>
                            {dataList.underData.appliedManday
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                          </TableCell>
                        }
                        {showApproveCol && <TableCell> </TableCell>}
                      </TableRow>
                    </TableBody>
                  ))}

                  <TableBody>
                    <TableRow>
                      <TableCell> </TableCell>
                      <TableCell> </TableCell>
                      <TableCell> </TableCell>
                      <TableCell> </TableCell>
                      <TableCell> </TableCell>
                      <TableCell> </TableCell>
                      <TableCell> </TableCell>
                      <TableCell sx={{ fontSize: "18px", textAlign: "right" }}>
                        <b>{t("search_total_dot")}</b>
                      </TableCell>
                      {
                        <TableCell
                          sx={{ fontSize: "18px", textAlign: "right" }}
                        >
                          {handleInOutTime3(contentData)}
                        </TableCell>
                      }
                      {showApproveCol && <TableCell> </TableCell>}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            {
              <div>
                <TableContainer
                  component={Paper}
                  sx={{ marginBottom: "20px", backgroundColor: "transparent" }}
                >
                  <Table sx={{ textAlign: "left" }} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "15px",
                            px: "5px",
                            width: 160,
                            border: 0,
                          }}
                        >
                          {t("search_remark_dot")}
                          <br />
                          (Max 100 chars)
                          <br />
                          Size：
                          <TextField
                            sx={{ width: "10ch", height: "1ch" }}
                            id="outlined-multiline-static"
                            rows={3}
                            value={remarkLenghtValue}
                            defaultValue={0}
                          />
                          chars
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "15px", px: "5px", border: 0 }}
                        >
                          <TextField
                            sx={{ width: "50ch" }}
                            id="outlined-multiline-static"
                            multiline
                            rows={3}
                            inputProps={{ maxLength: 100 }}
                            defaultValue={remark || ""}
                            onChange={handleChange}
                          />
                          {""}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "18px",
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      background: "yellow",
                      marginRight: 10,
                    }}
                    onClick={(e) => clickSaveBtn(e)}
                  >
                    提交
                  </Button>
                </div>
              </div>
            }

            <CustomDialog
              open={dialog.open}
              title={dialog.title}
              content={dialog.content}
              action={dialog.action}
            />

            {headerData.siteDesc && (
              <div>
                <Typography sx={{ textAlign: "left", marginTop: "60px" }}>
                  <b># 註：</b>不計算派駐安全主任/安全督導員人數
                  <br />
                  <b>1)</b>{" "}
                  安全人員派駐於地盤時間不足9小時，則以Pro-rata形式計算，若超過9小時，均按九小時計算，但如每日派駐時間不足三小時，一律不作計算。
                  <br />
                  <b>2)</b> 承判商須於安全人員出席該月後兩個月內提交此申請表。
                </Typography>
              </div>
            )}
          </div>
        </Container>
      </div>
    </>
  );
}
