import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {
    Button,
    Box,
    Card,
    Container,
    FormControl,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import SettingsIcon from '@mui/icons-material/Settings'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DownloadIcon from "@mui/icons-material/Download";
import moment from 'moment';
import { CustomDialog, CDatePicker, CAutocomplete, CTable, CPagination, CDialog, CTransferList, CTableExpandable } from "src/components/common"


import $http, { $download } from 'src/utils/api/services'


// 查詢报表接口
const handleRefreshData = async (body) => {
    try {
        // 调用接口
        const data = async () => {
            let data = await $http('/autopay/function/vendor-auth-info/select-all', body);
            return data.data;
        }
        return data();
    } catch (error) {
        return null;
    }
};
// 查詢詳情接口
const handleDetailData = async (body) => {
    try {
        // 调用接口
        const data = async () => {
            let data = await $http('/autopay/function/vendor-auth-info/select', body);
            return data;
        }
        return data();
    } catch (error) {
        return null;
    }
};

function VendorAuthorReport() {
    // 國際化
    const { t } = useTranslation();
    // 表格数据
    const [rows, setRows] = React.useState([]);
    // 表格数据總數
    const [total, setTotal] = React.useState(0);

    // 頁數
    const [page, setPage] = React.useState(1);
    // 每頁數量
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // 搜索按钮loading
    const [openLoading, setOpenLoading] = React.useState(false);
    // table loading
    const [tableLoading, setTableLoading] = React.useState(false);
    // 搜索表单
    const [searchForm, setSearchForm] = React.useState({
        vendorDesc: '',
        pageSize: rowsPerPage,
        pageNumber: page,
        orderBy: '',
        ascOrder: '',
    });

    // 彈出框
    const [dialog, setDialog] = useState({
        content: "",
        action: (
            <Button
                variant="contained"
                onClick={() => {
                    setDialog({ ...dialog, open: false });
                }}
            >
                {t('Confirm')}
            </Button>
        ),
        open: false,
    });

    // 頁面初始化
    useEffect(() => {
        handleRefreshData(searchForm).then((value) => {
            // 设置报表数据
            setRows(value?.records);
            setTotal(value?.total);
        })
    }, [page, rowsPerPage]);

    // 表單值改變
    const handleFormChange = (event) => {
        setSearchForm({
            ...searchForm,
            [event.target.name]: event.target.value,
        });
    };

    // 表单提交
    const handleSearchClick = async () => {
        // 打开按钮loading, 防止重复点击
        setOpenLoading(true);
        setTableLoading(true)
        setSearchForm({
            ...searchForm,
            pageNumber: page,
            pageSize: rowsPerPage,
        })
        handleRefreshData(searchForm).then((value) => {
            // 关闭按钮loading
            setOpenLoading(false);
            setTableLoading(false)
            // 设置报表数据
            setRows(value?.records);
            setTotal(value?.total);
        });
    };
    // 判斷數組是否含有某個元素, 沒有就加進去
    const pushKey = (arr, key) => {
        // arr是否為數組
        if (!Array.isArray(arr)) return;
        // 是否含有該key
        const _key = arr.some((item) => item === key);
        if (_key) {
            return arr;
        } else {
            return [...arr, key];
        }
    }

    // 重置查询表单
    const handleResetClick = () => {
        setSearchForm({
            vendorDesc: '',
        });
    };

    const handleShowSizeChange = (page, rowsPerPage) => {
        setPage(page);
        setRowsPerPage(rowsPerPage > 0 ? rowsPerPage : undefined);
        setSearchForm({
            ...searchForm,
            pageNumber: page,
            pageSize: rowsPerPage,
        });
    }

    function ChangeSize() {
        const [size, setSize] = useState({
            width: document.documentElement.clientWidth,
            hieght: document.documentElement.clientHeight
        })

        const onResize = useCallback(() => {
            setSize({
                width: document.documentElement.clientWidth,
                height: document.documentElement.clientHeight,
            })
        }, [])

        useEffect(() => {
            window.addEventListener('resize', onResize);
            return (() => {
                window.removeEventListener('resize', onResize)
            })
        }, [])

        return size;
    }

    // 获取浏览器宽高
    let windowsSize = ChangeSize();

    // 表頭
    const columns = [
        { dataIndex: 'loginId', label: t('login_id'), align: 'left', width: '20%', isOrder: false },
        { dataIndex: 'vendorDesc', label: t('company_name'), align: 'left', width: '40%', isOrder: false },
        { dataIndex: 'vendorDescC', label: t('company_name'), align: 'left', width: '40%', isOrder: false },
    ];

    const handleExcelClick = () => {
        $download('/autopay/report/vendor-auth-info/export-excel', 'application/vnd.ms-excel', searchForm)
    }

    const handlePDFClick = () => {
        $download(`/autopay/report/vendor-auth-info/export-pdf`, 'application/pdf', searchForm)
    }

    return (
        <>
            <Container maxWidth={false} sx={{ mt: 5, mb: 8 }}>
                <Typography variant="h4" sx={{ textAlign: "center", mb: 3 }}>
                    {t('icon2_vendor_author_report')}
                </Typography>
                <Typography sx={{ textAlign: "right", mb: 3 }}>
                    <Tooltip title={t('Excel')}>
                        <IconButton onClick={() => handleExcelClick()}>
                            <img src={process.env.PUBLIC_URL + "/assets/images/export-excel.png"} alt={t('Excel')} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('PDF')}>
                        <IconButton onClick={() => handlePDFClick()}>
                            {/* <DownloadIcon /> */}
                            <img src={process.env.PUBLIC_URL + "/assets/images/export-pdf.png"} alt={t('PDF')} />
                        </IconButton>
                    </Tooltip>
                </Typography>


                <Card variant="outlined" sx={{ border: '1px solid rgba(5, 5, 5, .06)', borderRadius: '8px' }}>
                    <Box sx={{ padding: '32px 24px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    name='vendorDesc' value={searchForm.vendorDesc || ""} fullWidth size="small" label={t("company_name")} onChange={(event) => handleFormChange(event)} />
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                <FormControl>
                                    <LoadingButton
                                        loading={openLoading} variant="contained" onClick={handleSearchClick} sx={{ backgroundColor: "primaryBlue", color: "commom.white", }}
                                    >
                                        {t('Search')}
                                    </LoadingButton>
                                </FormControl>
                                <FormControl sx={{ marginLeft: "20px" }}>
                                    <Button
                                        variant="contained" onClick={handleResetClick} sx={{ backgroundColor: "primaryGreen", color: "commom.white", }} label={t('Reset')}
                                    >
                                        {t("Reset")}
                                    </Button>
                                </FormControl>
                            </Grid>

                        </Grid>
                    </Box>
                    <CTable disablePagination rowKey="userId" loading={tableLoading} columns={columns} data={rows} paperSx={{ border: 0 }}
                        expandable={{
                            expandedRowRender: (record) => (
                                <Grid container maxWidth="lg" spacing={1}>
                                    {record.vendorAuthors.map((value, index) => (
                                        <React.Fragment key={index}>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <CTableExpandable label={t("vendor_author") + '(' + ++index + ')'} value={value.authorizerName + ' ' + t("Mr/Ms")} />
                                            </Grid><Grid item xs={12} sm={6} md={6}>
                                                <CTableExpandable label={t("phone")} value={value.authorizerPhone} />
                                            </Grid>
                                        </React.Fragment>
                                    )
                                    )}
                                </Grid>
                            )
                        }}
                    />
                    <CPagination total={total} rowsPerPage={rowsPerPage} onChange={handleShowSizeChange} />
                </Card>
            </Container>

            {/* 提示框 */}
            <CustomDialog
                sx={{ whiteSpace: "pre-line" }}
                open={dialog.open}
                title={dialog.title}
                content={dialog.content}
                action={dialog.action}
            />

        </>
    )
}

export default VendorAuthorReport;
