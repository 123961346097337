import {
  Box,
  Button,
  Grid,
  Divider,
  MenuItem,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  Typography,
  TableContainer,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";

import * as api from "src/utils/api/workerAttendanceRecord/AutoPayApi";

export default function TableGap(props) {
  const { getBack, data } = props;
  const { t } = useTranslation();

  useEffect(() => {}, []);

  return (
    <>
      <div
        style={{
          display: "block",
          textAlign: "left",
          marginTop: "0px",
        }}
      >
        <Button sx={{ float: "left", mr: "20px" }} onClick={getBack}>
          <ArrowBackIcon />
          {t("button_back")}
        </Button>
      </div>

      <div style={{ display: "block", textAlign: "right" }}>
        <Button sx={{ float: "right", mr: "20px" }}>
          <DownloadIcon />
          {t("button_print")}
        </Button>
      </div>
      <Typography variant="h4" sx={{ display: "block", textAlign: "center" }}>
        <b>{t("Payroll_has_been_prepared")}</b>
      </Typography>
      <Typography variant="h6" sx={{ display: "block", mt: "10px" }}>
        <b>{t("Contractor_Information")}</b>
      </Typography>
      <TableContainer>
        <Table sx={{ textAlign: "left" }} size="small">
          <TableBody>
            <TableRow>
              <TableCell sx={{ display: "block", borderBottom: "none" }}>
                {t("icon1_autopay_button8_print_label1")}
              </TableCell>
              <TableCell sx={{ textAlign: "left", borderBottom: "none" }}>
                {data.vendorList?.vendorDesc}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: 300, borderBottom: "none" }}>
                {t("Payroll_and_Contribution_Period")}:
              </TableCell>
              <TableCell sx={{ textAlign: "left", borderBottom: "none" }}>
                {moment(new Date(data.payrollGap?.dateFrom)).format(
                  "yyyy/MM/DD"
                ) +
                  " ~ " +
                  moment(new Date(data.payrollGap?.dateTo)).format(
                    "yyyy/MM/DD"
                  )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: 200, borderBottom: "none" }}>
                {t("Remark")}:
              </TableCell>
              <TableCell sx={{ textAlign: "left", borderBottom: "none" }}>
                {" "}
                {data.payrollGap?.remark}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
