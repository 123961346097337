import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FixedSizeList } from "react-window";

import * as commonApi from "../../../utils/api/systemAdministrator/NscVendorListApi";

export default function NscVendorList() {
  const [backOpen, setBackOpen] = useState(false);
  const [notVendorIdList, setnotVendorIdList] = useState([]);
  const [sendData, setSendData] = useState({});
  const [fatherList, setFatherList] = useState([]);
  const [batheeList, setBatheeList] = useState([]);
  const [checked, setChecked] = useState(true);
  const [editeStatus, setediteStatus] = useState(false);
  const { t } = useTranslation();
  const [left, setLeft] = useState([0, 1, 2, 3]);
  const [right, setRight] = useState([4, 5, 6, 7]);
  const [load, setLoad] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  // /*初始化加载 */
  useEffect(() => {
    // /*初始化,转圈圈 */

    const getData = async () => {
      setBackOpen(true);

      let getnotVendorIdList = await commonApi.getZoVendorId({});

      if (getnotVendorIdList) {
        setnotVendorIdList(getnotVendorIdList);
      }

      setBackOpen(false);
      // console.log("notVendorIdList", notVendorIdList);
    };
    getData();
  }, [load]);

  const handleChlid = (item) => {
    // if (fatherList == null)
    //   alert("請勾選在提交")

    let itemIndex = fatherList.indexOf(item);
    if (itemIndex === -1) {
      fatherList.push(item);
    } else {
      fatherList.splice(itemIndex, 1);
    }

    setFatherList(fatherList);
    console.log("select", fatherList);
  };

  const bandleChlid = (item) => {
    // if (fatherList == null)
    //   alert("請勾選在提交")

    let itemIndex = batheeList.indexOf(item);
    if (itemIndex === -1) {
      batheeList.push(item);
    } else {
      batheeList.splice(itemIndex, 1);
    }

    setBatheeList(batheeList);

    console.log("select", batheeList);
    // handleSubmit(batherLista)
  };

  const bandleSubmit = async (batheeList) => {
    if (batheeList.length === 0) {
      alert("請勾選在提交");
      return;
    }

    let send = await commonApi.deleteVendorId(batheeList);
    if (send) {
      setBatheeList([]);
      setnotVendorIdList([]);
      setLoad(!load);
    }
  };

  const handleSubmit = async (fatherList) => {
    if (fatherList.length === 0) {
      alert("請勾選在提交");
      return;
    }

    let send = await commonApi.insertVendorId(fatherList);
    if (send) {
      setFatherList([]);
      setnotVendorIdList([]);
      setLoad(!load);
    }
  };

  const handleEditeStatus = () => {
    setediteStatus(true);
  };

  function customLeft(props) {
    const { index, style } = props;

    return (
      <Grid item xs={10} md={10} style={style} key={notVendorIdList.notVendorId[index]?.vendorId}>
        <FormControlLabel
          key={notVendorIdList.notVendorId[index]?.vendorId}
          value={notVendorIdList.notVendorId[index]?.vendorId}
          control={<Checkbox />}
          label={notVendorIdList.notVendorId[index]?.vendorDesc}
          onChange={() => handleChlid(notVendorIdList.notVendorId[index])}
        />
      </Grid>
    );
  }

  function customRight(props) {
    const { index, style } = props;

    return (
      <Grid item xs={10} md={10} style={style} key={notVendorIdList.vendorId[index]?.vendorId}>
        <FormControlLabel
          key={notVendorIdList.vendorId[index]?.vendorId}
          value={notVendorIdList.vendorId[index]?.vendorId}
          control={<Checkbox />}
          label={notVendorIdList.vendorId[index]?.vendorDesc}
          onChange={() => bandleChlid(notVendorIdList.vendorId[index])}
        />
      </Grid>
    );
  }

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* NSC 承判商 */}
      <Grid item xs={12}>
        <Typography variant="h4" style={{ textAlign: "center" }}>
          <b>{t("nsc_title")}</b>
        </Typography>
      </Grid>

      <Grid container>
        <Typography item xs={12}>
          &nbsp;
        </Typography>
      </Grid>

      <Divider />

      <Grid container justifyContent="space-evenly" alignItems="center">
        <Grid item>
          <FixedSizeList
            height={900}
            width={400}
            itemSize={46}
            itemCount={notVendorIdList.countA}
            overscanCount={20}
          >
            {customLeft}
          </FixedSizeList>
        </Grid>

        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 1.5 }}
              variant="outlined"
              size="large"
              onClick={() => handleSubmit(fatherList)}
              // disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>

            <Button
              sx={{ my: 1.5 }}
              variant="outlined"
              size="large"
              onClick={() => bandleSubmit(batheeList)}
              // disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <FixedSizeList
            height={900}
            width={400}
            itemSize={46}
            itemCount={notVendorIdList.countB}
            overscanCount={notVendorIdList.countB}
          >
            {customRight}
          </FixedSizeList>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
}
