import React from 'react';
import {
  Container,
  Typography,
  Grid, Link
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

// 終止工人進入地盤申請表----首頁
export default function ToweafMain() {

  // const { setType } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleButton1 = () => {
    navigate('/workerAttendanceRecord/toweafMain/toweafSearch');
  };
  const handleButton2 = () => {
    navigate('/workerAttendanceRecord/toweafMain/qtoweafSearch');
  };

  const downLoad = () => {
    navigate('/otherDataSetting/otherDataSetting/25');
  };

  return (
    <Container sx={{ textAlign: "center" }} >
      <div style={{ display: 'block' }}  >
        <Typography variant='h4' style={{ color: 'black', textAlign: 'center', marginBottom: '18px' }}
        >
          <b>{t("search_teminate_site_apply")}</b>
        </Typography>
      </div>
      {/* btn1 */}
      <Container sx={{ marginTop: '4px', marginBottom: '10px', border: 1, width: '400px', borderBlockColor: 'grey', borderRadius: '8px' }}>

        <Grid item xs={12} md={12} sx={{ marginBottom: '16px' }}>
          <Button
            onClick={handleButton1}
            sx={{ mt: "8px" }}
            variant="contained"
          >
            {t("search_teminate_site_apply")}
          </Button>
        </Grid>
      </Container>
      <Grid item xs={12} md={12}>
        <ArrowDropDownIcon />
      </Grid>
      {/* btn2 */}
      <Container sx={{ marginTop: '4px', marginBottom: '10px', border: 1, width: '400px', borderBlockColor: 'grey', borderRadius: '8px' }}>

        <Grid item xs={12} md={12} sx={{ marginBottom: '16px' }}>
          <Button
            onClick={handleButton2}
            sx={{ mt: "8px" }}
            variant="contained"
          >
            {t("search_check_teminateApplayCase_title")}
          </Button>
        </Grid>
      </Container>

      {/* download */}
      <Grid item xs={12} md={12} sx={{ marginTop: '60px' }}>
        <Link style={{cursor:"pointer"}} underline="none" sx={{ whiteSpace: "nowrap", }}
          onClick={() => downLoad()}>
           {t("download_teminate_site_apply_user_guide")}
        </Link>
      </Grid>

    </Container>

  )
}

