import React, { useState, useRef, useEffect } from 'react'
import { Button, Container, Grid, TextField, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import PrintIcon from '@mui/icons-material/Print'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useTranslation } from 'react-i18next'
import ReactToPrint from 'react-to-print'
import CustomBackdrop from 'src/components/common/CustomBackdrop'
import CustomDialog from 'src/components/common/CustomDialog'
import CustomSnackbar from 'src/components/common/CustomSnackbar'
import { confirm } from 'src/components/common'
import $http, { $download } from 'src/utils/api/services'
import DownloadIcon from '@mui/icons-material/Download'
import { maintWorkerAccountlist, updateMaintWorkerAccount } from 'src/utils/api/workerAttendanceRecord/MaintWorkerAccountlist'

export default function MaintWorkerAccount(props) {
  const { setType } = props
  const contentRef = useRef(null)

  // 在save之後才會改變goBackToEdit，goBackToEdit為true，顯示row.remark， false顯示 row.bankAccount
  const [goBackToEdit, setGoBackToEdit] = useState(false)
  const [detail, setDetail] = useState({
    rsvendor: {},
    Worker1: [],
    Worker2: [],
  })
  const [inProgress, setInProgress] = useState(false)
  const [dialog, setDialog] = useState({
    title: '',
    content: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        確認
      </Button>
    ),
    open: false,
  })
  const [snackbar, setSnackbar] = useState({
    message: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setSnackbar({ ...snackbar, open: false })
        }}
      >
        確認
      </Button>
    ),
    open: false,
  })
  const componentRef = useRef()
  const { t } = useTranslation()

  useEffect(() => {
    getMaintWorkerAccountlist()
  }, [])

  const getMaintWorkerAccountlist = async () => {
    setInProgress(true)
    let res = await maintWorkerAccountlist()
    if (res && res.code === 200) {
      if (res.data && res.data.length > 0) {
        if (res.data[0].Worker1) {
          res.data[0].Worker1.forEach((val) => {
            // 後台example:
            // type: MPFEXISTS/BNKEXISTS
            // remark(修改後錯誤的值): 0150000761878/015143104003249
            let errors = val.type ? val.type.split('/') : []
            let errorRemarks = val.remark ? val.remark.split('/') : []
            val.errorDetail = {}
            errors.forEach((value, index) => {
              val.errorDetail[value] = errorRemarks[index]
            })
            val.isEdit = false
          })
        }
        if (res.data[0].Worker2) {
          res.data[0].Worker2.forEach((val) => {
            let errors = val.type ? val.type.split('/') : []
            let errorRemarks = val.remark ? val.remark.split('/') : []
            val.errorDetail = {}
            errors.forEach((value, index) => {
              val.errorDetail[value] = errorRemarks[index]
            })
            val.isEdit = false
          })
        }
        setDetail({
          ...detail,
          Worker1: res.data[0].Worker1 ? res.data[0].Worker1 : [],
          Worker2: res.data[0].Worker2 ? res.data[0].Worker2 : [],
        })
      }
    } else {
      setDialog({
        ...dialog,
        content: '請求錯誤',
        open: true,
      })
    }
    setInProgress(false)
  }

  const exportPdf = (data) => {
    $download(`/autoPayController/maintWorkerAccountPDF`, 'application/pdf', data)
  }

  const handleSave = () => {
    const saveWorkerAccountlist = async () => {
      setInProgress(true)
      let data = []
      if (detail.Worker1) {
        detail.Worker1.forEach((val) => {
          if (val.isEdit) {
            let newObj = {}
            newObj.cardHolderId = val.cardHolderId
            if (goBackToEdit && val.errorDetail['MPFEXISTS']) {
              newObj.mpfScheme = val.errorDetail['MPFEXISTS']
            } else if (goBackToEdit && val.errorDetail['MPFIND']) {
              newObj.mpfScheme = val.errorDetail['MPFIND']
            } else {
              newObj.mpfScheme = val.mpfScheme
            }

            if (goBackToEdit && val.errorDetail['BNKEXISTS']) {
              newObj.bankAccount = val.errorDetail['BNKEXISTS']
            } else if (goBackToEdit && val.errorDetail['BNKIND']) {
              newObj.bankAccount = val.errorDetail['BNKIND']
            } else {
              newObj.bankAccount = val.bankAccount
            }

            newObj.vendorId = val.vendorId
            // newObj.userId = "kfwgvz";
            data.push(newObj)
            // data.push(val);
          }
        })
      }
      if (detail.Worker2) {
        for (let val of detail.Worker2) {
          if (val.isEdit) {
            let newObj = {}
            newObj.cardHolderId = val.cardHolderId
            if (goBackToEdit && val.errorDetail['MPFEXISTS']) {
              newObj.mpfScheme = val.errorDetail['MPFEXISTS']
            } else if (goBackToEdit && val.errorDetail['MPFIND']) {
              newObj.mpfScheme = val.errorDetail['MPFIND']
            } else {
              newObj.mpfScheme = val.mpfScheme
            }

            if (goBackToEdit && val.errorDetail['BNKEXISTS']) {
              newObj.bankAccount = val.errorDetail['BNKEXISTS']
            } else if (goBackToEdit && val.errorDetail['BNKIND']) {
              newObj.bankAccount = val.errorDetail['BNKIND']
            } else {
              newObj.bankAccount = val.bankAccount
            }
            newObj.vendorId = val.vendorId
            // if (newObj.mpfScheme && !newObj.bankAccount) {
            //   console.log("item", newObj)
            //   setDialog({
            //     ...dialog,
            //     content: "修改的数据中員工戶口號碼不能为空",
            //     open: true,
            //   });
            //   setInProgress(false);
            //   return
            // }
            console.log('newObj', newObj)
            //  if (newObj.bankAccount) {
            data.push(newObj)
            //  }

            // newObj.userId = "kfwgvz";
            // data.push(val);
          }
        }
      }
      if (data.length <= 0) {
        setDialog({
          ...dialog,
          content: '請選擇一項進行修改',
          open: true,
        })
        setInProgress(false)
        return
      }
      let res = await updateMaintWorkerAccount(data)
      setInProgress(false)
      console.log(data)
      if (res && res.code === 200) {
        setGoBackToEdit(res.data)

        confirm({
          title: t('tip'),
          content: '正確輸入資料已修改,而部份不正確輸入資料,請查看並自行更改',
          okText: '確認',
          onOk: () => {
            getMaintWorkerAccountlist()
          },
        })
        // setSnackbar({
        //   ...snackbar,
        //   message: "正確輸入資料已修改,而部份不正確輸入資料,請查看並自行更改",
        //   open: true,
        //   action: (
        //     <Button
        //       variant="contained"
        //       onClick={() => {
        //         getMaintWorkerAccountlist();
        //         setSnackbar({ ...snackbar, open: false });
        //       }}
        //     >
        //       確認
        //     </Button>
        //   ),
        // });
      } else {
        setDialog({
          ...dialog,
          content: '請求錯誤',
          open: true,
        })
      }
      setInProgress(false)
    }
    saveWorkerAccountlist()
  }

  const handleBack = () => {
    setType(0)
  }

  function checkBankAccount(isWorker1, row, index) {
    // 修改出錯時，字段修改的值會存在remark，不會修改主表數據
    return (event) => {
      if (event.target.value.length > 15) {
        setDialog({
          ...dialog,
          content: '員工戶口號碼大於15位',
          open: true,
        })
        return
      }
      let inputData = event.target.value
      let newRecords = isWorker1 ? detail.Worker1 : detail.Worker2
      if (goBackToEdit && row.errorDetail['BNKEXISTS']) {
        row.errorDetail['BNKEXISTS'] = inputData.replace(/\D/g, '')
      } else if (goBackToEdit && row.errorDetail['BNKIND']) {
        row.errorDetail['BNKIND'] = inputData.replace(/\D/g, '')
      } else {
        row.bankAccount = inputData.replace(/\D/g, '')
      }
      row.isEdit = true
      newRecords[index] = row

      // newRecords.forEach((val) => {

      //   if (goBackToEdit && val.errorDetail["BNKEXISTS"]) {
      //     val.errorDetail["BNKEXISTS"] = inputData.replace(/\D/g, "");
      //   } else if (goBackToEdit && val.errorDetail["BNKIND"]) {
      //     val.errorDetail["BNKIND"] = inputData.replace(/\D/g, "");
      //   } else {
      //     val.bankAccount = inputData.replace(/\D/g, "");
      //   }
      //   val.isEdit = true;

      // });
      setDetail({ ...detail })
    }
  }

  function checkMpfScheme(isWorker1, row) {
    // 修改出錯時，字段修改的值會存在remark，不會修改主表數據
    return (event) => {
      if (event.target.value.length > 13) {
        setDialog({
          ...dialog,
          content: '計劃成員編號大於13位',
          open: true,
        })
        return
      }
      let inputData = event.target.value
      let newDetail = { ...detail }
      let newRecords = isWorker1 ? newDetail.Worker1 : newDetail.Worker2
      newRecords.forEach((val) => {
        if (val.cardHolderId === row.cardHolderId) {
          if (goBackToEdit && val.errorDetail['MPFEXISTS']) {
            val.errorDetail['MPFEXISTS'] = inputData.replace(/\D/g, '')
          } else if (goBackToEdit && val.errorDetail['MPFIND']) {
            val.errorDetail['MPFIND'] = inputData.replace(/\D/g, '')
          } else {
            val.mpfScheme = inputData.replace(/\D/g, '')
          }
          val.isEdit = true
        }
      })
      setDetail(newDetail)
    }
  }

  function getCheckAgeDesc(row) {
    if (row.checkAge === 'MT65') {
      return '*'
    } else if (row.checkAge === 'LT18') {
      return '@'
    } else {
      return ''
    }
  }

  function getBankAccount(isWorker1, row, index) {
    if (goBackToEdit && row.errorDetail['BNKEXISTS']) {
      // bankaccount 重複
      return (
        <TextField
          sx={{
            textAlign: 'left',
            backgroundColor: '#9933ff',
          }}
          size="small"
          onWheel={(e) => {
            e.target.blur()
          }}
          variant="outlined"
          value={row.errorDetail['BNKEXISTS'] || ''}
          onChange={checkBankAccount(isWorker1, row, index)}
        />
      )
    } else if (goBackToEdit && row.errorDetail['BNKIND']) {
      return (
        <TextField
          sx={{
            textAlign: 'left',
            backgroundColor: '#Ff8888',
          }}
          onWheel={(e) => {
            e.target.blur()
          }}
          size="small"
          variant="outlined"
          value={row.errorDetail['BNKIND'] || ''}
          onChange={checkBankAccount(isWorker1, row, index)}
        />
      )
    } else {
      return (
        <TextField
          sx={{
            textAlign: 'left',
            backgroundColor: '#ffffff',
          }}
          onWheel={(e) => {
            e.target.blur()
          }}
          size="small"
          variant="outlined"
          value={row.bankAccount || ''}
          onChange={checkBankAccount(isWorker1, row, index)}
        />
      )
    }
  }

  function getMpfScheme(isWorker1, row) {
    if (goBackToEdit && row.errorDetail['MPFEXISTS']) {
      return (
        <TextField
          sx={{
            textAlign: 'left',

            backgroundColor: '#9933ff',
          }}
          onWheel={(e) => {
            e.target.blur()
          }}
          size="small"
          variant="outlined"
          value={row.errorDetail['MPFEXISTS'] || ''}
          onChange={checkMpfScheme(isWorker1, row)}
        />
      )
    } else if (goBackToEdit && row.errorDetail['MPFIND']) {
      return (
        <TextField
          sx={{
            textAlign: 'left',
            backgroundColor: '#Ff8888',
          }}
          onWheel={(e) => {
            e.target.blur()
          }}
          size="small"
          variant="outlined"
          value={row.errorDetail['MPFIND'] || ''}
          onChange={checkMpfScheme(isWorker1, row)}
        />
      )
    } else {
      return (
        <TextField
          sx={{
            textAlign: 'left',
            backgroundColor: '#ffffff',
            padding: '2px',
          }}
          onWheel={(e) => {
            e.target.blur()
          }}
          size="small"
          variant="outlined"
          value={row.mpfScheme ? row.mpfScheme : ''}
          onChange={checkMpfScheme(isWorker1, row)}
        />
      )
    }
  }

  const handlePrint = async () => {
    try {
      const content = contentRef.current

      if (content) {
        const canvas = await html2canvas(content)
        const imgData = canvas.toDataURL('image/png')

        // 创建一个新窗口用于打印
        const printWindow = window.open('', '', 'width=600,height=600')
        printWindow.document.open()
        printWindow.document.write('<img src="' + imgData + '" />')
        printWindow.document.close()

        // 等待图像加载完成后再执行打印
        printWindow.onload = function () {
          printWindow.print()
          printWindow.close()
        }
      }
    } catch (error) {
      console.error('打印出错：', error)
    }
  }

  return (
    <Container maxWidth={false}>
      <div style={{ display: 'block', textAlign: 'left' }}>
        <Button sx={{ float: 'left' }} onClick={handleBack}>
          <ArrowBackIcon />
          {t('button_back')}
        </Button>
      </div>

      <div style={{ display: 'block', textAlign: 'right' }}>
        <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportPdf({})}>
          <DownloadIcon />
          {t('button_export_pdf')}
        </Button>
      </div>

      <Container
        maxWidth={false}
        ref={componentRef}
        style={{
          display: 'block',
          textAlign: 'left',
          marginTop: '50px',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            display: 'block',
            textAlign: 'center',
            //  pt: "30px",
          }}
        >
          <b>{t('icon1_autopay_maintWorkerAccount')}</b>
        </Typography>
        <Grid container sx={{ mt: '10px' }}>
          <Grid item md={2}>
            <Button sx={{ mr: '20px' }} variant="contained" onClick={handleSave}>
              {t('button_storage_data')}
            </Button>
          </Grid>
          <Grid item md={10}>
            <Typography sx={{ color: 'red', fontSize: '15px', textAlign: 'left' }}>{t('icon1_autopay_maintWorkerAccount_attention1')}</Typography>
            <br />
            <Typography
              sx={{
                color: 'purple',
                mt: '-20px',
                fontSize: '15px',
                textAlign: 'left',
              }}
            >
              {t('icon1_autopay_maintWorkerAccount_attention2')}
            </Typography>
            <br />
            <Typography sx={{ mt: '-20px', fontSize: '15px', textAlign: 'left' }}>{t('icon1_autopay_maintWorkerAccount_attention3')}</Typography>
            <br />
            <Typography sx={{ mt: '-20px', fontSize: '15px', textAlign: 'left' }}>{t('icon1_autopay_maintWorkerAccount_attention4')}</Typography>
            <br />
          </Grid>
          <Table
            // padding="none"
            sx={{
              // pageBreakInside: "inherit",
              minWidth: 650,
              //  "& .MuiTableCell-root": { border: 1 },
            }}
            size="small"
          >
            <TableHead>
              <TableRow hover sx={{ border: '1px' }}>
                <TableCell colSpan={8} sx={{ textAlign: 'center' }}>
                  由前一次糧期截至現在有出入記錄並前一次糧期為
                  {detail.rsvendor['vendor_desc'] ? detail.rsvendor['vendor_desc'] : ''}
                  服務並有戶口之工人
                </TableCell>
              </TableRow>
              <TableRow hover sx={{ border: '1px' }}>
                <TableCell sx={{ maxWidth: '10px' }} />
                <TableCell>No.</TableCell>
                <TableCell>{t('EnglishName')}</TableCell>
                <TableCell>{t('ChineseName')}</TableCell>
                <TableCell>{t('ID_number')}</TableCell>
                <TableCell>{t('Employee_Account_Number')}</TableCell>
                <TableCell>{t('plan_Member_Number')}</TableCell>
                <TableCell>{t('Subcontractors')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detail.Worker1.length > 0 ? (
                detail.Worker1.map((row, index) => (
                  <TableRow hover key={index}>
                    <TableCell component="th" scope="row" sx={{ fontSize: '25px' }}>
                      {getCheckAgeDesc(row)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell>{row.englishName}</TableCell>
                    <TableCell>{row.chineseName}</TableCell>
                    <TableCell>{row.hkid}</TableCell>
                    <TableCell>{getBankAccount(true, row, index)}</TableCell>
                    <TableCell>{getMpfScheme(true, row)}</TableCell>
                    <TableCell>{row.subContractorDesc}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} sx={{ textAlign: 'center' }}>
                    No Records found!
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Button sx={{ mt: '20px', mb: '20px' }} variant="contained" onClick={handleSave}>
            {t('button_storage_data')}
          </Button>
          <Table
            // stickyHeader
            // padding="none"
            sx={{
              // pageBreakInside: "inherit",
              minWidth: 650,
              // "& .MuiTableCell-root": { border: 1 },
            }}
            size="small"
          >
            <TableHead>
              <TableRow hover sx={{ border: '1px' }}>
                <TableCell colSpan={8}>
                  由前一次糧期截至現在為止有出入記錄並前一次糧期為
                  {detail.rsvendor['vendor_desc'] ? detail.rsvendor['vendor_desc'] : ''}
                  服務但沒有戶口之工人或由前一次糧期截至現在為止有出入記錄並前一次糧期不是為
                  {detail.rsvendor['vendor_desc'] ? detail.rsvendor['vendor_desc'] : ''}
                  服務之工人
                </TableCell>
              </TableRow>
              <TableRow hover sx={{ border: '1px' }}>
                <TableCell> </TableCell>
                <TableCell>No.</TableCell>
                <TableCell>{t('EnglishName')}</TableCell>
                <TableCell>{t('ChineseName')}</TableCell>
                <TableCell>{t('ID_number')}</TableCell>
                <TableCell>{t('Employee_Account_Number')}</TableCell>
                <TableCell>{t('plan_Member_Number')}</TableCell>
                <TableCell>{t('Subcontractors')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detail.Worker2.length > 0 ? (
                detail.Worker2.map((row, index) => (
                  <TableRow hover key={index}>
                    <TableCell component="th" scope="row" sx={{ fontSize: '25px' }}>
                      {getCheckAgeDesc(row)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell>{row.englishName}</TableCell>
                    <TableCell>{row.chineseName}</TableCell>
                    <TableCell>{row.hkid}</TableCell>
                    <TableCell>{getBankAccount(false, row)}</TableCell>
                    <TableCell>{getMpfScheme(false, row)}</TableCell>
                    <TableCell>{row.subContractorDesc}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} sx={{ textAlign: 'center' }}>
                    No Records Found!
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Button sx={{ mt: '20px' }} variant="contained" onClick={handleSave}>
            {t('button_storage_data')}
          </Button>
        </Grid>
      </Container>
      <CustomBackdrop open={inProgress} />
      <CustomDialog open={dialog.open} title={dialog.title} content={dialog.content} action={dialog.action} />
      <CustomSnackbar open={snackbar.open} message={snackbar.message} action={snackbar.action} />
    </Container>
  )
}
