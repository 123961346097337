import React, { useRef, useEffect } from "react";
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as apiAtt from "src/utils/api/workerAttendanceRecord/AutoPayApiAttend";
import DownloadIcon from "@mui/icons-material/Download";
import $http, { $download } from "src/utils/api/services";
import moment from "moment";

// 工人出入記錄一覽表---
export default function LoeaerDetailWithVendor() {
  const componentRef = useRef();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [mainData, setMainData] = React.useState([]);
  const [dataSource, setDataSource] = React.useState([]);
  // 記錄的days天 格式為20
  const [recordDays, setRecordDays] = React.useState([]);
  // 記錄的dates日期 格式為2021/11/01
  const [recordDates, setRecordDates] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {}, []);

  const handleBack = () => {
    navigate(-1);
  };

  const printComponentRef = useRef();

  useEffect(() => {
    getDays();
    getDates();
    getInOutRecordList();
  }, []);

  const getDateFrom = () => {
    let dateF = "";
    dateF = searchParams.get("accessDateFrom");
    while (dateF.indexOf("-") >= 0) {
      dateF = dateF.replace("-", "/");
    }
    return dateF;
  };

  const getDateTo = () => {
    let dateF = "";
    dateF = searchParams.get("accessDateTo");
    while (dateF.indexOf("-") >= 0) {
      dateF = dateF.replace("-", "/");
    }
    return dateF;
  };

  const getDays = () => {
    let startDateString = getDateFrom();
    let endDateString = getDateTo();
    let startDate = new Date(startDateString);
    let endDate = new Date(endDateString);

    let list = [];
    while (startDate <= endDate) {
      list.push(moment(startDate).format("DD"));
      startDate = moment(startDate).add(1, "days");
    }

    setRecordDays([...new Set(list)]);
    console.log("days==", [...new Set(list)]);
  };

  const getDates = () => {
    let startDateString = getDateFrom();
    let endDateString = getDateTo();
    let startDate = new Date(startDateString);
    let endDate = new Date(endDateString);

    let list = [];
    while (startDate <= endDate) {
      list.push(moment(startDate).format("yyyy/MM/DD"));
      startDate = moment(startDate).add(1, "days");
    }

    setRecordDates([...new Set(list)]);
    console.log("recordDates==", [...new Set(list)]);
  };
  const exportExcel = (data) => {
    $download(
      `/attendance/report/getInOutRecordListWithVendorExcel`,
      "application/vnd.ms-excel",
      data
    );
  };

  const exportPDF = (data) => {
    $download(
      `/attendance/report/getInOutRecordListWithVendorPdf`,
      "application/pdf",
      data
    );
  };

  const getInOutRecordList = async () => {
    console.log(
      "senddadadada",
      getDateFrom(),
      getDateTo(),
      searchParams.get("siteId"),
      searchParams.get("smartCardId"),
      searchParams.get("subContractorId"),
      searchParams.get("vendorId"),
      searchParams.get("vendorDesc")
    );
    let list = await apiAtt.getInOutRecordListWithVendor(
      getDateFrom(),
      getDateTo(),
      searchParams.get("siteId"),
      searchParams.get("smartCardId"),
      searchParams.get("subContractorId"),
      searchParams.get("vendorId"),
      searchParams.get("vendorDesc")
    );

    if (list !== null) {
      setMainData(list.mainData);
      setDataSource(list.titleData);
    }
    setLoading(false);
  };

  const getRecord = (subData, destinDate) => {
    let record = "";
    subData.map((value) => {
      if (value.accessdate === destinDate) {
        if (value.isValue === "Y") {
          if (value.accessdatetimeminutes.length < 2) {
            record =
              value.accessdatetimehour + ":0" + value.accessdatetimeminutes;
          } else {
            record =
              value.accessdatetimehour + ":" + value.accessdatetimeminutes;
          }
        } else {
          record = "M";
        }
      }
      return [];
    });
    return record;
  };

  // 獲取每個日期的總數
  const getEveryDaysTotalRecord = (mainData, destinDate, isOneDay) => {
    let record = 0;

    mainData.map((mainDataRow) => {
      if (mainDataRow.coutList) {
        mainDataRow.coutList.map((countListRow) => {
          countListRow.subData.map((subDataRow) => {
            if (isOneDay) {
              if (subDataRow.accessdate === destinDate) {
                if (subDataRow.isValue === "Y") {
                  record += 1;
                }
              }
            } else {
              if (subDataRow.isValue === "Y") {
                record += 1;
              }
            }

            return [];
          });
          return [];
        });
      }

      return [];
    });
    return record;
  };

  // 獲取工人的總數
  const getTotalPersonCount = (mainData) => {
    let record = 0;

    mainData.map((mainDataRow) => {
      if (mainDataRow.coutList) {
        record += mainDataRow.coutList.length;
      }

      return [];
    });
    return record;
  };

  // 獲取每個人的總數
  const getVailablePersonRecordCount = (subData) => {
    // 只要個人的數據isValue為Y就算一條有效數據
    let recordCount = 0;
    subData.map((value) => {
      if (value.isValue === "Y") {
        recordCount += 1;
      }
      return [];
    });
    return recordCount;
  };

  // 獲取每個分判下面全部的人的總數
  const getVailableVendorRecordCount = (countList) => {
    let recordCount = 0;
    countList.map((value) => {
      recordCount += getVailablePersonRecordCount(value.subData);
      return [];
    });
    return recordCount;
  };

  return (
    <Container maxWidth={false}>
      <div style={{ display: "block", textAlign: "left" }}>
        <Button sx={{ float: "left" }} onClick={handleBack}>
          <ArrowBackIcon />
          {t("button_back")}
        </Button>
      </div>

      <div style={{ display: "block", textAlign: "right" }}>
        <Button
          sx={{ float: "right", mr: "20px" }}
          onClick={() =>
            exportPDF({
              siteId: searchParams.get("siteId"),
              siteDesc: searchParams.get("siteDesc"),
              smartCardId: searchParams.get("smartCardId"),
              subContractorId: searchParams.get("subContractorId"),
              subContractorDesc: searchParams.get("subContractorDesc"),
              dateFrom: searchParams.get("accessDateFrom"),
              dateTo: searchParams.get("accessDateTo"),
              vendorId: searchParams.get("vendorId"),
              vendorDesc: searchParams.get("vendorDesc"),
            })
          }
        >
          <DownloadIcon />
          {t("button_export_pdf")}
        </Button>
        <Button
          sx={{ float: "right", mr: "20px" }}
          onClick={() =>
            exportExcel({
              siteId: searchParams.get("siteId"),
              siteDesc: searchParams.get("siteDesc"),
              smartCardId: searchParams.get("smartCardId"),
              subContractorId: searchParams.get("subContractorId"),
              subContractorDesc: searchParams.get("subContractorDesc"),
              dateFrom: searchParams.get("accessDateFrom"),
              dateTo: searchParams.get("accessDateTo"),
              vendorId: searchParams.get("vendorId"),
              vendorDesc: searchParams.get("vendorDesc"),
            })
          }
        >
          <DownloadIcon />
          {t("button_export_excel")}
        </Button>
      </div>

      <div id="div" style={{}} ref={printComponentRef}>
        <Container maxWidth={false}>
          <div>
            <div style={{ display: "block", marginBottom: "60px" }}>
              <Typography variant="h4" sx={{ textAlign: "center" }}>
                <b>{t("search_worker_income_chat")}</b>
              </Typography>
            </div>

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            {
              <div>
                <TableContainer
                  component={Paper}
                  sx={{ marginBottom: "20px", backgroundColor: "transparent" }}
                >
                  <Table sx={{ textAlign: "left" }} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {t("search_company_name")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {""}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: "5px", width: 200, border: 0 }}>
                          {t("Vendor") + "："}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {searchParams.get("vendorId") +
                            "-" +
                            searchParams.get("vendorDesc")}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {t("search_autoPay_date_from")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {searchParams.get("accessDateFrom")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", width: 160, border: 0 }}>
                          {t("search_to_date_dot")}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {searchParams.get("accessDateTo")}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {t("Site_Name") + "："}
                        </TableCell>
                        <TableCell sx={{ px: "5px", border: 0 }}>
                          {!searchParams.get("siteDesc")
                            ? "All"
                            : searchParams.get("siteDesc")}
                        </TableCell>
                      </TableRow>

                      {searchParams.get("subContractorDesc") && (
                        <TableRow>
                          <TableCell sx={{ px: "5px", border: 0 }}>
                            {t("search_name_of_vendor")}
                          </TableCell>
                          <TableCell sx={{ px: "5px", border: 0 }}>
                            {searchParams.get("subContractorDesc")}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }
          </div>

          {/* table */}
          <TableContainer sx={{ marginTop: "20px" }}>
            <Table sx={{ p: "2px" }} size="small">
              <TableHead>
                <TableRow hover>
                  {/* (siteName, carid, engName, chName, joy, approveDate, inDate, inVateDate, applyDate */}
                  <TableCell sx={{ whiteSpace: "nowrap", maxWidth: 5 }}>
                    序號
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", maxWidth: 3 }}>
                    {t("search_name_title")}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", maxWidth: 5 }}>
                    {t("search_smartCardId_notDot")}
                  </TableCell>

                  {recordDays.map((dayDesc, index) => (
                    <TableCell
                      key={index + Math.random}
                      sx={{ fontSize: "15px", textAlign: "left", maxWidth: 0 }}
                    >
                      {dayDesc}
                    </TableCell>
                  ))}

                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                    {t("search_totalDays")}
                  </TableCell>
                </TableRow>
              </TableHead>

              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>

              {mainData.map((mainDataRow, index) => (
                <>
                  <TableRow hover key={index + Math.random}>
                    <TableCell
                      colSpan={recordDays.length + 3}
                      sx={{ fontSize: "15px", textAlign: "left" }}
                    >
                      {mainDataRow.employerName}{" "}
                    </TableCell>
                  </TableRow>

                  {mainDataRow.coutList.map((coutListRow, coutListRowIndex) => (
                    <>
                      {/* 單個人數據，需要循環遍歷 */}
                      <TableRow
                        hover
                        key={coutListRowIndex + Math.random}
                        sx={{
                          backgroundColor:
                            coutListRowIndex % 2 !== 0 ? "#DCDCDC" : "#ffffff",
                        }}
                      >
                        {/* 序號 */}
                        <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                          {coutListRowIndex + 1}{" "}
                        </TableCell>
                        {/* 名字 */}
                        <TableCell
                          sx={{
                            fontSize: "15px",
                            textAlign: "left",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {coutListRow.chineseName}{" "}
                        </TableCell>
                        {/* 智能卡編號 */}
                        <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                          {coutListRow.smartCardId}{" "}
                        </TableCell>
                        {/* 每天的数据 */}
                        {recordDates.map(
                          (recordDatesRow, recordDatesRowIndex) => (
                            <TableCell
                              key={recordDatesRowIndex + Math.random}
                              sx={{ fontSize: "15px", textAlign: "left" }}
                            >
                              {getRecord(coutListRow.subData, recordDatesRow)}
                            </TableCell>
                          )
                        )}
                        {/* 总日数 */}
                        <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                          {getVailablePersonRecordCount(coutListRow.subData)}{" "}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}

                  {/* 总数 */}
                  <TableRow hover key={index + Math.random + 2}>
                    <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                      {}
                    </TableCell>
                    {/* colSpan表示合併單元格 */}
                    <TableCell
                      colSpan={recordDays.length + 2}
                      sx={{ fontSize: "15px", textAlign: "left" }}
                    >
                      {t("search_totals_title")}
                    </TableCell>
                    <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                      {getVailableVendorRecordCount(mainDataRow.coutList)}{" "}
                    </TableCell>
                  </TableRow>

                  <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                    {}
                  </TableCell>
                </>
              ))}

              {/* 最后的总数 */}
              <TableRow hover>
                <TableCell
                  colSpan={3}
                  sx={{ fontSize: "15px", textAlign: "left" }}
                >
                  {t("search_totals_title")}
                </TableCell>
                {/* 每天的總数据 */}
                {recordDates.map((recordDatesRow, index) => (
                  <TableCell
                    key={index + Math.random}
                    sx={{ fontSize: "15px", textAlign: "left" }}
                  >
                    {getEveryDaysTotalRecord(mainData, recordDatesRow, true)}
                  </TableCell>
                ))}
                {/* 每天總數據的和 */}
                <TableCell sx={{ fontSize: "15px", textAlign: "left" }}>
                  {getEveryDaysTotalRecord(mainData, null, false)}
                </TableCell>
              </TableRow>

              {/* 承判商工人總數： */}
              <TableRow hover>
                {/* colSpan表示合併單元格 */}
                <TableCell
                  colSpan={recordDays.length + 3}
                  sx={{ fontSize: "15px", textAlign: "left" }}
                >
                  {t("search_total_vendors")}
                  {getTotalPersonCount(mainData)}{" "}
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
          {
            <div>
              <Typography sx={{ textAlign: "left", marginTop: "60px" }}>
                數字 (小時:分鐘) - 工人當天最先入閘時間及最後出閘時間的差額
                <br />
                "M" - 工人出入閘記錄不正確
                <br />
                " " - 當天沒有出入閘記錄
                <br />
              </Typography>
            </div>
          }
        </Container>
      </div>
    </Container>
  );
}
