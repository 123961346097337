import { instance } from '../../instance'

// Combobox
export const getComboboxList = async (url, data) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}` + url,
    data: data,
  }).then(
    (result) => result.data,
    (error) => error,
  )
}

//  獲取加載首頁的route list
export const getAllMenu = async () => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/userController/menuViewByParent`,
  }).then(
    (result) => {
      return result?.data?.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}

//  獲取当前登陆的用户
export const getUser = async () => {
  return instance({
    method: 'get',
    url: `${process.env.REACT_APP_WEB_URL}/currentUser`,
  }).then(
    (result) => {
      return result?.data?.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}
//  修改 郵箱賬號   http://localhost:3000/api/user-master/changeEmail
// export const changeEmail = async (data) => {
//   return instance({
//     method: 'post',
//     url: `${process.env.REACT_APP_OIDC_CHANGE_EMAIL_URL_DEV}/api/user-master/changeEmail`,
//     data: data,
//   }).then(
//     (result) => {
//       return result
//     },
//     (error) => {
//       // alert("請求出錯")
//       return []
//     },
//   )
// }

export const changeEmail = async (data) => {
  return instance.post(`${process.env.REACT_APP_OIDC_CHANGE_EMAIL_CHANGE_URL}/api/user-master/changeEmail`, data).then(
    (result) => {
      return result
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}
export const sendEmail = async (data) => {
  return instance.post(`${process.env.REACT_APP_WEB_URL}/notificationController/sendcaptcha`, data).then(
    (result) => {
      return result
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}
// cuauat.sanfield.com.hk/api/user-master/changeEmail
