import { instance } from "../../instance";

export const getMaintWorkerAutopayMainCreateSearch = async () => {
  let data = {};
  // data.userId = "kfwgvz";
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getMaintWorkerAutopayMainCreateSearch`,
    data,
  }).then(
    (result) => result.data,
    (error) => error
  );
};

export const insertVendorDetail = async (condition) => {
  let data = { ...condition };
  // data.userId = "kfwgvz";
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/insertVendorDetail`,
    data,
  }).then(
    (result) => result.data,
    (error) => error
  );
};

export const insertPayrollGap = async (buildGapCondition) => {
  let data = { ...buildGapCondition };
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/insertPayrollGap`,
    data,
  }).then(
    (result) => result.data,
    (error) => error
  );
};

export const getWorkerDetail = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getAccessHold`,
    data,
  }).then(
    (result) => result.data,
    (error) => error
  );
};