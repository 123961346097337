import React, { useState, useEffect } from 'react'
import { Button, Container, TextField, Typography, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CustomBackdrop from 'src/components/common/CustomBackdrop'
import CustomDialog from 'src/components/common/CustomDialog'
import CustomSnackbar from 'src/components/common/CustomSnackbar'
import { confirm } from 'src/components/common'
import moment from 'moment'
import { getMaintWorkerAutopayMainCreateSearch, insertVendorDetail, insertPayrollGap, getWorkerDetail } from '../../../../utils/api/workerAttendanceRecord/MaintWorkerAutopayMainCreateSearch'

export default function MaintWorkerAutopayMainCreateSearch(props) {
  const { setType } = props
  const { t } = useTranslation()
  const [step, setStep] = useState(0)
  const [condition, setCondition] = useState({
    vendorId: '',
    vendorDesc: '',
    bankAccountName: '',
    bankAccount: '',
    branchCode: '',
    schemeNo: '',
    contactPerson: '',
    phoneNumber: '',
    dateFrom: '',
    dateTo: '',
    // oriDateFrom不會變, 用於判斷dateFrom能否修改
    oriDateFrom: '',
  })
  const [buildGapCondition, setBuildGapCondition] = useState({
    dateFrom: '',
    dateTo: '',
    remark: '',
  })
  const [inProgress, setInProgress] = useState(false)
  const [dialog, setDialog] = useState({
    title: '',
    content: '',
    open: false,
    action: '',
  })
  const [snackbar, setSnackbar] = useState({
    message: '',
    action: '',
    open: false,
  })

  useEffect(() => {
    const getSearchCondition = async () => {
      setInProgress(true)
      let res = await getMaintWorkerAutopayMainCreateSearch()
      if (res && res.code === 200) {
        if (res.data && res.data.length > 0) {
          setCondition({
            ...condition,
            vendorId: res.data[0].vendorId,
            vendorDesc: res.data[0].vendorDesc,
            bankAccountName: res.data[0].bankAccountName,
            bankAccount: res.data[0].bankAccount,
            branchCode: res.data[0].branchCode,
            schemeNo: res.data[0].schemeNo,
            contactPerson: res.data[0].contactPerson,
            phoneNumber: res.data[0].phoneNumber,
            dateFrom: res.data[0].dateFrom,
            oriDateFrom: res.data[0].dateFrom,
          })
        }
      } else {
        setDialog({
          ...dialog,
          content: '請求錯誤',
          open: true,
          action: dialogAction(
            false,
            () => {
              setDialog({
                ...dialog,
                open: false,
              })
            },
            null,
          ),
        })
      }
      setInProgress(false)
    }
    getSearchCondition()
  }, [])

  const handleCancel = () => {
    setType(0)
  }

  const saveAction = async () => {
    setInProgress(true)
    let saveData = { ...condition }
    let workerDetail = await getWorkerDetail({
      dateTo: moment(new Date(condition.dateTo).getTime()).format('yyyy/MM/DD'),
      dateFrom: moment(new Date(condition.dateFrom).getTime()).format('yyyy/MM/DD'),
    })
    console.log('saveData', condition)
    console.log(workerDetail)
    if (workerDetail.code !== 200) {
      setDialog({
        ...dialog,
        content: workerDetail.message,
        open: true,
        action: dialogAction(
          false,
          () => {
            setDialog({
              ...dialog,
              open: false,
            })
          },
          null,
        ),
      })
      setInProgress(false)
      return
    }
    delete saveData['oriDateFrom']
    if (saveData.dateFrom) {
      saveData.dateFrom = saveData.dateFrom.replaceAll('-', '/')
    }

    if (saveData.dateTo) {
      saveData.dateTo = saveData.dateTo.replaceAll('-', '/')
    }
    let res = await insertVendorDetail(saveData)
    setInProgress(false)
    if (res && res.code === 200) {
      if (res.message && res.message === 'SUCCESS') {
        setDialog({ ...dialog, open: false })
        confirm({
          title: t('tip'),
          content: '新增工人支薪及供款糧期表成功',
          okText: '確認',
          onOk: () => {
            setType(0)
          },
        })
        // setSnackbar({
        //   ...snackbar,
        //   open: true,
        //   message: "新增工人支薪及供款糧期表成功",
        //   action: (
        //     <Button
        //       variant="contained"
        //       onClick={() => {
        //         setType(0);
        //       }}
        //     >
        //       確認
        //     </Button>
        //   ),
        // });
      } else {
        setDialog({
          ...dialog,
          content: res.message,
          open: true,
          action: dialogAction(
            false,
            () => {
              setDialog({
                ...dialog,
                open: false,
              })
            },
            null,
          ),
        })
      }
    } else {
      setDialog({
        ...dialog,
        content: '請求錯誤',
        open: true,
        action: dialogAction(
          false,
          () => {
            setDialog({
              ...dialog,
              open: false,
            })
          },
          null,
        ),
      })
    }
    // setInProgress(false);
  }

  function hasError() {
    let errorMessage = ''
    if (!condition.bankAccount) {
      errorMessage = '銀行戶口號碼不能為空！'
    }
    if (!condition.bankAccountName) {
      errorMessage = '銀行戶口號碼名稱不能為空！'
    }
    if (condition.bankAccount.length < 14) {
      errorMessage = '銀行戶口號碼不能小於14！'
    }
    if (condition.bankAccountName.length > 20) {
      errorMessage = '銀行戶口名稱不能大於20！'
    }
    if (!condition.phoneNumber) {
      errorMessage = '電話號碼不能為空！'
    }
    if (!condition.contactPerson) {
      errorMessage = '聯絡人姓名不能為空！'
    }
    if (!condition.schemeNo) {
      errorMessage = '計劃成員編號不能為空！'
    }
    if (!condition.dateTo) {
      errorMessage = '糧期（至）不能為空！'
    }
    if (!condition.dateFrom) {
      errorMessage = '糧期（由）不能為空！'
    }
    if (new Date(condition.dateTo).getTime() < new Date(condition.dateFrom).getTime()) {
      errorMessage = '糧期（至）不能小於糧期（由）！'
    }
    return errorMessage
  }

  const handleSave = () => {
    let errorMessage = hasError()
    if (errorMessage) {
      setDialog({
        ...dialog,
        open: true,
        content: errorMessage,
        action: dialogAction(
          false,
          () => {
            setDialog({
              ...dialog,
              open: false,
            })
          },
          null,
        ),
      })
    } else {
      if (moment(condition.dateTo).diff(moment(), 'day') >= 0) {
        setDialog({
          ...dialog,
          content: "選擇 '確定' 即選擇製造短糧期, 選擇 '取消' 即暫時不作任何改動, 選擇之糧期(至)在今日或之後, 只能製作短糧期",
          open: true,
          action: dialogAction(
            true,
            () => {
              saveAction()
            },
            () => {
              setDialog({
                ...dialog,
                open: false,
              })
            },
          ),
        })
      } else {
        saveAction()
      }
    }
  }

  const handleBuildGap = () => {
    if (condition.dateFrom && condition.dateTo) {
      setBuildGapCondition({
        ...buildGapCondition,
        dateFrom: condition.dateFrom,
        dateTo: condition.dateTo,
      })
      setStep(1)
    } else {
      setDialog({
        ...dialog,
        content: '沒有選擇糧期 (由) 及 糧期 (至) ',
        open: true,
        action: dialogAction(
          false,
          () => {
            setDialog({
              ...dialog,
              open: false,
            })
          },
          null,
        ),
      })
    }
  }

  const handleBuildGapSave = () => {
    const saveBuildGap = async () => {
      setInProgress(true)
      let saveData = { ...buildGapCondition }
      if (saveData.dateFrom) {
        saveData.dateFrom = Date.parse(new Date(saveData.dateFrom.replaceAll('-', '/')))
      }
      if (saveData.dateTo) {
        saveData.dateTo = Date.parse(new Date(saveData.dateTo.replaceAll('-', '/')))
      }
      saveData.vendorId = condition.vendorId
      let res = await insertPayrollGap(saveData)
      setInProgress(false)
      if (res && res.code === 200) {
        confirm({
          title: t('tip'),
          content: res.message,
          okText: '確認',
          onOk: () => {
            setType(0)
          },
        })
        // setSnackbar({
        //   ...snackbar,
        //   open: true,
        //   message: res.message,
        //   action: (
        //     <Button
        //       variant="contained"
        //       onClick={() => {
        //         setType(0);
        //       }}
        //     >
        //       確認
        //     </Button>
        //   ),
        // });
      } else {
        setDialog({
          ...dialog,
          content: '請求錯誤',
          open: true,
          action: dialogAction(
            false,
            () => {
              setDialog({
                ...dialog,
                open: false,
              })
            },
            null,
          ),
        })
      }
      // setInProgress(false);
    }
    saveBuildGap()
  }

  const handleBuildGapCancel = () => {
    setType(0)
  }

  const dialogAction = (showCancelButton, handleConfirmDialog, handleCancelDialog) => {
    if (showCancelButton) {
      return (
        <>
          <Button variant="contained" onClick={handleConfirmDialog}>
            確認
          </Button>
          <Button variant="contained" onClick={handleCancelDialog}>
            取消
          </Button>
        </>
      )
    } else {
      return (
        <Button variant="contained" onClick={handleConfirmDialog}>
          確認
        </Button>
      )
    }
  }

  return (
    <Container sx={{ maxWidth: '1240px', textAlign: 'center' }} maxWidth="md">
      {!step ? (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">
              <b>{t('icon1_autopay_maintWorkerAccount_title')}</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ marginTop: '20px' }}>
              {t('icon1_autopay_maintWorkerAccount_label1')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth size="small" label={'僱主名稱'} variant="outlined" value={condition.vendorDesc ? condition.vendorDesc : ''} sx={{ maxWidth: '400px' }} disabled />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              label={'銀行戶口名稱'}
              onWheel={(e) => {
                e.target.blur()
              }}
              variant="outlined"
              value={condition.bankAccountName ? condition.bankAccountName : ''}
              sx={{ maxWidth: '400px' }}
              onChange={(event) => {
                setCondition({
                  ...condition,
                  bankAccountName: event.target.value,
                })
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              onWheel={(e) => {
                e.target.blur()
              }}
              label={'銀行戶口號碼'}
              variant="outlined"
              value={condition.bankAccount ? condition.bankAccount : ''}
              sx={{ maxWidth: '400px' }}
              onChange={(event) => {
                let inputData = event.target.value
                setCondition({
                  ...condition,
                  bankAccount: inputData.replace(/\D/g, ''),
                })
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              label={'計劃成員編號'}
              onWheel={(e) => {
                e.target.blur()
              }}
              variant="outlined"
              value={condition.schemeNo ? condition.schemeNo : ''}
              sx={{ maxWidth: '400px' }}
              onChange={(event) => {
                let inputData = event.target.value
                let newCondition = { ...condition }
                newCondition['schemeNo'] = inputData.replace(/\D/g, '')
                setCondition({ ...newCondition })
              }}
              // helperText={"1"}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              label={'僱主代號'}
              onWheel={(e) => {
                e.target.blur()
              }}
              variant="outlined"
              value={condition.branchCode ? condition.branchCode : ''}
              sx={{ maxWidth: '400px' }}
              onChange={(event) => {
                let inputData = event.target.value
                let newCondition = { ...condition }
                newCondition['branchCode'] = inputData.replace(/\D/g, '')
                setCondition({
                  ...newCondition,
                  branchCode: inputData.replace(/\D/g, ''),
                })
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              label={'聯絡人姓名'}
              onWheel={(e) => {
                e.target.blur()
              }}
              variant="outlined"
              value={condition.contactPerson ? condition.contactPerson : ''}
              sx={{ maxWidth: '400px' }}
              onChange={(event) => {
                setCondition({
                  ...condition,
                  contactPerson: event.target.value,
                })
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              label={'電話號碼'}
              onWheel={(e) => {
                e.target.blur()
              }}
              variant="outlined"
              value={condition.phoneNumber ? condition.phoneNumber : ''}
              sx={{ maxWidth: '400px' }}
              onChange={(event) => {
                setCondition({
                  ...condition,
                  phoneNumber: event.target.value,
                })
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              label={'糧期（由）'}
              variant="outlined"
              value={condition.dateFrom ? moment(condition.dateFrom, 'YYYY/MM/DD').format('YYYY-MM-DD') : ''}
              sx={{ maxWidth: '400px' }}
              onChange={(event) => {
                setCondition({
                  ...condition,
                  dateFrom: event.target.value,
                })
              }}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: '2099-12-31',
              }}
              disabled={condition.oriDateFrom !== null && condition.oriDateFrom !== ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              label={'糧期（至）'}
              variant="outlined"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: '2099-12-31',
              }}
              value={condition.dateTo ? moment(condition.dateTo, 'YYYY/MM/DD').format('YYYY-MM-DD') : ''}
              sx={{ maxWidth: '400px' }}
              onChange={(event) => {
                setCondition({
                  ...condition,
                  dateTo: event.target.value,
                })
              }}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={12}>
            <Button onClick={handleSave} sx={{ mt: '20px', width: '100px' }} variant="contained">
              {t('button_save')}
            </Button>
            <Button onClick={handleCancel} sx={{ mt: '20px', ml: '10px', width: '100px' }} variant="contained">
              {t('button_cancel')}
            </Button>
            <Button onClick={handleBuildGap} sx={{ mt: '20px', ml: '10px' }} variant="contained">
              {'建立沒有供款的糧期表'}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '35px' }}>
              <b>新增沒有供款的糧期表</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              sx={{ textAlign: 'left', maxWidth: '400px', mt: '5px' }}
              size="small"
              label={t('糧期（由）')}
              variant="outlined"
              value={buildGapCondition.dateFrom ? moment(buildGapCondition.dateFrom, 'YYYY/MM/DD').format('YYYY-MM-DD') : ''}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: '2099-12-31',
              }}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              sx={{ textAlign: 'left', maxWidth: '400px', mt: '5px' }}
              size="small"
              label={'糧期（至）'}
              variant="outlined"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: '2099-12-31',
              }}
              value={buildGapCondition.dateTo ? moment(buildGapCondition.dateTo, 'YYYY/MM/DD').format('YYYY-MM-DD') : ''}
              onChange={(event) => {
                setBuildGapCondition({
                  ...buildGapCondition,
                  dateTo: event.target.value,
                })
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              sx={{ textAlign: 'left', maxWidth: '400px', mt: '5px' }}
              size="small"
              label="備註"
              variant="outlined"
              value={buildGapCondition.remark ? buildGapCondition.remark : ''}
              onChange={(event) => {
                setBuildGapCondition({
                  ...buildGapCondition,
                  remark: event.target.value,
                })
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleBuildGapSave} sx={{ mt: '20px', width: '100px' }} variant="contained">
              {t('button_save')}
            </Button>
            <Button onClick={handleBuildGapCancel} sx={{ mt: '20px', ml: '10px', width: '100px' }} variant="contained">
              {t('button_cancel')}
            </Button>
          </Grid>
        </Grid>
      )}
      <CustomBackdrop open={inProgress} />
      <CustomDialog open={dialog.open} title={dialog.title} content={dialog.content} action={dialog.action} />
      <CustomSnackbar open={snackbar.open} message={snackbar.message} action={snackbar.action} />
    </Container>
  )
}
