import { useState } from 'react'
import { Box, Checkbox, Collapse, Grid, IconButton, TableCell, tableCellClasses, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import CTableExpandable from './CTableExpandable'

const StyledTableCell = styled(TableCell)(() => ({
    borderBottom: '1px solid #f6f6f7',
    fontSize: '1rem',
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#ecf0f1',
        color: '#333',
        fontSize: '1rem'
    }
}))
const StyledTableRow = styled(TableRow)(() => ({
    fontSize: '1rem',
    '&:hover': {
        backgroundColor: '#f6f6f7'
    }
}))

export default function CTableRow(props) {
    const { columns, cellSx, expandable, index, row, rowSelection, isSelected, rowKey, onClick, onSelectClick } = props

    const [open, setOpen] = useState(false)

    const isItemSelected = isSelected(row[rowKey])
    
    return (
        <>
            <StyledTableRow sx={{ '& > *': expandable ? { borderBottom: 'unset !important' } : '' }}>
                {/* 下拉 */}
                {expandable ? (
                    <StyledTableCell sx={{ borderLeft: open ? '3px solid #1976d2' : 'none' }}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </StyledTableCell>
                ) : null}

                {/* 选择功能 */}
                {rowSelection ? (
                    rowSelection.type === 'checkbox' ? (
                        <StyledTableCell padding="checkbox" sx={{ ...cellSx }}>
                            <Checkbox color="primary" checked={isItemSelected} onClick={(event) => onSelectClick(event, row[rowKey])} />
                        </StyledTableCell>
                    ) : null
                ) : null}

                {columns.map((column, index) => (
                    <StyledTableCell
                        align={column.align}
                        key={column.dataIndex || null || undefined}
                        style={{ height: 21 }}
                        sx={{ 
                            width: column.width ? column.width : 'auto', 
                            textAlign: column.align, 
                            ...cellSx 
                        }}
                        onClick={column.click ? onClick && (() => onClick(row, column)) : undefined}
                    >
                        {column.render ? column.render(row, index) : (row[column.dataIndex])}
                    </StyledTableCell>
                ))}
            </StyledTableRow>

            {expandable ? (
                <StyledTableRow>
                    <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0, borderLeft: open ? '3px solid #1976d2' : 'none' }} />
                    <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ paddingBottom: 2, paddingTop: 2 }}>
                                {expandable.expandedRowRenderGrid ? (
                                    <Grid container maxWidth="lg" spacing={1}>
                                        {expandable.expandedRowRenderGrid.map((expandableItem, expandableItemIndex) => (
                                            <Grid key={expandableItem.dataIndex} item xs={12} sm={6} md={3}>
                                                <CTableExpandable
                                                    label={expandableItem.label}
                                                    value={row[expandableItem.dataIndex]}
                                                    render={expandableItem.render ? expandableItem.render(row, expandableItemIndex) : (row[expandableItem.dataIndex])}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                ) : null}
                                {expandable.expandedRowRender ? expandable.expandedRowRender(row, index, open, () => setOpen(!open)) : null}
                            </Box>
                        </Collapse>
                    </StyledTableCell>
                </StyledTableRow>
            ) : null}
        </>
    )
}
