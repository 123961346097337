
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const TextTitle = styled('div')(({ theme }) => ({
    background: "linear-gradient(180deg, #059aff, #007dd1)",
    color: '#ffffff',
    paddingLeft: '15px',
    paddingRight: '5px',
    paddingTop: '5px',
    paddingBottom: '5px',
    borderRadius: '12px',
}));
const TextLine = styled('div')(({ theme }) => ({
    color: theme.palette.common.black,
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '5px',
    paddingBottom: '5px'
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center', minHeight: '50px' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        //   color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export default function Attention(prop) {
    const { callback } = prop;

    const [open, setOpen] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        callback();
    };

    return (
        <div>
            <BootstrapDialog
                maxWidth='xl'
                // onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {/* 注意事項 */}
                </BootstrapDialogTitle>
                <DialogContent dividers>

{/* 
                    <TextLine style={{ textAlign: 'center' }}>
                        <img src={process.env.PUBLIC_URL + "/assets/images/202306_Euromoney-banner_CN.jpg"} alt='新鴻基地產' style={{ display: "inline-block", width: '100%' }} />
                    </TextLine>
                    <TextTitle>
                        “2023年度《欧洲货币》房地产大奖” — 请投票予新鸿基地产
                    </TextTitle>
                    <TextLine>
                        尊敬的先生/女士：
                    </TextLine>
                    <TextLine>
                        您好！为表彰过去12个月房地产行业表现最佳的产品和服务提供商，<p style={{ display: 'inline', color: '#82abd5' }}><b>《欧洲货币》杂志主办“2023年度房地产大奖”</b></p>，并现正接受投票。我们很高兴，<p style={{ display: 'inline', color: '#82abd5' }}><b>新鸿基地产（Sun Hung Kai Properties）已入围</b></p>香港特别行政区、中国内地和亚太地区多个<p style={{ display: 'inline', color: '#82abd5' }}><b>开发商类别</b></p>。
                    </TextLine>
                    <TextLine>
                        作为新鸿基地产宝贵的支持者，您的参与及宝贵的一票对我们非常重要。现诚邀阁下再次鼎力支持和投票予<p style={{ display: 'inline', color: '#82abd5' }}><b>新鸿基地产</b></p>。投票过程只需几分钟，请参考以下指引进行线上投票：
                    </TextLine>
                    <TextLine>
                        <ul style={{ listStyleType: 'disc', paddingLeft: 40 }}>
                            <li>请填写个人信息</li>
                            <li>投票</li>
                        </ul>
                    </TextLine>
                    <TextLine>
                        <ul style={{ listStyleType: 'circle', paddingLeft: 80 }}>
                            <li>请选择<p style={{ display: 'inline', color: '#82abd5' }}><b>“开发项目/开发商”</b></p> 作为投票的奖项类别</li>
                            <li>请选择<p style={{ display: 'inline', color: '#82abd5' }}><b>“国内/单一国家”</b></p>和<p style={{ display: 'inline', color: '#82abd5' }}><b>“亚太区”</b></p>作为投票的地域范围</li>
                        </ul>
                    </TextLine>
                    <TextLine>
                        <ul style={{ listStyleType: 'square', paddingLeft: 120 }}>
                            <li>请选择<p style={{ display: 'inline', color: '#82abd5' }}><b>“中国内地”</b></p>作为投票的国家</li>
                        </ul>
                    </TextLine>
                    <TextLine>
                        <ul style={{ listStyleType: 'circle', paddingLeft: 80 }}>
                            <li><b>新鸿基地产入围的开发商类别</b>如下:</li>
                        </ul>
                    </TextLine>
                    <TextLine>
                        <ul style={{ listStyleType: 'square', paddingLeft: 120 }}>
                            <li>亚太区及中国内地的<p style={{ display: 'inline', color: '#82abd5' }}><b>最佳开发商</b></p></li>
                            <li>亚太区及中国内地的<p style={{ display: 'inline', color: '#82abd5' }}><b>最佳住宅项目开发商</b></p></li>
                            <li>亚太区及中国内地的<p style={{ display: 'inline', color: '#82abd5' }}><b>最佳零售项目开发商</b></p></li>
                            <li>亚太区及中国内地的<p style={{ display: 'inline', color: '#82abd5' }}><b>最佳办公楼开发商</b></p></li>
                            <li>亚太区的<p style={{ display: 'inline', color: '#82abd5' }}><b>最佳个别开发项目</b></p> – 环球贸易广场（ICC）及上海国金中心（Shanghai IFC）</li>
                        </ul>
                    </TextLine>
                    <TextLine>
                        <ul style={{ listStyleType: 'circle', paddingLeft: 80 }}>
                            <li><b>请选出相关类别中的第一及第二名</b></li>
                        </ul>
                    </TextLine>
                    <TextLine>
                        <ul style={{ listStyleType: 'square', paddingLeft: 120 }}>
                            <li><b>每个类别的第一名和第二名不能相同</b></li>
                        </ul>
                    </TextLine>
                    <TextLine>
                        <ul style={{ listStyleType: 'circle', paddingLeft: 80 }}>
                            <li><b>点击“下一步”，系统自动保存投票进度。</b>如无法一次完成投票过程，您可以稍后通过同一浏览器打开投票链接继续投票</li>
                        </ul>
                    </TextLine>
                    <TextLine>
                        点击以下链接查看<b>步骤指南：</b>
                    </TextLine>
                    <TextLine style={{ textAlign: 'center' }}>
                        <Link href='https://www.shkp.com/Html/eDM/SHKP-Euromoney/202306/stepbystepguide_MainlandChina-SC.pdf' target="_blank" >
                            <img src={process.env.PUBLIC_URL + "/assets/images/step_guide.jpg"} alt='步骤指南' style={{ display: "inline-block" }} />
                        </Link>
                    </TextLine>
                    <TextLine>
                        为方便线上投票，<p style={{ display: 'inline' }}><b>请点击下方图标进入投票网站：</b></p>
                    </TextLine>
                    <TextLine style={{ textAlign: 'center' }}>
                        <Link href='https://euromoney.qualtrics.com/jfe/form/SV_6A9HnurkY6gGtoi' target="_blank">
                            <img src={process.env.PUBLIC_URL + "/assets/images/vote.jpg"} alt='线上投票' style={{ display: "inline-block" }} />
                        </Link>
                    </TextLine>
                    <TextLine>
                        请注意投票<p style={{ display: 'inline', color: '#82abd5' }}><b>截止日期为2023年7月21日（星期五）。</b></p>
                    </TextLine>
                    <TextLine>
                        <p style={{ display: 'inline' }}><b>每家公司的投票人数没有上限。</b></p>因此，如能将此邀请发送同事并鼓励他们参与，我们将不胜感激。本次投票资料将绝对保密，绝不透露投票人或公司资料。
                    </TextLine>
                    <TextLine>
                        我们非常感谢您的宝贵时间和鼎力支持。
                    </TextLine>
                    <TextLine>
                        新鸿基地产
                    </TextLine> */}

                    {/* 第二條信息 */}
                    <TextTitle>
                        聲明：本聲明內容將會隨時更新(不另通告)，並即時顯示於網頁內，請每次登入本網頁內前必須查閱。若閣下每次登入我們的網頁內時，即表示同意接受以下最新之聲明所約束。
                    </TextTitle>
                    <TextLine>
                        1.本網頁只提供予獲本公司授權之承判商授權人仕/使用者瀏灠。<br />
                        2.本網頁只提供本公司屬下地盤各承判商之屬下員工與分判工人以智能咭記錄進出閘口的資料。<br />
                        3.本公司各承判商如須進入本網頁瀏灠，請向本公司之(I.T.)資訊科技組查詢；查詢電話：28285324<br />
                        4.本網頁是以報表式提供有關的資料。<br />
                        5.本網頁只提供過往三個月內的資料。<br />
                        6.本網頁將於每週首個工作天更新內容。<br />
                        7.本公司各承判商授權人仕/使用者只可下載或列印所屬承判商屬下員工與分判工人之進出地盤記錄。<br />
                        8.本公司各承判商屬下員工與分判工人的登記資料；均以地盤工作証(智能咭)申請表內容為準。<br />
                        9.在本網頁所有下載或列印的資料只供參考用途。<br />
                        10.本公司有權隨時終止授權人仕/使用者之登入權限。<br />
                        11.本公司有權拒絕確認下載後的資料之準確性，如有爭議，一概以網上記錄為準。<br />
                        12.本公司保留所有從本網頁下載的資料之最終使用權。<br />
                        13.本公司有權禁止任何人仕使用/行使從本網頁收集的資料記錄，若任何人仕因引用從本網頁收集的資料而發生任何法律訴訟，一切法律責任概與本公司無關。<br />
                        14.任何人仕從本網頁收集之資料，未經本公司同意，不得向第三者或業務夥伴等…披露、交換、傳遞或轉移，否則，即屬違法。<br />
                        15.如對上述條文或本網頁有任何查詢，請致電28285324<br />
                    </TextLine>
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>
                        Save changes
                    </Button>
                </DialogActions> */}
            </BootstrapDialog>
        </div >
    );
}