import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
// @mui
import { styled } from '@mui/material/styles'
//
import { useTranslation } from 'react-i18next'
import { CDelay, CDialog } from 'src/components/common'
import VendorAuthorForm from 'src/pages/icon/vendorAutoSystem/vendorAuthor/VendorAuthorForm'
import * as api from 'src/utils/api/common/index'
import RefreshToken from 'src/utils/api/workerAttendanceRecord/RefreshToken'
import Header from './header'
import Nav from './nav'

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
})

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP - 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

// ----------------------------------------------------------------------

export default function DashboardLayout(props) {
  // 國際化
  const { t } = useTranslation()
  const { type } = props
  const [open, setOpen] = useState(false)
  const [user, setUser] = useState({})
  const [deviceId, setDeviceId] = useState(true)

  const [vendorAuthorOpen, setVendorAuthorOpen] = useState(false)

  useEffect(() => {
    const getData = async () => {
      const getUser = await api.getUser()
      if (getUser) {
        setUser(getUser)
        if (type === 'index' && getUser.vendorAuthorFlag) {
          setVendorAuthorOpen(true)
        }
      }
    }

    getData()
  }, [])

  const handelDevice = () => {
    setDeviceId(!deviceId)
  }

  return (
    <CDelay>
      <StyledRoot>
        <RefreshToken />

        <CDialog fullWidth disableBackdropClick maxWidth={'md'} title={t('icon2_vendor_author_setup')} open={vendorAuthorOpen} onClose={() => setVendorAuthorOpen(false)}>
          <VendorAuthorForm onClose={() => setVendorAuthorOpen(false)} username={user.loginId} dialogTitle={t('Add')} />
        </CDialog>

        <Header user={user} handelDevice={handelDevice} />

        {deviceId === true && <Nav openNav={open} onCloseNav={() => setOpen(false)} type={type} />}

        <Main>
          <Outlet />
        </Main>
      </StyledRoot>
    </CDelay>
  )
}
