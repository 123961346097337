import { instance } from "../../instance";


// get report data 
export const getReportData = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/RptWorkersAutoPayStatuStradeList`,
        data: data
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

  
export const getDetailSQLlistWS = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/getDetailSQLlistWS`,
        data: data
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

 
export const getRPTworkersautopayMPFpfsdtl = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/getRPTworkersautopayMPFpfsdtl`,
        data: data
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

export const getSummarySQLlistWS = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/getSummarySQLlistWS`,
        data: data
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

export const getFillSite = async () => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/vps/common/getFillSite`,
        headers: {
            'Content-Type': 'text/plain'
        }
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                alert("請求出錯")
                return [];
            }
        );
};