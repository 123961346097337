import React, { useState, useRef, useEffect } from 'react'
import { Button, Grid, Typography, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, FormControlLabel, Checkbox } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { confirm } from 'src/components/common'
import { partialBatchSelectThreeSand, partialBatchSelectThreeSandAction } from '../../../../../utils/api/workerAttendanceRecord/DistributePartialBatch'

export default function AddPartialGrainPeriod(props) {
  const { snackbar, dialog, setInProgress, setSnackbar, setDialog, handleBackToMainReport } = props
  const [detail, setDetail] = useState({
    headerSet: [],
    workerSet: [],
  })
  const [checkSubContractor, setCheckSubContractor] = useState({})
  const [checkWorker, setCheckWorker] = useState({})

  const { t } = useTranslation()

  let lastSubContractorId = useRef(0)

  useEffect(() => {
    lastSubContractorId.current = 0
  })

  useEffect(() => {
    // 執行邏輯
    const getPartialBatchSelectThreeSand = async () => {
      setInProgress(true)
      let res = await partialBatchSelectThreeSand()
      if (res && res.code && res.code === 200) {
        setDetail({
          ...detail,
          headerSet: res.data.headerSet,
          workerSet: res.data.workerSet,
        })
        if (res.data && res.data.workerSet) {
          let newCheckSubContractor = { ...checkSubContractor }
          let newCheckWorker = { ...checkWorker }
          res.data.workerSet.forEach((row) => {
            if (newCheckSubContractor['sub-contractor-' + row.subContractorId] === undefined) {
              newCheckSubContractor['sub-contractor-' + row.subContractorId] = false
            }
          })
          setCheckSubContractor(newCheckSubContractor)

          res.data.workerSet.forEach((row) => {
            if (newCheckWorker['sub-contractor-' + row.subContractorId + '-' + row.cardHolderId] === undefined) {
              newCheckWorker['sub-contractor-' + row.subContractorId + '-' + row.cardHolderId] = false
            }
          })
          setCheckWorker(newCheckWorker)
        }
      } else {
        setDialog({
          ...dialog,
          content: '請求錯誤',
          open: true,
        })
      }
      setInProgress(false)
    }
    getPartialBatchSelectThreeSand()
  }, [])

  const getSubContractorDesc = (row) => {
    if (lastSubContractorId.current === 0 || lastSubContractorId.current !== row.subContractorId) {
      lastSubContractorId.current = row.subContractorId
      return (
        <FormControlLabel
          label={row.subContractorDesc}
          control={
            <Checkbox
              size="small"
              checked={checkSubContractor['sub-contractor-' + row.subContractorId] === undefined ? false : checkSubContractor['sub-contractor-' + row.subContractorId]}
              onChange={(event) => {
                let newCheckSubContractor = { ...checkSubContractor }
                newCheckSubContractor['sub-contractor-' + row.subContractorId] = event.target.checked
                setCheckSubContractor(newCheckSubContractor)

                let newCheckWorker = { ...checkWorker }
                Object.keys(newCheckWorker).forEach((key) => {
                  if (key.substring(0, key.lastIndexOf('-')) === 'sub-contractor-' + row.subContractorId) {
                    newCheckWorker[key] = event.target.checked
                  }
                })
                setCheckWorker(newCheckWorker)
              }}
            />
          }
        />
      )
    } else {
      return ''
    }
  }

  const getChineseNameDesc = (row) => (
    <FormControlLabel
      label={row.chineseName}
      size="small"
      control={
        <Checkbox
          size="small"
          checked={checkWorker['sub-contractor-' + row.subContractorId + '-' + row.cardHolderId] === undefined ? false : checkWorker['sub-contractor-' + row.subContractorId + '-' + row.cardHolderId]}
          onChange={(event) => {
            let newCheckWorker = { ...checkWorker }
            newCheckWorker['sub-contractor-' + row.subContractorId + '-' + row.cardHolderId] = event.target.checked
            setCheckWorker(newCheckWorker)
          }}
        />
      }
    />
  )

  const handleSubmit = () => {
    const submitPartialBatchSelectThreeSand = async () => {
      setInProgress(true)
      let cardHolderIdBox = []

      if (checkWorker) {
        Object.keys(checkWorker).forEach((val) => {
          if (checkWorker[val]) {
            // val: sub-contractor-100000-100000500, get card holder id 100000500
            cardHolderIdBox.push(val.substring(val.lastIndexOf('-') + 1))
          }
        })
      }
      if (cardHolderIdBox.length === 0) {
        setDialog({
          ...dialog,
          content: '請選擇工人',
          open: true,
        })
        setInProgress(false)
        return
      }

      if (cardHolderIdBox.length === detail.workerSet.length) {
        setDialog({
          ...dialog,
          content: '請勿選擇全部員工',
          open: true,
        })
        setInProgress(false)
        return
      }
      let data = {}
      data.cardHolderIdBox = cardHolderIdBox
      data.selectedWorker = cardHolderIdBox.length
      let res = await partialBatchSelectThreeSandAction(data)
      setInProgress(false)
      if (res && res.code && res.code === 200) {
        confirm({
          title: t('tip'),
          content: '保存成功',
          onOk: () => {
            handleBackToMainReport()
          },
        })
        // setSnackbar({
        //   ...snackbar,
        //   open: true,
        //   message: "保存成功",
        //   action: (
        //     <Button
        //       variant="contained"
        //       onClick={() => {
        //         setSnackbar({ ...snackbar, open: false });
        //         handleBackToMainReport()
        //       }}
        //     >
        //       確認
        //     </Button>
        //   )
        // });
      } else {
        setDialog({
          ...dialog,
          content: '提交失敗',
          open: true,
        })
      }
      // setInProgress(false);
    }
    submitPartialBatchSelectThreeSand()
  }

  return (
    <div>
      <Typography
        variant="h4"
        sx={{
          display: 'block',
          //  fontSize: "35px",
          textAlign: 'center',
        }}
      >
        <b>{t('Please_select_worker')}</b>
      </Typography>
      <div style={{ display: 'block', textAlign: 'left' }}>
        <Grid container columnSpacing={1}>
          <Grid item xs={12}>
            <Typography sx={{ display: 'block', fontSize: '18px' }}>
              {t('icon1_autopay_distributePartialBatch_print_label1') + (detail.headerSet.length > 0 && detail.headerSet[0].dateFrom ? detail.headerSet[0].dateFrom : '')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: 'block', fontSize: '18px' }}>
              {t('icon1_autopay_distributePartialBatch_print_label2') + (detail.headerSet.length > 0 && detail.headerSet[0].dateTo ? detail.headerSet[0].dateTo : '')}
            </Typography>
          </Grid>
        </Grid>
        {/* table */}
        <TableContainer sx={{ display: 'block' }}>
          <Table
            // padding="none"
            sx={{
              pageBreakInside: 'inherit',
              minWidth: 650,
              // "& .MuiTableCell-root": { border: 1 },
              pr: '5px',
            }}
            size="small"
          >
            <TableHead>
              <TableRow hover sx={{ border: '1px' }}>
                <TableCell sx={{ fontSize: '15px' }}>{t('No.')}</TableCell>
                <TableCell align="left" sx={{ fontSize: '15px' }}>
                  {t('Subcontractors')}
                </TableCell>
                <TableCell align="left" sx={{ fontSize: '15px' }}>
                  {t('icon1_autopay_distributePartialBatch_print_table_label3')}
                </TableCell>
                <TableCell align="left" sx={{ fontSize: '15px' }}>
                  {t('icon1_autopay_distributePartialBatch_print_table_label4')}
                </TableCell>
                <TableCell align="left" sx={{ fontSize: '15px' }}>
                  {t('icon1_autopay_distributePartialBatch_print_table_label5')}
                </TableCell>
                <TableCell align="left" sx={{ fontSize: '15px' }}>
                  {t('icon1_autopay_distributePartialBatch_print_table_label6')}
                </TableCell>
                <TableCell align="left" sx={{ fontSize: '15px' }}>
                  {t('icon1_autopay_distributePartialBatch_print_table_label7')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detail.workerSet && detail.workerSet.length > 0 ? (
                detail.workerSet.map((row, index) => (
                  <TableRow hover key={row.subContractorId + '-' + row.cardHolderId}>
                    <TableCell sx={{ fontSize: '15px', px: '5px' }} component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: '15px', px: '5px' }}>
                      {getSubContractorDesc(row)}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: '15px', px: '5px' }}>
                      {getChineseNameDesc(row)}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: '15px', px: '5px' }}>
                      {row.englishName}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: '15px', px: '5px' }}>
                      {row.hkid}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: '15px', px: '5px' }}>
                      {row.bankAccount ? row.bankAccount : ''}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: '15px', px: '5px' }}>
                      {row.mpfScheme ? row.mpfScheme : ''}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow hover>
                  <TableCell colSpan={7}>No Records Found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ textAlign: 'center' }}>
          {detail.workerSet && detail.workerSet.length > 0 ? (
            <Button onClick={handleSubmit} sx={{ mt: '20px', ml: '10px', width: '100px' }} variant="contained">
              {t('button_save')}
            </Button>
          ) : (
            <></>
          )}

          <Button onClick={handleBackToMainReport} sx={{ mt: '20px', ml: '10px', width: '100px' }} variant="contained">
            {t('button_cancel')}
          </Button>
        </div>
      </div>
    </div>
  )
}
