import React, { useState, useRef, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography, MenuItem,
  TextField, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Backdrop, CircularProgress

} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useNavigate, createSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; 
import { useForm } from "react-hook-form";
import CustomDialog from "src/components/common/CustomDialog";
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'

export default function CattpwspReport() {

  const navigate = useNavigate();
  const componentRef = useRef()
  const { t } = useTranslation();

  const [tradeId, setTradeId] = useState("");
  const [dataYear, setDataYear] = useState("");
  const [reportType, setReportType] = useState('1')

  const [loading, setLoading] = React.useState(false);
  // 
  const [fillJobNatureList, setFillJobNatureList] = useState([])
  const [fillYearList, setFillYearList] = useState([])


  useEffect(() => {

  }, [])

  const goDetail = (event) => {
    console.log(1)
  };

  const handleBack = () => {
    navigate(-1)
  }

  const { control, handleSubmit, setValue, getValues, reset } = useForm({
    defaultValues: { 
      subContract: "",
      dateFrom: "",
      dateTo: "",
      outputType: "",
    },
  });

  const submitForm = (data) => {
  };

  const resetForm = (data) => { 
    setDataYear("") 
    setTradeId("") 
    setReportType("1")
  };
  
  const [dialog, setDialog] = useState({
    title: "",
    content: "",
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false });
        }}
      >
        {t("Confirm")}
      </Button>
    ),
    open: false,
  });

  // 修改報表類型
  const handleChangeReportType = (event) => {
    setReportType(event.target.value)
  };

  const showDetailResult = (data) => {
    if (!dataYear) {
      setDialog({
        ...dialog,
        content: t("search_chooseDatetips"),
        open: true,
      });
      return;
    }

    if (reportType === '1') {
      // 圖標展示
      navigate({
        pathname: '/vendorAutopaySystem/CattpwspReportChartDetail',
        search: `?${createSearchParams({
          'tradeId': tradeId,
          'dataYear': dataYear,
        }, { state: { fromSpecificPage: true } })}`
      })
    } else if (reportType === '2') {
      navigate({
        pathname: '/vendorAutopaySystem/cattpwspReportDetail',
        search: `?${createSearchParams({
          'tradeId': tradeId,
          'dataYear': dataYear,
        }, { state: { fromSpecificPage: true } })}`
      })
    }
  
  }

  const handleTradeId = (data) => {
    setTradeId(data.id)
  }

  const handeDataYearId = (data) => {
    setDataYear(data.id)
    console.log('data.text==', data.text)
  }

  useEffect(() => {

    // 獲取行頭
    const getfilljobNatureId = async () => {
      let getfilljobNatureIdSelect = await apiAtt.getfilljobNatureId()
      if (getfilljobNatureIdSelect) {
        const result = getfilljobNatureIdSelect
        setFillJobNatureList(result)
      }
    }

    // 獲取年份
    const getfillYear = async () => {
      let getfillYearSelect = await apiAtt.getfillYearMonth()
      if (getfillYearSelect) {
        const result = getfillYearSelect
        setFillYearList(result)
      }
    }

    getfilljobNatureId()
    getfillYear()

  }, [])

  return (

    < >
      {(
        <>
          <div style={{ display: 'block', textAlign: 'left' }}>
            <Button sx={{ float: 'left' }} onClick={handleBack}><ArrowBackIcon />{t('button_back')}</Button>
          </div>
          <div id='div' ref={componentRef}   >
            <Container  >
              <div>
                <div style={{ display: 'block' }}  >
                  <Typography variant='h4' style={{ color: 'black', textAlign: 'center',  }}
                  >
                    <b>{t("search_vendorMonth_title")}</b>
                  </Typography>

                  <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>

                  <Container sx={{ width: '500px', borderColor: '#d3d3d3', borderRadius: 4 }}>

                    <Grid item xs={6} sx={{ margin: 'auto', width: '400px',   marginTop: '10px' }}>
                      <TextField id="subContractId" value={tradeId || ""} fullWidth size="small" label={t("search_trade_title")} select >
                        {fillJobNatureList.map((item, index) => (
                          <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleTradeId(item)}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={6} sx={{ margin: 'auto', width: '400px',   marginTop: '10px' }}>
                      <TextField error={!dataYear} id="subContractId" value={dataYear || ""} fullWidth size="small" label={t("search_date_title")} select >
                        {fillYearList.map((item, index) => (
                          <MenuItem key={index} value={item.id} name={item.text} onClick={() => handeDataYearId(item)}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Content */}
                    <Grid item xs={12} sx={{ margin: 'auto', width: '400px', marginBottom: '20px', marginTop: '10px' }}   >
                      <FormLabel id="contentTitle">{t("search_chat_title")}</FormLabel>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <FormControl >
                        <RadioGroup
                          // row表示横向排列
                          row
                          aria-labelledby="contentRadio"
                          name="row-radio-buttons-group"
                          value={reportType}
                          onChange={handleChangeReportType}
                        >
                          {/* 加入参数disabled 可以禁止选择 */}
                          <FormControlLabel value="1" control={<Radio />} label="Yes" />
                          <FormControlLabel value="2" control={<Radio />} label="No" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                  </Container>
                </div>
              </div>

              <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '18px'
              }} >
                {/* 提交按钮 */}
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <FormControl size="small">
                    <Button
                      sx={{
                        backgroundColor: "primaryBlue",
                        color: "commom.white",
                      }}
                      variant="contained"
                      onClick={handleSubmit(showDetailResult)}
                    >
                      {t("button_submit")}
                    </Button>
                  </FormControl>

                  {/* 重置按钮 */}
                  <FormControl size="small">
                    <Button
                      variant="contained"
                      onClick={resetForm}
                      label="Reset"
                      sx={{
                        backgroundColor: "primaryGreen",
                        color: "commom.white",
                        marginLeft: "20px",
                      }}
                    >
                      {t("button_reset")}
                    </Button>
                  </FormControl>
                </Grid>
              </div>
            </Container>
          </div>
        </>
      )}

      <CustomDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        action={dialog.action}
      />
    </>
  )
}

