import { instance } from "../../instance";

export const distributePartialBatch = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/distributePartialBatch`,
    // data: "kfwgvzpo",
    // headers: {
    //   "Content-Type": "text/plain",
    // },
  }).then(
    (result) => result.data,
    (error) => error
  );
}
export const partialBatchSelectThreeSand = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/partialBatchSelectThreeSand`,
    // data: "kfwgvzpo",
    // headers: {
    //   "Content-Type": "text/plain",
    // },
  }).then(
    (result) => result.data,
    (error) => error
  );
}
export const partialBatchSelectThreeSandAction = async (data) => {
  // data.userId = "kfwgvzpo";
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/partialBatchSelectThreeSandAction`,
    data: data,
  }).then(
    (result) => result.data,
    (error) => error
  );
};

export const partialBatchFinishBatch = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/partialBatchFinishBatch`,
    // data: "kfwgvzpo",
    // headers: {
    //   "Content-Type": "text/plain",
    // },
  }).then(
    (result) => result.data,
    (error) => error
  );
}
export const changePayTpye = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/selectType1`,
    data: "P",
    headers: {
      "Content-Type": "text/plain",
    },
  }).then(
    (result) => result.data,
    (error) => error
  );
} 