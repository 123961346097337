import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import { Box, Link, List, ListItemText, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CommerceModule } from '@faker-js/faker'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import { StyledNavItem, StyledNavItemIcon } from './styles'

NavSection.propTypes = {
  data: PropTypes.array,
}

export default function NavSection({ data = [], type, ...other }) {
  const { t } = useTranslation()

  useEffect(() => {}, [])

  return (
    <>
      {/* {type !== "index" ? (
        <Link
          sx={{
            pt: "10px",
            backgroundColor: "common.white",
            textAlign: "center",
            fontSize: "15px",
          }}
          href="/newsmps/homePage"
        >
          {t("backToHome")}
        </Link>
      ) : null} */}
      <Box sx={{ backgroundColor: 'common.white' }} {...other}>
        <List sx={{ p: 1 }}>
          {data.map((item, index) => (
            <NavItem type={type} key={index} item={item} />
          ))}
        </List>
      </Box>
    </>
  )
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
}

function NavItem({ item, type }) {
  const { t, i18n } = useTranslation()

  if (item.parentMenuId) {
    item.icon = ArrowRightIcon
  }
  const { title, icon, info } = item

  const handleRedirect = () => {
    // if (item.redirectUrl) {
    //   window.location.href = item.redirectUrl;
    // }
    if (item.target) {
      window.open(item.target, '_blank')
    } else {
      if (item.path?.includes('http://') || item.path?.includes('https://')) {
        window.location.href = item.path
      } else {
        // window.location.href = "/newsmps" + item.path;
      }
    }
  }

  return (
    <StyledNavItem
      component={RouterLink}
      onClick={handleRedirect}
      to={item.target || item.path?.includes('http://') || item.path?.includes('https://') ? null : item.path}
      sx={{
        color: 'common.black',
        '&.active': {
          // color: 'common.white',
          // bgcolor: 'action.selected',
          // fontWeight: 'fontWeightBold',
        },
      }}
    >
      {type !== 'index' ? (
        <Box
          component={item.icon}
          sx={{
            mx: '10px',
            width: '1.25rem!important',
            height: '1.25rem!important',
          }}
        />
      ) : (
        <Box
          component={icon}
          sx={{
            mx: '10px',
            width: '1.25rem!important',
            height: '1.25rem!important',
          }}
        />
      )}
      {/* <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon> */}

      <ListItemText sx={{ fontSize: '17px' }} disableTypography primary={i18n.language === 'zh-CN' ? item.menuDescCn : item.menuDescEn} />

      {info && info}
    </StyledNavItem>
  )
}
