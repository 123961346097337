/* eslint-disable react/style-prop-object */
import { React, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Container,
  Grid,
  FormControl,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  MenuItem,
  TableFooter,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import html2canvas from "html2canvas";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import $http, { $download } from "src/utils/api/services";

import * as api from "../../../utils/api/vendorAutopaySystem/RptPFSASVendorBonusListApi";

const ReportLeftTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    wordBreak: "keep-all",
    whiteSpace: "nowrap",
    textAlign: "center",
    backgroundColor: "#c6c6c6",
    padding: "3px",
    fontSize: "14px",
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: "Left",
    padding: "3px",
    fontSize: "13px",
  },
}));

export default function RptPFSASVendorBonusList() {
  // 國際化
  const { t } = useTranslation();

  const [backOpen, setBackOpen] = useState(false);

  const [sendData, setSendData] = useState({});
  const [tableData, setTableData] = useState({});
  const elementRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    console.log("12321321");
    const getData = async () => {
      setBackOpen(true);
      let send = await api.getPFSASVendorBonusList();
      if (send) {
        setTableData(send);
      }
      setBackOpen(false);
    };
    getData();
  }, []);

  const handleBack = () => {
    setIsSearchPage(true);
  };

  const exportExcel = () => {
    console.log("132132123");
  };

  const captureScreenshot = () => {
    console.log("132132123212132");
    const options = {
      width: elementRef.current.width, // 设置Canvas宽度
      height: elementRef.current.height, // 设置Canvas高度
      backgroundColor: "white", // 设置背景颜色
    };
    html2canvas(elementRef.current, options).then((canvas) => {
      // 在这里可以处理Canvas对象，例如保存为图片或者显示在界面上
      const dataURL = canvas.toDataURL();
      //   const screenshotImage = new Image();
      //   screenshotImage.src = dataURL;
      //   document.body.appendChild(screenshotImage);
      // 或者保存为图片文件
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = "screenshot.png";
      link.click();
    });
  };
  const handlePrint = async () => {
    try {
      const content = contentRef.current;

      if (content) {
        const canvas = await html2canvas(content);
        const imgData = canvas.toDataURL("image/png");

        // 创建一个新窗口用于打印
        const printWindow = window.open("", "", "width=600,height=600");
        printWindow.document.open();
        printWindow.document.write('<img src="' + imgData + '" />');
        printWindow.document.close();

        // 等待图像加载完成后再执行打印
        printWindow.onload = function () {
          printWindow.print();
          printWindow.close();
        };
      }
    } catch (error) {
      console.error("打印出错：", error);
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Button
        id="capture"
        sx={{ float: "right", mr: "50px", mx: 100 }}
        onClick={() => handlePrint()}
      >
        {t("列印")}
      </Button>
      <Container
        maxWidth="md"
        sx={{ border: "3px solid" }}
        ref={contentRef}
        id="contentRef"
      >
        <div style={{ margin: "10px" }}>
          <Typography variant="h6" style={{ textAlign: "left" }}>
            <b>{t("Name_of_the_contractor")}</b> :{" "}
            {tableData.vendorData?.vendorDesc}
          </Typography>
          <Typography variant="h4" sx={{ textAlign: "center", my: "10px" }}>
            <b>{t("Zero_injury_incentive_program")}</b>
          </Typography>
          <div>
            <Typography variant="subtitle1" gutterBottom>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {t("paragraph1")}
              <b>{t("paragraph2")}</b>
              {t("paragraph3")}
              <b>{t("paragraph4")}</b>
              {t("paragraph5")}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("paragraph6")}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("paragraph7")}
            </Typography>
          </div>

          <TableContainer>
            <Table size="small" sx={{ mt: "20px" }}>
              <TableHead sx={{ backgroundColor: "#87cefa" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t("Site_Name")}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "right",
                      fontWeight: "bold",
                      fontSize: "15px",
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t("Workers_salaries")}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                      textAlign: "right",
                    }}
                  >
                    {t("Hours_of_work_injury_rate")}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                      textAlign: "right",
                    }}
                  >
                    {t("Serious_industrial_and_commercial_accidents")}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                      textAlign: "right",
                    }}
                  >
                    {t("Zero_injury_bonus")}
                  </TableCell>
                </TableRow>
              </TableHead>
              {!!tableData && tableData.formData?.length > 0 && (
                <TableBody>
                  {tableData.formData.map((row, ind) => (
                    <TableRow hover key={ind}>
                      <TableCell>{row.siteDesc}</TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        $
                        {row.payroll
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        {row.irRate
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        {row.accident}
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        $
                        {row.bonus
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
              {(!tableData || tableData.formData?.length === 0) && (
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ textAlign: "center" }} colSpan={10}>
                      {"No data found !"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              <TableBody sx={{ mt: "20px" }}>
                <TableRow>
                  <TableCell colSpan={5} sx={{ borderBottom: "none" }}>
                    {" "}
                    {t("PFSAS_ACCOUNTING_DEPARTMENT_CONTACT")}
                    {tableData.PFSAS_ACCOUNTING_DEPARTMENT_CONTACT}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={5} sx={{ borderBottom: "none" }}>
                    {" "}
                    {t("PFSAS_ADMINISTRATION_DEPARTMENT_CONTACT")}
                    {tableData.PFSAS_ADMINISTRATION_DEPARTMENT_CONTACT}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={5} sx={{ borderBottom: "none" }}>
                    {" "}
                    {t("PFSAS_SECURITY_DEPARTMENT_CONTACT")}
                    {tableData.PFSAS_SECURITY_DEPARTMENT_CONTACT}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="subtitle1">{t("Notes")}</Typography>
        </div>
      </Container>
    </>
  );
}
