
import { instance } from "../../instance";


// 获取行头
export const getfilljobNatureId = async () => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/vps/common/getfilljobNatureId`,
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};
// 年份
export const getPeriodYear = async () => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/vps/common/getPeriodYear`,
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};





// get Report 每月
export const getRPTvendorautopaystatuslist2 = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/getRPTvendorautopaystatuslist2`,
        data: data
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

// get Report 每半年
export const getRPTvendorautopaystatuslist = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/getAvgPayReportList`,
        data: data
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};












