import React, { useRef, useEffect } from "react";
import {
  Button,
  // Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PrintIcon from "@mui/icons-material/Print";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactToPrint from "react-to-print";
import { confirm } from "src/components/common"
import { finishBatch } from "../../../../../utils/api/workerAttendanceRecord/DistributeSubBatchApi";

export default function GetRecord(props) {
  const {
    workerSet,
    headerSet,
    batchSet,
    rsbatch,
    setInProgress,
    disabled,
    fullDisabled,
    dialog,
    setDialog,
    snackbar,
    setSnackbar,
    handleBackToFlowMenu,
    handleAddShortGrainPeriod,
    reload,
    setReload,
  } = props;

  const componentRef = useRef();
  const { t } = useTranslation();

  let lastSubContractorId = useRef(0);
  useEffect(() => {
    lastSubContractorId.current = 0;
  }, []);

  const handleFinishBatch = () => {
    const finishSubBatch = async () => {
      setInProgress(true);
      let res = await finishBatch();
      setInProgress(false);
      if (res && res.code && res.code === 200) {
        setSnackbar({
          ...snackbar,
          message: "已經完成整個糧期",
          action: (
            <Button
              variant="contained"
              onClick={() => {
                setSnackbar({ ...snackbar, open: false });
                setReload(!reload);
              }}
            >
              確認
            </Button>
          ),
          open: true,
        });
      } else {
        setDialog({
          ...dialog,
          content: "請求錯誤",
          open: true,
        });
      }
      // setInProgress(false);
    };
    finishSubBatch();
  };

  const getSubContractorDesc = (row) => {
    if (
      lastSubContractorId.current === 0 ||
      lastSubContractorId.current !== row.subContractorId
    ) {
      lastSubContractorId.current = row.subContractorId;
      return row.subContractorDesc;
    } else {
      return "";
    }
  };

  const getNewline = (val) => {
    let str = val;
    let bytesCount = 0;
    let s = "";
    for (let i = 0, n = str.length; i < n; i++) {
      let c = str.charCodeAt(i);
      // 统计字符串的字符长度
      if ((c >= 0x0001 && c <= 0x007e) || (c >= 0xff60 && c <= 0xff9f)) {
        bytesCount += 1;
      } else {
        bytesCount += 2;
      }
      // 换行
      s += str.charAt(i);
      if (bytesCount >= 11) {
        s += "\n";
        // 重置
        bytesCount = 0;
      }
    }
    return s;
  };

  const getSubBatchDesc = (row) => {
    let desc = row.datePeriod;
    let period = "";
    if (desc) {
      let list = desc.split("~");

      return list.map((item, index) => (
        <Typography
          key={index}
          sx={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}
        >
          {item}
          <br />
        </Typography>
      ));
    }
    return "";
  };

  return (
    <div>
      <div style={{ display: "block", textAlign: "left", marginTop: "0px" }}>
        <Button
          sx={{ float: "left", mr: "20px" }}
          onClick={handleBackToFlowMenu}
        >
          <ArrowBackIcon />
          {t("button_back")}
        </Button>
      </div>
      <div style={{ display: "block", textAlign: "right", marginTop: "0px" }}>
        {/* <ReactToPrint
          trigger={() => (
            <Button>
              <PrintIcon />
              {t("button_print")}
            </Button>
          )}
          content={() => componentRef.current}
          copyStyles
          pageStyle={`
                      @page { margin: 20px 20px !important; }
                      @media print {
                          @page {  }
                      }`}
        /> */}
      </div>
      <div ref={componentRef}>
        <Typography variant="h4" sx={{ display: "block" }}>
          <b>{t("Assigning_workers_short_pay_periods")}</b>
        </Typography>
        <Grid container columnSpacing={1}>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "70%" }}>
                    <Typography sx={{ display: "block", fontSize: "16px" }}>
                      {t("icon1_autopay_distributeSubBatch_print_label1") +
                        (headerSet.length > 0 && headerSet[0].dateFrom
                          ? headerSet[0].dateFrom
                          : "")}
                    </Typography>
                    <Typography sx={{ display: "block", fontSize: "16px" }}>
                      {t("icon1_autopay_distributeSubBatch_print_label2") +
                        (headerSet.length > 0 && headerSet[0].dateTo
                          ? headerSet[0].dateTo
                          : "")}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ width: "30%" }}>
                    {batchSet && batchSet.length > 0 && (
                      <table>
                        <thead>
                          <tr>
                            <td style={{ fontSize: "16px" }}>
                              {t(
                                "icon1_autopay_distributeSubBatch_print_label3"
                              )}
                            </td>
                            <td
                              style={{ fontSize: "16px", paddingLeft: "15px" }}
                            >
                              {t(
                                "icon1_autopay_distributeSubBatch_print_label4"
                              )}
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {batchSet.map((item, index) => (
                            <tr key={index}>
                              <td style={{ fontSize: "15px" }}>
                                {item.dateFrom +
                                  (item.dateTo ? " - " + item.dateTo : "")}
                              </td>
                              <td
                                style={{
                                  fontSize: "15px",
                                  paddingLeft: "15px",
                                }}
                              >
                                {item.people}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Grid>

          <Grid item xs={12} sx={{ marginBottom: "10px", textAlign: "left" }}>
            <Button
              variant="contained"
              onClick={handleAddShortGrainPeriod}
              disabled={disabled === "Disabled"}
            >
              {t("Join_Short_Payroll")}
            </Button>

            {rsbatch && rsbatch.counter >= 1 ? (
              <Button
                variant="contained"
                sx={{ marginLeft: "10px" }}
                onClick={handleFinishBatch}
                disabled={fullDisabled === "Disabled"}
              >
                {t("Complete_the_entire_payroll_period")}
              </Button>
            ) : (
              <Button variant="contained" sx={{ marginLeft: "10px" }} disabled>
                {t("Complete_the_entire_payroll_period")}
              </Button>
            )}
          </Grid>
        </Grid>
        {/* table */}
        <TableContainer sx={{ display: "block" }}>
          <Table
            sx={{
              minWidth: 650,
              // "& .MuiTableCell-root": { border: 1 },
              pr: "5px",
            }}
            size="small"
          >
            <TableHead>
              <TableRow hover>
                <TableCell
                  align="left"
                  sx={{
                    fontSize: "15px",
                    wordBreak: "keep-all",
                    whiteSpace: "nowrap",
                  }}
                >
                  {t("No.")}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontSize: "15px",
                    wordBreak: "keep-all",
                    whiteSpace: "nowrap",
                  }}
                >
                  {t("Subcontractors")}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontSize: "15px",
                    wordBreak: "keep-all",
                    whiteSpace: "nowrap",
                  }}
                >
                  {t("icon1_autopay_distributeSubBatch_print_table_label3")}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontSize: "15px",
                    wordBreak: "keep-all",
                    whiteSpace: "nowrap",
                  }}
                >
                  {t("icon1_autopay_distributeSubBatch_print_table_label4")}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontSize: "15px",
                    wordBreak: "keep-all",
                    whiteSpace: "nowrap",
                  }}
                >
                  {t("icon1_autopay_distributeSubBatch_print_table_label5")}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontSize: "15px",
                    wordBreak: "keep-all",
                    whiteSpace: "nowrap",
                  }}
                >
                  {t("icon1_autopay_distributeSubBatch_print_table_label6")}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontSize: "15px",
                    wordBreak: "keep-all",
                    whiteSpace: "nowrap",
                  }}
                >
                  {t("icon1_autopay_distributeSubBatch_print_table_label7")}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontSize: "15px",
                    wordBreak: "keep-all",
                    whiteSpace: "nowrap",
                  }}
                >
                  {t("icon1_autopay_distributeSubBatch_print_table_label8")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workerSet && workerSet.length > 0 ? (
                workerSet.map((row, index) => (
                  <TableRow hover key={index}>
                    <TableCell
                      sx={{ fontSize: "15px" }}
                      component="th"
                      scope="row"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "15px" }}>
                      {getSubContractorDesc(row)}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "15px" }}>
                      {row.chineseName}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "15px" }}>
                      {row.englishName}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "15px" }}>
                      {row.hkid}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "15px" }}>
                      {row.bankAccount ? row.bankAccount : ""}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "15px" }}>
                      {row.mpfScheme ? row.mpfScheme : ""}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "15px" }}>
                      {/* {row.datePeriod} */}
                      {getSubBatchDesc(row)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow hover>
                  <TableCell colSpan={8}>No Records Found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
