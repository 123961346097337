// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontColor: theme.palette.common.black,
          fontWeight:"bold",
          textAlign: "left",
          fontSize: "18px"
        },
        body: {
          textAlign: "left",
          fontSize: "15px",
          fontColor: theme.palette.common.black
        }
      },
    },
  };
}
