import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Autocomplete,
  Container,
  Grid,
  FormControl,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  MenuItem,
  TableFooter,
  FormLabel,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import moment from 'moment'
import $http, { $download } from 'src/utils/api/services'
import { set } from 'lodash'
import * as api from '../../../utils/api/vendorAutopaySystem/RPTworkersautopaystatusvendorlistwholemonthApi'

export default function RPTworkersautopaystatusvendorlistwholemonth() {
  const [isSearchPage, setIsSearchPage] = useState(true)
  // 國際化
  const { t } = useTranslation()
  const [backOpen, setBackOpen] = useState(false)
  const [periodYearList, setPeriodYearList] = useState([])
  const [sendData, setSendData] = useState({
    reportForm: '糧期',
    recordscriteria: '0',
  })
  const [tableData, setTableData] = useState({})
  const [vendorIdList, setVendorIdList] = useState([])

  useEffect(() => {
    // setBackOpen(true)

    const getData = async () => {
      setBackOpen(true)

      let getVendorIdList = await api.getVendorIdList()

      let getPeriodYearList = await api.getPeriodYear()

      if (getPeriodYearList) {
        setPeriodYearList(getPeriodYearList)
      }

      if (getVendorIdList) {
        setVendorIdList(getVendorIdList)
      }

      setBackOpen(false)
    }
    getData()
  }, [])

  const handleBack = () => {
    setSendData({
      ...sendData,
      reportForm: '糧期',
      recordscriteria: '0',
    })
    setIsSearchPage(true)
  }

  const handleVendorId = (e, data) => {
    setSendData({
      ...sendData,
      vendorId: data.id,
      vendorDesc: data.text,
      vendor: data,
    })
  }

  const handleReportForm = (e) => {
    setSendData({ ...sendData, recordscriteria: e.target.value })
  }

  const handleType = (e) => {
    setSendData({
      ...sendData,
      reportForm: e.target.value === '糧期' ? '糧期' : '承判商',
    })
  }

  const handleSubmit = (e) => {
    if (!sendData.periodYear) {
      alert('請選擇日期')
    }
    const getData = async () => {
      let send = null

      console.log('data', sendData)
      setBackOpen(true)

      if (sendData.reportForm === '糧期') {
        send = await api.getRPTworkersautopaystatusvendorlist(sendData)
      } else {
        send = await api.getRPTworkersautopaystatusvendorlistwholemonth(sendData)
      }

      if (send) {
        setIsSearchPage(false)
        setTableData(send)
      }
      setBackOpen(false)
    }
    getData()
  }

  const resetForm = (e) => {
    setSendData({})
  }

  const handlePeriodYear = (e, data) => {
    setSendData({
      ...sendData,
      dateFrom: data.id,
      periodYearDesc: data.text,
      periodYear: data,
    })
  }

  const exportExcel = (data) => {
    if (sendData.reportForm === '承判商') {
      $download(`/payrollReportController/getRPTworkersautopaystatusvendorlistwholemonthExcel`, 'application/vnd.ms-excel', data)
    } else {
      $download(`/payrollReportController/getRPTworkersautopaystatusvendorlistExcel`, 'application/vnd.ms-excel', data)
    }
  }

  const exportPDF = (data) => {
    if (sendData.reportForm === '承判商') {
      $download(`/payrollReportController/getRPTworkersautopaystatusvendorlistwholemonthPdf`, 'application/pdf', data)
    } else {
      $download(`/payrollReportController/getRPTworkersautopaystatusvendorlistPdf`, 'application/pdf', data)
    }
  }

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {isSearchPage && (
        <form>
          <Container maxWidth="md">
            <Grid container spacing={2}>
              {/* 工人薪金分析(以承判商分類) */}
              <Grid item xs={12}>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                  <b>{t('salary_Analysis_of_Workers')}</b>
                </Typography>
              </Grid>

              <Grid item xs={12} container justifyContent="center">
                <Autocomplete
                  sx={{ width: '400px' }}
                  value={sendData.vendor || null}
                  onChange={handleVendorId}
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  getOptionLabel={(option) => {
                    return option.text
                  }}
                  options={vendorIdList}
                  renderInput={(params) => <TextField fullWidth size="small" {...params} label={t('icon1_personalentersearch_sub_condition')} />}
                />
              </Grid>

              <Grid item xs={12} container justifyContent="center">
                <Autocomplete
                  sx={{ width: '400px' }}
                  value={sendData.periodYear || null}
                  onChange={handlePeriodYear}
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  getOptionLabel={(option) => {
                    return option.text
                  }}
                  options={periodYearList}
                  renderInput={(params) => <TextField error={!sendData.periodYear} fullWidth size="small" {...params} label={t('date')} />}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <TextField sx={{ textAlign: 'left', maxWidth: '400px' }} defaultValue="糧期" fullWidth size="small" label={t('icon_vas_wsat_group_type')} onChange={handleType} select>
                  <MenuItem value="糧期">{t('Payroll_period')}</MenuItem>
                  <MenuItem value="承判商">{t('Vendor')}</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <TextField sx={{ textAlign: 'left', maxWidth: '400px' }} defaultValue="0" fullWidth size="small" label={t('Report_form')} onChange={handleReportForm} select>
                  <MenuItem value="0">{t('icon_vas_wsat_report_type_detail')}</MenuItem>
                  <MenuItem value="1">{t('icon_vas_wsat_report_type_summary')}</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={6} />
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <FormControl>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                      backgroundColor: 'primaryBlue',
                      color: 'commom.white',
                    }}
                  >
                    {t('button_submit')}
                  </Button>
                </FormControl>
                <FormControl sx={{ marginLeft: '20px' }}>
                  <Button
                    variant="contained"
                    onClick={resetForm}
                    sx={{
                      backgroundColor: 'primaryGreen',
                      color: 'commom.white',
                      marginRight: '10px',
                    }}
                    label="Reset"
                  >
                    {t('button_reset')}
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Container>
        </form>
      )}

      {!isSearchPage && (
        <Container maxWidth={false}>
          <div
            style={{
              display: 'block',
              textAlign: 'left',
              marginTop: '0px',
            }}
          >
            <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
              <ArrowBackIcon />
              {t('button_back')}
            </Button>
          </div>

          <div style={{ display: 'block', textAlign: 'right' }}>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportExcel(sendData)}>
              <DownloadIcon />
              {t('button_export_excel')}
            </Button>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportPDF(sendData)}>
              <DownloadIcon />
              {t('button_export_pdf')}
            </Button>
          </div>
          <div>
            <Typography variant="h4" style={{ textAlign: 'center', mt: '-30px' }}>
              <b>{t('salary_Analysis_of_Workers')}</b>
            </Typography>
            <TableContainer>
              <Table sx={{ textAlign: 'left' }} size="small">
                <TableBody>
                  <TableRow>
                    {/* 製作日期 */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      {t('Production_Date')}：
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{moment(new Date().getTime()).format('yyyy/MM/DD')}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 承判商： */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      {t('Contractor')}：
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.vendorDesc}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 日期（由） */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      {t('icon_vas_wsat_date_from')}：
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.periodYearDesc}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 報表形式： */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        display: 'block',
                        borderBottom: 'none',
                      }}
                    >
                      {t('Report_form')}:
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>
                      <Typography sx={{ display: 'block', fontSize: '15px' }}>
                        {sendData.recordscriteria === '0' ? t('icon_vas_wsat_report_type_detail') : t('icon_vas_wsat_report_type_summary')}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    {/* 組合形式： */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        display: 'block',
                        borderBottom: 'none',
                      }}
                    >
                      {t('icon_vas_wsat_group_type')}:
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>
                      <Typography sx={{ display: 'block', fontSize: '15px' }}>{sendData.reportForm === '糧期' ? t('Payroll_period') : t('Vendor')}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer sx={{ maxHeight: '80vh', maxWidth: '100vw' }}>
              <Table size="small" sx={{ mt: '20px' }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('Contractor')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('icon_vas_wsat_period')}
                    </TableCell>
                    {sendData.recordscriteria === '0' && (
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('Outfit')}
                      </TableCell>
                    )}

                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('Number_of_participants')}*
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('icon_vas_wsat_total_percent')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('icon_vas_wsat_absence_num')}*
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('icon_vas_wsat_total_percent')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('Total_number_of_people')}*
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('icon_vas_wsat_attendance_days')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('icon_vas_wsat_total_percent')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('icon_vas_wsat_absence_days')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('icon_vas_wsat_total_percent')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('icon_vas_wsat_total_days')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('icon_vas_wsat_total_salary')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('icon_vas_wsat_average_daily_salary')}
                    </TableCell>
                  </TableRow>
                </TableHead>

                {!!tableData && tableData.mainData.length > 0 && (
                  <TableBody>
                    {tableData.mainData.map((row, ind) => (
                      <>
                        {sendData.reportForm === '承判商' && (
                          <TableRow
                            hover
                            key={ind}
                            sx={{
                              backgroundColor: ind % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                            }}
                          >
                            <TableCell>{row.withautopayvendordesc}</TableCell>
                            <TableCell>
                              {row.withoutautopayperiod}~{row.withoutautopayDateToPeriod}
                            </TableCell>
                            {sendData.recordscriteria === '0' && <TableCell>{row.withautopayjobnaturedesc}</TableCell>}
                            <TableCell>{row.workerswithautopay.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell>{row.workerswithautopaypercent.toFixed(0)}%</TableCell>
                            <TableCell>{row.workerswithoutautopay.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell>{row.workerswithoutautopaypercent.toFixed(0)}%</TableCell>
                            <TableCell>{row.totalworkers.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell>{row.wdawithautopay.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell>{row.wdawithautopaypercent.toFixed(0)}%</TableCell>
                            <TableCell>{row.wdawithoutautopay.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell>{row.wdawithoutautopaypercent}%</TableCell>
                            <TableCell>{row.totalwda.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell>{row.salarywithautopay !== null ? row.salarywithautopay.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '0.00'}</TableCell>
                            <TableCell>{row.averagesalary !== null ? row.averagesalary.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '0.00'}</TableCell>
                          </TableRow>
                        )}

                        {sendData.reportForm === '糧期' && (
                          <TableRow
                            hover
                            key={ind}
                            sx={{
                              backgroundColor: ind % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                            }}
                          >
                            <TableCell>{row.withautopayvendordesc}</TableCell>
                            <TableCell>
                              {row.withautopaydatefrom}~{row.withautopaydateto}
                            </TableCell>
                            {sendData.recordscriteria === '0' && <TableCell>{row.withautopayjobnaturedesc}</TableCell>}
                            <TableCell>{row.workerswithautopay.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell>{row.workerswithautopaypercent.toFixed(0)}%</TableCell>
                            <TableCell>{row.workerswithoutautopay.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell>{row.workerswithoutautopaypercent.toFixed(0)}%</TableCell>
                            <TableCell>{row.totalworkers.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell>{row.wdawithautopay.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell>{row.wdawithautopaypercent.toFixed(0)}%</TableCell>
                            <TableCell>{row.wdawithoutautopay.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell>{row.wdawithoutautopaypercent.toFixed(0)}%</TableCell>
                            <TableCell>{row.totalwda.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell>{row.salarywithautopay !== null ? row.salarywithautopay.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '0.00'}</TableCell>
                            <TableCell>{row.averagesalary !== null ? row.averagesalary?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '0.00'}</TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
                  </TableBody>
                )}
                {(!tableData || tableData.mainData.length === 0) && (
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ textAlign: 'center' }} colSpan={14}>
                        {'No data found !'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TableContainer>
              <Table size="small" sx={{ mt: '20px', textAlign: 'left', maxWidth: '650px' }}>
                <TableHead>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                      margin: '10px',
                    }}
                  >
                    {t(' ')}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                      margin: '10px',
                    }}
                  >
                    {t('icon1_accrecsearch_total_condition')}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      wordBreak: 'keep-all',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                      margin: '10px',
                    }}
                  >
                    {t('icon_vas_wsat_total_percent')}
                  </TableCell>
                </TableHead>

                {!!tableData && tableData.mainData.length > 0 && (
                  <TableBody>
                    {sendData.reportForm === '糧期' && (
                      <>
                        <TableRow hover>
                          <TableCell sx={{ textAlign: 'left' }}> {t('Number_of_participants')}*</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.attendantWorkers.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.attendantWorkersPercent.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}%</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ textAlign: 'left' }}> {t('icon_vas_wsat_absence_num')}*</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.notAttendantWorkers.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.notAttendantWorkersPercent.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}%</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ textAlign: 'left' }}> {t('Total_number_of_people')}*</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.totalWorkers.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ textAlign: 'left' }}>{t('icon_vas_wsat_attendance_days')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.attendantWorkerDays.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.attendantWorkerDaysPercent.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}%</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ textAlign: 'left' }}>{t('icon_vas_wsat_absence_days')} </TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.notAttendantWorkerDays.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.notAttendantWorkerDaysPercent.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}%</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ textAlign: 'left' }}> {t('icon_vas_wsat_total_days')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.totalWorkerDays.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ textAlign: 'left' }}>{t('icon_vas_wsat_total_salary')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.totalNetIncomeHKD.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ textAlign: 'left' }}> {t('icon_vas_wsat_average_daily_salary')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.averageTotalNetIncomeHKD.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{}</TableCell>
                        </TableRow>
                      </>
                    )}

                    {sendData.reportForm === '承判商' && (
                      <>
                        <TableRow hover>
                          <TableCell sx={{ textAlign: 'left' }}>{t('Number_of_participants')}*</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.attendantWorkers.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.attendantWorkersPercent.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}%</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ textAlign: 'left' }}> {t('icon_vas_wsat_absence_num')}*</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.notAttendantWorkers.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.notAttendantWorkersPercent.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}%</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ textAlign: 'left' }}> {t('Total_number_of_people')}*</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.totalWorkers.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ textAlign: 'left' }}>{t('icon_vas_wsat_attendance_days')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.attendantWorkerDays.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.attendantWorkerDaysPercent.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}%</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ textAlign: 'left' }}>{t('icon_vas_wsat_absence_days')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.notAttendantWorkerDays.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.notAttendantWorkerDaysPercent.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}%</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ textAlign: 'left' }}>{t('icon_vas_wsat_total_days')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.totalWorkerDays.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ textAlign: 'left' }}>{t('icon_vas_wsat_total_salary')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.totalNetIncomeHKD.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ textAlign: 'left' }}> {t('icon_vas_wsat_average_daily_salary')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{tableData.averageTotalNetIncomeHKD.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'left' }}>{}</TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                )}
                {(!tableData || tableData.mainData?.length === 0) && (
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ textAlign: 'center' }} colSpan={3}>
                        {'No data found !'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
          <div>
            <Typography variant="subtitle1" sx={{ textAlign: 'left', pt: '10px' }}>
              <b>{t('salary_Analysis_of_Workers_description')}</b>
            </Typography>
          </div>
        </Container>
      )}
    </>
  )
}
