import React, { useRef, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow, Backdrop, CircularProgress

} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import $http, { $download } from 'src/utils/api/services'
import DownloadIcon from "@mui/icons-material/Download";

// 承判商派駐地盤安全主任/安全督導員登記記錄---详情界面
export default function RegistrationRecordDetail() {

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {

  }, [])




  const handleBack = () => {
    navigate(-1)
  }

  const printComponentRef = useRef();
  const [detailList, setDetailList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {

    getSafetyAllowEnterList(0)

  }, [])

  const getSafetyAllowEnterList = async (outPage) => {
    let dateF = ''
    dateF = searchParams.get("dateFrom")
    while (dateF.indexOf('-') >= 0) {
      dateF = dateF.replace('-', '/');
    }
    let dateT = ''
    dateT = searchParams.get("dateTo")
    while (dateT.indexOf('-') >= 0) {
      dateT = dateT.replace('-', '/');
    }

    let list = await apiAtt.getSafetyAllowEnterList(
      dateF,
      dateT,
      searchParams.get('jobType'),
      searchParams.get('job'),
      searchParams.get('siteId'))

    if (list !== null) {
      setDetailList(list)

      console.log(list)
    }
    setLoading(false)

  }

  const exportPDF = (data) => {
    $download(`/attendance/report/getSafetyAllowEnterListPdf`, 'application/pdf', data)
 };

  const exportExcel = (data) => {
    $download(`/attendance/report/getSafetyAllowEnterListExcel`, 'application/vnd.ms-excel', data)
  };

  return (
    <Container   maxWidth="false">
      <div style={{ display: 'block', textAlign: 'left' }}>
        <Button sx={{ float: 'left' }}
          onClick={handleBack}><ArrowBackIcon />{t('button_back')}</Button>
      </div>

      <div style={{ display: 'block', textAlign: 'right' }}>
        <Button sx={{ float: "right", mr: "20px" }} onClick={() => exportExcel(
          {
            "siteId": searchParams.get("siteId"),
            "siteDesc": searchParams.get("siteDesc"),
            "dateFromFormat": searchParams.get("dateFrom"),
            "dateToFormat": searchParams.get("dateTo"),
            "jobNatureId": searchParams.get("jobNatureId"),
            "safetyPositionId": searchParams.get("safetyPositionId"),
            "pageSize": rowsPerPage,
            "pageNumber": page
          }
        )}>
          <DownloadIcon />
          {t("button_export_excel")}
        </Button> 
        <Button sx={{ float: "right", mr: "20px" }} onClick={() => exportPDF(
          {
            "siteId": searchParams.get("siteId"),
            "siteDesc": searchParams.get("siteDesc"),
            "dateFromFormat": searchParams.get("dateFrom"),
            "dateToFormat": searchParams.get("dateTo"),
            "jobNatureId": searchParams.get("jobNatureId"),
            "safetyPositionId": searchParams.get("safetyPositionId"),
            "pageSize": rowsPerPage,
            "pageNumber": page
          }
        )}>
          <DownloadIcon />
          {t("button_export_pdf")}
        </Button> 
      </div>

      {/* <div style={{ display: 'block', textAlign: 'right' }}>
        <ReactToPrint
          trigger={() => (
            <Button>
              <PrintIcon />
              {t("button_print")}
            </Button>
          )}
          content={() => printComponentRef.current}
          copyStyles
          pageStyle={`
              @page { margin: 20px 20px !important; }
              @media print {
                @page { size: landscape; }
              }`}
        />
      </div> */}

      <div id='div' style={{}} ref={printComponentRef} >
        <Container  maxWidth="false">
          <div  >
            <div style={{ display: 'block', marginBottom: '60px' }}>
              <Typography variant='h4' sx={{ textAlign: 'center' }}>
                <b>{t("search_atrrot_records")}</b></Typography>
            </div>
            {/* table */}
            <TableContainer component={Paper} sx={{ backgroundColor: 'transparent' }}>
              <Table sx={{ textAlign: 'left' }} size="small"  >
                <TableBody>
                  <TableRow    >
                    <TableCell sx={{ fontSize: '15px', px: '5px', width: 160, border: 0 }}>
                      {t("search_approveDate_from")}
                    </TableCell>
                    <TableCell sx={{ fontSize: '15px', px: '5px', border: 0 }}>
                      {searchParams.get("dateFrom").replace('-', '/').replace('-', '/')}</TableCell>
                    <TableCell sx={{ fontSize: '15px', px: '5px', width: 160, border: 0 }}>
                      {t("search_approveDate_to")}</TableCell>
                    <TableCell sx={{ fontSize: '15px', px: '5px', border: 0 }}>
                      {searchParams.get("dateTo").replace('-', '/').replace('-', '/')}</TableCell>
                  </TableRow>

                  <TableRow    >
                    <TableCell sx={{ fontSize: '15px', px: '5px', width: 160, border: 0 }}>
                      Total: </TableCell>
                    <TableCell sx={{ fontSize: '15px', px: '5px', border: 0 }}>
                      {detailList.length} Record(s)</TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>
          </div>

          {/* table */}
          <TableContainer sx={{ marginTop: '20px' }}>
            <Table sx={{ p: '2px' }} size="small" >
              <TableHead>
                <TableRow hover  >
                  <TableCell sx={{ whiteSpace: "nowrap", }} >
                    {t("search_site_title")}</TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", }}>
                    {t("search_work_type")}</TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", }}>
                    {t("search_employmentPosition")}</TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", }}>
                    {t("search_approveDate_title")}</TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", }} >
                    {t("search_effectiveDate")}</TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", }}>
                    {t("search_valiteDate_to")}</TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", }}>
                    {t("search_smartcardId")}</TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", }}>
                    {t("search_english_not_dot")}</TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", }}>
                    {t("search_chinese_not_dot")}</TableCell>
                </TableRow>
              </TableHead>
              <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <TableBody>
                {/* {排序}  */}
                {detailList.map((row, index) => (
                  <TableRow hover   >
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                      {row.siteDesc} </TableCell>
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                      {row.jobNatureDesc} </TableCell>
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                      {row.safetyPositionDesc} </TableCell>
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                      {row.spApprovalDate1}</TableCell>
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                      {row.effectiveDate1}</TableCell>
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                      {row.effectiveDateTo1}</TableCell>
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                      {row.smartCardId}</TableCell>
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                      {row.englishName}</TableCell>
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>
                      {row.chineseName}</TableCell>
                  </TableRow>
                ))}

              </TableBody>
            </Table>
          </TableContainer>
        </Container>

      </div>
    </Container>

  )
}

