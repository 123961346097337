import { instance } from "../../instance";

// 獲取數據map
export const getPFSASVendorBonusList = async () => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getPFSASVendorBonusList`,
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};












