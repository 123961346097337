import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Autocomplete,
  Container,
  Grid,
  FormControl,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  MenuItem,
  FormLabel,
  Link,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import moment from 'moment'
import $http, { $download } from 'src/utils/api/services'
import * as api from '../../../utils/api/vendorAutopaySystem/RptWorkersautopaystatusMPFlistApi'

export default function RptWorkersautopaystatusMPFlist() {
  const [isSearchPage, setIsSearchPage] = useState(true)
  const { t } = useTranslation()
  const [backOpen, setBackOpen] = useState(false)
  const [dateMonthList, setDateMonthList] = useState([])
  const [vendorList, setVendorList] = useState([])
  const [siteList, setSiteList] = useState([])
  const [dateTo, setDateTo] = useState([])
  const [dateFrom, setDateFrom] = useState([])
  const [recordsCriteria, setRecordsCriteria] = useState('0')
  const [sendData, setSendData] = useState({})
  const [reportData, setReportData] = useState({})
  const [tableData, setTableData] = useState([])
  const [isShowVendorColumn, setIsShowVendorColumn] = useState(false)

  const [dialog, setDialog] = useState({
    content: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        確認
      </Button>
    ),
    open: false,
  })

  useEffect(() => {
    setBackOpen(true)
    const getData = async () => {
      let vendorList = await api.getVendorIdList()
      let siteList = await api.getSiteList()
      let getDateFrom = await api.getDateFrom()
      let getDateTo = await api.getDateTo()

      if (getDateFrom) {
        setDateFrom(getDateFrom)
      }
      if (getDateTo) {
        setDateTo(getDateTo)
      }
      if (vendorList) {
        setVendorList(vendorList)
      }

      if (siteList) {
        setSiteList(siteList)
      }
      setSendData({ ...sendData, recordsCriteria: '0' })

      setBackOpen(false)
    }
    getData()
  }, [])

  const handleBack = () => {
    setIsSearchPage(true)
  }

  const handleVendorId = (e, data) => {
    if (data !== null) {
      setSendData({
        ...sendData,
        vendorId: data.id,
        vendorDesc: data.text,
        vendor: data,
      })
    } else {
      setSendData({
        ...sendData,
        vendorId: null,
        vendorDesc: null,
        vendor: null,
      })
    }
  }

  const handleDateFrom = (e) => {
    setSendData({ ...sendData, dateFrom: e.id, dateFromDesc: e.text })
  }

  const handleDateTo = (e) => {
    // if (!completeDate(new Date(sendData.dateFrom), new Date(e.id), 12)) {
    //   alert(t("The_difference_between_the_date_12_check"));
    //   return;
    // }

    if (isBefore(new Date(e.id), new Date(sendData.dateFrom))) {
      alert(t('Date_to_cannot_be_less_than_date_by'))
      return
    }
    setSendData({ ...sendData, dateTo: e.id, dateToDesc: e.text })
  }

  const isBefore = (date1, date2) => {
    return date1 < date2
  }

  const handleSite = (e, data) => {
    if (data !== null) {
      setSendData({
        ...sendData,
        siteId: data.id,
        siteDesc: data.text,
        site: data,
      })
    } else {
      setSendData({
        ...sendData,
        siteId: null,
        siteDesc: null,
        site: null,
      })
    }
  }

  const handleReportType = (e) => {
    setRecordsCriteria(e.target.value)
    setSendData({ ...sendData, recordsCriteria: e.target.value })
  }

  const sortByColumn = (sort) => {
    const searchData = { ...sendData }

    if (searchData.sortBy === sort && searchData.orderBy) {
      searchData.orderBy = null
    } else {
      searchData.orderBy = 'desc'
    }

    searchData.sortBy = sort
    setSendData(searchData)

    handleSubmit(searchData)
  }

  const handleSubmit = (data) => {
    let searchData = {}
    if (data) {
      searchData = { ...data }
    } else {
      searchData = { ...sendData, orderBy: null }
    }
    // recordsCriteria 1 一覽表
    const getData = async () => {
      // if (!sendData.dateFrom || !sendData.dateTo) {
      //   alert("請選擇日期");
      //   return;
      // }

      if (!sendData.vendorId && (!sendData.dateFrom || !sendData.dateTo)) {
        alert('請選擇日期或承判商！！！')
        return
      }
      setBackOpen(true)
      let data = null

      if (searchData.recordsCriteria === '0') {
        data = await api.getDetailSQLlistWSPS(searchData)
      } else {
        data = await api.getSummarySQLlistWSPS(searchData)
      }

      if (data) {
        setIsSearchPage(false)
        setReportData(data)
        setTableData(data)
        setIsShowVendorColumn(searchData.recordsCriteria === '0')
      }
      setBackOpen(false)
    }
    getData()
  }

  const completeDate = (time1, time2, m) => {
    const year1 = time1.getFullYear()
    const year2 = time2.getFullYear()
    const month1 = time1.getMonth() + 1
    const month2 = time2.getMonth() + 1
    const day1 = time1.getDate()
    const day2 = time2.getDate()
    if (year2 === year1) {
      // 判断两个日期 是否是同年
      if (month2 - month1 > m) {
        // 相差是否 在m个月中
        return false
      } else if (month2 - month1 === m) {
        // 如果正好为 m月 判断天数
        if (day2 > day1) {
          return false
        }
      }
    } else {
      // 不同年
      if (year2 - year1 > 1) {
        return false
      } else if (year2 - year1 === 1) {
        if (month2 > m || month1 + m - month2 < 12) {
          return false
        } else if (month1 + m - month2 === 12) {
          // 正好为m月 判断天数
          if (day2 > day1) {
            return false
          }
        }
      }
    }
    return true
  }

  // const resetForm = (e) => {
  //   setReportType("0");
  //   setSendData({ recordsCriteria: "0" });
  // };

  const resetForm = (e) => {
    setSendData({ recordsCriteria: '0' })
    setReportType('0')
  }

  // 一览表
  const exportExcel = (data) => {
    if (sendData.recordsCriteria === '0') {
      $download(`/payrollReportController/getDetailSQLlistWSPSExcel`, 'application/vnd.ms-excel', data)
    } else {
      $download(`/payrollReportController/getSummarySQLlistWSPSExcel`, 'application/vnd.ms-excel', data)
    }
  }

  const exportPDF = (data) => {
    if (sendData.recordsCriteria === '0') {
      $download(`/payrollReportController/getDetailSQLlistWSPSPdf`, 'application/pdf', data)
    } else {
      $download(`/payrollReportController/getSummarySQLlistWSPSPdf`, 'application/pdf', data)
    }
  }

  const renderReportType = (param) => {
    switch (param) {
      case '0':
        return t('icon_vas_wsat_report_type_detail')
      case '1':
        return t('icon_vas_wsat_report_type_summary')
      default:
        return ''
    }
  }

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {isSearchPage && (
        <form>
          <Container maxWidth="md">
            <Grid justifyContent="center" alignItems="center">
              {/* 工人薪金及強積金及安全人員狀況分析 */}
              <Grid item xs={12}>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                  <b>{t('analysis_of_workers_salaries_and_MPF_status')}</b>
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  margin: 'auto',
                  width: '400px',
                  marginBottom: '20px',
                  marginTop: '10px',
                }}
              >
                <Autocomplete
                  sx={{ width: '400px' }}
                  value={sendData.vendor || null}
                  onChange={handleVendorId}
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  getOptionLabel={(option) => {
                    return option.text
                  }}
                  options={vendorList}
                  renderInput={(params) => <TextField fullWidth size="small" {...params} label={t('Vendor')} />}
                />
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  margin: 'auto',
                  width: '400px',
                  marginBottom: '20px',
                  marginTop: '10px',
                }}
              >
                <Autocomplete
                  sx={{ width: '400px' }}
                  value={sendData.site || null}
                  onChange={handleSite}
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  getOptionLabel={(option) => {
                    return option.id + ' ~' + option.text
                  }}
                  options={siteList}
                  renderInput={(params) => <TextField fullWidth size="small" {...params} label={t('icon1_autopay_setSiteUserMapping_tableLabel1')} />}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  margin: 'auto',
                  width: '400px',
                  marginBottom: '20px',
                  marginTop: '10px',
                }}
              >
                <TextField
                  sx={{ width: '400px' }}
                  // error={!sendData.dateFrom}
                  id="dateFrom"
                  value={sendData.dateFrom || ''}
                  fullWidth
                  size="small"
                  label={t('icon1_personalentersearch_from_date_condition')}
                  select
                >
                  {dateFrom.map((item, index) => (
                    <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleDateFrom(item)}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  margin: 'auto',
                  width: '400px',
                  marginBottom: '20px',
                  marginTop: '10px',
                }}
              >
                <TextField
                  sx={{ width: '400px' }}
                  // error={!sendData.dateTo}
                  id="dateTo"
                  value={sendData.dateTo || ''}
                  fullWidth
                  size="small"
                  label={t('icon1_personalentersearch_to_date_condition')}
                  select
                >
                  {dateTo.map((item, index) => (
                    <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleDateTo(item)}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  margin: 'auto',
                  width: '400px',
                  marginBottom: '20px',
                  marginTop: '10px',
                }}
              >
                <FormControl size="small">
                  <FormLabel>{t('criterion')}</FormLabel>
                  <RadioGroup row onChange={handleReportType} value={recordsCriteria}>
                    <FormControlLabel value="0" checked={recordsCriteria === '0'} label={t('icon_vas_wsat_report_type_detail')} control={<Radio />} />
                    <FormControlLabel value="1" checked={recordsCriteria === '1'} label={t('icon_vas_wsat_report_type_summary')} control={<Radio />} />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={6} />
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <FormControl>
                  <Button
                    variant="contained"
                    onClick={() => handleSubmit()}
                    sx={{
                      backgroundColor: 'primaryBlue',
                      color: 'commom.white',
                    }}
                  >
                    {t('button_submit')}
                  </Button>
                </FormControl>
                <FormControl sx={{ marginLeft: '20px' }}>
                  <Button
                    variant="contained"
                    onClick={resetForm}
                    sx={{
                      backgroundColor: 'primaryGreen',
                      color: 'commom.white',
                      marginRight: '10px',
                    }}
                    label="Reset"
                  >
                    {t('button_reset')}
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Container>
        </form>
      )}

      {!isSearchPage && (
        <>
          <Container maxWidth={false}>
            <div
              style={{
                display: 'block',
                textAlign: 'left',
                marginTop: '0px',
              }}
            >
              <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
                <ArrowBackIcon />
                {t('button_back')}
              </Button>
            </div>

            <div style={{ display: 'block', textAlign: 'right' }}>
              <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportExcel(sendData)}>
                <DownloadIcon />
                {t('button_export_excel')}
              </Button>
              <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportPDF(sendData)}>
                <DownloadIcon />
                {t('button_export_pdf')}
              </Button>
            </div>
            <div>
              <Typography variant="h4" style={{ textAlign: 'center', mt: '-30px' }}>
                <b>{t('analysis_of_workers_salaries_and_MPF_status')}</b>
              </Typography>
              <TableContainer>
                <Table sx={{ textAlign: 'left' }} size="small">
                  <TableBody>
                    <TableRow>
                      {/* 製作日期 */}
                      <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('icon_label_creation_date')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{moment(new Date().getTime()).format('yyyy/MM/DD')}</TableCell>
                    </TableRow>
                    <TableRow>
                      {/* 承判商 */}
                      <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('Contractor')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>
                        {/* {sendData.vendorDesc} */}

                        {sendData.vendorId ? sendData.vendorDesc : t('icon_vas_wsmszo_vendor')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {/* 地盤 */}
                      <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('icon1_autopay_setSiteUserMapping_tableLabel1')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.siteDesc ? sendData.siteDesc : t('all_territory')}</TableCell>
                    </TableRow>

                    {sendData.dateFrom && (
                      <TableRow>
                        {/* 日期（由) */}
                        <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('date_from')}:</TableCell>
                        <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.dateFromDesc}</TableCell>
                      </TableRow>
                    )}

                    {sendData.dateTo && (
                      <TableRow>
                        {/* 日期（至) */}
                        <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('date_to')}:</TableCell>
                        <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.dateToDesc}</TableCell>
                      </TableRow>
                    )}

                    <TableRow>
                      {/* 準則: 	 */}
                      <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('criterion')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>
                        {sendData.recordsCriteria === '0' ? t('icon_vas_wsat_report_type_detail') : t('icon_vas_wsat_report_type_summary')}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              {/* 詳細記錄 */}
              {sendData.recordsCriteria === '0' && (
                <>
                  <TableContainer style={{ maxHeight: '800px', maxWidth: '100vw' }}>
                    <Table size="small" sx={{ mt: '20px' }} stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('search_site_title2')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('Contractor')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('time')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('icon_vas_wsms_report_table_header_worker_name')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('icon_vas_wsms_report_table_header_smartcard')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('icon_vas_wsms_report_table_header_salary')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('icon_vas_wsms_report_table_header_employer_mpf')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('icon_vas_wsms_report_table_header_employee_mpf')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('icon_vas_wsms_report_table_header_work_days')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('icon_vas_wsms_report_table_header_average_salary')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('icon_vas_wsms_report_table_header_remark')}
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      {!!tableData && tableData.mainDataList.length > 0 && (
                        <>
                          <TableBody>
                            {tableData.mainDataList.map((row, ind) => (
                              <TableRow
                                hover
                                key={ind}
                                sx={{
                                  backgroundColor: ind % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                                }}
                              >
                                <TableCell>{row.siteDesc}</TableCell>
                                <TableCell>{row.vendorDesc}</TableCell>
                                <TableCell>
                                  {row.dateFrom}~{row.dateTo}
                                </TableCell>
                                <TableCell>{row.englishName}</TableCell>
                                <TableCell>{row.cardHolderId}</TableCell>
                                <TableCell>{row.relevantIncome.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                                <TableCell>{row.employerMPF.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                                <TableCell>{row.employeeMPF.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                                <TableCell>{row.workDayAct}</TableCell>
                                <TableCell>{row.aveSalary.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                                <TableCell>{row.payrollRemark}</TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {t('Total_Number')}：
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {tableData.numberTotal}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {tableData.relevantIncomeTotal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {tableData.employerMPFTotal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {tableData.employeeMPFTotal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {tableData.workDayActTotal.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {tableData.averageSal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </>
                      )}

                      {(!tableData || tableData.mainDataList.length <= 0) && (
                        <TableBody>
                          <TableRow>
                            <TableCell sx={{ textAlign: 'center' }} colSpan={15}>
                              {'No data found !'}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </>
              )}
              {/* 一覽表 */}
              {sendData.recordsCriteria === '1' && (
                <>
                  <TableContainer style={{ maxHeight: '800px', maxWidth: '100vw' }}>
                    <Table size="small" sx={{ mt: '20px' }} stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('search_site_title2')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('Contractor')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('time')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('icon_vas_wsms_report_table_header_total_salary')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('icon_vas_wsms_report_table_header_boss_mpf')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('employer_MPF_percentage')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('icon_vas_wsms_report_table_header_work_days')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('icon_vas_wsms_report_table_header_average_salary')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('confirm_the_salary_paid_by_the_employer')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('confirm_the_amount_of_MPF_that_the_employer_has_paid')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('there_are_no_pay_periods_for_salary_transfer_receipts')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('no_salary_transfer_to_receive_the_amount_of_the_feed_period')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('feed_periods_without_MPF_transfer_receipts')}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('mPF_amount_for_the_receiving_period_without_MPF_transfer')}
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      {!!tableData && tableData.mainDataList.length > 0 && (
                        <>
                          <TableBody>
                            {tableData.mainDataList.map((row, ind) => (
                              <TableRow
                                hover
                                key={ind}
                                sx={{
                                  backgroundColor: ind % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                                }}
                              >
                                <TableCell>{row.siteDesc}</TableCell>
                                <TableCell>{row.vendorDesc}</TableCell>
                                <TableCell>
                                  {row.dateFrom}~{row.dateTo}
                                </TableCell>
                                <TableCell>{row.relevantIncome.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                                <TableCell>{row.employerMPF.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                                <TableCell>{row.percentage.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                                <TableCell>{row.workDayAct.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                                <TableCell>{row.aveSalary.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                                <TableCell>{row.subIncome?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                                <TableCell>{row.subMpf?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>

                                <TableCell>
                                  {row.inPayRollSet?.map((itme, index) => (
                                    <Typography>{itme.period}</Typography>
                                  ))}
                                </TableCell>

                                <TableCell>
                                  {row.inPayRollSet?.map((itme, index) => (
                                    <Typography>{itme.netIncome?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Typography>
                                  ))}
                                </TableCell>

                                <TableCell>
                                  {row.inMPFSet?.map((itme, index) => (
                                    <Typography>{itme.period}</Typography>
                                  ))}
                                </TableCell>

                                <TableCell>
                                  {row.inMPFSet?.map((itme, index) => (
                                    <Typography>{itme.employerMpf?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Typography>
                                  ))}
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: 'right',
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {t('icon1_accrecsearch_total_condition')}：
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {tableData.relevantIncomeSum.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {tableData.employerMPFSum.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {(tableData.percentageSum * 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {tableData.workDayActSum.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {tableData.averageSal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {tableData.subincome.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {tableData.submpf.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {tableData.sumpayroll?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                  wordBreak: 'keep-all',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {tableData.summpf?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </>
                      )}

                      {(!tableData || tableData.mainDataList.length <= 0) && (
                        <TableBody>
                          <TableRow>
                            <TableCell sx={{ textAlign: 'center' }} colSpan={15}>
                              {'No data found !'}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </>
              )}
            </div>
          </Container>
        </>
      )}
    </>
  )
}
