import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
  lighter: '#059aff',
  light: '#76B0F1',
  main: '#2065D1',
  dark: '#103996',
  darker: '#061B64',
  contrastText: '#fff',
};

const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#061B64',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#061B64',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#061B64',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#061B64',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#061B64',
  contrastText: '#fff',
};

const TenderSys = {
  lighter: '#90ee90',
  light: '#00ff7f',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#061B64',
  contrastText: '#fff',
};

const TenderReport = {
  lighter: '#20b2aa',
  light: '#66cdaa',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#061B64',
  contrastText: '#fff',
};

const SystemAdministrator = {
  lighter: '#ebacac',
  light: '#e77c7c',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#061B64',
  contrastText: '#fff',
};

const vendorSystem = {
  lighter: '#9BCD9B',
  light: '#B4EEB4',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#061B64',
  contrastText: '#fff',
};

const pods = {
  lighter: '#6495ed',
  light: '#00bfff',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#061B64',
  contrastText: '#fff',
};
const wrprs = {
  lighter: '#ee82ee',
  light: '#ff69b4',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#061B64',
  contrastText: '#fff',
};
const vpas = {
  lighter: '#dda0dd',
  light: '#d8bfd8',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#061B64',
  contrastText: '#fff',
};

const REPORT = {
  header: "#4A93FF",
  record: "#E6E6E6"
};

const palette = {
  primaryBackgroundColor: "#1E88E5",
  primaryBlue: "#1E88E5",
  primaryGreen: "#2AC2CC",
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  TenderSys: TenderSys,
  TenderReport: TenderReport,
  SystemAdministrator: SystemAdministrator,
  vendorSystem: vendorSystem,
  pods: pods,
  wrprs: wrprs,
  vpas: vpas,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: GREY[100],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    // selected: "#1E88E5",
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  report: REPORT
};

export default palette;
