import { instance } from '../../instance'

export const distributeSubBatch = async () => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/distributeSubBatch`,
    // data: "kfwgvzpo",
    // headers: {
    //   "Content-Type": "text/plain",
    // },
  }).then(
    (result) => result.data,
    (error) => error,
  )
}
export const addSubBatchAction = async (searchCondition) => {
  // searchCondition.userId = "kfwgvzpo";
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/addSubBatchAction`,
    data: searchCondition,
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(
    (result) => result.data,
    (error) => error,
  )
}

export const selectThreeSand = async (searchCondition) => {
  // searchCondition.userId = "kfwgvzpo";
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/selectThreeSand`,
    data: searchCondition,
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(
    (result) => result.data,
    (error) => error,
  )
}

export const selectThreeSandAction = async (searchCondition) => {
  // searchCondition.userId = "kfwgvzpo";
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/selectThreeSandAction`,
    data: searchCondition,
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(
    (result) => result.data,
    (error) => error,
  )
}

export const finishBatch = async () => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/finishBatch`,
  }).then(
    (result) => result.data,
    (error) => error,
  )
}

export const changePayTpye = async () => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/selectType1`,
    data: 'S',
    headers: {
      'Content-Type': 'text/plain',
    },
  }).then(
    (result) => result.data,
    (error) => error,
  )
}
