import React, { useRef, useEffect } from 'react'
import { Container, Typography, Paper, Table, TableBody, TableHead, TableCell, TableContainer, TableRow, Backdrop, CircularProgress } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import $http, { $download } from 'src/utils/api/services'
import DownloadIcon from '@mui/icons-material/Download'
import moment from 'moment'
import { sub } from 'date-fns'

export default function CattpwspPerQuarterlyDetail() {
  const componentRef = useRef()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [dataSource, setDataSource] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  useEffect(() => {}, [])

  const handleBack = () => {
    navigate(-1)
  }

  const printComponentRef = useRef()

  useEffect(() => {
    getVendorAutoPayReportListTwo()
  }, [])

  const getVendorAutoPayReportListTwo = async () => {
    let list = await apiAtt.getVendorAutoPayReportListTwo(searchParams.get('dataYear'), searchParams.get('siteId'), searchParams.get('vendorId'), searchParams.get('tradeId'))

    if (list !== null) {
      setDataSource(list)
    }
    setLoading(false)
  }

  const exportPdf = (data) => {
    $download(`/payrollReportController/vendorAutoPayReportListTwoPdf`, 'application/pdf', data)
  }

  const exportExcel = (data) => {
    $download(`/payrollReportController/vendorAutoPayReportListTwoExcel`, 'application/vnd.ms-excel', data)
  }

  return (
    <Container maxWidth={false}>
      <div style={{ display: 'block', textAlign: 'left' }}>
        <Button sx={{ float: 'left' }} onClick={handleBack}>
          <ArrowBackIcon />
          {t('button_back')}
        </Button>
      </div>

      <div style={{ display: 'block', textAlign: 'right' }}>
        <Button
          sx={{ float: 'right', mr: '20px' }}
          onClick={() =>
            exportPdf({
              year: searchParams.get('dataYear'),
              siteId: searchParams.get('siteId'),
              siteDesc: searchParams.get('siteDesc'),
              vendorId: searchParams.get('vendorId'),
              vendorDesc: searchParams.get('vendorDesc'),
              jobNatureId: searchParams.get('tradeId'),
              jobNatureDesc: searchParams.get('tradeDesc'),
            })
          }
        >
          <DownloadIcon />
          {t('button_export_pdf')}
        </Button>
      </div>

      <div style={{ display: 'block', textAlign: 'right' }}>
        <Button
          sx={{ float: 'right', mr: '20px' }}
          onClick={() =>
            exportExcel({
              year: searchParams.get('dataYear'),
              siteId: searchParams.get('siteId'),
              siteDesc: searchParams.get('siteDesc'),
              vendorId: searchParams.get('vendorId'),
              vendorDesc: searchParams.get('vendorDesc'),
              jobNatureId: searchParams.get('tradeId'),
              jobNatureDesc: searchParams.get('tradeDesc'),
            })
          }
        >
          <DownloadIcon />
          {t('button_export_excel')}
        </Button>
      </div>

      <div id="div" style={{}} ref={printComponentRef}>
        <Container maxWidth={false}>
          <div>
            <div style={{ display: 'block', marginBottom: '60px' }}>
              <Typography variant="h4" sx={{ textAlign: 'center' }}>
                <b>{t('search_vendorPerQuarterly_title')}</b>
              </Typography>
            </div>

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>

            {
              <div>
                <TableContainer component={Paper} sx={{ marginBottom: '20px', backgroundColor: 'transparent', maxHeight: '80vh', maxWidth: '100vw' }}>
                  <Table sx={{ textAlign: 'left' }} size="small" stickyHeader>
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('search_site_title') + '：'} </TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{searchParams.get('siteDesc')}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', width: 200, border: 0 }}>{t('Vendor') + '：'}</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>
                          {searchParams.get('vendorId') === '' ? t('all_contractors') : searchParams.get('vendorId') + '-' + searchParams.get('vendorDesc')}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('search_trade_title') + '：'} </TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{searchParams.get('tradeId') === '' ? t('all_outfits') : searchParams.get('tradeDesc')}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>Total:</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{dataSource.length}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }

            {/* table */}
            <TableContainer sx={{ marginTop: '20px', maxHeight: '80vh', maxWidth: '100vw' }}>
              <Table sx={{ p: '2px' }} size="small" stickyHeader>
                <TableHead>
                  {/* TableRow是行，表示有多少行 */}
                  {/* TableCell是列，橫著看 */}
                  <TableRow hover>
                    <TableCell rowSpan={4} sx={{ whiteSpace: 'nowrap' }}>
                      {t('Site_Name')}
                    </TableCell>
                    <TableCell rowSpan={4} sx={{ whiteSpace: 'nowrap' }}>
                      {t('search_vendor_title')}
                    </TableCell>
                    <TableCell rowSpan={4} sx={{ whiteSpace: 'nowrap' }}>
                      {t('search_category_title')}
                    </TableCell>
                  </TableRow>
                  {/* 第一行 */}
                  <TableRow>
                    <TableCell colSpan={15} sx={{ textAlign: 'center' }}>
                      {searchParams.get('dataYear')}年
                    </TableCell>
                  </TableRow>
                  {/* 第二行 */}
                  <TableRow>
                    <TableCell colSpan={3} sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                      {t('search_season1_januaryMarch')}
                    </TableCell>
                    <TableCell colSpan={3} sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                      {t('search_season2_aprilJune')}
                    </TableCell>
                    <TableCell colSpan={3} sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                      {t('search_season3_julySeptember')}
                    </TableCell>
                    <TableCell colSpan={3} sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                      {t('search_season4_octoberDecember')}
                    </TableCell>
                    <TableCell colSpan={3} sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                      {t('search_annual_performanceSummary')}
                    </TableCell>
                  </TableRow>
                  {/* 第三行 */}
                  <TableRow>
                    {/* 第一季度 */}
                    <TableCell sx={{ textAlign: 'center' }}>{t('search_pay_with_autopay')}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{t('search_participants_persons')}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{t('search_involved_workers')}</TableCell>
                    {/* 第二季度 */}
                    <TableCell sx={{ textAlign: 'center' }}>{t('search_pay_with_autopay')}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{t('search_participants_persons')}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{t('search_involved_workers')}</TableCell>
                    {/* 第三季度 */}
                    <TableCell sx={{ textAlign: 'center' }}>{t('search_pay_with_autopay')}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{t('search_participants_persons')}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{t('search_involved_workers')}</TableCell>
                    {/* 第四季度 */}
                    <TableCell sx={{ textAlign: 'center' }}>{t('search_pay_with_autopay')}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{t('search_participants_persons')}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{t('search_involved_workers')}</TableCell>
                    {/* 全年表現總結 */}
                    <TableCell sx={{ textAlign: 'center' }}>{t('search_pay_with_autopay')}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{t('search_participants_persons')}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{t('search_involved_workers')}</TableCell>
                  </TableRow>
                </TableHead>

                {dataSource.map((dataRow, index) => (
                  <TableRow
                    hover
                    key={index + Math.random}
                    sx={{
                      backgroundColor: index % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                    }}
                  >
                    {/* 地盤名稱 */}
                    <TableCell>{dataRow.siteDesc} </TableCell>
                    {/* 承判商名稱 */}
                    <TableCell>{dataRow.vendorDesc} </TableCell>
                    {/* 行業分類 */}
                    <TableCell>{dataRow.jobNatureDesc} </TableCell>
                    {/* 第一季度3個數據 */}
                    <TableCell>{dataRow.useautoOne} </TableCell>
                    <TableCell>{dataRow.workerOne} </TableCell>
                    <TableCell>{dataRow.workdayOne} </TableCell>
                    {/* 第2季度3個數據 */}
                    <TableCell>{dataRow.useautoTwo} </TableCell>
                    {/* 地盤名稱 */}
                    <TableCell>{dataRow.workerTwo} </TableCell>
                    {/* 地盤名稱 */}
                    <TableCell>{dataRow.workdayTwo} </TableCell>
                    {/* 第3季度3個數據 */}
                    <TableCell>{dataRow.useautoThree} </TableCell>
                    {/* 地盤名稱 */}
                    <TableCell>{dataRow.workerThree} </TableCell>
                    {/* 地盤名稱 */}
                    <TableCell>{dataRow.workdayThree} </TableCell>
                    {/* 第4季度3個數據 */}
                    <TableCell>{dataRow.useautoFour} </TableCell>
                    {/* 地盤名稱 */}
                    <TableCell>{dataRow.workerFour}</TableCell>
                    {/* 地盤名稱 */}
                    <TableCell>{dataRow.workdayFour} </TableCell>
                    {/* 全年表現總結 */}
                    <TableCell>{dataRow.useautoAll}</TableCell>
                    {/* 地盤名稱 */}
                    <TableCell>{dataRow.workerAll} </TableCell>
                    {/* 地盤名稱 */}
                    <TableCell>{dataRow.workdayAll}</TableCell>
                  </TableRow>
                ))}
              </Table>
            </TableContainer>
          </div>
        </Container>
      </div>
    </Container>
  )
}
