import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Autocomplete,
  Container,
  Grid,
  FormControl,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  MenuItem,
  FormLabel,
  Link,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import moment from 'moment'
import * as api from '../../../utils/api/vendorAutopaySystem/RptWorkersAutoPayStatusSiteSumMaryBApi'
import * as commonApi from '../../../utils/api/CommonApi'

export default function RptWorkersAutoPayStatusSiteSumMaryB() {
  const [isSearchPage, setIsSearchPage] = useState(true)
  const { t } = useTranslation()
  const [backOpen, setBackOpen] = useState(false)
  const [siteList, setSiteList] = useState([])
  const [dateMonthList, setDateMonthList] = useState([])
  const [sendData, setSendData] = useState({})
  const [reportData, setReportData] = useState({})
  const [tableData, setTableData] = useState([])
  const [defaultIssml, setDefaultIssml] = useState('0')

  const [dialog, setDialog] = useState({
    content: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        確認
      </Button>
    ),
    open: false,
  })

  useEffect(() => {
    setBackOpen(true)
    const getData = async () => {
      // /*初始化值 */
      setSendData({ ...sendData, isSml: '0' })
      let getSiteList = await commonApi.getTeamBSite()
      let getDateMonthList = await commonApi.getDateMonthList()

      if (getSiteList) {
        getSiteList.splice(0, 0, { id: '', text: 'All' })
        setSiteList(getSiteList)
      }
      if (getDateMonthList) {
        setDateMonthList(getDateMonthList)
      }
      setBackOpen(false)
    }
    getData()
  }, [])

  const handleBack = () => {
    setIsSearchPage(true)
  }

  const handleDateFrom = (e) => {
    setSendData({ ...sendData, SearchDate: e.id, SearchToDate: e.text })
  }

  const handleSite = (e) => {
    setSendData({ ...sendData, siteId: e.id, siteDesc: e.text })
  }

  const handleIsSmlType = (e) => {
    setDefaultIssml(e.target.value)
    setSendData({ ...sendData, isSml: e.target.value })
  }

  const sortByColumn = (sort) => {
    const searchData = { ...sendData }

    if (searchData.sortBy === sort && searchData.orderBy) {
      searchData.orderBy = null
    } else {
      searchData.orderBy = 'desc'
    }

    searchData.sortBy = sort
    setSendData(searchData)

    handleSubmit(searchData)
  }

  const handleSubmit = (data) => {
    if (!sendData.SearchDate) {
      alert('請選擇日期')
    }

    let searchData = {}
    if (data) {
      searchData = { ...data }
    } else {
      searchData = { ...sendData, orderBy: null }
    }

    const getData = async () => {
      setBackOpen(true)
      let data = await api.getReportData(searchData)
      if (data && data.list) {
        setIsSearchPage(false)
        setReportData(data)
        setTableData(data.list)
      }
      setBackOpen(false)
    }
    getData()
  }

  const resetForm = (e) => {
    setDefaultIssml()
    setDefaultIssml('0')
    setSendData({ SearchDate: '', siteId: '', isSml: '0' })
  }

  const exportExcel = () => {
    const mpfPrint = async () => {
      setBackOpen(true)
      let sendApi = await commonApi.exportFile(sendData, '/payrollReportController/rptWorkersAutoPayStatusSiteSumMaryBListExcel', 'xls', 'RptWorkersAutoPayStatusSiteSumMaryB')
      if (sendApi) {
        if (sendApi.code === 500) {
          setDialog({
            ...dialog,
            content: '請求出錯',
            open: true,
          })
          return []
        }
        // setPrintData(sendApi.data || {})
      }
      setBackOpen(false)
    }
    mpfPrint()
  }

  const exportPdf = () => {
    const mpfPrint = async () => {
      setBackOpen(true)
      let sendApi = await commonApi.exportFile(sendData, '/payrollReportController/rptWorkersAutoPayStatusSiteSumMaryBListPDF', 'pdf', 'RptWorkersAutoPayStatusSiteSumMaryB')
      if (sendApi) {
        if (sendApi.code === 500) {
          setDialog({
            ...dialog,
            content: '請求出錯',
            open: true,
          })
          return []
        }
        // setPrintData(sendApi.data || {})
      }
      setBackOpen(false)
    }
    mpfPrint()
  }

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {isSearchPage && (
        <form>
          <Container maxWidth="md">
            <Grid justifyContent="center" alignItems="center" spacing={2}>
              {/* 工人薪金分析(以地盤分類)-Team B(總表) */}
              <Grid item xs={12}>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                  <b>{t('icon_vas_wspsd_title')}</b>
                </Typography>
              </Grid>

              <Grid item sx={{ margin: 'auto', width: '400px', marginBottom: '20px', marginTop: '10px' }}>
                <TextField id="siteId" value={sendData.siteId || ''} fullWidth size="small" label={t('icon_vas_wsms_site')} select>
                  {siteList.map((item, index) => (
                    <MenuItem key={index} value={item.id} onClick={() => handleSite(item)}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item sx={{ margin: 'auto', width: '400px', marginBottom: '20px', marginTop: '10px' }}>
                <TextField error={!sendData.SearchDate} id="SearchDate" value={sendData.SearchDate || ''} fullWidth size="small" label={t('icon_vas_wsat_date_from')} select>
                  {dateMonthList.map((item, index) => (
                    <MenuItem key={index} value={item.id} onClick={() => handleDateFrom(item)}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item sx={{ margin: 'auto', width: '400px', marginBottom: '20px', marginTop: '10px' }}>
                <FormControl size="small">
                  <FormLabel>{t('icon_vas_wsmszo_missml')}</FormLabel>
                  <RadioGroup row onChange={handleIsSmlType} aria-labelledby="contentRadio" name="row-radio-buttons-group" value={defaultIssml}>
                    <FormControlLabel value="0" label={t('icon_vas_wsmszo_noissml')} control={<Radio />} />
                    <FormControlLabel value="1" label={t('icon_vas_wsmszo_issml')} control={<Radio />} />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item sx={{ margin: 'auto', width: '400px', marginBottom: '20px', marginTop: '10px' }}>
                <FormControl>
                  <Button
                    variant="contained"
                    onClick={() => handleSubmit()}
                    sx={{
                      backgroundColor: 'primaryBlue',
                      color: 'commom.white',
                    }}
                  >
                    {t('button_submit')}
                  </Button>
                </FormControl>
                <FormControl sx={{ marginLeft: '20px' }}>
                  <Button
                    variant="contained"
                    onClick={() => resetForm()}
                    sx={{
                      backgroundColor: 'primaryGreen',
                      color: 'commom.white',
                      marginRight: '10px',
                    }}
                    label="Reset"
                  >
                    {t('button_reset')}
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Container>
        </form>
      )}

      {!isSearchPage && (
        <>
          <Container maxWidth={false}>
            <div
              style={{
                display: 'block',
                textAlign: 'left',
                marginTop: '0px',
              }}
            >
              <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
                <ArrowBackIcon />
                {t('button_back')}
              </Button>
            </div>

            <div style={{ display: 'block', textAlign: 'right' }}>
              <Button sx={{ float: 'right', mr: '20px' }} onClick={exportExcel}>
                <DownloadIcon />
                {t('button_export_excel')}
              </Button>
              <Button sx={{ float: 'right', mr: '20px' }} onClick={exportPdf}>
                <DownloadIcon />
                {t('button_export_pdf')}
              </Button>
            </div>
            <div>
              <Typography variant="h4" style={{ textAlign: 'center', mt: '-30px' }}>
                <b>{t('icon_vas_wspsd_title')}</b>
              </Typography>
              <TableContainer>
                <Table sx={{ textAlign: 'left' }} size="small">
                  <TableBody>
                    <TableRow>
                      {/* 製作日期 */}
                      <TableCell sx={{ fontWeight: 'bold', display: 'block', borderBottom: 'none' }}>{t('icon_label_creation_date')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{moment(new Date().getTime()).format('yyyy/MM/DD')}</TableCell>
                    </TableRow>
                    <TableRow>
                      {/* 日期（由) */}
                      <TableCell sx={{ fontWeight: 'bold', width: 200, borderBottom: 'none' }}>{t('icon_vas_wsat_date_from')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.SearchToDate}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 包括 SANFIELD (MANAGEMENT) LTD 	 */}
                      <TableCell sx={{ fontWeight: 'bold', display: 'block', borderBottom: 'none' }}>{t('icon_vas_wsms_ssml')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.isSml === '0' ? t('icon_vas_wsmszo_noissml') : t('icon_vas_wsmszo_issml')}</TableCell>
                    </TableRow>

                    <TableRow>
                      {/* 地盤： 	 */}
                      <TableCell sx={{ fontWeight: 'bold', display: 'block', borderBottom: 'none' }}>{t('icon1_autopay_setSiteUserMapping_tableLabel1')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.siteId === '' ? t('All_site1') : sendData.siteId == null ? t('All_site1') : sendData.siteDesc}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer style={{ maxHeight: '800px' }}>
                <Table size="small" sx={{ mt: '20px' }} stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>{''}</TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap', textAlign: 'center' }} colSpan={30}>
                        <Link underline="none">{sendData.SearchToDate}</Link>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>{''}</TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap', textAlign: 'center' }} colSpan={2}>
                        <Link underline="none">{t('icon_vas_wsms_bitou')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap', textAlign: 'center' }} colSpan={12}>
                        <Link underline="none">{t('icon_vas_wsms_bitoua')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap', textAlign: 'center' }} colSpan={8}>
                        <Link underline="none">{t('icon_vas_wsms_bitous')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap', textAlign: 'center' }} colSpan={8}>
                        <Link underline="none">{t('icon_vas_wsms_bitoud')}</Link>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('icon_vas_wsms_bitouf')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('Total_Number')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('icon_vas_wsat_total_days')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('search_participants_persons')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('search_percent')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('icon_vas_wsat_attendance_days')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('search_percent')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('search_non_participatine')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('search_percent')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('icon_vas_wsat_absence_days')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('search_percent')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('icon_vas_wsat_selfw')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('search_percent')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('icon_vas_wsat_selfday')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('search_percent')}</Link>
                      </TableCell>

                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('icon1_autopay_button8_print_PeopleCount')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('search_percent')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('search_participatin_dayone')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('search_percent')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('icon_vas_wsat_selfw')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('search_percent')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('icon_vas_wsat_selfday')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('search_percent')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('icon1_autopay_button8_print_PeopleCount')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('search_percent')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('search_participatin_dayone')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('search_percent')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('icon_vas_wsat_selfw')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('search_percent')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('icon_vas_wsat_selfday')}</Link>
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Link underline="none">{t('search_percent')}</Link>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {!!tableData && tableData.length > 0 && (
                    <>
                      <TableBody>
                        {tableData.map((row, ind) => (
                          <TableRow
                            hover
                            key={ind}
                            sx={{
                              backgroundColor: ind % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                            }}
                          >
                            <TableCell>{row.siteDesc}</TableCell>
                            <TableCell>{row.allWorkers}</TableCell>
                            <TableCell>{row.allWorkDays}</TableCell>
                            <TableCell>{row.workerSwithAutoPay}</TableCell>
                            <TableCell>{row.percenTageOne}</TableCell>
                            <TableCell>{row.workDaySwithAutoPay}</TableCell>
                            <TableCell>{row.percenTageTwo}</TableCell>
                            <TableCell>{row.workerSwithOutAutoPay}</TableCell>
                            <TableCell>{row.percenTageThree}</TableCell>
                            <TableCell>{row.workDaySwithOutAutoPay}</TableCell>
                            <TableCell>{row.percenTageFrou}</TableCell>
                            <TableCell>{row.workerSwithOutSelfEmployee}</TableCell>
                            <TableCell>{row.percenTageFive}</TableCell>
                            <TableCell>{row.workDaySwithOutSelfEmployee}</TableCell>
                            <TableCell>{row.percenTageSix}</TableCell>
                            <TableCell>{row.nopayWorkers}</TableCell>
                            <TableCell>{row.percenTageSeven}</TableCell>
                            <TableCell>{row.nopayWorkDays}</TableCell>
                            <TableCell>{row.percenTageEight}</TableCell>
                            <TableCell>{row.nopayWorkersSelfEmployee}</TableCell>
                            <TableCell>{row.percenTageNine}</TableCell>
                            <TableCell>{row.nopayWorkDaysSelfEmployee}</TableCell>
                            <TableCell>{row.percenTageTen}</TableCell>
                            <TableCell>{row.exenscWorkers}</TableCell>
                            <TableCell>{row.percenTageEleven}</TableCell>
                            <TableCell>{row.exenscWorkDays}</TableCell>
                            <TableCell>{row.percenTageTwelve}</TableCell>
                            <TableCell>{row.exenscWorkersSelfEmployee}</TableCell>
                            <TableCell>{row.percenTageThirteen}</TableCell>
                            <TableCell>{row.exenscWorkDaysSelfEmployee}</TableCell>
                            <TableCell>{row.percenTageFourteen}</TableCell>
                          </TableRow>
                        ))}

                        <TableRow>
                          <TableCell>
                            <b>{'Total'}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.allWorkers}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.allWorkDays}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.workerSwithAutoPay}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.percenTageOne}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.workDaySwithAutoPay}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.percenTageTwo}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.workerSwithOutAutoPay}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.percenTageThree}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.workDaySwithOutAutoPay}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.percenTageFrou}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.workerSwithOutSelfEmployee}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.percenTageFive}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.workDaySwithOutSelfEmployee}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.percenTageSix}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.nopayWorkers}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.percenTageSeven}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.nopayWorkDays}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.percenTageEight}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.nopayWorkersSelfEmployee}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.percenTageNine}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.nopayWorkDaysSelfEmployee}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.percenTageTen}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.exenscWorkers}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.percenTageEleven}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.exenscWorkDays}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.percenTageTwelve}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.exenscWorkersSelfEmployee}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.percenTageThirteen}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.exenscWorkDaysSelfEmployee}</b>
                          </TableCell>
                          <TableCell>
                            <b>{reportData.percenTageFourteen}</b>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </>
                  )}
                  {(!tableData || tableData.length <= 0) && (
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ textAlign: 'center' }} colSpan={15}>
                          {'No data found !'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          </Container>
        </>
      )}
    </>
  )
}
