import { instance } from "../../instance";

// 查詢所有菜單
export const getAllMenu = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/userController/menuViewByParentAll`,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 角色list
export const getRoleList = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/userController/role/select-all`,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 编辑
export const editMenu = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/userController/menuEidte`,
    data: data,
  }).then(
    (result) => {
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
// 新增
export const newMenu = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/userController/menuAddAction`,
    data: data,
  }).then(
    (result) => {
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 新增
export const deleteMenu = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/userController/menuDelete`,
    data: data,
  }).then(
    (result) => {
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 移动
export const moveMenu = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/userController/menuEidteUp`,
    data: data,
  }).then(
    (result) => {
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 最新通知list
export const searchNoticList = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/othermodules/searchAllMessage`,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
