// @mui
import { GlobalStyles as MUIGlobalStyles } from '@mui/material'
import { initial, unset } from 'lodash'

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
          // zoom: 0.6,
        },

        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },

        div: {
          '& .MuiListItemText-root': {
            fontSize: 15,
          },
          '& .MuiFormControlLabel-label': {
            fontSize: '0.9rem',
          },
          '& .MuiButton-sizeMedium': {
            fontSize: 12,
          },
          '& .MuiInputLabel-shrink': {
            fontSize: 15,
          },
          '& .MuiOutlinedInput-input': {
            fontSize: '0.9rem',
          },

          '& .MuiTableCell-sizeSmall': {
            size: 12,
          },
          '& .MuiButton-containedPrimary': {
            size: 15,
          },

          '& .MuiInputBase-formControl': {
            fontSize: 15,
          },

          '& .MuiInputBase-inputSizeSmall': {
            fontSize: 14,
          },

          '& .MuiButton-root': {
            fontSize: 12,
          },
          '& .css-q2q4r9': {
            webkitFlexGrow: 0,
          },
          '& .css-1n12mwm-MuiTypography-root': {
            fontSize: 35,
          },

          '& .css-1hf2ghy-MuiTableCell-root': {
            fontSize: 15,
          },
          '& .css-2ulfj5-MuiTypography-root': {
            fontSize: 20,
          },

          '& .css-47v85a-MuiTableRow-root': {
            fontSize: 12,
          },

          '& .css-kge0eu': {
            fontSize: 35,
          },
          '& .css-nfgojn-MuiToolbar-root': {
            padding: '28px 0px',
            minHeight: '54px',
          },
          '& .css-xi6ehz-MuiContainer-root': {
            paddingLeft: '14px',
            paddingRight: '192px',
          },
          '& .css-1mk1w41-MuiCardHeader-root': {
            padding: ' 12px 12px 1px',
          },

          '& .css-2o8dm9-MuiPaper-root-MuiCard-root': {
            width: '174px',
            height: '162px',
          },
          '& .css-1f60k0n-MuiPaper-root-MuiCard-root': {
            width: '174px',
            height: ' 162px',
          },

          '& .css-16bb5bf-MuiPaper-root-MuiCard-root': {
            width: '174px',
            height: '162px',
          },
          '& .css-15n46qv-MuiPaper-root-MuiCard-root': {
            width: '174px',
            height: '162px',
          },

          '& .css-mqiuzi-MuiPaper-root-MuiCard-root': {
            width: '174px',
            height: '162px',
          },

          '& .css-2wpjcl-MuiPaper-root-MuiCard-root': {
            width: '174px',
            height: '162px',
          },
          '& .css-1rab87k-MuiPaper-root-MuiCard-root': {
            width: '174px',
            height: '162px',
          },

          '& .css-1lentyj-MuiPaper-root-MuiCard-root': {
            width: '174px',
            height: '162px',
          },

          '& .css-st6rqe-MuiPaper-root-MuiCard-root': {
            width: '174px',
            height: '162px',
          },
          '& .css-1oq6dvy-MuiPaper-root-MuiCard-root': {
            width: '174px',
            height: '162px',
          },

          '& .css-ez287j-MuiPaper-root-MuiCard-root': {
            width: '174px',
            height: '162px',
          },
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
      }}
    />
  )

  return inputGlobalStyles
}
