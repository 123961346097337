import React, { useRef, useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PrintIcon from "@mui/icons-material/Print";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactToPrint from "react-to-print";
import { confirm } from "src/components/common";
import { partialBatchFinishBatch } from "../../../../../utils/api/workerAttendanceRecord/DistributePartialBatch";

export default function GetRecord(props) {
  const {
    workerSet,
    headerSet,
    batchSet,
    rsbatch,
    disabled,
    fullDisabled,
    setInProgress,
    handleBackToFlowMenu,
    handleAddPartialGrainPeriod,
    setSnackbar,
    snackbar,
    setDialog,
    dialog,
    reload,
    setReload,
  } = props;
  const componentRef = useRef();
  const { t } = useTranslation();
  let lastSubContractorId = useRef(0);
  useEffect(() => {
    lastSubContractorId.current = 0;
  }, []);

  const handleFinshPartialGrainPeriod = async () => {
    setInProgress(true);
    let res = await partialBatchFinishBatch();
    setInProgress(false);
    if (res.code && res.code === 200) {
      confirm({
        title: t("tip"),
        content: "保存成功",
        onOk: () => {
          setReload(!reload);
        },
      });
      // setSnackbar({
      //   ...snackbar,
      //   message: res.message ? res.message : "",
      //   open: true,
      //   action: (
      //     <Button
      //       variant="contained"
      //       onClick={() => {
      //         setSnackbar({ ...snackbar, open: false });
      //         setReload(!reload)
      //       }}
      //     >
      //       確認
      //     </Button>
      //   ),
      // });
    } else {
      setDialog({
        ...dialog,
        open: true,
        content: res.message ? res.message : "",
      });
    }
    // setInProgress(false);
  };

  const getSubContractorDesc = (row) => {
    console.log("lastSubContractorId", lastSubContractorId.current);
    console.log("row.subContractorId", row.subContractorId);

    if (
      lastSubContractorId.current === 0 ||
      lastSubContractorId.current !== row.subContractorId
    ) {
      lastSubContractorId.current = row.subContractorId;
      return row.subContractorDesc;
    } else {
      return "";
    }
  };

  const getSubBatchDesc = (row) => {
    let desc = "";
    if (row.subBatchDateSet && row.subBatchDateSet.length > 0) {
      row.subBatchDateSet.forEach((val) => {
        desc += `${val.dateFrom} - ${val.dateTo}\n`;
      });
    }
    return row.subBatchId
      ? desc + "(" + row.subBatchId + ")" + row.dateFrom + "-" + row.dateTo
      : desc;
  };

  return (
    <div>
      <div style={{ display: "block", textAlign: "left", marginTop: "0px" }}>
        <Button
          sx={{ float: "left", mr: "20px" }}
          onClick={handleBackToFlowMenu}
        >
          <ArrowBackIcon />
          {t("button_back")}
        </Button>
      </div>
      <div style={{ display: "block", textAlign: "right", marginTop: "0px" }}>
        {/* <ReactToPrint
          trigger={() => (
            <Button>
              <PrintIcon />
              {t("button_print")}
            </Button>
          )}
          content={() => componentRef.current}
          copyStyles
          pageStyle={`
                  @page { margin: 20px 20px !important; }
                  @media print {
                      @page {  }
                  }`}
        /> */}
      </div>
      <div ref={componentRef}>
        <Typography variant="h4" sx={{ display: "block" }}>
          <b>{t("Assigning_workers_partial_pay_periods")}</b>
        </Typography>
        <div style={{ display: "block", textAlign: "left" }}>
          <Grid container columnSpacing={1}>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "70%" }}>
                      <Typography sx={{ display: "block", fontSize: "16px" }}>
                        {t(
                          "icon1_autopay_distributePartialBatch_print_label1"
                        ) +
                          (headerSet.length > 0 && headerSet[0].dateFrom
                            ? headerSet[0].dateFrom
                            : "")}
                      </Typography>
                      <Typography sx={{ display: "block", fontSize: "16px" }}>
                        {t(
                          "icon1_autopay_distributePartialBatch_print_label2"
                        ) +
                          (headerSet.length > 0 && headerSet[0].dateTo
                            ? headerSet[0].dateTo
                            : "")}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "30%" }}>
                      {batchSet.length > 0 && (
                        // <TableContainer sx={{ display: "block" }}>
                        <table
                          style={{
                            pageBreakInside: "inherit",
                            maxWidth: "300px",
                          }}
                        >
                          <thead>
                            <tr>
                              <td align="left" style={{ fontSize: "16px" }}>
                                {t(
                                  "icon1_autopay_distributePartialBatch_print_label3"
                                )}
                              </td>
                              <td
                                align="left"
                                style={{
                                  fontSize: "16px",
                                  paddingLeft: "15px",
                                }}
                              >
                                {t(
                                  "icon1_autopay_distributePartialBatch_print_label4"
                                )}
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {batchSet.map((item, index) => (
                              <tr key={index}>
                                <td style={{ fontSize: "15px" }}>
                                  {"(" + item.subBatchId + ")  "}
                                  {item.dateFrom +
                                    (item.dateTo ? " - " + item.dateTo : "")}
                                </td>
                                <td
                                  style={{
                                    fontSize: "15px",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  {item.people}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        // </TableContainer>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </Grid>

            <Grid item xs={12} sx={{ marginBottom: "10px" }}>
              <Button
                variant="contained"
                onClick={handleAddPartialGrainPeriod}
                disabled={disabled === "Disabled"}
              >
                {t("icon1_autopay_distributePartialBatch_print_label5")}
              </Button>

              {rsbatch !== null && rsbatch?.counter > 0 ? (
                <Button
                  variant="contained"
                  onClick={handleFinshPartialGrainPeriod}
                  sx={{ marginLeft: "10px" }}
                  disabled={fullDisabled === "Disabled"}
                >
                  {t("icon1_autopay_distributePartialBatch_print_label6")}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{ marginLeft: "10px" }}
                  disabled
                >
                  {t("icon1_autopay_distributePartialBatch_print_label6")}
                </Button>
              )}
            </Grid>
          </Grid>
          {/* table */}
          <TableContainer sx={{ display: "block" }}>
            <Table
              sx={{
                minWidth: 650,
              }}
              size="small"
            >
              <TableHead>
                <TableRow hover>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      fontSize: "15px",
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t("No.")}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      fontSize: "15px",
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t("Subcontractors")}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      fontSize: "15px",
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t(
                      "icon1_autopay_distributePartialBatch_print_table_label3"
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      fontSize: "15px",
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t(
                      "icon1_autopay_distributePartialBatch_print_table_label4"
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      fontSize: "15px",
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t(
                      "icon1_autopay_distributePartialBatch_print_table_label5"
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      fontSize: "15px",
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t(
                      "icon1_autopay_distributePartialBatch_print_table_label6"
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      fontSize: "15px",
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t(
                      "icon1_autopay_distributePartialBatch_print_table_label7"
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      fontSize: "15px",
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t("Partial_pay_period")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workerSet && workerSet.length > 0 ? (
                  workerSet.map((row, index) => (
                    <TableRow hover key={index}>
                      <TableCell
                        sx={{ fontSize: "15px" }}
                        component="th"
                        scope="row"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: "15px" }}>
                        {getSubContractorDesc(row)}
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: "15px" }}>
                        {row.chineseName}
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: "15px" }}>
                        {row.englishName}
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: "15px" }}>
                        {row.hkid}
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: "15px" }}>
                        {row.bankAccount ? row.bankAccount : ""}
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: "15px" }}>
                        {row.mpfScheme ? row.mpfScheme : ""}
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: "15px" }}>
                        {getSubBatchDesc(row)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow hover>
                    <TableCell colSpan={8}>No Records Found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
