import { instance } from "../../instance";
// 日期下拉框
export const getPeriodYear = async (site) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/vps/common/getPeriodYear`,
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

// 承判商名称
export const getSubContractorList = async (site) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/vps/common/getvendorId`,
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

// 表格數據 pdf
export const getvendorAutoPayStatusByPayrollListPDF = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/vendorAutoPayStatusByPayrollListPDF`,
        data: data
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

// 表格數據 excel
export const getvendorAutoPayStatusByPayrollListExcel = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/vendorAutoPayStatusByPayrollListExcel`,
        data: data
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

// 表格數據
export const getData = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/VendorAutoPayStatusByPayrollList`,
        data: data
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};

// 返回支薪及供款期
export const getVendorId = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/getVendorId`,
        data: data
    })
        .then(
            (result) => {
                return result.data.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};


/**
 * 
 * @param {檢索條件對象} data 
 * @param {URL相對路徑} relativePath 
 * @param {文件類型} fileType 
 * @param {文件名} fileName 
 * @returns 
 */
export const exportFile = async (data, relativePath, fileType, fileName) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}${relativePath}`,
        data: data,
        responseType: 'arraybuffer',
    })
        .then(
            (result) => {
                let url = window.URL.createObjectURL(new Blob([result.data]))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                const documentName = !fileName ? moment(new Date().getTime()).format("yyyyMMDDHHmmssSSS") : fileName + moment(new Date().getTime()).format("yyyyMMDDHHmmssSSS")
                link.setAttribute('download', `${documentName}.${fileType}`)
                document.body.appendChild(link)
                link.click()
                return result.data;
            },
            (error) => {
                // alert("請求出錯")
                return [];
            }
        );
};
