import { instance } from '../../instance'

export const getFillSite = async () => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getSite`,
    headers: {
      'Content-Type': 'text/plain',
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯");
      return []
    },
  )
}

export const getNewSite = async () => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getFillSite`,
    headers: {
      'Content-Type': 'text/plain',
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯");
      return []
    },
  )
}

export const getFillSiteWithVendor = async (data) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getSiteWithVendor`,
    data: data,
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯");
      return []
    },
  )
}

// 獲取所有承判商list
export const getFillSiteWithVendorAll = async () => {
  return instance({
    method: 'GET',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getfillVendorId`,
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      // alert("請求出錯");
      return []
    },
  )
}

// 工作類標
export const getfilljobNatureId = async () => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getfilljobNatureId`,
    headers: {
      'Content-Type': 'text/plain',
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}
// 受僱職位
export const getfillSafetyPosition = async () => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getfillSafetyPosition`,
    headers: {
      'Content-Type': 'text/plain',
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}
// 模塊1：登記記錄列表
export const getSafetyAllowEnterList = async (dateFrom, dateTo, jobNatureId, safetyPositionId, siteId) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getSafetyAllowEnterList`,
    data: {
      dateFrom: dateFrom,
      dateTo: dateTo,
      jobNatureId: jobNatureId,
      safetyPositionId: safetyPositionId,
      siteId: siteId,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}
// 模塊2：出席天數首頁列表
export const getPFSApplicationApply1 = async () => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getPFSApplicationApply1`,
    data: {},
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 模塊2：出席天數第一个Detail--头部数据（一个页面分了两个接口，一个头部，一个内容
export const getPFSApplicationApplyDetail = async (smartCardId, siteId, effectiveDate) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getPFSApplicationApplyDetail`,
    data: {
      smartCardId: smartCardId,
      siteId: siteId,
      effectiveDate: effectiveDate,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 模塊2：出席天數第一个Detail--内容数据（一个页面分了两个接口，一个头部，一个内容
export const getPFSApplicationApplyDetailVoForm = async (smartCardId, siteId, effectiveDate) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getPFSApplicationApplyDetailVoForm`,
    data: {
      smartCardId: smartCardId,
      siteId: siteId,
      effectiveDate: effectiveDate,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}
// 模塊2：出席天數编辑界面--头部数据 （分为头部---中间--底部
export const getPFSApplicationViewTitle = async (applicationId, applicationSeqNo) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getPFSApplicationViewTitle`,
    data: {
      applicationId: applicationId,
      applicationSeqNo: applicationSeqNo,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 模塊：承判商申請派駐地盤安全人員每日出席批核記錄查看View
export const getPFSApplicationDayView = async (applicationId, applicationSeqNo) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getPTPFSApprovedByDateListByView`,
    data: {
      applicationId: applicationId,
      applicationSeqNo: applicationSeqNo,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 模塊2：出席天數编辑界面--中间数据 （分为头部---中间--底部
export const getPFSApplicationViewForm1 = async (applicationId, applicationSeqNo) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getPFSApplicationViewForm1`,
    data: {
      applicationId: applicationId,
      applicationSeqNo: applicationSeqNo,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 模塊2：出席天數编辑界面--头部数据 （分为头部---中间--底部
export const getPFSApplicationSetup = async () => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getPFSApplicationSetup`,
  }).then(
    (result) => {
      return result.data.data
    },
    () => {
      alert('請求出錯')
      return null
    },
  )
}

// 模塊2：出席天數编辑界面--底部数据 （分为头部---中间--底部
export const getPFSApplicationViewForm2 = async (applicationId, applicationSeqNo) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getPFSApplicationViewForm2`,
    data: {
      applicationId: applicationId,
      applicationSeqNo: applicationSeqNo,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}
// 模塊2：修改
export const updatePFSApplicationViewForm1 = async (sendDataList) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/updatePFSApplicationViewForm1`,
    data: sendDataList,
  }).then(
    (result) => {
      return result.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 模塊2：修改
export const updatePFSApplicationViewForm2 = async (sendDataList) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/updatePFSApplicationViewForm2`,
    data: sendDataList,
  }).then(
    (result) => {
      return result.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 模塊2：删除
export const deletePFSApplicationViewForm1 = async (sendDataList) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/deletePFSApplicationViewForm1`,
    data: sendDataList,
  }).then(
    (result) => {
      return result.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}
// 模塊3：獲取智能卡編號
export const getfillSmartCard2 = async (chineseName, englishName, cardHolderId, safetyPositionId) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getfillSmartCard2`,
    data: {
      chineseName: chineseName,
      englishName: englishName,
      cardHolderId: cardHolderId,
      safetyPositionId: safetyPositionId,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}
// 模塊3：批核詳細記錄列表
export const getRPTPFSApprovedDtlList = async (siteId, cardHolderId, cd, dateFrom, dateTo) => {
  if (cd === 'D') {
    dateFrom = dateFrom.replace('-', '')
    dateTo = dateTo.replace('-', '')
  }

  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getRPTPFSApprovedDtlList`,
    data: {
      siteId: siteId,
      cardHolderId: cardHolderId,
      design: cd,
      dateFrom: dateFrom,
      dateTo: dateTo,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}
// 模塊3：每日批核詳細記錄列表
export const getPTPFSApprovedByDateList = async (siteId, cardHolderId, cd, dateFrom, dateTo) => {
  if (cd === 'D') {
    dateFrom = dateFrom.replace('-', '')
    dateTo = dateTo.replace('-', '')
  }
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getPTPFSApprovedByDateList`,
    data: {
      siteId: siteId,
      cardHolderId: cardHolderId,
      design: cd,
      dateFrom: dateFrom,
      dateTo: dateTo,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}
// 模塊4：一覽表
export const getMonthlyApprovedDay = async (siteId, dateFrom, dateTo) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getMonthlyApprovedDay`,
    data: {
      siteId: siteId,
      dateFrom: dateFrom,
      dateTo: dateTo,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 承判商申請派駐地盤安全人員每月出席一覽表詳情
export const getMonthlySummaryDetailView = async (siteId, vendorId, smartCardId, yearmonth) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getMonthlySummaryDetailView`,
    data: {
      siteId: siteId,
      vendorId: vendorId,
      smartCardId: smartCardId,
      yearmonth: yearmonth,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 工人出入記錄一覽表
export const getSubContractorId = async (siteId) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getSubContractorId`,
    data: {
      siteId: siteId,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

export const getInOutRecordList = async (accessDateFrom, accessDateTo, siteId, smartCardId, subContractorId) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getInOutRecordList`,
    data: {
      accessDateFrom: accessDateFrom,
      accessDateTo: accessDateTo,
      siteId: siteId,
      smartCardId: smartCardId,
      subContractorId: subContractorId,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

export const getInOutRecordListWithVendor = async (accessDateFrom, accessDateTo, siteId, smartCardId, subContractorId, vendorId, vendorDesc) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getInOutRecordListWithVendor`,
    data: {
      accessDateFrom: accessDateFrom,
      accessDateTo: accessDateTo,
      siteId: siteId,
      smartCardId: smartCardId,
      subContractorId: subContractorId,
      vendorId: vendorId,
      vendorDesc: vendorDesc,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

export const getInOutRecordAccrualList = async (accessDateFrom, accessDateTo, siteId, smartCardId, subContractorId) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getInOutRecordAccrualList`,
    data: {
      accessDateFrom: accessDateFrom,
      accessDateTo: accessDateTo,
      siteId: siteId,
      smartCardId: smartCardId,
      subContractorId: subContractorId,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

export const getInOutRecordAccrualListWithVendor = async (accessDateFrom, accessDateTo, siteId, smartCardId, subContractorId, vendorId, vendorDesc) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getInOutRecordAccrualListWithVendor`,
    data: {
      accessDateFrom: accessDateFrom,
      accessDateTo: accessDateTo,
      siteId: siteId,
      smartCardId: smartCardId,
      subContractorId: subContractorId,
      vendorId: vendorId,
      vendorDesc: vendorDesc,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

export const getInOutRecordListForWorker = async (accessDateFrom, accessDateTo, siteId, smartCardId, subContractorId) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getInOutRecordListForWorker`,
    data: {
      accessDateFrom: accessDateFrom,
      accessDateTo: accessDateTo,
      siteId: siteId,
      smartCardId: smartCardId,
      subContractorId: subContractorId,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

export const getInOutRecordListForWorkerWithVendor = async (accessDateFrom, accessDateTo, siteId, smartCardId, subContractorId, vendorId, vendorDesc) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getInOutRecordListForWorkerWithVendor`,
    data: {
      accessDateFrom: accessDateFrom,
      accessDateTo: accessDateTo,
      siteId: siteId,
      smartCardId: smartCardId,
      subContractorId: subContractorId,
      vendorId: vendorId,
      vendorDesc: vendorDesc,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 獲取承判商列表
export const getvendorId = async () => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getvendorId`,
    data: {},
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 獲取年份列表
export const getfillYear = async () => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getfillYear`,
    data: {},
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 獲取年月列表
export const getfillYearMonth = async () => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getfillYearMoDate   `,
    data: {},
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 獲取承判商自動轉帳支付工人薪金表現(每季)
export const getVendorAutoPayReportListTwo = async (year, siteId, vendorId, jobNatureId) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/VendorAutoPayReportListTwo`,
    data: {
      year: year,
      siteId: siteId,
      vendorId: vendorId,
      jobNatureId: jobNatureId,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

export const vendorAutoPayReportListTwoPdf = async (sendData) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/vendorAutoPayReportListTwoPdf`,
    data: sendData,
    responseType: 'arraybuffer',
  }).then(
    (result) => {
      let url = window.URL.createObjectURL(new Blob([result.data]))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      const fileName = decodeURI(result.headers['content-disposition'].split(';')[1].split("filename*=UTF-8''")[1])
      link.setAttribute('download', fileName + '.pdf')
      document.body.appendChild(link)
      link.click()
      return result.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}

export const vendorAutoPayReportListTwoExcel = async (sendData) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/vendorAutoPayReportListTwoExcel`,
    data: sendData,
    responseType: 'arraybuffer',
  }).then(
    (result) => {
      let url = window.URL.createObjectURL(new Blob([result.data]))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      const documentName = !fileName ? moment(new Date().getTime()).format('yyyyMMDDHHmmssSSS') : fileName + moment(new Date().getTime()).format('yyyyMMDDHHmmssSSS')
      link.setAttribute('download', `${documentName}.${fileType}`)
      document.body.appendChild(link)
      link.click()
      return result.data
    },
    (error) => {
      // alert("請求出錯")
      return []
    },
  )
}

// 獲取承判商自動轉賬支付工人薪金表現（每月）
export const getVendorAutoPaybeHaveMonthListTwolist = async (year, siteId, vendorId, jobNatureId) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/VendorAutoPaybeHaveMonthListTwolist`,
    data: {
      year: year,
      siteId: siteId,
      vendorId: vendorId,
      jobNatureId: jobNatureId,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 獲取承判商所有行業每月自動轉賬出糧分析報告
export const getTradeReportList = async (year, jobNatureId) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/payrollReportController/TradeReportList`,
    data: {
      year: year,
      jobNatureId: jobNatureId,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 獲取承判商工人支薪記錄表
export const getPayrollchecklistList = async (accessDateFrom, accessDateTo, siteId, smartCardId, chineseName, englishName, subContractorId, subContractorDesc) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getPayrollchecklistList`,
    data: {
      accessDateFrom: accessDateFrom,
      accessDateTo: accessDateTo,
      siteId: siteId,
      smartCardId: smartCardId,
      chineseName: chineseName,
      englishName: englishName,
      subContractorId: subContractorId,
      subContractorDesc: subContractorDesc,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 獲取承判商工人支薪記錄表
export const getAcssArTerminationSetupList = async (applicationDate) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getAcssArTerminationSetupList`,
    data: {
      applicationDate: applicationDate,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 獲取終止工人進入地盤申請表----申请日期List
export const getAcssArTerminationSetupListApplyForm = async () => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getAcssArTerminationSetupListApplyForm`,
    data: {},
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 獲取終止工人進入地盤申請表----查询日期List
export const getApplication = async () => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/vps/common/getApplication`,
    data: {},
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 獲取承判商工人支薪記錄表
export const getUpdateData = async (applicationDate) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getUpdateData`,
    data: {
      applicationDate: applicationDate,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 儲存數據----終止工人進入地盤申請表
export const submitDataAcsArTermination = async (sendDataList) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/submitData`,
    data: sendDataList,
  }).then(
    (result) => {
      if (result.data && result.data.code !== 200) {
        alert('請求出錯')
        return null
      }

      return result.data
    },
    (error) => {
      alert('請求出錯')
      return null
    },
  )
}

// 递交數據----終止工人進入地盤申請表
export const updateData = async (sendDataList) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/updateData`,
    data: sendDataList,
  }).then(
    (result) => {
      if (result.data && result.data.code !== 200) {
        alert('請求出錯')
        return null
      }

      return result.data
    },
    (error) => {
      alert('請求出錯')
      return null
    },
  )
}

// 承判商自動轉賬收條
export const getMaintDebitAdviceSearch = async (searChingCriteria, dateFrom, dateTo, vendorId, bankAccount, mpfScheme, recordsCriteria) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/getMaintDebitAdviceSearch`,
    data: {
      searChingCriteria: searChingCriteria,
      dateFrom: dateFrom,
      dateTo: dateTo,
      vendorId: vendorId,
      bankAccount: bankAccount,
      mpfScheme: mpfScheme,
      recordsCriteria: recordsCriteria,
    },
  }).then(
    (result) => {
      return result.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 承判商自動轉賬收條--整個量期和部分量期接口
export const searchGenerateAndPartialBatchReport = async (data) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/searchGenerateAndPartialBatchReport`,
    data: {
      dateFrom: data.dateFrom,
      vendorId: data.vendorId,
      subBatchId: data.subBatchId,
    },
  }).then(
    (result) => {
      return result.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 承判商自動轉賬收條--最後的短糧期(phdateto==pddateto)
export const searchFinalGenerateReport = async (phDateFrom, headerId, vendorId) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/searchFinalGenerateReport`,
    data: {
      dateFrom: phDateFrom,
      headerId: headerId,
      vendorId: vendorId,
    },
  }).then(
    (result) => {
      return result.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 承判商自動轉賬收條--短糧期
export const searchGenerateSubGenerateReport = async (dateFrom, pdDateFrom, pdDateTo, headerId, vendorId) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/searchGenerateSubGenerateReport`,
    data: {
      dateFrom: dateFrom,
      pdDateFrom: pdDateFrom,
      pdDateTo: pdDateTo,
      headerId: headerId,
      vendorId: vendorId,
    },
  }).then(
    (result) => {
      return result.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 递交數據----承判商自動轉賬收條(提交)
export const xuPayrollHeaderBea = async (sendDataList) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/xuPayrollHeaderBea`,
    data: sendDataList,
  }).then(
    (result) => {
      return result.data
    },
    (error) => {
      alert('請求出錯')

      return result.data
    },
  )
}

// 承判商自動轉賬收條(change)
export const updateMaintAdm = async (sendDataList) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/updateMaintAdm`,
    data: sendDataList,
  }).then(
    (result) => {
      return result.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 获取权限列表
export const getMenuList = async (data) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/getMenuList`,
    data: data,
  }).then(
    (result) => {
      return result.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

export default async function $http(url, data = {}, options = {}) {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}` + url,
    data: data || {},
    ...(options || {}),
  }).then(
    (result) => result.data,
    (error) => error,
  )
}

// export const searchCategoryArticle = async (menuId) => {
//     try {
//         // 调用接口
//         const data = async () => {
//             let data = await $http('/othermodules/'+menuId+'/search-category-article', body);
//             return data.data;
//         }
//         return data();
//     } catch (error) {
//         return null;
//     }
// };

export const searchCategoryArticle = async (url) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}` + url,
  }).then(
    (result) => {
      return result.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}

// 模塊2：出席天數编辑界面--remark数据 （分为头部---中间--底部
export const getRemarkView = async (applicationId) => {
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/attendance/report/getRemarkView`,
    data: {
      applicationId: applicationId,
    },
  }).then(
    (result) => {
      return result.data.data
    },
    (error) => {
      alert('請求出錯')
      return []
    },
  )
}
