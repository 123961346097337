import React, { useState, useEffect } from 'react'
import {
  CardActionArea,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Container,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  Divider,
  Box,
  TextField,
  Tooltip,
} from '@mui/material'
import { CDialog } from 'src/components/common'
import MessageInformationForm from 'src/pages/otherDataSetting/MessageInformationForm'
import ContactInformationForm from 'src/pages/otherDataSetting/ContactInformationForm'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useLocation } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import StorageIcon from '@mui/icons-material/Storage'
import AnnouncementIcon from '@mui/icons-material/Announcement'
import SummarizeIcon from '@mui/icons-material/Summarize'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import AssessmentIcon from '@mui/icons-material/Assessment'
import FaxIcon from '@mui/icons-material/Fax'
import EngineeringIcon from '@mui/icons-material/Engineering'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import WebhookIcon from '@mui/icons-material/Webhook'
import CastConnectedIcon from '@mui/icons-material/CastConnected'
import StickyNote2Icon from '@mui/icons-material/StickyNote2'
import moment from 'moment'

import { useTheme } from '@mui/material/styles'
import axios from 'axios'
import Button from 'src/theme/overrides/Button'
//  i18n
import { useTranslation } from 'react-i18next'
import { instance } from 'src/utils/instance'
import * as api from 'src/utils/api/common/index'
import $http from 'src/utils/api/services'
//
// import {routers} from 'src/layouts/dashboard/nav/config'
import { set } from 'lodash'
import CardItem from '../components/homepage/CardItem'
import * as apix from '../utils/api/systemAdministrator/SetupMenuApi'

export default function HomePage() {
  // let history = useHistory();
  const location = useLocation()
  const theme = useTheme()
  const [messageList, setMessageList] = useState([])
  const [newMessage, setNewMessage] = useState([])
  const [messageRow, setMessageRow] = useState([])

  const [messageInformation, setMessageInformation] = React.useState(false)

  const { t, i18n } = useTranslation()
  const itemList = [
    {
      menuCode: 'ICON1',
      title: '地盤出入記錄資料庫',
      enTitle: 'Worker Attendance Record',
      color: 'primary',
      icon: ReceiptLongIcon,
      path: '/workerAttendanceRecord',
    },
    {
      menuCode: 'ICON2',
      title: '承判商自動轉賬狀況',
      enTitle: 'Vendor Autopay System',
      path: '/vendorAutopaySystem',
      icon: RequestQuoteIcon,
      color: 'vendorSystem',
    },
    {
      menuCode: 'ICON3',
      title: '系統管理',
      enTitle: 'System Administrator',
      color: 'SystemAdministrator',
      icon: SettingsSuggestIcon,
      path: '/systemAdministrator',
    },
    {
      menuCode: 'ICON4',
      title: '注意事項',
      enTitle: 'Notification Item',
      icon: AnnouncementIcon,
      color: 'warning',
    },
    {
      menuCode: 'ICON5',
      title: '其他資料庫',
      enTitle: 'Misc.Document',
      icon: StorageIcon,
      color: 'info',
      path: '/otherDataSetting',
    },
    {
      menuCode: 'ICON6',
      title: '投標標書',
      enTitle: 'Tender',
      icon: SummarizeIcon,
      color: 'error',
    },
    {
      menuCode: 'ICON7',
      title: '投標系统管理',
      enTitle: 'Tender System Admin',
      icon: PointOfSaleIcon,
      color: 'TenderSys',
    },
    {
      menuCode: 'ICON8',
      title: '投標系统报告',
      enTitle: 'Tender System Report',
      icon: AssessmentIcon,
      color: 'TenderReport',
    },
    {
      menuCode: 'ICON9',
      title: '訂單發送系統',
      enTitle: 'PODS',
      icon: FaxIcon,
      color: 'pods',
    },
    {
      menuCode: 'ICON10',
      title: '額外工程控制',
      enTitle: 'WRPRS',
      icon: EngineeringIcon,
      color: 'wrprs',
    },
    {
      menuCode: 'ICON11',
      title: '糧款申請',
      enTitle: 'VPAS',
      icon: AddBusinessIcon,
      color: 'vpas',
    },
    {
      menuCode: 'ICON12',
      title: '維修組標書(網上回標)',
      enTitle: 'Maintenance Tender Invitations',
      icon: CastConnectedIcon,
      color: 'vendorSystem',
    },
    {
      menuCode: 'ICON13',
      title: '鋼鐵系統',
      enTitle: 'REBAR',
      icon: WebhookIcon,
      color: 'vendorSystem',
    },
    {
      menuCode: 'ICON14',
      title: '其他功能',
      enTitle: 'Other Function',
      icon: RequestQuoteIcon,
      color: 'vendorSystem',
    },
    {
      menuCode: 'ICON15',
      title: '收樓系統',
      enTitle: 'Property Hand-over System',
      icon: LocationCityIcon,
      color: 'vendorSystem',
    },
    {
      menuCode: 'ICON16',
      title: 'ESG',
      enTitle: 'ESG System',
      icon: StickyNote2Icon,
      color: 'vendorSystem',
    },
  ]
  const [routeList, setRouteList] = useState([])

  useEffect(() => {
    const getData = async () => {
      let menus = await $http('/getMenuList')
      if (typeof menus !== 'undefined') {
        for (const item of menus.data) {
          for (const localItem of itemList)
            if (item.key === localItem.menuCode) {
              item.icon = localItem.icon
              item.color = localItem.color
            }
        }
        setRouteList([...menus.data])
      }

      let message = await apix.searchNoticList()
      if (message) {
        setMessageList(message)
      }

      // let routeListApi = await api.getAllMenu();
      // for (let item of routeListApi) {
      //   for (let localItem of itemList)
      //     if (item.menuCode === localItem.menuCode) {
      //       item.icon = localItem.icon
      //       item.color = localItem.color
      //     }
      // }
      // console.log("item", routeListApi)
      // setRouteList([...routeListApi])
    }
    getData()
  }, [])

  const handleTest = (e) => {}
  const handleMessageInformation = () => {
    setMessageInformation(true)
  }

  return (
    <Container maxWidth="false">
      <Typography variant="h5" style={{ marginBottom: '10px' }}>
        <b>{t('homepage_title')}</b>
      </Typography>
      {routeList.map((elementItem, index) => (
        <CardItem elementItem={elementItem} key={index} />
      ))}
      <Card sx={{ minHeight: '420px' }}>
        <CardHeader
          sx={{
            backgroundColor: theme.palette.primaryBackgroundColor,
          }}
          avatar={
            <Avatar
              variant="square"
              style={{ marginBottom: '10px', marginTop: '-10px' }}
              // src={process.env.WEB_URL+ '/images/5f3e1b8c57d870c33bff9ef4_news.png'}
            />
          }
          title={
            <Typography
              variant="h5"
              style={{
                color: '#ffffff',
                marginBottom: '10px',
                marginTop: '-10px',
              }}
            >
              {t('homepage_notice')}
            </Typography>
          }
        />

        {!!messageList && messageList.length > 0 && (
          <CardContent style={{ marginTop: '-15px' }}>
            {messageList?.map((row, ind) => (
              <Tooltip title={row.messageDesc} key={ind}>
                <CardActionArea
                  onClick={() => {
                    setMessageInformation(true)
                    setNewMessage(row.messageDesc)
                    setMessageRow(row)
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'left',
                      height: '30px',
                    }}
                  >
                    <ArrowForwardIcon />

                    <Typography gutterBottom style={{ fontSize: '18px', whiteSpace: 'nowrap' /* 不换行 */, overflow: 'hidden' /* 隐藏溢出的内容 */, textOverflow: 'ellipsis' }}>
                      [{moment(row.dateFrom, 'YYYY/MM/DD hh:mm:ss').format('YYYY/MM/DD')}]{' ' + row.messageDesc}
                    </Typography>
                  </div>
                </CardActionArea>
              </Tooltip>
            ))}
          </CardContent>
        )}
      </Card>
      <CDialog title={t('latest_notices')} open={messageInformation} onClose={() => setMessageInformation(false)}>
        <MessageInformationForm content={newMessage} data={messageRow} />
      </CDialog>
    </Container>
  )
}
