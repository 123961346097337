import { instance } from '../../instance'

export const maintWorkerAccountlist = async () => {
  let data = {}
  // data.userId = "kfwgvz";
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/MaintWorkerAccountlist`,
    data,
  }).then(
    (result) => result.data,
    (error) => error,
  )
}

export const updateMaintWorkerAccount = async (data) => {
  // let data = {};
  // data.userId = "kfwgvz";
  return instance({
    method: 'POST',
    url: `${process.env.REACT_APP_WEB_URL}/autoPayController/updateMaintWorkerAccount`,
    data,
  }).then(
    (result) => result.data,
    (error) => error,
  )
}
