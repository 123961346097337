import { useState } from 'react';
// @mui
// import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton, Popover, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import $http from 'src/utils/api/services'

const LANGS = [
  {
    value: 'en',
    label: 'English',
    icon: '/assets/icons/ic_flag_en.svg',
  },
  {
    value: 'de',
    label: 'German',
    icon: '/assets/icons/ic_flag_de.svg',
  },
  {
    value: 'fr',
    label: 'French',
    icon: '/assets/icons/ic_flag_fr.svg',
  },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const [open, setOpen] = useState(null);
  const [lang, setLang] = useState(true);

  const { t, i18n } = useTranslation();
  const theme = useTheme();
  // document.body.dir = i18n.dir();

  const postLanguage = async (lang) => {
    await $http('/changeLanguage', { "locale": lang })
  }

  const changeLanguage = () => {
    console.log(lang);
    if (lang) {
      i18n.changeLanguage("en-US")
      document.body.dir = i18n.dir();
      theme.direction = i18n.dir();
      localStorage.setItem("lang", "en-US")
      postLanguage("en-US")
    } else {
      i18n.changeLanguage("zh-CN")
      document.body.dir = i18n.dir();
      theme.direction = i18n.dir();
      localStorage.setItem("lang", "zh-CN")
      postLanguage("zh-CN")
    }
    setLang(!lang)
  }
  // const handleOpen = (event) => {
  //   setOpen(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setOpen(null);
  // };

  return (
    <>
      <IconButton
        onClick={changeLanguage}
        sx={{
          padding: 0,
          width: 44,
          // height: 44,
          // ...(open && {
          //   bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          // }),
        }}
      >
        <Typography style={{ fontSize: '15px', color: '#ffffff' }}>
          中\En
        </Typography>
        {/* <img src={LANGS[0].icon} alt={LANGS[0].label} /> */}
      </IconButton>

      {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem key={option.value} selected={option.value === LANGS[0].value} onClick={() => handleClose()}>
              <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover> */}
    </>
  );
}
