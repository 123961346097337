import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Autocomplete,
  Container,
  Grid,
  FormControl,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  MenuItem,
  TableFooter,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import moment from 'moment'
import $http, { $download } from 'src/utils/api/services'

import * as api from '../../../utils/api/vendorAutopaySystem/RptEstimateEmployerMPFSearchApi'

export default function RptEstimateEmployerMPFSearch() {
  const [isSearchPage, setIsSearchPage] = useState(true)
  // 國際化
  const { t } = useTranslation()

  const [backOpen, setBackOpen] = useState(false)
  const [siteList, setSiteList] = useState([])
  const [dateTo, setDateTo] = useState([])
  const [dateFrom, setDateFrom] = useState([])
  const [sendData, setSendData] = useState({})
  const [tableData, setTableData] = useState([])
  const [vendorIdList, setVendorIdList] = useState([])

  useEffect(() => {
    const getData = async () => {
      let getVendorIdList = await api.getVendorIdList()
      let getSiteList = await api.getSiteList()
      let getDateFrom = await api.getDateFrom()
      let getDateTo = await api.getDateTo()
      if (getDateFrom) {
        setDateFrom(getDateFrom)
      }
      if (getDateTo) {
        setDateTo(getDateTo)
      }

      if (getVendorIdList) {
        setVendorIdList(getVendorIdList)
      }

      if (getSiteList) {
        getSiteList.splice(0, 0, { id: '', text: 'ALL' })
        setSiteList(getSiteList)
      }
      // if (getSmartCard) {
      //   setSmartCard(getSmartCard)
      // }
      setBackOpen(false)
    }
    getData()
  }, [])

  const handleBack = () => {
    setIsSearchPage(true)
  }

  const handleSiteId = (e, data) => {
    if (data !== null) {
      setSendData({ ...sendData, siteId: data.id, site: data })
    } else {
      setSendData({ ...sendData, siteId: null, site: null })
    }
  }

  const handleVendorId = (e, data) => {
    if (data !== null) {
      setSendData({
        ...sendData,
        vendorId: data.id,
        vendorDesc: data.text,
        vendor: data,
      })
    } else {
      setSendData({
        ...sendData,
        vendorId: null,
        vendorDesc: null,
        vendor: null,
      })
    }
  }

  const handleDate = (e) => {
    setSendData({ ...sendData, date: e.target.value })
  }

  const handleDateFrom = (e) => {
    setSendData({ ...sendData, dateFrom: e.id, dateFromDesc: e.text })
  }

  const handleDateTo = (e) => {
    setSendData({ ...sendData, dateTo: e.id, dateToDesc: e.text })
  }

  const handleEstimateDailyEmployerMPF = (e) => {
    setSendData({ ...sendData, estimateDailyEmployerMPF: e.target.value })
  }

  const handleOrderBy = (e) => {
    setSendData({ ...sendData, orderBy: e.target.value })
  }

  const handleAscOrder = (e) => {
    setSendData({ ...sendData, ascOrder: e.target.value })
  }

  const handleOrderType = (e) => {
    setSendData({ ...sendData, ascOrder: e.target.value === 'ASC' })
  }

  const handleDownloadType = (e) => {
    setSendData({ ...sendData, downloadType: e.target.value })
  }

  const handleSubmit = (e) => {
    if (!sendData.vendorId) {
      alert('請選擇承判商')
      return
    } else if (!sendData.dateFrom || !sendData.dateTo) {
      alert('請選擇日期')
      return
    } else if (!sendData.estimateDailyEmployerMPF) {
      alert('請輸入估計日計僱主強積金')
      return
    }
    const getData = async () => {
      setBackOpen(true)
      let send = await api.getReport(sendData)
      if (send) {
        setIsSearchPage(false)
        setTableData(send)
      }
      setBackOpen(false)
    }
    getData()
  }

  const resetForm = (e) => {
    setSendData({})
  }

  const exportExcel = (data) => {
    $download(`/payrollReportController/getRptEstimateEmployerMPFListExcel`, 'application/vnd.ms-excel', data)
  }

  const exportPDF = (data) => {
    $download(`/payrollReportController/getRptEstimateEmployerMPFListPdf`, 'application/pdf', data)
  }

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {isSearchPage && (
        <form>
          <Container maxWidth="md">
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              {/* 估計僱主強積金分析報告 */}
              <Grid item xs={12}>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                  <b>{t('icon1_estimated_employer_MPF_analysis_report_title')}</b>
                </Typography>
              </Grid>

              <Grid item xs={12} container justifyContent="center">
                <Autocomplete
                  sx={{ width: '400px' }}
                  value={sendData.vendor || null}
                  onChange={handleVendorId}
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  getOptionLabel={(option) => {
                    return option.text
                  }}
                  options={vendorIdList}
                  renderInput={(params) => <TextField error={!sendData.vendorId} fullWidth size="small" {...params} label={t('icon1_personalentersearch_sub_condition')} />}
                />
              </Grid>

              <Grid item xs={12} container justifyContent="center">
                <TextField sx={{ width: '400px' }} id="dateFrom" value={sendData.dateFrom || ''} fullWidth size="small" label={t('icon1_personalentersearch_from_date_condition')} select>
                  <MenuItem value="">ALL</MenuItem>
                  {dateFrom.map((item, index) => (
                    <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleDateFrom(item)}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} container justifyContent="center">
                <TextField
                  sx={{ width: '400px' }}
                  error={!sendData.dateTo}
                  id="dateTo"
                  value={sendData.dateTo || ''}
                  fullWidth
                  size="small"
                  label={t('icon1_personalentersearch_to_date_condition')}
                  select
                >
                  <MenuItem value="">ALL</MenuItem>
                  {dateTo.map((item, index) => (
                    <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleDateTo(item)}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} container justifyContent="center">
                <Autocomplete
                  sx={{ width: '400px' }}
                  value={sendData.site || null}
                  onChange={handleSiteId}
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  getOptionLabel={(option) => {
                    return option.text
                  }}
                  options={siteList}
                  renderInput={(params) => <TextField fullWidth size="small" {...params} label={t('icon1_autopay_setSiteUserMapping_tableLabel1')} />}
                />
              </Grid>

              <Grid item xs={12} container justifyContent="center">
                <TextField
                  sx={{ width: '400px' }}
                  error={!sendData.estimateDailyEmployerMPF}
                  value={sendData.estimateDailyEmployerMPF || ''}
                  fullWidth
                  size="small"
                  label={t('icon1_estimated_employer_daily_employer_MPF')}
                  onChange={handleEstimateDailyEmployerMPF}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <FormControl>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                      backgroundColor: 'primaryBlue',
                      color: 'commom.white',
                    }}
                  >
                    {t('button_submit')}
                  </Button>
                </FormControl>
                <FormControl sx={{ marginLeft: '20px' }}>
                  <Button
                    variant="contained"
                    onClick={resetForm}
                    sx={{
                      backgroundColor: 'primaryGreen',
                      color: 'commom.white',
                      marginRight: '10px',
                    }}
                    label="Reset"
                  >
                    {t('button_reset')}
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Container>
        </form>
      )}

      {!isSearchPage && (
        <Container maxWidth={false}>
          <div
            style={{
              display: 'block',
              textAlign: 'left',
              marginTop: '0px',
            }}
          >
            <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
              <ArrowBackIcon />
              {t('button_back')}
            </Button>
          </div>

          <div style={{ display: 'block', textAlign: 'right' }}>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportExcel(sendData)}>
              <DownloadIcon />
              {t('button_export_excel')}
            </Button>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportPDF(sendData)}>
              <DownloadIcon />
              {t('button_export_pdf')}
            </Button>
          </div>
          <div>
            <Typography variant="h4" style={{ textAlign: 'center', mt: '-30px' }}>
              <b>{t('icon1_estimated_employer_MPF_analysis_report_title')}</b>
            </Typography>
            <TableContainer>
              <Table sx={{ textAlign: 'left' }} size="small">
                <TableBody>
                  <TableRow>
                    {/* 製作日期： */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      {t('date_of_production')}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{moment(new Date().getTime()).format('yyyy/MM/DD')}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 判頭： */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      {t('judgment_head')}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.vendorDesc}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 日期（由）： */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      {t('date_from')}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{moment(sendData.dateFrom).format('yyyy/MM')}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 日期（至）： */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        display: 'block',
                        borderBottom: 'none',
                      }}
                    >
                      {t('date_to')}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{moment(sendData.dateTo).format('yyyy/MM')}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 地盤： 	 */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        display: 'block',
                        borderBottom: 'none',
                      }}
                    >
                      {t('icon1_autopay_setSiteUserMapping_tableLabel1')}:
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.site?.text || 'All'}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 估計日計僱主強積金： */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        display: 'block',
                        borderBottom: 'none',
                      }}
                    >
                      {t('icon1_estimated_employer_daily_employer_MPF')}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>
                      <Typography sx={{ display: 'block', fontSize: '15px' }}>{sendData.estimateDailyEmployerMPF}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer sx={{ maxHeight: '80vh', maxWidth: '100vw' }}>
              <Table size="small" sx={{ mt: '20px' }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('icon1_useraccesslog_eng_name_label')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('icon1_useraccesslog_smart_card_label')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                        textAlign: 'right',
                      }}
                    >
                      {t('Worker_income')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                        textAlign: 'right',
                      }}
                    >
                      {t('Actual_number_of_working_days')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                        textAlign: 'right',
                      }}
                    >
                      {t('Employer_MPF')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                        textAlign: 'right',
                      }}
                    >
                      {t('Estimated_daily_employer_s_MPF')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                        textAlign: 'right',
                      }}
                    >
                      {t('Employer_s_MPF_callback')}
                    </TableCell>
                  </TableRow>
                </TableHead>

                {!!tableData && tableData.mainData.length > 0 && (
                  <TableBody>
                    {tableData.mainData.map((row, ind) => (
                      <>
                        <TableRow
                          hover
                          key={ind}
                          sx={{
                            backgroundColor: ind % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                          }}
                        >
                          <TableCell>{row.englishName}</TableCell>
                          <TableCell>{row.cardHolderId}</TableCell>
                          <TableCell sx={{ textAlign: 'right' }}>{row.relevantIncome.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'right' }}>{row.workDayAct}</TableCell>
                          <TableCell sx={{ textAlign: 'right' }}>{row.employerMpf.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'right' }}>{row.estimatedMpf.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'right' }}>{row.employerMpfClaim.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                        </TableRow>
                        {tableData.mainData.length - 1 === ind && (
                          <TableRow>
                            <TableCell
                              sx={{
                                wordBreak: 'keep-all',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {t('total')}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{tableData.sumData.workerNum.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{tableData.sumData.relevantIncome.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{tableData.sumData.workDayAct.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{tableData.sumData.employerMpf.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{tableData.sumData.estimatedMpf.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{tableData.sumData.employerMpfClaim.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
                  </TableBody>
                )}

                {(!tableData || tableData.mainData.length === 0) && (
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ textAlign: 'center' }} colSpan={10}>
                        {'No data found !'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        </Container>
      )}
    </>
  )
}
