import { instance } from "../../instance";

// System Parameter Setup - 顯示
export const selectLists = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/managementController/adminSysParaDataList`,
        data: data,
    }).then(
        (result) => result.data,
        (error) => error
    );
};
// System Parameter Setup - 新建
export const insertData = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/managementController/adminSysParaDataNew`,
        data: data,
    }).then(
        (result) => result.data,
        (error) => error
    );
};
// System Parameter Setup - 修改
export const updateData = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/managementController/adminSsysParaDataEdit`,
        data: data,
    }).then(
        (result) => result.data,
        (error) => error
    );
};
// System Parameter Setup - 刪除
export const deleteData = async (data) => {
    return instance({
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_URL}/managementController/adminSsysParaDataDelete`,
        data: data,
    }).then(
        (result) => result.data,
        (error) => error
    );
};