import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  MenuItem,
  FormGroup,
  Checkbox,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, createSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InputDropDown from "src/components/use-form/InputDropDown";
import { useForm } from "react-hook-form";
import CustomDialog from "src/components/common/CustomDialog";
import moment from "moment" ;
import * as apiAtt from "src/utils/api/workerAttendanceRecord/AutoPayApiAttend";

// 承判商自動轉賬收條---搜索界面
export default function CarSearch() {
  const navigate = useNavigate();
  const componentRef = useRef();
  const { t } = useTranslation();
  const [vendorId, setVendorId] = useState("");
  const [vendorDesc, setVendorDesc] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [reportType, setReportType] = useState("1");
  const [loading, setLoading] = React.useState(false);
  const [vendorIdList, setVendorIdLis] = useState([]);
  const [mpfScheme, setMpfScheme] = useState("");
  const [shortPeriodDateFrom, setShortPeriodDateFrom] = useState("");
  const [shortPeriodDateTo, setShortPeriodDateTo] = useState("");
  const [lastMonthDate,setLastMonthDate] = useState()
  const [chooseCheckbox1, setChooseCheckbox1] = useState("Y");
  const [chooseCheckbox2, setChooseCheckbox2] = useState("Y");
  const [chooseCheckbox3, setChooseCheckbox3] = useState("Y");
  const [chooseCheckbox4, setChooseCheckbox4] = useState("Y");

  useEffect(() => {}, []);

  const handleBack = () => {
    navigate(-1);
  };

  const { control, handleSubmit, setValue, getValues, reset } = useForm({
    defaultValues: {
      subContract: "",
      dateFrom: "",
      dateTo: "",
      outputType: "",
    },
  });

  const handleCheckbox1 = () => {
    setChooseCheckbox1(chooseCheckbox1 === "Y" ? "N" : "Y");
  };

  const handleCheckbox2 = () => {
    setChooseCheckbox2(chooseCheckbox2 === "Y" ? "N" : "Y");
  };

  const handleCheckbox3 = () => {
    setChooseCheckbox3(chooseCheckbox3 === "Y" ? "N" : "Y");
  };

  const handleCheckbox4 = () => {
    setChooseCheckbox4(chooseCheckbox4 === "Y" ? "N" : "Y");
  };

  const resetForm = (data) => {
    // setReportType("0")
    setShortPeriodDateFrom(shortPeriodDateFrom);
    setShortPeriodDateTo(shortPeriodDateTo);
    setVendorId("");
    setVendorDesc("");
    setBankAccount("");
    setMpfScheme("");
    setChooseCheckbox1("Y");
    setChooseCheckbox2("Y");
    setChooseCheckbox3("Y");
    setChooseCheckbox4("Y");
  };

 
  const fromLastMonth = () => {
    let date = new Date();
    let lastMonthDate = "";
    let day = date.getDate();
    if(day < 10){
      day = "0"+day;
    }
    let month = date.getMonth();
    let year = date.getFullYear();
    
    if(month < 1){
      lastMonthDate = year-1 + '/12/' + day;
    }else{
      // 月份小于10则补0
      if(month < 10){
        month = "0"+month;
      }
      lastMonthDate = year + '/' + month + '/' + day;
    }
    return lastMonthDate;
  }

  const [dialog, setDialog] = useState({
    title: "",
    content: "",
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false });
        }}
      >
        {t("Confirm")}
      </Button>
    ),
    open: false,
  });

  const showDetailResult = (data) => {
    if (reportType === "2") {
      if (!shortPeriodDateFrom) {
        setDialog({
          ...dialog,
          content:
            t("search_chooseDatetips") + "(" + t("search_from_tips") + ")",
          open: true,
        });
        return;
      }
      if (!shortPeriodDateTo) {
        setDialog({
          ...dialog,
          content: t("search_chooseDatetips") + '(' + t("search_to_tips") + ')',
          open: true,
        });
        return;
      }
    }

    navigate({
      pathname: '/systemAdministrator/carSearch/carSearchDetail',
      search: `?${createSearchParams({
        'searChingCriteria': reportType === '2' ? "1" : "0",
        'accessDateFrom': shortPeriodDateFrom,
        'accessDateTo': shortPeriodDateTo,
        'vendorId': vendorId,
        'vendorDesc': vendorDesc,
        'bankAccount': bankAccount,
        'mpfScheme': mpfScheme,
        "recordsCriteria": getRecordsCriteria(),
      }, { state: { fromSpecificPage: true } })}`
    })

  }

  const getRecordsCriteria = () => {
    let recordsCriteria = "";

    if (chooseCheckbox1 === "Y") {
      recordsCriteria += "1";
    }
    if (chooseCheckbox2 === "Y") {
      recordsCriteria += "2";
    }
    if (chooseCheckbox3 === "Y") {
      recordsCriteria += "3";
    }
    if (chooseCheckbox4 === "Y") {
      recordsCriteria += "4";
    }

    return recordsCriteria;
  };

  // 选了地盘后，需要请求分判商
  const handleVendorId = (data) => {
    setVendorId(data.id);
    setVendorDesc(data.text);
  };

  const handleChangeReportType = (event) => {
    setReportType(event.target.value);
  };
  
  useEffect(() => {
    const ggetvendorId = async () => {
      let ggetvendorIdSelect = await apiAtt.getvendorId();
      if (ggetvendorIdSelect) {
        const result = ggetvendorIdSelect;
        setVendorIdLis(result);
      }
      
      // setLastMonthDate(fromLastMonth())
      setShortPeriodDateFrom(fromLastMonth())
      setShortPeriodDateTo(moment(new Date()).format("YYYY/MM/DD"))

      console.log("ShortPeriodDateFrom",shortPeriodDateFrom)
    };

    ggetvendorId();
  }, []);

  return (
    <>
      {
        <>
          <div style={{ display: "block", textAlign: "left" }}>
            <Button sx={{ float: "left" }} onClick={handleBack}>
              <ArrowBackIcon />
              {t("button_back")}
            </Button>
          </div>
          <div id="div" ref={componentRef}>
            <Container>
              <div>
                <div style={{ display: "block" }}>
                  <Typography
                    variant="h4"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    <b>{t("search_vendor_autoPay_receipt")}</b>
                  </Typography>

                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>

                  <Container
                    sx={{
                      width: "500px",
                      borderColor: "#d3d3d3",
                      borderRadius: 4,
                    }}
                  >
                    <RadioGroup
                      // row表示横向排列
                      row
                      aria-labelledby="contentRadio"
                      name="row-radio-buttons-group"
                      value={reportType}
                      onChange={handleChangeReportType}
                    >
                      <div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <FormControl>
                          <FormControlLabel
                            sx={{ marginleft: "12px" }}
                            value="1"
                            control={<Radio />}
                            label={t("search_all_not_receipt_file")}
                          />
                        </FormControl>
                      </div>

                      <div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <FormControl>
                          <FormControlLabel
                            sx={{ marginleft: "12px" }}
                            value="2"
                            control={<Radio />}
                            label={t("search_list_data_by_chooseItem")}
                          />
                        </FormControl>
                      </div>
                    </RadioGroup>

                    {/* 自动转账日期 由 */}
                    <Grid item xs={6} sx={{ margin: "auto", width: "400px" }}>
                      <TextField
                        type="date"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        sx={{
                          textAlign: "left",
                          maxWidth: "400px",
                          mt: "20px",
                        }}
                        label={t("search_autoPay_date_from")}
                        error={
                          reportType === "2" ? !shortPeriodDateFrom : false
                        }
                        disabled={reportType === "2" ? null : true}
                        inputProps={{
                          max: "2099-12-31",
                        }}
                        value={moment(new Date(shortPeriodDateFrom)).format("yyyy-MM-DD")}
                       // value={shortPeriodDateFrom}
                        onChange={(event) => {
                          setShortPeriodDateFrom(event.target.value);
                          console.log("setShortPeriodDateFrom",shortPeriodDateFrom)
                        }}
                        format="yyyy/MM/dd"
                      />
                    </Grid>

                    {/* 自动转账日期 至 */}
                    <Grid item xs={6} sx={{ margin: "auto", width: "400px" }}>
                      <TextField
                        type="date"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        sx={{
                          textAlign: "left",
                          maxWidth: "400px",
                          mt: "20px",
                        }}
                        label={t("search_autoPay_date_to")}
                        error={reportType === "2" ? !shortPeriodDateTo : false}
                        disabled={reportType === "2" ? null : true}
                        inputProps={{
                          max: "2099-12-31",
                        }}
                        value={moment(new Date(shortPeriodDateTo)).format("yyyy-MM-DD")}
                        onChange={(event) => {
                          setShortPeriodDateTo(event.target.value);
                          console.log("setShortPeriodDateTo",shortPeriodDateTo)
                        }}
                        format="yyyy/MM/dd"
                      />
                    </Grid>

                    {/* 承判商 */}
                    <Grid
                      item
                      xs={6}
                      sx={{ margin: "auto", width: "400px", marginTop: "10px" }}
                    >
                      <TextField
                        disabled={reportType === "2" ? null : "true"}
                        id="vendorDesc"
                        value={vendorId || ""}
                        fullWidth
                        size="small"
                        label={t("Vendor")}
                        select
                      >
                        {vendorIdList.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item.id}
                            name={item.text}
                            onClick={() => handleVendorId(item)}
                          >
                            {item.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    {/* 銀行戶口號碼 */}
                    <Grid item xs={6} sx={{ margin: "auto", width: "400px" }}>
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        sx={{
                          textAlign: "left",
                          maxWidth: "400px",
                          mt: "20px",
                        }}
                        label={t("icon1_autopay_button8_print_label3")}
                        // 設置了value可以進行重置
                        value={bankAccount}
                        disabled={reportType === "2" ? null : "true"}
                        onChange={(event) => {
                          setBankAccount(event.target.value);
                        }}
                      />
                    </Grid>

                    {/* 計劃成員編號 */}
                    <Grid item xs={6} sx={{ margin: "auto", width: "400px" }}>
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        sx={{
                          textAlign: "left",
                          maxWidth: "400px",
                          mt: "20px",
                        }}
                        label={t("plan_Member_Number")}
                        disabled={reportType === "2" ? null : "true"}
                        // 設置了value可以進行重置
                        value={mpfScheme}
                        onChange={(event) => {
                          setMpfScheme(event.target.value);
                        }}
                      />
                    </Grid>

                    <FormGroup aria-label="position" row={true}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <FormControlLabel
                        labelPlacement="end"
                        control={
                          <Checkbox
                            disabled={reportType === "2" ? null : "true"}
                            s
                            checked={chooseCheckbox1 === "Y"}
                            size="small"
                            onClick={() => handleCheckbox1()}
                          />
                        }
                        label={t("search_only_mpf_receipt")}
                      />
                      <br />
                    </FormGroup>

                    <FormGroup aria-label="position" row={true}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <FormControlLabel
                        labelPlacement="end"
                        control={
                          <Checkbox
                            disabled={reportType === "2" ? null : "true"}
                            checked={chooseCheckbox2 === "Y"}
                            size="small"
                            onClick={() => handleCheckbox2()}
                          />
                        }
                        label={t("search_only_income_receipt")}
                      />
                    </FormGroup>

                    <FormGroup aria-label="position" row={true}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <FormControlLabel
                        labelPlacement="end"
                        control={
                          <Checkbox
                            disabled={reportType === "2" ? null : "true"}
                            checked={chooseCheckbox3 === "Y"}
                            size="small"
                            onClick={() => handleCheckbox3()}
                          />
                        }
                        label={t("search_both_not_choose")}
                      />
                      <FormControlLabel
                        labelPlacement="end"
                        control={
                          <Checkbox
                            disabled={reportType === "2" ? null : "true"}
                            checked={chooseCheckbox4 === "Y"}
                            size="small"
                            onClick={() => handleCheckbox4()}
                          />
                        }
                        label={t("search_both_all_choose")}
                      />
                    </FormGroup>
                  </Container>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "18px",
                }}
              >
                {/* 提交按钮 */}
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <FormControl size="small">
                    <Button
                      sx={{
                        backgroundColor: "primaryBlue",
                        color: "commom.white",
                      }}
                      variant="contained"
                      onClick={handleSubmit(showDetailResult)}
                    >
                      {t("button_submit")}
                    </Button>
                  </FormControl>

                  {/* 重置按钮 */}
                  <FormControl size="small">
                    <Button
                      variant="contained"
                      onClick={resetForm}
                      label="Reset"
                      sx={{
                        backgroundColor: "primaryGreen",
                        color: "commom.white",
                        marginLeft: "20px",
                      }}
                    >
                      {t("button_reset")}
                    </Button>
                  </FormControl>
                </Grid>
              </div>
            </Container>
          </div>
        </>
      }

      <CustomDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        action={dialog.action}
      />
    </>
  );
}
