import React, { useState, useRef, useEffect, useMemo } from "react";
import { Button, Container, Backdrop, CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
//  i18n
import { useTranslation } from 'react-i18next';
// component
import { instance } from 'src/utils/instance';
// api
import * as AutoPayApi from "src/utils/api/workerAttendanceRecord/AutoPayApi"
import Main from "src/components/icon/icon1/autoPay/Main";
import SetSiteUserMapping from "src/components/icon/icon1/autoPay/SetSiteUserMapping";
import MaintWorkerAccount from "src/components/icon/icon1/autoPay/MaintWorkerAccount";
import MaintWorkerAutopayMainCreateSearch from "src/components/icon/icon1/autoPay/MaintWorkerAutopayMainCreateSearch";
import UpdateWorkerMPF from "src/components/icon/icon1/autoPay/updateWorkerMPF/UpdateWorkerMPF";
import CreateAutopayFiles from "src/components/icon/icon1/autoPay/createAutoPayFile/CreateAutopayFiles";
import OneBatch from "src/components/icon/icon1/autoPay/OneBatch";
import DistributeSubBatch from "src/components/icon/icon1/autoPay/DistributeSubBatch";
import DistributePartialBatch from "src/components/icon/icon1/autoPay/DistributePartialBatch";
import SearchScheduleAutopayFile from "src/components/icon/icon1/autoPay/searchScheduleAutopayFile/SearchScheduleAutopayFile";
import ReactToPrint from "react-to-print";

export default function HomePage() {
    // let history = useHistory();
    const { t } = useTranslation();
    const [type, setType] = useState(0)
    const [step, setStep] = useState([])
    const [data, setData] = useState({});
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getFlow = async () => {
            setLoading(true)
            setOpen(true)
            let getMaintworkerautopaymainflow = await AutoPayApi.getMaintworkerautopaymainflow()
            if (getMaintworkerautopaymainflow) {
                setData(getMaintworkerautopaymainflow)
            }
            setOpen(false)
            setLoading(false)
        }
        if (type === 0) {
            getFlow()
        }
        setStep([])
    }, [type])

    const SelectComponent = useMemo(() => {
        switch (type) {
            case 0: return <Main loading={loading} setOpen={setOpen} open={open} data={data} type={type} setType={setType} />
            case 1: return <SetSiteUserMapping type={type} setType={setType} />
            case 2: return <MaintWorkerAccount type={type} setType={setType} />
            case 3: return <MaintWorkerAutopayMainCreateSearch type={type} setType={setType} />
            case 4: return <OneBatch type={type} setType={setType} />
            case 5: return <DistributeSubBatch setType={setType} />
            case 6: return <DistributePartialBatch setType={setType} />
            case 7: return <UpdateWorkerMPF data={data} type={type} setType={setType} />
            case 8: return <CreateAutopayFiles data={data} step={step} type={type} setType={setType} />
            case 9: return <SearchScheduleAutopayFile data={data} step={step} type={type} setType={setType} />
            default:
                return <Main data={data} type={type} setType={setType} />
        }
    }, [data, type, step, setStep, setType])

    return (
        <>
            <Backdrop sx={{ color: "#fff", zIndex: 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {SelectComponent}
        </>
    );
}
