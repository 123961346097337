import { instance } from "../../instance";

// 用戶信息
export const getUser = async (username) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/userController/user/${username}/select-detail`,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 角色list
export const getRoleList = async () => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/userController/role/select-all`,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 用戶list 模糊查詢
export const getUserListWithRoles = async (securityRoleTable) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/userController/userView`,
    data: securityRoleTable,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 用戶list 模糊查詢
export const getUserListAll = async (securityRoleTable) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/userController/userviewall`,
    data: securityRoleTable,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 權限list
export const userSelectByUser = async (securityRoleTable) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/userController/role/select-byuser`,
    data: securityRoleTable,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 權限list
export const userSelectByUserCovert = async (securityRoleTable) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/userController/role/select-byusercovert`,
    data: securityRoleTable,
  }).then(
    (result) => {
      return result.data.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
// 新增
export const addRoleList = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/userController/role/add-rolelistwithuser`,
    data: data,
  }).then(
    (result) => {
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 刪除
export const sendDelete = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/userController/userMappingDelete`,
    data: data,
  }).then(
    (result) => {
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};

// 編輯
export const sendEdit = async (data) => {
  return instance({
    method: "POST",
    url: `${process.env.REACT_APP_WEB_URL}/userController/user/update`,
    data: data,
  }).then(
    (result) => {
      return result.data;
    },
    (error) => {
      // alert("請求出錯")
      return [];
    }
  );
};
