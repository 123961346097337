import { React, useState, useEffect } from 'react'
import {
  Button,
  Typography,
  Container,
  Grid,
  CssBaseline,
  Backdrop,
  CircularProgress,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { CDialog, CDelay } from 'src/components/common'
import CustomDialog from 'src/components/common/CustomDialog'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import Scrollbar from '../../../components/scrollbar'
import NavSection from '../../../components/nav-section'

const NAV_WIDTH = 280
export default function FAQs() {
  const [backOpen, setBackOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingRest, setLoadingRest] = useState(false)

  const { t } = useTranslation()
  const [faqsType, setFaqsType] = useState('')

  const [dialog, setDialog] = useState({
    content: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        {t('sure')}
      </Button>
    ),
    open: false,
  })

  useEffect(() => {}, [])
  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const handleFAQsType = (text) => {
    if (text === 'Enter/amend-worker_salary/MPF_information') {
      // 輸入/修改工人薪金/強積金資料
      setFaqsType('1')
    }
    if (text === 'search_worker_income_chat') {
      // 工人出入記錄一覽表
      setFaqsType('2')
    }
    if (text === 'icon1_autopay_title') {
      // 製作自動轉賬檔案
      setFaqsType('3')
    }
    console.log('text', text)
  }

  return (
    <CDelay>
      <>
        <Box component="nav" sx={{ flexShrink: { lg: 0 }, width: { lg: NAV_WIDTH } }}>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backOpen}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Drawer open variant="permanent" PaperProps={{ sx: { mt: '65px', pb: '65px', width: NAV_WIDTH, bgcolor: 'background.default', borderRightStyle: 'dashed' } }}>
            <List>
              {['Enter/amend-worker_salary/MPF_information', 'search_worker_income_chat', 'icon1_autopay_title'].map((text, index) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton>
                    <ListItemText
                      primary={t(text)}
                      onClick={() => {
                        handleFAQsType(text)
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <CustomDialog sx={{ whiteSpace: 'pre-line' }} open={dialog.open} title={dialog.title} content={dialog.content} action={dialog.action} />
        <Container component="main">
          <CssBaseline />
          <Grid item xs={12}>
            <Typography variant="h4" style={{ textAlign: 'center', lineHeight: '2' }}>
              {t('frequently_asked_questions')}
            </Typography>
          </Grid>
          {faqsType === '1' && (
            <Grid>
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                  {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('Enter/amend-worker_salary/MPF_information')}</Typography> */}
                  <Typography sx={{ color: 'text.secondary' }}>{t('question_1')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('answer_1')}</Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                  {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('Enter/amend-worker_salary/MPF_information')}</Typography> */}
                  <Typography sx={{ color: 'text.secondary' }}>{t('question_2')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('answer_2')}</Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
                  {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('Enter/amend-worker_salary/MPF_information')}</Typography> */}
                  <Typography sx={{ color: 'text.secondary' }}>{t('question_3')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('answer_3')}</Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                  {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('Enter/amend-worker_salary/MPF_information')}</Typography> */}
                  <Typography sx={{ color: 'text.secondary' }}>{t('question_4')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('answer_4')}</Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5bh-header">
                  {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('Enter/amend-worker_salary/MPF_information')}</Typography> */}
                  <Typography sx={{ color: 'text.secondary' }}>{t('question_5')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('answer_5')}</Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6bh-content" id="panel6bh-header">
                  {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('Enter/amend-worker_salary/MPF_information')}</Typography> */}
                  <Typography sx={{ color: 'text.secondary' }}>{t('question_6')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('answer_6')}</Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel8bh-content" id="panel8bh-header">
                  {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('Enter/amend-worker_salary/MPF_information')}</Typography> */}
                  <Typography sx={{ color: 'text.secondary' }}>{t('question_8')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('answer_8')}</Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
          {faqsType === '2' && (
            <Grid>
              <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel7bh-content" id="panel7bh-header">
                  {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('search_worker_income_chat')}</Typography> */}
                  <Typography sx={{ color: 'text.secondary' }}>{t('question_7')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('answer_7')}</Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
          {faqsType === '3' && (
            <Grid>
              <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel9bh-content" id="panel9bh-header">
                  {/* <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('search_worker_income_chat')}</Typography> */}
                  <Typography sx={{ color: 'text.secondary' }}>{t('question_9')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('answer_9')}</Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
        </Container>
      </>
    </CDelay>
  )
}
