import React, { useRef, useEffect } from 'react'
import { Container, Typography, Paper, Table, TableBody, TableHead, TableCell, TableContainer, TableRow, Backdrop, CircularProgress } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import $http, { $download } from 'src/utils/api/services'
import DownloadIcon from '@mui/icons-material/Download'

// 承判商工人支薪記錄表---详情界面（acmemw有数据
export default function CwprfDetail() {
  const componentRef = useRef()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [dataSource, setDataSource] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  useEffect(() => {}, [])

  const handleBack = () => {
    navigate(-1)
  }

  const printComponentRef = useRef()

  useEffect(() => {
    getPayrollchecklistList()
  }, [])

  const getDateFrom = () => {
    let dateF = ''
    dateF = searchParams.get('accessDateFrom')
    while (dateF.indexOf('-') >= 0) {
      dateF = dateF.replace('-', '/')
    }
    return dateF
  }

  const getDateTo = () => {
    let dateF = ''
    dateF = searchParams.get('accessDateTo')
    while (dateF.indexOf('-') >= 0) {
      dateF = dateF.replace('-', '/')
    }
    return dateF
  }

  const getPayrollchecklistList = async () => {
    let list = await apiAtt.getPayrollchecklistList(
      getDateFrom(),
      getDateTo(),
      searchParams.get('siteId'),
      searchParams.get('smartCardId'),
      searchParams.get('chineseName'),
      searchParams.get('englishName'),
      searchParams.get('subContractorId'),
      searchParams.get('subContractorDesc'),
    )

    if (list !== null) {
      setDataSource(list)
    }
    setLoading(false)
  }

  const exportPdf = (data) => {
    $download(`/attendance/report/payrollchecklistPDF`, 'application/pdf', data)
  }

  return (
    <Container maxWidth="false">
      <div style={{ display: 'block', textAlign: 'left' }}>
        <Button sx={{ float: 'left' }} onClick={handleBack}>
          <ArrowBackIcon />
          {t('button_back')}
        </Button>
      </div>

      <div style={{ display: 'block', textAlign: 'right' }}>
        <Button
          sx={{ float: 'right', mr: '20px' }}
          onClick={() =>
            exportPdf({
              accessDateFrom: getDateFrom(),
              accessDateTo: getDateTo(),
              siteId: searchParams.get('siteId'),
              siteDesc: searchParams.get('siteDesc'),
              chineseName: searchParams.get('chineseName'),
              englishName: searchParams.get('englishName'),
              smartCardId: searchParams.get('smartCardId'),
              subContractorId: searchParams.get('subContractorId'),
            })
          }
        >
          <DownloadIcon />
          {t('button_export_pdf')}
        </Button>
      </div>

      <div id="div" style={{}} ref={printComponentRef}>
        <Container maxWidth="false">
          <div>
            <div style={{ display: 'block', marginBottom: '60px' }}>
              <Typography variant="h4" sx={{ textAlign: 'center' }}>
                <b>{t('search_vendor_pay_record')}</b>
              </Typography>
            </div>

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>

            {
              <div>
                <TableContainer component={Paper} sx={{ marginBottom: '20px', backgroundColor: 'transparent' }}>
                  <Table sx={{ textAlign: 'left' }} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('search_form_date_dot')}</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{getDateFrom()}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', width: 200, border: 0 }}>{t('search_to_date_dot')}</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{getDateTo()}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('Site_Name') + '：'}</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{searchParams.get('siteDesc')}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ px: '5px', border: 0 }}>{t('search_two_vendor_title')}</TableCell>
                        <TableCell sx={{ px: '5px', border: 0 }}>{searchParams.get('subContractorDesc')}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }
          </div>

          <TableContainer sx={{ marginTop: '20px', maxHeight: '80vh', maxWidth: '100vw' }}>
            <Table sx={{ p: '2px' }} size="small" stickyHeader>
              <TableHead>
                <TableRow hover>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('search_smartcardId')}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('search_chinese_not_dot')}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('search_english_not_dot')}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('search_income_record')}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('search_worker_sign_income')}</TableCell>
                </TableRow>
              </TableHead>

              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>

              {dataSource.map((dataRow, index) => (
                <>
                  <TableRow
                    hover
                    key={dataRow + Math.random}
                    sx={{
                      backgroundColor: index % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                    }}
                  >
                    {/* 智能卡編號 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{dataRow.smartCardId} </TableCell>
                    {/* 中文姓名 */}
                    <TableCell
                      sx={{
                        fontSize: '15px',
                        textAlign: 'left',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {dataRow.chineseName}{' '}
                    </TableCell>
                    {/* 英文姓名 */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{dataRow.englishName} </TableCell>
                    {/* 支薪記錄（請將不適用刪除） */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{t('search_empty_title_desc')} </TableCell>
                    {/* 工人簽收工資* */}
                    <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{}</TableCell>
                  </TableRow>
                </>
              ))}
            </Table>
          </TableContainer>

          {
            <div>
              <Typography sx={{ textAlign: 'left', marginTop: '60px' }}>
                * 此欄必須由工友親身簽名確實。
                <br />
                凡簽收確認者，必須清楚自己是否己實收工資，不得出爾反爾，
                <br />
                否則日後發生勞資糾紛，本公司將作欺詐事件，並即時交由警方處理。
                <br />
              </Typography>
            </div>
          }

          <TableContainer sx={{ marginTop: '40px' }}>
            <TableRow>
              <TableCell sx={{ px: '5px', border: 0 }}>{t('search_twoVendor_sign_title')}</TableCell>
              <TableCell sx={{ px: '5px', border: 0 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </TableCell>
              <TableCell sx={{ px: '5px', border: 0 }}>{t('search_date_dot')}</TableCell>
              <TableCell sx={{ px: '5px', border: 0 }}>{''}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ px: '5px', border: 0 }}>{t('search_threeVendor_sign_title')}</TableCell>
              <TableCell sx={{ px: '5px', border: 0 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </TableCell>
              <TableCell sx={{ px: '5px', border: 0 }}>{t('search_date_dot')}</TableCell>
              <TableCell sx={{ px: '5px', border: 0 }}>{''}</TableCell>
            </TableRow>
          </TableContainer>
        </Container>
      </div>
    </Container>
  )
}
