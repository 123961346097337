import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Divider,
  Container,
  Grid,
  FormControl,
  Button,
  FormLabel,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  tableCellClasses,
  MenuItem,
  Tab,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useSearchParams, useNavigate, createSearchParams } from 'react-router-dom'
import DownloadIcon from '@mui/icons-material/Download'
import moment from 'moment'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import * as api from 'src/utils/api/vendorAutopaySystem/ContractorAutopayStatusApi'

const ReportTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: '15px',
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'right',
    padding: '3px',
    fontSize: '13px',
  },
}))

// 承判商自動轉賬狀況  第一個表格

export default function TableAllData(props) {
  const { handleBack, sendData, tableData, setTableData, setTableReport, setIsSearchPage, setReportData, reportData, goData1, setGoData1 } = props
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [backOpen, setBackOpen] = useState(false)
  const [goData, setGoData] = useState({})
  const [siteList, setSiteList] = useState({})

  const navigate = useNavigate()

  useEffect(() => {
    setBackOpen(false)
  }, [])

  const exportExcel = () => {}

  const handleSubbatchId = (e, item, v, index, rowdata) => {
    const getSite = async () => {
      setBackOpen(true)

      goData.pdDateFrom = v.pdDateFrom
      goData.pdDateTo = v.pdDateTo
      goData.dateFrom = item.dateFrom
      goData.dateTo = item.dateTo
      goData.subbatchId = v.id
      goData.subbatchIdVo = v.id
      goData.vendorId = item.vendorId
      goData.payrollType = item.payrollType

      navigate({
        search: `?${createSearchParams({
          dateFrom1: v.pdDateFrom || '',
          dateTo1: v.pdDateTo || '',
        })}`,
      })

      let get = await api.getTableSiteData(goData)
      if (get) {
        tableData[index].allPeriod = [...get]
        setTableData([...tableData])
      }

      item.subbatchId = v.id

      setGoData({ ...goData })
      setBackOpen(false)
    }

    getSite()
  }

  const handleSiteId = (e, item, v, index) => {
    item.siteId = v.id
    tableData[index] = { ...item }
    setTableData([...tableData])
  }

  const handleTableReport = async (item) => {
    setBackOpen(true)

    let sendData = {}

    const getDate = (dateString) => (dateString && moment.isDate(moment(dateString).toDate()) ? moment(dateString).format('yyyy/MM/DD') : '')

    const dateFrom1 = searchParams.get('dateFrom1')
    const dateTo1 = searchParams.get('dateTo1')

    if (item.payrollType === 'S' && item.status === 'C') {
      // 完成的短糧期
      sendData = {
        dateFrom: item.dateFrom,
        vendorId: item.vendorId,
        payrollType: item.payrollType,
        subBatchId: item.subbatchId,
        headerId: item.headerId,
        dateTo: item.dateTo,
        siteId: item.siteId,
        status: item.status,
        pdDateFrom: getDate(dateFrom1) || moment(item.dateFrom).format('yyyy/MM/DD'),
        pdDateTo: getDate(dateTo1) || moment(item.dateTo).format('yyyy/MM/DD'),
      }
    } else if (item.payrollType === 'S' && item.status === 'I') {
      // 完成的短糧期（未完成狀態）
      sendData = {
        dateFrom: item.dateFrom,
        vendorId: item.vendorId,
        payrollType: item.payrollType,
        subBatchId: item.subbatchId,
        headerId: item.headerId,
        dateTo: item.dateTo,
        siteId: item.siteId,
        status: item.status,
        pdDateFrom: getDate(dateFrom1) || moment(item.dateFrom).format('yyyy/MM/DD'),
        pdDateTo: getDate(dateTo1) || moment(item.dateTo).format('yyyy/MM/DD'),
      }
    } else if (item.payrollType === 'F') {
      // 完成整個糧期
      sendData = {
        dateFrom: item.dateFrom,
        vendorId: item.vendorId,
        payrollType: item.payrollType,
        headerId: item.headerId,
        dateTo: item.dateTo,
        siteId: item.siteId,
        status: item.status,
        pdDateFrom: item.dateFrom,
        pdDateTo: item.dateTo,
      }
    } else if (item.payrollType === 'P') {
      // 分配部分糧期
      sendData = {
        dateFrom: item.dateFrom,
        vendorId: item.vendorId,
        payrollType: item.payrollType,
        subBatchId: item.subbatchId,
        headerId: item.headerId,
        dateTo: item.dateTo,
        siteId: item.siteId,
        status: item.status,
        pdDateFrom: item.dateFrom,
        pdDateTo: item.dateTo,
      }
    }

    let get = await api.getTableReport(sendData)

    setReportData({ ...sendData })
    setTableReport({ ...get })
    setIsSearchPage(3)
    setBackOpen(false)
  }

  // 承判商自動轉賬支薪報告
  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <div
          style={{
            display: 'block',
            textAlign: 'left',
            marginTop: '0px',
          }}
        >
          <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
            <ArrowBackIcon />
            {t('button_back')}
          </Button>
        </div>

        {/* <div style={{ display: "block", textAlign: "right" }}>
                    <Button sx={{ float: "right", mr: "20px" }} onClick={exportExcel}>
                        <DownloadIcon />
                        {t("button_export_excel")}
                    </Button>
                    <Button sx={{ float: "right", mr: "20px" }}>
                        <DownloadIcon />
                        {t("button_export_pdf")}
                    </Button>
                </div> */}
        <div>
          <Typography variant="h4" style={{ textAlign: 'center', mt: '-30px' }}>
            <b>{t('Contractor_Autopay_Status')}</b>
          </Typography>
          <TableContainer>
            <Table sx={{ textAlign: 'left' }} size="small">
              <TableBody>
                <TableRow>
                  {/* 承判商: */}
                  <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('icon1_accrecsearch_sub_condition')}:</TableCell>
                  <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{!sendData.vendorId || sendData.vendorId === null ? t('all_contractors') : sendData.subcontractorDesc}</TableCell>
                </TableRow>
                <TableRow>
                  {/* 日期（由) */}
                  <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('Autopay_Date') + '(' + t('from') + ')'}:</TableCell>
                  <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.dateFrom}</TableCell>
                </TableRow>
                <TableRow>
                  {/* 日期（至) */}
                  <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('Autopay_Date') + '(' + t('to') + ')'}:</TableCell>
                  <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.dateTo}</TableCell>
                </TableRow>
                <TableRow>
                  {/* 狀況 */}
                  <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('Status')}:</TableCell>
                  <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.statusDesc}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer sx={{ maxHeight: '80vh', maxWidth: '100vw' }}>
            <Table size="small" sx={{ mt: '20px' }} stickyHeader>
              <TableHead>
                <TableRow>
                  <ReportTableCell>{t('icon1_accrecsearch_sub_condition')}</ReportTableCell>
                  <ReportTableCell>{t('Autopay_Date')}</ReportTableCell>
                  <ReportTableCell>{t('Payroll_period') + t('from')}</ReportTableCell>
                  <ReportTableCell>{t('Payroll_period') + t('to')}</ReportTableCell>
                  <ReportTableCell>{t('Payroll_method_and_total_times')}</ReportTableCell>
                  <ReportTableCell>{t('Status')}</ReportTableCell>
                  <ReportTableCell>{t('Payroll_Transfer_Receipt')}</ReportTableCell>
                  <ReportTableCell>{t('MPF_Transfer_Receipt')}</ReportTableCell>
                  <ReportTableCell sx={{ textAlign: 'right' }}>{t('Relevant_income')}</ReportTableCell>
                  <ReportTableCell sx={{ textAlign: 'right' }}>{t('Total_MPF_Contributions')}</ReportTableCell>
                  <ReportTableCell sx={{ textAlign: 'right' }}>{t('Total_workers')}</ReportTableCell>
                  <ReportTableCell sx={{ textAlign: 'right' }}>{t('Payroll_numbers')}</ReportTableCell>
                  <ReportTableCell>{t('Short_or_partial_pay_periods_Number_of_payouts')}</ReportTableCell>
                  <ReportTableCell>{t('Site_Name')}</ReportTableCell>
                  <ReportTableCell>{t('Bank_Account_No.')}</ReportTableCell>
                  <ReportTableCell>{t('plan_Member_Number')}</ReportTableCell>
                  <ReportTableCell>{t('Remark')}</ReportTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((item, index) => (
                  <TableRow
                    hover
                    key={index}
                    sx={{
                      backgroundColor: index % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                    }}
                  >
                    <TableCell sx={{ textAlign: '' }}>{item.vendorDesc}</TableCell>
                    <TableCell sx={{ textAlign: '' }}>
                      {item.status !== 'invalid' && item.status !== 'I' && (
                        <Button size="small" onClick={() => handleTableReport(item)}>
                          {' '}
                          {item.autoPayDate}
                        </Button>
                      )}
                    </TableCell>
                    <TableCell sx={{ textAlign: '' }}>
                      {item.status !== 'invalid' ? (
                        <Button size="small" onClick={() => handleTableReport(item)}>
                          {item.dateFrom}
                        </Button>
                      ) : (
                        item.dateFrom
                      )}
                    </TableCell>
                    <TableCell sx={{ textAlign: '' }}>
                      {item.status !== 'invalid' ? (
                        <Button size="small" onClick={() => handleTableReport(item)}>
                          {item.dateTo}
                        </Button>
                      ) : (
                        item.dateTo
                      )}
                    </TableCell>
                    <TableCell sx={{ textAlign: '' }}>{item.counTer}</TableCell>
                    <TableCell sx={{ textAlign: '' }}>{item.statusDesc}</TableCell>
                    {/* 薪金轉賬收條 */}
                    <TableCell sx={{ textAlign: '' }}>
                      {item.status === 'C' ? (
                        <>
                          {item.payrollFlag === 'Y' ? (
                            <Button title={t('Last_update_date') + item.lastUpdateDate} size="small">
                              <CheckIcon />
                            </Button>
                          ) : (
                            <Button title={t('Last_update_date') + item.lastUpdateDate} size="small">
                              <CloseIcon />
                            </Button>
                          )}
                        </>
                      ) : null}
                    </TableCell>
                    <TableCell sx={{ textAlign: '' }}>
                      {item.status === 'C' ? (
                        <>
                          {item.mpfFlag === 'Y' ? (
                            <Button title={t('Last_update_date') + item.lastUpdateDate} size="small">
                              <CheckIcon />
                            </Button>
                          ) : (
                            <Button title={t('Last_update_date') + item.lastUpdateDate} size="small">
                              <CloseIcon />
                            </Button>
                          )}
                        </>
                      ) : null}
                    </TableCell>
                    {/* 有關收入 */}
                    <TableCell sx={{ textAlign: 'right' }}>{item.relevantIncomeFormat}</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>{item.employerMpfFormat}</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>{item.employeeNum}</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>{item.employeeAuto}</TableCell>

                    <TableCell>
                      {item.sortList && item.sortList?.length > 0 ? (
                        <>
                          <TextField value={item.subbatchId || ' '} sx={{ width: '200px' }} select size="small">
                            <MenuItem value=" " onClick={(e) => handleSubbatchId(e, item, { subbatchId: ' ' }, index)}>
                              全部糧期
                            </MenuItem>
                            {item.sortList.map((v, i) => (
                              <MenuItem value={v.id} key={i} onClick={(e) => handleSubbatchId(e, item, v, index)}>
                                {v.text}
                              </MenuItem>
                            ))}
                          </TextField>
                          {/* <Button
                            size="small"
                            onClick={() => handleTableReport(item)}
                          >
                            Go
                          </Button> */}
                        </>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {item.allPeriod && item.allPeriod?.length > 0 ? (
                        <>
                          <TextField value={item.siteId || ' '} sx={{ width: '250px' }} select size="small">
                            <MenuItem value=" " onClick={(e) => handleSiteId(e, item, { site: ' ' }, index)}>
                              全部地盤
                            </MenuItem>

                            {item.allPeriod.map((site, i) => (
                              <MenuItem value={site.id} key={i} onClick={(e) => handleSiteId(e, item, site, index)}>
                                {site.text}
                              </MenuItem>
                            ))}
                          </TextField>
                          <Button size="small" onClick={() => handleTableReport(item)}>
                            Go
                          </Button>
                        </>
                      ) : null}
                    </TableCell>
                    <TableCell sx={{ textAlign: '' }}>{item.bankAccount}</TableCell>
                    <TableCell sx={{ textAlign: '' }}>{item.schemeNo}</TableCell>
                    <TableCell sx={{ textAlign: '' }}>{item.remarkExhibit}</TableCell>
                  </TableRow>
                ))}
                {(!tableData || tableData.length === 0) && (
                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }} colSpan={17}>
                      {'No data found !'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Container>
    </>
  )
}
