import { useTranslation } from 'react-i18next'
import {
  Button,
  Divider,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Dialog,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
  MenuItem,
} from '@mui/material'
import html2canvas from 'html2canvas'
import DownloadIcon from '@mui/icons-material/Download'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import * as api from 'src/utils/api/workerAttendanceRecord/AutoPayApi'
import { confirm } from 'src/components/common'
import { set } from 'lodash'
import ErrorUpdateTable from './ErrorUpdateTable'
import CalendarPart from './CalendarPart'

export default function SaveButton(props) {
  const { t } = useTranslation()
  const { handleSaveReload, setSnackbar, snackbar, setStep, sendData, setOpen, setType, setDialog, lastPageData, dialog } = props
  const [headData, setHeadData] = useState({})
  const [tableData, setTableData] = useState([])
  const [propertyList, setPropertyList] = useState([])
  const [dateOpen, setDateOpen] = useState(false)
  const [member, setMember] = useState(0)
  const [datePartI, setDatePartI] = useState(0)
  const [datePartIndex, setDatePartIndex] = useState(0)
  const [datePartRow, setDatePartRow] = useState({})
  const [account, setAccount] = useState(0)
  const [allDateList, setAllDateList] = useState([])
  const [reload, setReload] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [errorOpen, setErrorOpen] = useState(false)
  const [errorData, setErrorData] = useState([])
  const elementRef = useRef(null)
  const contentRef = useRef(null)
  const [originalTableData, setOriginalTableData] = React.useState({})
  const [saveData, setSaveData] = React.useState({})
  const [requestData, setRequestData] = React.useState({})
  const [isChangeData, setIsChangeData] = React.useState(false)
  const [shortDateFrom, setShortDateFrom] = useState('left')
  const [shortDateTo, setShortDateTo] = useState('right')
  //   const [shortDateFromData, setShortDateFromData] = useState('left')
  // const [shortDateTo, setShortDateTo] = useState('right')
  const [leftOrRightDesign, setLeftOrRightDesign] = useState(false)
  // 頁數
  const [page, setPage] = React.useState(sendData.pageNumber - 1)
  // 每頁數量
  const [rowsPerPage, setRowsPerPage] = React.useState(sendData.pageSize)

  const requestDetailData = async () => {
    let count = new Set()

    let autoPayMainDataResponse = {}

    if (lastPageData.payrollType === 'P') {
      // 部分粮期
      autoPayMainDataResponse = await api.sendPartAllData(sendData)
    } else {
      autoPayMainDataResponse = await api.sendAllData(sendData)
    }
    if (autoPayMainDataResponse) {
      setSaveData(autoPayMainDataResponse)
    } else {
      alert('未知錯誤')
    }

    let moneyCount = 0
    if (autoPayMainDataResponse.periodset.bankAccount.length < 14 || autoPayMainDataResponse.periodset.bankAccount.length > 15) {
      autoPayMainDataResponse.periodset.bankAccount = ''
    }

    setHeadData({ ...autoPayMainDataResponse.periodset } || {})
    setTableData([...(autoPayMainDataResponse.subData || [])])
    setOriginalTableData([...(autoPayMainDataResponse.subData || [])])
    autoPayMainDataResponse.subData?.map((v) => {
      for (let i of v.workerDetailsqlOragin) {
        moneyCount += i.relevantIncome
        count.add(i.cardHolderId)
      }
      return []
    })
    setAccount(moneyCount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))
    setMember(count.size)
  }

  useEffect(() => {
    requestDetailData()
  }, [reload])

  // 切換頁數
  const handleChangePage = (event, newPage) => {
    setOpen(true)
    if (isChangeData) {
      // 增加弹窗
      setDialog({
        ...dialog,
        content: '本頁數據已修改，請點擊保存按鈕保存數據！！！',
        open: true,
      })
      setOpen(false)
      return
    } else {
      setOpen(false)
    }

    setPage(newPage)
    sendData['pageNumber'] = newPage + 1
    requestDetailData().then(() => {
      setOpen(false)
    })
  }

  // 切換每頁數量
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(1)

    sendData['pageNumber'] = 1
    sendData['pageSize'] = +event.target.value
    requestDetailData()
  }

  const handleBack = () => {
    // 重置分页
    sendData['pageNumber'] = 1
    setStep(0)
  }
  const handleCreateFiles = () => {
    // test
    confirm({
      title: t('tip'),
      content: t('confirm_the_transfer_to_make_an_autopay'),
      okText: '取消',
      cancelText: '確認',
      onOk: () => {},
      onCancel: () => {
        setType(8)
      },
    })
    //  setType(8);
  }

  const handleBankName = (e) => {
    if (e.target.value.length > 20) {
      return
    }
    setHeadData({ ...headData, bankAccountName: e.target.value })
  }
  const handleSchemeNo = (e) => {
    setHeadData({ ...headData, schemeNo: e.target.value })
  }
  const handleContactPerson = (e) => {
    if (e.target.value.length > 20) {
      return
    }
    setHeadData({ ...headData, contactPerson: e.target.value })
  }

  const handleBankAccount = (e) => {
    if (e.target.value.length > 15) {
      return
    }
    setHeadData({ ...headData, bankAccount: e.target.value })
  }
  const handlePhoneNum = (e) => {
    if (e.target.value.length > 20) {
      return
    }
    setHeadData({ ...headData, phoneNumber: e.target.value })
  }

  // const handleSchemeNo = (e) => {
  //     setHeadData({ ...headData, schemeNo: e.target.value })
  // }
  const handleSaveHead = () => {
    if (headData.schemeNo.length < 11) {
      setDialog({
        ...dialog,
        content: '計劃成員編號必须为11位',
        open: true,
      })
      return
    }
    if (headData.bankAccount.length < 14) {
      setDialog({
        ...dialog,
        content: '銀行戶口號碼必须大於14位小於15位',
        open: true,
      })
      return
    }
    headData.siteId = sendData.siteId
    const saveHead = async () => {
      let result = await api.updateHeaderAutopayMainData(headData)
      setDialog({
        ...dialog,
        content: result.message,
        open: true,
      })
    }

    saveHead()
    handleSaveReload()
    setOpen(false)
  }
  const handleAdd = (i, row, index) => {
    if (row.age > 65) {
      if (!row.bankAccount) {
        setDialog({
          ...dialog,
          content: '請先填寫完整 员工户口號碼 再加入新數據!',
          open: true,
          action: (
            <Button
              variant="contained"
              onClick={() => {
                setDialog({ ...dialog, open: false })
              }}
            >
              確認
            </Button>
          ),
        })
        return
      }
    } else {
      if (!row.bankAccount || !row.mpfScheme) {
        setDialog({
          ...dialog,
          content: '請先填寫完整 员工户口號碼 和 計劃成员編號,再加入新數據!',
          open: true,
          action: (
            <Button
              variant="contained"
              onClick={() => {
                setDialog({ ...dialog, open: false })
              }}
            >
              確認
            </Button>
          ),
        })
        return
      }
    }

    let newRow = { ...row }
    newRow.addByLocal = 'Y'
    newRow.newadd = 'Y'
    newRow.typeAdd = 'new'
    newRow.dailyRate = row?.dailyRate
    newRow.payrollRemark = ''
    newRow.shortdatefrom = ''
    newRow.shortdateto = ''
    newRow.relevantIncome = 0
    newRow.workDayAct = 0
    newRow.workDay = 0
    tableData[index].workerDetailsql.splice(i + 1, 0, newRow)
    setIsChangeData(true)
    setTableData([...tableData])
  }
  const handleDelete = (item, i, row, index) => {
    setDialog({
      ...dialog,
      content: '確定要刪除該數據嗎？',
      open: true,
      action: (
        <>
          <Button
            variant="contained"
            onClick={() => {
              setDialog({
                ...dialog,
                open: false,
              })
            }}
          >
            取消
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const sendDelete = async () => {
                if (row.addByLocal === 'Y') {
                  setDialog({
                    ...dialog,
                    open: false,
                  })
                  setSnackbar({
                    ...snackbar,
                    message: '刪除成功',
                    open: true,
                    action: (
                      <Button
                        variant="contained"
                        onClick={() => {
                          setSnackbar({ ...snackbar, open: false })
                        }}
                      >
                        確認
                      </Button>
                    ),
                  })
                  tableData[index].workerDetailsql.splice(i, 1)

                  setIsChangeData(true)
                  setTableData([...tableData])
                } else {
                  let deleteData = await api.deleteData({
                    headerId: row.headerId,
                    seqNo: row.seqNo,
                  })
                  if (deleteData.code === 200) {
                    setDialog({
                      ...dialog,
                      open: false,
                    })
                    setSnackbar({
                      ...snackbar,
                      message: '刪除成功',
                      open: true,
                      action: (
                        <Button
                          variant="contained"
                          onClick={() => {
                            setSnackbar({ ...snackbar, open: false })
                          }}
                        >
                          確認
                        </Button>
                      ),
                    })
                    tableData[index].workerDetailsql.splice(i, 1)

                    setIsChangeData(true)
                    setTableData([...tableData])
                  }
                }
              }
              sendDelete()
            }}
          >
            確認
          </Button>
        </>
      ),
    })
    setReload(!reload)
  }
  const addRelevantIncome = (list) => {
    let count = 0
    for (let v of list) {
      count += v.relevantIncome
    }
    return count.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const handleDate = (i, row, index, leftOrRight) => {
    // 判断日期控件范围

    if (saveData.payroll_Type === 'S' && sendData.subDesc !== 'F') {
      setStartDate(sendData.pdDateFrom)
      setEndDate(sendData.pdDateTo)
    } else if (saveData.payroll_Type === 'S' && sendData.subDesc === 'F') {
      setStartDate(sendData.dateFrom)
      setEndDate(sendData.dateTo)
    } else {
      setStartDate(headData.dateFrom)
      setEndDate(headData.dateTo)
    }
    // 拿出用戶選擇的所有日期區間
    let dateList = []

    for (let value of tableData[index].workerDetailsql) {
      if (value.headerId === row.headerId && value.cardHolderId === row.cardHolderId) {
        dateList.push({
          startDate: value.shortdatefrom,
          endDate: value.shortdateto,
        })
      }
    }
    setAllDateList([...dateList])
    console.log('DatePartRow', row)
    console.log('tableData', tableData)
    console.log('dateList', dateList)
    setDatePartI(i)
    setDatePartIndex(index)
    setDatePartRow(row)
    setDateOpen(true)
    setIsChangeData(true)
    console.log('value', row)

    if (leftOrRight === 'left') {
      setLeftOrRightDesign(true)
    } else {
      setLeftOrRightDesign(false)
    }
  }

  const handleShortDateFrom = (e, index, i, item, row) => {
    let workerDay = 0
    let dayCount = 0
    if (tableData[index].workerDetailsql[i].shortdateto !== null) {
      dayCount = new Date(tableData[index].workerDetailsql[i].shortdateto) - new Date(e.target.value)
      if (dayCount < 0) {
        tableData[index].workerDetailsql[i].shortdateto = ''
        workerDay = 0
      } else {
        workerDay = Math.ceil(dayCount / 86400000) + 1
      }
    }
    row.typeAdd = row.typeAdd === 'new' ? 'new' : 'edit'
    tableData[index].workerDetailsql[i].shortdatefrom = ''
    for (let value of tableData[index].workerDetailsql) {
      if (
        value.headerId === row.headerId &&
        value.cardHolderId === row.cardHolderId &&
        value.shortdatefrom &&
        new Date(e.target.value) >= new Date(value.shortdatefrom) &&
        new Date(e.target.value) <= new Date(value.shortdateto)
      ) {
        setDialog({
          ...dialog,
          content: '同一個用戶選擇的時間重複',
          open: true,
        })
        // alert("同一個用戶選擇的時間重複")
        return
      }
    }
    tableData[index].workerDetailsql[i].relevantIncome = workerDay * tableData[index].workerDetailsql[i].dailyRate || 0
    tableData[index].workerDetailsql[i].workDayAct = workerDay
    tableData[index].workerDetailsql[i].shortdatefrom = e.target.value
    setTableData([...tableData])
  }
  const handleShortDateTo = (e, index, i, item, row) => {
    let workerDay = 0
    let dayCount = 0
    if (tableData[index].workerDetailsql[i].shortdatefrom !== null) {
      dayCount = new Date(e.target.value) - new Date(tableData[index].workerDetailsql[i].shortdatefrom)
      if (dayCount < 0) {
        workerDay = 0
      } else {
        workerDay = Math.ceil(dayCount / 86400000) + 1
      }
    }
    row.typeAdd = row.typeAdd === 'new' ? 'new' : 'edit'
    tableData[index].workerDetailsql[i].shortdateto = ''
    for (let value of tableData[index].workerDetailsql) {
      // 同一個用戶對比時間
      if (
        value.headerId === row.headerId &&
        value.cardHolderId === row.cardHolderId &&
        new Date(e.target.value) >= new Date(value.shortdatefrom) &&
        new Date(e.target.value) <= new Date(value.shortdateto)
      ) {
        setDialog({
          ...dialog,
          content: '同一個用戶選擇的時間重複',
          open: true,
        })
        // alert("同一個用戶選擇的時間重複")
        return
      }
    }
    tableData[index].workerDetailsql[i].relevantIncome = workerDay * tableData[index].workerDetailsql[i].dailyRate || 0
    tableData[index].workerDetailsql[i].workDayAct = workerDay
    tableData[index].workerDetailsql[i].shortdateto = e.target.value
    setTableData([...tableData])
  }

  const errorFunction = (result) => {
    if (result.data.data) {
      setOpen(false)
      handleSaveReload()

      setErrorOpen(true)
    } else {
      setOpen(false)
      if (result.data.length > 0) {
        setErrorOpen(true)
        setErrorData(result.data)
      }
      setDialog({
        ...dialog,
        content: result.message,
        open: true,
      })
      //   alert(result.message)
    }
    setIsChangeData(false)

    setReload(!reload)
  }

  const handleSave = (index, value) => {
    setOpen(true)
    for (let item of tableData[index].workerDetailsql) {
      if (!item.shortdatefrom || !item.shortdateto) {
        item.workDayAct = 0
      }
      // if (!item.shortdatefrom || !item.shortdateto) {
      //     setDialog({
      //         ...dialog,
      //         content: "請選擇實際上班日期",
      //         open: true,
      //         action: (
      //             <Button
      //                 variant="contained"
      //                 onClick={() => {
      //                     setDialog({ ...dialog, open: false });
      //                 }}
      //             >
      //                 確認
      //             </Button>
      //         ),
      //     })
      //     setOpen(false)
      //     return
      // }
      item.siteId = sendData.siteId
    }
    const saveTableData = async () => {
      if (sendData.payrollType === 'P') {
        // 部分
        await api
          .saveTableData(headData.dateFrom, headData.dateTo, value.subconset?.subContractorId, sendData.siteId, saveData.periodset?.headerId, tableData[index].workerDetailsql)
          .then((result) => errorFunction(result))
          .catch((error) => console.log(error))
      } else {
        // 短
        if (sendData.subBatchPeriod === 'F') {
          await api
            .saveTableData(sendData.pdDateFrom, sendData.pdDateTo, value.subconset?.subContractorId, sendData.siteId, saveData.periodset?.headerId, tableData[index].workerDetailsql)
            .then((result) => errorFunction(result))
            .catch((error) => console.log(error))
        } else {
          await api
            .saveTableData(sendData.pdDateFrom, sendData.pdDateTo, value.subconset?.subContractorId, sendData.siteId, saveData.periodset?.headerId, tableData[index].workerDetailsql)
            .then((result) => errorFunction(result))
            .catch((error) => console.log(error))
        }
      }
    }

    saveTableData()
  }

  const captureScreenshot = () => {
    const options = {
      width: elementRef.current.width, // 设置Canvas宽度
      height: elementRef.current.height, // 设置Canvas高度
      backgroundColor: 'white', // 设置背景颜色
    }
    html2canvas(elementRef.current, options).then((canvas) => {
      // 在这里可以处理Canvas对象，例如保存为图片或者显示在界面上
      const dataURL = canvas.toDataURL()
      // const screenshotImage = new Image();
      // screenshotImage.src = dataURL;
      // document.body.appendChild(screenshotImage);
      // 或者保存为图片文件
      const link = document.createElement('a')
      link.href = dataURL
      link.download = 'screenshot.png'
      link.click()
    })
  }

  const handlePrint = async () => {
    try {
      const content = contentRef.current
      const scale = 0.8
      if (content) {
        const canvas = await html2canvas(content, { scale: scale })
        const imgData = canvas.toDataURL('image/png')

        // 创建一个新窗口用于打印
        const printWindow = window.open('', '', 'width=600,height=600')
        printWindow.document.open()
        printWindow.document.write('<img src="' + imgData + '" />')
        printWindow.document.close()

        // 等待图像加载完成后再执行打印
        printWindow.onload = function () {
          printWindow.print()
          printWindow.close()
        }
      }
    } catch (error) {
      console.error('打印出错：', error)
    }
  }

  const handleShortDateFromCover = (data, row, index, i) => {
    let inputData = new Date(data)
    let headerDateFrom = new Date(headData.dateFrom)
    let headerDateTo = new Date(headData.dateTo)
    // if (data) {
    //   if (moment(inputData).format('yyyy/MM/DD') < moment(headerDateFrom).format('yyyy/MM/DD')) {
    //     // 增加弹窗
    //     setDialog({
    //       ...dialog,
    //       content: '實際上班日期(由) 不能超過大糧期範圍！！！',
    //       open: true,
    //     })
    //     setOpen(false)
    //   }

    // if (moment(inputData).format('yyyy/MM/DD') > moment(headerDateTo).format('yyyy/MM/DD')) {
    //   // 增加弹窗
    //   setDialog({
    //     ...dialog,
    //     content: '實際上班日期(至) 不能超過大糧期範圍！！！',
    //     open: true,
    //   })
    //   setOpen(false)
    // }
    // }
    if (tableData[index].workerDetailsql[i].shortdatefrom !== null && tableData[index].workerDetailsql[i].shortdateto !== null) {
      if (moment(tableData[index].workerDetailsql[i].shortdateto).format('yyyy/MM/DD') < moment(tableData[index].workerDetailsql[i].shortdatefrom).format('yyyy/MM/DD')) {
        // 彈窗
        setDialog({
          ...dialog,
          content: '實際上班日期(由) 不能超過 實際上班日期(至)！！！',
          open: true,
        })
        tableData[index].workerDetailsql[i].shortdatefrom = null
        tableData[index].workerDetailsql[i].relevantIncome = 0
        setOpen(false)
      }
    }

    // 同一用户check
    // 拿出用戶選擇的所有日期區間
    let dateList = []
    for (let value of tableData[index].workerDetailsql) {
      if (value.headerId === row.headerId && value.cardHolderId === row.cardHolderId) {
        dateList.push({
          startDate: value.shortdatefrom,
          endDate: value.shortdateto,
        })
      }
    }
  }

  // onKeyPress时禁止输入小数点及其他特殊数字字符
  const handleKeyPress = (event) => {
    const invalidChars = ['-', '+', 'e', '.', 'E']
    if (invalidChars.indexOf(event.key) !== -1) {
      event.preventDefault()
    }
  }

  return (
    <div ref={contentRef}>
      <ErrorUpdateTable setErrorOpen={setErrorOpen} errorOpen={errorOpen} errorData={errorData} />
      {/* <TestCalendar /> */}
      <div style={{ display: 'block', textAlign: 'left', marginTop: '0px' }}>
        <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
          <ArrowBackIcon />
          {t('button_back')}
        </Button>
      </div>

      <div style={{ textAlign: 'center' }}>
        <Typography sx={{ fontSize: '35px' }}>
          <b>{t('Enter/amend-worker_salary/MPF_information')}</b>
        </Typography>
      </div>

      {/* <Button sx={{ float: 'right', mr: '50px', mx: 100 }} onClick={() => handlePrint()}>
        {t('列印')}
      </Button> */}

      {saveData.canSubmit === 1 ? (
        <Button variant="contained" sx={{ mb: '10px' }} onClick={handleCreateFiles}>
          {t('create_auto-transfer_files')}
        </Button>
      ) : (
        <Button variant="contained" sx={{ mb: '10px' }} disabled>
          {t('create_auto-transfer_files')} ({t('No_rights_production')})
        </Button>
      )}
      <Typography variant="h6">
        <b>{t('icon1_autopay_button8_print_title')}:</b>
      </Typography>

      {/* 僱主名稱 */}
      <div>
        <TableContainer>
          <Table padding="none" sx={{ textAlign: 'left' }} size="small">
            <TableBody>
              <TableRow>
                {/* 僱主名稱 */}
                <TableCell
                  sx={{
                    fontSize: '15px',
                    width: 200,
                    borderBottom: 'none',
                    py: '10px',
                  }}
                >
                  {t('icon1_autopay_button8_print_label1')}:
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    borderBottom: 'none',
                  }}
                >
                  {headData.vendorId}
                </TableCell>
              </TableRow>
              <TableRow>
                {/* 銀行戶口名稱 */}
                <TableCell
                  sx={{
                    fontSize: '15px',
                    width: 200,
                    borderBottom: 'none',
                    py: '10px',
                  }}
                >
                  {t('icon1_autopay_button8_print_label2')}:
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    borderBottom: 'none',
                  }}
                >
                  <TextField inputProps={{ maxLength: 50 }} value={headData.bankAccountName || ''} size="small" onChange={handleBankName} />
                </TableCell>
              </TableRow>
              <TableRow>
                {/* 銀行戶口號碼 */}
                <TableCell
                  sx={{
                    display: 'block',
                    fontSize: '15px',
                    borderBottom: 'none',
                    py: '10px',
                  }}
                >
                  {t('icon1_autopay_button8_print_label3')}:
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    borderBottom: 'none',
                  }}
                >
                  <TextField
                    // type="number"
                    size="small"
                    onWheel={(e) => {
                      e.target.blur()
                    }}
                    value={headData.bankAccount || ''}
                    onChange={handleBankAccount}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                {/* 計劃成員編號 	 */}
                <TableCell
                  sx={{
                    display: 'block',
                    fontSize: '15px',
                    borderBottom: 'none',
                    py: '10px',
                  }}
                >
                  {t('plan_Member_Number')}:
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    borderBottom: 'none',
                  }}
                >
                  <TextField inputProps={{ maxLength: 11, minLength: 11 }} size="small" value={headData.schemeNo || ''} onChange={handleSchemeNo} />
                </TableCell>
              </TableRow>
              <TableRow>
                {/* 僱主代號 */}
                <TableCell
                  sx={{
                    display: 'block',
                    fontSize: '15px',
                    borderBottom: 'none',
                    py: '10px',
                  }}
                >
                  {t('icon1_autopay_button8_print_label5')}:
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    borderBottom: 'none',
                  }}
                >
                  <Typography sx={{ display: 'block', fontSize: '15px' }}>{headData.branchCode || ''}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                {/* 聯絡人姓名 */}
                <TableCell
                  sx={{
                    display: 'block',
                    fontSize: '15px',
                    borderBottom: 'none',
                    py: '10px',
                  }}
                >
                  {t('icon1_autopay_button8_print_label6')}:
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    borderBottom: 'none',
                  }}
                >
                  <TextField size="small" value={headData.contactPerson || ''} onChange={handleContactPerson} />
                </TableCell>
              </TableRow>
              <TableRow>
                {/* 電話號碼 */}
                <TableCell
                  sx={{
                    display: 'block',
                    fontSize: '15px',
                    borderBottom: 'none',
                    py: '10px',
                  }}
                >
                  {t('icon1_autopay_button8_print_label7')}:
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    borderBottom: 'none',
                  }}
                >
                  <TextField sx={{ py: '1px' }} size="small" value={headData.phoneNumber || ''} onChange={handlePhoneNum} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Button variant="contained" sx={{ mb: '10px' }} onClick={handleSaveHead}>
          {t('storage_of_contractor_bank_information')}
        </Button>
      </div>
      <Divider sx={{ my: 2, color: '#ffffff' }} />
      <Typography variant="h6">
        <b>{t('Workers_Income_And_Bank_Information')}:</b>
      </Typography>

      {/* 循環的表格 */}
      <div>
        {tableData.map((item, index) => (
          <div key={index}>
            <TableContainer>
              <Table padding="none" sx={{ textAlign: 'left', mt: '10px', width: '80%' }} size="small">
                <TableBody>
                  <TableRow>
                    {/* 大糧期:	 */}
                    <TableCell
                      sx={{
                        fontSize: '15px',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      <b>{t('Big_Pay_Period')}:</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '15px',
                        textAlign: 'left',
                        borderBottom: 'none',
                      }}
                    >
                      {moment(headData.dateFrom).format('yyyy/MM/DD')}~{moment(headData.dateTo).format('yyyy/MM/DD')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {saveData.payroll_Type === 'P' ? (
                      <>
                        {/* 部分糧期: */}
                        <TableCell
                          sx={{
                            fontSize: '15px',
                            width: 200,
                            borderBottom: 'none',
                          }}
                        >
                          <b>{t('Partial_pay_period')}:</b>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '15px',
                            textAlign: 'left',
                            borderBottom: 'none',
                          }}
                        >
                          {sendData.subBatchId}-{sendData.dateFrom}-{sendData.dateTo}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        {/* 短糧期: */}
                        <TableCell
                          sx={{
                            fontSize: '15px',
                            width: 200,
                            borderBottom: 'none',
                          }}
                        >
                          <b>{t('Short_Pay_Period')}:</b>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '15px',
                            textAlign: 'left',
                            borderBottom: 'none',
                          }}
                        >
                          {sendData.subDesc === 'F' ? '最後的短糧期' : sendData.subDesc}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                  <TableRow>
                    {/* 地盤名稱: */}
                    <TableCell
                      sx={{
                        fontSize: '15px',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      <b>{t('Site_Name')}:</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '15px',
                        textAlign: 'left',
                        borderBottom: 'none',
                      }}
                    >
                      {sendData.siteDesc}
                    </TableCell>
                  </TableRow>
                  {saveData.conDesc !== null && (
                    <TableRow>
                      {/* 分判商:	 */}
                      <TableCell sx={{ display: 'block', fontSize: '15px' }}>
                        <b>{t('Subcontractor_Name')}:</b>
                      </TableCell>

                      <TableCell
                        sx={{
                          fontSize: '15px',
                          textAlign: 'left',
                          borderBottom: 'none',
                        }}
                      >
                        {saveData.conDesc}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Button variant="contained" sx={{ mb: '10px' }} onClick={() => handleSave(index, item)}>
                {t('store_workers_income_and_bank_information')}
              </Button>
            </TableContainer>
            <TableContainer sx={{ maxHeight: '80vh', maxWidth: '100vw' }}>
              <Table
                sx={{
                  mt: '10px',
                  '& .MuiTableCell-root': {
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    ariaLable: 'sticky table',
                  },

                  ariaLable: 'sticky table',
                }}
                size="small"
                stickyHeader
                ariaLabel="sticky table"
              >
                <TableHead>
                  <TableRow hover sx={{ border: '0px', ariaLabel: 'sticky table' }}>
                    <TableCell
                      sx={{
                        fontSize: '15px',
                        textAlign: 'left',
                        width: '1%',
                      }}
                    >
                      No{' '}
                    </TableCell>
                    {/* 英文姓名 */}
                    <TableCell
                      sx={{
                        fontSize: '15px',
                        textAlign: 'left',
                        width: '5%',
                      }}
                    >
                      {t('EnglishName')}
                    </TableCell>
                    {/* 中文姓名 */}
                    <TableCell
                      sx={{
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        textAlign: 'left',
                        width: '3%',
                      }}
                    >
                      {t('ChineseName')}
                    </TableCell>
                    {/* 身份証號碼 */}
                    <TableCell
                      sx={{
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        width: '5%',
                      }}
                    >
                      {t('ID_number')}
                    </TableCell>
                    {/* 實際上班日期(由) */}
                    <TableCell
                      sx={{
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        width: 5,
                      }}
                    >
                      {t('Actual_start_date_(From)')}
                    </TableCell>
                    {/* 實際上班日期(至) */}
                    <TableCell
                      sx={{
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        width: 5,
                      }}
                    >
                      {t('Actual_start_date_(To)')}
                    </TableCell>
                    {/* 工人上班日數 */}
                    <TableCell
                      sx={{
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        textAlign: 'right',
                        width: '2%',
                      }}
                    >
                      {t('Number_Of_Workers_Working_Days')}
                    </TableCell>
                    {/* 實際上班日數 */}
                    <TableCell
                      sx={{
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        textAlign: 'right',
                        width: '2%',
                      }}
                    >
                      {t('Actual_number_of_working_days')}
                    </TableCell>
                    {/* 每天入息 */}
                    <TableCell
                      sx={{
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        textAlign: 'right',
                        width: '5%',
                      }}
                    >
                      {t('Daily_Income')}
                    </TableCell>
                    {/* 工人入息 */}
                    <TableCell
                      sx={{
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        textAlign: 'right',
                        width: '5%',
                      }}
                    >
                      {t('Worker_income')}
                    </TableCell>
                    {/* 登記平均入息 */}
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        textAlign: 'right',
                        width: '5%',
                      }}
                    >
                      {t('icon1_autopay_button7_label6')}
                    </TableCell>
                    {/* 員工戶口號碼 */}
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        textAlign: 'left',
                        width: '7%',
                      }}
                    >
                      {t('Employee_Account_Number')}
                    </TableCell>
                    {/* 計劃成員編號 */}

                    <TableCell
                      align="center"
                      sx={{
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        textAlign: 'left',
                        width: '7%',
                      }}
                    >
                      {t('plan_Member_Number')}
                    </TableCell>
                    {sendData.subBatchPeriod === 'F' && sendData.payrollType === 'S' ? (
                      <>
                        {/* 已繳付發薪實額 */}
                        <TableCell
                          sx={{
                            fontSize: '15px',
                            wordBreak: 'keep-all',
                            textAlign: 'left',
                            width: '5%',
                          }}
                        >
                          {t('Actual_payroll_paid')}
                        </TableCell>
                        {/* 已繳付上班日數 */}
                        <TableCell
                          sx={{
                            fontSize: '15px',
                            wordBreak: 'keep-all',
                            textAlign: 'left',
                            width: '5%',
                          }}
                        >
                          {t('Number_of_working_days_paid')}
                        </TableCell>
                      </>
                    ) : null}
                    {/* 日薪/月薪 */}
                    {/*  <TableCell align="center" sx={{ fontSize: '15px' }}>{t('Daily/Monthly_Salary')}</TableCell> */}
                    {/* 不包括星期日	 */}
                    {/*  <TableCell align="center" sx={{ fontSize: '15px' }}>{t('Excluding_Sundays')}</TableCell> */}
                    {/* 備註 */}
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        width: 20,
                      }}
                    >
                      {t('Remark')}
                    </TableCell>
                    {/* 加入/刪除 */}
                    <TableCell align="center" sx={{ fontSize: '15px', whiteSpace: 'nowrap' }}>
                      {t('Add_Or_Deleted')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.workerDetailsql.length > 0 ? (
                    <>
                      {item.workerDetailsql.map((row, i) => (
                        <TableRow
                          hover
                          key={i}
                          sx={{
                            backgroundColor: i % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                            height: '-50px',
                          }}
                        >
                          <TableCell
                            sx={{
                              fontSize: '15px',
                            }}
                            component="th"
                            scope="row"
                          >
                            {i + 1}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              whiteSpace: 'nowrap',

                              textAlign: 'left',
                            }}
                          >
                            {row.englishName}{' '}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {row.chineseName}
                          </TableCell>
                          <TableCell align="center" sx={{ fontSize: '15px' }}>
                            {row.hkid}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: '15px',
                              textAlign: 'center',
                            }}
                          >
                            {/* 上班日期控件由 */}
                            <TextField
                              id="shortdateFrom"
                              sx={{
                                width: '140px',
                                WebkitAppearance: 'none',
                                '& ::-webkit-calendar-picker-indicator': {
                                  display: 'none',
                                  visibility: 'hidden',
                                },
                              }}
                              size="small"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      size="small"
                                      sx={{
                                        textAlign: 'center',
                                        ml: '-13px',
                                        mr: '-8px',
                                        fontSize: '15px',
                                      }}
                                    >
                                      <CalendarMonthIcon onClick={() => handleDate(i, row, index, shortDateFrom)} />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              // 新增加Onchange事件
                              onChange={(e) => {
                                // tableData[index].workerDetailsql[i].shortdatefrom = e.target.value
                                setIsChangeData(true)
                                // setTableData([...tableData])
                                //
                                // for (let value of tableData[index].workerDetailsql) {
                                //   // 同一個用戶對比時間

                                //   console.log('new Date(data)', moment(e.target.value).format('yyyy/MM/DD'))
                                //   console.log('value.shortdatefrom', moment(value.shortdatefrom).format('yyyy/MM/DD'))
                                //   console.log('value.shortdateto', moment(value.shortdateto).format('yyyy/MM/DD'))
                                //   if (
                                //     value.headerId === row.headerId &&
                                //     value.cardHolderId === row.cardHolderId &&
                                //     moment(e.target.value).format('yyyy/MM/DD') >= moment(value.shortdatefrom).format('yyyy/MM/DD') &&
                                //     moment(e.target.value).format('yyyy/MM/DD') <= moment(value.shortdateto).format('yyyy/MM/DD')
                                //   ) {
                                //     if (row.seqNo === value.seqNo) {
                                //       setDialog({
                                //         ...dialog,
                                //         content: '同一個用戶選擇的時間重複',
                                //         open: true,
                                //       })
                                //       // alert("同一個用戶選擇的時間重複")
                                //       return
                                //     }
                                //   }
                                // }

                                tableData[index].workerDetailsql[i].shortdatefrom = moment(e.target.value).format('yyyy/MM/DD')
                                if (e.target.value) {
                                  if (moment(e.target.value).format('yyyy/MM/DD') < moment(new Date(headData.dateFrom)).format('yyyy/MM/DD')) {
                                    // 增加弹窗
                                    setDialog({
                                      ...dialog,
                                      content: '實際上班日期(由) 不能超過大糧期範圍！！！',
                                      open: true,
                                    })
                                    tableData[index].workerDetailsql[i].shortdatefrom = null
                                    tableData[index].workerDetailsql[i].relevantIncome = 0
                                    setOpen(false)
                                  }
                                  if (moment(e.target.value).format('yyyy/MM/DD') > moment(new Date(headData.dateTo)).format('yyyy/MM/DD')) {
                                    // 增加弹窗
                                    setDialog({
                                      ...dialog,
                                      content: '實際上班日期(由) 不能超過大糧期範圍！！！',
                                      open: true,
                                    })
                                    tableData[index].workerDetailsql[i].shortdatefrom = null
                                    tableData[index].workerDetailsql[i].relevantIncome = 0
                                    setOpen(false)
                                  }
                                }
                                if (tableData[index].workerDetailsql[i].shortdatefrom !== null && tableData[index].workerDetailsql[i].shortdateto !== null) {
                                  // 定位
                                  let workerday = new Date(tableData[index].workerDetailsql[i].shortdateto) - new Date(tableData[index].workerDetailsql[i].shortdatefrom)
                                  tableData[index].workerDetailsql[i].workDayAct = Math.ceil(workerday / 86400000) + 1 || 0
                                  tableData[index].workerDetailsql[i].relevantIncome = tableData[index].workerDetailsql[i].workDayAct * tableData[index].workerDetailsql[i].dailyRate || 0
                                }

                                setTableData([...tableData])
                                //
                                setShortDateFrom(e.target.value)
                              }}
                              onBlur={() => handleShortDateFromCover(tableData[index].workerDetailsql[i].shortdatefrom, row, index, i)}
                              value={(row.shortdatefrom && moment(row.shortdatefrom).format('yyyy-MM-DD')) || ''}
                              type="date"
                            />
                          </TableCell>
                          <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'center' }}>
                            {/* 上班日期控件至 */}
                            <TextField
                              // disabled

                              sx={{
                                width: '140px',
                                WebkitAppearance: 'none',
                                '& ::-webkit-calendar-picker-indicator': {
                                  display: 'none',
                                  visibility: 'hidden',
                                },
                              }}
                              size="small"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      size="small"
                                      sx={{
                                        textAlign: 'center',
                                        ml: '-13px',
                                        mr: '-8px',
                                        fontSize: '15px',
                                      }}
                                    >
                                      <CalendarMonthIcon onClick={() => handleDate(i, row, index, shortDateTo)} />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              // 新增加Onchange事件

                              onChange={(e) => {
                                tableData[index].workerDetailsql[i].shortdateto = e.target.value
                                setIsChangeData(true)
                                //

                                // for (let value of tableData[index].workerDetailsql) {
                                //   // 同一個用戶對比時間

                                //   console.log('new Date(data)', moment(e.target.value).format('yyyy/MM/DD'))
                                //   console.log('value.shortdatefrom', moment(value.shortdatefrom).format('yyyy/MM/DD'))
                                //   console.log('value.shortdateto', moment(value.shortdateto).format('yyyy/MM/DD'))
                                //   if (
                                //     value.headerId === row.headerId &&
                                //     value.cardHolderId === row.cardHolderId &&
                                //     moment(e.target.value).format('yyyy/MM/DD') >= moment(value.shortdatefrom).format('yyyy/MM/DD') &&
                                //     moment(e.target.value).format('yyyy/MM/DD') <= moment(value.shortdateto).format('yyyy/MM/DD')
                                //   ) {
                                //     setDialog({
                                //       ...dialog,
                                //       content: '同一個用戶選擇的時間重複',
                                //       open: true,
                                //     })
                                //     // alert("同一個用戶選擇的時間重複")
                                //     return
                                //   }
                                // }

                                console.log('e.target.value', e.target.value)
                                tableData[index].workerDetailsql[i].shortdateto = moment(e.target.value).format('yyyy/MM/DD')
                                if (e.target.value) {
                                  if (moment(e.target.value).format('yyyy/MM/DD') > moment(new Date(headData.dateTo)).format('yyyy/MM/DD')) {
                                    // 增加弹窗
                                    setDialog({
                                      ...dialog,
                                      content: '實際上班日期(至) 不能超過大糧期範圍！！！',
                                      open: true,
                                    })
                                    tableData[index].workerDetailsql[i].shortdateto = null
                                    tableData[index].workerDetailsql[i].relevantIncome = 0
                                    setOpen(false)
                                  }
                                  if (moment(e.target.value).format('yyyy/MM/DD') < moment(new Date(headData.dateFrom)).format('yyyy/MM/DD')) {
                                    // 增加弹窗
                                    setDialog({
                                      ...dialog,
                                      content: '實際上班日期(至) 不能超過大糧期範圍！！！',
                                      open: true,
                                    })
                                    tableData[index].workerDetailsql[i].shortdateto = null
                                    tableData[index].workerDetailsql[i].relevantIncome = 0
                                    setOpen(false)
                                  }
                                  // 定位
                                }

                                if (tableData[index].workerDetailsql[i].shortdatefrom !== null && tableData[index].workerDetailsql[i].shortdateto !== null) {
                                  let workerday = new Date(tableData[index].workerDetailsql[i].shortdateto) - new Date(tableData[index].workerDetailsql[i].shortdatefrom)
                                  tableData[index].workerDetailsql[i].workDayAct = Math.ceil(workerday / 86400000) + 1 || 0
                                  tableData[index].workerDetailsql[i].relevantIncome = tableData[index].workerDetailsql[i].workDayAct * tableData[index].workerDetailsql[i].dailyRate || 0
                                }

                                setTableData([...tableData])
                                //
                                // setTableData([...tableData])
                                setShortDateTo(e.target.value)
                              }}
                              onBlur={() => handleShortDateFromCover(tableData[index].workerDetailsql[i].shortdateto, row, index, i)}
                              value={(row.shortdateto && moment(new Date(row.shortdateto).getTime()).format('yyyy-MM-DD')) || ''}
                              type="date"
                            />

                            {/* <TextField size="small"
                                                        inputProps={{
                                                            min: row.shortdatefrom || moment(new Date(headData.dateFrom).getTime()).format('yyyy-MM-DD'),
                                                            max: moment(new Date(headData.dateTo).getTime()).format('yyyy-MM-DD') || false,
                                                        }}
                                                        onChange={(e) => handleShortDateTo(e, index, i, item, row)} value={row.shortdateto && moment(new Date(row.shortdateto).getTime()).format('yyyy-MM-DD')} type="date" /> */}
                          </TableCell>
                          <TableCell align="right" sx={{ textAlign: 'right' }}>
                            {row.newadd === 'N' ? row.workDay : ''}
                          </TableCell>
                          <TableCell align="right" sx={{ textAlign: 'right' }}>
                            {row.shortdateto && row.shortdatefrom ? row.workDayAct : 0}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              textAlign: 'right',
                            }}
                          >
                            {/* 每天入息 */}
                            <TextField
                              sx={{
                                width: '75px',
                                textAlign: 'right',
                              }}
                              size="small"
                              value={row.dailyRate || ''}
                              onChange={(e) => {
                                //   console.log("workDayAct", row.workDayAct);
                                tableData[index].workerDetailsql[i].typeAdd = row.typeAdd === 'new' ? 'new' : 'edit'
                                tableData[index].workerDetailsql[i].dailyRate = e.target.value || null
                                tableData[index].workerDetailsql[i].relevantIncome = e.target.value * (row.workDayAct || 0)

                                setIsChangeData(true)
                                setTableData([...tableData])
                              }}
                              min={0}
                              onKeyDown={handleKeyPress}
                              type="number"
                              onWheel={(e) => {
                                e.target.blur()
                              }}
                            />
                          </TableCell>
                          <TableCell align="right" sx={{ textAlign: 'right' }}>
                            {row.relevantIncome ? row.relevantIncome.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '0.00'}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              textAlign: 'right',
                            }}
                          >
                            {row.newadd === 'N' ? row.minbasicsalaryForMat : ''}
                          </TableCell>
                          <TableCell align="center" sx={{ fontSize: '15px' }}>
                            {row.newadd === 'Y' ? null : (
                              // 員工戶口號碼
                              <TextField
                                sx={{ width: '155px', fontSize: '15px' }}
                                size="small"
                                // type="number"
                                // onMouseDown={(event) => {
                                //   console.log('点击')
                                //   event.preventDefault()
                                // }}
                                // onMouseMoveCapture="return false;"

                                onWheel={(e) => {
                                  e.target.blur()
                                }}
                                onChange={(e) => {
                                  if (e.target.value.length > 15) {
                                    return
                                  }
                                  // 同一個用戶設置相同的戶口號碼
                                  tableData[index].workerDetailsql[i].mpfSchemeChange = true
                                  for (let value of tableData[index].workerDetailsql) {
                                    if (value.headerId === row.headerId && value.cardHolderId === row.cardHolderId) {
                                      value.bankAccount = e.target.value
                                      value.mpfSchemeChange = true
                                    }
                                  }

                                  tableData[index].workerDetailsql[i].typeAdd = row.typeAdd === 'new' ? 'new' : 'edit'
                                  tableData[index].workerDetailsql[i].bankAccount = e.target.value

                                  setIsChangeData(true)
                                  setTableData([...tableData])
                                }}
                                value={row.bankAccount || ''}
                              />
                            )}
                          </TableCell>
                          <TableCell align="left" sx={{ fontSize: '15px' }}>
                            {
                              // 計劃成員編號
                              row.newadd === 'Y' ? null : row.mpfflagSpecial === 'Y' ? (
                                <TextField
                                  sx={{ width: '160px' }}
                                  size="small"
                                  // type="number"
                                  onWheel={(e) => {
                                    e.target.blur()
                                  }}
                                  onChange={(e) => {
                                    if (e.target.value.length > 13) {
                                      return
                                    }
                                    // 同一個用戶設置相同的計劃成員編號
                                    for (let value of tableData[index].workerDetailsql) {
                                      if (value.headerId === row.headerId && value.cardHolderId === row.cardHolderId) {
                                        value.mpfScheme = e.target.value
                                      }
                                    }
                                    tableData[index].workerDetailsql[i].typeAdd = row.typeAdd === 'new' ? 'new' : 'edit'
                                    tableData[index].workerDetailsql[i].mpfScheme = e.target.value

                                    setIsChangeData(true)
                                    setTableData([...tableData])
                                  }}
                                  value={row.mpfScheme || ''}
                                />
                              ) : null
                            }
                          </TableCell>
                          {sendData.subBatchPeriod === 'F' && sendData.payrollType === 'S' ? (
                            <>
                              {/* 已繳付發薪實額 */}
                              <TableCell align="left" sx={{ fontSize: '15px' }}>
                                {row.previousRelevantIncomeSet?.relevantIncome || ''}
                              </TableCell>
                              {/* 已繳付上班日數 */}
                              <TableCell align="left" sx={{ fontSize: '15px' }}>
                                {row.previousRelevantIncomeSet?.workDayAct || ''}
                              </TableCell>
                            </>
                          ) : null}
                          {/* <TableCell align="left" sx={{ fontSize: '15px', pr: '5px' }}>
                                                    {row.newadd === "Y" ? null :
                                                        <Checkbox
                                                            disabled
                                                            onChange={(e) => {
                                                                tableData[index].workerDetailsql[i].typeAdd = row.typeAdd === "new" ? "new" : "edit"
                                                                row.type = e.target.checked ? "D" : "M"
                                                                if (e.target.checked) {
                                                                    tableData[index].workerDetailsql[i].type = "D"
                                                                } else {
                                                                    tableData[index].workerDetailsql[i].type = "M"
                                                                }
                                                                // 同一個用戶設置相同的計劃成員編號
                                                                for (let value of tableData[index].workerDetailsql) {
                                                                    if (value.headerId === row.headerId && value.cardHolderId === row.cardHolderId) {
                                                                        value.type = e.target.checked ? "D" : "M"
                                                                    }
                                                                }
                                                                setTableData([...tableData])
                                                            }}
                                                            defaultChecked={row.type === "D" || false} />}
                                                </TableCell>
                                                <TableCell align="left" sx={{ fontSize: '15px', pr: '5px' }}>{row.excludesunday}</TableCell> */}
                          <TableCell align="left" sx={{ minWidth: '100px', fontSize: '15px' }}>
                            <TextField
                              size="small"
                              value={row.payrollRemark || ''}
                              onChange={(e) => {
                                if (e.target.value.length > 100) {
                                  return
                                }
                                tableData[index].workerDetailsql[i].typeAdd = row.typeAdd === 'new' ? 'new' : 'edit'
                                tableData[index].workerDetailsql[i].payrollRemark = e.target.value
                                setIsChangeData(true)
                                setTableData([...tableData])
                              }}
                            />
                          </TableCell>
                          <TableCell align="left" sx={{ fontSize: '15px' }}>
                            {row.typeAdd === 'new' || row.newadd === 'Y' ? (
                              <Button size="small" onClick={() => handleDelete(item, i, row, index)}>
                                {t('button_delete')}
                              </Button>
                            ) : (
                              <>
                                <Button onClick={() => handleAdd(i, row, index)} size="small">
                                  {t('button_add')}
                                </Button>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell colSpan="19" sx={{ textAlign: 'center', fontSize: '15px' }}>
                        No Record
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow hover>
                    <TableCell colSpan="9" sx={{ fontSize: '15px', textAlign: 'right' }}>
                      {t('Total_income')}:
                    </TableCell>
                    <TableCell colSpan="10" sx={{ fontSize: '15px', textAlign: 'left' }}>
                      {addRelevantIncome(item.workerDetailsql)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {/* 加入分頁 */}
              <TablePagination
                rowsPerPageOptions={[50]}
                component="div"
                count={item.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <Button variant="contained" sx={{ mb: '10px' }} onClick={() => handleSave(index, item)}>
                {t('store_workers_income_and_bank_information')}
              </Button>
            </TableContainer>
          </div>
        ))}
      </div>

      <TableContainer>
        <Table
          sx={{
            wordBreak: 'keep-all',
            pageBreakInside: 'inherit',
            maxWidth: 650,
            mt: '40px',
          }}
          size="small"
        >
          <TableHead>
            <TableRow hover>
              <TableCell />
              <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Total_Number')} </TableCell>
              <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Total_income_of_workers')} </TableCell>
              {saveData.loanFlag === 'Y' ? (
                <>
                  <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Borrowing')}</TableCell>
                  <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Deduct_loan')}</TableCell>
                </>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Total_Contractors')}:</TableCell>
              <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{member}</TableCell>
              <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{account || 0}</TableCell>
              {saveData.loanFlag === 'Y' ? (
                <>
                  <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{saveData.final_tot_adv_pay || 0}</TableCell>
                  <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{saveData.final_tot_rtn_pay || 0}</TableCell>
                </>
              ) : null}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Typography sx={{ fontSize: '15px', mt: '10px' }}>
        <b>{t('65_years_Note')}</b>
      </Typography>
      <CalendarPart
        allDateList={allDateList}
        setAllDateList={setAllDateList}
        startDate={startDate}
        endDate={endDate}
        dateOpen={dateOpen}
        setDateOpen={setDateOpen}
        setShortDateTo={setShortDateTo}
        setShortDateFrom={setShortDateFrom}
        datePartI={datePartI}
        datePartIndex={datePartIndex}
        datePartRow={datePartRow}
        setTableData={setTableData}
        tableData={tableData}
        leftOrRightDesign={leftOrRightDesign}
        setDialog={setDialog}
        dialog={dialog}
      />
    </div>
  )
}
