import { Box, Button, Grid, Divider, MenuItem, Table, TableCell, TableHead, TableRow, TableBody, TextField, Typography, TableContainer } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import { useSearchParams, useNavigate, createSearchParams } from 'react-router-dom'
import $http, { $download } from 'src/utils/api/services'
import * as api from 'src/utils/api/workerAttendanceRecord/AutoPayApi'

export default function TableDetail(props) {
  const { handleBackDetail, tableReport, reportData, goData } = props
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [tableData, setTableData] = useState([])
  const [peopleCount, setPeopleCount] = useState(0)
  useEffect(() => {
    let count = 0
    if (tableReport.subDeatilData) {
      for (let v of tableReport.subDeatilData) {
        count += v.workerDetailSet.length
      }
    }
    navigate({
      search: `?${createSearchParams({
        dateFrom1: searchParams.get('dateFrom1'),
        dateTo1: searchParams.get('dateTo1'),
      })}`,
    })

    console.log('adas', goData)

    setPeopleCount(count)
    console.log('handleBackDetail', handleBackDetail)
    console.log('tableReport', tableReport)
    console.log('reportData', reportData)
    console.log('search', searchParams.get('dateFrom1'))
  }, [])

  const exportPDF = (data) => {
    $download(`/autoPayController/reportVendorAutoPayStatusPdf`, 'application/pdf', data)
    console.log('reportData.dateTo', reportData.dateTo)
    console.log('reportData.pdDateTo', reportData.pdDateTo)
    console.log('istrue', reportData.dateTo === reportData.pdDateTo)
  }

  return (
    <>
      <div
        style={{
          display: 'block',
          textAlign: 'left',
          marginTop: '0px',
        }}
      >
        <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBackDetail}>
          <ArrowBackIcon />
          {t('button_back')}
        </Button>
      </div>

      {/* <div style={{ display: "block", textAlign: "right" }}>
                <Button sx={{ float: "right", mr: "20px" }} >
                    <DownloadIcon />
                    {t("button_print")}
                </Button>
            </div> */}
      <Typography variant="h4" sx={{ display: 'block', textAlign: 'center' }}>
        <b>{t('Contractor_Autopay_Status')}</b>
      </Typography>

      <Button sx={{ float: 'right', mr: '100px' }} onClick={() => exportPDF(reportData)}>
        <DownloadIcon />
        {t('button_export_pdf')}
      </Button>
      <Typography variant="h6" sx={{ display: 'block', mt: '10px' }}>
        <b>{t('icon1_autopay_button8_print_title')}</b>
      </Typography>

      <TableContainer>
        <Table sx={{ textAlign: 'left' }} size="small">
          <TableBody>
            <TableRow>
              <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('icon1_autopay_button8_print_label1')}</TableCell>
              <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{tableReport.vendoridset?.vendorDesc}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: 300, borderBottom: 'none' }}>{t('icon1_autopay_button8_print_label2')}:</TableCell>
              <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{tableReport.bankacset ? tableReport.bankacset[0]?.bankAccountName : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('icon1_autopay_button8_print_label3')}:</TableCell>
              <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{tableReport.bankacset ? tableReport.bankacset[0]?.bankAccount : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('plan_Member_Number')}:</TableCell>
              <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{tableReport.bankacset ? tableReport.bankacset[0]?.schemeNo : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('icon1_autopay_button8_print_label5')}:</TableCell>
              <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{tableReport.bankacset ? tableReport.bankacset[0]?.branchCode : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('icon1_autopay_button8_print_label6')}:</TableCell>
              <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{tableReport.bankacset ? tableReport.bankacset[0]?.contactPerson : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('icon1_autopay_button8_print_label7')}:</TableCell>
              <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{tableReport.bankacset ? tableReport.bankacset[0]?.phoneNumber : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('Contractor_zhi')}:</TableCell>
              <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{reportData.pdDateFrom + ' ~ ' + reportData.pdDateTo}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('icon1_autopay_button8_print_label8')}:</TableCell>
              <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{!tableReport.autopaydateset ? 'Not Complete' : tableReport.autopaydate}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Divider sx={{ mt: '10px' }} />
      {tableReport.subDeatilData?.length > 0 ? (
        <>
          {tableReport.subDeatilData.map((item, index) => (
            <div key={index}>
              {/* table */}
              <Typography variant="h6" sx={{ display: 'block', mt: '20px' }}>
                <b>{t('Workers_Income_And_Bank_Information')}</b>
              </Typography>
              {reportData.siteId ? (
                <TableContainer>
                  <Table sx={{ textAlign: 'left' }} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('Payroll_and_Contribution_Period')}</TableCell>

                        <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>
                          {/* {reportData.payrollType === 'P'
                            ? reportData.subBatchId
                              ? reportData?.subBatchId + '.)' + tableReport.bankacset[0]?.dateFrom + ' ~ ' + tableReport.bankacset[0]?.dateTo
                              : tableReport.bankacset[0]?.dateFrom + ' ~ ' + tableReport.bankacset[0]?.dateTo
                            : reportData.payrollType === 'S'
                            ? reportData.pdDateFrom + ' ~ ' + reportData.pdDateTo
                            : tableReport.bankacset[0]?.dateFrom + ' ~ ' + tableReport.bankacset[0]?.dateTo}{' '} */}
                          {reportData.payrollType === 'C' || !reportData.subBatchId
                            ? '完成整個糧期'
                            : reportData.subBatchId
                            ? reportData?.subBatchId + '.)' + tableReport.bankacset[0]?.dateFrom + ' ~ ' + tableReport.bankacset[0]?.dateTo
                            : tableReport.bankacset[0]?.dateFrom + ' ~ ' + tableReport.bankacset[0]?.dateTo}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 300, borderBottom: 'none' }}>{t('Site_Name')}:</TableCell>
                        <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{reportData.siteId}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('Subcontractors')}:</TableCell>
                        <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{item.subcondescset?.subContractorDesc}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <TableContainer>
                  <Table sx={{ textAlign: 'left' }} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('Payroll_and_Contribution_Period')}</TableCell>

                        <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>
                          {' '}
                          {/* {reportData.payrollType === 'P'
                            ? reportData.subBatchId
                              ? reportData?.subBatchId + '.)' + tableReport.bankacset[0]?.dateFrom + ' ~ ' + tableReport.bankacset[0]?.dateTo
                              : tableReport.bankacset[0]?.dateFrom + ' ~ ' + tableReport.bankacset[0]?.dateTo
                            : reportData.payrollType === 'S'
                            ? reportData.pdDateFrom + ' ~ ' + reportData.pdDateTo
                            : tableReport.bankacset[0]?.dateFrom + ' ~ ' + tableReport.bankacset[0]?.dateTo} */}
                          {reportData.payrollType === 'C' || !reportData.subBatchId
                            ? '完成整個糧期'
                            : reportData.subBatchId
                            ? reportData?.subBatchId + '.)' + tableReport.bankacset[0]?.dateFrom + ' ~ ' + tableReport.bankacset[0]?.dateTo
                            : tableReport.bankacset[0]?.dateFrom + ' ~ ' + tableReport.bankacset[0]?.dateTo}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 300, borderBottom: 'none' }}>{t('Site_Name')}:</TableCell>
                        <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{reportData.siteId === undefined ? 'ALL' : ''}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              <TableContainer sx={{ maxHeight: '80vh', maxWidth: '100vw' }}>
                <Table
                  sx={{
                    pageBreakInside: 'inherit',
                    minWidth: 650,
                    pr: '5px',
                    '& .MuiTableCell-root': {
                      paddingLeft: '7px',
                      paddingRight: '7px',
                    },
                  }}
                  stickyHeader
                  size="small"
                >
                  <TableHead sx={{ position: 'sticky', top: '0' }}>
                    <TableRow hover sx={{ border: '1px' }}>
                      <TableCell sx={{ fontSize: '15px' }}>No.</TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all' }}>{t('EnglishName')}</TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all' }}>{t('ChineseName')}</TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all' }}>{t('ID_number')}</TableCell>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          textAlign: 'right',
                          wordBreak: 'keep-all',
                        }}
                      >
                        {t('Number_Of_Workers_Working_Days')}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          textAlign: 'right',
                          wordBreak: 'keep-all',
                        }}
                      >
                        {t('Actual_number_of_working_days')}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          textAlign: 'right',
                          wordBreak: 'keep-all',
                        }}
                      >
                        {t('Worker_income')}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          textAlign: 'right',
                          wordBreak: 'keep-all',
                        }}
                      >
                        {t('Actual_Payroll')}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          textAlign: 'right',
                          wordBreak: 'keep-all',
                        }}
                      >
                        {t('Employer_MPF_contributions')}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          textAlign: 'right',
                          wordBreak: 'keep-all',
                        }}
                      >
                        {t('Employee_MPF_contributions')}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          textAlign: 'right',
                        }}
                      >
                        {t('Average_income')}
                      </TableCell>

                      {reportData.siteId !== null && reportData.siteId && (
                        <TableCell
                          sx={{
                            fontSize: '15px',
                            wordBreak: 'keep-all',
                            textAlign: 'right',
                          }}
                        >
                          {t('icon1_autopay_button7_label6')}
                        </TableCell>
                      )}

                      <TableCell
                        sx={{
                          fontSize: '15px',
                          textAlign: 'right',
                          wordBreak: 'keep-all',
                        }}
                      >
                        {t('Employee_Account_Number')}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          textAlign: 'right',
                          wordBreak: 'keep-all',
                        }}
                      >
                        {t('plan_Member_Number')}
                      </TableCell>
                      <TableCell sx={{ fontSize: '15px', wordBreak: 'keep-all' }}>{t('Remark')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.workerDetailSet.length > 0 ? (
                      <>
                        {item.workerDetailSet.map((row, index) => (
                          <TableRow
                            hover
                            key={index}
                            sx={{
                              backgroundColor: index % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                            }}
                          >
                            <TableCell sx={{ fontSize: '15px', textAlign: 'right' }} component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell sx={{ fontSize: '15px', whiteSpace: 'nowrap' }}>{row.englishName} </TableCell>
                            <TableCell sx={{ fontSize: '15px' }}>{row.chineseName}</TableCell>
                            <TableCell sx={{ fontSize: '15px' }}>{row.hkid}</TableCell>
                            <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                              {row.workDay}
                            </TableCell>
                            <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                              {row.workDayAct}
                            </TableCell>
                            <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                              {row.relevantIncomeForMat && row.relevantIncomeForMat}
                            </TableCell>
                            <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                              {row.netIncomeForMat && row.netIncomeForMat}
                            </TableCell>
                            <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                              {row.employerMpfForMat && row.employerMpfForMat}
                            </TableCell>
                            <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                              {row.employeeMpfForMat && row.employeeMpfForMat}
                            </TableCell>
                            <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{row.dailyIncomeForMat && row.dailyIncomeForMat}</TableCell>
                            {reportData.siteId !== null && reportData.siteId && <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{row?.basicSalaryForMat}</TableCell>}

                            {tableReport.bankacset[0]?.loanFlag === 'Y' ? (
                              <>
                                <TableCell
                                  sx={{
                                    fontSize: '15px',
                                    wordBreak: 'keep-all',
                                  }}
                                >
                                  0
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontSize: '15px',
                                    wordBreak: 'keep-all',
                                  }}
                                >
                                  0
                                </TableCell>
                              </>
                            ) : null}
                            <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{row.bankAccount}</TableCell>
                            <TableCell align="left" sx={{ fontSize: '15px', textAlign: 'right' }}>
                              {row.mpfScheme}
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '15px', wordBreak: 'keep-all' }}>
                              {row.payrollRemark}
                            </TableCell>
                          </TableRow>
                        ))}

                        {reportData.siteId ? (
                          <TableRow hover>
                            <TableCell colSpan="6" sx={{ textAlign: 'right', fontSize: '15px' }}>
                              {' '}
                              {t('Total_income_of_subcontractor_workers')}:
                            </TableCell>
                            <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                              {item.subConDailyIncomeTotal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                            </TableCell>
                            {tableReport.bankacset[0]?.loanFlag === 'Y' ? (
                              <>
                                <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                                  {tableReport.final_tot_adv_pay_format}
                                </TableCell>
                                <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                                  {tableReport.final_tot_rtn_pay_format}
                                </TableCell>
                              </>
                            ) : null}
                            <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                              {item.subConDailyActTotal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                            </TableCell>
                            <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                              {item.subConemployeeMpfTotal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                            </TableCell>
                            <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                              {item.subConemployerMpfTotal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                            </TableCell>
                            <TableCell align="right" sx={{ fontSize: '15px' }} />
                            <TableCell align="right" sx={{ fontSize: '15px' }} />
                            <TableCell align="right" sx={{ fontSize: '15px' }} />
                            <TableCell align="right" sx={{ fontSize: '15px' }} />
                          </TableRow>
                        ) : (
                          <TableRow hover>
                            <TableCell colSpan="6" sx={{ textAlign: 'right', fontSize: '15px' }}>
                              {' '}
                              {t('Total_Contractors')}:
                            </TableCell>
                            <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                              {tableReport.final_tot_rel_format}
                            </TableCell>
                            {tableReport.bankacset[0]?.loanFlag === 'Y' ? (
                              <>
                                <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                                  {tableReport.final_tot_adv_pay_format}
                                </TableCell>
                                <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                                  {tableReport.final_tot_rtn_pay_format}
                                </TableCell>
                              </>
                            ) : null}
                            <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                              {tableReport.final_tot_net_format}
                            </TableCell>
                            <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                              {tableReport.final_tot_er_mpf_format}
                            </TableCell>
                            <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                              {tableReport.final_tot_ee_mpf_format}
                            </TableCell>
                            <TableCell align="right" sx={{ fontSize: '15px' }} />
                            <TableCell align="right" sx={{ fontSize: '15px' }} />
                            <TableCell align="right" sx={{ fontSize: '15px' }} />
                            <TableCell align="right" sx={{ fontSize: '15px' }} />
                          </TableRow>
                        )}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan="14"
                          sx={{
                            textAlign: 'center',
                            fontSize: '15px',
                            px: '5px',
                          }}
                        >
                          No Record
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ))}
        </>
      ) : null}
      <div style={{ marginTop: '50px' }}>
        <TableContainer>
          <Table
            sx={{
              pageBreakInside: 'inherit',
              minWidth: 650,
              wordBreak: 'keep-all',
            }}
            size="small"
          >
            <TableHead>
              <TableRow hover sx={{ border: '1px' }}>
                <TableCell sx={{ fontSize: '15px', textAlign: 'right' }} />
                <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('icon1_autopay_button8_print_PeopleCount')}</TableCell>
                <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Worker_income')}</TableCell>
                <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Actual_Payroll')}</TableCell>
                <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Employer_MPF_contributions')}</TableCell>
                <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Employee_MPF_contributions')}</TableCell>
                <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Total_contributions_from_both_parties')}</TableCell>
                <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Deductions_from_Contractor_Account')}</TableCell>
                {tableReport.bankacset[0]?.loanFlag === 'Y' ? (
                  <>
                    <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                      {t('Borrowing')}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                      {t('Deduct_loan')}
                    </TableCell>
                  </>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover>
                {reportData.siteId ? (
                  <TableCell sx={{ textAlign: 'right', fontSize: '15px' }}> {reportData.siteId + t('Total_Contractors')}:</TableCell>
                ) : (
                  <TableCell sx={{ textAlign: 'right', fontSize: '15px' }}> {t('Total_All_Site_Contractors')}:</TableCell>
                )}
                <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                  {peopleCount}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                  {tableReport.final_tot_rel_format}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                  {tableReport.final_tot_net_format}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                  {tableReport.final_tot_er_mpf_format}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                  {tableReport.final_tot_ee_mpf_format}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                  {(tableReport.final_tot_ee_mpf + tableReport.final_tot_er_mpf).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                  {reportData.dateTo === reportData.pdDateTo
                    ? (tableReport.final_tot_ee_mpf + tableReport.final_tot_er_mpf + tableReport.final_tot_net).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    : tableReport.final_tot_net_format}
                </TableCell>
                {/* <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                  {reportData.dateTo === reportData.pdDateTo
                    ? (tableReport.final_tot_ee_mpf + tableReport.final_tot_er_mpf + tableReport.final_tot_net).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    : (tableReport.final_tot_ee_mpf + tableReport.final_tot_er_mpf + tableReport.final_tot_net).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                </TableCell> */}

                {tableReport.bankacset[0]?.loanFlag === 'Y' ? (
                  <>
                    <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                      {tableReport.final_tot_adv_pay_format}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                      {tableReport.final_tot_rtn_pay_format}
                    </TableCell>
                  </>
                ) : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  )
}
